import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { Cell, ConfirmActionModal, Feedback, Grid, H2, Loader, P, Table, TRTDTemp } from "packages/catalog";
import { EStatus, scrollToContentTop, TParams } from "packages/utils";

import { useGetRoomSystemsOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";
import { deleteRoomSystem } from "packages/client/room-systems/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SuperAdminRoomSystemRow } from "packages/client/room-systems/components/SuperAdminRoomSystemRow/SuperAdminRoomSystemRow";

const COLUMNS = ["ID", "Name", "Status", "Type", "Address/extension"];
const COLUMN_WIDTHS = "4rem 16rem 1fr 1fr 1.4fr 2rem";

export function SuperAdminOrganisationRoomSystems() {
  const { organisationID }: TParams = useParams();

  const [actionID, setActionID] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] = useState(false);
  const [showDeleteErrorMessage, setShowDeleteErrorMessage] = useState(false);

  const {
    data: organisationData,
    loading,
    refetch,
  } = useGetRoomSystemsOrganisationQuery({
    variables: {
      organisationID: parseInt(organisationID, 10),
    },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const handleDeleteRoomSystem = useCallback(() => {
    deleteRoomSystem(actionID)
      .then(() => {
        scrollToContentTop();
        refetch();
        setShowDeleteSuccessMessage(true);
      })
      .catch(() => {
        scrollToContentTop();
        setShowDeleteErrorMessage(true);
      });
    setActionID("");
    setIsDeleteModalOpen(false);
  }, [actionID, refetch]);

  const prepareDeleteRoomSystem = useCallback((roomSystemID: string) => {
    setShowDeleteSuccessMessage(false);
    setShowDeleteErrorMessage(false);
    setActionID(roomSystemID);
    setIsDeleteModalOpen(true);
  }, []);

  if (!organisationData) return null;
  if (loading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler
        message="The organisation Room Systems page has loaded."
        pageTitle="Organisation Room Systems"
      />
      <Grid>
        {showDeleteSuccessMessage && (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Room System deleted.</Feedback>
          </Cell>
        )}
        {showDeleteErrorMessage && (
          <Cell>
            <Feedback status={EStatus.DANGER}>Unable to delete Room System. Please try again.</Feedback>
          </Cell>
        )}
        <Cell>
          <H2>Room Systems</H2>
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing the organisation's Room Systems"
            hasMenuColumn>
            <>
              {organisationData?.organisation?.roomsystemSet?.length ? (
                <>
                  {organisationData?.organisation?.roomsystemSet?.map(roomSystem => (
                    <SuperAdminRoomSystemRow
                      hideOrganisation
                      columns={COLUMNS}
                      columnWidths={COLUMN_WIDTHS}
                      ip={roomSystem.ip}
                      name={roomSystem.name}
                      prepareDeleteRoomSystem={prepareDeleteRoomSystem}
                      privacy={roomSystem.privacy}
                      roomSystemID={roomSystem.id}
                      status={roomSystem.onlineStatus}
                      type={roomSystem.type}
                      key={roomSystem.id}
                    />
                  ))}
                </>
              ) : (
                <TRTDTemp>
                  <P center>This organisation has no Room Systems.</P>
                </TRTDTemp>
              )}
            </>
          </Table>
        </Cell>
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="Room System"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteRoomSystem}
        status={EStatus.DANGER}
      />
    </>
  );
}
