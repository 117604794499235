import { InputHTMLAttributes, KeyboardEvent, MutableRefObject, useCallback } from "react";

import { Input, IAutocompleteDropdownOption } from "packages/catalog";

export interface PAutocompleteSearch extends InputHTMLAttributes<HTMLElement> {
  autoCompleteSearchRef: MutableRefObject<HTMLInputElement>;
  dropdownListRef: MutableRefObject<HTMLDivElement>;
  dropdownOpen: boolean;
  onSelection: (option: IAutocompleteDropdownOption) => void;
  searchOptions: IAutocompleteDropdownOption[];
  setDropdownOpen: (open: boolean) => void;
}

export function AutocompleteSearch({
  autoCompleteSearchRef,
  dropdownListRef,
  dropdownOpen,
  onSelection,
  searchOptions,
  setDropdownOpen,
  ...rest
}: PAutocompleteSearch) {
  const handleSelect = useCallback(
    (s: string) => {
      if (s) {
        const option = searchOptions.find(e => e.name.toLocaleLowerCase().includes(s.toLowerCase()));
        if (option) {
          onSelection(option);
          setDropdownOpen(false);
        }
      }
    },
    [onSelection, searchOptions, setDropdownOpen],
  );

  const handleKeyboardEvent = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          dropdownListRef.current.focus();
          break;
        case "Escape":
          setDropdownOpen(false);
          break;
        case "Enter":
          handleSelect(event.currentTarget.value);
          break;
        default:
          if (!dropdownOpen) setDropdownOpen(true);
      }
    },
    [dropdownListRef, dropdownOpen, handleSelect, setDropdownOpen],
  );

  return (
    <Input
      aria-autocomplete="list"
      aria-expanded={dropdownOpen}
      aria-owns="autocompleteOptions"
      autoCapitalize="none"
      autoComplete="off"
      name="auto-search"
      onKeyDown={handleKeyboardEvent}
      ref={autoCompleteSearchRef}
      role="combobox"
      tabIndex={0}
      type="text"
      {...rest}
    />
  );
}
