export enum EDjangoProfilePrivacy {
  PROTECTED = "protected",
  PUBLIC = "public",
}

export enum EDjangoRecordingQuality {
  High = "high",
  Low = "low",
  Medium = "medium",
}

export interface IDjangoPasswords {
  confirm_password: string;
  new_password: string;
  old_password: string;
}

export interface IDjangoUser {
  confirm_password: string;
  email: string;
  first_name: string;
  id?: string;
  last_name: string;
  opt_in: boolean;
  organisation: number;
  password: string;
  phone_number?: string;
}

export interface IDjangoUserOwnProfile {
  accepted_privacy_policy: boolean;
  bio: string | null;
  email: string;
  first_login: boolean;
  first_name: string;
  id: number;
  is_admin: boolean;
  is_superuser?: boolean;
  job_title: string | null;
  last_name: string;
  opt_in: boolean;
  organisation: number;
  phone_number: string | null;
  profile_picture: string | null;
  profile: EDjangoProfilePrivacy;
  recording_quality: EDjangoRecordingQuality;
  skills: string | null;
  website: string | null;
}
