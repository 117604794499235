import { KeyboardEvent, PointerEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import type { HSL } from "packages/utils";

import styles from "./ColorSquare.module.scss";

const height = 210;

export interface PColorSquare {
  colorHSL: HSL;
  pickerOpen: boolean;
  setColorHSL: (hsl: HSL) => void;
}

export function ColorSquare({ colorHSL, pickerOpen, setColorHSL }: PColorSquare) {
  const [circleGrabbed, setCircleGrabbed] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);

  const canvas = useRef<HTMLCanvasElement>(null);
  const context = useRef<CanvasRenderingContext2D>(null);
  const x = useRef(0);
  const y = useRef(0);
  const animation = useRef<number>(null);

  const canvasBackground = useMemo(
    () => ({
      background: `linear-gradient(to top, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0)), linear-gradient(to right, hsla(0, 0%, 100%, 1), hsla(0, 0%, 100%, 0)), hsla(${colorHSL[0]}, 100%, 50%, 1)`,
    }),
    [colorHSL],
  );

  const updateColorFromPos = useCallback(
    (x: number, y: number) => {
      const hsvValue = 1 - y / height;
      const hsvSaturation = x / canvasWidth;
      const lightness = (hsvValue / 2) * (2 - hsvSaturation);
      const saturation = (hsvValue * hsvSaturation) / (1 - Math.abs(2 * lightness - 1));
      setColorHSL([
        colorHSL[0],
        Math.min((!isNaN(saturation) ? saturation : hsvSaturation) * 100, 100),
        Math.min(lightness * 100, 100),
      ]);
    },
    [canvasWidth, colorHSL, setColorHSL],
  );

  const getMousePos = useCallback(
    (x: number, y: number) => {
      const rect = canvas.current.getBoundingClientRect();
      const xPos = Math.max(0, Math.min(x - rect.left, canvasWidth));
      const yPos = Math.max(0, Math.min(y - rect.top, height));
      return [xPos, yPos];
    },
    [canvasWidth],
  );

  const initContext = useCallback(() => {
    if (!canvas.current) return;
    if (context.current) return;
    context.current = canvas.current.getContext("2d");
  }, []);

  const getNewPosition = useCallback(
    (width: number) => {
      const sat = colorHSL[1] / 100;
      const light = colorHSL[2] / 100;
      const hsvSaturation =
        ((sat / light) * (1 - Math.abs(2 * light - 1))) / (1 + (sat / (2 * light)) * (1 - Math.abs(2 * light - 1)));
      const hsvValue = (2 * light) / (2 - hsvSaturation);
      const xPos = Math.round((!isNaN(hsvSaturation) ? hsvSaturation : sat) * width);
      const yPos = colorHSL[2] !== 0 ? Math.round(height * (1 - hsvValue)) : height;
      x.current = xPos;
      y.current = yPos;
      return [xPos, yPos];
    },
    [colorHSL],
  );

  const moveCircle = useCallback(() => {
    initContext();
    if (!context.current) return;
    const canvasRect = canvas.current.getBoundingClientRect();
    if (canvasRect.width !== canvasWidth) {
      setCanvasWidth(canvasRect.width);
    }

    const [xPos, yPos] = getNewPosition(canvasRect.width);

    const ctx = context.current;

    ctx.clearRect(0, 0, canvasRect.width, height);
    ctx.beginPath();
    ctx.arc(xPos, yPos, 9, 0, 2 * Math.PI);
    ctx.fillStyle = "hsla(240, 1%, 85%, 1)";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(xPos, yPos, 7.5, 0, 2 * Math.PI);
    ctx.fillStyle = `hsla(${colorHSL[0]}, ${colorHSL[1]}%, ${colorHSL[2]}%, 1)`;
    ctx.fill();
    ctx.lineWidth = 1.5;
    ctx.strokeStyle = "#ffffff";
    ctx.stroke();
    animation.current = null;
  }, [canvasWidth, colorHSL, initContext, getNewPosition]);

  const scheduleMoveCircle = useCallback(() => {
    if (!animation.current) animation.current = requestAnimationFrame(moveCircle);
  }, [moveCircle]);

  const handlePointerDown = useCallback(
    (e: PointerEvent<HTMLCanvasElement>) => {
      setCircleGrabbed(true);
      const [xPos, yPos] = getMousePos(e.clientX, e.clientY);
      updateColorFromPos(xPos, yPos);
    },
    [getMousePos, updateColorFromPos],
  );

  const handlePointerMove = useCallback(
    (e: PointerEvent<HTMLCanvasElement>) => {
      if (!circleGrabbed) return;
      const [xPos, yPos] = getMousePos(e.clientX, e.clientY);
      updateColorFromPos(xPos, yPos);
    },
    [circleGrabbed, getMousePos, updateColorFromPos],
  );

  const handlePointerLeave = useCallback(
    (e: PointerEvent<HTMLCanvasElement>) => {
      if (circleGrabbed) {
        const [xPos, yPos] = getMousePos(e.clientX, e.clientY);
        updateColorFromPos(xPos, yPos);
      }
    },
    [circleGrabbed, getMousePos, updateColorFromPos],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLCanvasElement>) => {
      if (e.key !== "Tab") e.preventDefault();
      switch (e.key) {
        case "ArrowUp":
          updateColorFromPos(x.current, Math.max(0, y.current - 1));
          break;
        case "ArrowDown":
          updateColorFromPos(x.current, Math.min(y.current + 1, height));
          break;
        case "ArrowLeft":
          updateColorFromPos(Math.max(0, x.current - 1), y.current);
          break;
        case "ArrowRight":
          updateColorFromPos(Math.min(x.current + 1, canvasWidth), y.current);
          break;
        default:
          break;
      }
    },
    [canvasWidth, updateColorFromPos],
  );

  useEffect(() => {
    return () => cancelAnimationFrame(animation.current);
  }, []);

  useEffect(() => {
    if (!canvas.current || !pickerOpen) return;
    const canvasRect = canvas.current.getBoundingClientRect();
    setCanvasWidth(canvasRect.width);
  }, [pickerOpen, canvas, canvasWidth]);

  useEffect(() => {
    scheduleMoveCircle();
  }, [canvasWidth, colorHSL, scheduleMoveCircle]);

  return (
    <div className={styles.canvasWrapper}>
      <canvas
        ref={canvas}
        className={styles.canvas}
        width={canvasWidth}
        height={height}
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<HTMLCanvasElement>) => handleKeyDown(e)}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={() => setCircleGrabbed(false)}
        onPointerLeave={handlePointerLeave}
        style={canvasBackground}>
        Color picker
      </canvas>
    </div>
  );
}
