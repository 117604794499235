import { useTypedSelector } from "packages/client/redux";

import styles from "./RoutingChangeMessage.module.scss";

export function RoutingChangeMessage() {
  const { routingChangeMessage } = useTypedSelector(({ layout }) => ({
    routingChangeMessage: layout.routingChangeMessage,
  }));

  return (
    <p aria-live="polite" aria-atomic="true" className={styles.message}>
      {routingChangeMessage}
    </p>
  );
}
