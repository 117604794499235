import { useMergedClassNames } from "packages/utils";
import type { HTMLProps } from "react";

import styles from "./Legend.module.scss";

export interface PLegend extends HTMLProps<HTMLLegendElement> {
  hasMargin?: boolean;
}

export function Legend({ className, hasMargin, ...rest }: PLegend) {
  const legendClasses = useMergedClassNames({ className }, [styles.legend, hasMargin && styles.hasMargin]);

  return <legend {...rest} className={legendClasses} />;
}
