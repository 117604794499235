import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  BigPopoverMenu,
  Cell,
  ConfirmActionModal,
  Description,
  Feedback,
  Flexbox,
  Grid,
  H1,
  IconButton,
  IllustratedCTA,
  Input,
  InternalTextLink,
  Label,
  Loader,
  P,
  Pagination,
  PlainAction,
  Strong,
  Table,
  TD,
  TextButton,
  TR,
  TRTDTemp,
} from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EIllustration,
  EStatus,
  PATH_ORGANISATION,
  PATH_ROOMSYSTEM_EDIT,
  PATH_ROOMSYSTEM,
  roomSystemConnectionType,
  scrollToContentTop,
  SEGMENT_ADD,
} from "packages/utils";

import { useGetRoomSystemsOrganisationChildOrganisationsQuery } from "packages/client/room-systems/graphql/roomsystems.generated";
import { useGetChildOrganisationsIDsOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { deleteRoomSystem } from "packages/client/room-systems/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { OrganisationSummary } from "packages/client/organisations/components";
import { PrivacyDescription } from "packages/client/room-systems/components";

const COLUMNS = ["ID", "Name", "Type", "Address/extension", "Organisation"];
const COLUMN_WIDTHS = "4rem 20rem 1fr 1.4fr 2fr 2rem";

export function RoomSystems() {
  const [actionID, setActionID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchString, setDebounceSearchString] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] = useState(false);
  const [showDeleteErrorMessage, setShowDeleteErrorMessage] = useState(false);

  const {
    data: allRoomSystemsData,
    loading,
    error,
    refetch: refetchAllRoomSystems,
  } = useGetRoomSystemsOrganisationChildOrganisationsQuery({
    variables: { page: currentPage, search: debouncedSearchString },
  });
  const { data: childOrganisationIDsData } = useGetChildOrganisationsIDsOwnUserQuery();

  const handleChangeSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchString(e.currentTarget.value),
    [],
  );

  const handleDeleteRoomSystem = useCallback(() => {
    deleteRoomSystem(actionID)
      .then(() => {
        scrollToContentTop();
        refetchAllRoomSystems();
        setShowDeleteSuccessMessage(true);
      })
      .catch(() => {
        scrollToContentTop();
        setShowDeleteErrorMessage(true);
      });
    setActionID("");
    setIsDeleteModalOpen(false);
  }, [actionID, refetchAllRoomSystems]);

  const prepareDeleteRoomSystem = useCallback((roomSystemID: string) => {
    setShowDeleteSuccessMessage(false);
    setShowDeleteErrorMessage(false);
    setActionID(roomSystemID);
    setIsDeleteModalOpen(true);
  }, []);

  useEffect(() => {
    let cancelled = false;

    window.setTimeout(() => {
      if (!cancelled) setDebounceSearchString(searchString);
    }, 500);

    return () => {
      cancelled = true;
    };
  }, [searchString]);

  return (
    <>
      <RoutingChangeHandler message="The room systems page has loaded." pageTitle="Room systems" />
      <Grid noColGap>
        {showDeleteSuccessMessage && (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Room System deleted.</Feedback>
          </Cell>
        )}
        {showDeleteErrorMessage && (
          <Cell>
            <Feedback status={EStatus.DANGER}>Unable to delete Room System. Please try again.</Feedback>
          </Cell>
        )}
        <Cell>
          <Flexbox alignItemsCenter justifyContentSpaceBetween>
            <div>
              <H1>Room Systems</H1>
              <Description>
                Displaying all Room Systems of your organisation
                {childOrganisationIDsData?.user?.organisation?.organisationSet.length
                  ? " and its child organisations"
                  : null}
                .
              </Description>
            </div>
            <Flexbox>
              <Label htmlFor="search" isVisuallyHidden text="Search Room Systems" />
              <Input id="search" name="search" onChange={handleChangeSearch} type="search" value={searchString} />
              <TextButton as={Link} status={EStatus.ACCENT} to={SEGMENT_ADD}>
                Add new
              </TextButton>
            </Flexbox>
          </Flexbox>
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing room systems on ClassView"
            hasMenuColumn>
            <>
              {loading ? (
                <TRTDTemp>
                  <Loader notFullHeight />
                </TRTDTemp>
              ) : (
                <>
                  {allRoomSystemsData?.myOrgRoomSystems?.objects?.length
                    ? allRoomSystemsData?.myOrgRoomSystems?.objects?.map(roomSystem => (
                        <TR columnWidths={COLUMN_WIDTHS} key={roomSystem.id}>
                          <TD heading={COLUMNS[0]}>
                            <Strong>
                              <P>{roomSystem.id}</P>
                            </Strong>
                          </TD>
                          <TD heading={COLUMNS[1]}>
                            <P>
                              <InternalTextLink to={PATH_ROOMSYSTEM(roomSystem.id)}>{roomSystem.name}</InternalTextLink>
                              <PrivacyDescription privacy={roomSystem.privacy} />
                            </P>
                          </TD>
                          <TD heading={COLUMNS[2]}>
                            <P>{roomSystemConnectionType[roomSystem.type]}</P>
                          </TD>
                          <TD heading={COLUMNS[3]}>
                            <P>{roomSystem.ip}</P>
                          </TD>
                          <TD heading={COLUMNS[4]}>
                            <Link style={{ display: "block" }} to={PATH_ORGANISATION(roomSystem.organisation.id)}>
                              <OrganisationSummary organisationID={roomSystem.organisation.id} />
                            </Link>
                          </TD>
                          <TD>
                            <BigPopoverMenu
                              attachment={EAttachment.SOUTH_SOUTH_EAST}
                              trigger={
                                <IconButton
                                  appearance={EAppearance.GHOST}
                                  as="div"
                                  description="Options"
                                  dimension={EDimension.SMALL}
                                  icon={EIcon.MOREVERTICAL}
                                  status={EStatus.NEUTRAL}
                                />
                              }>
                              <Flexbox as="ul" flexDirectionColumn>
                                <li>
                                  <PlainAction as={Link} to={PATH_ROOMSYSTEM_EDIT(roomSystem.id)}>
                                    Edit
                                  </PlainAction>
                                </li>
                                <li>
                                  <PlainAction
                                    as="button"
                                    onClick={() => prepareDeleteRoomSystem(roomSystem.id)}
                                    status={EStatus.DANGER}>
                                    Delete
                                  </PlainAction>
                                </li>
                              </Flexbox>
                            </BigPopoverMenu>
                          </TD>
                        </TR>
                      ))
                    : !debouncedSearchString.length &&
                      !loading && (
                        <TRTDTemp>
                          <IllustratedCTA
                            cta="Add new Room System"
                            illustration={EIllustration.PEOPLE_COLLABORATING}
                            to={SEGMENT_ADD}
                            noPadding>
                            {"Your organisation doesn't have any Room Systems yet."}
                          </IllustratedCTA>
                        </TRTDTemp>
                      )}
                  {debouncedSearchString.length && !allRoomSystemsData?.myOrgRoomSystems?.objects.length && !loading ? (
                    <TRTDTemp>
                      <IllustratedCTA illustration={EIllustration.PEOPLE_COLLABORATING} noPadding>
                        No Room Systems found.
                      </IllustratedCTA>
                    </TRTDTemp>
                  ) : null}
                </>
              )}
              {error && (
                <TRTDTemp>
                  <Feedback status={EStatus.DANGER}>Unable to get list of room systems.</Feedback>
                </TRTDTemp>
              )}
              <></>
            </>
          </Table>
        </Cell>
        {allRoomSystemsData?.myOrgRoomSystems?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={allRoomSystemsData?.myOrgRoomSystems?.hasNext}
              hasPrevious={allRoomSystemsData?.myOrgRoomSystems?.hasPrev}
              page={allRoomSystemsData?.myOrgRoomSystems?.page}
              pages={allRoomSystemsData?.myOrgRoomSystems?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="Room System"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteRoomSystem}
        status={EStatus.DANGER}
      />
    </>
  );
}
