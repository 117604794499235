import { Emoji, ExternalTextLink } from "packages/catalog";

import styles from "./Meta.module.scss";

export interface PMeta {
  setIsFeedbackFormOpen: () => void;
}

export function Meta({ setIsFeedbackFormOpen }: PMeta) {
  return (
    <>
      Made with <Emoji description="love" symbol="❤️" /> by{" "}
      <ExternalTextLink href="https://www.i-immersive.co.uk">i-immersive</ExternalTextLink> |{" "}
      <button className={styles.feedback} onClick={setIsFeedbackFormOpen} type="button">
        Send us feedback
      </button>
    </>
  );
}
