import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";

import {
  Accordion,
  AutocompleteDropdown,
  Cell,
  Field,
  H3,
  IconButton,
  IAutocompleteDropdownOption,
} from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus, useFeatureFlag } from "packages/utils";

import { IRoomSystem, useAddRoomSystem, useRoom } from "packages/client/incall/hooks";

import diallingAudio from "public/ogg/dialling.ogg";
import failedAudio from "public/ogg/failed.ogg";
import joinedAudio from "public/ogg/joined.ogg";

import styles from "./RoomSystems.module.scss";

interface PRoomSystemListItem {
  dialFailed: boolean;
  dialRoom: (roomId: string) => Promise<void>;
  roomSystem: IRoomSystem;
}

const RoomSystemListItem = ({ dialFailed, dialRoom, roomSystem }: PRoomSystemListItem) => {
  const [isDialling, setIsDialling] = useState(false);
  const [roomNotAnswering, setRoomNotAnswering] = useState(false);

  const diallingSound = useMemo(() => {
    const audio = new Audio(diallingAudio);
    audio.loop = true;
    return audio;
  }, []);

  const failedSound = useMemo(() => new Audio(failedAudio), []);

  const joinedSound = useMemo(() => new Audio(joinedAudio), []);

  useEffect(() => {
    if (!isDialling) return;
    const diallingTimer = setTimeout(() => {
      setIsDialling(false);
      setRoomNotAnswering(true);
      diallingSound.currentTime = 0;
    }, 10500);
    diallingSound.play();
    return () => {
      if (isDialling && window.location.search.includes("participants")) joinedSound.play();
      diallingSound.pause();
      clearTimeout(diallingTimer);
    };
  }, [diallingSound, isDialling, joinedSound]);

  useEffect(() => {
    if (!roomNotAnswering) return;
    const noAnswerTimer = setTimeout(() => {
      setRoomNotAnswering(false);
      failedSound.currentTime = 0;
    }, 1500);
    diallingSound.pause();
    failedSound.play();
    return () => {
      failedSound.pause();
      clearTimeout(noAnswerTimer);
    };
  }, [diallingSound, failedSound, roomNotAnswering]);

  useEffect(() => {
    if (dialFailed) {
      setIsDialling(false);
      failedSound.play();
    }
    return () => {
      failedSound.pause();
    };
  }, [dialFailed, failedSound]);

  const handleClick = useCallback(async () => {
    await dialRoom(roomSystem.id);
    setIsDialling(true);
  }, [dialRoom, roomSystem.id]);

  return (
    <li
      className={`${styles.roomSystemListItem} ${isDialling ? styles.dialling : ""} ${
        roomNotAnswering ? styles.noAnswer : ""
      }`}>
      <div className={styles.avatar}>
        <p className={styles.name}>{roomSystem.name}</p>
      </div>
      <IconButton
        appearance={EAppearance.GHOST}
        as="button"
        description={`Dial ${roomSystem.name}`}
        dimension={EDimension.SMALL}
        icon={EIcon.PHONEOUTGOING}
        onClick={handleClick}
        status={EStatus.NEUTRAL}
      />
    </li>
  );
};

export function RoomSystems() {
  const [searchInput, setSearchInput] = useState<string>("");

  const roomSystemDialoutEnabled = useFeatureFlag("roomSystemsDialout");

  const { feedbackMessage, dialFailedId, roomSystemsOnSearch, dialRoom, dialableRooms } = useAddRoomSystem(searchInput);
  const { isMeeting } = useRoom();

  const handleRoomSelect = useCallback(
    async (room: IAutocompleteDropdownOption) => {
      setSearchInput(room.name);
      await dialRoom(room.id, true);
      setSearchInput("");
    },
    [dialRoom],
  );

  const invitedRoomSystems = useMemo(
    () => (
      <ul>
        {dialableRooms?.map(roomSystem => (
          <RoomSystemListItem
            key={roomSystem.id}
            dialFailed={dialFailedId === roomSystem.id}
            dialRoom={dialRoom}
            roomSystem={roomSystem}
          />
        ))}
      </ul>
    ),
    [dialFailedId, dialRoom, dialableRooms],
  );

  return (
    <>
      <Cell>
        <Field
          alerts={
            feedbackMessage && [{ message: feedbackMessage, status: !dialFailedId ? EStatus.SUCCESS : EStatus.DANGER }]
          }
          label="Search for room systems to add">
          <AutocompleteDropdown
            maxLength={30}
            name="room-systems"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
            onOptionSelect={handleRoomSelect}
            options={searchInput.trim().length > 0 ? roomSystemsOnSearch : []}
            optionsCollectiveName="Room systems"
            showNoResultsMessage
            value={searchInput}
          />
        </Field>
      </Cell>
      {roomSystemDialoutEnabled && dialableRooms && isMeeting && dialableRooms?.length !== 0 && (
        <Cell>
          {dialableRooms?.length >= 3 ? (
            <Accordion heading={`Invited room systems (${dialableRooms?.length})`}>{invitedRoomSystems}</Accordion>
          ) : (
            <>
              <H3 hasMargin>{`Invited room systems (${dialableRooms?.length})`}</H3>
              {invitedRoomSystems}
            </>
          )}
        </Cell>
      )}
    </>
  );
}
