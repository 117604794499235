import { Description, Flexbox, P, StatusBadge, Strong } from "packages/catalog";
import { EDimension, EStatus } from "packages/utils";

import { useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { UserAvatar } from "packages/client/users/components";

export interface PUserSummary {
  email: string;
  emailAuthenticated?: boolean;
  fullName: string;
  isActive?: boolean;
  isAdmin?: boolean;
  isSuperadmin?: boolean;
  organisationName?: string;
  profilePictureURL?: string;
}

export function UserSummary({
  email,
  emailAuthenticated = true,
  fullName,
  isActive = true,
  isAdmin,
  isSuperadmin,
  organisationName,
  profilePictureURL,
}: PUserSummary) {
  const { data: ownUserData } = useGetEssentialOwnUserQuery();

  return (
    <Flexbox alignItemsCenter>
      <UserAvatar
        dimension={EDimension.SMALL}
        email={email}
        fullName={fullName}
        profilePictureURL={profilePictureURL}
      />
      <div>
        <Flexbox alignItemsCenter>
          <P>
            <Strong>{fullName}</Strong>
          </P>
          <Flexbox alignItemsCenter>
            {isSuperadmin ? (
              <StatusBadge status={EStatus.NEUTRAL}>SuperAdmin</StatusBadge>
            ) : isAdmin ? (
              <StatusBadge status={EStatus.ACCENT}>Admin</StatusBadge>
            ) : null}
            {/* remove the below once response is org-users only for SA */}
            {ownUserData?.user?.isSuperuser && !emailAuthenticated && (
              <StatusBadge status={EStatus.DANGER}>Pending email verification</StatusBadge>
            )}
            {ownUserData?.user?.isSuperuser && !isActive && (
              <StatusBadge status={EStatus.WARNING}>Pending approval</StatusBadge>
            )}
          </Flexbox>
        </Flexbox>
        <Description>{organisationName ?? email}</Description>
      </div>
    </Flexbox>
  );
}
