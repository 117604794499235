import { useEffect, useMemo, useState } from "react";

import { useChooseDevices, useDevicesMutedState, useKeepDevicesActive, useMicActivity, VidyoCall } from "packages/call";
import { Cell, Grid, Feedback, LabelledContent } from "packages/catalog";
import { EStatus } from "packages/utils";

import { DeviceConfiguration } from "packages/client/precall/components";

import styles from "./DevicePreview.module.scss";

const isNotSupported = !navigator.mediaDevices.enumerateDevices || !navigator.mediaDevices.getUserMedia;

export function DevicePreview() {
  const [haveWebRTCRights, setHaveWebRTCRights] = useState(false);
  const [err, setErr] = useState<Error>(undefined);

  const { level: audioLevel } = useMicActivity();

  const isDevicesReady = haveWebRTCRights && !isNotSupported;

  useKeepDevicesActive(!isDevicesReady);
  const { cameras } = useChooseDevices();
  const { shouldCameraBeMuted } = useDevicesMutedState();

  const videoClasses = useMemo(
    () =>
      [styles.videoPreviewContainer, !cameras.selected && haveWebRTCRights && styles.noCameraFound]
        .filter(e => e)
        .join(" "),
    [cameras, haveWebRTCRights],
  );

  useEffect(() => {
    if (isNotSupported) return;
    let cancelled = false;
    async function f() {
      try {
        const tryWebRTC = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        tryWebRTC.getTracks().forEach(d => d.stop());
        // give time to release the tracks before vidyo grabs them
        window.setTimeout(() => {
          if (!cancelled) setHaveWebRTCRights(true);
        }, 250);
      } catch (e) {
        setErr(e);
      }
    }
    f();
    return () => {
      cancelled = true;
    };
  }, []);

  return (
    <Grid alignItemsCenter>
      {isNotSupported && (
        <Cell>
          <Feedback status={EStatus.WARNING}>Sorry, your browser is not able to support ClassView.</Feedback>
        </Cell>
      )}
      {err && (
        <Cell>
          {!haveWebRTCRights || err.message === "Permission denied" ? (
            <Feedback status={EStatus.WARNING}>
              Cannot access your devices. Please ensure
              {!IS_ELECTRON && " you have granted permission in your browser settings (URL bar) and"} that no other
              application is using your camera or microphone.
            </Feedback>
          ) : (
            <Feedback status={EStatus.DANGER}>Sorry, something went wrong: {err.message}</Feedback>
          )}
        </Cell>
      )}
      <Cell colSpan={12} colSpanMD={6} fullHeight>
        <Grid noColGap>
          <Cell>
            <LabelledContent
              label="Video preview"
              content={
                <div className={videoClasses}>
                  {!cameras.selected && haveWebRTCRights && <div className={styles.camOff}>No camera(s) available</div>}
                  {!haveWebRTCRights && <div className={styles.camOff}>Camera access disallowed</div>}
                  {shouldCameraBeMuted && haveWebRTCRights && (
                    <div className={styles.camOff}>
                      Camera turned off
                      <div className={styles.audioBar} style={{ width: `${audioLevel * 100}%` }}></div>
                    </div>
                  )}
                  {isDevicesReady && !shouldCameraBeMuted && (
                    <div className={styles.videoPreview}>
                      <VidyoCall />
                    </div>
                  )}
                  {IS_ELECTRON && (
                    <meter
                      className={styles.audioMeter}
                      aria-label="microphone detection meter"
                      min="0"
                      max="100"
                      value={audioLevel * 100}
                    />
                  )}
                </div>
              }
            />
          </Cell>
        </Grid>
      </Cell>
      <Cell colSpan={12} colSpanMD={6}>
        <Grid noColGap>
          <DeviceConfiguration />
        </Grid>
      </Cell>
    </Grid>
  );
}
