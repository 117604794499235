import { Link } from "react-router-dom";

import {
  BigPopoverMenu,
  Description,
  Flexbox,
  IconButton,
  InternalTextLink,
  P,
  PlainAction,
  StatusBadge,
  Strong,
  TD,
  TR,
} from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  PATH_SUPERADMIN_ORGANISATION,
  PATH_SUPERADMIN_USER,
} from "packages/utils";

export interface PSuperAdminOrganisationRow {
  columns: string[];
  columnWidths: string;
  email: string;
  handleApproveUser?: (userID: string) => void;
  handleDemoteUser?: (userID: string) => void;
  handlePromoteUser?: (userID: string) => void;
  hideMenuColumn?: boolean;
  hideOrganisation?: boolean;
  isAdmin?: boolean;
  isApproved?: boolean;
  isEmailAuthenticated?: boolean;
  isSuperAdmin?: boolean;
  organisationID?: string;
  organisationName?: string;
  prepareDeleteUser: (userID: string) => void;
  userFullname: string;
  userID: string;
  userOwnID?: string;
}

export function SuperAdminUserRow({
  columns,
  columnWidths,
  email,
  handleApproveUser,
  handleDemoteUser,
  handlePromoteUser,
  hideMenuColumn,
  hideOrganisation,
  isAdmin,
  isApproved,
  isEmailAuthenticated,
  isSuperAdmin,
  organisationID,
  organisationName,
  prepareDeleteUser,
  userFullname,
  userID,
  userOwnID,
}: PSuperAdminOrganisationRow) {
  return (
    <TR columnWidths={columnWidths}>
      <TD heading={columns[0]}>
        <P>
          <Strong>{userID}</Strong>
        </P>
      </TD>
      <TD heading={columns[1]}>
        <Flexbox alignItemsCenter>
          <P>
            {isApproved ? (
              <InternalTextLink to={PATH_SUPERADMIN_USER(userID)}>{userFullname}</InternalTextLink>
            ) : (
              <>{userFullname}</>
            )}
          </P>
          {isSuperAdmin ? (
            <StatusBadge status={EStatus.NEUTRAL}>SuperAdmin</StatusBadge>
          ) : isAdmin ? (
            <StatusBadge status={EStatus.ACCENT}>Admin</StatusBadge>
          ) : null}
        </Flexbox>
        <Description>{email}</Description>
      </TD>
      <TD heading={columns[2]}>
        {isApproved ? (
          <Flexbox alignItemsCenter hasHalfGap>
            <StatusBadge status={EStatus.SUCCESS}>Active</StatusBadge>
          </Flexbox>
        ) : isEmailAuthenticated ? (
          organisationID ? (
            <Flexbox alignItemsCenter hasHalfGap>
              <StatusBadge status={EStatus.WARNING}>Awaiting approval</StatusBadge>
            </Flexbox>
          ) : (
            <Flexbox alignItemsCenter hasHalfGap>
              <StatusBadge status={EStatus.NEUTRAL}>Email verified</StatusBadge>
            </Flexbox>
          )
        ) : (
          <Flexbox alignItemsCenter hasHalfGap>
            <StatusBadge status={EStatus.DANGER}>Awaiting email verification</StatusBadge>
          </Flexbox>
        )}
      </TD>
      {!hideOrganisation && (
        <TD heading={columns[3]}>
          <P>
            {organisationID ? (
              <InternalTextLink to={PATH_SUPERADMIN_ORGANISATION(organisationID)}>{organisationName}</InternalTextLink>
            ) : isEmailAuthenticated ? (
              <StatusBadge status={EStatus.WARNING}>Awaiting approval</StatusBadge>
            ) : (
              <StatusBadge status={EStatus.NEUTRAL}>Pending</StatusBadge>
            )}
          </P>
        </TD>
      )}
      {!hideMenuColumn && (
        <TD>
          {userID !== userOwnID && (
            <BigPopoverMenu
              attachment={EAttachment.SOUTH_SOUTH_EAST}
              trigger={
                <IconButton
                  appearance={EAppearance.GHOST}
                  as="div"
                  description="Options"
                  dimension={EDimension.SMALL}
                  icon={EIcon.MOREVERTICAL}
                  status={EStatus.NEUTRAL}
                />
              }>
              <Flexbox as="ul" flexDirectionColumn>
                {isApproved && (
                  <>
                    <li>
                      <PlainAction as={Link} to={PATH_SUPERADMIN_USER(userID)}>
                        Edit
                      </PlainAction>
                    </li>
                    <li>
                      <PlainAction
                        as="button"
                        onClick={isAdmin ? () => handleDemoteUser(userID) : () => handlePromoteUser(userID)}>
                        {isAdmin ? "Demote" : "Make admin"}
                      </PlainAction>
                    </li>
                  </>
                )}
                {!isApproved && isEmailAuthenticated && organisationID && (
                  <li>
                    <PlainAction as="button" onClick={() => handleApproveUser(userID)}>
                      Approve
                    </PlainAction>
                  </li>
                )}
                <li>
                  <PlainAction as="button" onClick={() => prepareDeleteUser(userID)} status={EStatus.DANGER}>
                    {isApproved || !isEmailAuthenticated ? "Delete" : "Reject"}
                  </PlainAction>
                </li>
              </Flexbox>
            </BigPopoverMenu>
          )}
        </TD>
      )}
    </TR>
  );
}
