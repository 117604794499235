import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { IPollsState } from "packages/client/polls/redux/types";

const initialState: IPollsState = {
  pollQuery: "",
};

export const pollsSlice = createSlice({
  name: "polls",
  initialState,
  reducers: {
    updatePollsQuery: (state, action: PayloadAction<string>) => {
      state.pollQuery = action.payload;
    },
  },
});

export const { updatePollsQuery } = pollsSlice.actions;

export const pollsReducer = pollsSlice.reducer;
