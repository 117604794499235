export enum EDays {
  MO = 0,
  TU = 1,
  WE = 2,
  TH = 3,
  FR = 4,
  SA = 5,
  SU = 6,
}

export enum EParticipants {
  Users = "users",
  Moderators = "moderators",
  Lecturers = "lecturers",
  Guests = "guests",
  RoomSystems = "roomSystems",
}

export enum EToggle {
  Unselect = "unselect",
  Select = "select",
}

export enum ERecurringEnd {
  On = "on",
  After = "after",
}

export enum ERecurringFrequency {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
}

export enum EWeeklyOccurrence {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
}

export interface ISessionState {
  pin: string | null;
  roomKey: string | null;
}
