import type { EIcon, EStatus } from "packages/utils";

export enum ESnackbarFeedbackActions {
  AddSnackbar = "AddSnackbar",
  RemoveSnackbar = "RemoveSnackbar",
}

export interface ISnackbar {
  actions?: ISnackbarAction[];
  content: string;
  id?: string;
  props?: {
    dismissTimeout?: number;
    icon?: EIcon;
    pulse?: boolean;
    small?: boolean;
    status?: EStatus;
    transparent?: boolean;
  };
}

export interface ISnackbarFeedbackState {
  snackbars: ISnackbar[];
}

export interface ISnackbarAction {
  content: string;
  id?: string;
  onTrigger?: () => void;
  props: {
    as: string;
    status?: EStatus;
    to?: string;
  };
}

export interface IAddSnackbarAction {
  data: ISnackbar;
  type: ESnackbarFeedbackActions.AddSnackbar;
}

export interface IRemoveSnackbarAction {
  id: string;
  type: ESnackbarFeedbackActions.RemoveSnackbar;
}

export type TSnackbarFeedbackAction = IAddSnackbarAction | IRemoveSnackbarAction;
