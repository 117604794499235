import { useEffect, useState } from "react";

import { useVidyoSDKInstance } from "../../status/hooks/useVidyoSDKInstance";
import { useSelectorVidyo } from "../../redux";

export function useMicActivity() {
  const [level, setLevel] = useState(0);
  const vidyoSDK = useVidyoSDKInstance();
  const { activeMic, isMuted } = useSelectorVidyo(({ localDevices }) => ({
    activeMic: localDevices.microphones.selected,
    isMuted: localDevices.microphones.shouldBeMuted || localDevices.microphones.serverMuted,
  }));

  useEffect(() => {
    setLevel(0);

    if (!vidyoSDK || !activeMic || isMuted) return;

    vidyoSDK.RegisterLocalMicrophoneEnergyListener({
      onEnergy: (localMic, audioEnergy) => {
        if (localMic.id === activeMic.id) {
          // audioEnergy is a negative number the closer it get to zero the more sound it receives, the next line transform this value to a number between 0 and 1
          const newLevel = Math.min(Math.max((audioEnergy + 60) / 60, 0), 1);
          setLevel(newLevel);
        }
      },
    });

    return () => {
      vidyoSDK.UnregisterLocalMicrophoneEnergyListener();
    };
  }, [activeMic, isMuted, vidyoSDK]);
  return { level };
}
