import { EStatus } from "packages/utils";

export interface PuseStatusStyle {
  accent?: string;
  danger?: string;
  loading?: string;
  neutral?: string;
  success?: string;
  warning?: string;
}

export function useStatusStyle({ accent, danger, loading, neutral, success, warning }: PuseStatusStyle) {
  return {
    [EStatus.ACCENT]: accent ?? "",
    [EStatus.DANGER]: danger ?? "",
    [EStatus.LOADING]: loading ?? "",
    [EStatus.NEUTRAL]: neutral ?? "",
    [EStatus.SUCCESS]: success ?? "",
    [EStatus.WARNING]: warning ?? "",
  };
}
