import { FormEvent, useCallback, useMemo, useState } from "react";

import { Block, Cell, ConfirmActionModal, Feedback, Grid, H1, Input, Flexbox, Label } from "packages/catalog";
import { EStatus } from "packages/utils";

import { OrganisationPricingPlan, RoomObjectType } from "packages/client/graphql_definitions.generated";
import {
  useGetFullOrganisationInfoOwnUserQuery,
  useGetHubsOwnUserQuery,
} from "packages/client/users/graphql/users.generated";
import { deleteHub, setPin } from "packages/client/hubs/django/requests";

import { CreateHub, HubListItem, HubModalPIN } from "packages/client/hubs/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./Hubs.module.scss";

export function Hubs() {
  const [filterString, setFilterString] = useState("");
  const [hubIDToDelete, setHubIDToDelete] = useState<string>(null);
  const [hubToSetPINFor, setHubToSetPINFor] = useState<Partial<RoomObjectType>>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { data: hubsData, refetch: refetchHubsData } = useGetHubsOwnUserQuery();
  const { data: organisationData } = useGetFullOrganisationInfoOwnUserQuery();

  const LIMIT_HUBS = useMemo(() => {
    return organisationData &&
      (organisationData?.user?.organisation?.pricingPlan === OrganisationPricingPlan.SuperOrg ||
        organisationData?.user?.organisation?.pricingPlan === OrganisationPricingPlan.SuperOrgLite)
      ? Infinity
      : 5;
  }, [organisationData]);

  // remove any old existing classview hubs from the hub list
  const legacyHubs = useMemo(() => hubsData?.user?.hubs.filter(hub => !hub.isClassview), [hubsData]);

  const filteredHubs = useMemo(() => {
    if (!filterString?.length) return legacyHubs;
    return legacyHubs.filter(h => h.name.toLowerCase().includes(filterString.toLowerCase()));
  }, [legacyHubs, filterString]);

  const handleChangeFilter = useCallback((e: FormEvent<HTMLInputElement>) => {
    setFilterString(e.currentTarget.value);
  }, []);

  const openDeleteModal = useCallback((hubID: string) => {
    setHubIDToDelete(hubID);
    setIsDeleteModalOpen(true);
  }, []);

  const abortDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setHubIDToDelete(null);
  }, []);

  const abortPINModal = useCallback(() => {
    setHubToSetPINFor(null);
  }, []);

  const handleDeleteHub = useCallback(() => {
    deleteHub(hubIDToDelete).then(() => {
      refetchHubsData();
      abortDeleteModal();
    });
  }, [abortDeleteModal, hubIDToDelete, refetchHubsData]);

  const handleChoosingHubToSetPINFor = useCallback(
    (hubRoomKey: string) => {
      const hubToSet = hubsData?.user?.hubs.find(hub => hub.key === hubRoomKey);
      if (!hubToSet) return;
      setHubToSetPINFor(hubToSet);
    },
    [hubsData],
  );

  const handleSetPIN = useCallback(
    (newPin: number | null) => {
      setPin(hubToSetPINFor.key, newPin)
        .then(() => refetchHubsData())
        .catch(_e => {
          window.alert("Error changing PIN");
        });
      abortPINModal();
    },
    [abortPINModal, hubToSetPINFor?.key, refetchHubsData],
  );

  return (
    <>
      <RoutingChangeHandler message="The hubs page has loaded." pageTitle="Hubs" />
      <Grid noColGap>
        <Cell>
          <Flexbox alignItemsCenter justifyContentSpaceBetween>
            <H1>Hubs</H1>
            <Label htmlFor="searchHubs" isVisuallyHidden text="Search Hubs" />
            <Input id="searchHubs" name="searchHubs" onChange={handleChangeFilter} type="search" />
          </Flexbox>
        </Cell>
        {organisationData?.user?.organisation?.pricingPlan === OrganisationPricingPlan.Schools && (
          <Cell>
            <Feedback status={EStatus.NEUTRAL}>
              {"Our new Hubs provide more functionality. Create a new Hub to make the most of our new features."}
            </Feedback>
          </Cell>
        )}
        <Cell>
          <Grid>
            <Cell as={Block} className={styles.card} colSpanSM={6} colSpanLG={4}>
              <CreateHub limit={LIMIT_HUBS} refetchHubs={refetchHubsData} totalHubs={legacyHubs?.length} />
            </Cell>
            <>
              {filteredHubs?.map(hub => (
                <Cell as={Block} className={styles.card} colSpanSM={6} colSpanLG={4} key={hub.key}>
                  <HubListItem
                    hub={hub}
                    mainHub={legacyHubs[0].key === hub.key}
                    removeHub={openDeleteModal}
                    setPin={handleChoosingHubToSetPINFor}
                  />
                </Cell>
              ))}
            </>
          </Grid>
        </Cell>
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="hub"
        isOpen={isDeleteModalOpen}
        onAbort={abortDeleteModal}
        onAction={handleDeleteHub}
        status={EStatus.DANGER}
      />
      <HubModalPIN
        currentPIN={hubToSetPINFor?.pin}
        handleSetPIN={handleSetPIN}
        isOpen={!!hubToSetPINFor}
        onAbort={abortPINModal}
      />
    </>
  );
}
