import { useEffect, useRef } from "react";

import { useTypedDispatch } from "packages/client/redux";
import { updateRoutingChangeMessage } from "packages/client/layout/redux/slice";

import styles from "./RoutingChangeHandler.module.scss";

export interface PRoutingChangeHandler {
  message: string;
  pageTitle?: string;
}

export function RoutingChangeHandler({ message, pageTitle }: PRoutingChangeHandler) {
  const focusRef = useRef<HTMLDivElement>(null);

  const dispatch = useTypedDispatch();

  useEffect(() => {
    // change the page title
    if (pageTitle) document.title = `${pageTitle} | ClassView`;

    // move focus to the content
    if (focusRef?.current) focusRef?.current?.focus();

    // update the screenreader message to say the page has changed
    dispatch(updateRoutingChangeMessage(message));

    return () => {
      dispatch(updateRoutingChangeMessage(""));
    };
  }, [dispatch, focusRef, message, pageTitle]);

  return <div className={styles.div} ref={focusRef} tabIndex={-1} />;
}
