import { LOCAL_JWT_TOKEN, S3_API_PATH } from "packages/utils";

import { getToken } from "packages/client/authentication/redux/functions";

export function generateFileAPIEndpointURLAuthenticated(bucket: string, subfolder: string, filename: string) {
  const token = getToken();
  if (!token) return;
  return `https://${DOMAIN}${S3_API_PATH}/file?bucket=${bucket}&subfolder=${subfolder}&filename=${filename}&token=${token}`;
}

export function generateRecordingAPIEndpointURL(filename: string): string {
  const token = getToken();
  const localToken = localStorage.getItem(LOCAL_JWT_TOKEN);
  if (!token && !localToken) return "";
  return `https://${DOMAIN}${S3_API_PATH}/file/url?token=${token || localToken}&file=${filename}&time=7200`;
}
