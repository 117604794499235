import { DetailedHTMLProps, ElementType, forwardRef, HTMLAttributes, MutableRefObject, useMemo } from "react";

import type { TSpan } from "packages/utils";

import styles from "./Cell.module.scss";

export interface PCell extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  as?: string | ElementType;
  center?: boolean;
  className?: string;
  colSpan?: TSpan;
  colSpanSM?: TSpan;
  colSpanMD?: TSpan;
  colSpanLG?: TSpan;
  colSpanXL?: TSpan;
  colSpanXXL?: TSpan;
  fullWidthContent?: boolean;
  fullHeight?: boolean;
}

const allBreakpointClasses = {
  1: styles.colSpan1,
  2: styles.colSpan2,
  3: styles.colSpan3,
  4: styles.colSpan4,
  5: styles.colSpan5,
  6: styles.colSpan6,
  7: styles.colSpan7,
  8: styles.colSpan8,
  9: styles.colSpan9,
  10: styles.colSpan10,
  11: styles.colSpan11,
  12: styles.colSpan12,
};

const SMClasses = {
  1: styles.colSpanSM1,
  2: styles.colSpanSM2,
  3: styles.colSpanSM3,
  4: styles.colSpanSM4,
  5: styles.colSpanSM5,
  6: styles.colSpanSM6,
  7: styles.colSpanSM7,
  8: styles.colSpanSM8,
  9: styles.colSpanSM9,
  10: styles.colSpanSM10,
  11: styles.colSpanSM11,
  12: styles.colSpanSM12,
};

const MDClasses = {
  1: styles.colSpanMD1,
  2: styles.colSpanMD2,
  3: styles.colSpanMD3,
  4: styles.colSpanMD4,
  5: styles.colSpanMD5,
  6: styles.colSpanMD6,
  7: styles.colSpanMD7,
  8: styles.colSpanMD8,
  9: styles.colSpanMD9,
  10: styles.colSpanMD10,
  11: styles.colSpanMD11,
  12: styles.colSpanMD12,
};

const LGClasses = {
  1: styles.colSpanLG1,
  2: styles.colSpanLG2,
  3: styles.colSpanLG3,
  4: styles.colSpanLG4,
  5: styles.colSpanLG5,
  6: styles.colSpanLG6,
  7: styles.colSpanLG7,
  8: styles.colSpanLG8,
  9: styles.colSpanLG9,
  10: styles.colSpanLG10,
  11: styles.colSpanLG11,
  12: styles.colSpanLG12,
};

const XLClasses = {
  1: styles.colSpanXL1,
  2: styles.colSpanXL2,
  3: styles.colSpanXL3,
  4: styles.colSpanXL4,
  5: styles.colSpanXL5,
  6: styles.colSpanXL6,
  7: styles.colSpanXL7,
  8: styles.colSpanXL8,
  9: styles.colSpanXL9,
  10: styles.colSpanXL10,
  11: styles.colSpanXL11,
  12: styles.colSpanXL12,
};

const XXLClasses = {
  1: styles.colSpanXXL1,
  2: styles.colSpanXXL2,
  3: styles.colSpanXXL3,
  4: styles.colSpanXXL4,
  5: styles.colSpanXXL5,
  6: styles.colSpanXXL6,
  7: styles.colSpanXXL7,
  8: styles.colSpanXXL8,
  9: styles.colSpanXXL9,
  10: styles.colSpanXXL10,
  11: styles.colSpanXXL11,
  12: styles.colSpanXXL12,
};

export const Cell = forwardRef((props: PCell, ref: MutableRefObject<HTMLElement>) => {
  const {
    center,
    children,
    className,
    colSpan: ALL,
    colSpanSM: SM,
    colSpanMD: MD,
    colSpanLG: LG,
    colSpanXL: XL,
    colSpanXXL: XXL,
    as: Component = "div",
    fullWidthContent,
    fullHeight,
    ...rest
  } = props;

  const colSpansClasses = useMemo(
    () =>
      [allBreakpointClasses[ALL], SMClasses[SM], MDClasses[MD], LGClasses[LG], XLClasses[XL], XXLClasses[XXL]]
        .filter(breakpoint => breakpoint)
        .join(" "),
    [ALL, SM, MD, LG, XL, XXL],
  );

  const classes = useMemo(
    () =>
      [
        styles.cell,
        center && styles.center,
        className && className,
        colSpansClasses && colSpansClasses,
        fullWidthContent && styles.fullWidthContent,
        fullHeight && styles.fullHeight,
      ]
        .filter(e => e)
        .join(" "),
    [center, className, colSpansClasses, fullHeight, fullWidthContent],
  );

  return (
    <Component className={classes} ref={ref} {...rest}>
      {children}
    </Component>
  );
});
