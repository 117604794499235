import type { IVidyoLayoutState } from "../interfaces";
import { EVidyoLocalDeviceStatus } from "../../status/enums";
import { EVideoStream } from "../../streams/enums";
import type { IParticipantStream } from "../../streams/interfaces";

const STATUSES_DISPLAYABLE = {
  [EVidyoLocalDeviceStatus.STARTED]: true,
  [EVidyoLocalDeviceStatus.RESUMED]: true,
  [EVidyoLocalDeviceStatus.UNSUSPENDED]: true,
} as const;

export const SELF_USERID = "local";

function isStatusDisplayable(stateToTest: EVidyoLocalDeviceStatus): boolean {
  if (!stateToTest) return true;
  // Vidyo on electron doesn't handle devices the same way and activate / deactivates them on his own
  if (IS_ELECTRON) return true;
  // @ts-expect-error typescript derps on const from string enums
  return STATUSES_DISPLAYABLE?.[stateToTest] ?? false;
}

export function getLocalParticipantStreams(state: IVidyoLayoutState) {
  const { localDevices, sharing, status } = state;
  const { cameras } = localDevices;
  const localWindowShare = sharing?.windowShare?.selected;
  const arr: IParticipantStream[] = [];

  if (localWindowShare) {
    arr.push({
      participantUserID: SELF_USERID,
      stream: localWindowShare,
      streamType: EVideoStream.LOCAL_WINDOW_SHARE,
    });
  }

  if (
    cameras.selected &&
    status.showSelfview &&
    !cameras.shouldBeMuted &&
    !cameras.serverMuted &&
    isStatusDisplayable(cameras.status)
  ) {
    arr.push({
      participantUserID: SELF_USERID,
      stream: cameras.selected,
      streamType: EVideoStream.LOCAL_CAMERA,
      status: cameras.status,
    });
  }
  return arr;
}
