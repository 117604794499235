import { Cell, Field, Input } from "packages/catalog";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import { changeDescription, changeSubject } from "packages/client/sessions/redux/slice";

export function EditOverview() {
  const dispatch = useTypedDispatch();

  const { description, subject } = useTypedSelector(({ sessionForm }) => ({
    description: sessionForm.description,
    subject: sessionForm.subject,
  }));

  return (
    <>
      <Cell>
        <Field label="Title">
          <Input
            maxLength={50}
            name="title"
            onChange={s => dispatch(changeSubject(s.currentTarget.value))}
            required
            type="text"
            value={subject}
          />
        </Field>
      </Cell>
      <Cell>
        <Field label="Description">
          <Input
            maxLength={100}
            name="description"
            onChange={s => dispatch(changeDescription(s.currentTarget.value))}
            type="text"
            value={description}
          />
        </Field>
      </Cell>
    </>
  );
}
