import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import type { ApolloQueryResult } from "@apollo/client";

import { Cell, Field, Flexbox, Grid, Input, TextButton } from "packages/catalog";
import { EAppearance, EStatus } from "packages/utils";

import type { GetHubsOwnUserQuery } from "packages/client/users/graphql/users.generated";
import { createHub } from "packages/client/hubs/django/requests";

import styles from "./CreateHub.module.scss";

export interface PCreateHub {
  limit: number;
  refetchHubs: () => Promise<ApolloQueryResult<GetHubsOwnUserQuery>>;
  totalHubs: number;
}

export function CreateHub({ limit, refetchHubs, totalHubs }: PCreateHub) {
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState<string>(null);
  const [name, setName] = useState("");

  const handleCreateHub = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setErrorText(null);
      setName("");
      setError(false);
      setCreating(false);

      if (totalHubs >= limit) {
        setError(true);
        setErrorText(`You are already at the maximum of ${limit} hubs, please delete one to create another.`);
        return;
      }

      if (name.length < 1) {
        setError(true);
        setErrorText("You need to provide a name for your new hub.");
        return;
      }

      createHub(name)
        .then(() => {
          refetchHubs();
          setErrorText(null);
          setName("");
          setError(false);
        })
        .catch(() => {
          setError(true);
          setErrorText("Unable to create hub. Please try again.");
        });
    },
    [limit, name, refetchHubs, totalHubs],
  );

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  }, []);

  const toggleCreating = useCallback(() => {
    setCreating(!creating);
    setErrorText(null);
    setName("");
    setError(false);
  }, [creating]);

  return (
    <Grid className={styles.card} noColGap>
      {creating ? (
        <Cell>
          <form onSubmit={handleCreateHub}>
            <Grid>
              <Cell>
                <Field alerts={error ? [{ message: errorText, status: EStatus.DANGER }] : null} label="Hub name">
                  <Input
                    name="name"
                    maxLength={50}
                    minLength={1}
                    onChange={handleNameChange}
                    type="text"
                    value={name}
                  />
                </Field>
              </Cell>
              <Cell center>
                <Flexbox>
                  <TextButton appearance={EAppearance.GHOST} onClick={toggleCreating}>
                    Cancel
                  </TextButton>
                  <Input type="submit" value="Create" />
                </Flexbox>
              </Cell>
            </Grid>
          </form>
        </Cell>
      ) : (
        <Cell center>
          <TextButton onClick={toggleCreating} status={EStatus.ACCENT}>
            Create a new Hub
          </TextButton>
        </Cell>
      )}
    </Grid>
  );
}
