import { useCallback } from "react";

import { ETheme, DEFAULT_THEME } from "packages/theming";
import { LOCAL_THEME } from "packages/utils";

import { useTypedDispatch } from "packages/client/redux";
import { updateTheme } from "packages/client/layout/redux/slice";

const possibleValues = Object.values(ETheme);

function isTheme(t: string): t is ETheme {
  return possibleValues.includes(t as ETheme);
}

export function useTheme() {
  const dispatch = useTypedDispatch();

  const autoLoadTheme = useCallback(() => {
    const storedTheme = localStorage.getItem(LOCAL_THEME);

    if (storedTheme && isTheme(storedTheme)) {
      dispatch(updateTheme(storedTheme));
    } else if (window.matchMedia?.("(prefers-color-scheme: dark)").matches) {
      dispatch(updateTheme(DEFAULT_THEME));
    }
  }, [dispatch]);

  return {
    autoLoadTheme,
  };
}
