import { useRef } from "react";

const _globalIDs: Record<string, number> = {};

const CURRENT_ID_PROPERTY = "__currentId__";
const DEFAULT_ID_STRING = "id__";
if (_globalIDs[CURRENT_ID_PROPERTY] === undefined) {
  _globalIDs[CURRENT_ID_PROPERTY] = 0;
}

function getId(prefix?: string) {
  const index = _globalIDs[CURRENT_ID_PROPERTY]++;

  return `${prefix ?? DEFAULT_ID_STRING}${index}`;
}

export function useId(prefix?: string, providedId?: string): string {
  // getId should only be called once since it updates the global constant for the next ID value.
  // (While an extra update isn't likely to cause problems in practice, it's better to avoid it.)
  const ref = useRef<string | undefined>(providedId);
  if (!ref.current) {
    ref.current = getId(prefix);
  }
  return ref.current;
}
