import type { ITimeSlot } from "packages/utils";

export const MAXIMUM_DATETIME = new Date(new Date().setFullYear(new Date().getFullYear() + 2));

export function generateTimeSlots(start: Date, end: Date, slotTimeDifference = 300000): ITimeSlot[] {
  let i = 0;
  let lastSlot = new Date(start).setHours(start.getHours(), start.getMinutes(), start.getSeconds(), 0);
  const slots = [];
  for (; end ? lastSlot < end.getTime() : i < 100; i++) {
    lastSlot = lastSlot - (lastSlot % slotTimeDifference) + slotTimeDifference;
    slots.push({
      id: lastSlot,
      date: new Date(lastSlot),
    });
  }
  return slots;
}

export function areSameDays(d: Date, dd: Date): boolean {
  return d.getDate() === dd.getDate() && d.getMonth() === dd.getMonth() && d.getFullYear() === dd.getFullYear();
}

export function isToday(d: Date): boolean {
  return areSameDays(d, new Date());
}

export function isNowBetweenStartAndEnd(startTime: Date, endTime: Date) {
  const now = new Date().getTime();
  return now >= startTime.getTime() && now <= endTime.getTime();
}

export function isNowOneHourFromStart(startTime: Date) {
  const now = new Date().getTime();
  return startTime.getTime() - now <= 60 * 60 * 1000;
}

export function isNowAfterTime(time: Date): boolean {
  const now = new Date();
  if (time.getHours() < now.getHours()) {
    return true;
  } else if (time.getHours() === now.getHours() && time.getMinutes() < now.getMinutes()) {
    return true;
  } else {
    return false;
  }
}

export function isNowAfterDate(date: Date): boolean {
  const now = new Date();

  return now.getTime() > date.getTime();
}

// TODO: remove when not in use
export function getMidnight(date: Date): Date {
  return new Date(new Date(date).setHours(23, 59, 59, 999));
}
