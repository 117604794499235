// THIS IS A GENERATED FILE `yarn generate` to refresh it

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AnswerV2 = {
  readonly __typename: 'AnswerV2';
  readonly answerOptionIds: ReadonlyArray<Scalars['String']['output']>;
  readonly customAnswer?: Maybe<Scalars['String']['output']>;
  readonly questionId: Scalars['String']['output'];
};

export type ContentMessage = {
  readonly __typename: 'ContentMessage';
  /** Content of the message in Markdown */
  readonly text: Scalars['String']['output'];
};

export type ContentMessageInput = {
  /** Content of the message in Markdown */
  readonly text: Scalars['String']['input'];
};

export type ConversationCleared = {
  readonly __typename: 'ConversationCleared';
  readonly clearedBy: Scalars['String']['output'];
  readonly convID: Scalars['ID']['output'];
  readonly deletedCount: Scalars['Int']['output'];
};

export type DialInDetails = {
  readonly __typename: 'DialInDetails';
  readonly e164?: Maybe<Scalars['String']['output']>;
  readonly extension?: Maybe<Scalars['String']['output']>;
  readonly h323?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly owner?: Maybe<Scalars['Int']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly room: Scalars['ID']['output'];
  readonly s4b?: Maybe<Scalars['String']['output']>;
  readonly sip?: Maybe<Scalars['String']['output']>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  readonly __typename: 'DjangoDebug';
  /** Executed SQL queries for this API query. */
  readonly sql?: Maybe<ReadonlyArray<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  readonly __typename: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  readonly alias: Scalars['String']['output'];
  /** Duration of this database query in seconds. */
  readonly duration: Scalars['Float']['output'];
  /** Postgres connection encoding if available. */
  readonly encoding?: Maybe<Scalars['String']['output']>;
  /** Whether this database query was a SELECT. */
  readonly isSelect: Scalars['Boolean']['output'];
  /** Whether this database query took more than 10 seconds. */
  readonly isSlow: Scalars['Boolean']['output'];
  /** Postgres isolation level if available. */
  readonly isoLevel?: Maybe<Scalars['String']['output']>;
  /** JSON encoded database query parameters. */
  readonly params: Scalars['String']['output'];
  /** The raw SQL of this query, without params. */
  readonly rawSql: Scalars['String']['output'];
  /** The actual SQL sent to this database. */
  readonly sql?: Maybe<Scalars['String']['output']>;
  /** Start time of this database query. */
  readonly startTime: Scalars['Float']['output'];
  /** Stop time of this database query. */
  readonly stopTime: Scalars['Float']['output'];
  /** Postgres transaction ID if available. */
  readonly transId?: Maybe<Scalars['String']['output']>;
  /** Postgres transaction status if available. */
  readonly transStatus?: Maybe<Scalars['String']['output']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  readonly vendor: Scalars['String']['output'];
};

export type EditPollInputV2 = {
  readonly canUsersSeeResults: Scalars['Boolean']['input'];
  readonly isRespondentsNameRequired?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name: Scalars['String']['input'];
  readonly questions: ReadonlyArray<PollQuestionsInputV2>;
};

export type GetAllRoomInfoRes = {
  readonly __typename: 'GetAllRoomInfoRes';
  readonly canUserModerate?: Maybe<Scalars['Boolean']['output']>;
  readonly dialInDetails?: Maybe<DialInDetails>;
  readonly needPin: Scalars['Boolean']['output'];
  readonly roomId: Scalars['ID']['output'];
  readonly roomInfo: RoomInfo;
  readonly roomTypeLink?: Maybe<RoomTypeLinking>;
};

export type GetFeaturesByRoomIdRes = {
  readonly __typename: 'GetFeaturesByRoomIdRes';
  readonly hideCollaboration: Scalars['Boolean']['output'];
  readonly privateInvites: Scalars['Boolean']['output'];
  readonly recordingDisabled: Scalars['Boolean']['output'];
  readonly roomId: Scalars['ID']['output'];
};

export type GetMyPollsResV2 = {
  readonly __typename: 'GetMyPollsResV2';
  readonly count: Scalars['Int']['output'];
  readonly items: ReadonlyArray<PollV2>;
  readonly total: Scalars['Int']['output'];
};

export type MeetingPaginatedType = {
  readonly __typename: 'MeetingPaginatedType';
  readonly hasNext?: Maybe<Scalars['Boolean']['output']>;
  readonly hasPrev?: Maybe<Scalars['Boolean']['output']>;
  readonly objects?: Maybe<ReadonlyArray<Maybe<MeetingType>>>;
  readonly page?: Maybe<Scalars['Int']['output']>;
  readonly pages?: Maybe<Scalars['Int']['output']>;
};

export type MeetingType = {
  readonly __typename: 'MeetingType';
  readonly applicationPreferred: Scalars['Boolean']['output'];
  readonly blockExternalGuests: Scalars['Boolean']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly endTime?: Maybe<Scalars['DateTime']['output']>;
  readonly guests?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly id: Scalars['ID']['output'];
  readonly isLectureMode: Scalars['Boolean']['output'];
  readonly isPrivate: Scalars['Boolean']['output'];
  readonly isRecorded: Scalars['Boolean']['output'];
  readonly isWebcastMode: Scalars['Boolean']['output'];
  readonly lecturers: ReadonlyArray<UserType>;
  readonly localTimezone: Scalars['String']['output'];
  readonly meetingOrganiser?: Maybe<UserType>;
  readonly moderators: ReadonlyArray<UserType>;
  readonly nextRecurringEnd?: Maybe<Scalars['DateTime']['output']>;
  readonly nextRecurringStart?: Maybe<Scalars['DateTime']['output']>;
  readonly objectId?: Maybe<Scalars['Int']['output']>;
  readonly pexipRoomUrl?: Maybe<Scalars['String']['output']>;
  readonly pin?: Maybe<Scalars['Int']['output']>;
  readonly pinProtected: Scalars['Boolean']['output'];
  readonly recordingPlaybackUrl?: Maybe<Scalars['String']['output']>;
  readonly recurringDates?: Maybe<ReadonlyArray<Maybe<Scalars['DateTime']['output']>>>;
  readonly recurringEndDate?: Maybe<Scalars['String']['output']>;
  readonly recurringEveryXMonths?: Maybe<Scalars['Int']['output']>;
  readonly recurringEveryXWeeks?: Maybe<Scalars['Int']['output']>;
  readonly recurringInterval?: Maybe<Scalars['String']['output']>;
  readonly recurringOnDays?: Maybe<ReadonlyArray<Maybe<Scalars['Int']['output']>>>;
  readonly recurringOnXWeekOfMonth?: Maybe<Scalars['Int']['output']>;
  readonly recurringRepetitions?: Maybe<Scalars['Int']['output']>;
  readonly recurringWeekdaysOnly?: Maybe<Scalars['Boolean']['output']>;
  readonly room?: Maybe<RoomObjectType>;
  readonly roomSystems: ReadonlyArray<RoomSystemDialType>;
  readonly startTime?: Maybe<Scalars['DateTime']['output']>;
  readonly subject?: Maybe<Scalars['String']['output']>;
  readonly users: ReadonlyArray<UserType>;
  readonly waitingRoomEnabled: Scalars['Boolean']['output'];
  readonly webcastAlias?: Maybe<Scalars['String']['output']>;
  readonly webcastChannelId?: Maybe<Scalars['String']['output']>;
  readonly webcastRtmp?: Maybe<Scalars['String']['output']>;
  readonly webcastUrl?: Maybe<Scalars['String']['output']>;
};

export type Message = {
  readonly __typename: 'Message';
  /** text + metadatas */
  readonly content?: Maybe<ContentMessage>;
  /** Conversation ID */
  readonly convID: Scalars['ID']['output'];
  /** Unix datetime of the message (set by backend) */
  readonly datetime: Scalars['ID']['output'];
  /** Sender is a guest */
  readonly guest?: Maybe<Scalars['Boolean']['output']>;
  /** Sender of the message */
  readonly sender?: Maybe<Scalars['String']['output']>;
};

export type MessageDeleted = {
  readonly __typename: 'MessageDeleted';
  readonly convID: Scalars['ID']['output'];
  readonly datetime: Scalars['ID']['output'];
  readonly deletedBy: Scalars['String']['output'];
};

export type MessageInput = {
  /** text + metadatas */
  readonly content: ContentMessageInput;
  /** Conversation ID */
  readonly convID: Scalars['ID']['input'];
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly createPollV2: PollV2;
  readonly createResponseV2: Scalars['Boolean']['output'];
  /** signal will received from subscription conversationCleared */
  readonly deleteAllMessagesFromConv?: Maybe<ConversationCleared>;
  /** signal will received from subscription messageDeleted */
  readonly deleteMessage?: Maybe<MessageDeleted>;
  readonly deletePollV2: Scalars['Boolean']['output'];
  readonly deletePublicAnnouncement: Scalars['ID']['output'];
  readonly duplicatePollV2: PollV2;
  readonly editPollV2: Scalars['Boolean']['output'];
  /** message will received from subscription newMessage */
  readonly sendMessage?: Maybe<Message>;
  readonly setRaiseHand: ReadonlyArray<RaisedHand>;
  readonly unlockRoom: Scalars['Boolean']['output'];
  readonly updatePollStatusV2: Scalars['Boolean']['output'];
  readonly upsertPublicAnnoucement: PublicAnnouncement;
  readonly whiteboardCleanEntities?: Maybe<Scalars['ID']['output']>;
  readonly whiteboardDeleteEntity?: Maybe<Scalars['ID']['output']>;
  readonly whiteboardUpsertEntity?: Maybe<WhiteboardEntity>;
};


export type MutationCreatePollV2Args = {
  newPollInput: NewPollInputV2;
};


export type MutationCreateResponseV2Args = {
  newResponseInput: NewResponseInputV2;
};


export type MutationDeleteAllMessagesFromConvArgs = {
  convID: Scalars['ID']['input'];
};


export type MutationDeleteMessageArgs = {
  convID: Scalars['ID']['input'];
  datetime: Scalars['ID']['input'];
};


export type MutationDeletePollV2Args = {
  pollId: Scalars['ID']['input'];
};


export type MutationDeletePublicAnnouncementArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDuplicatePollV2Args = {
  pollId: Scalars['ID']['input'];
};


export type MutationEditPollV2Args = {
  editPollInput: EditPollInputV2;
  pollId: Scalars['ID']['input'];
};


export type MutationSendMessageArgs = {
  message: MessageInput;
};


export type MutationSetRaiseHandArgs = {
  convID: Scalars['ID']['input'];
  raised: Scalars['Boolean']['input'];
  userID: Scalars['ID']['input'];
};


export type MutationUnlockRoomArgs = {
  name: Scalars['String']['input'];
  roomKey: Scalars['String']['input'];
};


export type MutationUpdatePollStatusV2Args = {
  pollId: Scalars['ID']['input'];
  status?: InputMaybe<PollStatusV2>;
};


export type MutationUpsertPublicAnnoucementArgs = {
  publicAnnounce: PublicAnnouncementInput;
};


export type MutationWhiteboardCleanEntitiesArgs = {
  room: Scalars['ID']['input'];
};


export type MutationWhiteboardDeleteEntityArgs = {
  id: Scalars['ID']['input'];
  room: Scalars['ID']['input'];
};


export type MutationWhiteboardUpsertEntityArgs = {
  entity: WhiteboardEntityInput;
  room: Scalars['ID']['input'];
};

export type NewPollInputV2 = {
  readonly canUsersSeeResults: Scalars['Boolean']['input'];
  readonly isRespondentsNameRequired?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name: Scalars['String']['input'];
  readonly questions: ReadonlyArray<PollQuestionsInputV2>;
  readonly status: PollStatusV2;
};

export type NewResponseAnswersInputV2 = {
  readonly answerOptionIds: ReadonlyArray<Scalars['String']['input']>;
  readonly customAnswer?: InputMaybe<Scalars['String']['input']>;
  readonly questionId: Scalars['String']['input'];
};

export type NewResponseInputV2 = {
  readonly answers: ReadonlyArray<NewResponseAnswersInputV2>;
  readonly pollId: Scalars['String']['input'];
  readonly respondentsName?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationPaginatedType = {
  readonly __typename: 'OrganisationPaginatedType';
  readonly hasNext?: Maybe<Scalars['Boolean']['output']>;
  readonly hasPrev?: Maybe<Scalars['Boolean']['output']>;
  readonly objects?: Maybe<ReadonlyArray<Maybe<OrganisationType>>>;
  readonly page?: Maybe<Scalars['Int']['output']>;
  readonly pages?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum OrganisationPricingPlan {
  /** Campus */
  Campus = 'CAMPUS',
  /** Schools */
  Schools = 'SCHOOLS',
  /** SuperOrg */
  SuperOrg = 'SUPER_ORG',
  /** SuperOrgLite */
  SuperOrgLite = 'SUPER_ORG_LITE',
  /** Team */
  Team = 'TEAM'
}

/** An enumeration. */
export enum OrganisationSector {
  /** Commercial */
  Commercial = 'COMMERCIAL',
  /** Content Providers */
  ContentProviders = 'CONTENT_PROVIDERS',
  /** Further Education and Adult */
  FurtherEducationAndAdult = 'FURTHER_EDUCATION_AND_ADULT',
  /** Health */
  Health = 'HEALTH',
  /** Higher Education */
  HigherEducation = 'HIGHER_EDUCATION',
  /** Public Sector */
  PublicSector = 'PUBLIC_SECTOR',
  /** Research */
  Research = 'RESEARCH',
  /** Schools */
  Schools = 'SCHOOLS'
}

export type OrganisationType = {
  readonly __typename: 'OrganisationType';
  readonly about?: Maybe<Scalars['String']['output']>;
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly administrators: ReadonlyArray<UserType>;
  readonly analyticsDashboard: Scalars['Boolean']['output'];
  readonly bulkUserImport: Scalars['Boolean']['output'];
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly connectedOrganisations: ReadonlyArray<OrganisationType>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly county?: Maybe<Scalars['String']['output']>;
  readonly dialOutEnabled: Scalars['Boolean']['output'];
  readonly domain?: Maybe<Scalars['String']['output']>;
  readonly hideCollaboration: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isApproved: Scalars['Boolean']['output'];
  readonly latitude?: Maybe<Scalars['Float']['output']>;
  readonly logo?: Maybe<Scalars['String']['output']>;
  readonly longitude?: Maybe<Scalars['Float']['output']>;
  readonly maxAdminAccounts: Scalars['Int']['output'];
  readonly maxGuests: Scalars['Int']['output'];
  readonly maxHostAccounts: Scalars['Int']['output'];
  readonly maxSipConnections: Scalars['Int']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly nrenumEnabled: Scalars['Boolean']['output'];
  readonly operatorSupport: Scalars['Boolean']['output'];
  readonly organisationSet: ReadonlyArray<Maybe<OrganisationType>>;
  readonly organisationsConnected: ReadonlyArray<OrganisationType>;
  readonly parentOrganisation?: Maybe<OrganisationType>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly postcode?: Maybe<Scalars['String']['output']>;
  readonly pricingPlan: OrganisationPricingPlan;
  readonly privateInvites: Scalars['Boolean']['output'];
  readonly rebranding: Scalars['Boolean']['output'];
  readonly recordingDisabled: Scalars['Boolean']['output'];
  readonly recordingEnabled: Scalars['Boolean']['output'];
  readonly region?: Maybe<Scalars['String']['output']>;
  readonly roomsystemSet: ReadonlyArray<RoomSystemObjectType>;
  readonly sector?: Maybe<OrganisationSector>;
  readonly skypeForBusinessEnabled: Scalars['Boolean']['output'];
  readonly userSet: ReadonlyArray<UserType>;
  readonly usesProxy: Scalars['Boolean']['output'];
  readonly webcastEnabled: Scalars['Boolean']['output'];
};

export type PaginatedMessages = {
  readonly __typename: 'PaginatedMessages';
  readonly items?: Maybe<ReadonlyArray<Maybe<Message>>>;
  readonly lastKey?: Maybe<Scalars['ID']['output']>;
};

export type PaginatedPublicAnnoucement = {
  readonly __typename: 'PaginatedPublicAnnoucement';
  readonly items: ReadonlyArray<PublicAnnouncement>;
  readonly lastKey?: Maybe<PublicAnnouncementLastKeyPagination>;
};

export type PollOptionV2 = {
  readonly __typename: 'PollOptionV2';
  readonly id: Scalars['String']['output'];
  readonly isCorrectAnswer?: Maybe<Scalars['Boolean']['output']>;
  readonly value: Scalars['String']['output'];
};

export type PollQuestionOptionsInputV2 = {
  readonly isCorrectAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  readonly value: Scalars['String']['input'];
};

export type PollQuestionV2 = {
  readonly __typename: 'PollQuestionV2';
  readonly allowsCustomAnswer: Scalars['Boolean']['output'];
  readonly allowsMultipleAnswers: Scalars['Boolean']['output'];
  readonly answerOptions: ReadonlyArray<PollOptionV2>;
  readonly id: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

export type PollQuestionsInputV2 = {
  readonly allowsCustomAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  readonly allowsMultipleAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  readonly answerOptions: ReadonlyArray<PollQuestionOptionsInputV2>;
  readonly value: Scalars['String']['input'];
};

export type PollStatusHistoryObjectV2 = {
  readonly __typename: 'PollStatusHistoryObjectV2';
  readonly date: Scalars['String']['output'];
  readonly status: PollStatusV2;
};

export enum PollStatusV2 {
  Draft = 'DRAFT',
  Ended = 'ENDED',
  Published = 'PUBLISHED'
}

export type PollV2 = {
  readonly __typename: 'PollV2';
  readonly canUsersSeeResults: Scalars['Boolean']['output'];
  readonly closingDate?: Maybe<Scalars['String']['output']>;
  readonly creationDate: Scalars['String']['output'];
  readonly creator: Scalars['Int']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isRespondentsRequired: Scalars['Boolean']['output'];
  readonly maxRespondents: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly questions: ReadonlyArray<PollQuestionV2>;
  readonly respondentsNamesUsed: ReadonlyArray<Scalars['String']['output']>;
  readonly status: PollStatusV2;
  readonly statusHistory: ReadonlyArray<PollStatusHistoryObjectV2>;
  readonly voteCount: Scalars['Int']['output'];
};

export enum PrivacyFilter {
  All = 'ALL',
  Organisation = 'ORGANISATION',
  Public = 'PUBLIC'
}

export type PublicAnnouncement = {
  readonly __typename: 'PublicAnnouncement';
  readonly content: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly organisationID?: Maybe<Scalars['String']['output']>;
  readonly startDate: Scalars['String']['output'];
};

export type PublicAnnouncementInput = {
  readonly content: Scalars['String']['input'];
  readonly endDate?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  readonly organisationID?: InputMaybe<Scalars['String']['input']>;
  readonly startDate?: InputMaybe<Scalars['String']['input']>;
};

export type PublicAnnouncementLastKeyPagination = {
  readonly __typename: 'PublicAnnouncementLastKeyPagination';
  readonly endDate: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly orgID: Scalars['String']['output'];
};

export type PublicAnnouncementLastKeyPaginationInput = {
  readonly endDate: Scalars['String']['input'];
  readonly id: Scalars['ID']['input'];
  readonly orgID: Scalars['String']['input'];
};

export type Query = {
  readonly __typename: 'Query';
  readonly activePublicAnnouncements: ReadonlyArray<PublicAnnouncement>;
  readonly allApprovedOrganisations?: Maybe<OrganisationPaginatedType>;
  readonly allFutureMeetings?: Maybe<MeetingPaginatedType>;
  readonly allPublicAnnouncements?: Maybe<PaginatedPublicAnnoucement>;
  readonly allRecordings?: Maybe<RecordingSystemPaginatedType>;
  readonly allRoomSystems?: Maybe<RoomSystemPaginatedType>;
  readonly allUsers?: Maybe<UserPaginatedType>;
  readonly debug?: Maybe<DjangoDebug>;
  readonly getAllRoomInfo: GetAllRoomInfoRes;
  readonly getFeaturesByRoomId: GetFeaturesByRoomIdRes;
  readonly getMyPollsV2: GetMyPollsResV2;
  readonly getPollV2: PollV2;
  readonly getRaisedHands: ReadonlyArray<RaisedHand>;
  readonly getResponsesByPollIdV2: ResponsesByPollIdResV2;
  readonly isRoomUnlocked: Scalars['Boolean']['output'];
  readonly meeting?: Maybe<MeetingType>;
  /** Get messages from conv, lastKey from previous query for next page, empty key on answer means no more messages */
  readonly messagesPaginated?: Maybe<PaginatedMessages>;
  readonly myOrgRoomSystems?: Maybe<RoomSystemPaginatedType>;
  readonly myOrganisationUsers?: Maybe<UserPaginatedType>;
  readonly myRoomSystems?: Maybe<RoomSystemPaginatedType>;
  readonly nonApprovedOrganisations?: Maybe<OrganisationPaginatedType>;
  readonly organisation?: Maybe<OrganisationType>;
  readonly pendingUsers?: Maybe<UserPaginatedType>;
  readonly publicRoomSystems?: Maybe<RoomSystemPaginatedType>;
  readonly recording?: Maybe<RecordingSystemType>;
  readonly room?: Maybe<RoomObjectType>;
  readonly roomSystem?: Maybe<RoomSystemObjectType>;
  readonly user?: Maybe<UserType>;
  readonly whiteboard?: Maybe<ReadonlyArray<Maybe<WhiteboardEntity>>>;
};


export type QueryAllApprovedOrganisationsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllFutureMeetingsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllPublicAnnouncementsArgs = {
  endDateAfter?: InputMaybe<Scalars['String']['input']>;
  lastKey?: InputMaybe<PublicAnnouncementLastKeyPaginationInput>;
  organisationID?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllRecordingsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllRoomSystemsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllUsersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAllRoomInfoArgs = {
  roomId: Scalars['ID']['input'];
};


export type QueryGetFeaturesByRoomIdArgs = {
  roomId: Scalars['ID']['input'];
};


export type QueryGetMyPollsV2Args = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPollV2Args = {
  pollId: Scalars['ID']['input'];
};


export type QueryGetRaisedHandsArgs = {
  convID: Scalars['ID']['input'];
};


export type QueryGetResponsesByPollIdV2Args = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pollId: Scalars['ID']['input'];
};


export type QueryIsRoomUnlockedArgs = {
  roomKey: Scalars['String']['input'];
};


export type QueryMeetingArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessagesPaginatedArgs = {
  convID: Scalars['ID']['input'];
  lastKey?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMyOrgRoomSystemsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  includeChilds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  privacy?: InputMaybe<PrivacyFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMyOrganisationUsersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMyRoomSystemsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNonApprovedOrganisationsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganisationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPendingUsersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPublicRoomSystemsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRecordingArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRoomArgs = {
  extension?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRoomSystemArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWhiteboardArgs = {
  room: Scalars['ID']['input'];
};

export type RaisedHand = {
  readonly __typename: 'RaisedHand';
  readonly raisedAt: Scalars['String']['output'];
  readonly user: Scalars['ID']['output'];
};

export type RecordingSystemPaginatedType = {
  readonly __typename: 'RecordingSystemPaginatedType';
  readonly hasNext?: Maybe<Scalars['Boolean']['output']>;
  readonly hasPrev?: Maybe<Scalars['Boolean']['output']>;
  readonly objects?: Maybe<ReadonlyArray<Maybe<RecordingSystemType>>>;
  readonly page?: Maybe<Scalars['Int']['output']>;
  readonly pages?: Maybe<Scalars['Int']['output']>;
};

export type RecordingSystemType = {
  readonly __typename: 'RecordingSystemType';
  readonly availableTo: ReadonlyArray<UserType>;
  readonly duration?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly owner?: Maybe<UserType>;
  readonly playbackUrl?: Maybe<Scalars['String']['output']>;
  readonly room?: Maybe<RoomObjectType>;
  readonly startTime: Scalars['DateTime']['output'];
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  readonly vidyoreplayId?: Maybe<Scalars['String']['output']>;
};

export type ResponseV2 = {
  readonly __typename: 'ResponseV2';
  readonly answers: ReadonlyArray<AnswerV2>;
  readonly creationDate: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly pollId: Scalars['String']['output'];
  readonly respondentsName?: Maybe<Scalars['String']['output']>;
};

export type ResponsesByPollIdResV2 = {
  readonly __typename: 'ResponsesByPollIdResV2';
  readonly count: Scalars['Int']['output'];
  readonly items: ReadonlyArray<ResponseV2>;
};

export type RoomInfo = {
  readonly __typename: 'RoomInfo';
  readonly blockExternalGuests?: Maybe<Scalars['Boolean']['output']>;
  readonly createdAt?: Maybe<Scalars['String']['output']>;
  readonly displayName?: Maybe<Scalars['String']['output']>;
  readonly extension?: Maybe<Scalars['String']['output']>;
  readonly isClassview?: Maybe<Scalars['Boolean']['output']>;
  readonly key?: Maybe<Scalars['ID']['output']>;
  readonly moderators?: Maybe<ReadonlyArray<Scalars['Int']['output']>>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly newKey?: Maybe<Scalars['Boolean']['output']>;
  readonly owner?: Maybe<Scalars['String']['output']>;
  readonly pin?: Maybe<Scalars['String']['output']>;
  readonly prioritiseSharedScreenRecording?: Maybe<Scalars['Boolean']['output']>;
  readonly screenSharingParticipant?: Maybe<Scalars['Boolean']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly waitingRoomEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type RoomObjectType = {
  readonly __typename: 'RoomObjectType';
  readonly blockExternalGuests: Scalars['Boolean']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly displayName: Scalars['String']['output'];
  readonly extension?: Maybe<Scalars['String']['output']>;
  readonly isClassview: Scalars['Boolean']['output'];
  readonly key: Scalars['String']['output'];
  readonly meetingSet: ReadonlyArray<MeetingType>;
  readonly moderators: ReadonlyArray<UserType>;
  readonly name: Scalars['String']['output'];
  readonly newKey?: Maybe<Scalars['String']['output']>;
  readonly owner?: Maybe<UserType>;
  readonly pin?: Maybe<Scalars['Int']['output']>;
  readonly prioritiseSharedScreenRecording: Scalars['Boolean']['output'];
  readonly recordingRoom: ReadonlyArray<RecordingSystemType>;
  readonly screenSharingParticipants: Scalars['Boolean']['output'];
  readonly type: RoomType;
  readonly userHubs: ReadonlyArray<UserType>;
  readonly waitingRoomEnabled: Scalars['Boolean']['output'];
};

export type RoomSystemDialType = {
  readonly __typename: 'RoomSystemDialType';
  readonly dialOut: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly meetingRoomSystems: ReadonlyArray<MeetingType>;
  readonly roomSystem?: Maybe<RoomSystemObjectType>;
};

export type RoomSystemObjectType = {
  readonly __typename: 'RoomSystemObjectType';
  readonly id: Scalars['ID']['output'];
  readonly ip?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly notificationEmail: Scalars['String']['output'];
  readonly onlineStatus?: Maybe<Scalars['String']['output']>;
  readonly organisation?: Maybe<OrganisationType>;
  readonly privacy: RoomSystemPrivacy;
  readonly roomsystemdialSet: ReadonlyArray<RoomSystemDialType>;
  readonly type: RoomSystemType;
};

export type RoomSystemPaginatedType = {
  readonly __typename: 'RoomSystemPaginatedType';
  readonly hasNext?: Maybe<Scalars['Boolean']['output']>;
  readonly hasPrev?: Maybe<Scalars['Boolean']['output']>;
  readonly objects?: Maybe<ReadonlyArray<Maybe<RoomSystemObjectType>>>;
  readonly page?: Maybe<Scalars['Int']['output']>;
  readonly pages?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum RoomSystemPrivacy {
  /** Organisation */
  Organisation = 'ORGANISATION',
  /** Public */
  Public = 'PUBLIC'
}

/** An enumeration. */
export enum RoomSystemType {
  /** H.323/E.164/IP */
  H323 = 'H323',
  /** SIP */
  Sip = 'SIP',
  /** V-Room */
  Vsceneroom = 'VSCENEROOM'
}

/** An enumeration. */
export enum RoomType {
  /** hub */
  Hub = 'HUB',
  /** lecture_mode */
  LectureMode = 'LECTURE_MODE',
  /** meeting */
  Meeting = 'MEETING',
  /** webcast */
  Webcast = 'WEBCAST'
}

export type RoomTypeLinking = {
  readonly __typename: 'RoomTypeLinking';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly lecturers?: Maybe<ReadonlyArray<Scalars['Int']['output']>>;
  readonly organisation?: Maybe<ReadonlyArray<Scalars['Int']['output']>>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  readonly __typename: 'Subscription';
  /** all messages from conv to be removed */
  readonly allMessagesFromConvDeleted?: Maybe<ConversationCleared>;
  readonly getRaisedHandsUpdate: ReadonlyArray<RaisedHand>;
  readonly isRoomUnlockedUpdate: UnlockBy;
  /** message deleted to remove from collab */
  readonly messageDeletedInCall?: Maybe<MessageDeleted>;
  /** New message on collab conversation for a specific room */
  readonly newMessageInCall?: Maybe<Message>;
  readonly whiteboardUpdate?: Maybe<WhiteboardUpdate>;
};


export type SubscriptionAllMessagesFromConvDeletedArgs = {
  convID: Scalars['ID']['input'];
};


export type SubscriptionGetRaisedHandsUpdateArgs = {
  convID: Scalars['ID']['input'];
  userID: Scalars['ID']['input'];
};


export type SubscriptionIsRoomUnlockedUpdateArgs = {
  roomKey: Scalars['String']['input'];
};


export type SubscriptionMessageDeletedInCallArgs = {
  convID: Scalars['ID']['input'];
};


export type SubscriptionNewMessageInCallArgs = {
  convID: Scalars['ID']['input'];
};


export type SubscriptionWhiteboardUpdateArgs = {
  room: Scalars['ID']['input'];
};

export type UnlockBy = {
  readonly __typename: 'UnlockBy';
  readonly name: Scalars['String']['output'];
  readonly unlocked: Scalars['Boolean']['output'];
};

export type UserPaginatedType = {
  readonly __typename: 'UserPaginatedType';
  readonly hasNext?: Maybe<Scalars['Boolean']['output']>;
  readonly hasPrev?: Maybe<Scalars['Boolean']['output']>;
  readonly objects?: Maybe<ReadonlyArray<Maybe<UserType>>>;
  readonly page?: Maybe<Scalars['Int']['output']>;
  readonly pages?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum UserProfile {
  /** Protected */
  Protected = 'PROTECTED',
  /** Public */
  Public = 'PUBLIC'
}

/** An enumeration. */
export enum UserRecordingQuality {
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM'
}

export type UserType = {
  readonly __typename: 'UserType';
  readonly acceptedPrivacyPolicy: Scalars['Boolean']['output'];
  readonly acceptedPrivacyPolicyDate?: Maybe<Scalars['DateTime']['output']>;
  readonly authenticationCode: Scalars['String']['output'];
  readonly authenticationTimestamp: Scalars['Int']['output'];
  readonly bio?: Maybe<Scalars['String']['output']>;
  readonly email: Scalars['String']['output'];
  readonly emailAuthenticated: Scalars['Boolean']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly hubs: ReadonlyArray<RoomObjectType>;
  readonly id: Scalars['ID']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly isAdmin: Scalars['Boolean']['output'];
  readonly isSuperuser: Scalars['Boolean']['output'];
  readonly jobTitle?: Maybe<Scalars['String']['output']>;
  readonly lastName: Scalars['String']['output'];
  readonly meetingLecturers: ReadonlyArray<MeetingType>;
  readonly meetingModerators: ReadonlyArray<MeetingType>;
  readonly meetingParticipants: ReadonlyArray<MeetingType>;
  readonly meetingSet: ReadonlyArray<MeetingType>;
  readonly optIn: Scalars['Boolean']['output'];
  readonly organisation?: Maybe<OrganisationType>;
  readonly organisationAdministrators: ReadonlyArray<OrganisationType>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly profile: UserProfile;
  readonly profilePicture?: Maybe<Scalars['String']['output']>;
  readonly recordingOwner: ReadonlyArray<RecordingSystemType>;
  readonly recordingQuality: UserRecordingQuality;
  readonly recordingsRecordingModerators: ReadonlyArray<RecordingSystemType>;
  readonly roomsRoomModerators: ReadonlyArray<RoomObjectType>;
  readonly roomsRoomOwner: ReadonlyArray<RoomObjectType>;
  readonly skills?: Maybe<Scalars['String']['output']>;
  readonly website?: Maybe<Scalars['String']['output']>;
};

export type WhiteboardEntity = {
  readonly __typename: 'WhiteboardEntity';
  readonly author: Scalars['String']['output'];
  readonly color: Scalars['String']['output'];
  readonly fillColor?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly origin?: Maybe<WhiteboardPoint>;
  readonly points?: Maybe<ReadonlyArray<Maybe<WhiteboardPoint>>>;
  readonly radius?: Maybe<Scalars['Int']['output']>;
  readonly size?: Maybe<Scalars['Int']['output']>;
  readonly target?: Maybe<WhiteboardTarget>;
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
};

export type WhiteboardEntityInput = {
  readonly author: Scalars['String']['input'];
  readonly color: Scalars['String']['input'];
  readonly fillColor?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  readonly origin?: InputMaybe<WhiteboardPointInput>;
  readonly points?: InputMaybe<ReadonlyArray<InputMaybe<WhiteboardPointInput>>>;
  readonly radius?: InputMaybe<Scalars['Int']['input']>;
  readonly size?: InputMaybe<Scalars['Int']['input']>;
  readonly target?: InputMaybe<WhiteboardTargetInput>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
  readonly type: Scalars['String']['input'];
};

export type WhiteboardPoint = {
  readonly __typename: 'WhiteboardPoint';
  readonly x: Scalars['Int']['output'];
  readonly y: Scalars['Int']['output'];
};

export type WhiteboardPointInput = {
  readonly x: Scalars['Int']['input'];
  readonly y: Scalars['Int']['input'];
};

export type WhiteboardTarget = {
  readonly __typename: 'WhiteboardTarget';
  readonly begin: WhiteboardPoint;
  readonly end: WhiteboardPoint;
};

export type WhiteboardTargetInput = {
  readonly begin: WhiteboardPointInput;
  readonly end: WhiteboardPointInput;
};

export type WhiteboardUpdate = {
  readonly __typename: 'WhiteboardUpdate';
  readonly entity?: Maybe<WhiteboardEntity>;
  readonly id?: Maybe<Scalars['ID']['output']>;
  readonly room: Scalars['ID']['output'];
  readonly type?: Maybe<WhiteboardUpdateType>;
};

export enum WhiteboardUpdateType {
  Clean = 'CLEAN',
  Delete = 'DELETE',
  Upsert = 'UPSERT'
}
