import { FormEvent, useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  //HR,
  //InputOption,
  //Toggle,
  //ToggleOption,
  //Tooltip,
  Cell,
  Field,
  Flexbox,
  Grid,
  H1,
  H2,
  Icon,
  IconButton,
  Input,
  TextArea,
  TextButton,
} from "packages/catalog";
import {
  //EAttachment,
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  PATH_POLL,
} from "packages/utils";

import { CreatePollMutation, useCreatePollMutation } from "packages/client/polls/graphql/polls.generated";
import { PollStatusV2 } from "packages/client/graphql_definitions.generated";

import { usePoll } from "packages/client/polls/hooks";

import { FullScreenContainer, RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./CreatePoll.module.scss";

export interface IPollOption {
  isCorrectAnswer: boolean;
  value: string;
}

export function CreatePoll() {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState<IPollOption[]>([
    { value: "", isCorrectAnswer: false },
    { value: "", isCorrectAnswer: false },
  ]);
  //const [markAnswersAsCorrect, setMarkAnswersAsCorrect] = useState(false);
  //const [allowOtherOption, setAllowOtherOption] = useState(false);
  //const [allowMultipleOptions, setAllowMultipleOptions] = useState(false);
  //const [endDate, setEndDate] = useState("");
  //const [neverEnds, setNeverEnds] = useState(false);
  //const [showResults, setShowResults] = useState(false);
  //const [requireName, setRequireName] = useState(false);

  usePoll();
  const navigate = useNavigate();
  const [createPollMutation, { loading }] = useCreatePollMutation({
    onCompleted: (data: CreatePollMutation) => navigate(PATH_POLL(data.createPollV2.id)),
  });

  const handleOptionChange = useCallback(
    (key: keyof IPollOption) => (newValue: IPollOption[keyof IPollOption], index: number) => {
      const changedOption = { ...options[index], [key]: newValue };
      const updatedOptions = [...options];
      updatedOptions.splice(index, 1, changedOption);
      setOptions(updatedOptions);
    },
    [options, setOptions],
  );

  const handleValueChange = useMemo(() => handleOptionChange("value"), [handleOptionChange]);
  //const handleCorrectChange = useMemo(() => handleOptionChange("isCorrectAnswer"), [handleOptionChange]);

  const handleDeleteOption = useCallback(
    (index: number) => {
      const updatedOptions = [...options];
      updatedOptions.splice(index, 1);
      setOptions(updatedOptions);
    },
    [options, setOptions],
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const allOptions = options.filter(option => option.value.trim().length);
      // const answerOptions = markAnswersAsCorrect ? allOptions : allOptions.map(option => ({ value: option.value }));
      const answerOptions = allOptions.map(option => ({ value: option.value }));
      createPollMutation({
        variables: {
          pollInput: {
            name: question,
            questions: [
              {
                value: question,
                // allowsMultipleAnswers: allowMultipleOptions,
                // allowsCustomAnswer: allowOtherOption,
                answerOptions,
              },
            ],
            status: PollStatusV2.Draft,
            // isRespondentsNameRequired: requireName,
            // canUsersSeeResults: showResults,
            canUsersSeeResults: false,
          },
        },
        refetchQueries: ["getAllPolls"],
      });
    },
    [
      // allowMultipleOptions,
      // allowOtherOption,
      createPollMutation,
      options,
      // markAnswersAsCorrect,
      // requireName,
      // showResults,
      question,
    ],
  );

  // const tooltipTrigger = useMemo(
  //   () => (
  //     <IconButton
  //       appearance={EAppearance.GHOST}
  //       as="button"
  //       description=""
  //       dimension={EDimension.SMALL}
  //       icon={EIcon.INFO}
  //       status={EStatus.ACCENT}
  //     />
  //   ),
  //   [],
  // );

  // const disabled = useMemo(
  //   () =>
  //     (markAnswersAsCorrect &&
  //       options.every(option => !option.isCorrectAnswer)) ||
  //     !question.trim().length ||
  //     !options[0].value.trim().length ||
  //     !options[1].value.trim().length,
  //   [options, markAnswersAsCorrect, question.length],
  // );
  const disabled = useMemo(
    () => !question.trim().length || !options[0].value.trim().length || !options[1].value.trim().length || loading,
    [question, options, loading],
  );

  return (
    <FullScreenContainer
      closeTo=".."
      heading={
        <H1 hasMargin icon={<Icon icon={EIcon.BARCHART2} />}>
          Create poll
        </H1>
      }>
      <RoutingChangeHandler message="The create poll page has loaded." pageTitle="Create poll" />
      <Grid as="form" noColGap onSubmit={handleSubmit}>
        <Cell as="section">
          <H2 hasMargin>Poll details</H2>
          <Field label="Question">
            <TextArea
              maxLength={240}
              name="question"
              onChange={event => setQuestion(event.target.value)}
              placeholder="e.g. What's your favourite colour?"
              required
              rows={2}
              value={question}
            />
          </Field>
        </Cell>
        <Cell>
          <Grid as="ul" noColGap>
            <>
              {options.map((option, index) => (
                <Cell as="li" className={styles.option} key={`Option${1 + index}`}>
                  <Field label={`Option ${1 + index}`}>
                    <Input
                      autoFocus
                      maxLength={240}
                      onChange={event => handleValueChange(event.target.value, index)}
                      required={index < 2}
                      type="text"
                      value={option.value}
                    />
                  </Field>
                  {/* {(options.length > 2 || markAnswersAsCorrect) && ( */}
                  {options.length > 2 && (
                    <div className={styles.optionControls}>
                      {/* {markAnswersAsCorrect && (
                        <div className={styles.checkbox}>
                          <label className={styles.label} htmlFor={`correct${index}`}>
                            Mark as correct
                          </label>
                          <Input
                            type="checkbox"
                            checked={option.isCorrectAnswer}
                            name={`correct${index}`}
                            onChange={() => handleCorrectChange(!option.isCorrectAnswer, index)}
                          />
                        </div>
                      )} */}
                      {index >= 2 && (
                        <span className={styles.trash}>
                          <IconButton
                            as="button"
                            appearance={EAppearance.GHOST}
                            description="Delete option"
                            dimension={EDimension.SMALL}
                            icon={EIcon.TRASH2}
                            onClick={() => handleDeleteOption(index)}
                            status={EStatus.NEUTRAL}
                            type="button"
                          />
                        </span>
                      )}
                    </div>
                  )}
                </Cell>
              ))}
            </>
          </Grid>
        </Cell>
        <Cell>
          <IconButton
            as="button"
            appearance={EAppearance.GHOST}
            description="Add an option"
            dimension={EDimension.SMALL}
            disabled={options.length >= 50}
            icon={EIcon.PLUS}
            label="Add an option"
            onClick={() => setOptions([...options, { value: "", isCorrectAnswer: false }])}
            status={EStatus.NEUTRAL}
            type="button"
          />
        </Cell>
        <div className={styles.buttons}>
          <Flexbox className={styles.buttonsContainer} justifyContentEnd>
            <TextButton appearance={EAppearance.GHOST} as={Link} status={EStatus.NEUTRAL} to={".."}>
              Cancel
            </TextButton>
            <Input disabled={disabled} name="submit" type="submit" value="Create" />
          </Flexbox>
        </div>
        {/* <Cell>
          <Grid as="ul">
            <Cell as="li">
              <ToggleOption
                label={
                  <>
                    <span>Mark answer(s) as correct</span>
                    {allowOtherOption && (
                      <Tooltip
                        attachment={EAttachment.EAST_SOUTH_EAST}
                        targetName="Mark answer(s) as correct"
                        tip="It's not possible to mark answer(s) as correct when the 'Other' option is added."
                        trigger={tooltipTrigger}
                      />
                    )}
                  </>
                }>
                <Toggle
                  checked={markAnswersAsCorrect}
                  disabled={allowOtherOption}
                  id="markAnswersCorrectToggle"
                  onChange={() => setMarkAnswersAsCorrect(!markAnswersAsCorrect)}
                />
              </ToggleOption>
            </Cell>
            <Cell as="li">
              <ToggleOption
                label={
                  <>
                    <span>Add an &apos;Other&apos; option for participants to fill out</span>
                    {markAnswersAsCorrect && (
                      <Tooltip
                        attachment={EAttachment.EAST_SOUTH_EAST}
                        targetName="Other option"
                        tip="The 'Other' option can't be selected when one or more answers are marked as correct."
                        trigger={tooltipTrigger}
                      />
                    )}
                  </>
                }>
                <Toggle
                  checked={allowOtherOption}
                  disabled={markAnswersAsCorrect}
                  id="allowOtherOption"
                  onChange={() => setAllowOtherOption(!allowOtherOption)}
                />
              </ToggleOption>
            </Cell>
            <Cell as="li">
              <ToggleOption label="Allow participants to select multiple options">
                <Toggle
                  checked={allowMultipleOptions}
                  onChange={() => setAllowMultipleOptions(!allowMultipleOptions)}
                />
              </ToggleOption>
            </Cell>
          </Grid>
        </Cell> */}
        {/* <Cell>
            <HR />
        </Cell>
        <Cell as="section">
            <H2>End date</H2>
        </Cell>
        <Cell className={styles.endDate}>
            <Field label="End date">
                <Input
                    max={new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toISOString().substring(0, 10)}
                    min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0, 10)}
                    onChange={event => setEndDate(event.target.value)}
                    type="date"
                    value={endDate}
                />
            </Field>
            <span className={styles.checkbox}>
                <InputOption label="Never ends">
                    <Input checked={neverEnds} type="checkbox" onChange={() => setNeverEnds(!neverEnds)}/>
                </InputOption>
            </span>
        </Cell> */}
        {/* <Cell>
          <HR />
        </Cell>
        <Cell as="section">
          <H2 hasMargin>Advanced options</H2>
          <Grid as="ul">
            <Cell as="li">
              <ToggleOption label="Show poll results to participants">
                <Toggle checked={showResults} onChange={() => setShowResults(!showResults)} />
              </ToggleOption>
            </Cell>
            <Cell as="li">
              <ToggleOption label="Require participants to enter their name">
                <Toggle checked={requireName} onChange={() => setRequireName(!requireName)} />
              </ToggleOption>
            </Cell>
          </Grid>
        </Cell> */}
      </Grid>
    </FullScreenContainer>
  );
}
