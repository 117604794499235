import { ChangeEvent, useCallback, useEffect, useState } from "react";

import {
  Cell,
  ConfirmActionModal,
  Grid,
  Feedback,
  H1,
  Input,
  Label,
  P,
  Pagination,
  Table,
  TRTDTemp,
} from "packages/catalog";
import { EStatus, scrollToContentTop } from "packages/utils";

import { useGetSessionsQuery } from "packages/client/sessions/graphql/sessions.generated";
import { deleteSession } from "packages/client/sessions/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SuperAdminSessionRow } from "packages/client/sessions/components";

const COLUMNS = ["ID", "Next", "Name", "Owner", "Organisation"];
const COLUMN_WIDTHS = "4rem 20rem 1.5fr 1fr 1.5fr 2rem";

export function SuperAdminSessions() {
  const [actionID, setActionID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchString, setDebounceSearchString] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { data: allSessionsData, refetch } = useGetSessionsQuery({
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    variables: { page: currentPage, search: debouncedSearchString },
  });

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value);
  }, []);

  const handleDeleteSession = useCallback(() => {
    setSuccessMessage("");
    setErrorMessage("");

    scrollToContentTop();
    setIsDeleteModalOpen(false);

    deleteSession(actionID)
      .then(() => {
        refetch();
        setSuccessMessage("Session deleted.");
      })
      .catch(reason => {
        setErrorMessage(reason.message);
      });

    setActionID("");
  }, [actionID, refetch]);

  const prepareDeleteSession = useCallback((userID: string) => {
    setActionID(userID);
    setIsDeleteModalOpen(true);
  }, []);

  useEffect(() => {
    let cancelled = false;

    window.setTimeout(() => {
      if (!cancelled) setDebounceSearchString(searchString);
    }, 500);

    return () => {
      cancelled = true;
    };
  }, [searchString]);

  return (
    <>
      <RoutingChangeHandler message="The sessions page has loaded." pageTitle="Sessions" />
      <Grid noColGap>
        {errorMessage.length ? (
          <Cell>
            <Feedback status={EStatus.DANGER}>{errorMessage}</Feedback>
          </Cell>
        ) : null}
        {successMessage.length ? (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>{successMessage}</Feedback>
          </Cell>
        ) : null}
        <Cell>
          <H1>Sessions</H1>
        </Cell>
        <Cell>
          <Label htmlFor="search" isVisuallyHidden text="Search sessions" />
          <Input id="search" name="search" onChange={handleChangeSearch} type="search" value={searchString} />
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing all sessions"
            hasMenuColumn>
            {allSessionsData?.allFutureMeetings?.objects?.length ? (
              <>
                {allSessionsData?.allFutureMeetings?.objects?.map(session => (
                  <SuperAdminSessionRow
                    columns={COLUMNS}
                    columnWidths={COLUMN_WIDTHS}
                    endTime={session.endTime}
                    key={session.id}
                    nextRecurringEndTime={session.nextRecurringEnd}
                    nextRecurringStartTime={session.nextRecurringStart}
                    organisationID={session.meetingOrganiser.organisation.id}
                    organisationName={session.meetingOrganiser.organisation.name}
                    ownerID={session.meetingOrganiser.id}
                    ownerFullName={`${session.meetingOrganiser.firstName} ${session.meetingOrganiser.lastName}`}
                    prepareDeleteSession={prepareDeleteSession}
                    roomKey={session.room?.key}
                    sessionID={session.id}
                    startTime={session.startTime}
                    subject={session.subject}
                  />
                ))}
              </>
            ) : (
              <TRTDTemp>
                <P center>No upcoming sessions.</P>
              </TRTDTemp>
            )}
          </Table>
        </Cell>
        {allSessionsData?.allFutureMeetings?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={allSessionsData?.allFutureMeetings?.hasNext}
              hasPrevious={allSessionsData?.allFutureMeetings?.hasPrev}
              page={allSessionsData?.allFutureMeetings?.page}
              pages={allSessionsData?.allFutureMeetings?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="session"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteSession}
        status={EStatus.DANGER}
      />
    </>
  );
}
