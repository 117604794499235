import { useCallback, useEffect, useState } from "react";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";

import { Block, Cell, Description, ExternalTextLink, Grid, H1, H2, Illustration, P } from "packages/catalog";
import { EIllustration, TParams, PATH_PRECALL_JOINCALL, PATH_TLD } from "packages/utils";

import { useTypedSelector } from "packages/client/redux";

import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./JoinMethod.module.scss";

async function generateRoomLink(roomKey: string, token: string, moderate?: boolean) {
  const { EDeepLinkingAction, generateNativeURL } = await import("packages/native/src/deepLinking");

  return generateNativeURL({
    action: EDeepLinkingAction.Call,
    params: { roomkey: roomKey, token, ...(moderate && { moderate }) },
    protocol: PREFIX_PROTOCOL,
  });
}

export function JoinMethod() {
  const [nativeLink, setNativeLink] = useState<string>(null);
  const { callID }: TParams = useParams();
  const { token } = useTypedSelector(({ authentication }) => ({
    token: authentication.token,
  }));
  const { state }: { state?: { moderate?: boolean } } = useLocation();
  const moderate = state?.moderate ?? false;

  const handleOnClickDeepLink = useCallback(() => {
    window.open(nativeLink);
  }, [nativeLink]);

  useEffect(() => {
    if (!callID) return;

    let cancelled = false;

    async function getLink() {
      const u = await generateRoomLink(callID, token, moderate);
      if (!cancelled) {
        setNativeLink(u);
      }
    }

    getLink();

    return () => {
      cancelled = true;
    };
  }, [callID, moderate, token]);

  // if there's no callID we can't do anything, return to dashboard
  if (!callID) return <Navigate to={PATH_TLD} />;

  // if it's electron, skip this screen and go straight to precall flow
  if (IS_ELECTRON) return <Navigate state={state} to={PATH_PRECALL_JOINCALL(callID)} />;

  return (
    <>
      <RoutingChangeHandler message="The join method page has loaded." pageTitle="Join session" />
      <Grid>
        <Cell>
          <H1 center>How would you like to join your ClassView session?</H1>
        </Cell>
        <Cell colSpanMD={6} style={{ position: "relative" }}>
          <Illustration className={styles.illustration} illustration={EIllustration.ONLINE_LEARNING} />
        </Cell>
        <Cell colSpanMD={6}>
          <Grid noColGap>
            <Cell>
              <Link state={state} to={PATH_PRECALL_JOINCALL(callID)}>
                <Block className={styles.link}>
                  <Illustration className={styles.screen} illustration={EIllustration.CONFERENCE_CALL} />
                  <div>
                    <H2>Continue on this browser</H2>
                    <P>No download needed.</P>
                  </div>
                </Block>
              </Link>
            </Cell>
            <Cell>
              <button className={styles.app} onClick={handleOnClickDeepLink}>
                <Block className={styles.link}>
                  <svg
                    className={styles.windows}
                    id="windows"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 90 90">
                    <path d="m0,12.402,35.687-4.8602,0.0156,34.423-35.67,0.20313z" fill="#f8682c" />
                    <path d="m39.996,6.9059,47.318-6.906,0,41.527-47.318,0.37565z" fill="#91c300" />
                    <path fill="#00b4f1" d="m35.67,45.931,0.0277,34.453-35.67-4.9041-0.002-29.78z" />
                    <path d="m87.326,46.255-0.0111,41.34-47.318-6.6784-0.0663-34.739z" fill="#ffc300" />
                  </svg>
                  <div>
                    <H2>Launch Windows app</H2>
                    <P>The desktop app gives the best experience.</P>
                  </div>
                </Block>
              </button>
              <P center>
                <Description>
                  <ExternalTextLink download href={`https://desktop.classview.com/${APP_INSTALLER_URL}`}>
                    Download app for Windows
                  </ExternalTextLink>
                </Description>
              </P>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </>
  );
}
