import type { HTMLProps, ReactNode } from "react";

import styles from "./TD.module.scss";

export interface TDProps extends HTMLProps<HTMLTableCellElement> {
  children: string | OneOrManyReactChildren<ReactNode>;
  heading?: string;
}

export function TD({ children, heading, ...rest }: TDProps) {
  return (
    <td className={styles.td} data-th={heading} {...rest}>
      <div className={styles.content}>{children}</div>
    </td>
  );
}
