import { useMemo } from "react";

import { Cell, Dialog, Flexbox, Grid, P, TextButton } from "packages/catalog";
import { EAppearance, EStatus, toSentenceCase } from "packages/utils";

import { RoutingChangeHandler } from "packages/client/layout/components";

export interface PConfirmActionModal {
  action: string;
  content: string;
  isOpen: boolean;
  onAbort: () => void;
  onAction: () => void;
  status?: EStatus;
}

export function ConfirmActionModal({ action, content, isOpen, onAbort, onAction, status }: PConfirmActionModal) {
  const capitalisedAction = useMemo(() => toSentenceCase(action), [action]);

  return (
    <Dialog h1={`${capitalisedAction} ${content}`} isOpen={isOpen} onAbort={onAbort}>
      <RoutingChangeHandler message={`The ${action} modal has loaded`} />
      <Grid noColGap>
        <Cell>
          <P hasMargin>
            Are you sure you want to {action} this {content}?
          </P>
          <P>{"You can't undo this action."}</P>
        </Cell>
        <Cell>
          <Flexbox alignItemsCenter justifyContentEnd>
            <TextButton appearance={EAppearance.GHOST} onClick={onAbort} status={EStatus.NEUTRAL}>
              Cancel
            </TextButton>
            <TextButton appearance={EAppearance.FILL} onClick={onAction} status={status ? status : EStatus.ACCENT}>
              {capitalisedAction}
            </TextButton>
          </Flexbox>
        </Cell>
      </Grid>
    </Dialog>
  );
}
