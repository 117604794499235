import { Grid, H2, SidebarBack, SidebarHeader, SidebarMain } from "packages/catalog";

import { DeviceConfiguration } from "packages/client/precall/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

export function DeviceSettings() {
  return (
    <>
      <RoutingChangeHandler message="The device settings have loaded." />
      <SidebarHeader>
        <SidebarBack backTo=".." closeTo="../.." label="Settings" />
        <H2>Devices</H2>
      </SidebarHeader>
      <SidebarMain>
        <Grid noColGap>
          <DeviceConfiguration />
        </Grid>
      </SidebarMain>
    </>
  );
}
