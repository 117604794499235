import { useEffect, useState } from "react";

import type { IDjangoRoomSystemUsage } from "packages/client/room-systems/django/types";

import { fetchDjangoAPIAuthenticated } from "packages/client/fetch";

export function useRoomSystemsUsage() {
  const [roomsUsage, setRoomsUsage] = useState<IDjangoRoomSystemUsage>(null);
  const [isRoomsLimitReached, setIsRoomsLimitReached] = useState(false);

  useEffect(() => {
    let cancelled = false;

    async function getRoomsUsage() {
      if (cancelled) return;

      try {
        const response = await fetchDjangoAPIAuthenticated("/room-systems/usage/", {
          method: "GET",
        });
        const dataDB: IDjangoRoomSystemUsage = await response.json();

        setRoomsUsage(dataDB);
        setIsRoomsLimitReached(dataDB.limit === dataDB.current);
      } catch (err) {
        console.error(err);
      }
    }
    getRoomsUsage();

    return () => {
      cancelled = true;
    };
  }, []);

  return {
    isRoomsLimitReached,
    roomsUsage,
  };
}
