import { EDays } from "packages/client/sessions/redux/types";

export const days = [
  { longName: "Monday", shortName: "Mon", value: EDays.MO },
  { longName: "Tuesday", shortName: "Tue", value: EDays.TU },
  { longName: "Wednesday", shortName: "Wed", value: EDays.WE },
  { longName: "Thursday", shortName: "Thu", value: EDays.TH },
  { longName: "Friday", shortName: "Fri", value: EDays.FR },
  { longName: "Saturday", shortName: "Sat", value: EDays.SA },
  { longName: "Sunday", shortName: "Sun", value: EDays.SU },
];
