import { Cell, Flexbox, H1, P, TextButton } from "packages/catalog";
import { EAppearance, EStatus } from "packages/utils";

import type { Screens } from "packages/client/onboarding/enums";

import { OrgLookupContainer, SScreen } from "packages/client/onboarding/containers";

import { RoutingChangeHandler } from "packages/client/layout/components";

export interface PChooseOrg {
  cache: SScreen;
  goTo: (screen: Screens.CreateOrgStep1) => void;
  isRegistrationLoading: boolean;
  onChange: (e: { selected: { id: number; name: string } }) => void;
  onPrevious: () => void;
  onSubmit: () => void;
}

export function ChooseOrg({ cache, isRegistrationLoading, onChange, onPrevious, onSubmit }: PChooseOrg) {
  return (
    <>
      <RoutingChangeHandler message="The join organisation page has loaded." pageTitle="Join organisation" />
      <Cell>
        <H1 center hasMargin>
          Join an organisation
        </H1>
        <P>Find and select your organisation.</P>
      </Cell>
      <Cell>
        <OrgLookupContainer title="Organisation" onChange={selected => onChange({ selected })} />
      </Cell>
      <Cell>
        <Flexbox justifyContentSpaceBetween>
          <TextButton appearance={EAppearance.GHOST} as="button" onClick={onPrevious}>
            Back
          </TextButton>
          <TextButton
            disabled={!cache.isValid || isRegistrationLoading}
            onClick={cache.isValid ? onSubmit : undefined}
            status={EStatus.ACCENT}>
            Create your account
          </TextButton>
        </Flexbox>
      </Cell>
    </>
  );
}
