import { EVidyoStatusActions } from "../redux/types";

import { dispatchVidyo } from "../../redux";
import { EVidyoStreamActions } from "../../streams/redux/types";
import type { EVidyoDeviceStatus } from "../enums";
import type { TStatusableDevice, TVidyoConnector } from "../types";
import type { TVidyoStream, TVidyoVideoStream } from "../../streams/types";
import type { EStream } from "../../streams/enums";
import { EDevice } from "../../local-devices/enums/EDevice";
import { EVideoStream } from "../../streams/enums";
import type { TVidyoParticipant } from "../../participants/types/TVidyoParticipant";

function logStatusUpdate<T extends EStream>(
  remoteStream: TVidyoStream<T>,
  participant: TVidyoParticipant,
  state: string,
) {
  if (NODE_ENV !== "production") console.info("statusChange", remoteStream, participant, state);
}

function remoteHandler<T extends EStream>(streamType?: EVideoStream, deviceType?: TStatusableDevice) {
  return {
    onAdded: (remoteDevice: TVidyoStream<T>, participant: TVidyoParticipant) => {
      if (streamType) {
        // let isPtz = false;
        // if (deviceType && deviceType === DEVICE_TYPE.CAMERA) {
        //   isPtz = await(remoteDevice as RemoteCamera).IsControllable();
        // }
        dispatchVidyo({
          type: EVidyoStreamActions.ADD_STREAM,
          stream: {
            streamType,
            stream: remoteDevice as TVidyoVideoStream<typeof streamType>,
            participantUserID: participant.userId,
          },
          // isPtz,
        });
      }
      if (deviceType)
        dispatchVidyo({
          type: EVidyoStreamActions.ADD_REMOTE_DEVICE,
          participantUserID: participant.userId,
          deviceStatus: {
            deviceId: remoteDevice.id,
            deviceName: remoteDevice.name,
            deviceType,
          },
        });
    },
    onRemoved: (remoteDevice: TVidyoStream<T>, participant: TVidyoParticipant) => {
      if (streamType)
        dispatchVidyo({
          type: EVidyoStreamActions.REMOVE_STREAM,
          stream: {
            streamType,
            stream: remoteDevice as TVidyoVideoStream<typeof streamType>,
            participantUserID: participant.userId,
          },
        });
      if (deviceType)
        dispatchVidyo({
          type: EVidyoStreamActions.REMOVE_REMOTE_DEVICE,
          participantUserID: participant.userId,
          deviceStatus: {
            deviceId: remoteDevice.id,
            deviceName: remoteDevice.name,
            deviceType,
          },
        });
    },
    onStateUpdated: (remoteDevice: TVidyoStream<T>, participant: TVidyoParticipant, state: EVidyoDeviceStatus) => {
      if (deviceType)
        dispatchVidyo({
          type: EVidyoStreamActions.UPDATE_REMOTE_DEVICE_STATE,
          participantUserID: participant.userId,
          deviceStatus: {
            deviceId: remoteDevice.id,
            deviceName: remoteDevice.name,
            deviceType,
            state,
          },
        });
      else logStatusUpdate(remoteDevice, participant, status);
    },
  };
}

export async function createStreamsLifecycle(vidyoConnector: TVidyoConnector) {
  await vidyoConnector.RegisterResourceManagerEventListener({
    onAvailableResourcesChanged: (cpuEncode, cpuDecode, bandwidthSend, bandwidthReceive) => {
      if (NODE_ENV !== "production")
        console.info("onAvailableResourcesChanged", cpuEncode, cpuDecode, bandwidthSend, bandwidthReceive);
    },
    onMaxRemoteSourcesChanged: maxVidyoRemoteSources =>
      dispatchVidyo({
        type: EVidyoStatusActions.MAX_REMOTE_SOURCES,
        maxVidyoRemoteSources,
      }),
  });
  await vidyoConnector.RegisterRemoteCameraEventListener(remoteHandler(EVideoStream.REMOTE_CAMERA, EDevice.CAMERA));
  await vidyoConnector.RegisterRemoteWindowShareEventListener(remoteHandler(EVideoStream.REMOTE_WINDOW_SHARE));
  await vidyoConnector.RegisterRemoteMicrophoneEventListener(remoteHandler(undefined, EDevice.MICROPHONE));
}
