import { BUCKET_PROFILE_PICTURES, HEADER_JSON, parseDjangoError } from "packages/utils";

import { fetchDjangoAPI, fetchDjangoAPIAuthenticated } from "packages/client/fetch";
import { getToken } from "packages/client/authentication/redux/functions";
import { deleteFile, sendFile } from "packages/client/files/s3";

import type { UserType } from "packages/client/graphql_definitions.generated";
import type { IDjangoUser } from "packages/client/users/django/types";
import type { IUserTypeOverride } from "packages/client/users/graphql/types";

import { toDjangoUser, toDjangoUserOwnProfile, toDjangoPasswords } from "packages/client/users/django/serialisers";

export async function acceptPrivacyPolicy() {
  const resp = await fetchDjangoAPIAuthenticated("/users/accept-privacy-policy/", {
    method: "GET",
  });

  return resp;
}

export async function approveUser(userID: string) {
  const resp = await fetchDjangoAPIAuthenticated(`/users/activate/${userID}/`, {
    method: "GET",
    headers: HEADER_JSON,
  });

  if (resp.status !== 200) {
    if (resp.status === 400) {
      throw new Error("Unable to approve registration. Maximum number of users reached.");
    } else throw new Error("Unable to approve registration.");
  }
}

export async function changePassword(passwords: {
  confirmPassword: string;
  currentPassword: string;
  newPassword: string;
}) {
  const resp = await fetchDjangoAPIAuthenticated("/users/change-password/", {
    body: JSON.stringify(toDjangoPasswords(passwords)),
    headers: HEADER_JSON,
    method: "PATCH",
  });
  if (resp.status !== 200) {
    const err = await resp.json();
    let error = "";
    if (err) {
      Object.keys(err)
        // eslint-disable-next-line
        .map(field => (error += `[${field}]: ${err[field]}`))
        .join(" ,");
      throw new Error(error);
    }
  }
  return resp;
}

export async function createUser(data: IUserTypeOverride) {
  const resp = await fetchDjangoAPI("/users/register/", {
    body: JSON.stringify(toDjangoUser(data)),
    headers: HEADER_JSON,
    method: "POST",
  });

  if (resp.status !== 201) {
    parseDjangoError(resp);
  } else {
    const dataDB: IDjangoUser = await resp.json();
    if (!dataDB) throw new Error("No data received.");

    return { newUserID: dataDB.id, newOrganisationID: dataDB.organisation };
  }
}

export async function deleteUser(userID: string) {
  const resp = await fetchDjangoAPIAuthenticated(`/users/profile/${userID}/`, {
    method: "DELETE",
    headers: HEADER_JSON,
  });

  if (resp.status !== 204) {
    throw new Error("Unable to delete user.");
  }

  return resp;
}

export async function demoteUser(userID: string) {
  const resp = await fetchDjangoAPIAuthenticated(`/users/unset-admin/${userID}/`, {
    method: "GET",
    headers: HEADER_JSON,
  });

  if (resp.status !== 200) {
    throw new Error("Unable to demote user.");
  }

  return resp;
}

export async function editUser(userID: string, data: Partial<UserType>) {
  const resp = await fetchDjangoAPIAuthenticated(`/users/profile/${userID}/`, {
    body: JSON.stringify(toDjangoUserOwnProfile(data)),
    headers: HEADER_JSON,
    method: "PATCH",
  });

  if (resp.status !== 200) {
    throw new Error("Unable to edit user.");
  }

  return resp;
}

export async function fetchOrganisationUsers(organisationID: string) {
  const resp = await fetchDjangoAPIAuthenticated(`/users/organisation/${organisationID}/`, {
    headers: HEADER_JSON,
    method: "GET",
  });

  return resp;
}

export async function fetchUserProfile() {
  const resp = await fetchDjangoAPIAuthenticated("/users/profile/", {
    headers: HEADER_JSON,
    method: "GET",
  });

  if (resp.status !== 200) {
    throw new Error("Unable to fetch user profile.");
  }

  return resp.json() as Promise<{ first_name: string; id: number; last_name: string }>;
}

export async function promoteUser(userID: string) {
  const resp = await fetchDjangoAPIAuthenticated(`/users/set-admin/${userID}/`, {
    method: "GET",
    headers: HEADER_JSON,
  });

  if (resp.status !== 200) {
    if (resp.status === 400) {
      throw new Error("Unable to make user an adminstrator. Maximum number of adminstrators reached.");
    } else throw new Error("Unable to make user an administrator.");
  }

  return resp;
}

export async function uploadProfilePicture(
  formData: FormData,
  filename: string,
  currentProfilePicture: string,
  userID: number,
) {
  const SUBFOLDER_PICTURES = "";

  try {
    formData.append("bucket", BUCKET_PROFILE_PICTURES);
    formData.append("subfolder", SUBFOLDER_PICTURES);

    const token = getToken();

    const response = await sendFile(formData, token);

    if (!response) {
      const userIsUpdated = await updateProfilePictureAddress(userID, filename);

      if (userIsUpdated) {
        if (currentProfilePicture) {
          if (
            await deleteFile(
              {
                bucket: BUCKET_PROFILE_PICTURES,
                filename: currentProfilePicture,
                subfolder: SUBFOLDER_PICTURES,
              },
              token,
            )
          ) {
            console.error("Unable to delete current profile picture.");
          }
        }
      } else {
        throw new Error("Unable to update profile picture.");
      }
    } else {
      throw new Error(`Error: ${response} when sending files.`);
    }
    return response;
  } catch (err) {
    throw new Error("Unable to update profile picture.");
  }
}

export async function updateProfilePictureAddress(userID: number, filename: string) {
  const resp = await fetchDjangoAPIAuthenticated("/users/profile/", {
    body: JSON.stringify({
      id: userID,
      profile_picture: filename,
    }),
    headers: HEADER_JSON,
    method: "PATCH", // modify
  });
  return resp.status === 200;
}

export async function validateUser(data: IUserTypeOverride) {
  const resp = await fetchDjangoAPI("/users/validate/", {
    body: JSON.stringify(toDjangoUser(data)),
    headers: HEADER_JSON,
    method: "POST",
  });

  if (resp.status !== 200) throw await parseDjangoError(resp);
  else return resp;
}
