import { ElementType, HTMLAttributes, useMemo } from "react";

import type { Cell } from "./Cell";

import styles from "./Grid.module.scss";

export interface PGrid extends HTMLAttributes<HTMLElement> {
  alignItemsCenter?: boolean;
  as?: string | ElementType;
  children: OneOrManyReactChildren<typeof Cell>;
  className?: string;
  noColGap?: boolean;
}

export function Grid({ alignItemsCenter, as: Component = "div", children, className, noColGap, ...rest }: PGrid) {
  const classes = useMemo(
    () =>
      [styles.grid, className && className, noColGap && styles.noColGap, alignItemsCenter && styles.alignItemsCenter]
        .filter(e => e)
        .join(" "),
    [alignItemsCenter, className, noColGap],
  );

  return (
    <Component className={classes} {...rest}>
      {children}
    </Component>
  );
}
