import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { EColorSpace, convertColor, HSL } from "packages/utils";
import { Input } from "../Input/Input";

import { ColorSquare } from "./ColorSquare";
import { InputFields } from "./InputFields";

import styles from "./ColorPicker.module.scss";

export interface PColorPicker {
  color: string;
  pickerOpen: boolean;
  setColor: (hex: string) => void;
}

export function ColorPicker({ color, pickerOpen, setColor }: PColorPicker) {
  const [colorHSL, setColorHSL] = useState(convertColor(EColorSpace.HEX, EColorSpace.HSL, color) as HSL);

  useEffect(() => {
    const hex = convertColor(EColorSpace.HSL, EColorSpace.HEX, colorHSL) as string;
    const colorUpdateTimer = setTimeout(() => setColor(hex), 100);
    return () => clearTimeout(colorUpdateTimer);
  }, [colorHSL, setColor]);

  const handleHueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setColorHSL([parseInt(e.target.value), colorHSL[1], colorHSL[2]]);
    },
    [colorHSL, setColorHSL],
  );

  return (
    <div tabIndex={-1} className={styles.colorPicker}>
      <ColorSquare colorHSL={colorHSL} pickerOpen={pickerOpen} setColorHSL={setColorHSL} />
      <div className={styles.inputsWrapper}>
        <Input
          max={360}
          min={0}
          name="color-picker"
          onChange={handleHueChange}
          step={1}
          type="range"
          value={colorHSL[0]}
        />
        <InputFields colorHSL={colorHSL} setColorHSL={setColorHSL} />
      </div>
    </div>
  );
}
