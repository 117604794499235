import type { ErrorInfo } from "react";

import { Feedback, IllustratedCTA } from "packages/catalog";
import { EIllustration, EStatus } from "packages/utils";

export interface PSomethingWentWrong {
  error: Error;
  info: ErrorInfo;
}

export function SomethingWentWrong({ error, info }: PSomethingWentWrong) {
  if (process.env.NODE_ENV === "production") {
    return (
      <IllustratedCTA
        cta="Reload ClassView"
        illustration={EIllustration.SCREEN_INCALL_WARNING}
        onClick={window.location.reload}>
        {"We're very sorry, something has gone wrong. Please use the button below to reload ClassView."}
      </IllustratedCTA>
    );
  } else {
    return (
      <>
        <Feedback status={EStatus.DANGER}>{error.toString()}</Feedback>
        <ol>
          {info?.componentStack.split("\n").map((e, i) => {
            return <li key={i}>{e}</li>;
          })}
        </ol>
      </>
    );
  }
}
