import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Flexbox, H1, InternalTextLink, P, Snackbar } from "packages/catalog";
import { EIcon, EStatus, numToWords, PATH_CALL_PARTICIPANTS, TParams, useContainerSize } from "packages/utils";

import { useGetCallOrganisation, useRoom, useVidyoContext } from "packages/client/incall/hooks";
import { useGetEssentialSession } from "packages/client/sessions/hooks";
import { useRaisehandContext } from "packages/client/incall/contexts";

import { MeetingEndingNotification } from "packages/client/incall/components";

import raiseHandImport from "public/svg/hand.svg";

import styles from "./LetterboxTop.module.scss";

export function LetterboxTopWeb() {
  const params = useParams();
  const { callID }: TParams = params;

  const { isModerator, sessionID, isMeeting, room } = useRoom();
  const { session } = useGetEssentialSession(sessionID);
  const nextRecurringStart = session?.nextRecurringStart ?? false;
  const { orgName } = useGetCallOrganisation();
  const {
    recording: { isBeingRecorded },
  } = useVidyoContext();

  const { raisedHands } = useRaisehandContext();

  const [numOfHands, setNumOfHands] = useState("");

  const letterboxTopRef = useRef<HTMLElement>();

  const { width } = useContainerSize(letterboxTopRef);

  const isSmall = useMemo(() => width < 1000, [width]);
  const isMedium = useMemo(() => width < 1500, [width]);
  const raisedHandsText = useMemo(
    () => (isMedium ? raisedHands.length : `${numOfHands} hand${raisedHands.length > 1 ? "s" : ""} raised`),
    [isMedium, numOfHands, raisedHands],
  );

  useEffect(() => {
    if (raisedHands.length > 0) {
      let number = numToWords(raisedHands.length);
      number = number.charAt(0).toUpperCase() + number.slice(1);

      setNumOfHands(number);
    }
  }, [raisedHands]);

  return (
    <section className={styles.letterboxTop} ref={letterboxTopRef}>
      <Flexbox>
        {isBeingRecorded && (
          <Snackbar icon={EIcon.RECORD} status={EStatus.DANGER} title="Recording" pulse>
            {!isSmall && <P className={styles.recording}>Recording</P>}
          </Snackbar>
        )}
        {isMeeting && (
          <MeetingEndingNotification
            isMedium={isMedium}
            isModerator={isModerator}
            isRecurring={nextRecurringStart}
            isSmall={isSmall}
            sessionID={sessionID}
          />
        )}
        {isModerator && raisedHands.length > 0 && (
          <Snackbar status={EStatus.WARNING}>
            <svg className={styles.hand}>
              <use href={`${raiseHandImport}#hand`} role="img"></use>
            </svg>
            <InternalTextLink to={PATH_CALL_PARTICIPANTS(callID)}>{raisedHandsText}</InternalTextLink>
          </Snackbar>
        )}
      </Flexbox>
      <div className={styles.sessionDetails}>
        <H1 className={styles.sessionName}>{room?.roomInfo?.displayName}</H1>
        <P className={styles.orgName}>{orgName}</P>
      </div>
    </section>
  );
}
