import { useMemo } from "react";

import { StatusBadge } from "packages/catalog";
import { EStatus, toSentenceCase } from "packages/utils";

import type { PollStatusV2 } from "packages/client/graphql_definitions.generated";

export interface PBadge {
  status: PollStatusV2;
}

const badgeStatus = {
  DRAFT: EStatus.NEUTRAL,
  PUBLISHED: EStatus.SUCCESS,
  ENDED: EStatus.DANGER,
} as const;

export function Badge({ status }: PBadge) {
  return useMemo(() => <StatusBadge status={badgeStatus[status]}>{toSentenceCase(status)}</StatusBadge>, [status]);
}
