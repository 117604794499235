import { Children, useMemo } from "react";

import {
  Alerts,
  BackgroundColorOption,
  Description,
  Flexbox,
  InputOption,
  Legend,
  PAlert,
  PillOption,
  RatingOption,
  ToggleOption,
} from "packages/catalog";
import { flattenString, EStatus } from "packages/utils";

import styles from "./Fieldset.module.scss";

export interface PFieldset {
  alerts?: PAlert[];
  children: OneOrManyReactChildren<
    typeof BackgroundColorOption | typeof InputOption | typeof PillOption | typeof RatingOption | typeof ToggleOption
  >;
  description?: string;
  flexDirectionRow?: boolean;
  flexWrapWrap?: boolean;
  justifyContentSpaceBetween?: boolean;
  hasDoubleGap?: boolean;
  label?: string;
}

function calculateBorderStyle(error: boolean, warning: boolean, success: boolean) {
  if (error) {
    return styles.error;
  }
  if (warning) {
    return styles.warning;
  }
  if (success) {
    return styles.success;
  } else return null;
}

export function Fieldset({
  alerts,
  children,
  description,
  flexDirectionRow,
  flexWrapWrap,
  hasDoubleGap,
  justifyContentSpaceBetween,
  label,
  ...rest
}: PFieldset) {
  const descriptionString = flattenString(description);

  const hasError = useMemo(() => alerts?.some((alert: PAlert) => alert.status === EStatus.DANGER), [alerts]);

  const hasWarning = useMemo(() => alerts?.some((alert: PAlert) => alert.status === EStatus.WARNING), [alerts]);

  const hasSuccess = useMemo(() => alerts?.some((alert: PAlert) => alert.status === EStatus.SUCCESS), [alerts]);

  const requireds: Array<boolean> = useMemo(() => {
    return (
      Children.map(children, child => {
        return child?.props?.children?.props?.required || false;
      }) || []
    );
  }, [children]);

  const required = useMemo(() => requireds.some(item => item), [requireds]);

  return (
    <fieldset
      aria-describedby={descriptionString}
      className={calculateBorderStyle(hasError, hasWarning, hasSuccess)}
      {...rest}>
      {label && (
        <Legend hasMargin required={required}>
          {label}
        </Legend>
      )}
      <Flexbox
        flexDirectionColumn={!flexDirectionRow}
        flexWrapWrap={flexWrapWrap}
        hasDoubleGap={hasDoubleGap}
        justifyContentSpaceBetween={justifyContentSpaceBetween}
        role="group">
        {children}
      </Flexbox>
      {description && (
        <Description className={styles.description} id={descriptionString}>
          {description}
        </Description>
      )}
      {alerts && <Alerts alerts={alerts} />}
    </fieldset>
  );
}
