import { HTMLAttributes, useMemo } from "react";

import { EStatus, useStatusStyle } from "packages/utils";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./StatusBadge.module.scss";

export interface PStatusBadge extends HTMLAttributes<HTMLSpanElement> {
  status: EStatus;
}

export function StatusBadge({ className, status, ...rest }: PStatusBadge) {
  const statusStyle = useStatusStyle(styles);
  const classes = useMemo(
    () => [styles.content, statusStyle[status], className && className].filter(e => e).join(" "),
    [className, status, statusStyle],
  );

  return <span className={classes} {...rest} />;
}
