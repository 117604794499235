import { useCallback } from "react";

import { Dialog, Feedback, Flexbox, TextButton } from "packages/catalog";
import { EAppearance, reloadElectron, EStatus } from "packages/utils";
import { EDeepLinkingAction, generateNativeURL, PROTOCOL_PREFIX } from "packages/native/src/deepLinking";

import { useTypedSelector } from "packages/client/redux";

export interface PModalCallError {
  isOpen: boolean;
  reason: string;
}

export function ModalCallError({ isOpen, reason }: PModalCallError) {
  const { roomKey } = useTypedSelector(({ sessions }) => ({ roomKey: sessions.roomKey }));

  const handleRefresh = useCallback(() => {
    IS_ELECTRON
      ? reloadElectron(
          generateNativeURL({
            action: EDeepLinkingAction.Call,
            params: { roomkey: roomKey },
            protocol: PROTOCOL_PREFIX,
          }),
        )
      : window.location.reload();
  }, [roomKey]);

  return (
    <Dialog isAtBottom={IS_ELECTRON} isOpen={isOpen}>
      <Flexbox flexDirectionColumn={!IS_ELECTRON}>
        <Feedback center status={EStatus.DANGER}>
          <h1>{reason}</h1>
        </Feedback>
        <TextButton appearance={EAppearance.FILL} onClick={handleRefresh} status={EStatus.ACCENT}>
          {IS_ELECTRON ? "Reload" : "Refresh"}
        </TextButton>
      </Flexbox>
    </Dialog>
  );
}
