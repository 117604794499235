import { MouseEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Cell,
  ClosableHeading,
  Flexbox,
  Grid,
  H3,
  IconButton,
  PIconButton,
  SidebarFooter,
  SidebarHeader,
  SidebarMain,
  SmallPopoverMenu,
  Tooltip,
} from "packages/catalog";
import { EAppearance, EAttachment, copyString, EDimension, EIcon, EStatus, PATH_CALLLINK } from "packages/utils";

import type { IContextParticipant } from "packages/client/incall/django/types";

import { useRoom, useVidyoContext } from "packages/client/incall/hooks";
import { useTypedSelector } from "packages/client/redux";
import { useRaisehandContext } from "packages/client/incall/contexts";

import { Participant, RoomSystems } from "packages/client/incall/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

import raiseHandImport from "public/svg/hand.svg";

import styles from "./Participants.module.scss";

interface IParticipant extends IContextParticipant {
  handRaisedAt?: number;
}

const compareParticipants = (a: IParticipant, b: IParticipant) => {
  if (a.handRaisedAt && b.handRaisedAt) {
    return a.handRaisedAt - b.handRaisedAt;
  } else if (a.handRaisedAt || b.handRaisedAt) {
    return a.handRaisedAt ? -1 : 1;
  } else if (a.isGuest !== b.isGuest) {
    return a.isGuest ? -1 : 1;
  }
  return a.id.localeCompare(b.id);
};

function ParticipantIconButton({
  appearance,
  description,
  dimension,
  icon,
  participantID,
  onPartClick,
  onClick,
  ...rest
}: Omit<PIconButton, "as"> & {
  participantID: string;
  onPartClick: (participantID: string) => void;
}) {
  function handleOnClick(e: MouseEvent<HTMLButtonElement>) {
    if (onClick) onClick(e);
    onPartClick(participantID);
  }

  return (
    <IconButton
      {...rest}
      onClick={handleOnClick}
      status={EStatus.NEUTRAL}
      as="button"
      appearance={appearance}
      description={description}
      dimension={dimension}
      icon={icon}
    />
  );
}

function ParticipantMenu({
  kickParticipant,
  participantID,
  participantsListRef,
}: {
  kickParticipant: (id: string) => void;
  participantID: string;
  participantsListRef: MutableRefObject<HTMLUListElement>;
}) {
  const menuRef = useRef<HTMLDivElement>();
  const [menuUpwards, setMenuUpwards] = useState(false);

  const attachmentType = useMemo(
    () => (menuUpwards ? EAttachment.SOUTH_SOUTH_EAST : EAttachment.NORTH_NORTH_EAST),
    [menuUpwards],
  );

  useEffect(() => {
    if (!menuRef.current) return;

    // detect if the participant's popover menu has enough room to display a downwards menu
    const observer = new IntersectionObserver(
      entries => {
        setMenuUpwards(entries[0].isIntersecting);
      },
      {
        root: participantsListRef.current,
        // this is a hard-coded estimated margin box based on the SmallPopoverMenu's height. if the volume of items in the menu changes, the third value (bottom margin) will need updated
        rootMargin: "0px 0px -120px 0px",
        threshold: 1.0,
      },
    );

    observer.observe(menuRef.current);
    return () => observer.disconnect();
  });

  return (
    <div ref={menuRef}>
      <SmallPopoverMenu
        attachment={attachmentType}
        options={[
          {
            onClick: () => kickParticipant(participantID),
            status: EStatus.DANGER,
            text: "Remove from session",
          },
        ]}
        trigger={
          <IconButton
            appearance={EAppearance.GHOST}
            as="div"
            description="More options"
            dimension={EDimension.SMALL}
            icon={EIcon.MOREVERTICAL}
            status={EStatus.NEUTRAL}
          />
        }
      />
    </div>
  );
}

export function Participants() {
  const { isModerator } = useRoom();
  const {
    moderation: {
      kickAll,
      kickParticipant,
      loading,
      muteCameraAll,
      muteCameraParticipant,
      muteMicrophoneAll,
      muteMicrophoneParticipant,
      participantStatuses,
      unmuteCameraAll,
      unmuteCameraParticipant,
      unmuteMicrophoneAll,
      unmuteMicrophoneParticipant,
    },
  } = useVidyoContext();

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const participantsListRef = useRef<HTMLUListElement>();

  const { guestName, roomKey } = useTypedSelector(({ authentication, sessions }) => ({
    guestName: authentication.guestName,
    roomKey: sessions.roomKey,
  }));
  const { raisedHands, unRaiseUserHand } = useRaisehandContext();

  // YVAN TODO: flagging this as you may need to change this domain here too?
  const participants = useMemo(() => {
    const realParticipants = participantStatuses.filter(p => !p.id.endsWith("@pex.vscene.net"));
    return realParticipants.map(p => {
      const raisedHandUser = raisedHands.find(participant => participant.user === p.id);
      const handRaisedAt = raisedHandUser ? parseInt(raisedHandUser.raisedAt) : null;
      return {
        ...p,
        handRaisedAt,
      };
    });
  }, [participantStatuses, raisedHands]);

  const participantsInOrder = useMemo(() => participants.sort((a, b) => compareParticipants(a, b)), [participants]);

  const allCamsOffStatus = useMemo(
    () =>
      participants.length > 1 ? participants.filter(e => e.id !== guestName).every(e => !e.isCameraActive) : false,
    [participants, guestName],
  );
  const allMicsOffStatus = useMemo(
    () =>
      participants.length > 1 ? participants.filter(e => e.id !== guestName).every(e => !e.isMicrophoneActive) : false,
    [participants, guestName],
  );

  const handleToggleAllCams = useCallback(() => {
    allCamsOffStatus ? unmuteCameraAll() : muteCameraAll();
  }, [allCamsOffStatus, muteCameraAll, unmuteCameraAll]);
  const handleToggleAllMics = useCallback(() => {
    allMicsOffStatus ? unmuteMicrophoneAll() : muteMicrophoneAll();
  }, [allMicsOffStatus, muteMicrophoneAll, unmuteMicrophoneAll]);

  const handleCopyLink = useCallback(() => {
    const link = `https://${DOMAIN}${PATH_CALLLINK(roomKey)}`;
    copyString(link);
    setIsLinkCopied(true);
  }, [roomKey]);

  const moderationOptions = useMemo(() => {
    return [
      {
        onClick: handleToggleAllCams,
        status: EStatus.NEUTRAL,
        text: allCamsOffStatus ? "Enable all cameras" : "Disable all cameras",
      },
      {
        onClick: handleToggleAllMics,
        status: EStatus.NEUTRAL,
        text: allMicsOffStatus ? "Enable all microphones" : "Disable all microphones",
      },
      { onClick: kickAll, status: EStatus.DANGER, text: "Remove everyone from session" },
    ];
  }, [allCamsOffStatus, allMicsOffStatus, handleToggleAllCams, handleToggleAllMics, kickAll]);

  useEffect(() => {
    if (!isLinkCopied) return;
    const timeoutId = window.setTimeout(() => setIsLinkCopied(false), 3 * 1000);
    return () => window.clearTimeout(timeoutId);
  }, [isLinkCopied]);

  return (
    <>
      <RoutingChangeHandler message="The participants list has loaded." />
      <SidebarHeader>
        <ClosableHeading to="..">Participants</ClosableHeading>
      </SidebarHeader>
      <SidebarMain>
        <Grid noColGap>
          {isModerator && <RoomSystems />}
          <Cell>
            <Flexbox justifyContentSpaceBetween>
              <H3 hasMargin={!isModerator}>{`All participants (${participants.length})`}</H3>
              {isModerator && participants.length > 1 && (
                <Flexbox>
                  <SmallPopoverMenu
                    attachment={EAttachment.SOUTH_SOUTH_EAST}
                    options={moderationOptions}
                    trigger={
                      <IconButton
                        as="div"
                        description="More options"
                        status={EStatus.NEUTRAL}
                        icon={EIcon.MOREVERTICAL}
                        appearance={EAppearance.GHOST}
                        dimension={EDimension.SMALL}
                      />
                    }
                  />
                </Flexbox>
              )}
            </Flexbox>
          </Cell>
          <Cell as="ul" ref={participantsListRef}>
            <>
              {participantsInOrder.map((p, index) => {
                const micOn = p.isMicrophoneActive;
                const camOn = p.isCameraActive;
                const isMe = p.id === guestName;
                return (
                  <Participant
                    buttons={
                      <>
                        {p.handRaisedAt &&
                          (!isModerator && !isMe ? (
                            <svg aria-hidden="true" className={styles.hand} focusable="false">
                              <title id={`${p.id}RaisedTheirHand`}>{p.id} raised their hand</title>
                              <use
                                aria-labelledby={`${p.id}RaisedTheirHand`}
                                href={`${raiseHandImport}#hand`}
                                role="img"
                              />
                            </svg>
                          ) : (
                            <IconButton
                              appearance={EAppearance.GHOST}
                              as="button"
                              description="Lower hand"
                              dimension={EDimension.SMALL}
                              icon={null}
                              onClick={() => unRaiseUserHand(p.id)}
                              status={EStatus.NEUTRAL}>
                              <svg style={{ strokeWidth: "calc(var(--width-outline) * 2)" }}>
                                <use href={`${raiseHandImport}#hand`} role="img"></use>
                              </svg>
                            </IconButton>
                          ))}
                        {isModerator && (
                          <>
                            <ParticipantIconButton
                              status={EStatus.NEUTRAL}
                              appearance={EAppearance.GHOST}
                              description={camOn ? "Disable camera" : "Enable camera"}
                              dimension={EDimension.SMALL}
                              icon={camOn ? EIcon.VIDEO : EIcon.VIDEOOFF}
                              participantID={p.id}
                              onPartClick={camOn ? muteCameraParticipant : unmuteCameraParticipant}
                              disabled={loading}
                            />
                            <ParticipantIconButton
                              status={EStatus.NEUTRAL}
                              appearance={EAppearance.GHOST}
                              description={micOn ? "Disable microphone" : "Enable microphone"}
                              dimension={EDimension.SMALL}
                              icon={micOn ? EIcon.MIC : EIcon.MICOFF}
                              participantID={p.id}
                              onPartClick={micOn ? muteMicrophoneParticipant : unmuteMicrophoneParticipant}
                              disabled={loading}
                            />
                            <ParticipantMenu
                              kickParticipant={kickParticipant}
                              participantID={p.id}
                              participantsListRef={participantsListRef}
                            />
                          </>
                        )}
                      </>
                    }
                    key={`${p.id}${index}`}
                    fullName={p.id}
                  />
                );
              })}
            </>
          </Cell>
        </Grid>
      </SidebarMain>
      <SidebarFooter>
        <Tooltip
          attachment={EAttachment.NORTH}
          isActive={!isLinkCopied}
          targetName="Copy session link"
          tip="Link copied"
          trigger={
            <IconButton
              appearance={EAppearance.OUTLINE}
              as="button"
              description=""
              dimension={EDimension.SMALL}
              icon={EIcon.LINK}
              onClick={handleCopyLink}
              status={EStatus.NEUTRAL}
              label="Copy session link"
            />
          }
        />
      </SidebarFooter>
    </>
  );
}
