import React from "react";

import { Flexbox, IconButton, Input, ISelectListData, P } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

export interface IRoomSystem {
  id: number;
  roomName: string;
}

export interface PEditRoomSystemsResults {
  dialOutRoomSystems: number[];
  disabled?: boolean;
  removeAction: (item: ISelectListData["id"]) => void;
  rooms: IRoomSystem[];
  updateDialOut: (ids: number[]) => void;
}

export class EditRoomSystemsResults extends React.Component<PEditRoomSystemsResults> {
  constructor(props: PEditRoomSystemsResults) {
    super(props);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
  }

  public render() {
    const { dialOutRoomSystems, disabled, rooms } = this.props;
    return rooms ? (
      <Flexbox flexDirectionColumn>
        {rooms.map((item: IRoomSystem) => (
          <Flexbox key={item.id} alignItemsCenter justifyContentSpaceBetween>
            <Flexbox alignItemsCenter>
              <P>{item.roomName}</P>
              {!disabled && (
                <IconButton
                  appearance={EAppearance.GHOST}
                  as="button"
                  description="Remove"
                  dimension={EDimension.SMALL}
                  icon={EIcon.X}
                  onClick={() => this.handleRemoveItem(item.id)}
                  status={EStatus.DANGER}
                  type="button"
                />
              )}
            </Flexbox>
            <Input
              checked={dialOutRoomSystems.includes(item.id)}
              disabled={disabled || false}
              name="dial-out"
              onChange={this.handleCheckedChange(item)}
              type="checkbox"
            />
          </Flexbox>
        ))}
      </Flexbox>
    ) : null;
  }

  private handleCheckedChange(item: IRoomSystem) {
    const { dialOutRoomSystems, updateDialOut } = this.props;
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      updateDialOut(
        e.target.checked ? [...dialOutRoomSystems, item.id] : dialOutRoomSystems.filter(id => id !== item.id),
      );
    };
  }

  private handleRemoveItem(item: ISelectListData["id"]) {
    const { removeAction } = this.props;
    removeAction(item);
  }
}
