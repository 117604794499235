/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from "react";

import { Screens } from "packages/client/onboarding/enums/screens";

import { createOrganisation } from "packages/client/organisations/django/requests";
import { createUser, validateUser } from "packages/client/users/django/requests";
import { OrganisationPricingPlan, OrganisationSector } from "packages/client/graphql_definitions.generated";

import {
  AccountCreated,
  ChooseOrg,
  CreateOrgStep1,
  CreateOrgStep2,
  CreateOrgStep3,
  JoinOrCreate,
  Register,
  Review,
  User,
} from "packages/client/onboarding/components";

export interface SScreen {
  administrators?: string[];
  city?: string;
  country?: string;
  county?: string;
  email?: string;
  eula?: boolean;
  firstName?: string;
  isValid: boolean;
  lastName?: string;
  optIn?: boolean;
  orgName?: string;
  orgPhone?: string;
  password?: string;
  plan?: OrganisationPricingPlan;
  postcode?: string;
  repeat?: string;
  sector?: OrganisationSector;
  selected?: boolean;
  selectedOrg?: string;
  selectedOrgName?: string;
  selectedParent?: string;
  selectedParentName?: string;
  street?: string;
  tnc?: boolean;
  url?: string;
  userPhone?: string;
}

export interface SRegister {
  checkingEmpty: {
    [screen: string]: boolean;
  };
  currentScreen: Screens;
  isNewOrganisationLoading?: boolean;
  isNewUserLoading?: boolean;
  isNewUserValid?: boolean;
  isNewUserValidating?: boolean;
  newOrganisationError?: string;
  newOrganisationID?: number;
  newUserError?: string;
  newUserID?: string;
  screens: {
    [screen: string]: {
      render: (state?: SRegister) => ReactNode;
      prev: (state?: SRegister) => Screens;
      next: (state?: SRegister) => Screens;
      goTo?: (screen: Screens, state?: SRegister) => Screens;
    };
  };
  screenStates: {
    [screen: string]: SScreen;
  };
}

const initialScreenStates = {
  [Screens.User]: { isValid: false },
  [Screens.JoinOrCreate]: { isValid: true },
  [Screens.ChooseOrg]: { isValid: false, selected: null, selectedOrg: null, selectedOrgName: null },
  [Screens.CreateOrgStep1]: {
    isValid: false,
    selected: null,
    selectedParent: null,
    selectedParentName: null,
  },
  [Screens.CreateOrgStep2]: { isValid: false },
  [Screens.CreateOrgStep3]: { isValid: false },
  [Screens.Review]: { isValid: true },
  [Screens.Success]: { isValid: true },
} as any;

// eslint-disable-next-line @typescript-eslint/ban-types
export class RegisterContainer extends React.Component<{}, SRegister> {
  private getInitialScreens() {
    return {
      [Screens.User]: {
        goTo: (screen: Screens.CreateOrgStep1) => screen,
        next: null as any,
        prev: null as any,
        render: (state: SRegister) => (
          <User
            cache={state.screenStates[Screens.User]}
            checkEmpty={() => this._checkEmpty("user")}
            checkingEmpty={this.state.checkingEmpty.user}
            emptyChecked={this._emptyChecked}
            isUserValidating={this.state.isNewUserValidating}
            newUserError={this.state.newUserError}
            onChange={data => this._updateData(data)}
            onValidation={isValid => this._updateData({ isValid })}
            validateUser={this._validateUser}
          />
        ),
      },
      [Screens.JoinOrCreate]: {
        goTo: (screen: Screens) => screen,
        next: null as any,
        prev: () => Screens.User,
        render: (state: SRegister) => (
          <JoinOrCreate
            goTo={screen => {
              this._goToScreen(screen);
              this._updateData(initialScreenStates[state.currentScreen], state.currentScreen);
            }}
            onPrevious={this._previousScreen}
          />
        ),
      },
      [Screens.ChooseOrg]: {
        goTo: (screen: Screens.CreateOrgStep1) => screen,
        next: () => Screens.Success,
        prev: () => Screens.JoinOrCreate,
        render: (state: SRegister) => (
          <ChooseOrg
            cache={state.screenStates[Screens.ChooseOrg]}
            onChange={data =>
              this._updateData({
                ...data,
                selectedOrg: data.selected
                  ? data.selected.id
                  : data.selected === null
                  ? null
                  : state.screenStates[Screens.ChooseOrg].selectedOrg,
                selectedOrgName: data.selected
                  ? data.selected.name
                  : data.selected === null
                  ? null
                  : state.screenStates[Screens.ChooseOrg].selectedOrgName,
                isValid: data.selected ? true : false,
              })
            }
            onPrevious={this._previousScreen}
            goTo={screen => {
              this._goToScreen(screen);
              this._updateData(initialScreenStates[state.currentScreen], state.currentScreen);
            }}
            onSubmit={this._createUser}
            isRegistrationLoading={this.state.isNewUserLoading}
          />
        ),
      },
      [Screens.CreateOrgStep1]: {
        goTo: (screen: Screens.ChooseOrg) => screen,
        next: () => {
          if (this.state.screenStates[Screens.CreateOrgStep1].selectedParent) {
            this._updateData({ plan: OrganisationPricingPlan.SuperOrg }, Screens.CreateOrgStep3);
          } else {
            this._updateData({ plan: null }, Screens.CreateOrgStep3);
          }
          return Screens.CreateOrgStep2;
        },
        prev: () => Screens.JoinOrCreate,
        render: (state: SRegister) => (
          <CreateOrgStep1
            cache={state.screenStates[Screens.CreateOrgStep1]}
            checkEmpty={() => this._checkEmpty("orgA")}
            checkingEmpty={this.state.checkingEmpty.orgA}
            emptyChecked={this._emptyChecked}
            goTo={this._goToScreen}
            onChange={data =>
              this._updateData({
                ...data,
                isValid: state.screenStates[Screens.CreateOrgStep1].isValid,
                selectedParent: data.selected
                  ? data.selected.id
                  : data.selected === null
                  ? null
                  : state.screenStates[Screens.CreateOrgStep1].selectedParent,
                selectedParentName: data.selected
                  ? data.selected.name
                  : data.selected === null
                  ? null
                  : state.screenStates[Screens.CreateOrgStep1].selectedParentName,
              })
            }
            onFormValidation={isValid =>
              this._updateData({
                isFormValid: isValid,
                isValid,
              })
            }
            onNext={this._nextScreen}
            onPrevious={this._previousScreen}
          />
        ),
      },
      [Screens.CreateOrgStep2]: {
        goTo: (screen: Screens.ChooseOrg) => screen,
        next: (state: SRegister) => {
          return state.screenStates[Screens.CreateOrgStep1].selectedParent !== null
            ? Screens.Review
            : Screens.CreateOrgStep3;
        },
        prev: () => Screens.CreateOrgStep1,
        render: (state: SRegister) => (
          <CreateOrgStep2
            cache={state.screenStates[Screens.CreateOrgStep2]}
            checkEmpty={() => this._checkEmpty("orgB")}
            checkingEmpty={this.state.checkingEmpty.orgB}
            emptyChecked={this._emptyChecked}
            goTo={this._goToScreen}
            onChange={data => this._updateData(data)}
            onNext={this._nextScreen}
            onPrevious={this._previousScreen}
            onValidation={isValid => this._updateData({ isValid })}
          />
        ),
      },
      [Screens.CreateOrgStep3]: {
        goTo: (screen: Screens.ChooseOrg) => screen,
        next: () => Screens.Review,
        prev: () => Screens.CreateOrgStep2,
        render: (state: SRegister) => (
          <CreateOrgStep3
            cache={state.screenStates[Screens.CreateOrgStep3]}
            onPrevious={this._previousScreen}
            onNext={this._nextScreen}
            goTo={this._goToScreen}
            onChange={data => this._updateData({ ...data, isValid: data.plan ? true : false })}
          />
        ),
      },
      [Screens.Review]: {
        render: (state: SRegister) => (
          <Review
            cache={state.screenStates}
            onSubmit={this._createUser}
            isOrgLoading={this.state.isNewOrganisationLoading}
            isUserLoading={this.state.isNewUserLoading}
            orgError={this.state.newOrganisationError}
            userError={this.state.newUserError}
            onPrevious={this._previousScreen}
          />
        ),
        prev: (state: SRegister) => {
          return state.screenStates[Screens.CreateOrgStep1].selected !== null
            ? Screens.CreateOrgStep2
            : Screens.CreateOrgStep3;
        },
        next: () => Screens.Success,
      },
      [Screens.Success]: {
        next: null as any,
        prev: null as any,
        render: () => <AccountCreated />,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this._checkEmpty = this._checkEmpty.bind(this);
    this._createOrg = this._createOrg.bind(this);
    this._createUser = this._createUser.bind(this);
    this._emptyChecked = this._emptyChecked.bind(this);
    this._goToScreen = this._goToScreen.bind(this);
    this._nextScreen = this._nextScreen.bind(this);
    this._previousScreen = this._previousScreen.bind(this);
    this._reset = this._reset.bind(this);
    this._updateData = this._updateData.bind(this);
    this._validateUser = this._validateUser.bind(this);
    this.getInitialScreens = this.getInitialScreens.bind(this);

    this.state = {
      checkingEmpty: {
        orgA: false,
        orgB: false,
        user: false,
      },
      currentScreen: Screens.User,
      isNewOrganisationLoading: false,
      isNewUserValid: null,
      isNewUserValidating: false,
      newOrganisationError: null,
      newOrganisationID: null,
      newUserError: null,
      newUserID: null,
      screens: this.getInitialScreens(),
      screenStates: { ...initialScreenStates },
    };
  }

  public componentDidUpdate(_: any, _prevState: SRegister) {
    if (this.state.currentScreen === Screens.ChooseOrg && !_prevState.newUserID && this.state.newUserID) {
      // after choosing an org and successful user creation
      this._nextScreen();
    } else if (this.state.currentScreen === Screens.Review && !_prevState.newUserID && this.state.newUserID) {
      // else after filling out new org info
      this._createOrg();
    } else if (
      this.state.currentScreen === Screens.Review &&
      !_prevState.newOrganisationID &&
      this.state.newOrganisationID
    ) {
      // after the new organisation has been created
      this._nextScreen();
    }

    // validating the user
    if (this.state.isNewUserValid) {
      this.setState({
        isNewUserValid: null,
        screenStates: {
          ...this.state.screenStates,
          [Screens.User]: {
            ...this.state.screenStates[Screens.User],
            isValid: true,
          },
        },
      });
      this._goToScreen(Screens.JoinOrCreate);
    }
  }

  public render() {
    const { currentScreen, screens } = this.state;
    return <Register currentScreen={currentScreen} renderScreen={screens[currentScreen].render(this.state)} />;
  }

  private _checkEmpty(screen: string) {
    this.setState({
      checkingEmpty: {
        ...this.state.checkingEmpty,
        [screen]: true,
      },
    });
  }

  private _emptyChecked(screen: string) {
    this.setState({
      checkingEmpty: {
        ...this.state.checkingEmpty,
        [screen]: false,
      },
    });
  }

  private _reset() {
    this.setState({
      currentScreen: Screens.User,
      screenStates: { ...initialScreenStates },
      screens: this.getInitialScreens(),
    });
  }

  private _updateData(data: any, screen: Screens = this.state.currentScreen) {
    this.setState({
      screenStates: {
        ...this.state.screenStates,
        [screen]: {
          ...this.state.screenStates[screen],
          ...data,
        },
      },
    });
  }

  private _previousScreen() {
    const { screens, currentScreen } = this.state;
    if (screens[currentScreen].prev) {
      this.setState({
        currentScreen: screens[currentScreen].prev(this.state),
      });
    }
  }

  private _nextScreen() {
    const { screens, currentScreen } = this.state;
    if (screens[currentScreen].next) {
      this.setState({
        currentScreen: screens[currentScreen].next(this.state),
      });
    }
  }

  private _goToScreen(screen: Screens) {
    const { screens, currentScreen } = this.state;
    if (screens[currentScreen].goTo) {
      this.setState({
        currentScreen: screens[currentScreen].goTo(screen, this.state),
      });
    }
  }

  private _createUser() {
    this._emptyChecked("user");

    const userCache = this.state.screenStates[Screens.User];
    const selectedOrg = this.state.screenStates[Screens.ChooseOrg].selectedOrg || null;

    this.setState({ isNewUserLoading: true, newUserError: null }, () => {
      createUser({
        firstName: userCache.firstName,
        lastName: userCache.lastName,
        email: userCache.email,
        password: userCache.password,
        confirmPassword: userCache.repeat,
        organisationID: selectedOrg ? selectedOrg : null,
        phoneNumber: userCache.userPhone,
        optIn: userCache.optIn,
      })
        .then(response =>
          this.setState({
            isNewUserLoading: false,
            newOrganisationID: response.newOrganisationID,
            newUserError: null,
            newUserID: response.newUserID,
          }),
        )
        .catch(reason =>
          this.setState({
            isNewUserLoading: false,
            newUserError: reason,
          }),
        );
    });
  }

  private _createOrg() {
    if (this.state.newUserID) {
      const orgCache = {
        ...this.state.screenStates[Screens.CreateOrgStep1],
        ...this.state.screenStates[Screens.CreateOrgStep2],
        ...this.state.screenStates[Screens.CreateOrgStep3],
      };

      this.setState({ isNewOrganisationLoading: true }, () => {
        if (orgCache.tnc) {
          createOrganisation({
            address: orgCache.street,
            administratorIDs: [`${this.state.newUserID}`],
            city: orgCache.city,
            country: orgCache.country,
            county: orgCache.county,
            domain: orgCache.url,
            name: orgCache.orgName,
            phoneNumber: orgCache.orgPhone,
            parentOrganisationID: orgCache.selectedParent,
            pricingPlan: orgCache.plan,
            postcode: orgCache.postcode,
            sector: orgCache.sector,
          })
            .then(response => this.setState({ isNewOrganisationLoading: false, newOrganisationID: response.id }))
            .catch(reason => this.setState({ isNewOrganisationLoading: false, newOrganisationError: reason }));
        }
      });
    }
  }

  private _validateUser() {
    this._emptyChecked("user");

    const userCache = this.state.screenStates[Screens.User];
    const selectedOrg = this.state.screenStates[Screens.ChooseOrg].selectedOrg || null;

    this.setState(
      { isNewUserLoading: true, isNewUserValid: false, isNewUserValidating: true, newUserError: null },
      () => {
        validateUser({
          confirmPassword: userCache.repeat,
          email: userCache.email,
          firstName: userCache.firstName,
          lastName: userCache.lastName,
          optIn: userCache.optIn,
          organisationID: selectedOrg ? selectedOrg : null,
          password: userCache.password,
          phoneNumber: userCache.userPhone,
        })
          .then(() => this.setState({ isNewUserLoading: false, isNewUserValid: true, isNewUserValidating: false }))
          .catch(reason =>
            this.setState({
              isNewUserLoading: false,
              isNewUserValid: false,
              isNewUserValidating: false,
              newUserError: reason,
            }),
          );
      },
    );
  }
}
