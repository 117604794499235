/**@example await promiseWaiter(500); // waits 500 milliseconds */
export function promiseWaiter(timeOutInMs = 50) {
  return new Promise<null>(resolve => {
    setTimeout(() => resolve(null), timeOutInMs);
  });
}

/**
 * A function that emits a side effect and does not return anything.
 */
export type CallBack = (...args: unknown[]) => void;

/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/no-this-alias */
export function simpleThrottle<F extends CallBack>(
  cb: F,
  wait: number,
  options: { runLastCall: boolean } = { runLastCall: false },
): F {
  let lastCall: () => void = undefined;
  let waiter: number = undefined;
  return function (this: unknown, ...args: unknown[]) {
    function runAtEndOfTimer() {
      waiter = undefined;
      if (lastCall && options.runLastCall) {
        lastCall();
        lastCall = undefined;
        waiter = window.setTimeout(runAtEndOfTimer, wait);
      }
    }
    if (waiter && options.runLastCall) lastCall = () => cb.apply(this, args);
    if (!waiter) {
      cb.apply(this, args);
      waiter = window.setTimeout(runAtEndOfTimer, wait);
    }
  } as F;
}

/**
 * @example
 *      return {
 *          ...state,
 *          doctorsByID: eliminateKeyFromObject(state.doctorsByID, action.doctorDeleted.id)
 *      };
 */
export function eliminateKeyFromObject<T extends Record<K, unknown>, K extends keyof T>(o: T, key: K): T {
  const oCopy = { ...o };
  delete oCopy[key];
  return oCopy;
}

export function downloadBlobAsFile(blob: Blob, filename: string): void {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    a.remove();
  }, 1000);
}
export async function downloadDataURLasFile(dataURL: string, filename: string) {
  const blob = await (await fetch(dataURL)).blob();
  downloadBlobAsFile(blob, filename);
}

export async function logsToTextFile() {
  const currentLogs = await window.getAllBrowserLogs();

  const contentLines: string[] = [];
  currentLogs.forEach(line => {
    const d = new Date(line.timestamp);
    contentLines.push([`${d.toLocaleString()}`, line.value].join("\n"));
  });
  const content = contentLines.join("\n");
  const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
  const filename = `classviewlogs-${Date.now()}-${new Date().toISOString()}.txt`;
  downloadBlobAsFile(blob, filename);
}

// // dirty code on, not to worry, we've got everything under control
// export function fullscreenToggle(el?: string | HTMLElement): void {
//   const documentNode = window.document as any;
//   const canFullScreen =
//     documentNode.fullscreenElement || documentNode.mozFullScreenElement || documentNode.webkitFullscreenElement;
//   if (canFullScreen) {
//     if (documentNode.exitFullscreen) documentNode.exitFullscreen();
//     else if (documentNode.mozCancelFullScreen) documentNode.mozCancelFullScreen();
//     else if (documentNode.webkitExitFullscreen) documentNode.webkitExitFullscreen();
//   } else {
//     let element: any;
//     if (typeof el === "string") {
//       element = el ? document.getElementById(el) : null ?? document.body;
//     } else {
//       element = el;
//     }
//     if (!element) element = document.body;
//     if (element.requestFullscreen) element.requestFullscreen();
//     else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
//     else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
//     else if (element.msRequestFullscreen) element.msRequestFullscreen();
//   }
// }
// // dirty code off

export function randomId(): string {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

/** Simple object check. */
function isObject(item: unknown): boolean {
  return item && typeof item === "object" && !Array.isArray(item);
}

// TODO: write test for it
/**
 * Deep merge two objects.
 * @link https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
 */
export function mergeDeep<T>(target: T, ...sources: Partial<T>[]): T {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * check equality of each field for two objects.
 * @link https://stackoverflow.com/questions/25456013/javascript-deepequal-comparison/25456134#25456134
 */
export function deepEqual<T>(a: T, b: T): boolean {
  if (a === b) return true;
  else if (typeof a === "object" && a !== null && typeof b === "object" && b !== null) {
    if (Object.keys(a).length !== Object.keys(b).length) return false;

    for (const prop in a) {
      // eslint-disable-next-line no-prototype-builtins
      if (b.hasOwnProperty(prop)) {
        if (!deepEqual(a[prop], b[prop])) return false;
      } else return false;
    }

    if (a instanceof Date && b instanceof Date) {
      if (a.getTime() !== b.getTime()) return false;
    }

    return true;
  }

  return false;
}
