import { SUPPORT_INFORMATION } from "packages/utils";

import {
  fetchRecordingCloudfrontURLFromAPIEndpoint,
  fetchRecordingHash,
  generateRecordingAPIEndpointURL,
} from "packages/client/files/s3";

function generateBody(hash: string) {
  return `Link to ClassView session recording:\n\nhttps://${window.location.host}/replay/0?publicUrl=${hash}\n\nPlease note, this link will expire in 1 hour.\n\nIf you require help please contact ClassView support at ${SUPPORT_INFORMATION.mail} or ${SUPPORT_INFORMATION.tel}`;
}

function generateLink(hash: string) {
  return `mailto:?subject=ClassView%20recording&body=${encodeURI(generateBody(hash))}`;
}

export async function generateRecordingEmailInvitation(replayID: string) {
  const recordingAPIEndpointURL = generateRecordingAPIEndpointURL(replayID);
  const recordingCloudfrontURL = await fetchRecordingCloudfrontURLFromAPIEndpoint(recordingAPIEndpointURL);
  const recordingHash = await fetchRecordingHash(recordingCloudfrontURL);

  const linkHref = generateLink(recordingHash);

  const linkNode = document.createElement("a");
  linkNode.href = linkHref;
  const body = document.getElementsByTagName("body")[0];
  body.appendChild(linkNode);

  linkNode.click();

  window.setTimeout(() => body.removeChild(linkNode), 1000);
}
