import { useMergedClassNames } from "packages/utils";
import type { HTMLProps } from "react";

import styles from "./Q.module.scss";

export function Q({ className, ...rest }: HTMLProps<HTMLQuoteElement>) {
  const qClasses = useMergedClassNames({ className }, [styles.quote]);

  return <q className={qClasses} {...rest} />;
}
