import { EIcon } from "packages/utils";

import { useWhiteboardContext } from "packages/client/incall/contexts";

import { Tool } from "packages/client/incall/components";

export function ToolDrawing({ isCondensed }: { isCondensed: boolean }) {
  const { isPencilMode, setPencilMode } = useWhiteboardContext();

  if (isCondensed) {
    return (
      <Tool
        active={isPencilMode}
        icon={EIcon.EDIT2}
        inputType="button"
        label="Pencil"
        onClick={() => setPencilMode(!isPencilMode)}
      />
    );
  }

  return (
    <>
      <Tool
        active={!isPencilMode}
        icon={EIcon.MOVE}
        inputType="radio"
        label="Move tool"
        name="drawingTool"
        onChange={() => setPencilMode(false)}
      />
      <Tool
        active={isPencilMode}
        icon={EIcon.EDIT2}
        inputType="radio"
        label="Pencil"
        name="drawingTool"
        onChange={() => setPencilMode(true)}
      />
    </>
  );
}
