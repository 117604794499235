import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Block,
  Cell,
  Description,
  Grid,
  H1,
  H2,
  IconButton,
  LabelledContent,
  Loader,
  TextButton,
} from "packages/catalog";
import {
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  roomSystemConnectionType,
  roomSystemConnectionTypeLabel,
  SEGMENT_EDIT,
  TParams,
} from "packages/utils";

import { RoomSystemPrivacy } from "packages/client/graphql_definitions.generated";
import { useGetFullRoomSystemQuery } from "packages/client/room-systems/graphql/roomsystems.generated";
import { useGetEssentialOrganisationInfoOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { OrganisationSummary } from "packages/client/organisations/components";
import { PrivacyDescription } from "packages/client/room-systems/components";
import { RequireAuthorisation } from "packages/client/authentication/components";

export function RoomSystem() {
  const { roomsystemID }: TParams = useParams();

  const {
    data: roomSystemData,
    loading: roomSystemDataLoading,
    error: roomSystemDataError,
  } = useGetFullRoomSystemQuery({
    variables: {
      roomsystemID: parseInt(roomsystemID),
    },
  });
  const { data: userOwnData, loading: userOwnDataLoading } = useGetEssentialOrganisationInfoOwnUserQuery();

  // check if graphql fetch is blocked
  const isUnauthorised = useMemo(() => roomSystemDataError?.message === "Permission denied", [roomSystemDataError]);

  // user must be an admin of the room system's organisation or parent organisation
  const hasAdminRightsToOrganisation = useMemo(
    () =>
      userOwnData?.user?.isAdmin &&
      (roomSystemData?.roomSystem?.organisation?.id === userOwnData?.user?.organisation?.id ||
        roomSystemData?.roomSystem?.organisation?.parentOrganisation?.id === userOwnData?.user?.organisation?.id),
    [roomSystemData, userOwnData],
  );

  if (roomSystemDataLoading || userOwnDataLoading) return <Loader />;

  return (
    <RequireAuthorisation conditions={[hasAdminRightsToOrganisation, !isUnauthorised]}>
      <RoutingChangeHandler message="The Room System page has loaded." pageTitle="Room System" />
      <Grid>
        <Cell>
          <IconButton
            appearance={EAppearance.GHOST}
            as={Link}
            description="Room Systems"
            dimension={EDimension.SMALL}
            icon={EIcon.ARROWLEFT}
            label="Room Systems"
            status={EStatus.NEUTRAL}
            to=".."
          />
        </Cell>
        <Cell>
          <H1>Room System</H1>
        </Cell>
        <Cell as={Block} colSpanXL={5}>
          <Grid noColGap>
            <Cell>
              <H2>{roomSystemData?.roomSystem?.name}</H2>
            </Cell>
            <Cell>
              <LabelledContent
                content={<PrivacyDescription privacy={roomSystemData?.roomSystem?.privacy} />}
                label="Privacy level"
              />
              <Description>
                {roomSystemData?.roomSystem?.privacy === RoomSystemPrivacy.Organisation
                  ? "Accessible to your organisations and connections only"
                  : "Accessible to all organisations"}
              </Description>
            </Cell>
            <Cell>
              <LabelledContent content={roomSystemConnectionType[roomSystemData?.roomSystem?.type]} label="Type" />
            </Cell>
            <Cell>
              <LabelledContent
                content={roomSystemData?.roomSystem?.ip}
                label={roomSystemConnectionTypeLabel[roomSystemData?.roomSystem?.type]}
              />
            </Cell>
            <Cell>
              <LabelledContent
                content={<OrganisationSummary organisationID={roomSystemData?.roomSystem?.organisation?.id} />}
                label="Organisation"
              />
            </Cell>
            <Cell>
              <LabelledContent content={roomSystemData?.roomSystem?.notificationEmail} label="Email address" />
              <Description>Notifications for this Room System will be sent to this email address.</Description>
            </Cell>
          </Grid>
        </Cell>
        <Cell center>
          <TextButton as={Link} status={EStatus.ACCENT} to={SEGMENT_EDIT}>
            Edit
          </TextButton>
        </Cell>
      </Grid>
    </RequireAuthorisation>
  );
}
