import { useCallback, useMemo, useState } from "react";

import {
  Cell,
  Feedback,
  Field,
  Flexbox,
  Grid,
  H1,
  H2,
  IllustratedCTA,
  Input,
  Table,
  TD,
  TextButton,
  TR,
} from "packages/catalog";
import { EAppearance, EIllustration, EStatus, formatCompleteDate } from "packages/utils";

import type { IDjangoFeatureFlag } from "packages/client/feature-flags/django/types";

import { useFeatureFlags } from "packages/client/feature-flags/hooks";

import { RoutingChangeHandler } from "packages/client/layout/components";

const COLUMNS = ["Name", "Created", "Last edited", "Enable globally", "Enable for orgs"];
const COLUMN_WIDTHS = "20% repeat(auto-fit, minmax(5rem, 1fr))";

interface PEditableArrayField {
  onSave: (newValue: number[]) => void;
  value: number[];
}

function tryParsing(s: string): number[] | undefined {
  try {
    const newS = `[${s}]`;
    const d = JSON.parse(newS);
    if (!Array.isArray(d)) return undefined;
    for (const o of d) {
      if (typeof o !== "number") return undefined;
    }
    return d;
  } catch (e) {
    return undefined;
  }
}

function EditableArrayField({ onSave, value }: PEditableArrayField) {
  const [editable, setEditable] = useState(false);
  const [newValue, setValue] = useState(value.join(", "));

  const isValid = useMemo(() => !!tryParsing(newValue), [newValue]);

  function handleSave() {
    const v = tryParsing(newValue);
    if (!isValid || !v) return;
    onSave(v);
    setEditable(false);
  }

  const onCancel = useCallback(() => {
    setEditable(false);
    setValue(value.join(", "));
  }, [value]);

  if (!editable) {
    return (
      <span>
        {value.join(", ")}
        <TextButton appearance={EAppearance.GHOST} onClick={() => setEditable(true)}>
          Edit
        </TextButton>
      </span>
    );
  } else
    return (
      <Flexbox flexDirectionColumn>
        <Input name="flag" type="text" value={newValue} onChange={e => setValue(e.target.value)} />
        <Flexbox>
          <TextButton disabled={!isValid} onClick={handleSave} status={EStatus.ACCENT}>
            Save
          </TextButton>
          <TextButton appearance={EAppearance.GHOST} onClick={onCancel}>
            Cancel
          </TextButton>
        </Flexbox>
      </Flexbox>
    );
}

export function SuperAdminFeatureFlags() {
  const [flagName, setFlagName] = useState("");
  const { addFeatureFlag, editFeatureFlag, deleteFeatureFlag, flags } = useFeatureFlags();

  const handleCreateFlag = useCallback(() => {
    addFeatureFlag(flagName);
    setFlagName("");
  }, [addFeatureFlag, flagName]);

  const handleDeleteFlag = useCallback(
    (flag: IDjangoFeatureFlag) => {
      const isSure = window.confirm(`Are you sure you want to delete flag ${flag.name}?`);
      if (isSure) deleteFeatureFlag(flag);
    },
    [deleteFeatureFlag],
  );

  const isValidFlagName = useMemo(() => {
    if (flagName.length < 6) return false;
    if (flagName.includes(" ")) return false;
    return true;
  }, [flagName]);

  return (
    <>
      <RoutingChangeHandler message="The feature flags page has loaded." pageTitle="Feature flags" />
      <Grid>
        <Cell>
          <H1 hasMargin>Feature flags</H1>
          <Feedback status={EStatus.WARNING}>
            Feature flags are applied on all environments - dev, staging and live - so what is e.g. organisation 6 on
            dev can be a different organisation on live.
          </Feedback>
        </Cell>
        <Cell>
          <H2 hasMargin>All feature flags</H2>
          {flags.length ? (
            <Table columns={COLUMNS} columnWidths={COLUMN_WIDTHS} description="Feature flags in use" hasMenuColumn>
              {flags.map(flag => (
                <TR columnWidths={COLUMN_WIDTHS} key={flag.name}>
                  <TD heading={COLUMNS[0]}>{flag.name}</TD>
                  <TD heading={COLUMNS[1]}>{formatCompleteDate(new Date(flag.createdAt))}</TD>
                  <TD heading={COLUMNS[2]}>{flag.lastEdited ? formatCompleteDate(new Date(flag.lastEdited)) : ""}</TD>
                  <TD heading={COLUMNS[3]}>
                    <Input
                      checked={flag.enabled}
                      name="flag-enabled"
                      onChange={() => editFeatureFlag({ ...flag, enabled: !flag.enabled })}
                      type="checkbox"
                    />
                  </TD>
                  <TD heading={COLUMNS[4]}>
                    <EditableArrayField
                      value={flag.allowedOrgs ?? []}
                      onSave={newOrgs => editFeatureFlag({ ...flag, allowedOrgs: newOrgs })}
                    />
                  </TD>
                  <TD>
                    <TextButton onClick={() => handleDeleteFlag(flag)} status={EStatus.DANGER}>
                      Delete
                    </TextButton>
                  </TD>
                </TR>
              ))}
            </Table>
          ) : (
            <IllustratedCTA illustration={EIllustration.PEOPLE_COLLABORATING}>
              No flags have been created.
            </IllustratedCTA>
          )}
        </Cell>
        <Cell>
          <H2 hasMargin>Create a flag</H2>
          <Field
            alerts={[
              {
                message: "Name must be at least 6 characters and have no spaces",
                status: flagName.length ? (isValidFlagName ? EStatus.SUCCESS : EStatus.DANGER) : EStatus.NEUTRAL,
              },
            ]}
            label="Flag name">
            <Input
              name="flag-name"
              minLength={6}
              onChange={e => setFlagName(e.target.value)}
              type="text"
              value={flagName}
            />
          </Field>
        </Cell>
        <Cell>
          <TextButton disabled={!isValidFlagName} onClick={handleCreateFlag}>
            Create
          </TextButton>
        </Cell>
      </Grid>
    </>
  );
}
