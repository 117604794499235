import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";

import { EBackgroundColor } from "packages/utils";

// the following code has been modified from viydo's example mediapipe js file
let isStopped = true;
let backgroundColor = EBackgroundColor.GRAPE;

export const handleSetBackgroundColor = (color: EBackgroundColor) => {
  backgroundColor = color;
};

export const handleStopColouredBackground = () => {
  isStopped = true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getColouredStream = function (stream: any) {
  const selfieSegmentation = new SelfieSegmentation({
    locateFile: (file: string) => {
      return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
    },
  });

  selfieSegmentation.setOptions({
    modelSelection: 1,
  });

  const [videoTrack] = stream.getVideoTracks();
  const defaultConstraints = { width: 1280, height: 720 };
  const constraints = videoTrack?.getConstraints() || defaultConstraints;

  const videoElement = document.createElement("video");
  videoElement.width = defaultConstraints.width;
  videoElement.height = defaultConstraints.height;

  const canvasElement = document.createElement("canvas");
  canvasElement.width = constraints.width;
  canvasElement.height = constraints.height;

  const canvasCtx = canvasElement.getContext("2d");

  selfieSegmentation.onResults(function (results) {
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height);

    canvasCtx.globalCompositeOperation = "source-out";
    canvasCtx.fillStyle = `${backgroundColor}`;
    canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

    canvasCtx.globalCompositeOperation = "destination-atop";

    canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.restore();
  });

  videoElement.srcObject = stream;

  isStopped = false;

  const proc = () => {
    selfieSegmentation.send({ image: videoElement }).then(() => {
      if (!isStopped) {
        setTimeout(() => proc(), 1000 / 30);
      }
    });
  };

  videoElement.onloadedmetadata = function () {
    videoElement.play();
    setTimeout(function () {
      proc();
    }, 1000 / 30);
  };

  const str = canvasElement.captureStream(30);

  return Promise.resolve(str);
};
