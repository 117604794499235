import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useReducer, useState } from "react";

import {
  BigPopoverMenu,
  Block,
  Cell,
  Feedback,
  Field,
  Flexbox,
  FormActionModal,
  Grid,
  H1,
  H2,
  IconButton,
  IllustratedCTA,
  LabelledContent,
  Markdown,
  P,
  Pagination,
  PlainAction,
  Table,
  TR,
  TD,
  TextArea,
  TextButton,
  Input,
} from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EIllustration,
  EStatus,
  formatCompleteDate,
  formatISOShortDate,
} from "packages/utils";

import type { PublicAnnouncementInput } from "packages/client/graphql_definitions.generated";
import {
  FEssentialAnnouncementFragment,
  useGetAllAnnouncementsQuery,
  useDeleteAnnouncementMutation,
  useUpdateAnnouncementMutation,
} from "packages/client/announcements/graphql/announcements.generated";
import { useGetAllOrganisationsQuery } from "packages/client/organisations/graphql/organisations.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";

const COLUMNS = ["ID", "Start date", "End date"];
const COLUMN_WIDTHS = "50% repeat(auto-fit, minmax(5rem, 1fr))";

export function SuperAdminAnnouncements() {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<FEssentialAnnouncementFragment>(null);
  const [selectedOrganisationID, setSelectedOrganisationID] = useState<string>(null);

  const [editedAnnouncement, pushEditedAnnouncement] = useReducer(
    (s: PublicAnnouncementInput | null, a: Partial<PublicAnnouncementInput> | null): PublicAnnouncementInput | null => {
      if (!a) return null;
      else return { ...s, ...a };
    },
    null,
  );

  const { data: allOrganisationsData } = useGetAllOrganisationsQuery({
    variables: { page: currentPage },
  });
  const { data: allAnnouncementsData, refetch: refetchAllAnnouncements } = useGetAllAnnouncementsQuery({
    variables: { organisationID: selectedOrganisationID },
    fetchPolicy: "cache-and-network",
  });

  const [deleteAnnouncement] = useDeleteAnnouncementMutation();
  const [updateAnnouncement] = useUpdateAnnouncementMutation();

  const addAnnouncement = useCallback(() => {
    setSelectedAnnouncement(null);
    window.setTimeout(() => pushEditedAnnouncement({ organisationID: selectedOrganisationID, content: "" }), 250);
  }, [selectedOrganisationID]);

  const handleDeleteAnnouncement = useCallback(
    async (announcementID: string) => {
      try {
        const { errors } = await deleteAnnouncement({
          variables: { announcementID },
        });

        if (errors) throw new Error(JSON.stringify(errors));

        setSelectedAnnouncement(null);
        refetchAllAnnouncements();
      } catch (e) {
        console.error(e);
        window.alert("Error during update, more details in the console");
      }
    },
    [deleteAnnouncement, refetchAllAnnouncements],
  );

  const handleEndDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(new Date(e.currentTarget.value).setHours(23, 59));
    pushEditedAnnouncement({ endDate: newDate.toISOString() });
  }, []);

  const handleReset = useCallback(() => {
    setSelectedAnnouncement(null);
    pushEditedAnnouncement(null);
    setSelectedOrganisationID(null);
  }, []);

  const handleSelectOrganisation = useCallback((organisationID: string) => {
    setSelectedAnnouncement(null);
    setSelectedOrganisationID(organisationID);
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
        const { errors } = await updateAnnouncement({
          variables: { announcement: editedAnnouncement },
        });

        if (errors) throw new Error(JSON.stringify(errors));

        pushEditedAnnouncement(null);
        setSelectedAnnouncement(null);
        refetchAllAnnouncements();
      } catch (e) {
        console.error(e);
        window.alert("Error during update, more details in the console");
      }
    },
    [editedAnnouncement, refetchAllAnnouncements, updateAnnouncement],
  );

  const handleStartDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.currentTarget.value);
    pushEditedAnnouncement({ startDate: newDate.toISOString() });
  }, []);

  const handleTextAreaChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const t = e.target.value;
    const cleaned = t.replace(/[\n\r]/g, "\n");
    pushEditedAnnouncement({ content: cleaned });
  }, []);

  const announcementList = useMemo(() => {
    const announcements = allAnnouncementsData?.allPublicAnnouncements?.items ?? [];
    return announcements.map(announcement => (
      <TR columnWidths={COLUMN_WIDTHS} key={announcement.id}>
        <TD heading={COLUMNS[0]}>{announcement.id}</TD>
        <TD heading={COLUMNS[1]}>{formatCompleteDate(new Date(announcement.startDate))}</TD>
        <TD heading={COLUMNS[2]}>{formatCompleteDate(new Date(announcement.endDate))}</TD>
        <TD>
          <BigPopoverMenu
            attachment={EAttachment.SOUTH_SOUTH_EAST}
            trigger={
              <IconButton
                appearance={EAppearance.GHOST}
                as="div"
                description="Options"
                dimension={EDimension.SMALL}
                icon={EIcon.MOREVERTICAL}
                status={EStatus.NEUTRAL}
              />
            }>
            <Flexbox as="ul" flexDirectionColumn>
              <li>
                <PlainAction as="button" onClick={() => setSelectedAnnouncement(announcement)}>
                  Edit
                </PlainAction>
              </li>
              <li>
                <PlainAction
                  as="button"
                  onClick={() => handleDeleteAnnouncement(announcement.id)}
                  status={EStatus.DANGER}>
                  Delete
                </PlainAction>
              </li>
            </Flexbox>
          </BigPopoverMenu>
        </TD>
      </TR>
    ));
  }, [allAnnouncementsData, handleDeleteAnnouncement]);

  useEffect(() => {
    const copy = selectedAnnouncement ? { ...selectedAnnouncement } : null;
    if (copy) delete copy.__typename;
    pushEditedAnnouncement(copy);
  }, [selectedAnnouncement]);

  return (
    <>
      <RoutingChangeHandler message="The announcements page has loaded." pageTitle="Announcements" />
      <Grid>
        <Cell>
          <H1 hasMargin>Announcements</H1>
          <Feedback status={EStatus.WARNING}>
            If an announcement is not linked to an organisation, it will go out to all organisations.
          </Feedback>
        </Cell>
        <Cell>
          <Flexbox justifyContentSpaceBetween>
            <Flexbox alignItemsCenter>
              {selectedOrganisationID ? (
                <H2>Organisation {selectedOrganisationID} announcements</H2>
              ) : (
                <H2>Global announcements</H2>
              )}
              {selectedOrganisationID && (
                <TextButton appearance={EAppearance.GHOST} disabled={!selectedOrganisationID} onClick={handleReset}>
                  Reset to global
                </TextButton>
              )}
            </Flexbox>
            <TextButton onClick={addAnnouncement} status={EStatus.ACCENT}>
              {selectedOrganisationID ? "Create organisation announcement" : "Create a global announcement"}
            </TextButton>
          </Flexbox>
        </Cell>
        {announcementList.length ? (
          <Cell>
            <Table
              columns={COLUMNS}
              columnWidths={COLUMN_WIDTHS}
              description="A table listing live announcements"
              hasMenuColumn>
              {announcementList}
            </Table>
          </Cell>
        ) : (
          <Cell center>
            <IllustratedCTA illustration={EIllustration.WOMAN_SIGN} noPadding>
              No announcements live
            </IllustratedCTA>
          </Cell>
        )}
        {!selectedOrganisationID && (
          <Cell>
            <H2 hasMargin>Manage organisation announcements</H2>
            {allOrganisationsData?.allApprovedOrganisations?.objects?.map(organisation => (
              <P key={organisation.id}>
                <TextButton appearance={EAppearance.GHOST} onClick={() => handleSelectOrganisation(organisation.id)}>
                  {organisation.name}
                </TextButton>
              </P>
            ))}
            <ul></ul>
            {allOrganisationsData?.allApprovedOrganisations?.pages > 1 && (
              <Cell>
                <Pagination
                  hasNext={allOrganisationsData?.allApprovedOrganisations?.hasNext}
                  hasPrevious={allOrganisationsData?.allApprovedOrganisations?.hasPrev}
                  page={allOrganisationsData?.allApprovedOrganisations?.page}
                  pages={allOrganisationsData?.allApprovedOrganisations?.pages}
                  setPage={setCurrentPage}
                />
              </Cell>
            )}
          </Cell>
        )}
      </Grid>
      <FormActionModal
        ctaText={selectedAnnouncement ? "Edit" : "Create"}
        heading={`${selectedAnnouncement ? "Edit" : "Create an"} announcement`}
        isOpen={!!editedAnnouncement}
        onAbort={handleReset}
        onAction={handleSubmit}>
        <Grid>
          <Cell colSpan={6}>
            <Field description="Content can be formatted using Markdown" label="Announcement">
              <TextArea name="announcement" onChange={handleTextAreaChange} rows={8}>
                {editedAnnouncement?.content}
              </TextArea>
            </Field>
          </Cell>
          <Cell colSpan={6}>
            <Grid>
              <Cell colSpan={6}>
                <Field label="Start">
                  <Input
                    min={formatISOShortDate(new Date())}
                    name="start"
                    onChange={handleStartDateChange}
                    type="date"
                    value={editedAnnouncement?.startDate?.slice(0, 10) ?? formatISOShortDate(new Date())}
                  />
                </Field>
              </Cell>
              <Cell colSpan={6}>
                <Field label="End">
                  <Input
                    min={formatISOShortDate(new Date())}
                    name="end"
                    onChange={handleEndDateChange}
                    type="date"
                    value={editedAnnouncement?.endDate?.slice(0, 10) ?? "2243-10-17"}
                  />
                </Field>
              </Cell>
              <Cell>
                <LabelledContent
                  content={
                    <Block>
                      <Markdown value={editedAnnouncement?.content} />
                    </Block>
                  }
                  label="Preview"
                />
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </FormActionModal>
    </>
  );
}
