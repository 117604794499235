import { useCallback, useMemo } from "react";

import { dispatchVidyo, useSelectorVidyo } from "../../redux";
import { EVidyoLocalDevicesActions } from "../redux/types";
import { EDevice } from "../enums/EDevice";

export function useDevicesMutedState() {
  const {
    cameraServerMuted,
    microphoneServerMuted,
    shouldCameraBeMuted,
    shouldMicrophoneBeMuted,
    shouldSpeakerBeMuted,
  } = useSelectorVidyo(({ localDevices }) => ({
    cameraServerMuted: localDevices.cameras.serverMuted,
    microphoneServerMuted: localDevices.microphones.serverMuted,
    shouldCameraBeMuted: localDevices.cameras.shouldBeMuted,
    shouldMicrophoneBeMuted: localDevices.microphones.shouldBeMuted,
    shouldSpeakerBeMuted: localDevices.speakers.shouldBeMuted,
  }));

  const changeMutedState = useCallback(
    (deviceType: EDevice) => (shouldBeMuted: boolean) =>
      dispatchVidyo({
        type: EVidyoLocalDevicesActions.MUTE_DEVICE,
        deviceType,
        shouldBeMuted,
      }),
    [],
  );

  const setShouldCameraBeMuted = useMemo(() => changeMutedState(EDevice.CAMERA), [changeMutedState]);
  const setShouldMicrophoneBeMuted = useMemo(() => changeMutedState(EDevice.MICROPHONE), [changeMutedState]);
  const setShouldSpeakerBeMuted = useMemo(() => changeMutedState(EDevice.SPEAKER), [changeMutedState]);

  return {
    cameraServerMuted,
    microphoneServerMuted,
    setShouldCameraBeMuted,
    setShouldMicrophoneBeMuted,
    setShouldSpeakerBeMuted,
    shouldCameraBeMuted,
    shouldMicrophoneBeMuted,
    shouldSpeakerBeMuted,
  };
}
