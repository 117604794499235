import { Cell, Feedback, Flexbox, H1, LabelledContent, TextButton } from "packages/catalog";
import { EAppearance, EStatus, underscoredToTitleCase } from "packages/utils";

import type { SScreen } from "packages/client/onboarding/containers";
import { Screens } from "packages/client/onboarding/enums";

import { RoutingChangeHandler } from "packages/client/layout/components";

export interface PReview {
  cache: {
    [screen: number]: SScreen;
  };
  isOrgLoading: boolean;
  isUserLoading: boolean;
  onPrevious: () => void;
  onSubmit: () => void;
  orgError?: string;
  userError?: string;
}

export function Review({ cache, isOrgLoading, isUserLoading, orgError, userError, onPrevious, onSubmit }: PReview) {
  return (
    <>
      <RoutingChangeHandler message="The review page has loaded." pageTitle="Review your information" />
      <Cell>
        <H1>Review your information</H1>
      </Cell>
      <Cell>
        <LabelledContent
          label="Your name"
          content={`${cache[Screens.User].firstName} ${cache[Screens.User].lastName}`}
        />
      </Cell>
      <Cell>
        <LabelledContent label="Institutional email address" content={cache[Screens.User].email} />
      </Cell>
      {cache[Screens.User].userPhone && (
        <Cell>
          <LabelledContent label="Your telephone number" content={cache[Screens.User].userPhone} />
        </Cell>
      )}
      <Cell>
        <LabelledContent
          label="Organisation"
          content={
            <>
              {cache[Screens.CreateOrgStep1].orgName}{" "}
              {cache[Screens.CreateOrgStep1].selectedParentName &&
                `(${cache[Screens.CreateOrgStep1].selectedParentName})`}
            </>
          }
        />
      </Cell>
      <Cell>
        <LabelledContent label="Sector" content={underscoredToTitleCase(cache[Screens.CreateOrgStep1].sector)} />
      </Cell>
      {cache[Screens.CreateOrgStep2].orgPhone && (
        <Cell>
          <LabelledContent label="Organisation telephone number" content={cache[Screens.CreateOrgStep2].orgPhone} />
        </Cell>
      )}
      {cache[Screens.CreateOrgStep1].url && (
        <Cell>
          <LabelledContent label="Website URL" content={cache[Screens.CreateOrgStep1].url} />
        </Cell>
      )}
      <Cell>
        <LabelledContent
          label="Address"
          content={
            <>
              {cache[Screens.CreateOrgStep2].street}
              <br />
              {cache[Screens.CreateOrgStep2].city}
              <br />
              {cache[Screens.CreateOrgStep2].postcode}
              <br />
              {cache[Screens.CreateOrgStep2].county && (
                <>
                  {cache[Screens.CreateOrgStep2].county}
                  <br />
                </>
              )}
              {cache[Screens.CreateOrgStep2].country}
            </>
          }
        />
      </Cell>
      <Cell>
        <Flexbox justifyContentSpaceBetween>
          <TextButton appearance={EAppearance.GHOST} as="button" onClick={onPrevious}>
            Back
          </TextButton>
          <TextButton as="button" disabled={isOrgLoading || isUserLoading} onClick={onSubmit} status={EStatus.ACCENT}>
            Create your account
          </TextButton>
        </Flexbox>
      </Cell>
      {userError && (
        <Cell>
          <Feedback status={EStatus.DANGER}>{`${userError}. Please go back and update your input.`}</Feedback>
        </Cell>
      )}
      {orgError && (
        <Cell>
          <Feedback status={EStatus.DANGER}>{`${orgError}. Please go back and update your input.`}</Feedback>
        </Cell>
      )}
    </>
  );
}
