import { useCallback, useEffect, useMemo, useState } from "react";

import type { IDjangoFeatureFlag } from "packages/client/feature-flags/django/types";

import { addFlag, deleteFlag, editFlag, getAllFlags } from "packages/client/feature-flags/django/requests";

function sortFlags(a: IDjangoFeatureFlag, b: IDjangoFeatureFlag) {
  return a.createdAt.localeCompare(b.createdAt);
}

export function useFeatureFlags() {
  const [flags, setFlags] = useState<IDjangoFeatureFlag[]>([]);

  const addFeatureFlag = useCallback(async (flagName: string) => {
    await addFlag({ name: flagName });
    const newData = await getAllFlags();
    setFlags(newData);
  }, []);

  const deleteFeatureFlag = useCallback(
    async (flag: IDjangoFeatureFlag) => {
      const deleted = await deleteFlag(flag);
      if (deleted) setFlags(flags.filter(f => f.name !== flag.name));
    },
    [flags],
  );

  const editFeatureFlag = useCallback(
    async (flag: IDjangoFeatureFlag) => {
      const d = await editFlag(flag);
      setFlags([...flags.filter(f => f.name !== flag.name), d]);
    },
    [flags],
  );

  const sortedFlags = useMemo(() => flags.sort(sortFlags), [flags]);

  useEffect(() => {
    let cancelled = false;
    (async () => {
      const data = await getAllFlags();
      if (!cancelled) setFlags(data);
    })();
    return () => {
      cancelled = true;
    };
  }, []);

  return { addFeatureFlag, deleteFeatureFlag, editFeatureFlag, flags: sortedFlags };
}
