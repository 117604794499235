import type { InputHTMLAttributes } from "react";

import { IconButton, Input } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

import styles from "./Stepper.module.scss";

export interface PStepper extends InputHTMLAttributes<HTMLInputElement> {
  onDecrement: () => void;
  onIncrement: () => void;
}

export function Stepper({ disabled, max, min, onDecrement, onIncrement, value, ...rest }: PStepper) {
  return (
    <div className={styles.stepper}>
      <IconButton
        appearance={EAppearance.GHOST}
        as="button"
        description="Decrease"
        dimension={EDimension.SMALL}
        disabled={disabled || value === min}
        icon={EIcon.MINUS}
        onClick={onDecrement}
        status={EStatus.NEUTRAL}
      />
      <Input type="range" disabled={disabled} max={max} min={min} value={value} {...rest} />
      <IconButton
        appearance={EAppearance.GHOST}
        as="button"
        description="Increase"
        dimension={EDimension.SMALL}
        icon={EIcon.PLUS}
        status={EStatus.NEUTRAL}
        onClick={onIncrement}
        disabled={disabled || value === max}
      />
    </div>
  );
}
