import { ButtonHTMLAttributes, ComponentType, useMemo } from "react";
import type { LinkProps } from "react-router-dom";

import { ILocationState, EStatus, useStatusStyle } from "packages/utils";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./PlainAction.module.scss";

export interface PPlainAction extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: string | ComponentType<{ title?: string }>;
  children: string;
  download?: string;
  href?: string;
  rel?: string;
  state?: ILocationState;
  status?: EStatus;
  target?: string;
  to?: string | LinkProps["to"];
}

export function PlainAction({ as: Component = "button", children, status = EStatus.NEUTRAL, ...rest }: PPlainAction) {
  const statusStyle = useStatusStyle(styles);
  const classes = useMemo(() => [styles.button, statusStyle[status]].filter(e => e).join(" "), [status, statusStyle]);

  return (
    <Component className={classes} {...rest}>
      {children}
    </Component>
  );
}
