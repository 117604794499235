import { useState, useRef, useEffect, useCallback, ChangeEvent, FormEvent, SyntheticEvent } from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { Cell, Dialog, Flexbox, Grid, Input, Label, P, TextButton } from "packages/catalog";
import { ETheme } from "packages/theming";
import { EStatus, randomString } from "packages/utils";

import styles from "./ImageCropModal.module.scss";

const PNG_COMPRESSION_QUALITY = 0.75;
const PNG_SIZE_UPLOAD = 300;

export interface PImageCropModal {
  handleUpload: (f: FormData, filename: string) => void;
  isOpen: boolean;
  onAbort: () => void;
}

interface IFileInfo {
  filename: string;
  size: number;
  url: string;
}

const ImagePreview = ({ label, src, theme }: { label?: string; src: string; theme: ETheme }) => {
  const backgroundStyle = {
    [ETheme.DARK]: styles.dark,
    [ETheme.LIGHT]: styles.light,
  };

  return (
    <figure>
      <figcaption className={styles.figcaption}>{label}</figcaption>
      <div className={backgroundStyle[theme]}>
        <img alt="Click on the uploaded image to crop" className={styles.image} src={src} />
      </div>
    </figure>
  );
};

export function ImageCropModal({ handleUpload, isOpen, onAbort }: PImageCropModal) {
  const [crop, setCrop] = useState<Crop>(undefined);
  const [croppedImageSrc, setCroppedImageSrc] = useState("");
  const [fileInformation, setFileInformation] = useState<IFileInfo>(null);
  const [file, setFile] = useState<File>(null);

  const fileInputRef = useRef<HTMLInputElement>();
  const croppedImageRef = useRef<HTMLImageElement>();

  const cropImage = useCallback((currentCrop: Crop, currentFileInfo: IFileInfo) => {
    if (!croppedImageRef.current || !currentFileInfo?.url || currentCrop.width === 0 || currentCrop.height === 0) {
      setCroppedImageSrc("");

      return;
    }

    const imgNodeOrigin = croppedImageRef.current;
    const img = document.createElement("img");

    img.src = currentFileInfo.url;

    const leftDelta = imgNodeOrigin.offsetLeft;
    const topDelta = imgNodeOrigin.offsetTop;
    const scaleX = imgNodeOrigin.naturalWidth / imgNodeOrigin.width;
    const scaleY = imgNodeOrigin.naturalHeight / imgNodeOrigin.height;
    const canvas = document.createElement("canvas");

    canvas.width = PNG_SIZE_UPLOAD;
    canvas.height = PNG_SIZE_UPLOAD;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      img,
      (currentCrop.x - leftDelta) * scaleX,
      (currentCrop.y - topDelta) * scaleY,
      currentCrop.width * scaleX,
      currentCrop.height * scaleY,
      0,
      0,
      PNG_SIZE_UPLOAD,
      PNG_SIZE_UPLOAD,
    );

    const dataURL = canvas.toDataURL("image/png", PNG_COMPRESSION_QUALITY);

    setCroppedImageSrc(dataURL);
  }, []);

  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files.length > 0) setFile(files.item(0));
    else setFile(null);
  }, []);

  const onImageLoad = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;

    const cropInformation = centerCrop(
      makeAspectCrop({ unit: "%", width: 50, height: null, x: 50, y: 50 }, 1, width, height),
      width,
      height,
    );

    setCrop(cropInformation);
  }, []);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const req = await fetch(croppedImageSrc); // create blob from dataURL
      const blob = await req.blob();

      if (fileInformation && blob) {
        const formData = new FormData();

        formData.append("file", blob, fileInformation.filename);
        formData.append("filename", fileInformation.filename);

        handleUpload(formData, fileInformation.filename);
      }
    },
    [croppedImageSrc, fileInformation, handleUpload],
  );

  useEffect(() => {
    if (file) {
      const filename = `${randomString()}.png`;
      const url = window.URL.createObjectURL(file);

      setFileInformation({
        filename,
        size: file.size,
        url,
      });

      return () => window.URL.revokeObjectURL(url);
    } else {
      setFileInformation(null);
    }
  }, [file]);

  useEffect(() => {
    const timer = window.setTimeout(() => cropImage(crop, fileInformation), 250);

    return () => window.clearTimeout(timer);
  }, [crop, cropImage, fileInformation]);

  return (
    <Dialog h1="Image upload" isOpen={isOpen} onAbort={onAbort}>
      <form onSubmit={onSubmit}>
        <Grid noColGap>
          {fileInformation && (
            <>
              <Cell>
                <P center>Move and resize the selected area to adjust your image</P>
              </Cell>
              <Cell>
                <Grid>
                  <Cell colSpan={6}>
                    <ReactCrop
                      aspect={1}
                      circularCrop={true}
                      className={styles.cropComponent}
                      crop={crop}
                      minHeight={50}
                      minWidth={50}
                      onChange={setCrop}
                      onComplete={setCrop}
                      ruleOfThirds>
                      <img
                        alt="Your profile photo"
                        onLoad={onImageLoad}
                        ref={croppedImageRef}
                        src={fileInformation.url}
                      />
                    </ReactCrop>
                  </Cell>
                  <Cell center colSpan={6}>
                    <Flexbox alignItemsCenter flexDirectionColumn hasDoubleGap justifyContentCenter>
                      <ImagePreview label="Light mode preview" src={croppedImageSrc} theme={ETheme.LIGHT} />
                      <ImagePreview label="Dark mode preview" src={croppedImageSrc} theme={ETheme.DARK} />
                    </Flexbox>
                  </Cell>
                </Grid>
              </Cell>
            </>
          )}
          <Cell>
            <Flexbox justifyContentSpaceBetween>
              <Label isVisuallyHidden text="Choose file">
                <Input accept="image/*" name="file" onChange={onChangeInput} ref={fileInputRef} type="file" />
              </Label>
              {fileInformation && (
                <TextButton status={EStatus.ACCENT} type="submit">
                  Save &amp; upload
                </TextButton>
              )}
            </Flexbox>
          </Cell>
        </Grid>
      </form>
    </Dialog>
  );
}
