import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";

import { Block, Cell, Feedback, Fieldset, Grid, H1, Input, InputOption, Loader } from "packages/catalog";
import { ETheme } from "packages/theming";
import { scrollToContentTop, EStatus, LOCAL_THEME } from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { updateTheme } from "packages/client/layout/redux/slice";

import { editUser } from "packages/client/users/django/requests";

import { UserProfile, UserRecordingQuality } from "packages/client/graphql_definitions.generated";
import { useGetSettingsOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function UserSettings() {
  const [optIn, setOptIn] = useState<boolean>(false);
  const [privacyLevel, setPrivacyLevel] = useState<UserProfile>(UserProfile.Protected);
  const [recordingQuality, setRecordingQuality] = useState<UserRecordingQuality>(UserRecordingQuality.Low);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { data, loading, error, refetch } = useGetSettingsOwnUserQuery({ fetchPolicy: "cache-and-network" });
  const dispatch = useTypedDispatch();
  const { theme } = useTypedSelector(({ layout }) => ({
    theme: layout.theme,
  }));

  const otherTheme = useMemo(() => (theme === ETheme.LIGHT ? ETheme.DARK : ETheme.LIGHT), [theme]);

  const handleChangeTheme = useCallback(() => {
    dispatch(updateTheme(otherTheme));

    localStorage.setItem(LOCAL_THEME, otherTheme);
  }, [dispatch, otherTheme]);

  useEffect(() => {
    if (data && !loading) {
      setPrivacyLevel(data.user.profile);
      setRecordingQuality(data.user.recordingQuality);
      setOptIn(data.user.optIn);
    }
  }, [data, loading]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setShowErrorMessage(false);
      setShowSuccessMessage(false);
      editUser(data?.user?.id, { optIn, profile: privacyLevel, recordingQuality })
        .then(() => {
          refetch();
          scrollToContentTop();
          setShowSuccessMessage(true);
        })
        .catch(() => setShowErrorMessage(true));
    },
    [data, optIn, privacyLevel, recordingQuality, refetch],
  );

  const renderHint = useCallback((option): string => {
    switch (option) {
      case UserProfile.Protected:
        return "Only users within your organisation can see your profile";
      case UserProfile.Public:
        return "Any signed-in user can see your profile";
      default:
        return null;
    }
  }, []);

  return (
    <>
      {!data && loading && <Loader />}
      <RoutingChangeHandler message="The settings page has loaded." pageTitle="Settings" />
      <Grid noColGap>
        <Cell>
          <H1>Settings</H1>
        </Cell>
        {showErrorMessage && (
          <Cell>
            <Feedback status={EStatus.DANGER}>Unable to update profile. Please try again.</Feedback>
          </Cell>
        )}
        {showSuccessMessage && (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Profile updated.</Feedback>
          </Cell>
        )}
        {error && (
          <Cell>
            <Feedback status={EStatus.DANGER}>Unable to get your profile information. Please try again.</Feedback>
          </Cell>
        )}
        {data && !loading && (
          <Cell as="form" onSubmit={handleSubmit}>
            <Grid>
              <Cell>
                <Grid as={Block}>
                  <Cell>
                    <Fieldset label="Theme">
                      <InputOption label="Light theme">
                        <Input
                          checked={theme === ETheme.LIGHT}
                          name="theme"
                          type="radio"
                          onChange={handleChangeTheme}
                        />
                      </InputOption>
                      <InputOption label="Dark theme">
                        <Input checked={theme === ETheme.DARK} name="theme" onChange={handleChangeTheme} type="radio" />
                      </InputOption>
                    </Fieldset>
                  </Cell>
                  <Cell>
                    <Fieldset description={renderHint(privacyLevel)} label="Profile visibility">
                      <InputOption label="Public">
                        <Input
                          checked={privacyLevel === UserProfile.Public}
                          name="privacyStatus"
                          onChange={() => setPrivacyLevel(UserProfile.Public)}
                          type="radio"
                          value={UserProfile.Public}
                        />
                      </InputOption>
                      <InputOption label="Protected">
                        <Input
                          checked={privacyLevel === UserProfile.Protected}
                          name="privacyStatus"
                          onChange={() => setPrivacyLevel(UserProfile.Protected)}
                          type="radio"
                          value={UserProfile.Protected}
                        />
                      </InputOption>
                    </Fieldset>
                  </Cell>
                  <Cell>
                    <Fieldset label="Recording quality">
                      <InputOption label="High">
                        <Input
                          checked={recordingQuality === UserRecordingQuality.High}
                          name="recordingQuality"
                          onChange={() => setRecordingQuality(UserRecordingQuality.High)}
                          type="radio"
                          value={UserRecordingQuality.High}
                        />
                      </InputOption>
                      <InputOption label="Medium">
                        <Input
                          checked={recordingQuality === UserRecordingQuality.Medium}
                          name="recordingQuality"
                          onChange={() => setRecordingQuality(UserRecordingQuality.Medium)}
                          type="radio"
                          value={UserRecordingQuality.Medium}
                        />
                      </InputOption>
                      <InputOption label="Low">
                        <Input
                          checked={recordingQuality === UserRecordingQuality.Low}
                          name="recordingQuality"
                          onChange={() => setRecordingQuality(UserRecordingQuality.Low)}
                          type="radio"
                          value={UserRecordingQuality.Low}
                        />
                      </InputOption>
                    </Fieldset>
                  </Cell>
                  <Cell>
                    <Fieldset label="Email notification">
                      <InputOption label="I would like to OPT IN to receive marketing communications on the ClassView service such as feature updates and service improvements. I understand that if I don’t opt in, I will still be sent technical and service updates.">
                        <Input checked={optIn} name="OptIn" onChange={() => setOptIn(!optIn)} type="checkbox" />
                      </InputOption>
                    </Fieldset>
                  </Cell>
                </Grid>
              </Cell>
              <Cell center>
                <Input type="submit" value="Save" />
              </Cell>
            </Grid>
          </Cell>
        )}
      </Grid>
    </>
  );
}
