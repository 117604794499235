import { Link } from "react-router-dom";

import {
  Block,
  Cell,
  Description,
  Emoji,
  Flexbox,
  Grid,
  H1,
  H2,
  IconButton,
  IconContent,
  Loader,
  P,
  Statistic,
  StatusBadge,
  Strong,
} from "packages/catalog";
import {
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  PATH_ORGANISATION_EDIT,
  PATH_ORGANISATION_REPORT,
  PATH_USERS,
  SEGMENT_ALL,
  SEGMENT_CHILDREN,
  underscoredToTitleCase,
} from "packages/utils";

import { useGetFullOrganisationInfoOwnUserQuery } from "packages/client/users/graphql/users.generated";
import { useGetConnections } from "packages/client/organisations/hooks";
import { useGetPendingOrganisationsQuery } from "packages/client/organisations/graphql/organisations.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { OrganisationLogo } from "packages/client/organisations/components";
import { UserAvatarList } from "packages/client/users/components";

export function Overview() {
  const { data: organisationInfoOwnUserData, loading } = useGetFullOrganisationInfoOwnUserQuery();

  const { data: pendingOrganisationsData } = useGetPendingOrganisationsQuery({
    fetchPolicy: "cache-and-network",
    variables: { page: 1 },
  });

  const {
    connections: { connected, incoming },
  } = useGetConnections();

  if (!organisationInfoOwnUserData) return null;
  if (loading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler message="The organisation overview page has loaded." pageTitle="Organisation overview" />
      <Grid>
        <Cell as={Block} colSpanLG={8}>
          <Flexbox>
            <OrganisationLogo
              dimension={EDimension.LARGE}
              organisationID={organisationInfoOwnUserData?.user?.organisation?.id}
            />
            <Grid noColGap>
              <Cell>
                <Link to={organisationInfoOwnUserData?.user?.organisation?.id}>
                  <H1>
                    <Flexbox alignItemsCenter>
                      <span>{organisationInfoOwnUserData?.user?.organisation?.name}</span>
                      <StatusBadge status={EStatus.NEUTRAL}>
                        {underscoredToTitleCase(organisationInfoOwnUserData?.user?.organisation?.pricingPlan)}
                      </StatusBadge>
                    </Flexbox>
                  </H1>
                </Link>
                <Description>
                  {underscoredToTitleCase(organisationInfoOwnUserData?.user?.organisation?.sector)}
                </Description>
              </Cell>
              <Cell>
                <H2>Features</H2>
              </Cell>
              <Cell as={Grid}>
                <Cell colSpan={6}>
                  <IconContent
                    description={
                      organisationInfoOwnUserData?.user?.organisation?.recordingEnabled
                        ? "Sessions can be recorded"
                        : "Sessions cannot be recorded"
                    }
                    icon={
                      organisationInfoOwnUserData?.user?.organisation?.recordingEnabled
                        ? EIcon.CHECKCIRCLE
                        : EIcon.XCIRCLE
                    }>
                    <P>Recording allowed</P>
                  </IconContent>
                </Cell>
                <Cell colSpan={6}>
                  <IconContent
                    description={
                      organisationInfoOwnUserData?.user?.organisation?.rebranding ? "Rebranding on" : "Rebranding off"
                    }
                    icon={
                      organisationInfoOwnUserData?.user?.organisation?.rebranding ? EIcon.CHECKCIRCLE : EIcon.XCIRCLE
                    }>
                    <P>Custom application branding</P>
                  </IconContent>
                </Cell>
                {/* <Cell colSpan={6}>
                <IconContent
                  description={
                    organisationInfoOwnUserData?.user?.organisation?.webcastEnabled
                      ? "Livestreaming allowed"
                      : "Livestreaming not allowed"
                  }
                  icon={
                    organisationInfoOwnUserData?.user?.organisation?.webcastEnabled ? EIcon.CHECKCIRCLE : EIcon.XCIRCLE
                  }>
                  <P>Livestreaming allowed</P>
                </IconContent>
              </Cell> */}
              </Cell>
            </Grid>
          </Flexbox>
        </Cell>
        <Cell colSpanLG={4}>
          <Flexbox flexDirectionColumn hasDoubleGap>
            <H2>Options</H2>
            <IconButton
              appearance={EAppearance.OUTLINE}
              as={Link}
              description="Generate report"
              dimension={EDimension.SMALL}
              icon={EIcon.FILETEXT}
              label="Generate report"
              status={EStatus.NEUTRAL}
              to={PATH_ORGANISATION_REPORT(organisationInfoOwnUserData?.user?.organisation?.id)}
            />
            <IconButton
              appearance={EAppearance.OUTLINE}
              as={Link}
              description="Edit organisation profile"
              dimension={EDimension.SMALL}
              icon={EIcon.EDIT}
              label="Edit organisation profile"
              status={EStatus.NEUTRAL}
              to={PATH_ORGANISATION_EDIT(organisationInfoOwnUserData?.user?.organisation?.id)}
            />
            <IconButton
              appearance={EAppearance.OUTLINE}
              as={Link}
              description="View organisation profile"
              dimension={EDimension.SMALL}
              icon={EIcon.GLOBE}
              label="View organisation profile"
              status={EStatus.NEUTRAL}
              to={organisationInfoOwnUserData?.user?.organisation?.id}
            />
          </Flexbox>
        </Cell>
        <Cell as={Block} colSpanSM={6} colSpanLG={4}>
          <Grid noColGap>
            <Cell>
              <Statistic
                count={organisationInfoOwnUserData?.user?.organisation?.administrators?.length}
                text="administrators"
              />
            </Cell>
            <Cell>
              <P hasMargin>
                Administrators can moderate, edit and delete all sessions and manage users and Room Systems.
              </P>
              <UserAvatarList
                users={organisationInfoOwnUserData?.user?.organisation?.administrators?.map(administrator => ({
                  email: administrator.email,
                  firstName: administrator.firstName,
                  id: administrator.id,
                  lastName: administrator.lastName,
                  profilePicture: administrator.profilePicture,
                }))}
              />
            </Cell>
            <Cell>
              <IconButton
                appearance={EAppearance.OUTLINE}
                as={Link}
                description="Manage administrators"
                dimension={EDimension.SMALL}
                icon={EIcon.USERS}
                label="Manage users"
                status={EStatus.NEUTRAL}
                to={PATH_USERS}
              />
            </Cell>
          </Grid>
        </Cell>
        <Cell as={Block} colSpanSM={6} colSpanLG={4}>
          <Grid noColGap>
            <Cell>
              <Statistic count={connected.length} text="connections" />
              {incoming.length ? (
                <Description>
                  + <Strong>{incoming.length}</Strong> new connection request(s) <Emoji symbol="✨" />
                </Description>
              ) : null}
            </Cell>
            <Cell>
              <P>
                Connecting with other organisations allows you to invite their ClassView users and private Room Systems
                into your sessions.
              </P>
            </Cell>
            <Cell>
              <IconButton
                appearance={EAppearance.OUTLINE}
                as={Link}
                description="Manage connections"
                dimension={EDimension.SMALL}
                icon={EIcon.LINK}
                label="Manage connections"
                status={EStatus.NEUTRAL}
                to={SEGMENT_ALL}
              />
            </Cell>
          </Grid>
        </Cell>
        {organisationInfoOwnUserData?.user?.organisation?.organisationSet?.length ||
        pendingOrganisationsData?.nonApprovedOrganisations?.objects?.length ? (
          <Cell as={Block} colSpanSM={6} colSpanLG={4}>
            <Grid noColGap>
              <Cell>
                <Statistic
                  count={organisationInfoOwnUserData?.user?.organisation?.organisationSet?.length}
                  text="child organisations"
                />
                {pendingOrganisationsData?.nonApprovedOrganisations?.objects?.length ? (
                  <Description>
                    +{" "}
                    <Strong>
                      {pendingOrganisationsData?.nonApprovedOrganisations?.objects?.length <= 5
                        ? pendingOrganisationsData?.nonApprovedOrganisations?.objects?.length
                        : "5+"}
                    </Strong>{" "}
                    new pending child organisation(s) <Emoji symbol="✨" />
                  </Description>
                ) : null}
              </Cell>
              <Cell>
                <P>Allows you to split your estate into smaller organisations under one umbrella organisation.</P>
              </Cell>
              <Cell>
                <IconButton
                  appearance={EAppearance.OUTLINE}
                  as={Link}
                  description="Manage child organisations"
                  dimension={EDimension.SMALL}
                  icon={EIcon.GLOBE}
                  label="Manage child organisations"
                  status={EStatus.NEUTRAL}
                  to={SEGMENT_CHILDREN}
                />
              </Cell>
            </Grid>
          </Cell>
        ) : null}
      </Grid>
    </>
  );
}
