export interface PEmoji {
  description?: string;
  symbol: string;
}

export function Emoji({ description, symbol }: PEmoji) {
  return (
    <span aria-hidden={!description} role="img" title={description}>
      {symbol}
    </span>
  );
}
