import { useCallback, useMemo, useState } from "react";

import { Flexbox, IconButton, P, SelectList } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus, REGEX_EMAIL } from "packages/utils";

import styles from "./EditGuests.module.scss";

export interface IEmailGuest {
  id: string;
  value: string;
}

export interface PEditGuests {
  description?: string;
  label: string;
  onSelect: (sel: IEmailGuest[]) => void;
  placeholder?: string;
  preSelected: string[];
}

export function EditGuests({ label, description, placeholder, preSelected, onSelect }: PEditGuests) {
  const [suggested, setSuggested] = useState(null as IEmailGuest);

  const preSelectedFormatted: IEmailGuest[] = useMemo(
    () => preSelected.map(g => ({ id: `${g}`, value: g })),
    [preSelected],
  );

  const handleSearch = useCallback((input: string) => {
    const isEmail = !!REGEX_EMAIL.exec(input);
    setSuggested(isEmail ? { id: `${input}`.toLowerCase(), value: input } : null);
  }, []);

  return (
    <SelectList
      data={suggested ? [suggested] : []}
      description={description}
      label={label}
      onSearch={handleSearch}
      onSelect={onSelect}
      placeholder={placeholder}
      preSelected={preSelectedFormatted}
      renderDataAs={(data, addItem) => (
        <>
          {data.map((e: IEmailGuest) => (
            <IconButton
              appearance={EAppearance.GHOST}
              as="button"
              description={`Invite ${e.value}`}
              dimension={EDimension.SMALL}
              icon={EIcon.PLUS}
              key={e.id}
              label={`Invite ${e.value}`}
              onClick={() => addItem(e)}
              status={EStatus.NEUTRAL}
              type="button"
            />
          ))}
        </>
      )}
      renderSelectedAs={(selected, removeItem) => (
        <Flexbox as="ul" className={styles.selected} flexDirectionColumn>
          {selected.map(s => (
            <Flexbox as="li" alignItemsCenter key={s.id} justifyContentSpaceBetween>
              <P className={styles.email}>{s.value}</P>
              <IconButton
                appearance={EAppearance.GHOST}
                as="button"
                description={"Remove"}
                dimension={EDimension.SMALL}
                icon={EIcon.X}
                onClick={() => removeItem(s.id)}
                status={EStatus.DANGER}
                type="button"
              />
            </Flexbox>
          ))}
        </Flexbox>
      )}
    />
  );
}
