import { HTMLAttributes, ReactNode, useEffect, useMemo } from "react";

import { Flexbox, Icon, IconButton } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus, useStatusStyle } from "packages/utils";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Snackbar.module.scss";

export interface PSnackbar extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  dismissTimeout?: number;
  handleDismiss?: () => void;
  icon?: EIcon;
  pulse?: boolean;
  small?: boolean;
  status: EStatus;
  transparent?: boolean;
}

export function Snackbar({
  children,
  dismissTimeout = 3600000,
  handleDismiss,
  icon,
  pulse,
  small,
  status,
  transparent,
  ...rest
}: PSnackbar) {
  const snackbarStyles = useMemo(
    () => [styles.snackbar, small && styles.small, transparent && styles.transparent].filter(e => e).join(" "),
    [small, transparent],
  );
  const statusStyle = useStatusStyle(styles);
  const iconStyles = useMemo(
    () => [styles.icon, statusStyle[status], pulse && styles.pulse].filter(e => e).join(" "),
    [pulse, status, statusStyle],
  );

  useEffect(() => {
    const timeoutID = setTimeout(handleDismiss, dismissTimeout);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [dismissTimeout, handleDismiss]);

  return (
    <Flexbox alignItemsCenter className={snackbarStyles} role="alert" {...rest}>
      {icon && <Icon className={iconStyles} icon={icon} />}
      {children}
      {handleDismiss && (
        <IconButton
          appearance={EAppearance.GHOST}
          as="button"
          description="Dismiss"
          dimension={EDimension.SMALL}
          icon={EIcon.X}
          onClick={handleDismiss}
          status={EStatus.NEUTRAL}
        />
      )}
    </Flexbox>
  );
}
