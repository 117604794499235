import { useState } from "react";

import { Description, P } from "packages/catalog";

import { UserFeedbackModal } from "packages/client/feedback-form/components";
import { Meta, Version } from "packages/client/layout/components";

import styles from "./BasicFooter.module.scss";

export function BasicFooter() {
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);

  return (
    <>
      <footer className={styles.footer}>
        <P>
          <Description className={styles.meta}>
            <Meta setIsFeedbackFormOpen={() => setIsFeedbackFormOpen(true)} />
          </Description>
        </P>
        <P>
          <Description>
            <Version />
          </Description>
        </P>
      </footer>
      <UserFeedbackModal isOpen={isFeedbackFormOpen} onAbort={() => setIsFeedbackFormOpen(false)} />
    </>
  );
}
