import { useCallback, useEffect, useMemo, MouseEvent, useRef } from "react";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";

import {
  ECallStatus,
  useKeepDevicesActive,
  usePresenceInCall,
  useReversibleGridViewPreferred,
  VidyoCall,
} from "packages/call";
import { TParams, isMobile, PATH_POSTCALL, PATH_PRECALL_JOINCALL, PATH_CALLLINK } from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { IncallChatContext, RaisehandContext, VidyoContext, WhiteboardContext } from "packages/client/incall/contexts";

import { updatePIN, updateRoomKey } from "packages/client/sessions/redux/slice";

import { useAuth } from "packages/client/authentication/hooks";
import { usePowerSaverBlocker } from "packages/client/incall/hooks";

import { Controls, LetterboxTop, LeaveOptions, ModalCallError, Whiteboard } from "packages/client/incall/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./Call.module.scss";

function WhiteboardWithForcedGridView() {
  useReversibleGridViewPreferred(true);
  return <Whiteboard />;
}

export function Call() {
  const { clearGuestTokenIfNotSignedIn } = useAuth();

  const { callID }: TParams = useParams();

  const { roomKey, guestName, isLeaveOptionsOpen, isWhiteboardOpen, roomPin } = useTypedSelector(
    ({ authentication, layout, sessions }) => ({
      guestName: authentication.guestName,
      isLeaveOptionsOpen: layout.isLeaveOptionsOpen,
      isWhiteboardOpen: layout.isWhiteboardOpen,
      roomKey: sessions.roomKey,
      roomPin: sessions.pin,
    }),
  );

  // const { unlockRoom } = useWaitingRoom(roomKey);

  const { callStatus, reason } = usePresenceInCall({
    roomKey,
    roomPin,
    displayName: guestName,
  });
  useKeepDevicesActive();

  const dispatch = useTypedDispatch();
  const callContainerRef = useRef<HTMLDivElement>();

  const navigate = useNavigate();

  const containerStyles = useMemo(() => {
    return [
      styles.container,
      isWhiteboardOpen ? styles.containerWithWhiteboardVisible : "",
      isLeaveOptionsOpen ? styles.containerWithLeaveOptionsVisible : "",
      isWhiteboardOpen && isMobile ? styles.mobileWhiteboard : "", // prevent whiteboard to be to small when in landscapemode on mobile
    ].join(" ");
  }, [isLeaveOptionsOpen, isWhiteboardOpen]);

  useEffect(
    () => () => {
      clearGuestTokenIfNotSignedIn();
      dispatch(updateRoomKey(""));
      dispatch(updatePIN(""));
    },
    [clearGuestTokenIfNotSignedIn, dispatch],
  );
  // useEffect(() => {
  //   if (!room?.roomInfo?.waitingRoomEnabled || !isModerator) return;
  //   unlockRoom();
  //   const interval = setInterval(() => unlockRoom(), 30000);
  //   return () => clearInterval(interval);
  // }, [isModerator, room?.roomInfo?.waitingRoomEnabled, unlockRoom]);

  usePowerSaverBlocker();

  // prevent new window to appear on wheelclick electron
  const handleAuxClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    if (event.button === 1) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (!callStatus) return;

    if (callStatus === ECallStatus.KICKED || callStatus === ECallStatus.TERMINATED) navigate(PATH_POSTCALL(callID));
  }, [callID, callStatus, navigate]);

  // if we are not incall, on electron we want to join the call, on web we want to choose the method by which we join
  if (!roomKey || roomKey === "")
    return <Navigate to={IS_ELECTRON ? PATH_PRECALL_JOINCALL(callID) : PATH_CALLLINK(callID)} />;

  return (
    <VidyoContext>
      <WhiteboardContext>
        <IncallChatContext>
          <RaisehandContext>
            <div className={containerStyles} ref={callContainerRef} onAuxClick={handleAuxClick}>
              <LetterboxTop />
              <div className={styles.whiteboard}>{isWhiteboardOpen && <WhiteboardWithForcedGridView />}</div>
              <div className={styles.leaveOptions}>{isLeaveOptionsOpen && <LeaveOptions />}</div>
              <div className={styles.callPlacement}>
                <RoutingChangeHandler message="The session call has loaded." pageTitle="Session" />
                <VidyoCall />
              </div>
              <Outlet />
              <Controls />
              <ModalCallError isOpen={callStatus === ECallStatus.FAILURE} reason={reason} />
            </div>
          </RaisehandContext>
        </IncallChatContext>
      </WhiteboardContext>
    </VidyoContext>
  );
}
