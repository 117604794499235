import { Cell, Fieldset, Input, InputOption } from "packages/catalog";

import { ERecurringFrequency } from "packages/client/sessions/redux/types";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import { changeRecurringFrequency } from "packages/client/sessions/redux/slice";

export function EditRecurringFrequency() {
  const { recurringInterval } = useTypedSelector(({ sessionForm }) => ({
    recurringInterval: sessionForm.recurringInterval,
  }));
  const dispatch = useTypedDispatch();

  return (
    <Cell>
      <Fieldset label="Frequency">
        <InputOption label="Daily">
          <Input
            checked={recurringInterval === ERecurringFrequency.Daily}
            name="frequency"
            onChange={() => dispatch(changeRecurringFrequency(ERecurringFrequency.Daily))}
            type="radio"
          />
        </InputOption>
        <InputOption label="Weekly">
          <Input
            checked={recurringInterval === ERecurringFrequency.Weekly}
            name="frequency"
            onChange={() => dispatch(changeRecurringFrequency(ERecurringFrequency.Weekly))}
            type="radio"
          />
        </InputOption>
        <InputOption label="Monthly">
          <Input
            checked={recurringInterval === ERecurringFrequency.Monthly}
            name="frequency"
            onChange={() => dispatch(changeRecurringFrequency(ERecurringFrequency.Monthly))}
            type="radio"
          />
        </InputOption>
      </Fieldset>
    </Cell>
  );
}
