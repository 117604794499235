import type { InputHTMLAttributes, ReactNode } from "react";

import type { Input } from "packages/catalog";

import styles from "./PillOption.module.scss";

export interface PPillOption extends InputHTMLAttributes<HTMLElement> {
  children: ReactChild<typeof Input>;
  label: string | ReactNode;
  title?: string;
}

export function PillOption({ children, label, title }: PPillOption) {
  return (
    <label className={styles.container} title={title}>
      {children}
      <span className={`${styles.label}${children.props.required ? ` ${styles.required}` : ""}`}>{label}</span>
    </label>
  );
}
