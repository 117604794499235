import { useMemo } from "react";
import { Link } from "react-router-dom";

import { Block, Cell, Description, Flexbox, H2, IconButton, P, SmallPopoverMenu, Strong } from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  formatLongDate,
  formatLongTime,
  PATH_RECORDING,
} from "packages/utils";

import { RoomType } from "packages/client/graphql_definitions.generated";
import { generateDownloadLink, generateRecordingThumbnailURL } from "packages/client/recordings/s3";

import { generateRecordingEmailInvitation } from "packages/client/recordings/functions";

import { UserAvatar } from "packages/client/users/components";

import styles from "./RecordingListItem.module.scss";

export interface PRecordingListItem {
  duration?: string;
  id: string;
  isOwner?: boolean;
  name?: string;
  ownerEmail: string;
  ownerFullName: string;
  ownerOrganisationName: string;
  prepareDeleteRecording: (recordingID: string) => void;
  recordingID: string;
  roomType?: RoomType;
  startTime: string;
}

function getHeaderClassName(roomType?: RoomType): string {
  switch (roomType) {
    case RoomType.LectureMode:
      return styles.lecture;
    case RoomType.Webcast:
      return styles.webcast;
    case RoomType.Meeting:
      return styles.meeting;
    case RoomType.Hub:
      return styles.hub;
    default:
      return styles.default;
  }
}

function formatDuration(duration: string) {
  const individualBits = duration.split(":");

  // return formatted duration for <time> element
  return `${individualBits[0]}h ${individualBits[1]}m ${individualBits[2]}s`;
}

export function RecordingListItem({
  duration,
  id,
  isOwner,
  name,
  ownerEmail,
  ownerFullName,
  ownerOrganisationName,
  prepareDeleteRecording,
  recordingID,
  roomType,
  startTime,
}: PRecordingListItem) {
  const formattedDuration = useMemo(() => formatDuration(duration), [duration]);
  const formattedStartDate = useMemo(() => formatLongDate(new Date(startTime)), [startTime]);
  const formattedStartTime = useMemo(() => formatLongTime(new Date(startTime)), [startTime]);
  const title = useMemo(
    () => (name ? name : roomType === RoomType.Hub ? "Personal Hub" : "[room deleted]"),
    [name, roomType],
  );
  const thumbnailSRC = useMemo(() => generateRecordingThumbnailURL(recordingID), [recordingID]);

  const headerClasses = useMemo(() => [styles.header, getHeaderClassName(roomType)].join(" "), [roomType]);

  return (
    <Cell as="li" colSpan={12} colSpanMD={6} colSpanXL={4}>
      <Block>
        <Link to={PATH_RECORDING(recordingID)}>
          <header className={headerClasses}>
            {recordingID?.length && <img alt={title || "Recording"} className={styles.image} src={thumbnailSRC} />}
            <time className={styles.duration} dateTime={formattedDuration}>
              {duration}
            </time>
          </header>
        </Link>
        <main className={styles.main}>
          <Flexbox alignItemsCenter justifyContentSpaceBetween>
            <Link to={PATH_RECORDING(recordingID)}>
              <H2>{title}</H2>
            </Link>
            {isOwner && (
              <SmallPopoverMenu
                attachment={EAttachment.SOUTH_SOUTH_EAST}
                options={[
                  {
                    onClick: () => generateRecordingEmailInvitation(`${recordingID}.mp4`),
                    status: EStatus.NEUTRAL,
                    text: "Share",
                  },
                  {
                    as: "a",
                    download: `${recordingID}.mp4`,
                    href: generateDownloadLink(recordingID),
                    status: EStatus.NEUTRAL,
                    text: "Download",
                  },
                  {
                    onClick: () => prepareDeleteRecording(id),
                    status: EStatus.DANGER,
                    text: "Delete",
                  },
                ]}
                trigger={
                  <IconButton
                    as="div"
                    description="Options"
                    status={EStatus.NEUTRAL}
                    icon={EIcon.MOREVERTICAL}
                    appearance={EAppearance.GHOST}
                    dimension={EDimension.SMALL}
                  />
                }
              />
            )}
          </Flexbox>
          {ownerFullName && (
            <Flexbox alignItemsCenter hasHalfGap>
              <UserAvatar dimension={EDimension.EXTRA_SMALL} email={ownerEmail} fullName={ownerFullName} />
              <P>
                <Description>
                  <Strong>{ownerFullName}</Strong> &#x2022; {ownerOrganisationName}
                </Description>
              </P>
            </Flexbox>
          )}
          <Flexbox alignItemsCenter justifyContentSpaceBetween>
            <P>
              <Description>
                {formattedStartDate} at {formattedStartTime}
              </Description>
            </P>
            <P>
              <Description>{recordingID}.mp4</Description>
            </P>
          </Flexbox>
        </main>
      </Block>
    </Cell>
  );
}
