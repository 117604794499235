import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { EAppearance, EAttachment, EDimension, EIcon, EMessageOrder, EStatus } from "packages/utils";

import {
  Bubble,
  PBubble,
  ClosableHeading,
  IconButton,
  Markdown,
  SidebarFooter,
  SidebarHeader,
  SidebarMain,
  SmallPopoverMenu,
} from "packages/catalog";

import type { Message } from "packages/client/graphql_definitions.generated";

import { useTypedSelector } from "packages/client/redux";
import { useIncallChatContext } from "packages/client/incall/contexts";
import { useGetEssentialUserQuery } from "packages/client/users/graphql/users.generated";
import { useRoom } from "packages/client/incall/hooks";

import { ChatMessageTextArea } from "packages/client/incall/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./IncallChat.module.scss";

type PMessage = Omit<PBubble, "message" | "author" | "renderProfilePic"> & { message: Message };

function AuthorisedMessage({ message, ...rest }: PMessage) {
  const senderID = parseInt(message.sender, 10);
  const { data } = useGetEssentialUserQuery({
    variables: {
      userID: senderID,
    },
    returnPartialData: true,
  });
  const author: PBubble["author"] = useMemo(
    () =>
      data?.user
        ? { fullName: `${data?.user?.firstName} ${data?.user?.lastName}`, id: senderID }
        : { id: senderID, fullName: "Loading" },
    [data?.user, senderID],
  );

  return (
    <Bubble {...rest} author={author} message={message.content.text}>
      <Markdown value={message.content.text} />
    </Bubble>
  );
}

function GuestMessage({ message, ...rest }: PMessage) {
  const author = useMemo(() => ({ fullName: message.sender, id: message.sender }), [message.sender]);

  return (
    <Bubble {...rest} author={author} message={message.content.text}>
      <Markdown value={message.content.text} />
    </Bubble>
  );
}
export function IncallChat() {
  const { userName, userID, isSignedIn } = useTypedSelector(({ authentication }) => ({
    isSignedIn: authentication.isSignedIn,
    userID: authentication.userID,
    userName: authentication.guestName,
  }));
  const { isModerator } = useRoom();

  const meSender = useMemo(() => (isSignedIn ? userID : userName), [isSignedIn, userID, userName]);

  const { deleteAllMessages, deleteMessage, messages } = useIncallChatContext();

  const [isScrollAtBottom, setIsScrollAtBottom] = useState(true);

  const messagesDivRef = useRef<HTMLDivElement>();
  const bottomRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!bottomRef.current) return;

    const observer = new IntersectionObserver(entries => {
      setIsScrollAtBottom(entries[0].isIntersecting);
    });

    observer.observe(bottomRef.current);
  });

  const scrollToBottom = useCallback(() => {
    if (messagesDivRef.current && bottomRef.current) {
      bottomRef.current.scrollIntoView(false);
    }
  }, []);

  useEffect(() => {
    if (!messagesDivRef.current) return;
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (isScrollAtBottom && entry) {
          scrollToBottom();
        }
      }
    });
    resizeObserver.observe(messagesDivRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [messages, isScrollAtBottom, scrollToBottom]);

  const messagesOrdering = useMemo(() => {
    const orders = new Map<string, EMessageOrder>();
    let prevSender: string | null = null;
    let nextSender: string | null;
    messages.forEach((m, i) => {
      const currentSender = m.sender;
      let order = EMessageOrder.FIRSTANDLAST;
      if (i > 0) prevSender = messages[i - 1].sender;
      nextSender = i < messages.length - 1 ? messages[i + 1].sender : null;
      if (prevSender !== currentSender && nextSender === currentSender) order = EMessageOrder.FIRST;
      else if (prevSender === currentSender && currentSender === nextSender) order = EMessageOrder.NTH;
      else if (prevSender === currentSender && currentSender !== nextSender) order = EMessageOrder.LAST;
      orders.set(m.datetime, order);
    });
    return Object.fromEntries(orders);
  }, [messages]);

  const messageISOTime = useMemo(
    () =>
      messages.reduce<Record<string, string>>((acc, m) => {
        const timeInMs = parseInt(m.datetime) / 10 ** 6;
        const messageDateTime = new Date(timeInMs);
        acc[m.datetime] = messageDateTime.toISOString();
        return acc;
      }, {}),
    [messages],
  );

  return (
    <>
      <RoutingChangeHandler message="The chat has loaded." />
      <SidebarHeader>
        <ClosableHeading to="..">
          <div className={styles.deleteChatContainer}>
            Chat
            {isModerator && (
              <SmallPopoverMenu
                attachment={EAttachment.SOUTH_SOUTH_EAST}
                options={[
                  {
                    onClick: deleteAllMessages,
                    status: EStatus.DANGER,
                    text: "Clear chat",
                  },
                ]}
                trigger={
                  <IconButton
                    as="div"
                    description="More options"
                    status={EStatus.NEUTRAL}
                    icon={EIcon.MOREVERTICAL}
                    appearance={EAppearance.GHOST}
                    dimension={EDimension.SMALL}
                  />
                }
              />
            )}
          </div>
        </ClosableHeading>
      </SidebarHeader>
      <SidebarMain ref={messagesDivRef}>
        {messages.map(message => {
          const Renderer = message.guest ? GuestMessage : AuthorisedMessage;
          const isMine = message.sender === meSender;
          return (
            <Renderer
              key={message.datetime}
              datetime={messageISOTime[message.datetime]}
              isAuthorMe={isMine}
              isAdmin={isModerator}
              message={message}
              messageOrder={messagesOrdering[message.datetime]}
              onDeleteClick={() => deleteMessage(message.datetime)}
              isPinned={false}
              containerRef={messagesDivRef}
            />
          );
        })}
        <div ref={bottomRef} style={{ height: "0.5rem", scrollSnapAlign: `${isScrollAtBottom ? "start" : "none"}` }} />
      </SidebarMain>
      <SidebarFooter>
        <ChatMessageTextArea scrollToBottom={scrollToBottom} />
      </SidebarFooter>
    </>
  );
}
