import { useTypedSelector } from "packages/client/redux";

import { useGetDialInQuery } from "packages/client/incall/graphql/room.generated";

export function useDialIn(roomKey: string, forceSkip = false) {
  const { isSignedIn } = useTypedSelector(({ authentication }) => ({
    isSignedIn: authentication.isSignedIn,
  }));

  const { data } = useGetDialInQuery({
    variables: {
      roomKey,
    },
    returnPartialData: true,
    skip: !isSignedIn || !roomKey || forceSkip,
  });
  return data?.getAllRoomInfo?.dialInDetails;
}
