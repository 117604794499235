export const REGEX_COUNTY = /^[a-zA-Z ]+$/;

export const REGEX_EMAIL = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const REGEX_TELEPHONE = /^(\+*)((\s?)([0-9]|\([0-9]\))(\s?))*$/;

export const REGEX_POSTCODE = /^[A-Za-z0-9\s]+$/;

export const REGEX_URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
