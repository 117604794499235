import { Cell, Feedback, H1, P } from "packages/catalog";
import { EStatus } from "packages/utils";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function AccountCreated() {
  return (
    <>
      <RoutingChangeHandler message="The account created page has loaded." pageTitle="Account created" />
      <Cell>
        <H1 center>{"Nearly there..."}</H1>
      </Cell>
      <Cell>
        <Feedback status={EStatus.SUCCESS}>Account created pending email address verification.</Feedback>
      </Cell>
      <Cell>
        <P>
          {
            "We've sent you a link to verify your email address. Please check your email and follow the link to complete your account creation."
          }
        </P>
      </Cell>
    </>
  );
}
