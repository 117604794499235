import type { ReactNode } from "react";

import styles from "./TRTDTemp.module.scss";

export interface PTRTDTemp {
  children: ReactNode;
}

export function TRTDTemp({ children }: PTRTDTemp) {
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>{children}</td>
    </tr>
  );
}
