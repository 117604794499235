import type { InputHTMLAttributes } from "react";

import type { Rating } from "packages/catalog";

import styles from "./RatingOption.module.scss";

export interface PRatingOption extends InputHTMLAttributes<HTMLElement> {
  children: ReactChild<typeof Rating>;
  label: string;
}

export function RatingOption({ children, label }: PRatingOption) {
  return (
    <label className={styles.ratingOption}>
      {children}
      <span className={styles.label}>{label}</span>
    </label>
  );
}
