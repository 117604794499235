import { Link } from "react-router-dom";

import {
  BigPopoverMenu,
  Flexbox,
  IconButton,
  InternalTextLink,
  P,
  PlainAction,
  StatusBadge,
  Strong,
  TD,
  TR,
} from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  PATH_SUPERADMIN_ORGANISATION,
  PATH_SUPERADMIN_ROOMSYSTEM_EDIT,
  roomSystemConnectionType,
} from "packages/utils";

import type { RoomSystemPrivacy, RoomSystemType } from "packages/client/graphql_definitions.generated";

import { PrivacyDescription } from "packages/client/room-systems/components/PrivacyDescription/PrivacyDescription";

export interface PSuperAdminRoomSystemRow {
  columns: string[];
  columnWidths: string;
  hideOrganisation?: boolean;
  ip: string;
  name: string;
  organisationID?: string;
  organisationName?: string;
  prepareDeleteRoomSystem: (roomSystemID: string) => void;
  privacy: RoomSystemPrivacy;
  roomSystemID: string;
  status: string;
  type: RoomSystemType;
}

export function SuperAdminRoomSystemRow({
  columns,
  columnWidths,
  hideOrganisation,
  ip,
  name,
  organisationID,
  organisationName,
  prepareDeleteRoomSystem,
  privacy,
  roomSystemID,
  status,
  type,
}: PSuperAdminRoomSystemRow) {
  return (
    <TR columnWidths={columnWidths} key={roomSystemID}>
      <TD heading={columns[0]}>
        <Strong>
          <P>{roomSystemID}</P>
        </Strong>
      </TD>
      <TD heading={columns[1]}>
        <P>
          <InternalTextLink to={PATH_SUPERADMIN_ROOMSYSTEM_EDIT(roomSystemID)}>{name}</InternalTextLink>
          <PrivacyDescription privacy={privacy} />
        </P>
      </TD>
      <TD heading={columns[2]}>
        {!status ? (
          "-"
        ) : status === "Offline" ? (
          <StatusBadge status={EStatus.DANGER}>Offline</StatusBadge>
        ) : (
          <StatusBadge status={EStatus.SUCCESS}>Online</StatusBadge>
        )}
      </TD>
      <TD heading={columns[3]}>
        <P>{roomSystemConnectionType[type]}</P>
      </TD>
      <TD heading={columns[4]}>
        <P>{ip}</P>
      </TD>
      {!hideOrganisation && (
        <TD heading={columns[5]}>
          <P>
            <InternalTextLink to={PATH_SUPERADMIN_ORGANISATION(organisationID)}>{organisationName}</InternalTextLink>
          </P>
        </TD>
      )}
      <TD>
        <BigPopoverMenu
          attachment={EAttachment.SOUTH_SOUTH_EAST}
          trigger={
            <IconButton
              appearance={EAppearance.GHOST}
              as="div"
              description="Options"
              dimension={EDimension.SMALL}
              icon={EIcon.MOREVERTICAL}
              status={EStatus.NEUTRAL}
            />
          }>
          <Flexbox as="ul" flexDirectionColumn>
            <li>
              <PlainAction as={Link} to={PATH_SUPERADMIN_ROOMSYSTEM_EDIT(roomSystemID)}>
                Edit
              </PlainAction>
            </li>
            <li>
              <PlainAction as="button" onClick={() => prepareDeleteRoomSystem(roomSystemID)} status={EStatus.DANGER}>
                Delete
              </PlainAction>
            </li>
          </Flexbox>
        </BigPopoverMenu>
      </TD>
    </TR>
  );
}
