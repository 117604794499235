import { Cell, Feedback, Flexbox, Field, Grid, Input, LabelledContent, P, TextButton } from "packages/catalog";
import { EAppearance, EStatus } from "packages/utils";

export interface POrgLookup {
  error?: string;
  handleInputChange: (input: string) => void;
  handleSelection: (selected: { id: number; name: string }) => void;
  isFetching?: boolean;
  optional?: boolean;
  orgs: {
    id: number;
    name: string;
  }[];
  selected?: {
    id: number | string | boolean;
    name: string;
  };
  title: string;
}

export function OrgLookup({
  handleInputChange,
  handleSelection,
  orgs,
  title,
  isFetching,
  error,
  selected,
}: POrgLookup) {
  return (
    <Grid noColGap>
      {!selected ? (
        <>
          <Cell>
            <Field label={title}>
              <Input
                name="organisation"
                onChange={e => handleInputChange(e.currentTarget.value.trim())}
                type="search"
              />
            </Field>
          </Cell>
          {error ? (
            <Cell>
              <Feedback status={EStatus.DANGER}>{error}</Feedback>
            </Cell>
          ) : !isFetching ? (
            orgs.length ? (
              <Cell>
                <ul>
                  {orgs.map(o => (
                    <li key={o.id}>
                      <TextButton
                        appearance={EAppearance.GHOST}
                        onClick={() =>
                          handleSelection(selected && o.id === selected.id ? null : { id: o.id, name: o.name })
                        }>
                        {o.name}
                      </TextButton>
                    </li>
                  ))}
                </ul>
              </Cell>
            ) : null
          ) : (
            <Cell>
              <P>Searching organisations...</P>
            </Cell>
          )}
        </>
      ) : (
        <Cell>
          <LabelledContent
            label={title}
            content={
              <Flexbox alignItemsCenter>
                {selected ? selected.name : null}{" "}
                <TextButton appearance={EAppearance.GHOST} onClick={() => handleSelection(null)}>
                  Change organisation
                </TextButton>
              </Flexbox>
            }
          />
        </Cell>
      )}
    </Grid>
  );
}
