import { Outlet } from "react-router-dom";

import { Cell, Grid, NavigationList, NavigationLink } from "packages/catalog";
import { PATH_PROFILE_EDIT, SEGMENT_CHANGEPASSWORD, SEGMENT_SETTINGS } from "packages/utils";

export function UserSettingsLayout() {
  return (
    <Grid>
      <Cell>
        <NavigationList>
          <NavigationLink to={PATH_PROFILE_EDIT}>Edit profile</NavigationLink>
          <NavigationLink to={SEGMENT_CHANGEPASSWORD}>Change password</NavigationLink>
          <NavigationLink to={SEGMENT_SETTINGS}>Settings</NavigationLink>
        </NavigationList>
      </Cell>
      <Cell>
        <Outlet />
      </Cell>
    </Grid>
  );
}
