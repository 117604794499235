import { Link } from "react-router-dom";

import {
  BigPopoverMenu,
  Flexbox,
  IconButton,
  InternalTextLink,
  P,
  PlainAction,
  Strong,
  TD,
  TR,
} from "packages/catalog";
import {
  EAttachment,
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  PATH_SUPERADMIN_ORGANISATION,
  PATH_SUPERADMIN_ORGANISATION_REPORT,
  PATH_SUPERADMIN_ORGANISATIONS_DELETE,
} from "packages/utils";

export interface PSuperAdminOrganisationRow {
  columns: string[];
  columnWidths: string;
  hideParent?: boolean;
  organisationID: string;
  organisationName: string;
  parentOrganisationID?: string;
  parentOrganisationName?: string;
}

export function SuperAdminOrganisationRow({
  columns,
  columnWidths,
  hideParent,
  organisationID,
  organisationName,
  parentOrganisationID,
  parentOrganisationName,
}: PSuperAdminOrganisationRow) {
  return (
    <TR columnWidths={columnWidths} key={organisationID}>
      <TD heading={columns[0]}>
        <Strong>
          <P>{organisationID}</P>
        </Strong>
      </TD>
      <TD heading={columns[1]}>
        <P>
          <InternalTextLink to={PATH_SUPERADMIN_ORGANISATION(organisationID)}>{organisationName}</InternalTextLink>
        </P>
      </TD>
      {!hideParent && (
        <TD heading={columns[3]}>
          {parentOrganisationName ? (
            <P>
              <InternalTextLink to={PATH_SUPERADMIN_ORGANISATION(parentOrganisationID)}>
                {parentOrganisationName}
              </InternalTextLink>
            </P>
          ) : (
            "-"
          )}
        </TD>
      )}
      <TD>
        <BigPopoverMenu
          attachment={EAttachment.SOUTH_SOUTH_EAST}
          trigger={
            <IconButton
              appearance={EAppearance.GHOST}
              as="div"
              description="Options"
              dimension={EDimension.SMALL}
              icon={EIcon.MOREVERTICAL}
              status={EStatus.NEUTRAL}
            />
          }>
          <Flexbox as="ul" flexDirectionColumn>
            <li>
              <PlainAction as={Link} to={PATH_SUPERADMIN_ORGANISATION(organisationID)}>
                Edit
              </PlainAction>
            </li>
            <li>
              <PlainAction as={Link} to={PATH_SUPERADMIN_ORGANISATION_REPORT(organisationID)}>
                Generate report
              </PlainAction>
            </li>
            <li>
              <PlainAction
                as={Link}
                state={{ organisationID }}
                status={EStatus.DANGER}
                to={PATH_SUPERADMIN_ORGANISATIONS_DELETE}>
                Delete
              </PlainAction>
            </li>
          </Flexbox>
        </BigPopoverMenu>
      </TD>
    </TR>
  );
}
