import { HEADER_JSON, IFeatureFlag } from "packages/utils";

import { fetchDjangoAPIAuthenticated } from "packages/client/fetch";

export async function getFeatureFlag(flag: string) {
  const resp = await fetchDjangoAPIAuthenticated(`/flags/${flag}/`, {
    headers: HEADER_JSON,
  });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp.json() as Promise<IFeatureFlag>;
}
