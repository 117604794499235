import { Cell, Feedback } from "packages/catalog";
import { EStatus } from "packages/utils";

import { useTypedSelector } from "packages/client/redux";

import { EditRecurringEnd, EditRecurringFrequency, EditRecurringOccurrence } from "packages/client/sessions/components";

export interface PEditRecurring {
  isEditMode: boolean;
}

export function EditRecurring({ isEditMode }: PEditRecurring) {
  const { recurringInterval } = useTypedSelector(({ sessionForm }) => ({
    recurringInterval: sessionForm.recurringInterval,
  }));

  if (!recurringInterval) return null;

  return (
    <>
      <Cell>
        <Feedback status={EStatus.WARNING}>
          Please note, repeating sessions cannot have their time extended while the session is live. If you would like
          to edit the start or end times, please do so from the dashboard once the session has ended.
        </Feedback>
      </Cell>
      <EditRecurringFrequency />
      <EditRecurringOccurrence isEditMode={isEditMode} />
      <EditRecurringEnd isEditMode={isEditMode} />
    </>
  );
}
