import { Description } from "packages/catalog";
import { logsToTextFile } from "packages/utils";

import styles from "./DownloadErrorReport.module.scss";

export function DownloadErrorReport() {
  if (IS_ELECTRON) return null;

  return (
    <Description>
      Experiencing a technical problem?{" "}
      <button className={styles.button} onClick={() => logsToTextFile()}>
        Download an error report
      </button>
    </Description>
  );
}
