import type { FormEvent, ReactNode } from "react";

import { Cell, Dialog, Flexbox, Grid, Input, TextButton } from "packages/catalog";
import { EAppearance, EStatus } from "packages/utils";

import { RoutingChangeHandler } from "packages/client/layout/components";

export interface PFormActionModal {
  children: ReactNode;
  ctaDisabled?: boolean;
  ctaText: string;
  heading: string;
  isOpen: boolean;
  onAbort: () => void;
  onAction: (e: FormEvent<HTMLFormElement>) => void;
}

export function FormActionModal({
  children,
  ctaDisabled,
  ctaText,
  heading,
  isOpen,
  onAbort,
  onAction,
}: PFormActionModal) {
  return (
    <Dialog h1={heading} isOpen={isOpen} onAbort={onAbort}>
      <RoutingChangeHandler message={`The ${heading} modal has loaded`} />
      <Grid as="form" onSubmit={onAction}>
        <Cell as="main">{children}</Cell>
        <Cell as="footer">
          <Flexbox justifyContentEnd>
            <TextButton appearance={EAppearance.GHOST} onClick={onAbort} status={EStatus.NEUTRAL} type="button">
              Cancel
            </TextButton>
            <Input disabled={ctaDisabled} type="submit" value={ctaText} />
          </Flexbox>
        </Cell>
      </Grid>
    </Dialog>
  );
}
