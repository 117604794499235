import {
  ComponentType,
  FocusEvent,
  HTMLProps,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { LinkProps } from "react-router-dom";

import { Flexbox, IconButton, PlainAction } from "packages/catalog";
import { EAttachment, handlePlacement, EStatus } from "packages/utils";

import styles from "./SmallPopoverMenu.module.scss";

export interface PSmallPopoverMenu extends HTMLProps<HTMLDetailsElement> {
  attachment: EAttachment;
  trigger: ReactChild<typeof IconButton>;
  options: {
    as?: string | ComponentType;
    download?: string;
    href?: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    status: EStatus;
    text: string;
    to?: string | LinkProps["to"];
  }[];
  shouldScrollIntoView?: boolean;
}

export function SmallPopoverMenu({ attachment, options, trigger, shouldScrollIntoView }: PSmallPopoverMenu) {
  const [currentHeight, setCurrentHeight] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(0);
  const timeoutID = useRef(null);

  const detailsRef = useRef<HTMLDetailsElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let f: number;
    let w: number;
    let h: number;
    function resize() {
      if (detailsRef.current) {
        const { width, height } = detailsRef.current.getBoundingClientRect();
        if (w !== width || h !== height) {
          w = width;
          h = height;
          setCurrentWidth(width);
          setCurrentHeight(height);
        }
      }
      f = requestAnimationFrame(resize);
    }
    f = requestAnimationFrame(resize);
    return () => {
      cancelAnimationFrame(f);
    };
  }, []);

  const contentStyle = useMemo(() => {
    if (currentWidth === 0 || currentHeight === 0) {
      return { display: "none" };
    }

    return handlePlacement(attachment, currentWidth, currentHeight);
  }, [attachment, currentHeight, currentWidth]);

  const onBlurHandler = useCallback(() => {
    timeoutID.current = setTimeout(() => {
      detailsRef.current.open = false;
    });
  }, []);

  const onFocusHandler = useCallback(() => {
    clearTimeout(timeoutID.current);
  }, []);

  const onClickHandler = useCallback(
    onClick => {
      if (!onClick) return;
      onClick();
      onBlurHandler();
    },
    [onBlurHandler],
  );

  const scrollIntoView = useCallback((e: SyntheticEvent<HTMLDetailsElement>) => {
    const target = e.currentTarget;
    if (target.open) {
      detailsRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }
  }, []);

  const onSummaryBlur = useCallback(
    (e: FocusEvent<HTMLElement>) => {
      //only close details when the blur target is outside the menu otherwise the onClickHandler isn't fired in Safari (tabIndex on the div is to make it the relatedTarget)
      const shouldBlur = (e.relatedTarget as HTMLElement) !== listRef.current ?? false;
      if (shouldBlur) onBlurHandler();
    },
    [onBlurHandler],
  );

  return (
    <details className={styles.details} ref={detailsRef} onToggle={shouldScrollIntoView ? scrollIntoView : null}>
      <summary className={styles.summary} onBlur={onSummaryBlur} onFocus={onFocusHandler}>
        {trigger}
      </summary>
      <Flexbox className={styles.content} flexDirectionColumn ref={listRef} style={contentStyle} tabIndex={-1}>
        {options
          .filter(e => e)
          .map((option, i) => (
            <PlainAction
              as={option.as ?? "button"}
              download={option.download}
              href={option.href}
              key={i}
              onBlur={onBlurHandler}
              onClick={() => onClickHandler(option.onClick)}
              onFocus={onFocusHandler}
              status={option.status}
              to={option.to}>
              {option.text}
            </PlainAction>
          ))}
      </Flexbox>
    </details>
  );
}
