import { RoomSystemPrivacy, RoomSystemType } from "packages/client/graphql_definitions.generated";
import {
  EDjangoRoomSystemPrivacy,
  EDjangoRoomSystemType,
  IDjangoRoomSystem,
} from "packages/client/room-systems/django/types";
import type { IMutableRoomSystem } from "packages/client/room-systems/graphql/types";

const mapRoomSystemPrivacy: Record<RoomSystemPrivacy, EDjangoRoomSystemPrivacy> = {
  [RoomSystemPrivacy.Organisation]: EDjangoRoomSystemPrivacy.Organisation,
  [RoomSystemPrivacy.Public]: EDjangoRoomSystemPrivacy.Public,
};

const mapRoomSystemType: Record<RoomSystemType, EDjangoRoomSystemType> = {
  [RoomSystemType.H323]: EDjangoRoomSystemType.H323,
  [RoomSystemType.Sip]: EDjangoRoomSystemType.Sip,
  [RoomSystemType.Vsceneroom]: EDjangoRoomSystemType.Vsceneroom,
};

export function toDjangoRoomSystem(data: Partial<IMutableRoomSystem>): IDjangoRoomSystem {
  return {
    id: data.id.length ? parseInt(data.id) : null,
    ip: data.ip,
    name: data.name,
    notification_email: data.notificationEmail,
    organisation: parseInt(data.organisationID) ?? null,
    privacy: mapRoomSystemPrivacy[data.privacy],
    type: mapRoomSystemType[data.type],
  };
}
