import { InMemoryCache, FieldMergeFunction } from "@apollo/client";
import type { TypedTypePolicies } from "./apolloHelpers.generated";

// messages are normalized, so items list in paginated queries are reference arrays
// const messageArrayMerging: FieldMergeFunction<Reference[]> = (existing: Reference[] = [], incoming: Reference[]) => {
//   // console.log(existing, incoming);
//   const mapKey = new Map<string, Reference>();
//   existing.forEach(e => mapKey.set(e.__ref, e));
//   incoming.forEach(e => mapKey.set(e.__ref, e));
//   return Array.from(mapKey.values());
// };

const preferIncomingMerging: FieldMergeFunction = (_existing, incoming) => incoming;

const typePolicies: TypedTypePolicies = {
  Message: {
    keyFields: ["convID", "datetime"],
  },
  Conversation: {
    keyFields: ["convID"],
  },
  ReadHistoryItem: {
    keyFields: false, // no normalization as part of Conversation object
  },
  DialInDetails: {
    keyFields: false, // keep part of room query
  },
  RoomInfo: {
    keyFields: ["key"],
  },
  RoomTypeLinking: {
    keyFields: false, // keep part of room query
  },
  GetAllRoomInfoRes: {
    keyFields: ["roomId"],
  },
  Query: {
    fields: {
      getRaisedHands: {
        merge: preferIncomingMerging,
      },
      getMyPollsV2: {
        keyArgs: false,
        merge(existing, incoming) {
          if (existing) {
            return { ...incoming, items: [...(existing?.items ?? []), ...(incoming?.items ?? [])] };
          }
          return incoming;
        },
      },
    },
  },
  Subscription: {
    fields: {
      getRaisedHandsUpdate: {
        merge: preferIncomingMerging,
      },
    },
  },
  DynamoPaginatedMessages: {
    fields: {
      items: {
        merge: preferIncomingMerging,
      },
    },
  },
  CollabMessagesAnswer: {
    fields: {
      items: {
        merge: preferIncomingMerging,
      },
    },
  },
  DirectCallDetails: {
    keyFields: ["callID"],
  },
};

export const cache = new InMemoryCache({
  addTypename: true,
  typePolicies,
});
