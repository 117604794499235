import { useCallback } from "react";
import { Link } from "react-router-dom";

import { Flexbox, PlainAction, Snackbar } from "packages/catalog";
import { EStatus } from "packages/utils";

import { useSnackbarFeedbackContext } from "packages/client/snackbar-feedback/contexts";

import type { ISnackbarAction } from "packages/client/snackbar-feedback/redux/types";

import styles from "./SnackbarFeedback.module.scss";

const TIME_OUT = 5000;

export function SnackbarFeedback() {
  const { dismissSnackbar, snackbars } = useSnackbarFeedbackContext();

  const handleAction = useCallback(
    (id: string, action: ISnackbarAction) => {
      if (action.onTrigger) action.onTrigger();
      window.setTimeout(() => dismissSnackbar(id), 50);
    },
    [dismissSnackbar],
  );

  return (
    <div className={styles.anchor}>
      {snackbars?.map(notification => (
        <div key={notification.id} className={styles.snackbar}>
          <Snackbar
            dismissTimeout={notification.props?.dismissTimeout || TIME_OUT}
            handleDismiss={() => dismissSnackbar(notification.id)}
            status={EStatus.NEUTRAL}>
            <Flexbox className={styles.snackbarContent}>
              <div className={styles.message}>{notification.content}</div>
              <Flexbox alignItemsCenter>
                {notification.actions?.map(action => (
                  <PlainAction
                    as={action.props.as === "Link" ? Link : action.props.as}
                    key={action.id}
                    onClick={() => handleAction(notification.id, action)}
                    status={action.props.status}
                    to={action.props.to}>
                    {action.content}
                  </PlainAction>
                ))}
              </Flexbox>
            </Flexbox>
          </Snackbar>
        </div>
      ))}
    </div>
  );
}
