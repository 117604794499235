import type { InputHTMLAttributes } from "react";

import { Reaction } from "packages/catalog";
import type { EReaction } from "packages/utils";

import styles from "./Rating.module.scss";

export interface PRating extends InputHTMLAttributes<HTMLElement> {
  icon: EReaction;
  value: string;
}

export function Rating({ icon, value, ...rest }: PRating) {
  return (
    <>
      <input className={styles.input} type="radio" {...rest} value={value} />
      <Reaction className={styles.icon} reaction={icon} />
    </>
  );
}
