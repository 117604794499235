export const defaultRules = {
  max: {
    message: "Your input is too long.",
    value: 75,
  },
  min: {
    message: "Input is required.",
    value: 1,
  },
};
