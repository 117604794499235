import type { DetailedHTMLProps } from "react";

import type { LI } from "packages/catalog";
import { useMergedClassNames } from "packages/utils";

import styles from "./OL.module.scss";

export interface POL extends DetailedHTMLProps<React.OlHTMLAttributes<HTMLOListElement>, HTMLOListElement> {
  children: OneOrManyReactChildren<typeof LI>;
  hasMargin?: boolean;
}

export function OL({ children, className, hasMargin, ...rest }: POL) {
  const olClasses = useMergedClassNames({ className }, [styles.ol, hasMargin && styles.hasMargin]);

  return (
    <ol className={olClasses} {...rest}>
      {children}
    </ol>
  );
}
