import { ChangeEvent, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import {
  Cell,
  ConfirmActionModal,
  Feedback,
  Flexbox,
  Grid,
  H1,
  IllustratedCTA,
  Input,
  Label,
  Loader,
  Pagination,
  TextButton,
} from "packages/catalog";
import { EIllustration, EStatus, isNowBetweenStartAndEnd, scrollToContentTop, SEGMENT_SCHEDULE } from "packages/utils";

import type { FEssentialSessionFragment } from "packages/client/sessions/graphql/sessions.generated";

import { deleteSession } from "packages/client/sessions/django/requests";

import { useGetSessionsQuery } from "packages/client/sessions/graphql/sessions.generated";
import { useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SessionListItem } from "packages/client/sessions/components";

export function Sessions() {
  const [actionID, setActionID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchString, setDebounceSearchString] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] = useState(false);
  const [showDeleteErrorMessage, setShowDeleteErrorMessage] = useState(false);

  const {
    data: allSessionsData,
    loading,
    refetch: refetchAllSessions,
  } = useGetSessionsQuery({
    variables: { page: currentPage, search: debouncedSearchString },
  });
  const { data: userOwnData } = useGetEssentialOwnUserQuery();

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value);
  }, []);

  const handleDeleteSession = useCallback(() => {
    deleteSession(actionID)
      .then(() => {
        scrollToContentTop();
        refetchAllSessions();
        setShowDeleteSuccessMessage(true);
      })
      .catch(() => {
        scrollToContentTop();
        setShowDeleteErrorMessage(true);
      });
    setActionID("");
    setIsDeleteModalOpen(false);
  }, [actionID, refetchAllSessions]);

  const prepareDeleteSession = useCallback((sessionID: string) => {
    setShowDeleteSuccessMessage(false);
    setShowDeleteErrorMessage(false);
    setActionID(sessionID);
    setIsDeleteModalOpen(true);
  }, []);

  useEffect(() => {
    let cancelled = false;

    window.setTimeout(() => {
      if (!cancelled) setDebounceSearchString(searchString);
    }, 500);

    return () => {
      cancelled = true;
    };
  }, [searchString]);

  function canCancel(session: FEssentialSessionFragment) {
    if (!userOwnData) return false;
    if (userOwnData?.user?.isAdmin || userOwnData?.user?.id === session?.meetingOrganiser?.id) return true;
    return false;
  }

  function canEdit(session: FEssentialSessionFragment) {
    if (!userOwnData) return false;
    if (userOwnData?.user?.isAdmin || userOwnData?.user.id === session.meetingOrganiser.id) {
      /**
       * TEMPORARILY DISABLED EDIT FOR LIVE SESSIONS
       */
      if (session.nextRecurringStart && session.nextRecurringEnd) {
        if (!isNowBetweenStartAndEnd(new Date(session.nextRecurringStart), new Date(session.nextRecurringEnd))) {
          return true;
        }
      } else {
        if (!isNowBetweenStartAndEnd(new Date(session.startTime), new Date(session.endTime))) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  function canModerate(session: FEssentialSessionFragment) {
    if (!userOwnData) return false;
    if (session.meetingOrganiser.id === userOwnData?.user?.id) return true;
    if (session.moderators?.map(m => m.id).includes(userOwnData?.user?.id)) return true;
    if (userOwnData?.user?.isAdmin) return true;
    return false;
  }

  return (
    <>
      <RoutingChangeHandler message="The sessions page has loaded." pageTitle="Sessions" />
      <Grid noColGap>
        {showDeleteSuccessMessage && (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Session deleted.</Feedback>
          </Cell>
        )}
        {showDeleteErrorMessage && (
          <Cell>
            <Feedback status={EStatus.DANGER}>Unable to delete session. Please try again.</Feedback>
          </Cell>
        )}
        <Cell>
          <Flexbox alignItemsCenter justifyContentSpaceBetween>
            <H1>{userOwnData?.user?.isAdmin ? "All ClassView sessions" : "ClassView sessions"}</H1>
            <Flexbox alignItemsCenter>
              <Label htmlFor="search" isVisuallyHidden text="Search sessions" />
              <Input id="search" name="search" onChange={handleChangeSearch} type="search" />
              <TextButton as={Link} status={EStatus.ACCENT} to={SEGMENT_SCHEDULE}>
                Schedule
              </TextButton>
            </Flexbox>
          </Flexbox>
        </Cell>
        {loading ? (
          <Cell>
            <Loader />
          </Cell>
        ) : (
          <Cell>
            <Grid as="ol" noColGap>
              <>
                {allSessionsData?.allFutureMeetings?.objects?.length
                  ? allSessionsData?.allFutureMeetings?.objects?.map(session => {
                      return (
                        <SessionListItem
                          canCancel={canCancel(session)}
                          canEdit={canEdit(session)}
                          canModerate={canModerate(session)}
                          endTimeUTC={session.endTime}
                          isRepeating={!!session.nextRecurringStart}
                          key={session.id}
                          meetingID={session.id}
                          nextRecurringEnd={session.nextRecurringEnd}
                          nextRecurringStart={session.nextRecurringStart}
                          roomKey={session.room?.key}
                          setToBeDeleted={prepareDeleteSession}
                          startTimeUTC={session.startTime}
                          title={session.subject}
                          user={{
                            firstName: session.meetingOrganiser.firstName,
                            lastName: session.meetingOrganiser.lastName,
                            email: session.meetingOrganiser.email,
                            id: session.meetingOrganiser.id,
                            organisationName: session.meetingOrganiser.organisation.name,
                            profilePictureURL: session.meetingOrganiser.profilePicture,
                          }}
                        />
                      );
                    })
                  : !debouncedSearchString.length &&
                    !loading && (
                      <Cell center>
                        <IllustratedCTA illustration={EIllustration.SUNNY_DAY}>
                          You have no sessions scheduled. Enjoy your day!
                        </IllustratedCTA>
                      </Cell>
                    )}
              </>
              {debouncedSearchString.length && !allSessionsData?.allFutureMeetings?.objects.length && !loading ? (
                <Cell center>
                  <IllustratedCTA illustration={EIllustration.GROUP_VIDEO}>No sessions found.</IllustratedCTA>
                </Cell>
              ) : null}
            </Grid>
          </Cell>
        )}
        {allSessionsData?.allFutureMeetings?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={allSessionsData?.allFutureMeetings?.hasNext}
              hasPrevious={allSessionsData?.allFutureMeetings?.hasPrev}
              page={allSessionsData?.allFutureMeetings?.page}
              pages={allSessionsData?.allFutureMeetings?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="session"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteSession}
        status={EStatus.DANGER}
      />
    </>
  );
}
