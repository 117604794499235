import type { HTMLProps } from "react";

import { useMergedClassNames } from "packages/utils";

import styles from "./H3.module.scss";

export interface H3Props extends HTMLProps<HTMLHeadingElement> {
  hasMargin?: boolean;
}

export function H3({ className, hasMargin, ...rest }: H3Props) {
  const h3Classes = useMergedClassNames({ className }, [styles.h3, hasMargin && styles.hasMargin]);

  return <h3 {...rest} className={h3Classes} />;
}
