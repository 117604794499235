import { useMemo } from "react";
import type { HTMLAttributes } from "react";

import { Flexbox, H3, Icon, LI, Q, UL, StatusBadge } from "packages/catalog";
import { EIcon, ELocale, pluralise, EStatus } from "packages/utils";

import styles from "./AnswerResult.module.scss";

export interface PAnswerResult extends HTMLAttributes<HTMLDetailsElement> {
  answer: string;
  defaultOpen?: boolean;
  isCorrect?: boolean;
  percentage: number;
  responses?: IResponse[];
  votes: number;
}

export interface IResponse {
  customAnswer?: string;
  respondent?: string;
}

export function AnswerResult({ answer, defaultOpen, isCorrect, percentage, responses, votes, ...rest }: PAnswerResult) {
  const votesText = useMemo(() => pluralise(votes, ELocale.EN_GB, "vote", "votes"), [votes]);

  const resultClasses = useMemo(() => [styles.result, votes && styles.colours].filter(e => e).join(" "), [votes]);

  const results = useMemo(
    () =>
      responses?.length && (
        <UL>
          {responses.map((response, i) => (
            <LI key={i}>
              {response.respondent && response.respondent}
              {response.respondent && response.customAnswer ? " - " : null}
              {response.customAnswer && <Q>{response.customAnswer}</Q>}
            </LI>
          ))}
        </UL>
      ),
    [responses],
  );

  return (
    <div className={resultClasses} data-correct={isCorrect}>
      {isCorrect && (
        <StatusBadge data-correct-badge status={EStatus.SUCCESS}>
          Correct
        </StatusBadge>
      )}
      <details className={styles.details} open={defaultOpen} {...rest}>
        <summary className={styles.summary} role={!responses ? "none" : "button"} tabIndex={!responses ? -1 : 0}>
          <Flexbox alignItemsCenter className={styles.overview} justifyContentSpaceBetween>
            <H3>{answer}</H3>
            <div className={styles.info}>
              <p className={styles.percentage}>{percentage ?? 0}%</p>
              <p className={styles.votes}>{votesText}</p>
              {!!responses?.length && <Icon aria-hidden="true" className={styles.icon} icon={EIcon.CHEVRONDOWN} />}
            </div>
          </Flexbox>
        </summary>
        <div className={styles.inner}>{results}</div>
      </details>
      <meter className={styles.meter} min="0" max="100" title={`${percentage}% of total votes`} value={percentage}>
        <div className={styles.meterFallback}>
          <span className={styles.meterBarFallback} style={{ width: `${percentage}%` }}></span>
        </div>
      </meter>
    </div>
  );
}
