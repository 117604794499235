import { useCallback, useEffect, useState } from "react";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import type { PollStatusV2 } from "packages/client/graphql_definitions.generated";

import { useGetAllPollsQuery, useUpdatePollStatusMutation } from "packages/client/polls/graphql/polls.generated";
import { updatePollsQuery } from "packages/client/polls/redux/slice";

const FETCH_LIMIT = 10;

export function usePoll() {
  const { dispatchedQuery } = useTypedSelector(({ polls }) => ({
    dispatchedQuery: polls.pollQuery,
  }));
  const [pollQuery, setPollQuery] = useState(dispatchedQuery);
  const dispatch = useTypedDispatch();

  const {
    data: dataPolls,
    fetchMore,
    loading: loadingPolls,
    refetch: refetchPolls,
  } = useGetAllPollsQuery({
    variables: {
      offset: 0,
      limit: FETCH_LIMIT,
      search: dispatchedQuery,
    },
  });

  const fetchMorePolls = useCallback(() => {
    if (dataPolls?.getMyPollsV2.total > dataPolls?.getMyPollsV2.items.length) {
      const offset = dataPolls?.getMyPollsV2.items.length;
      fetchMore({
        variables: {
          offset: offset,
          limit: offset + FETCH_LIMIT,
          notifyOnNetworkStatusChange: true,
        },
      });
    }
  }, [dataPolls, fetchMore]);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      dispatch(updatePollsQuery(pollQuery));
    }, 150);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [dispatch, pollQuery]);

  // yvan - feedback on the following much appreciated - not sure if this is the right way to ensure updatePollStatus returns a promise
  const [updatePollStatusMutation, updatePollStatusInfo] = useUpdatePollStatusMutation();
  const updatePollStatus = useCallback(
    (pollID: string, status: PollStatusV2) => {
      return updatePollStatusMutation({
        variables: {
          pollID,
          status,
        },
        refetchQueries: ["getAllPolls"],
      });
    },
    [updatePollStatusMutation],
  );

  return {
    fetchMorePolls,
    loadingPolls,
    pollQuery,
    polls: dataPolls?.getMyPollsV2,
    refetchPolls,
    setPollQuery,
    updatePollStatus,
    updatePollStatusInfo,
  };
}
