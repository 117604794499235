import type { HTMLProps } from "react";

import type { LI } from "packages/catalog";
import { useMergedClassNames } from "packages/utils";

import styles from "./UL.module.scss";

export interface PUL extends HTMLProps<HTMLUListElement> {
  children: OneOrManyReactChildren<typeof LI>;
  hasMargin?: boolean;
}

export function UL({ children, className, hasMargin, ...rest }: PUL) {
  const ulClasses = useMergedClassNames({ className }, [styles.ul, hasMargin && styles.hasMargin]);

  return (
    <ul {...rest} className={ulClasses}>
      {children}
    </ul>
  );
}
