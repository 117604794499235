import { Component } from "react";

import { debounce } from "packages/utils";

import { fetchDjangoAPI } from "packages/client/fetch";

import { OrgLookup } from "packages/client/onboarding/components";

export interface POrgLookupOwn {
  fetchOnMount?: boolean;
  limit?: number;
  onlySuperorgsAndLites?: boolean;
  optional?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (data: any) => void;
  selected?: {
    id: number | string | boolean;
    name: string;
  };
  title: string;
}

export interface IOrgLookup {
  error: string;
  isFetching: boolean;
  orgs: {
    id: number;
    name: string;
  }[];
  selected: {
    id: number | string | boolean;
    name: string;
  };
}

export type POrgLookup = POrgLookupOwn;

export class OrgLookupContainer extends Component<POrgLookup, IOrgLookup> {
  constructor(props: POrgLookup) {
    super(props);
    this.state = {
      orgs: [],
      selected: props.selected || null,
      error: null,
      isFetching: false,
    };
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleSelection = this._handleSelection.bind(this);
    this._queryOrgs = this._queryOrgs.bind(this);
    this._queryOrgs = debounce(this._queryOrgs, 500);
  }
  componentDidMount() {
    if (!this.props.selected) this.props.onChange(null);
    if (this.props.fetchOnMount) this._queryOrgs("");
  }
  componentDidUpdate(_prevProps: POrgLookup, prevState: IOrgLookup) {
    if (this.state.selected !== prevState.selected) this.props.onChange(this.state.selected);
  }
  private _handleInputChange(input: string) {
    this.props.onChange(this.state.selected ? this.state.selected : null);
    if (!this.state.isFetching) {
      this._queryOrgs(input);
    }
  }
  private _handleSelection(selected: { id: number | string | boolean; name: string } = null) {
    this.setState({
      selected,
    });
  }
  private _queryOrgs(_query: string) {
    this.setState({ isFetching: true, selected: null, error: null });
    fetchDjangoAPI(
      `/organisations/?name=${_query}&limit=${this.props.limit || 10}${
        this.props.onlySuperorgsAndLites ? "&plan=super_org&plan=super_org_lite" : ""
      }`,
    )
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          this.setState({
            isFetching: false,
            error: "There's been an error fetching organisations.",
          });
          throw new Error(res.statusText);
        }
      })
      .then(data => {
        this.setState({
          orgs: data,
          isFetching: false,
          error: data.length ? this.state.error : "No results found.",
        });
      })
      .catch(err => console.error(err));
  }
  public render() {
    return (
      <OrgLookup
        handleInputChange={this._handleInputChange}
        handleSelection={this._handleSelection}
        selected={this.state.selected}
        title={this.props.title}
        orgs={this.state.orgs}
        error={this.state.error}
        isFetching={this.state.isFetching}
        optional={this.props.optional}
      />
    );
  }
}
