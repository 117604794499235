import { useCallback, useEffect, useState } from "react";

import { LOCAL_JWT_TOKEN } from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import {
  clearAuthentication,
  clearToken,
  setAuthenticatedToken,
  setAuthenticatedUserProfile,
  setGuestToken,
  setIs2FACodeSent,
  setIsSignedIn,
} from "packages/client/authentication/redux/slice";
import { fetchUserProfile } from "packages/client/users/django/requests";
import { authenticateGuest, authenticateUser, refreshToken } from "packages/client/authentication/django/requests";
import { generateSignInErrorMessage } from "packages/client/authentication/functions";

export function useAuth() {
  const { isSignedIn, guestName, userID } = useTypedSelector(({ authentication }) => ({
    guestName: authentication.guestName,
    isSignedIn: authentication.isSignedIn,
    userID: authentication.userID,
  }));
  const dispatch = useTypedDispatch();

  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signingInError, setSigningInError] = useState(null);

  useEffect(() => {
    if (!isSignedIn || userID !== "") return;

    let cancelled = false;

    async function fetchProfile() {
      try {
        const { first_name, id, last_name } = await fetchUserProfile();

        if (cancelled) return;

        dispatch(setAuthenticatedUserProfile({ name: `${first_name ?? ""} ${last_name ?? ""}`, userID: `${id}` }));
      } catch (err) {
        console.error(`Error fetching user profile: [${(err as Error).message}]`);
      }
    }

    fetchProfile();

    return () => {
      cancelled = true;
    };
  }, [dispatch, isSignedIn, userID]);

  const signIn = useCallback(
    async (email: string, password: string, code?: string) => {
      try {
        setIsSigningIn(true);
        setSigningInError(null);

        const authData = await authenticateUser(email, password, code);

        if (authData?.token) {
          dispatch(setAuthenticatedToken(authData?.token));

          localStorage.setItem(LOCAL_JWT_TOKEN, authData.token);
        } else {
          dispatch(setIs2FACodeSent(true));
        }
      } catch (err) {
        if (err instanceof Response) {
          if (code && err.status === 400) {
            setSigningInError("Incorrect verification code. Please try again.");
          } else {
            setSigningInError(generateSignInErrorMessage(err.status));
          }
        } else setSigningInError("Unable to sign in. Please check your network status.");
      } finally {
        setIsSigningIn(false);
      }
    },
    [dispatch],
  );

  const signOut = useCallback(() => {
    try {
      dispatch(clearAuthentication());

      localStorage.removeItem(LOCAL_JWT_TOKEN);
    } catch {
      console.error("Unable to sign out. Please try again.");
    }
  }, [dispatch]);

  const autoSignIn = useCallback(async () => {
    try {
      setIsSigningIn(true);

      // attempt to get a previously-stored token from local storage and check it
      const storedToken = localStorage.getItem(LOCAL_JWT_TOKEN);

      if (!storedToken || storedToken === "" || !storedToken.length)
        throw new Error("No authentication token found in local storage.");

      if (typeof storedToken !== "string") throw new Error("Saved authentication token is in the wrong format.");

      // refresh the valid token
      const newTokenResponse = await refreshToken(storedToken);

      dispatch(setAuthenticatedToken(newTokenResponse.token));
      localStorage.setItem(LOCAL_JWT_TOKEN, newTokenResponse.token);
    } catch (err) {
      const error = err as Error;

      dispatch(setIsSignedIn(false));

      console.warn("Unable to sign in automatically. ", error?.message && `${error.message}`);

      if (localStorage.getItem(LOCAL_JWT_TOKEN)) localStorage.removeItem(LOCAL_JWT_TOKEN);
    } finally {
      setIsSigningIn(false);
    }
  }, [dispatch]);

  const getGuestToken = useCallback(
    async (roomKey: string) => {
      const token = await authenticateGuest(roomKey, guestName);
      dispatch(setGuestToken(token));
    },
    [dispatch, guestName],
  );

  const clearGuestTokenIfNotSignedIn = useCallback(() => {
    if (isSignedIn) return;
    dispatch(clearToken());
  }, [dispatch, isSignedIn]);

  return {
    autoSignIn,
    clearGuestTokenIfNotSignedIn,
    getGuestToken,
    isSigningIn,
    signIn,
    signingInError,
    signOut,
  };
}
