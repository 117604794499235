import { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Description, Loader } from "packages/catalog";

import { DownloadErrorReport, ErrorBoundary } from "packages/client/errors/components";
import { DashboardHeader, DashboardNavigation, Meta } from "packages/client/layout/components";
import { UserFeedbackModal } from "packages/client/feedback-form/components";

import styles from "./DashboardLayout.module.scss";

export function DashboardLayout() {
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);

  return (
    <>
      <HashLink className={styles.skip} to="#content">
        Skip to main content
      </HashLink>
      <div className={styles.grid}>
        <DashboardNavigation />
        <div className={styles.content}>
          <div className={styles.container}>
            <div>
              <DashboardHeader />
              <main id="content" tabIndex={-1}>
                <ErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    <Outlet />
                  </Suspense>
                </ErrorBoundary>
              </main>
            </div>
            <footer className={styles.footer}>
              <DownloadErrorReport />
              <Description className={styles.copyright}>
                <Meta setIsFeedbackFormOpen={() => setIsFeedbackFormOpen(true)} />
              </Description>
            </footer>
          </div>
        </div>
      </div>
      <UserFeedbackModal isOpen={isFeedbackFormOpen} onAbort={() => setIsFeedbackFormOpen(false)} />
    </>
  );
}
