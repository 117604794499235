import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useControlSharing, useDevicesMutedState } from "packages/call";
import { ExternalTextLink, IconButton, StatusBadge } from "packages/catalog";
import {
  EAppearance,
  EDimension,
  EIcon,
  isLinuxElectron,
  isMobile,
  isTablet,
  PATH_POLLS,
  SEGMENT_CHAT,
  SEGMENT_PARTICIPANTS,
  SEGMENT_SETTINGS,
  EStatus,
  useId,
  useREMSize,
  useWindowSize,
  SEGMENT_SHARE,
} from "packages/utils";

import type { GetFeaturesByRoomIdRes } from "packages/client/graphql_definitions.generated";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { useIncallChatContext, useRaisehandContext, useWhiteboardContext } from "packages/client/incall/contexts";
import { useGetCallOrganisation, useRoom, useScreenSharingPermission } from "packages/client/incall/hooks";
import { useLeaveCall } from "packages/client/postcall/hooks/useLeaveCall";
import { useVidyoContext } from "packages/client/incall/hooks";

import { toggleIsLeaveOptionsOpen, toggleIsWhiteboardOpen } from "packages/client/layout/redux/slice";

import { AppLogo } from "packages/client/layout/components";

import raiseHandImport from "public/svg/hand.svg";

import styles from "./Controls.module.scss";

const commonProps = {
  appearance: EAppearance.OUTLINE,
  as: "button",
  dimension: EDimension.LARGE,
  status: EStatus.NEUTRAL,
} as const;

const HIDE_SHARE_BUTTON = isMobile || isTablet;

export interface PButton {
  isCondensed?: boolean;
  isUpdated?: boolean;
  onClick?: () => void;
}

interface PControlsButtons {
  chatIsUpdated: boolean;
  features: GetFeaturesByRoomIdRes;
  logo?: string;
  whiteboardIsUpdated: boolean;
  width: number;
}

function Camera({ isCondensed }: PButton) {
  const { setShouldCameraBeMuted, shouldCameraBeMuted, cameraServerMuted } = useDevicesMutedState();

  function toggleCamera() {
    if (cameraServerMuted) return;
    setShouldCameraBeMuted(!shouldCameraBeMuted);
  }

  return (
    <IconButton
      {...commonProps}
      disabled={cameraServerMuted}
      description={!shouldCameraBeMuted ? "Turn off" : "Turn on"}
      icon={shouldCameraBeMuted || cameraServerMuted ? EIcon.VIDEOOFF : EIcon.VIDEO}
      label={isCondensed ? "" : !shouldCameraBeMuted ? "Turn off" : "Turn on"}
      onClick={toggleCamera}
    />
  );
}

function Chat({ isCondensed, isUpdated, onClick }: PButton) {
  const { pathname } = useLocation();

  return (
    <IconButton
      active={pathname.includes(SEGMENT_CHAT)}
      appearance={EAppearance.OUTLINE}
      aria-live={isUpdated ? "polite" : "off"}
      as={Link}
      description="Chat"
      dimension={EDimension.LARGE}
      hasNotification={isUpdated}
      icon={EIcon.MESSAGESQUARE}
      label={isCondensed ? "" : "Chat"}
      onClick={onClick}
      status={EStatus.NEUTRAL}
      to={pathname.includes(SEGMENT_CHAT) ? "." : SEGMENT_CHAT}
    />
  );
}

function End({ isCondensed, onClick }: PButton) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const leaveCall = useLeaveCall();

  const { isLeaveOptionsOpen } = useTypedSelector(({ layout }) => ({
    isLeaveOptionsOpen: layout.isLeaveOptionsOpen,
  }));
  const dispatch = useTypedDispatch();

  const { isModerator } = useRoom();
  const {
    moderation: { numberOfParticipants },
  } = useVidyoContext();

  const label = useMemo(
    () => (isModerator && numberOfParticipants > 1 ? "End session" : "Leave session"),
    [isModerator, numberOfParticipants],
  );

  const handleToggleLeaveOptions = useCallback(() => {
    // run onClick function (closes tray if small breakpoint)
    onClick?.();

    // navigates out of sidebar
    if (
      isCondensed &&
      (pathname.includes(SEGMENT_CHAT) ||
        pathname.includes(SEGMENT_SETTINGS) ||
        pathname.includes(SEGMENT_PARTICIPANTS))
    ) {
      navigate(".");
    }

    dispatch(toggleIsWhiteboardOpen(false));
    dispatch(toggleIsLeaveOptionsOpen(!isLeaveOptionsOpen));
  }, [dispatch, isCondensed, isLeaveOptionsOpen, navigate, onClick, pathname]);

  const onClickHandler = useCallback(() => {
    if (isModerator) {
      handleToggleLeaveOptions();
    } else leaveCall();
  }, [handleToggleLeaveOptions, isModerator, leaveCall]);

  return (
    <IconButton
      {...commonProps}
      active={isLeaveOptionsOpen}
      appearance={EAppearance.FILL}
      description={label}
      fillIcon
      icon={EIcon.PHONE}
      label={isCondensed ? "" : label}
      onClick={onClickHandler}
      rotateby={135}
      status={EStatus.DANGER}
    />
  );
}

function Microphone({ isCondensed }: PButton) {
  const { setShouldMicrophoneBeMuted, shouldMicrophoneBeMuted, microphoneServerMuted } = useDevicesMutedState();

  function toggleMic() {
    if (microphoneServerMuted) return;
    setShouldMicrophoneBeMuted(!shouldMicrophoneBeMuted);
  }

  return (
    <IconButton
      {...commonProps}
      disabled={microphoneServerMuted}
      description={!shouldMicrophoneBeMuted ? "Mute" : "Unmute"}
      icon={shouldMicrophoneBeMuted || microphoneServerMuted ? EIcon.MICOFF : EIcon.MIC}
      label={isCondensed ? "" : !shouldMicrophoneBeMuted ? "Mute" : "Unmute"}
      onClick={toggleMic}
    />
  );
}

function Participants({ isCondensed, onClick }: PButton) {
  const { pathname } = useLocation();

  return (
    <IconButton
      active={pathname.includes(SEGMENT_PARTICIPANTS)}
      appearance={EAppearance.OUTLINE}
      as={Link}
      description="Participants"
      dimension={EDimension.LARGE}
      icon={EIcon.USERS}
      label={isCondensed ? "" : "Participants"}
      onClick={onClick}
      status={EStatus.NEUTRAL}
      to={pathname.includes(SEGMENT_PARTICIPANTS) ? "." : SEGMENT_PARTICIPANTS}
    />
  );
}

function Record({ isCondensed }: PButton) {
  const { isModerator } = useRoom();
  const {
    recording: { isRequesting, isBeingRecorded, requestStartRecording, requestStopRecording },
  } = useVidyoContext();

  function handleClick() {
    if (!isBeingRecorded) {
      requestStartRecording();
    } else {
      requestStopRecording();
    }
  }
  return (
    <IconButton
      {...commonProps}
      description={isBeingRecorded ? "Stop recording" : "Record"}
      status={isBeingRecorded ? EStatus.DANGER : EStatus.NEUTRAL}
      disabled={isRequesting || !isModerator}
      icon={isBeingRecorded ? EIcon.STOPCIRCLE : EIcon.RECORD}
      label={isCondensed ? "" : isBeingRecorded ? "Stop" : "Record"}
      onClick={handleClick}
    />
  );
}

function Screen({ isCondensed }: PButton) {
  const { isModerator } = useRoom();
  const { isSharing, passiveSharingAvailable, chooseWindowSharing, chooseMonitor } = useControlSharing();
  const { screenSharingPermission } = useScreenSharingPermission();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const onShowElectronShareScreenModalHandler = useCallback(() => {
    if (pathname.includes(SEGMENT_SHARE)) {
      navigate(".");
    } else {
      navigate(SEGMENT_SHARE);
    }
  }, [navigate, pathname]);
  // const onAbortElectronShareScreenModalHandler = useCallback(() => {
  //   // console.log("aborted");
  //   setShowElectronShareScreenModal(false);
  // }, []);

  const onClickHandler = useCallback(() => {
    if (!passiveSharingAvailable) {
      chooseWindowSharing(isSharing ? null : "startSharing");
    } else {
      if (!isSharing) onShowElectronShareScreenModalHandler();
      else {
        chooseWindowSharing(null);
        chooseMonitor(null);
      }
    }
  }, [chooseMonitor, chooseWindowSharing, isSharing, onShowElectronShareScreenModalHandler, passiveSharingAvailable]);

  // if in linux electron session, deactivate screen sharing
  const shouldBeDisabled = isLinuxElectron || (isModerator ? false : !screenSharingPermission);

  return (
    <>
      <IconButton
        active={isSharing || pathname.includes(SEGMENT_SHARE)}
        description={isSharing ? "Stop sharing" : "Share"}
        disabled={shouldBeDisabled}
        icon={EIcon.UPLOAD}
        label={isCondensed ? "" : isSharing ? "Stop" : "Share"}
        onClick={onClickHandler}
        rotateby={isSharing ? 180 : 0}
        {...commonProps}
      />
      {/* {showElectronShareScreenModal && <ModalElectronShareScreen onAbort={onAbortElectronShareScreenModalHandler} />} */}
    </>
  );
}

function Settings({ isCondensed, onClick }: PButton) {
  const { pathname } = useLocation();

  return (
    <IconButton
      active={pathname.includes(SEGMENT_SETTINGS)}
      appearance={EAppearance.OUTLINE}
      as={Link}
      description="Settings"
      dimension={EDimension.LARGE}
      icon={EIcon.SETTINGS}
      label={isCondensed ? "" : "Settings"}
      onClick={onClick}
      status={EStatus.NEUTRAL}
      to={pathname.includes(SEGMENT_SETTINGS) ? "." : SEGMENT_SETTINGS}
    />
  );
}

function Whiteboard({ isCondensed, isUpdated, onClick }: PButton) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isWhiteboardOpen } = useTypedSelector(({ layout }) => ({
    isWhiteboardOpen: layout.isWhiteboardOpen,
  }));
  const dispatch = useTypedDispatch();

  const handleToggleWhiteboard = useCallback(() => {
    // run onClick function (closes tray if small breakpoint)
    onClick?.();

    // navigates out of sidebar
    if (
      isCondensed &&
      (pathname.includes(SEGMENT_CHAT) ||
        pathname.includes(SEGMENT_SETTINGS) ||
        pathname.includes(SEGMENT_PARTICIPANTS))
    ) {
      navigate(".");
    }

    // toggle the whiteboard display
    dispatch(toggleIsLeaveOptionsOpen(false));
    dispatch(toggleIsWhiteboardOpen(!isWhiteboardOpen));
  }, [dispatch, isCondensed, isWhiteboardOpen, navigate, onClick, pathname]);

  return (
    <IconButton
      {...commonProps}
      active={isWhiteboardOpen}
      aria-live={isUpdated ? "polite" : "off"}
      description="Whiteboard"
      hasNotification={isUpdated}
      icon={EIcon.EDIT}
      label={isCondensed ? "" : "Whiteboard"}
      onClick={handleToggleWhiteboard}
    />
  );
}

function Hand({ isCondensed }: PButton) {
  const { raisedHands, setRaisedHand } = useRaisehandContext();
  const { userName } = useTypedSelector(({ authentication }) => ({ userName: authentication.guestName }));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isHandRaised = useMemo(() => raisedHands.some((user: any) => user.user === userName), [raisedHands, userName]);
  const toggleHand = useCallback(() => {
    setRaisedHand(!isHandRaised);
  }, [isHandRaised, setRaisedHand]);

  return (
    <IconButton
      active={isHandRaised}
      as="button"
      description={isHandRaised ? "Lower hand" : "Raise hand"}
      icon={null}
      label={isCondensed ? "" : isHandRaised ? "Lower hand" : "Raise hand"}
      onClick={toggleHand}
      {...commonProps}>
      <svg style={{ strokeWidth: "calc(var(--width-outline) * 2)" }}>
        <use href={`${raiseHandImport}#hand`} role="img"></use>
      </svg>
    </IconButton>
  );
}

function Polls({ isCondensed }: PButton) {
  const { isSignedIn, token } = useTypedSelector(({ authentication }) => ({
    isSignedIn: authentication.isSignedIn,
    token: authentication.token,
  }));

  const electronPollsURL: string = isSignedIn
    ? `https://${DOMAIN}${PATH_POLLS}/?token=${token as string}`
    : `https://${DOMAIN}${PATH_POLLS}`;

  return (
    <div className={styles.betaWrapper}>
      <StatusBadge className={styles.beta} status={EStatus.NEUTRAL}>
        Beta
      </StatusBadge>
      <IconButton
        appearance={EAppearance.OUTLINE}
        as={IS_ELECTRON ? ExternalTextLink : Link}
        description="Polls"
        dimension={EDimension.LARGE}
        href={IS_ELECTRON ? electronPollsURL : null}
        icon={EIcon.BARCHART2}
        label={isCondensed ? "" : "Polls"}
        rel="noopener noreferrer"
        status={EStatus.NEUTRAL}
        target="_blank"
        to={!IS_ELECTRON ? PATH_POLLS : null}
      />
    </div>
  );
}

// commented out as layout is currently using vidyo's default
// function Selfview({ isCondensed }: ButtonProps) {
//   const { showSelfview, setShowSelfview } = useLayoutControl();
//   const { shouldCameraBeMuted } = useDevicesMutedState();

//   return (
//     <IconButton
//       {...commonProps}
//       disabled={shouldCameraBeMuted}
//       description={!showSelfview ? "Show self-view" : "Hide self-view"}
//       icon={showSelfview ? EIcon.USER : EIcon.USERX}
//       label={isCondensed ? "" : !showSelfview ? "Show self" : "Hide self"}
//       onClick={() => setShowSelfview(!showSelfview)}
//     />
//   );
// }

function ButtonsCondensed({ features, width, whiteboardIsUpdated, chatIsUpdated }: PControlsButtons) {
  const { isModerator } = useRoom();
  const key = useId("buttons-key");

  const [isTrayOpen, setTrayOpen] = useState(false);
  const remSize = useREMSize();

  const toggle = useMemo(() => {
    function toggleTray() {
      setTrayOpen(!isTrayOpen);
    }

    return (
      <IconButton
        {...commonProps}
        active={isTrayOpen}
        description={"More options"}
        hasNotification={chatIsUpdated}
        icon={isTrayOpen ? EIcon.CHEVRONUP : EIcon.CHEVRONDOWN}
        onClick={toggleTray}
        key={key + "toggle"}
      />
    );
  }, [chatIsUpdated, isTrayOpen, key]);

  const [visibleButtons, hiddenButtons] = useMemo(() => {
    const closeTray = () => setTrayOpen(false);

    const allButtons: {
      element: JSX.Element;
      order: number;
    }[] = [
      { element: <Microphone isCondensed key={key + "microphone"} />, order: 0 },
      { element: <Camera isCondensed key={key + "camera"} />, order: 1 },
      { element: <End isCondensed key={key + "end"} />, order: 2 },
      !features?.hideCollaboration && { element: <Hand isCondensed key={key + "hand"} />, order: 3 },
      // { element: <Selfview isCondensed key={key + "selfview"} />, order: 4 },
      {
        element: (
          <Whiteboard isCondensed isUpdated={whiteboardIsUpdated} onClick={closeTray} key={key + "whiteboard"} />
        ),
        order: 4,
      },
      !HIDE_SHARE_BUTTON && { element: <Screen isCondensed key={key + "screen"} />, order: 5 },
      isModerator && { element: <Record isCondensed key={key + "record"} />, order: 6 },
      !features?.hideCollaboration && {
        element: <Chat isCondensed isUpdated={chatIsUpdated} onClick={closeTray} key={key + "chat"} />,
        order: 7,
      },
      { element: <Participants isCondensed onClick={closeTray} key={key + "participants"} />, order: 8 },
      { element: <Polls isCondensed key={key + "polls"} />, order: 9 },
      { element: <Settings isCondensed onClick={closeTray} key={key + "settings"} />, order: 10 },
    ];

    const toggleButton = { element: toggle, order: 12 };

    const buttonWidth = 7 * remSize;
    const paddingWidth = 4 * remSize;
    const availableWidth = width - paddingWidth;
    const totalNumberOfButtonsThatCanFit = Math.floor(availableWidth / buttonWidth);

    // accounting for the fact that adding the toggle button in needs to count as one of the visible buttons
    const condition =
      totalNumberOfButtonsThatCanFit >= allButtons.length
        ? totalNumberOfButtonsThatCanFit
        : totalNumberOfButtonsThatCanFit - 1;

    let visibleButtons = allButtons.slice(0, condition);
    const hiddenButtons = allButtons.slice(condition);

    if (hiddenButtons.length) visibleButtons.push(toggleButton);

    visibleButtons = visibleButtons.sort((first, second) => first.order - second.order);

    return [visibleButtons, hiddenButtons];
  }, [chatIsUpdated, features, isModerator, key, remSize, toggle, whiteboardIsUpdated, width]);

  return (
    <>
      {visibleButtons.map(e => e.element)}
      {isTrayOpen && hiddenButtons.map(e => e.element)}
    </>
  );
}

function ButtonsExpanded({ chatIsUpdated, features, whiteboardIsUpdated, width }: PControlsButtons) {
  const remSize = useREMSize();
  const wideWidth = 140 * remSize;
  const isWide = width > wideWidth;

  const { isModerator } = useRoom();

  const mainClasses = useMemo(() => [styles.main, isWide && styles.fullyCenter].filter(e => e).join(" "), [isWide]);
  const toggleClasses = useMemo(() => [styles.group, isWide && styles.stickRight].filter(e => e).join(" "), [isWide]);

  return (
    <>
      {isWide && (
        <div className={styles.logo}>
          <AppLogo dimension={EDimension.SMALL} />
        </div>
      )}
      <div className={mainClasses}>
        <div className={styles.group}>
          <Microphone />
          <Camera />
          {/* <Selfview /> */}
          <End />
          {!features?.hideCollaboration && <Hand />}
          <Whiteboard isUpdated={whiteboardIsUpdated} />
          {!HIDE_SHARE_BUTTON && <Screen />}
          {isModerator && <Record />}
        </div>
      </div>
      <div className={toggleClasses}>
        {!features?.hideCollaboration && <Chat isUpdated={chatIsUpdated} />}
        <Participants />
        <Polls />
        <Settings />
      </div>
    </>
  );
}

export function Controls() {
  const { width } = useWindowSize();
  const remSize = useREMSize();
  const condensedWidth = 95 * remSize;
  const isCondensed = width < condensedWidth;

  const { isWhiteboardOpen } = useTypedSelector(({ layout }) => ({
    isWhiteboardOpen: layout.isWhiteboardOpen,
  }));

  const { logo, features } = useGetCallOrganisation();

  // responsive layout adjustments are applied here via extra classes as opposed to within css through a media query. this allows the breakpoint to be controlled by js, as media queries take ems from the browser font size, not the root font size, making any adjustments to the browser font size affect the layout negatively (if this sounds complicated, it is).

  const classes = useMemo(
    () => [styles.controls, !isCondensed && styles.twoGroups].filter(e => e).join(" "),
    [isCondensed],
  );

  const [whiteboardIsUpdated, setWhiteboardIsUpdated] = useState(false);
  const [chatIsUpdated, setChatIsUpdated] = useState(false);
  const { paths } = useWhiteboardContext();
  const { messages } = useIncallChatContext();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes(SEGMENT_CHAT)) {
      setChatIsUpdated(false);
      return;
    }
    if (messages.length) setChatIsUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    if (pathname.includes(SEGMENT_CHAT)) {
      setChatIsUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (isWhiteboardOpen) {
      setWhiteboardIsUpdated(false);
      return;
    }
    if (paths.length) setWhiteboardIsUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paths]);

  useEffect(() => {
    if (isWhiteboardOpen) {
      setWhiteboardIsUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <section className={classes}>
      {isCondensed ? (
        <ButtonsCondensed
          chatIsUpdated={chatIsUpdated}
          features={features}
          whiteboardIsUpdated={whiteboardIsUpdated}
          width={width}
        />
      ) : (
        <ButtonsExpanded
          chatIsUpdated={chatIsUpdated}
          features={features}
          logo={logo}
          whiteboardIsUpdated={whiteboardIsUpdated}
          width={width}
        />
      )}
    </section>
  );
}
