export enum EIcon {
  ACTIVITY = "activity",
  AIRPLAY = "airplay",
  ALERTCIRCLE = "alert-circle",
  ALERTOCTAGON = "alert-octagon",
  ALERTTRIANGLE = "alert-triangle",
  ALIGNCENTER = "align-center",
  ALIGNJUSTIFY = "align-justify",
  ALIGNLEFT = "align-left",
  ALIGNRIGHT = "align-right",
  ANCHOR = "anchor",
  APERTURE = "aperture",
  ARCHIVE = "archive",
  ARROWDOWNCIRCLE = "arrow-down-circle",
  ARROWDOWNLEFT = "arrow-down-left",
  ARROWDOWNRIGHT = "arrow-down-right",
  ARROWDOWN = "arrow-down",
  ARROWLEFTCIRCLE = "arrow-left-circle",
  ARROWLEFT = "arrow-left",
  ARROWRIGHTCIRCLE = "arrow-right-circle",
  ARROWRIGHT = "arrow-right",
  ARROWUP = "arrow-up",
  ARROWUPCIRCLE = "arrow-up-circle",
  ARROWUPLEFT = "arrow-up-left",
  ARROWUPRIGHT = "arrow-up-right",
  AWARD = "award",
  BARCHART2 = "bar-chart-2",
  BARCHART = "bar-chart",
  BATTERYCHARGING = "battery-charging",
  BATTERY = "battery",
  BELLOFF = "bell-off",
  BELL = "bell",
  BLUETOOTH = "bluetooth",
  BOLD = "bold",
  BOOKOPEN = "book-open",
  BOOK = "book",
  BOOKMARK = "bookmark",
  BOX = "box",
  BRIEFCASE = "briefcase",
  CALENDAR = "calendar",
  CAMERAOFF = "camera-off",
  CAMERA = "camera",
  CAST = "cast",
  CHECKCIRCLE = "check-circle",
  CHECKSQUARE = "check-square",
  CHECK = "check",
  CHEVRONDOWN = "chevron-down",
  CHEVRONLEFT = "chevron-left",
  CHEVRONRIGHT = "chevron-right",
  CHEVRONUP = "chevron-up",
  CHEVRONSDOWN = "chevrons-down",
  CHEVRONSLEFT = "chevrons-left",
  CHEVRONSRIGHT = "chevrons-right",
  CHEVRONSUP = "chevrons-up",
  CHROME = "chrome",
  CIRCLE = "circle",
  CLIPBOARD = "clipboard",
  CLOCK = "clock",
  CLOUDDRIZZLE = "cloud-drizzle",
  CLOUDLIGHTNING = "cloud-lightning",
  CLOUDOFF = "cloud-off",
  CLOUDRAIN = "cloud-rain",
  CLOUDSNOW = "cloud-snow",
  CLOUD = "cloud",
  CODE = "code",
  CODEPEN = "codepen",
  CODESANDBOX = "codesandbox",
  COFFEE = "coffee",
  COLUMNS = "columns",
  COMMAND = "command",
  COMPASS = "compass",
  COPY = "copy",
  CORNERDOWNLEFT = "corner-down-left",
  CORNERDOWNRIGHT = "corner-down-right",
  CORNERLEFTDOWN = "corner-left-down",
  CORNERLEFTUP = "corner-left-up",
  CORNERRIGHTDOWN = "corner-right-down",
  CORNERRIGHTUP = "corner-right-up",
  CORNERUPLEFT = "corner-up-left",
  CORNERUPRIGHT = "corner-up-right",
  CPU = "cpu",
  CREDITCARD = "credit-card",
  CROP = "crop",
  CROSSHAIR = "crosshair",
  DATABASE = "database",
  DELETE = "delete",
  DISC = "disc",
  DIVIDECIRCLE = "divide-circle",
  DIVIDESQUARE = "divide-square",
  DOLLARSIGN = "dollar-sign",
  DOWNLOADCLOUD = "download-cloud",
  DOWNLOAD = "download",
  DRIBBBLE = "dribbble",
  DROPLET = "droplet",
  EDIT2 = "edit-2",
  EDIT3 = "edit-3",
  EDIT = "edit",
  EXTERNALLINK = "external-link",
  EYEOFF = "eye-off",
  EYE = "eye",
  FACEBOOK = "facebook",
  FASTFORWARD = "fast-forward",
  FEATER = "feather",
  FIGMA = "figma",
  FILEMENUS = "file-minus",
  FILEPLUS = "file-plus",
  FILETEXT = "file-text",
  FILE = "file",
  FILM = "film",
  FILTER = "filter",
  FLAG = "flag",
  FOLDERMINUS = "folder-minus",
  FOLDERPLUS = "folder-plus",
  FOLDER = "folder",
  FRAMER = "framer",
  FROWN = "frown",
  GIFT = "gift",
  GITBRANCH = "git-branch",
  GITCOMMIT = "git-commit",
  GITMERGE = "git-merge",
  GITPULLREQUEST = "git-pull-request",
  GITHUB = "github",
  GITLAB = "gitlab",
  GLOBE = "globe",
  GRID = "grid",
  HAND = "hand",
  HARDDRIVE = "hard-drive",
  HASH = "hash",
  HEADPHONES = "headphones",
  HEART = "heart",
  HELPCIRCLE = "help-circle",
  HEXAGON = "hexagon",
  HOME = "home",
  IMAGE = "image",
  INBOX = "inbox",
  INFO = "info",
  INSTRAGRAM = "instagram",
  ITALIC = "italic",
  KEY = "key",
  LAYERS = "layers",
  LAYOUT = "layout",
  LIFEBUOY = "life-buoy",
  LINK2 = "link-2",
  LINK = "link",
  LINKEDIN = "linkedin",
  LIST = "list",
  LOADER = "loader",
  LOCK = "lock",
  LOGIN = "log-in",
  LOGOUT = "log-out",
  MAIL = "mail",
  MAPPIN = "map-pin",
  MAP = "map",
  MAXIMISE2 = "maximize-2",
  MAXIMISE = "maximize",
  MEH = "meh",
  MENU = "menu",
  MESSAGECIRCLE = "message-circle",
  MESSAGESQUARE = "message-square",
  MICOFF = "mic-off",
  MIC = "mic",
  MINIMIZE2 = "minimize-2",
  MINIMIZE = "minimize",
  MINUSCIRCLE = "minus-circle",
  MINUSSQUARE = "minus-square",
  MINUS = "minus",
  MONITOR = "monitor",
  MOON = "moon",
  MOREHORIZONTAL = "more-horizontal",
  MOREVERTICAL = "more-vertical",
  MOUSEPOINTER = "mouse-pointer",
  MOVE = "move",
  MUSIC = "music",
  NAVIGATION2 = "navigation-2",
  NAVIGATION = "navigation",
  OCTAGON = "octagon",
  PACKAGE = "package",
  PAPERCLIP = "paperclip",
  PAUSECIRCLE = "pause-circle",
  PAUSE = "pause",
  PENTOOL = "pen-tool",
  PERCENT = "percent",
  PHONECALL = "phone-call",
  PHONEFORWARDED = "phone-forwarded",
  PHONEINCOMING = "phone-incoming",
  PHONEMISSED = "phone-missed",
  PHONEOFF = "phone-off",
  PHONEOUTGOING = "phone-outgoing",
  PHONE = "phone",
  PIECHART = "pie-chart",
  PIN = "pin",
  PLAYCIRCLE = "play-circle",
  PLAY = "play",
  PLUSCIRCLE = "plus-circle",
  PLUSSQUARE = "plus-square",
  PLUS = "plus",
  POCKET = "pocket",
  POWER = "power",
  PRINTER = "printer",
  RADIO = "radio",
  RECORD = "record",
  REFRESHCCW = "refresh-ccw",
  REFRESHCW = "refresh-cw",
  REPEAT = "repeat",
  REWIND = "rewind",
  ROTATECCW = "rotate-ccw",
  ROTATECW = "rotate-cw",
  RSS = "rss",
  SAVE = "save",
  SCISSORS = "scissors",
  SEARCH = "search",
  SEND = "send",
  SERVER = "server",
  SETTINGS = "settings",
  SHARE2 = "share-2",
  SHARE = "share",
  SHIELDOFF = "shield-off",
  SHIELD = "shield",
  SHOPPINGBAG = "shopping-bag",
  SHOPPINGCART = "shopping-cart",
  SHUFFLE = "shuffle",
  SIDEBAR = "sidebar",
  SKIPBACK = "skip-back",
  SKIPFORWARD = "skip-forward",
  SLACK = "slack",
  SLASH = "slash",
  SLIDERS = "sliders",
  SMARTPHONE = "smartphone",
  SMILE = "smile",
  SPEAKER = "speaker",
  SQUARE = "square",
  STAR = "star",
  STOPCIRCLE = "stop-circle",
  SUN = "sun",
  SUNRISE = "sunrise",
  SUNSET = "sunset",
  TABLET = "tablet",
  TAG = "tag",
  TARGET = "target",
  TERMINAL = "terminal",
  THERMOMETER = "thermometer",
  THUMBSDOWN = "thumbs-down",
  THUMBSUP = "thumbs-up",
  TOGGLELEFT = "toggle-left",
  TOGGLERIGHT = "toggle-right",
  TOOL = "tool",
  TRASH2 = "trash-2",
  TRASH = "trash",
  TRELLO = "trello",
  TRENDINGDOWN = "trending-down",
  TRENDINGUP = "trending-up",
  TRIANGLE = "triangle",
  TRUCK = "truck",
  TV = "tv",
  TWITCH = "twitch",
  TWITTER = "twitter",
  TYPE = "type",
  UMBRELLA = "umbrella",
  UNDERLINE = "underline",
  UNLOCK = "unlock",
  UPLOADCLOUD = "upload-cloud",
  UPLOAD = "upload",
  USERCHECK = "user-check",
  USERMINUS = "user-minus",
  USERPLUS = "user-plus",
  USERX = "user-x",
  USER = "user",
  USERS = "users",
  VIDEOOFF = "video-off",
  VIDEO = "video",
  VOICEMAIL = "voicemail",
  VOLUME1 = "volume-1",
  VOLUME2 = "volume-2",
  VOLUMEX = "volume-x",
  VOLUME = "volume",
  WATCH = "watch",
  WIFIOFF = "wifi-off",
  WIFI = "wifi",
  WIND = "wind",
  XCIRCLE = "x-circle",
  XOCTAGON = "x-octagon",
  XSQUARE = "x-square",
  X = "x",
  YOUTUBE = "youtube",
  ZAPOFF = "zap-off",
  ZAP = "zap",
  ZOOMIN = "zoom-in",
  ZOOMOUT = "zoom-out",
}
