import { useCallback, useRef } from "react";

import { Flexbox, IconButton, P, TextButton } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus, useContainerSize } from "packages/utils";

export interface PPagination {
  hasNext?: boolean;
  hasPrevious?: boolean;
  page: number;
  pages: number;
  setPage: (page: number) => void;
}

export function Pagination({ hasNext, hasPrevious, page, pages, setPage }: PPagination) {
  const divRef = useRef<HTMLDivElement>(null);
  const { width } = useContainerSize(divRef);

  const handleOnClickNext = useCallback(() => {
    if (hasNext) setPage(page + 1);
  }, [hasNext, page, setPage]);

  const handleOnClickPrevious = useCallback(() => {
    if (hasPrevious) setPage(page - 1);
  }, [hasPrevious, page, setPage]);

  const renderPageButtons = useCallback(() => {
    const numberOfButtonsToDisplay = width > 770 ? 9 : width > 500 ? 4 : 1;

    return [...Array(pages)].reduce((allButtons, _, i) => {
      // iterator is zero-indexed, page is 1-indexed, so increase the current iterator by 1
      const iteratorPage = i + 1;

      const button = (
        <TextButton
          appearance={iteratorPage === page ? EAppearance.FILL : EAppearance.GHOST}
          key={`page${iteratorPage}`}
          onClick={() => setPage(iteratorPage)}
          title={`Page ${iteratorPage}`}>
          {iteratorPage}
        </TextButton>
      );

      if (
        // display 1 through 9
        (iteratorPage <= numberOfButtonsToDisplay && iteratorPage > page - numberOfButtonsToDisplay) ||
        // trap the numbers after 9, but within 9 of the current page
        (iteratorPage > numberOfButtonsToDisplay &&
          iteratorPage > page - numberOfButtonsToDisplay &&
          iteratorPage <= page)
      ) {
        allButtons.push(button);
      }

      return allButtons;
    }, []);
  }, [page, pages, setPage, width]);

  return (
    <Flexbox alignItemsCenter justifyContentCenter ref={divRef}>
      <IconButton
        appearance={EAppearance.GHOST}
        as="button"
        description="First page"
        dimension={EDimension.SMALL}
        disabled={page === 1}
        icon={EIcon.CHEVRONSLEFT}
        onClick={() => setPage(1)}
        status={EStatus.NEUTRAL}
      />
      <IconButton
        appearance={EAppearance.GHOST}
        as="button"
        description="Previous page"
        dimension={EDimension.SMALL}
        disabled={!hasPrevious}
        icon={EIcon.CHEVRONLEFT}
        onClick={handleOnClickPrevious}
        status={EStatus.NEUTRAL}
      />
      {pages > 9 && page > 9 && <P>...</P>}
      {renderPageButtons()}
      {pages > 9 && page !== pages && <P>...</P>}
      <IconButton
        appearance={EAppearance.GHOST}
        as="button"
        description="Next page"
        dimension={EDimension.SMALL}
        disabled={!hasNext}
        icon={EIcon.CHEVRONRIGHT}
        onClick={handleOnClickNext}
        status={EStatus.NEUTRAL}
      />
      <IconButton
        appearance={EAppearance.GHOST}
        as="button"
        description="Last page"
        dimension={EDimension.SMALL}
        disabled={page === pages}
        icon={EIcon.CHEVRONSRIGHT}
        onClick={() => setPage(pages)}
        status={EStatus.NEUTRAL}
      />
    </Flexbox>
  );
}
