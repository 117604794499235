import { HTMLProps, useMemo } from "react";

import { TR, TRTDTemp } from "packages/catalog";

import styles from "./Table.module.scss";

export interface PTable extends HTMLProps<HTMLTableElement> {
  children?: OneOrManyReactChildren<typeof TR> | OneOrManyReactChildren<typeof TRTDTemp>;
  columns: string[];
  columnWidths: string;
  description: string;
  hasMenuColumn?: boolean;
}

export function Table({ children, columns, description, columnWidths, hasMenuColumn, ...rest }: PTable) {
  const tbodyClasses = useMemo(
    () => [styles.tbody, hasMenuColumn && styles.tbodymenu].filter(e => e).join(" "),
    [hasMenuColumn],
  );

  return (
    <table className={styles.table} {...rest}>
      <caption className={styles.caption}>{description}</caption>
      <thead className={styles.thead}>
        <TR columnWidths={columnWidths}>
          <>
            {columns.map(column => (
              <th className={styles.th} key={column} scope="col">
                {column}
              </th>
            ))}
          </>
          {hasMenuColumn && <th className={styles.thmenu}>Options</th>}
        </TR>
      </thead>
      <tbody className={tbodyClasses}>
        {children
          ? children
          : children !== undefined && (
              <tr>
                <td className={styles.empty}>No results found</td>
              </tr>
            )}
      </tbody>
    </table>
  );
}
