import { Link } from "react-router-dom";

import { Flexbox, TextButton } from "packages/catalog";
import { isNowBetweenStartAndEnd, isNowOneHourFromStart, PATH_CALLLINK, EStatus } from "packages/utils";

export interface PJoinButtons {
  canModerate?: boolean;
  endTime: string;
  nextRecurringEnd?: string;
  nextRecurringStart?: string;
  roomKey: string;
  startTime: string;
}

export function JoinButtons({
  canModerate,
  endTime,
  nextRecurringEnd,
  nextRecurringStart,
  roomKey,
  startTime,
}: PJoinButtons) {
  const currentEndTime = new Date(nextRecurringEnd || endTime);
  const currentStartTime = new Date(nextRecurringStart || startTime);

  const buttons = (
    <Flexbox justifyContentEnd>
      <TextButton as={Link} to={PATH_CALLLINK(roomKey)} status={EStatus.ACCENT}>
        Join
      </TextButton>
      {canModerate && (
        <TextButton as={Link} state={{ moderate: true }} to={PATH_CALLLINK(roomKey)}>
          Moderate
        </TextButton>
      )}
    </Flexbox>
  );

  return (canModerate &&
    (isNowOneHourFromStart(currentStartTime) || isNowBetweenStartAndEnd(currentStartTime, currentEndTime))) ||
    isNowBetweenStartAndEnd(currentStartTime, currentEndTime)
    ? buttons
    : null;
}
