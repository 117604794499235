import { useMemo } from "react";

import { Avatar } from "packages/catalog";
import type { EDimension } from "packages/utils";

import { useGetEssentialOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";

import { generateOrganisationLogoURLAuthenticated } from "packages/client/organisations/s3";

export interface POrganisationLogo {
  dimension: EDimension;
  organisationID: string;
  pendingOrganisationName?: string;
}

export function OrganisationLogo({ dimension, organisationID, pendingOrganisationName }: POrganisationLogo) {
  const { data: organisationData, loading } = useGetEssentialOrganisationQuery({
    variables: { organisationID: parseInt(organisationID) },
  });

  const logoURL = useMemo(
    () => generateOrganisationLogoURLAuthenticated(organisationData?.organisation?.logo),
    [organisationData],
  );

  return (
    <Avatar
      dimension={dimension}
      fullName={pendingOrganisationName ?? organisationData?.organisation?.name}
      imageURL={logoURL ? logoURL : null}
      loading={loading}
    />
  );
}
