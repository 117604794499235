import { useCallback, useEffect, useState } from "react";

import { getConnections, getPendingConnections } from "packages/client/organisations/django/requests";

export function useGetConnections() {
  const [connections, setConnections] = useState<{ connected: number[]; incoming: number[]; outgoing: number[] }>({
    connected: [],
    incoming: [],
    outgoing: [],
  });

  const getData = useCallback(() => {
    getConnections()
      .then(data => {
        setConnections(current => {
          return {
            ...current,
            connected: data.connected_organisations,
          };
        });
      })
      .catch(() => console.error("Unable to get connections."));

    getPendingConnections()
      .then(data => {
        setConnections(current => {
          return {
            ...current,
            incoming: data.incoming,
            outgoing: data.outgoing,
          };
        });
      })
      .catch(() => console.error("Unable to get pending outgoing connection requests."));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return { connections, getData };
}
