import { ChangeEvent, FormEvent, useCallback, useState } from "react";

import { Block, Cell, Feedback, Field, Grid, H1, Input } from "packages/catalog";
import { scrollToContentTop, EStatus } from "packages/utils";

import { changePassword } from "packages/client/users/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function ChangePassword() {
  const [form, setForm] = useState({ confirmPassword: "", newPassword: "", currentPassword: "" });
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setShowErrorMessage(false);
      setShowSuccessMessage(false);
      changePassword({ ...form })
        .then(() => {
          scrollToContentTop();
          setShowSuccessMessage(true);
        })
        .catch(() => setShowErrorMessage(true));
    },
    [form],
  );

  return (
    <>
      <RoutingChangeHandler message="The change password page has loaded." pageTitle="Change password" />
      <H1 hasMargin>Change password</H1>
      <Grid as="form" noColGap onSubmit={handleSubmit}>
        {showErrorMessage && (
          <Cell>
            <Feedback status={EStatus.DANGER}>Unable to change password. Please try again.</Feedback>
          </Cell>
        )}
        {showSuccessMessage && (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Password changed.</Feedback>
          </Cell>
        )}
        <Cell as={Block}>
          <Grid>
            <Cell colSpanSM={6}>
              <Field label="Current password">
                <Input
                  autoComplete="current-password"
                  name="currentPassword"
                  onChange={handleChangeInput}
                  required
                  type="password"
                  value={form.currentPassword}
                />
              </Field>
            </Cell>
            <Cell colSpanSM={6} />
            <Cell colSpanSM={6}>
              <Field description="Must be at least eight characters long." label="New password">
                <Input
                  autoComplete="new-password"
                  name="newPassword"
                  onChange={handleChangeInput}
                  required
                  type="password"
                  value={form.newPassword}
                />
              </Field>
            </Cell>
            <Cell colSpanSM={6}>
              <Field label="Confirm new password">
                <Input
                  autoComplete="new-password"
                  name="confirmPassword"
                  onChange={handleChangeInput}
                  required
                  type="password"
                  value={form.confirmPassword}
                />
              </Field>
            </Cell>
          </Grid>
        </Cell>
        <Cell center>
          <Input disabled={form.confirmPassword.length < 8} type="submit" value="Save" />
        </Cell>
      </Grid>
    </>
  );
}
