import { EBackgroundColor, isMobile } from "packages/utils";

import { EVidyoSettingsActions, ISettingsState, TSettingsAction } from "./types";

import { EVidyoNetworkPolicy } from "../../settings/enums";
import { saveReducerToLocalStorage } from "../../redux/functions";

const initialState: ISettingsState = isMobile
  ? {
      backgroundColor: EBackgroundColor.GRAPE,
      isBackgroundBlurred: false,
      isBackgroundColored: false,
      isInitialState: true,
      isSelfShareMaximized: false,
      isSpeakerMaximizedOnFallback: true,
      networkPolicy: EVidyoNetworkPolicy.PEOPLE,
      userProvidedMaxSources: 3,
      userProvidedMinSources: 1,
    }
  : {
      backgroundColor: EBackgroundColor.GRAPE,
      isBackgroundBlurred: false,
      isBackgroundColored: false,
      isInitialState: true,
      isSelfShareMaximized: false,
      isSpeakerMaximizedOnFallback: true,
      networkPolicy: EVidyoNetworkPolicy.PEOPLE,
      userProvidedMaxSources: 8,
      userProvidedMinSources: 2,
    };

function updateInitial(state: ISettingsState): ISettingsState {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isInitialState: _, ...rest } = state;
  const newIsInitialState = !Object.entries(rest).reduce((acc, e) => {
    const [k, v] = e;

    return acc || initialState[k as keyof ISettingsState] !== v;
  }, false);
  return { ...rest, isInitialState: newIsInitialState };
}

function settingsReducer(state = initialState, action: TSettingsAction): ISettingsState {
  switch (action.type) {
    case EVidyoSettingsActions.UPDATE_SETTINGS: {
      const newState = { ...state, ...action.update };
      if (action.update?.userProvidedMaxSources) {
        if (newState.userProvidedMaxSources < newState.userProvidedMinSources)
          newState.userProvidedMinSources = newState.userProvidedMaxSources;
      }
      if (action.update?.userProvidedMinSources) {
        if (newState.userProvidedMinSources > newState.userProvidedMaxSources)
          newState.userProvidedMaxSources = newState.userProvidedMinSources;
      }
      return updateInitial(newState);
    }
    case EVidyoSettingsActions.RESET_SETTINGS:
      return initialState;
    default:
      return state;
  }
}

export const persistedSettingsReducer = saveReducerToLocalStorage("callsettings", settingsReducer, s => ({
  isSelfShareMaximized: s.isSelfShareMaximized,
  isSpeakerMaximizedOnFallback: s.isSpeakerMaximizedOnFallback,
}));
