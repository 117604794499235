import type { RoomObjectType } from "packages/client/graphql_definitions.generated";
import type { IContextParticipant, IDjangoParticipant, IDjangoRoom } from "packages/client/incall/django/types";

export function toContextParticipant(data: IDjangoParticipant[] = []): IContextParticipant[] {
  return data.map(e => {
    return {
      id: e.participant,
      isCameraActive: e.video,
      isGuest: e.extension === "Guest",
      isMicrophoneActive: e.audio,
      isPresenter: e.presenter,
    };
  });
}

export function toDjangoRoom(data: Partial<RoomObjectType>, cleanUndefinedFields = false): Partial<IDjangoRoom> {
  const serialised = {
    prioritise_shared_screen_recording: data?.prioritiseSharedScreenRecording,
    screen_sharing_participants: data?.screenSharingParticipants,
  };

  if (!cleanUndefinedFields) return serialised;

  return Object.fromEntries(Object.entries(serialised).filter(([, v]) => v !== undefined));
}
