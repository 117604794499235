import { Component, ErrorInfo, ReactNode } from "react";

import { SomethingWentWrong } from "packages/client/errors/components";

export interface PErrorBoundary {
  fallback?: ReactNode;
  children: ReactNode;
}

export interface SErrorBoundary {
  error: Error;
  hasError: boolean;
  info: ErrorInfo;
}

export class ErrorBoundary extends Component<PErrorBoundary, SErrorBoundary> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }
  constructor(props: PErrorBoundary) {
    super(props);

    this.state = { hasError: false, error: new Error(), info: { componentStack: "" } };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    if (process.env.NODE_ENV === "production") {
      console.error(error.toString());
      console.error(info.componentStack);
    }
    this.setState({ error, info });
  }

  public render() {
    if (this.state.hasError) {
      const { error, info } = this.state;
      return this.props.fallback || <SomethingWentWrong error={error} info={info} />;
    }

    return this.props.children;
  }
}
