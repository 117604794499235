import { useMemo, useEffect, useCallback } from "react";

import type { ISortableMessage } from "packages/client/incall/interfaces";

import { uniqSortedMessages } from "packages/client/incall/functions";

import {
  GetMessagesDocument,
  GetMessagesQuery,
  UpdateMessageDocument,
  UpdateMessageSubscription,
  useDeleteAllMessagesMutation,
  useDeleteAllMessagesUpdateSubscription,
  useDeleteMessageMutation,
  useDeleteMessageUpdateSubscription,
  useGetMessagesQuery,
  useSendMessageMutation,
} from "packages/client/incall/graphql/incallChat.generated";
import type { MessageInput } from "packages/client/graphql_definitions.generated";

export type TUseIncallChat = ReturnType<typeof useIncallChat>;

function upsertMessage<T extends ISortableMessage>(state: Readonly<T[]> = [], mess: T): T[] {
  return uniqSortedMessages([...state, mess]);
}

function removeMessage<T extends ISortableMessage>(state: Readonly<T[]> = [], datetime: string): T[] {
  return state.filter(e => e.datetime !== datetime);
}

export function useIncallChat(convID: string) {
  const queryOptionsForMutate = useMemo(
    () => ({
      query: GetMessagesDocument,
      variables: {
        convID,
      },
    }),
    [convID],
  );

  const { data, subscribeToMore, fetchMore } = useGetMessagesQuery({ variables: { convID }, skip: !convID });
  const lastKey = data?.messagesPaginated?.lastKey;
  const canLoadMore = !!lastKey;

  useEffect(() => {
    if (!convID) return;
    const unsub = subscribeToMore<UpdateMessageSubscription>({
      document: UpdateMessageDocument,
      variables: {
        convID,
      },
      updateQuery: (state, { subscriptionData }) => {
        const { data: d } = subscriptionData;
        if (!d) return state;
        const { newMessageInCall } = d;
        if (!newMessageInCall?.datetime) return state;
        return {
          ...state,
          messagesPaginated: {
            ...state.messagesPaginated,
            items: upsertMessage(state.messagesPaginated.items, newMessageInCall),
          },
        };
      },
    });
    return () => unsub();
  }, [subscribeToMore, convID]);

  const fetchMoreMessages = useCallback(() => {
    if (lastKey) {
      fetchMore({
        variables: { convID, lastKey },
        updateQuery: (state, { fetchMoreResult }) => {
          return {
            ...state,
            collabMessages: {
              ...state.messagesPaginated,
              lastKey: fetchMoreResult.messagesPaginated.lastKey,
              items: uniqSortedMessages([...fetchMoreResult.messagesPaginated.items, ...state.messagesPaginated.items]),
            },
          };
        },
      });
    }
  }, [convID, fetchMore, lastKey]);

  useDeleteMessageUpdateSubscription({
    variables: {
      convID,
    },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const deletedDatetime = subscriptionData?.data?.messageDeletedInCall?.datetime;
      if (!deletedDatetime) return;
      const { cache } = client;
      const state = cache.readQuery<GetMessagesQuery>(queryOptionsForMutate);
      const newItems = removeMessage(state.messagesPaginated.items, deletedDatetime);
      cache.writeQuery<GetMessagesQuery>({
        ...queryOptionsForMutate,
        data: {
          ...state,
          messagesPaginated: {
            ...state.messagesPaginated,
            items: newItems,
          },
        },
      });
    },
  });

  useDeleteAllMessagesUpdateSubscription({
    variables: {
      convID,
    },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const deletedConvID = subscriptionData?.data?.allMessagesFromConvDeleted?.convID;
      if (!deletedConvID) return;
      const { cache } = client;
      const state = cache.readQuery<GetMessagesQuery>(queryOptionsForMutate);
      cache.writeQuery<GetMessagesQuery>({
        ...queryOptionsForMutate,
        data: {
          ...state,
          messagesPaginated: {
            ...state.messagesPaginated,
            items: [],
          },
        },
      });
    },
  });

  const [sendMessageMut] = useSendMessageMutation({
    update: (cache, { data: { sendMessage } }) => {
      const state = cache.readQuery<GetMessagesQuery>(queryOptionsForMutate);
      cache.writeQuery<GetMessagesQuery>({
        ...queryOptionsForMutate,
        data: {
          ...state,
          messagesPaginated: {
            ...state.messagesPaginated,
            items: upsertMessage(state.messagesPaginated.items, sendMessage),
          },
        },
      });
    },
  });

  const sendMessage = useCallback(
    (content: MessageInput["content"]) => {
      sendMessageMut({
        variables: {
          message: {
            content,
            convID,
          },
        },
      });
    },
    [sendMessageMut, convID],
  );

  const [deleteMessMut] = useDeleteMessageMutation({
    update: (cache, { data: { deleteMessage } }) => {
      const state = cache.readQuery<GetMessagesQuery>(queryOptionsForMutate);
      const newItems = removeMessage(state.messagesPaginated.items, deleteMessage.datetime);
      cache.writeQuery<GetMessagesQuery>({
        ...queryOptionsForMutate,
        data: {
          ...state,
          messagesPaginated: {
            ...state.messagesPaginated,
            items: newItems,
          },
        },
      });
    },
  });

  const deleteMessage = useCallback(
    (datetime: string) => {
      deleteMessMut({
        variables: {
          datetime,
          convID,
        },
      });
    },
    [convID, deleteMessMut],
  );

  const [deleteAllMessagesMut] = useDeleteAllMessagesMutation({
    update: cache => {
      const state = cache.readQuery<GetMessagesQuery>(queryOptionsForMutate);
      cache.writeQuery<GetMessagesQuery>({
        ...queryOptionsForMutate,
        data: {
          ...state,
          messagesPaginated: {
            ...state.messagesPaginated,
            items: [],
          },
        },
      });
    },
  });

  const deleteAllMessages = useCallback(() => {
    deleteAllMessagesMut({
      variables: {
        convID,
      },
    });
  }, [convID, deleteAllMessagesMut]);

  return {
    canLoadMore,
    deleteAllMessages,
    deleteMessage,
    fetchMoreMessages,
    messages: data?.messagesPaginated?.items ?? [],
    sendMessage,
  };
}
