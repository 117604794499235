import { useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import { Cell, Flexbox, Grid, H1, Illustration, P, TextButton } from "packages/catalog";
import { EIllustration, EStatus, PATH_PRECALL_JOINCALL, PATH_SESSIONS, PATH_SIGNIN } from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { updatePIN, updateRoomKey } from "packages/client/sessions/redux/slice";

import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./Welcome.module.scss";

export function Welcome() {
  const { pathname } = useLocation();

  const dispatch = useTypedDispatch();
  const { isSignedIn } = useTypedSelector(({ authentication }) => ({ isSignedIn: authentication.isSignedIn }));

  useEffect(() => {
    dispatch(updateRoomKey(""));
    dispatch(updatePIN(""));
  }, [dispatch]);

  if (isSignedIn) {
    return <Navigate to={PATH_SESSIONS} />;
  }

  return (
    <>
      <RoutingChangeHandler message="The welcome page has loaded." pageTitle="Welcome" />
      <Grid>
        <Cell colSpanMD={6}>
          <H1 className={styles.jumbo}>Welcome to ClassView</H1>
          <P hasMargin>
            The hybrid video platform built for education. ClassView lets you join your classes as if you were there.
          </P>
          <Flexbox hasDoubleGap>
            <TextButton as={Link} status={EStatus.ACCENT} to={PATH_PRECALL_JOINCALL()}>
              Join a session
            </TextButton>
            <TextButton as={Link} to={PATH_SIGNIN()} state={{ from: pathname }}>
              Sign in
            </TextButton>
          </Flexbox>
        </Cell>
        <Cell colSpanMD={6}>
          <Illustration className={styles.illustration} illustration={EIllustration.VIRTUAL_CLASSROOM} />
        </Cell>
      </Grid>
    </>
  );
}
