import { ButtonHTMLAttributes, DetailedHTMLProps, useMemo } from "react";

import { Block, Cell, Description, Grid, H3, Icon, P, Strong, TextButton } from "packages/catalog";
import { EIcon, EStatus, underscoredToTitleCase } from "packages/utils";

import styles from "./PackageOption.module.scss";

export interface PPackageOption extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  byline: string;
  cta: string;
  heading: string;
  onClick: () => void;
  price?: number | string;
  selected?: boolean;
}

export function PackageOption({ byline, cta, heading, onClick, price, selected }: PPackageOption) {
  const blockClasses = useMemo(() => [styles.block, selected && styles.selected].filter(e => e).join(" "), [selected]);

  return (
    <Block className={blockClasses}>
      {selected && <Icon className={styles.check} icon={EIcon.CHECK} />}
      <Grid>
        <Cell>
          <H3>{underscoredToTitleCase(heading)}</H3>
        </Cell>
        <Cell>
          <P>{byline}</P>
        </Cell>
        {price !== undefined && (
          <Cell>
            {+price > 0 && (
              <>
                <P>
                  <Strong>&pound;{price}</Strong>
                </P>
                <Description>per month</Description>
              </>
            )}
            {price === 0 && (
              <P>
                <Strong>Free</Strong>
              </P>
            )}
            {typeof price === "string" && (
              <P>
                <Strong>{price}</Strong>
              </P>
            )}
          </Cell>
        )}
        <Cell center>
          <TextButton onClick={onClick} status={EStatus.ACCENT}>
            {cta}
          </TextButton>
        </Cell>
      </Grid>
    </Block>
  );
}
