import type { ReactNode } from "react";

import { Description } from "packages/catalog";
import { flattenString } from "packages/utils";

import styles from "./LabelledContent.module.scss";

export interface PLabelledContent {
  content: string | ReactNode;
  description?: string;
  label: string;
}

export function LabelledContent({ content, description, label }: PLabelledContent) {
  const descriptionString = flattenString(description);

  return (
    <figure>
      <figcaption className={styles.label}>{label}</figcaption>
      <div className={styles.text} aria-describedby={descriptionString}>
        {content}
      </div>
      {description && <Description id={descriptionString}>{description}</Description>}
    </figure>
  );
}
