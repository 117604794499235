import { useEffect, useRef } from "react";

import { promiseWaiter } from "packages/utils";

import { EVidyoLocalDevicesActions } from "../../local-devices/redux/types";
import { ECallStatus, EVidyoStatusActions } from "../redux/types";

import { dispatchVidyo, useSelectorVidyo } from "../../redux";
import { useVidyoSDKInstance } from "./useVidyoSDKInstance";

export interface ICallConfig {
  displayName: string;
  roomKey: string;
  roomPin?: string;
}

const bootedByHostReasons: string[] = [
  "VIDYO_CONNECTORDISCONNECTREASON_Booted",
  "VIDYO_CONNECTORDISCONNECTREASON_Terminated",
];

export function usePresenceInCall(callConfig: ICallConfig) {
  const vidyoSDK = useVidyoSDKInstance();
  const { callStatus, reason } = useSelectorVidyo(({ status }) => ({
    callStatus: status.callStatus,
    reason: status.reason,
  }));

  const { roomKey, roomPin, displayName } = callConfig;

  const endCallResolver = useRef<Promise<void>>(Promise.resolve());

  useEffect(() => {
    if (!vidyoSDK) return;

    let cancelled = false;

    const instanceVidyoSDK = vidyoSDK;

    endCallResolver.current = endCallResolver.current.then(async () => {
      if (cancelled) return;

      await promiseWaiter(200);

      await instanceVidyoSDK.ConnectToRoomAsGuest({
        displayName: displayName,
        roomKey: roomKey,
        roomPin: roomPin ?? "",
        host: VIDYO_PORTAL,
        onSuccess: () => {
          dispatchVidyo({ type: EVidyoStatusActions.SET_CALL_STATUS, status: ECallStatus.IN_CALL });
        },
        onDisconnected: reason => {
          if (bootedByHostReasons[0] === reason) {
            dispatchVidyo({
              type: EVidyoStatusActions.SET_CALL_STATUS,
              status: ECallStatus.KICKED,
              reason,
            });
          }
          if (bootedByHostReasons[1] === reason) {
            dispatchVidyo({
              type: EVidyoStatusActions.SET_CALL_STATUS,
              status: ECallStatus.TERMINATED,
              reason,
            });
          } else
            dispatchVidyo({
              type: EVidyoStatusActions.SET_CALL_STATUS,
              status: ECallStatus.OUTSIDE_CALL,
            });
        },
        onFailure: reason => {
          console.error("Failure", reason);
          dispatchVidyo({
            type: EVidyoStatusActions.SET_CALL_STATUS,
            status: ECallStatus.FAILURE,
            reason,
          });
        },
      });
    });
    return () => {
      cancelled = true;

      endCallResolver.current = endCallResolver.current.then(async () => {
        if ((await instanceVidyoSDK.GetState()) === "VIDYO_CONNECTORSTATE_Connected") {
          dispatchVidyo({
            type: EVidyoLocalDevicesActions.RESET_MUTE,
          });
          return instanceVidyoSDK.Disconnect() as unknown as Promise<void>;
        } else return Promise.resolve();
      });
    };
  }, [displayName, roomKey, roomPin, vidyoSDK]);

  return { callStatus, reason };
}
