import StyleHeadRenderer from "./StyleHeadRenderer";

// raw loader is needed for managing as text content
import lightCss from "../styles/themes/Light.css?raw";

import { ETheme } from "../types";

export default function LightTheme() {
  return <StyleHeadRenderer content={lightCss} theme={ETheme.LIGHT} />;
}
