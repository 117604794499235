import { FormEvent, useCallback, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Cell, Flexbox, Grid, H1, Input, P, TextButton } from "packages/catalog";
import {
  EAppearance,
  PATH_INCALL,
  PATH_PRECALL_DEVICES,
  PATH_PRECALL_JOINCALL,
  TParams,
  // PATH_PRECALL_WAITINGROOM
} from "packages/utils";

import { useTypedSelector } from "packages/client/redux";

import { useAuth } from "packages/client/authentication/hooks";
import { useRoom } from "packages/client/incall/hooks";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { DevicePreview, DeviceStatus } from "packages/client/precall/components";

export function ChooseDevices() {
  const { callID }: TParams = useParams();

  const { isSignedIn, guestName, meetingId } = useTypedSelector(({ authentication, sessions }) => ({
    guestName: authentication.guestName,
    isSignedIn: authentication.isSignedIn,
    meetingId: sessions.roomKey,
    token: authentication.token,
  }));

  const navigate = useNavigate();
  const {
    // isModerator,
    loading: roomLoading,
    room,
  } = useRoom();
  const { getGuestToken } = useAuth();

  const isRoomKeyInvalid = useMemo(() => !room?.roomInfo?.key && !roomLoading, [room?.roomInfo?.key, roomLoading]);

  const isJoinDisabled = useMemo(() => {
    return roomLoading || isRoomKeyInvalid || !meetingId.length || !guestName.length;
  }, [roomLoading, isRoomKeyInvalid, meetingId.length, guestName.length]);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isRoomKeyInvalid) return;

      if (!isSignedIn) {
        await getGuestToken(meetingId);
      }

      // if (room?.roomInfo?.waitingRoomEnabled && !isModerator) {
      //   navigate(PATH_PRECALL_WAITINGROOM, { state: { from: PATH_PRECALL_JOINCALL(meetingId) } });
      //   return;
      // }

      navigate(PATH_INCALL(meetingId), { state: { from: PATH_PRECALL_DEVICES(meetingId) } });
    },
    [isRoomKeyInvalid, isSignedIn, navigate, meetingId, getGuestToken],
  );

  useEffect(() => {
    if (isRoomKeyInvalid) navigate(PATH_PRECALL_JOINCALL(callID));
  }, [callID, isRoomKeyInvalid, navigate]);

  return (
    <>
      <RoutingChangeHandler message="The choose devices page has loaded." pageTitle="Choose devices" />
      <Grid as="form" onSubmit={handleSubmit} noColGap>
        <Cell>
          <H1 center>Choose audio & video devices</H1>
          {room?.roomInfo?.displayName && <P center>{room?.roomInfo?.displayName}</P>}
        </Cell>
        <Cell>
          <DevicePreview />
        </Cell>
        <Cell center>
          <DeviceStatus />
        </Cell>
        <Cell center>
          <Flexbox>
            <TextButton appearance={EAppearance.GHOST} as={Link} to={PATH_PRECALL_JOINCALL(meetingId)}>
              Cancel
            </TextButton>
            <Input type="submit" disabled={isJoinDisabled} value="Join session" />
          </Flexbox>
        </Cell>
      </Grid>
    </>
  );
}
