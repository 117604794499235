import { Outlet } from "react-router-dom";

import { Cell, Grid, H1, NavigationLink, NavigationList } from "packages/catalog";
import { PATH_SUPERADMIN_ORGANISATIONS, PATH_SUPERADMIN_ORGANISATIONS_PENDING } from "packages/utils";

export function SuperAdminOrganisationsLayout() {
  return (
    <Grid noColGap>
      <Cell>
        <H1>Organisations</H1>
      </Cell>
      <Cell>
        <NavigationList>
          <NavigationLink end to={PATH_SUPERADMIN_ORGANISATIONS}>
            All
          </NavigationLink>
          <NavigationLink to={PATH_SUPERADMIN_ORGANISATIONS_PENDING}>Pending</NavigationLink>
        </NavigationList>
      </Cell>
      <Cell>
        <Outlet />
      </Cell>
    </Grid>
  );
}
