import { HEADER_JSON } from "packages/utils";

import { fetchDjangoAPIAuthenticated } from "packages/client/fetch";

import type { IDjangoFeatureFlag } from "packages/client/feature-flags/django/types";

export function addFlag(flag: Partial<IDjangoFeatureFlag>) {
  return fetchDjangoAPIAuthenticated("/flags/", { headers: HEADER_JSON, body: JSON.stringify(flag), method: "POST" });
}

export async function editFlag(flag: IDjangoFeatureFlag) {
  const resp = await fetchDjangoAPIAuthenticated(`/flags/${flag.name}/`, {
    headers: HEADER_JSON,
    body: JSON.stringify(flag),
    method: "PATCH",
  });
  if (resp.status !== 200) return undefined;
  return resp.json() as Promise<IDjangoFeatureFlag>;
}

export async function deleteFlag(flag: IDjangoFeatureFlag) {
  const resp = await fetchDjangoAPIAuthenticated(`/flags/${flag.name}/`, {
    headers: HEADER_JSON,
    method: "DELETE",
  });
  return resp.status === 200;
}

export async function getAllFlags() {
  const resp = await fetchDjangoAPIAuthenticated("/flags/", { headers: HEADER_JSON });
  const data: IDjangoFeatureFlag[] = await resp.json();
  return data;
}
