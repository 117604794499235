import { HEADER_JSON } from "packages/utils";

import { fetchDjangoAPI } from "packages/client/fetch";

export async function validatePIN(roomKey: string, pin: string) {
  const resp = await fetchDjangoAPI(`/rooms/${roomKey}/check-pin/${pin}`, { headers: HEADER_JSON });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp.json() as Promise<boolean>;
}

export async function validateRoomKey(roomKey: string) {
  const resp = await fetchDjangoAPI(`/rooms/${roomKey}/get-key`, { headers: HEADER_JSON });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp.json() as Promise<{ key: string }>;
}
