import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { IAuthenticationState } from "packages/client/authentication/redux/types";

const initialState: IAuthenticationState = {
  is2FACodeSent: false,
  isSignedIn: undefined,
  guestName: "",
  token: undefined,
  userID: "",
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    clearAuthentication: state => {
      state.guestName = "";
      state.is2FACodeSent = false;
      state.isSignedIn = false;
      state.token = undefined;
      state.userID = "";
    },
    clearToken: state => {
      state.token = undefined;
    },
    setAuthenticatedToken: (state, action: PayloadAction<string>) => {
      state.isSignedIn = true;
      state.token = action.payload;
    },
    setGuestName: (state, action: PayloadAction<string>) => {
      state.guestName = action.payload;
    },
    setGuestToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setIs2FACodeSent: (state, action: PayloadAction<boolean>) => {
      state.is2FACodeSent = action.payload;
    },
    setIsSignedIn: (state, action: PayloadAction<boolean>) => {
      state.isSignedIn = action.payload;
    },
    setAuthenticatedUserProfile: (state, action: PayloadAction<{ userID: string; name: string }>) => {
      state.guestName = action.payload.name;
      state.userID = action.payload.userID;
    },
  },
});

export const {
  clearAuthentication,
  clearToken,
  setAuthenticatedToken,
  setAuthenticatedUserProfile,
  setGuestName,
  setGuestToken,
  setIs2FACodeSent,
  setIsSignedIn,
} = authenticationSlice.actions;

export const authenticationReducer = authenticationSlice.reducer;
