import { LOCAL_REMEMBER_ME } from "packages/utils";

export function getRememberMeFromLocalStorage(): boolean {
  // check for setting in local storage and return false if not there
  const localRememberMe = JSON.parse(localStorage.getItem(LOCAL_REMEMBER_ME) || "false") as boolean;

  // if false
  if (!localRememberMe) {
    // set it into local storage as false
    localStorage.setItem(LOCAL_REMEMBER_ME, JSON.stringify(localRememberMe));
  }
  return localRememberMe;
}
