import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Feedback, Loader } from "packages/catalog";
import { EStatus, TParams } from "packages/utils";

import {
  fetchRecordingCloudfrontURLFromAPIEndpoint,
  fetchRecordingCloudfrontURLFromHash,
  generateRecordingAPIEndpointURL,
} from "packages/client/files/s3";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { Player } from "packages/client/video/components";

import styles from "./Recording.module.scss";

const params = new URLSearchParams(window.location.href.split("?")?.[1] ?? "");
const hash = params.has("publicUrl") ? params.get("publicUrl").toString() : undefined;

export function Recording() {
  const { recordingID }: TParams = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [videoSource, setVideoSource] = useState("");

  useEffect(() => {
    let cancelled = false;
    const checkedReplayID = recordingID ?? "";

    async function defineVideoSource(replayID: string) {
      setVideoSource("");
      setErrorMessage("");

      // get video source from hash for public links (replayID is set to 0 for public links)
      if (replayID === "0" && hash) {
        try {
          const cloudfrontURL = await fetchRecordingCloudfrontURLFromHash(hash);
          if (!cancelled) setVideoSource(cloudfrontURL);
        } catch (error) {
          setErrorMessage(error.message);
        }
      } else {
        // get video source from replayID (for your own videos)
        const recordingAPIEndpointURL = generateRecordingAPIEndpointURL(`${checkedReplayID}.mp4`);

        try {
          const recordingCloudfrontURL = await fetchRecordingCloudfrontURLFromAPIEndpoint(recordingAPIEndpointURL);

          if (cancelled) return;
          if (recordingCloudfrontURL !== "") {
            setVideoSource(recordingCloudfrontURL);
          } else {
            setErrorMessage(
              "This recording ID doesn't exist. Please try again or contact ClassView support if the problem persists.",
            );
          }
        } catch (error) {
          if (cancelled) return;
          setErrorMessage(error.message);
        }
      }
    }

    defineVideoSource(checkedReplayID);

    return () => {
      cancelled = true;
    };
  }, [recordingID]);

  if (videoSource === "") {
    if (errorMessage.length)
      return (
        <>
          <RoutingChangeHandler message="The recording playback error page has loaded." pageTitle="Playback error" />
          <div className={styles.centered}>
            <Feedback status={EStatus.DANGER}>{errorMessage}</Feedback>
          </div>
        </>
      );
    return <Loader message="Loading the recording..." />;
  }

  return (
    <>
      <RoutingChangeHandler message="The session recording page has loaded." pageTitle="Watch session" />
      <Player source={videoSource} />
    </>
  );
}
