import { EVidyoSharingActions, ISharingState, TSharingAction } from "./types";

import { addToAvailableArray } from "../functions/addToAvailableArray";
import { removeFromAvailableArray } from "../functions/removeFromAvailableArray";

const initialState: ISharingState = {
  monitor: { available: [], selected: null },
  windowShare: { available: [], selected: null },
  isSharing: false,
  passiveSharingAvailable: IS_ELECTRON,
};

export function sharingReducer(state = initialState, action: TSharingAction): ISharingState {
  switch (action.type) {
    case EVidyoSharingActions.ADD_MONITOR:
      return {
        ...state,
        monitor: { ...state.monitor, available: addToAvailableArray(state.monitor.available, action.monitor) },
      };
    case EVidyoSharingActions.ADD_WINDOW_SHARE:
      return {
        ...state,
        windowShare: {
          ...state.windowShare,
          available: addToAvailableArray(state.windowShare.available, action.windowShare),
        },
      };
    case EVidyoSharingActions.REMOVE_MONITOR:
      return {
        ...state,
        monitor: { ...state.monitor, available: removeFromAvailableArray(state.monitor.available, action.monitor) },
      };
    case EVidyoSharingActions.REMOVE_WINDOW_SHARE:
      return {
        ...state,
        windowShare: {
          ...state.windowShare,
          available: removeFromAvailableArray(state.windowShare.available, action.windowShare),
        },
      };
    case EVidyoSharingActions.SELECT_MONITOR:
      return {
        ...state,
        monitor: { ...state.monitor, selected: action.monitor },
        isSharing: !!state.windowShare.selected || !!action.monitor,
      };
    case EVidyoSharingActions.SELECT_WINDOW_SHARE:
      return {
        ...state,
        isSharing: !!state.monitor.selected || !!action.windowShare,
        windowShare: { ...state.windowShare, selected: action.windowShare },
      };
    default:
      return state;
  }
}
