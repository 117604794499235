import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  Cell,
  ConfirmActionModal,
  IllustratedCTA,
  Grid,
  H1,
  IconButton,
  ShowMore,
  Table,
  TextButton,
} from "packages/catalog";

import {
  EAppearance,
  EBreakpoint,
  EDimension,
  EIcon,
  EIllustration,
  EStatus,
  PATH_POLL,
  scrollToTop,
  SEGMENT_CREATE,
  useWindowSize,
} from "packages/utils";

import { PollStatusV2 } from "packages/client/graphql_definitions.generated";
import {
  FEssentialPollFragment,
  useDeletePollMutation,
  useUpdatePollStatusMutation,
} from "packages/client/polls/graphql/polls.generated";

import { usePoll } from "packages/client/polls/hooks";

import { PollListItem } from "packages/client/polls/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

import { useSnackbarFeedbackContext } from "packages/client/snackbar-feedback/contexts";

import styles from "./Polls.module.scss";

const COLUMNS = ["Name", "Participants", "Status"];
const COLUMNS_WIDTH = "40% repeat(auto-fit, minmax(5rem, 1fr))";

export function Polls() {
  const { fetchMorePolls, loadingPolls, polls, refetchPolls } = usePoll();
  const { dispatchLinkSnackbar, dispatchSnackbar, dispatchRetrySnackbar } = useSnackbarFeedbackContext();

  const [selectedPoll, setSelectedPoll] = useState<FEssentialPollFragment>(null);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmPublishModal, setShowConfirmPublishModal] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { width } = useWindowSize();

  const showScroll = useMemo(() => width < EBreakpoint.BREAKPOINT_MD && showScrollButton, [showScrollButton, width]);

  useEffect(() => {
    function onScroll() {
      setShowScrollButton(document.documentElement.scrollTop > document.documentElement.clientHeight);
    }
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  const [deletePoll] = useDeletePollMutation({
    onCompleted: () => {
      refetchPolls();
      dispatchSnackbar({ content: `${selectedPoll.name} deleted.` });
    },
    onError: () =>
      dispatchRetrySnackbar("Unable to delete poll.", () =>
        deletePoll({
          variables: {
            pollID: selectedPoll.id,
          },
          refetchQueries: ["getAllPolls"],
        }),
      ),
  });

  const prepareToDeletePoll = useCallback((poll: FEssentialPollFragment) => {
    setSelectedPoll(poll);
    setShowConfirmDeleteModal(true);
  }, []);

  const handleDeletePoll = useCallback(() => {
    setShowConfirmDeleteModal(false);
    deletePoll({
      variables: {
        pollID: selectedPoll.id,
      },
      refetchQueries: ["getAllPolls"],
    });
  }, [deletePoll, selectedPoll]);

  const [endPoll] = useUpdatePollStatusMutation({
    refetchQueries: ["getAllPolls"],
  });

  const handleEndPoll = useCallback(
    async (poll: FEssentialPollFragment) => {
      setSelectedPoll(poll);
      try {
        await endPoll({ variables: { pollID: poll.id, status: PollStatusV2.Ended } });
        dispatchLinkSnackbar(`Poll "${poll.name}" has been ended.`, "View poll", PATH_POLL(poll.id));
      } catch {
        dispatchRetrySnackbar("Unable to end poll.", () => handleEndPoll(poll));
      }
    },
    [dispatchLinkSnackbar, dispatchRetrySnackbar, endPoll],
  );

  const [publishPoll] = useUpdatePollStatusMutation({
    onCompleted: () => {
      dispatchLinkSnackbar(`Poll "${selectedPoll.name}" has been published.`, "View poll", PATH_POLL(selectedPoll.id));
    },
    onError: () =>
      dispatchRetrySnackbar("Unable to publish poll.", () =>
        publishPoll({
          variables: { pollID: selectedPoll.id, status: PollStatusV2.Published },
          refetchQueries: ["getAllPolls"],
        }),
      ),
  });

  const prepareToPublishPoll = useCallback((poll: FEssentialPollFragment) => {
    setSelectedPoll(poll);
    setShowConfirmPublishModal(true);
  }, []);

  const handlePublishPoll = useCallback(() => {
    setShowConfirmPublishModal(false);
    publishPoll({
      variables: { pollID: selectedPoll.id, status: PollStatusV2.Published },
      refetchQueries: ["getAllPolls"],
    });
  }, [publishPoll, selectedPoll]);

  useEffect(() => {
    refetchPolls();
  }, [refetchPolls]);

  return (
    <>
      <RoutingChangeHandler message="The polls page has loaded." pageTitle="Polls" />
      <Grid noColGap>
        {/* {we may need the total number of a users polls to act as the condition for whether or not to show the empty state} */}
        {polls?.items.length ? (
          <>
            <Cell className={styles.heading}>
              <H1>Polls</H1>
              {width > EBreakpoint.BREAKPOINT_SM ? (
                <TextButton appearance={EAppearance.FILL} as={Link} status={EStatus.ACCENT} to={SEGMENT_CREATE}>
                  Create a poll
                </TextButton>
              ) : (
                <IconButton
                  appearance={EAppearance.FILL}
                  as={Link}
                  description="Create a poll"
                  dimension={EDimension.MEDIUM}
                  icon={EIcon.PLUS}
                  status={EStatus.ACCENT}
                  to={SEGMENT_CREATE}
                />
              )}
            </Cell>
            <Cell>
              <Table
                columns={COLUMNS}
                description="A table listing the polls you've created"
                columnWidths={COLUMNS_WIDTH}
                hasMenuColumn>
                {polls?.items.map(poll => (
                  <PollListItem
                    columns={COLUMNS}
                    columnWidths={COLUMNS_WIDTH}
                    handleEnd={() => handleEndPoll(poll)}
                    key={poll.id}
                    poll={poll}
                    prepareDelete={() => prepareToDeletePoll(poll)}
                    preparePublish={() => prepareToPublishPoll(poll)}
                  />
                ))}
              </Table>
            </Cell>
          </>
        ) : (
          !loadingPolls && (
            <Cell>
              <IllustratedCTA cta="Create a poll" illustration={EIllustration.WOMAN_CHECKLIST} to={SEGMENT_CREATE}>
                {"You haven't created any polls yet."}
              </IllustratedCTA>
            </Cell>
          )
        )}
        {polls?.items.length > 0 && (
          <Cell className={styles.showMore}>
            <ShowMore
              maxVolume={polls.total}
              currentVolume={polls?.items.length}
              item="poll"
              onShowMore={fetchMorePolls}
              hasMore={polls?.items.length < polls.total}
            />
          </Cell>
        )}
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="poll"
        isOpen={showConfirmDeleteModal}
        onAbort={() => setShowConfirmDeleteModal(false)}
        onAction={handleDeletePoll}
        status={EStatus.DANGER}
      />
      <ConfirmActionModal
        action="publish"
        content="poll"
        isOpen={showConfirmPublishModal}
        onAbort={() => setShowConfirmPublishModal(false)}
        onAction={handlePublishPoll}
      />
      {showScroll && (
        <span className={styles.scrollButton}>
          <IconButton
            appearance={EAppearance.OUTLINE}
            as="button"
            description="Back to top"
            dimension={EDimension.MEDIUM}
            icon={EIcon.CHEVRONUP}
            onClick={scrollToTop}
            status={EStatus.NEUTRAL}
          />
        </span>
      )}
      {/* {isDuplicating && (
        <SnackbarContainer>
          <Snackbar dismissTimeout={5000} handleDismiss={() => setIsDuplicating(false)}>
            {`Creating copy of "${selectedPoll.name}"`}
          </Snackbar>
        </SnackbarContainer>
      )} */}
      {/* {showDuplicateSuccessSnackbar && (
        <SnackbarContainer>
          <Snackbar dismissTimeout={5000} handleDismiss={() => setShowDuplicateSuccessSnackbar(false)}>
            {`Poll "${selectedPoll.name}" has been duplicated.`}
            <PlainAction as={Link} status={EStatus.ACCENT} text="View poll" to="#" />
          </Snackbar>
        </SnackbarContainer>
      )}
      {showDuplicateErrorSnackbar && (
        <SnackbarContainer>
          <Snackbar dismissTimeout={5000} handleDismiss={() => setShowDuplicateErrorSnackbar(false)}>
            Unable to duplicate poll.
            <PlainAction
              as="button"
              onClick={() => handleDuplicate(selectedPoll)}
              status={EStatus.ACCENT}
              text="Retry"
            />
          </Snackbar>
        </SnackbarContainer>
      )} */}
    </>
  );
}
