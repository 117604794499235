import { useParams } from "react-router-dom";

import { Cell, Feedback, Grid, H2, Loader, Table, TRTDTemp } from "packages/catalog";
import { EStatus, TParams } from "packages/utils";

import { useGetChildOrganisationsOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SuperAdminOrganisationRow } from "packages/client/organisations/components/SuperAdminOrganisationRow/SuperAdminOrganisationRow";

const COLUMNS = ["ID", "Organisation"];
const COLUMN_WIDTHS = "4rem 1fr 2rem";

export function SuperAdminOrganisationChildren() {
  const { organisationID }: TParams = useParams();

  const {
    data: childOrganisationsData,
    loading,
    error,
  } = useGetChildOrganisationsOrganisationQuery({
    fetchPolicy: "cache-and-network",
    variables: { organisationID: parseInt(organisationID) },
  });

  return (
    <>
      <RoutingChangeHandler
        message="The organistion child organisations page has loaded."
        pageTitle="Organisation child organisations"
      />
      <Grid noColGap>
        <Cell>
          <H2>Child organisations</H2>
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing child organisations"
            hasMenuColumn>
            <>
              {loading && (
                <TRTDTemp>
                  <Loader notFullHeight />
                </TRTDTemp>
              )}
              {error && (
                <TRTDTemp>
                  <Feedback status={EStatus.DANGER}>Unable to get list of organisations.</Feedback>
                </TRTDTemp>
              )}
              <>
                {childOrganisationsData?.organisation?.organisationSet && (
                  <>
                    {childOrganisationsData?.organisation?.organisationSet.map(childOrganisation => (
                      <SuperAdminOrganisationRow
                        columns={COLUMNS}
                        columnWidths={COLUMN_WIDTHS}
                        hideParent
                        key={childOrganisation.id}
                        organisationID={childOrganisation.id}
                        organisationName={childOrganisation.name}
                      />
                    ))}
                  </>
                )}
              </>
            </>
          </Table>
        </Cell>
      </Grid>
    </>
  );
}
