import { SVGProps, useMemo } from "react";

import { flattenString, EIcon } from "packages/utils";

import handIcon from "public/svg/hand.svg";
import pinIcon from "public/svg/pin.svg";
import recordIcon from "public/svg/record.svg";
import feather from "feather-icons/dist/feather-sprite.svg";

export interface PIcon extends SVGProps<SVGSVGElement> {
  className?: string;
  fill?: string;
  icon: EIcon;
  title?: string;
  width?: string;
}

// hand icon currently used separately and not with this override; override allows for additional custom icons
const overridesIcon: Partial<Record<EIcon, string>> = {
  hand: handIcon,
  pin: pinIcon,
  record: recordIcon,
};

export function Icon({ className, fill, icon, strokeLinecap, strokeLinejoin, title, width, ...rest }: PIcon) {
  const formattedTitle = flattenString(title);

  const iconHref = useMemo(() => `${overridesIcon[icon] ?? feather}#${icon}`, [icon]);

  return (
    <svg
      aria-hidden="true"
      className={className}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      {...rest}>
      {title && <title id={formattedTitle}>{title}</title>}
      <use
        aria-labelledby={title && formattedTitle}
        fill={fill ? fill : "transparent"}
        href={iconHref}
        preserveAspectRatio="xMinYMin meet"
        role="img"
        strokeLinecap={strokeLinecap ?? "round"}
        strokeLinejoin={strokeLinejoin ?? "round"}
      />
    </svg>
  );
}
