import { useCallback, useMemo, useState } from "react";

import { Description, Flexbox, Label, IconButton, SelectList } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

import { useTypedDispatch } from "packages/client/redux";
import { changeDialoutIDs } from "packages/client/sessions/redux/slice";

import type {
  FEssentialRoomSystemDialoutFragment,
  FFullRoomSystemFragment,
} from "packages/client/room-systems/graphql/roomsystems.generated";

import { useGetAllRoomSystemsQuery } from "packages/client/room-systems/graphql/roomsystems.generated";

import { EditRoomSystemsResults } from "packages/client/sessions/components";

import styles from "./EditRoomSystems.module.scss";

export type TRoomSystemDialout = Omit<FEssentialRoomSystemDialoutFragment, "__typename">;

export interface PEditRoomSystems {
  description?: string;
  label: string;
  onSelect: (sel: TRoomSystemDialout[]) => void;
  preSelected: TRoomSystemDialout[];
}

export function EditRoomSystems({ description, label, onSelect, preSelected }: PEditRoomSystems) {
  const dispatch = useTypedDispatch();

  const schoolsPricing = false;

  const [searchString, setSearchString] = useState("");

  const { data: allRoomSystemsData, loading } = useGetAllRoomSystemsQuery({
    variables: { page: 1, search: searchString, pageSize: 10 },
    returnPartialData: true,
  });

  const dialedRoomSystems = useMemo(
    () => preSelected.filter(rsdt => rsdt.dialOut).map(frsdt => parseInt(frsdt.roomSystem.id, 10)),
    [preSelected],
  );

  const convertSingleRoomToDialType = useCallback(
    (rs: FFullRoomSystemFragment): TRoomSystemDialout => {
      const matchingPreselectedRs = preSelected.find(psrs => {
        return psrs.roomSystem.id === rs.id;
      });

      return {
        id: rs.id,
        roomSystem: rs,
        dialOut: matchingPreselectedRs ? matchingPreselectedRs.dialOut : true,
      };
    },
    [preSelected],
  );

  const convertRoomsToDialTypes = useCallback(
    (arr: FFullRoomSystemFragment[]): TRoomSystemDialout[] => {
      return arr.map(rs => convertSingleRoomToDialType(rs));
    },
    [convertSingleRoomToDialType],
  );

  const convertRoomDialTypesToRooms = useCallback((arr: TRoomSystemDialout[]): FFullRoomSystemFragment[] => {
    return arr.map(rsdt => rsdt.roomSystem);
  }, []);

  const handleSelect = useCallback(
    (selected: FFullRoomSystemFragment[]) => {
      onSelect(convertRoomsToDialTypes(selected));
    },
    [convertRoomsToDialTypes, onSelect],
  );

  return (
    <SelectList
      closeIfNoInput
      data={allRoomSystemsData?.allRoomSystems?.objects ?? []}
      description={description}
      isLoading={loading}
      label={label}
      onSearch={setSearchString}
      onSelect={handleSelect}
      preSelected={convertRoomDialTypesToRooms(preSelected)}
      renderDataAs={(data, addItem) => (
        <Flexbox flexDirectionColumn>
          {data.map((rs: FFullRoomSystemFragment) => (
            <IconButton
              appearance={EAppearance.GHOST}
              as="button"
              description={`Add ${rs.name}`}
              dimension={EDimension.SMALL}
              icon={EIcon.PLUS}
              key={rs.id}
              label={`Add ${rs.name}`}
              onClick={() => addItem(rs)}
              status={EStatus.NEUTRAL}
              type="button"
            />
          ))}
        </Flexbox>
      )}
      renderSelectedAs={(selected, removeItem) => (
        <>
          <Flexbox className={styles.selected} flexDirectionColumn>
            <Flexbox justifyContentEnd>
              <Label text="Dial out" />
            </Flexbox>
            <EditRoomSystemsResults
              dialOutRoomSystems={dialedRoomSystems}
              disabled={schoolsPricing}
              removeAction={removeItem}
              rooms={selected.map(rs => ({ id: parseInt(rs.id, 10), roomName: rs.name }))}
              updateDialOut={ids => dispatch(changeDialoutIDs(ids))}
            />
            <Description>{"To invite the room system but not call it into the session, untick 'Dial out'"}</Description>
          </Flexbox>
        </>
      )}
    />
  );
}
