import type { Action, Reducer } from "redux";

import { mergeDeep } from "packages/utils";

export type TSerializer<A, B> = (data: A) => B;

function serializeNothing<A, B>(e: A) {
  return e as unknown as B;
}

function getSavedState<SSaved>(k: string) {
  const serialized = localStorage.getItem(k);
  const serializedState: SSaved | undefined = serialized ? JSON.parse(serialized) : undefined;
  return serializedState;
}

function saveState<SSaved>(k: string, s: SSaved) {
  localStorage.setItem(k, JSON.stringify(s));
}

/** @see https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript/44120213#44120213 */
function getHash(o: unknown) {
  const s = JSON.stringify(o ?? {});
  return s.split("").reduce((a, b) => {
    // eslint-disable-next-line no-bitwise
    a = (a << 5) - a + b.charCodeAt(0);
    // eslint-disable-next-line no-bitwise
    return a & a;
  }, 0);
}

export function saveReducerToLocalStorage<S, A extends Action<unknown>, SSaved extends Partial<S> = S>(
  key: string,
  reducer: Reducer<S, A>,
  serializer: TSerializer<S, SSaved> = serializeNothing,
): Reducer<S, A> {
  const nameStorage = `${key}_persistedreducer`;
  const serializedState = getSavedState<SSaved>(nameStorage);

  let hashSaved = getHash(serializedState);
  let t: number | NodeJS.Timeout;

  return function (s: S, a: A): S {
    if (s === undefined && serializedState) {
      const initialState = reducer(s, a);

      return mergeDeep(initialState, serializedState);
    }
    const newState = reducer(s, a);

    if (t) clearTimeout(t as number);

    t = setTimeout(() => {
      const serialized = serializer(newState);
      const newHash = getHash(serialized);

      if (newHash !== hashSaved) {
        hashSaved = newHash;
        saveState(nameStorage, serialized);
      }
    }, 100);
    return newState;
  };
}
