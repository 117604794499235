import { InputHTMLAttributes, useRef, useState } from "react";

import type { Avatar } from "packages/catalog";
import type { EIcon } from "packages/utils";

import { AutocompleteSearch } from "./AutocompleteSearch";
import { DropdownList } from "./DropdownList";

import styles from "./AutocompleteDropdown.module.scss";

export interface PAutocompleteDropdown extends InputHTMLAttributes<HTMLInputElement> {
  onOptionSelect: (option: IAutocompleteDropdownOption) => void;
  options: IAutocompleteDropdownOption[];
  optionsCollectiveName: string;
  showNoResultsMessage?: boolean;
  value: string;
}

export interface IAutocompleteDropdownOption {
  description?: string;
  id: string;
  image?: EIcon | ReactChild<typeof Avatar>;
  name: string;
}

export function AutocompleteDropdown({
  onOptionSelect,
  options,
  optionsCollectiveName,
  showNoResultsMessage,
  value,
  ...rest
}: PAutocompleteDropdown) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const autoCompleteSearchRef = useRef<HTMLInputElement>(null);
  const dropdownListRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.dropdownWrapper} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
      <AutocompleteSearch
        autoCompleteSearchRef={autoCompleteSearchRef}
        dropdownListRef={dropdownListRef}
        dropdownOpen={dropdownOpen}
        onSelection={onOptionSelect}
        searchOptions={options}
        setDropdownOpen={setDropdownOpen}
        value={value}
        {...rest}
      />
      <DropdownList
        autoCompleteSearchRef={autoCompleteSearchRef}
        dropdownListRef={dropdownListRef}
        onSelect={onOptionSelect}
        options={options}
        optionsCollectiveName={optionsCollectiveName}
        searchInput={value}
        setDropdownOpen={setDropdownOpen}
        showNoResultsMessage={showNoResultsMessage}
      />
    </div>
  );
}
