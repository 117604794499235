import styles from "./Statistic.module.scss";

export interface PStatistic {
  count: number;
  text: string;
}

export function Statistic({ count, text }: PStatistic) {
  return (
    <h2>
      <span className={styles.number}>{`${count}`}</span>
      <span className={styles.text}>{text}</span>
    </h2>
  );
}
