import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { IFeedback, IFeedbackFormState } from "packages/client/feedback-form/redux/types";

const initialState: IFeedbackFormState = {
  feedback: {
    callID: "",
    email: "",
    feedbackMessage: "",
    happyToContact: false,
    rating: null,
    validEmail: false,
  },
};

export const feedbackFormSlice = createSlice({
  name: "feedbackForm",
  initialState,
  reducers: {
    updateFeedbackForm: (state, action: PayloadAction<IFeedback>) => {
      state.feedback = action.payload;
    },
  },
});

export const { updateFeedbackForm } = feedbackFormSlice.actions;

export const feedbackFormReducer = feedbackFormSlice.reducer;
