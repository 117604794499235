import { useCallback, useState } from "react";

import { Cell, Feedback, Grid, P, Pagination, TRTDTemp, Table } from "packages/catalog";
import { EStatus } from "packages/utils";

import { approveUser, deleteUser } from "packages/client/users/django/requests";
import { useGetPendingUsersQuery } from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SuperAdminUserRow } from "packages/client/users/components/SuperAdminUserRow/SuperAdminUserRow";

const COLUMNS = ["ID", "User", "Status", "Organisation"];
const COLUMN_WIDTHS = "4rem 1fr 0.5fr 1fr 3rem";

export function SuperAdminPendingUsers() {
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { data, refetch } = useGetPendingUsersQuery({
    fetchPolicy: "cache-and-network",
    variables: { page: currentPage },
  });

  const handleApproveUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      approveUser(userID)
        .then(() => {
          refetch();
          setSuccessMessage("User approved.");
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetch],
  );

  const handleRejectUser = useCallback(
    (userID: string) => {
      deleteUser(userID).then(() => refetch());
    },
    [refetch],
  );

  return (
    <>
      <RoutingChangeHandler message="The pending users page has loaded." pageTitle="Pending users" />
      <Grid>
        {errorMessage.length ? (
          <Cell>
            <Feedback status={EStatus.DANGER}>{errorMessage}</Feedback>
          </Cell>
        ) : null}
        {successMessage.length ? (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>{successMessage}</Feedback>
          </Cell>
        ) : null}
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing all pending users on ClassView"
            hasMenuColumn>
            <>
              {data?.pendingUsers?.objects.length ? (
                <>
                  {data?.pendingUsers?.objects?.map(pendingUser => (
                    <SuperAdminUserRow
                      columns={COLUMNS}
                      columnWidths={COLUMN_WIDTHS}
                      email={pendingUser.email}
                      handleApproveUser={handleApproveUser}
                      isApproved={pendingUser.isActive}
                      isEmailAuthenticated={pendingUser.emailAuthenticated}
                      isSuperAdmin={pendingUser.isSuperuser}
                      key={pendingUser.id}
                      organisationID={pendingUser.organisation?.id}
                      organisationName={pendingUser.organisation?.name}
                      prepareDeleteUser={handleRejectUser}
                      userFullname={`${pendingUser.firstName} ${pendingUser.lastName}`}
                      userID={pendingUser.id}
                    />
                  ))}
                </>
              ) : (
                <TRTDTemp>
                  <P center>No pending users</P>
                </TRTDTemp>
              )}
            </>
          </Table>
        </Cell>
        {data?.pendingUsers?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={data?.pendingUsers?.hasNext}
              hasPrevious={data?.pendingUsers?.hasPrev}
              page={data?.pendingUsers?.page}
              pages={data?.pendingUsers?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
    </>
  );
}
