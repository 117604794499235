import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useCallInformation } from "packages/call";
import { Flexbox, IconButton, PlainAction } from "packages/catalog";
import { ETheme } from "packages/theming";
import {
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  LOCAL_THEME,
  PATH_SIGNIN,
  PATH_TLD,
  SEGMENT_REGISTER,
  SEGMENT_SIGNIN,
  TParams,
} from "packages/utils";

import { useAuth } from "packages/client/authentication/hooks";
import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import { updateTheme } from "packages/client/layout/redux/slice";

import { AppLogo } from "packages/client/layout/components";

import styles from "./BasicHeader.module.scss";

export function BasicHeader() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { callID }: TParams = useParams();

  const { signOut } = useAuth();
  const { resetLeaveStatus, resetVidyoCallStatus } = useCallInformation();
  const dispatch = useTypedDispatch();
  const { isSignedIn, theme } = useTypedSelector(({ authentication, layout }) => ({
    isSignedIn: authentication.isSignedIn,
    theme: layout.theme,
  }));

  const otherTheme = useMemo(() => (theme === ETheme.LIGHT ? ETheme.DARK : ETheme.LIGHT), [theme]);

  const handleChangeTheme = useCallback(() => {
    dispatch(updateTheme(otherTheme));

    localStorage.setItem(LOCAL_THEME, otherTheme);
  }, [dispatch, otherTheme]);

  const handleOnClickCreateAnAccount = useCallback(() => {
    resetVidyoCallStatus();
    resetLeaveStatus();
    navigate(SEGMENT_REGISTER);
  }, [navigate, resetLeaveStatus, resetVidyoCallStatus]);

  const handleOnClickReturnToDashboard = useCallback(() => {
    resetVidyoCallStatus();
    resetLeaveStatus();
    navigate(PATH_TLD);
  }, [navigate, resetLeaveStatus, resetVidyoCallStatus]);

  const handleOnClickSignIn = useCallback(() => {
    resetVidyoCallStatus();
    resetLeaveStatus();
    navigate(PATH_SIGNIN(callID), { state: { from: pathname } });
  }, [callID, navigate, pathname, resetLeaveStatus, resetVidyoCallStatus]);

  const handleOnClickSignOut = useCallback(() => {
    resetVidyoCallStatus();
    resetLeaveStatus();
    signOut();

    if (!callID) {
      navigate(PATH_TLD);
    }
  }, [callID, navigate, resetLeaveStatus, resetVidyoCallStatus, signOut]);

  return (
    <header className={styles.header}>
      <button onClick={handleOnClickReturnToDashboard}>
        <Flexbox alignItemsCenter justifyContentCenter>
          <AppLogo dimension={EDimension.EXTRA_SMALL} />
          <span className={styles.mark}>ClassView</span>
        </Flexbox>
      </button>
      <nav>
        <Flexbox as="ul" hasDoubleGap>
          <IconButton
            appearance={EAppearance.OUTLINE}
            as="button"
            description={theme === ETheme.LIGHT ? "Use dark theme" : "Use light theme"}
            dimension={EDimension.SMALL}
            icon={theme === ETheme.LIGHT ? EIcon.MOON : EIcon.SUN}
            onClick={handleChangeTheme}
            status={EStatus.NEUTRAL}
          />
          {isSignedIn && (
            <li>
              <PlainAction as="button" onClick={handleOnClickReturnToDashboard}>
                Dashboard
              </PlainAction>
            </li>
          )}
          {!isSignedIn && !pathname.includes(SEGMENT_REGISTER) && (
            <li>
              <PlainAction as="button" onClick={handleOnClickCreateAnAccount}>
                Create an account
              </PlainAction>
            </li>
          )}
          {!isSignedIn && !pathname.includes(SEGMENT_SIGNIN) && (
            <li>
              <PlainAction as="button" onClick={handleOnClickSignIn}>
                Sign in
              </PlainAction>
            </li>
          )}
          <li>
            <PlainAction as="a" href="https://knowledgebase.classview.com/" rel="noopener noreferrer" target="_blank">
              Help
            </PlainAction>
          </li>
          {isSignedIn && (
            <>
              <li>
                <PlainAction as="button" onClick={handleOnClickSignOut}>
                  Sign out
                </PlainAction>
              </li>
            </>
          )}
        </Flexbox>
      </nav>
    </header>
  );
}
