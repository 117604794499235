export enum EDeepLinkingAction {
  Call = "call",
  None = "none",
}

export const PROTOCOL_PREFIX: string = PREFIX_PROTOCOL;

export type TClassViewMapParams = Record<string, string>;

export interface IDeepLinkCallURL {
  action: EDeepLinkingAction.Call;
  params: {
    moderate?: boolean | string;
    portal?: string;
    roomkey?: string;
    token?: string;
  };
  protocol?: typeof PROTOCOL_PREFIX;
}

export interface IDeepLinkURL {
  action: EDeepLinkingAction.None;
  params: Record<never, never>;
  protocol: undefined;
}
export type TCallURL = IDeepLinkCallURL | IDeepLinkURL;

export type TCallURLHandler = (d: TCallURL) => void;

const possiblesValues: EDeepLinkingAction[] = [EDeepLinkingAction.Call];

function findAction(s: string): EDeepLinkingAction {
  const sActionPotential = s as EDeepLinkingAction;

  return possiblesValues.includes(sActionPotential) ? sActionPotential : EDeepLinkingAction.None;
}

function fromNativeUrl(s: string): [string, EDeepLinkingAction, TClassViewMapParams] {
  let protocol = PROTOCOL_PREFIX;
  let action = EDeepLinkingAction.None;

  const params: TClassViewMapParams = {};

  if (s && s !== "") {
    const [, p, path] = /(.*):\/\/(.*)/.exec(s);
    protocol = p;
    const [a, ...v] = path.split("/").filter(e => e);
    action = findAction(a);

    if (v.length % 2 === 0)
      v.forEach((e, i, arr) => {
        if (i % 2 === 0) params[e] = arr[i + 1];
      }, {});
  }
  return [protocol, action, params];
}

export function extractFromNativeURL(url: string): TCallURL {
  if (!url || url === "" || url === ".") return null;

  const [protocol, action, params] = fromNativeUrl(url);

  if (action === EDeepLinkingAction.None) return null;

  return {
    protocol,
    action,
    params,
  };
}

export function makeParams(link: TCallURL) {
  const recordParams = (link as IDeepLinkCallURL)?.params
    ? Object.entries(link?.params)
        .filter(param => !!param[1])
        .map(param => [param[0], param[1]?.toString()])
        .filter(param => param[1] !== "")
    : [];
  const paramsLength = recordParams.length;
  const path = paramsLength > 0 ? `${recordParams.flat().join("/")}/` : "";
  return path;
}

export function generateNativeURL(link: TCallURL): string {
  const pathParams = makeParams(link);
  const url = new window.URL(`${link.protocol ?? PROTOCOL_PREFIX}://${link.action}/${pathParams}`);
  return encodeURI(url.toString());
}
