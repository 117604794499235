export enum EAlignment {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
  CENTER = "center",
  TOP_LEFT = "top-left",
  TOP_CENTER = "top-center",
  TOP_RIGHT = "top-right",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_RIGHT = "bottom-right",
  UPPER = "upper",
  LOWER = "lower",
  NORTH = "north",
  NORTH_NORTH_EAST = "north-north-east",
  EAST_NORTH_EAST = "east-north-east",
  EAST = "east",
  EAST_SOUTH_EAST = "east-south-east",
  SOUTH_SOUTH_EAST = "south-south-east",
  SOUTH = "south",
  SOUTH_SOUTH_WEST = "south-south-west",
  WEST_SOUTH_WEST = "west-south-west",
  WEST = "west",
  WEST_NORTH_WEST = "west-north-west",
  NORTH_NORTH_WEST = "north-north-west",
}
