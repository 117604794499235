import { CSSProperties, useCallback, PointerEvent as RPointerEvent, useEffect, useMemo, useRef, useState } from "react";

import { Flexbox, Stepper } from "packages/catalog";
import { downloadDataURLasFile } from "packages/utils";

import { useWhiteboardContext } from "packages/client/incall/contexts";

import { CanvasPath } from "packages/client/incall/classes";

import { WhiteboardToolbar } from "packages/client/incall/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./Whiteboard.module.scss";

export enum EWhiteboardEntity {
  FreePath = "freepath",
}

const CANVAS = { HEIGHT: 500, WIDTH: 1000, GRID_CELL: 25, GRID_COLOR: "hsla(240, 1%, 60%, 1)" };
const OFFSET_RATIO = 10 ** 3;
const ZOOM = { MIN: 0.7, MAX: 2, STEP: 0.1 };

function useAwesomeCursorStyling(color: string) {
  return useMemo(() => {
    const mySVG = `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="${color}"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round">
        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"/>
      </svg>`;
    const s: CSSProperties = {
      cursor: `url("data:image/svg+xml;utf8,${encodeURIComponent(mySVG)}") 2 22, cell`,
    };
    return s;
  }, [color]);
}

function correctOffset(n: number) {
  return Math.round(n * OFFSET_RATIO) / OFFSET_RATIO;
}

function distance(ev1: RPointerEvent<HTMLDivElement>, ev2: RPointerEvent<HTMLDivElement>) {
  const h = (ev2.clientX - ev1.clientX) ** 2 + (ev2.clientY - ev1.clientY) ** 2;
  return Math.round(Math.sqrt(h));
}

function pointerCoor(evCache: RPointerEvent<HTMLDivElement>[]) {
  if (evCache.length === 1) return { x: evCache[0].clientX, y: evCache[0].clientY };

  const middleX = (evCache[0].clientX + evCache[1].clientX) / 2;
  const middleY = (evCache[0].clientY + evCache[1].clientY) / 2;
  return { x: middleX, y: middleY };
}

export function Whiteboard() {
  const { activeGrid, currentColour, draw, isPencilMode, paths } = useWhiteboardContext();
  const pencilCursor = useAwesomeCursorStyling(currentColour);

  const canvasRef = useRef<HTMLCanvasElement>();
  const contextRef = useRef<CanvasRenderingContext2D>(null);
  const currentPath = useRef<CanvasPath>(null);

  const [zoom, setZoom] = useState(1); // => to refactor
  const grab = useRef(false);

  const xTranslate = useRef(0); // => to refactor
  const yTranslate = useRef(0);

  const evCache = useRef<RPointerEvent<HTMLDivElement>[]>([]);

  const cursorStyle = useMemo(() => (isPencilMode ? pencilCursor : null), [isPencilMode, pencilCursor]);

  const objectPaths = useMemo(
    () => paths.map(p => new CanvasPath({ points: [...p.points], color: p.color, drawer: p.author })),
    [paths],
  );

  const initContextRendering = useCallback(() => {
    if (!canvasRef.current) return;
    if (contextRef.current) return;
    contextRef.current = canvasRef.current.getContext("2d", { alpha: false });
  }, []);

  const drawCanvas = useCallback(() => {
    initContextRendering();
    if (!contextRef.current) return;
    const context = contextRef.current;

    context.clearRect(0, 0, CANVAS.WIDTH, CANVAS.HEIGHT);
    context.fillStyle = activeGrid === "dot" ? CANVAS.GRID_COLOR : "white";
    context.fillRect(0, 0, CANVAS.WIDTH, CANVAS.HEIGHT);

    if (activeGrid === "square") {
      const lineWidth = 0.5;
      context.fillStyle = CANVAS.GRID_COLOR;
      for (let x = CANVAS.GRID_CELL; x < CANVAS.WIDTH; x += CANVAS.GRID_CELL) {
        context.fillRect(x - lineWidth / 2, 0 / 2, lineWidth, CANVAS.HEIGHT);
        context.fillRect(0, x - lineWidth / 2, CANVAS.WIDTH, lineWidth);
      }
    }
    if (activeGrid === "dot") {
      const dotRadius = 2;
      context.fillStyle = "white";
      for (let x = dotRadius; x < CANVAS.WIDTH; x += CANVAS.GRID_CELL) {
        context.fillRect(x - dotRadius / 2, 0 / 2, CANVAS.GRID_CELL - dotRadius, CANVAS.HEIGHT);
        context.fillRect(0, x - dotRadius / 2, CANVAS.WIDTH, CANVAS.GRID_CELL - dotRadius);
      }
    }

    context.lineWidth = 2;
    context.lineJoin = "round";
    context.lineCap = "round";
    objectPaths.forEach((path: CanvasPath) => {
      context.strokeStyle = path.color;
      context.stroke(path.getPath2D());
    });

    if (currentPath?.current) {
      context.strokeStyle = currentPath.current.color;
      context.stroke(currentPath.current.getPath2D());
    }
    refAnimationFrame1.current = null;
  }, [activeGrid, initContextRendering, objectPaths]);

  const transformCanvas = useCallback(() => {
    canvasRef.current.style.transform = `scale(${zoom}) translate(${xTranslate.current}px, ${yTranslate.current}px)`;
    refAnimationFrame2.current = null;
  }, [zoom]);

  const refAnimationFrame1 = useRef<number>(null);
  const scheduleDrawing = useCallback(() => {
    if (!refAnimationFrame1.current) refAnimationFrame1.current = requestAnimationFrame(drawCanvas);
  }, [drawCanvas]);

  const refAnimationFrame2 = useRef<number>(null);
  const scheduleTranformCanvas = useCallback(() => {
    if (!refAnimationFrame2.current) refAnimationFrame2.current = requestAnimationFrame(transformCanvas);
  }, [transformCanvas]);

  useEffect(() => {
    scheduleDrawing();
  }, [scheduleDrawing]);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(refAnimationFrame1.current);
      cancelAnimationFrame(refAnimationFrame2.current);
    };
  }, []);

  const getPos = useCallback((clientX: number, clientY: number) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const scale = CANVAS.WIDTH / rect.width;
    const canvasX = (clientX - rect.left) * scale;
    const canvasY = (clientY - rect.top) * scale;
    return { x: canvasX, y: canvasY };
  }, []);

  const beginPath = useCallback(
    (pos: { x: number; y: number }) => {
      if (currentPath.current) return;
      currentPath.current = new CanvasPath({ color: currentColour });
      currentPath.current.add(pos);
      scheduleDrawing();
    },
    [currentColour, scheduleDrawing],
  );

  const movePath = useCallback(
    (pos: { x: number; y: number }) => {
      if (!currentPath.current) return;
      currentPath.current.add(pos);
      scheduleDrawing();
    },
    [scheduleDrawing],
  );

  const endPath = useCallback(() => {
    if (currentPath?.current) {
      const optimizedPoints = currentPath.current.getOptimized();
      const entity = {
        author: currentPath.current.drawer,
        color: currentPath.current.color,
        points: optimizedPoints.points,
        type: EWhiteboardEntity.FreePath,
      };
      draw(entity);
    }
    currentPath.current = null;
    scheduleDrawing();
  }, [draw, scheduleDrawing]);

  const moveCanvas = useCallback(
    (events: RPointerEvent<HTMLDivElement>[]) => {
      const pointer1 = pointerCoor(evCache.current);
      const pointer2 = pointerCoor(events);

      xTranslate.current = Math.round(xTranslate.current + pointer2.x - pointer1.x);
      yTranslate.current = Math.round(yTranslate.current + pointer2.y - pointer1.y);
      scheduleTranformCanvas();
    },
    [scheduleTranformCanvas],
  );

  const pinchZoom = useCallback(
    (events: RPointerEvent<HTMLDivElement>[]) => {
      const prevDist = distance(evCache.current[0], evCache.current[1]);
      const newDist = distance(events[0], events[1]);

      let newZoom = zoom + newDist / prevDist - 1;
      newZoom = Math.max(ZOOM.MIN, Math.min(ZOOM.MAX, newZoom));

      setZoom(newZoom);
    },
    [zoom],
  );

  const save = useCallback(() => {
    const dataURL = canvasRef.current.toDataURL("image/jpeg");
    downloadDataURLasFile(dataURL, `whiteboard-${Date.now()}.jpg`);
  }, []);

  const handlePointerDown = useCallback(
    (e: RPointerEvent<HTMLDivElement>) => {
      evCache.current = [...evCache.current, e];
      scheduleDrawing();
      if (isPencilMode && evCache.current.length < 2) beginPath(getPos(e.clientX, e.clientY));
      else if (e.pointerType !== "touch") grab.current = true;
      else if (evCache.current.length > 1) grab.current = true;
    },
    [scheduleDrawing, isPencilMode, beginPath, getPos],
  );

  const handlePointerMove = useCallback(
    (e: RPointerEvent<HTMLDivElement>) => {
      const secondaryEv = evCache.current.find(ev => ev.pointerId !== e.pointerId);

      if (isPencilMode && !grab.current) movePath(getPos(e.clientX, e.clientY));
      if (grab.current && evCache.current.length < 3) moveCanvas([e, secondaryEv].filter(ev => ev));
      if (evCache.current.length === 2) pinchZoom([e, secondaryEv].filter(ev => ev));

      evCache.current = evCache.current.map(ev => (ev.pointerId === e.pointerId ? e : ev));
    },
    [isPencilMode, movePath, getPos, moveCanvas, pinchZoom],
  );

  const endAction = useCallback(
    (e: RPointerEvent<HTMLDivElement>) => {
      // remove event from event cache
      evCache.current = evCache.current.filter(ev => ev.pointerId !== e.pointerId);

      if (isPencilMode && !grab.current) endPath();
      grab.current = false;
    },
    [endPath, isPencilMode],
  );

  const handleZoomChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(parseFloat(event.currentTarget.value));
  }, []);

  const handleZoom = useCallback(
    (sign: number) => {
      const newZoom = correctOffset(zoom + sign * ZOOM.STEP);
      if (newZoom < ZOOM.MIN || newZoom > ZOOM.MAX) return;
      setZoom(newZoom);
    },
    [zoom],
  );

  const handleWheelZoom = useCallback(
    (e: WheelEvent) => {
      e.preventDefault();
      handleZoom(Math.sign(-e.deltaY));
    },
    [handleZoom],
  );

  useEffect(() => {
    const copyCanvas = canvasRef.current;
    copyCanvas.addEventListener("wheel", handleWheelZoom);
    return () => copyCanvas?.removeEventListener("wheel", handleWheelZoom);
  }, [handleWheelZoom]);

  useEffect(() => {
    scheduleTranformCanvas();
  }, [scheduleTranformCanvas, zoom]);

  return (
    <div className={styles.whiteboard}>
      <RoutingChangeHandler message="The whiteboard has loaded." />
      <div
        className={`${styles.container} ${isPencilMode ? styles.draw : grab.current ? styles.moveTouch : styles.move}`}
        style={{ ...cursorStyle }}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={endAction}
        onPointerLeave={endAction}>
        <canvas ref={canvasRef} className={styles.canvas} width={CANVAS.WIDTH} height={CANVAS.HEIGHT} />
      </div>
      <WhiteboardToolbar download={save} />
      <Flexbox className={styles.zoom}>
        <div className={styles.box}>
          <Stepper
            max={ZOOM.MAX}
            min={ZOOM.MIN}
            name="zoom"
            onChange={event => handleZoomChange(event)}
            onDecrement={() => handleZoom(-1)}
            onIncrement={() => handleZoom(1)}
            step={ZOOM.STEP}
            value={zoom}
          />
        </div>
        <div className={styles.box}>
          <output className={styles.percent}>{Math.round(zoom * 100)}%</output>
        </div>
      </Flexbox>
    </div>
  );
}
