export enum EDjangoRoomSystemPrivacy {
  Organisation = "organisation",
  Public = "public",
}

export enum EDjangoRoomSystemType {
  H323 = "h323",
  Sip = "sip",
  Vsceneroom = "vsceneroom",
}

export interface IDjangoRoomSystem {
  id: number;
  ip: string;
  name: string;
  notification_email: string;
  organisation?: number;
  privacy: EDjangoRoomSystemPrivacy;
  type: EDjangoRoomSystemType;
}

export interface IDjangoRoomSystemUsage {
  current: number;
  limit: number;
  total: number;
}
