import { cloneElement, KeyboardEvent, MouseEvent, ReactElement, useCallback, useMemo, useState } from "react";

import type { EAttachment } from "packages/utils";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Tooltip.module.scss";

export interface PTooltip {
  attachment: EAttachment;
  isActive?: boolean;
  targetName: string;
  tip: string;
  trigger: ReactElement;
}

export function Tooltip({ attachment, isActive, targetName, tip, trigger }: PTooltip) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleKeyboardEvent = useCallback(
    (event: KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === "Escape") handleClose();
    },
    [handleClose],
  );

  const triggerAttributes = useMemo(() => {
    return {
      ["aria-describedby"]: `${targetName} tooltip`,
      ["onBlur"]: handleClose,
      ["onFocus"]: handleOpen,
      ["onTouchStart"]: handleOpen,
      ["onTouchEnd"]: handleClose,
      ["onKeyDown"]: (e: KeyboardEvent<HTMLButtonElement>) => handleKeyboardEvent(e),
    };
  }, [handleClose, handleKeyboardEvent, handleOpen, targetName]);

  const triggerComponent = useMemo(() => {
    return cloneElement(trigger, triggerAttributes);
  }, [trigger, triggerAttributes]);

  return (
    <div
      className={styles.tooltip_container}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      onClick={(e: MouseEvent<HTMLDivElement>) => e.preventDefault()}>
      {triggerComponent}
      <p
        aria-hidden={isActive ?? !isOpen}
        className={`${styles.tooltip_info} ${styles[attachment]}`}
        id={`${targetName} tooltip`}
        onClick={e => e.preventDefault()}
        role="tooltip">
        {tip}
      </p>
    </div>
  );
}
