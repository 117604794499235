export const API_DOMAIN = window.location.hostname === "app-proxy.classview.com" ? "api-proxy.classview.com" : DOMAIN;

export const HEADER_JSON = { "Content-Type": "application/json" };

export const NODE_API_PATH = "/api/node/v1";
export const S3_API_PATH = "/api/s3/v1";
export const VIDYO_API_PATH = "/api/vidyo/v1";
export const VSCENE_API_PATH = "/api/vscene/v1";
export const WS_API_PATH = "/api/ws/v2";

export const BUCKET_ORGANISATION_LOGOS = "vscene-organisation-logos";
export const BUCKET_PROFILE_PICTURES = "vscene-profile-pictures";
