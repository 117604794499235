import { useMemo } from "react";

import { Avatar } from "packages/catalog";
import type { EDimension } from "packages/utils";

import { generateProfilePictureURL } from "packages/client/users/s3";

export interface PUserAvatar {
  dimension: EDimension;
  email: string;
  fullName: string;
  profilePictureURL?: string;
}

export function UserAvatar({ dimension, email, fullName, profilePictureURL }: PUserAvatar) {
  const imageURL = useMemo(() => generateProfilePictureURL(profilePictureURL), [profilePictureURL]);

  return (
    <Avatar dimension={dimension} email={email} fullName={fullName} imageURL={profilePictureURL ? imageURL : null} />
  );
}
