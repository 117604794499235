import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { VidyoReduxProvider } from "packages/call";
import { Loader } from "packages/catalog";

import { instanceClientRootStore } from "packages/client/redux";
import { runRememberMe } from "packages/client/authentication/functions";

import App from "packages/client/App";
import { ErrorBoundary } from "packages/client/errors/components";
import { ScrollToTop } from "packages/client/layout/components";

const Router = IS_ELECTRON ? MemoryRouter : BrowserRouter;

runRememberMe();

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Provider store={instanceClientRootStore}>
          <VidyoReduxProvider>
            <Router>
              <ScrollToTop />
              <App />
            </Router>
          </VidyoReduxProvider>
        </Provider>
      </Suspense>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById("root"),
);
