import { ReactNode, useMemo } from "react";

import { EStatus, useStatusStyle } from "packages/utils";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Feedback.module.scss";

export interface PFeedback {
  center?: boolean;
  children: ReactNode;
  status: EStatus;
}

export function Feedback({ center, children, status }: PFeedback) {
  const statusStyle = useStatusStyle(styles);
  const classes = useMemo(
    () => [styles.feedback, statusStyle[status], center && styles.center].filter(e => e).join(" "),
    [center, status, statusStyle],
  );

  return (
    <div className={classes} role="alert">
      <p>{children}</p>
    </div>
  );
}
