import { useState, useEffect, useCallback, ChangeEvent } from "react";

import { Cell, Field, Fieldset, Grid, Input, InputOption } from "packages/catalog";
import { formatISOShortDate, getMidnight, MAXIMUM_DATETIME } from "packages/utils";

import { ERecurringEnd } from "packages/client/sessions/redux/types";

import { sanitiseRepetitions } from "packages/client/sessions/functions";

import {
  changeRecurringEndDate,
  changeRecurringEndType,
  changeRecurringRepetitions,
} from "packages/client/sessions/redux/slice";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

export interface PEditRecurringEnd {
  isEditMode: boolean;
}

export function EditRecurringEnd({ isEditMode }: PEditRecurringEnd) {
  const dispatch = useTypedDispatch();
  const {
    recurringEndDate,
    recurringEveryXMonths,
    recurringEveryXWeeks,
    recurringInterval,
    recurringRepetitions,
    startTime,
  } = useTypedSelector(({ sessionForm }) => ({
    recurringEndDate: sessionForm.recurringEndDate,
    recurringEveryXMonths: sessionForm.recurringEveryXMonths,
    recurringEveryXWeeks: sessionForm.recurringEveryXWeeks,
    recurringInterval: sessionForm.recurringInterval,
    recurringRepetitions: sessionForm.recurringRepetitions,
    startTime: sessionForm.startTime,
  }));

  // save recurring end date the first time from an existing meeting
  const [originalRecurringEndDate] = useState(recurringEndDate);

  const handleRepetitionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      dispatch(changeRecurringRepetitions(sanitiseRepetitions(e.currentTarget.value))),
    [dispatch],
  );

  const handleEndDateChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(changeRecurringEndDate(`${new Date(e.currentTarget.value).toDateString()} `));
    },
    [dispatch],
  );

  useEffect(() => {
    // stop the recurring end date from being updated if there was an original end date coming from the back end (edit mode)
    if (originalRecurringEndDate) return;

    if (recurringInterval && !recurringRepetitions) {
      const startDate = getMidnight(new Date(startTime));

      let endOnDate;

      if (recurringInterval === "daily") {
        endOnDate = new Date(startDate.setDate(startDate.getDate() + 1));
      } else if (recurringInterval === "weekly") {
        const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
        endOnDate = new Date(startDate.valueOf() + weekInMilliseconds * recurringEveryXWeeks);
      } else if (recurringInterval === "monthly") {
        endOnDate = new Date(startDate.setMonth(startDate.getMonth() + recurringEveryXMonths));
      }

      dispatch(changeRecurringEndDate(`${endOnDate.toDateString()}`));
    }
  }, [
    dispatch,
    isEditMode,
    originalRecurringEndDate,
    recurringInterval,
    recurringRepetitions,
    startTime,
    recurringEveryXMonths,
    recurringEveryXWeeks,
  ]);

  return (
    <Cell>
      <Grid>
        <Cell colSpan={5}>
          <Fieldset label="Ends">
            <InputOption label="On a particular date">
              <Input
                checked={recurringEndDate !== null || isNaN(recurringRepetitions)}
                name="ends"
                onChange={() => dispatch(changeRecurringEndType(ERecurringEnd.On))}
                type="radio"
              />
            </InputOption>
            <InputOption label="After a number of repetitions">
              <Input
                checked={recurringRepetitions !== null}
                name="ends"
                onChange={() => dispatch(changeRecurringEndType(ERecurringEnd.After))}
                type="radio"
              />
            </InputOption>
          </Fieldset>
        </Cell>
        <Cell colSpan={3}>
          {recurringEndDate !== null && (
            <Field label="End date">
              <Input
                disabled={!recurringEndDate}
                max={formatISOShortDate(MAXIMUM_DATETIME)}
                min={formatISOShortDate(new Date(startTime))}
                name="end-date"
                onChange={handleEndDateChange}
                required
                type="date"
                value={formatISOShortDate(new Date(recurringEndDate))}
              />
            </Field>
          )}
          {recurringRepetitions !== null && (
            <Field label="Repetitions">
              <Input
                disabled={recurringRepetitions === null}
                max={500}
                min={1}
                name="repetitions"
                onChange={handleRepetitionChange}
                required
                showNumberControls
                type="number"
                value={`${recurringRepetitions}`}
              />
            </Field>
          )}
        </Cell>
      </Grid>
    </Cell>
  );
}
