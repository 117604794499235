import { forwardRef, InputHTMLAttributes, MouseEvent, MutableRefObject, useCallback, useMemo, useState } from "react";

import { IconButton } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

import styles from "./Input.module.scss";

export interface PInput extends InputHTMLAttributes<HTMLInputElement> {
  datalistOptions?: string[];
  showNumberControls?: boolean;
}

export const Input = forwardRef((props: PInput, ref: MutableRefObject<HTMLInputElement>) => {
  const { datalistOptions, showNumberControls, ...rest } = props;
  const { disabled, list, required, type } = props;

  const [showPassword, setShowPassword] = useState(false);

  const inputClasses = useMemo(
    () => [styles.input, showNumberControls && styles.showNumberControls].filter(e => e).join(" "),
    [showNumberControls],
  );

  const passwordContainerClasses = useMemo(
    () => [styles.password, disabled && styles.disabled].filter(e => e).join(" "),
    [disabled],
  );

  const searchContainerClasses = useMemo(
    () => [styles.search, disabled && styles.disabled].filter(e => e).join(" "),
    [disabled],
  );

  const handlePasswordToggle = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setShowPassword(!showPassword);
    },
    [showPassword],
  );

  const input = useMemo(
    () => (
      <>
        <input aria-required={required} className={inputClasses} ref={ref} {...rest} />
        {list && (
          <datalist id={list}>
            {datalistOptions.map((option, i) => (
              <option key={i} value={option} />
            ))}
          </datalist>
        )}
      </>
    ),
    [datalistOptions, inputClasses, list, ref, required, rest],
  );

  if (type === "search") {
    return <div className={searchContainerClasses}>{input}</div>;
  } else if (type === "password") {
    return (
      <div className={passwordContainerClasses}>
        <input
          aria-required={required}
          className={inputClasses}
          ref={ref}
          {...rest}
          type={showPassword ? "text" : "password"}
        />
        <div className={styles.icon}>
          <IconButton
            appearance={EAppearance.GHOST}
            as="button"
            disabled={disabled}
            description={showPassword ? "Hide password" : "Show password"}
            dimension={EDimension.SMALL}
            status={EStatus.NEUTRAL}
            icon={showPassword ? EIcon.EYEOFF : EIcon.EYE}
            onClick={handlePasswordToggle}
            type="button"
          />
        </div>
      </div>
    );
  } else return input;
});
