import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import {
  Block,
  Cell,
  Field,
  Fieldset,
  Flexbox,
  Grid,
  H1,
  H2,
  IconButton,
  Input,
  InputOption,
  Loader,
} from "packages/catalog";
import {
  EAppearance,
  EDimension,
  flattenString,
  formatISOShortDate,
  EIcon,
  EStatus,
  SEGMENT_SUPERADMIN,
  TParams,
} from "packages/utils";

import { fetchDjangoAPIAuthenticated } from "packages/client/fetch";
import { useGetEssentialOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";
import { useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { RequireAuthorisation } from "packages/client/authentication/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

export enum EReport {
  Org = "org",
  SuperOrg = "super_org",
}

export enum EOptions {
  calls = "total-calls",
  concurrencies = "concurrency",
  connections = "connection-attempts",
  meetings = "meetings",
  users = "users",
  hubs = "hubs",
}

export function GenerateReport() {
  const { organisationID }: TParams = useParams();

  const [reportType, setReportType] = useState(EReport.Org);
  const [includeChildren, setIncludeChildren] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [calls, setCalls] = useState(false);
  const [concurrencies, setConcurencies] = useState(false);
  const [connections, setConnections] = useState(false);
  const [meetings, setMeetings] = useState(false);

  const [users, setUsers] = useState(false);
  const [hubs, setHubs] = useState(false);

  const location = useLocation();

  const { data: organisationData, loading: organisationDataLoading } = useGetEssentialOrganisationQuery({
    variables: {
      organisationID: parseInt(organisationID),
    },
  });
  const { data: userOwnData } = useGetEssentialOwnUserQuery();

  const isOwnOrganisation = useMemo(
    () => organisationID === userOwnData?.user?.organisation?.id,
    [organisationID, userOwnData?.user?.organisation?.id],
  );

  const isOwnChildOrganisation = useMemo(
    () =>
      userOwnData?.user?.organisation?.organisationSet?.some(
        childOrganisation => childOrganisation.id === organisationID,
      ),
    [organisationID, userOwnData],
  );

  const handleStartDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.currentTarget.value);
    setStartDate(newDate);
  }, []);

  const handleEndDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.currentTarget.value);
    setEndDate(newDate);
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const start = startDate.toISOString().split("T")[0];
      const end = endDate.toISOString().split("T")[0];

      const params = `start=${start}&end=${end}&${reportType}=${organisationID}&detailed_child_orgs=${includeChildren.toString()}`;

      const options: Record<EOptions, boolean> = {
        "total-calls": calls,
        concurrency: concurrencies,
        "connection-attempts": connections,
        meetings,
        users,
        hubs,
      };

      const optionsFiltered = Object.keys(options).filter((k: EOptions) => options[k]);

      const resp = await Promise.all(
        optionsFiltered.map(option => fetchDjangoAPIAuthenticated(`/reports/${option}?${params}`)),
      );
      const data = await Promise.all(resp.map(a => a.json()));

      const XLSX = await import("xlsx");
      const wb = XLSX.utils.book_new();

      data.forEach((d, index) => {
        const ws = XLSX.utils.json_to_sheet(d.flat());
        XLSX.utils.book_append_sheet(wb, ws, optionsFiltered[index]);
      });

      XLSX.writeFile(wb, `report_${flattenString(organisationData?.organisation?.name)}_${start}_${end}.xlsx`);
    },
    [
      calls,
      concurrencies,
      connections,
      endDate,
      hubs,
      includeChildren,
      meetings,
      organisationData,
      organisationID,
      reportType,
      startDate,
      users,
    ],
  );

  if (!organisationData) return null;
  if (!userOwnData) return null;
  if (organisationDataLoading) return <Loader />;

  return (
    <RequireAuthorisation
      conditions={[
        userOwnData?.user?.isAdmin,
        isOwnOrganisation || isOwnChildOrganisation || userOwnData?.user?.isSuperuser,
      ]}>
      <RoutingChangeHandler message="The generate report page has loaded." pageTitle="Generate report" />
      <Grid as="form" onSubmit={handleSubmit}>
        <Cell>
          <IconButton
            appearance={EAppearance.GHOST}
            as={Link}
            description="Organisation"
            dimension={EDimension.SMALL}
            icon={EIcon.ARROWLEFT}
            label="Organisation"
            status={EStatus.NEUTRAL}
            to={location.pathname.includes(SEGMENT_SUPERADMIN) ? "./.." : ".."}
          />
        </Cell>
        <Cell>
          <H1>Generate report</H1>
        </Cell>
        <Cell as={Block}>
          <Grid>
            <Cell>
              <H2>{organisationData?.organisation?.name}</H2>
            </Cell>
            {organisationData?.organisation?.organisationSet.length ? (
              <Cell>
                <Fieldset label="Report type">
                  <InputOption
                    description={`Only ${organisationData?.organisation?.name}'s data`}
                    label={`Summarised report - ${organisationData?.organisation?.name}`}>
                    <Input
                      checked={reportType === EReport.Org}
                      name="type"
                      onChange={() => {
                        setReportType(EReport.Org);
                        setIncludeChildren(false);
                      }}
                      type="radio"
                    />
                  </InputOption>
                  <InputOption
                    description={`${organisationData?.organisation?.name} and its child organisation data together`}
                    label={`Summarised report - ${organisationData?.organisation?.name} and all child organisations`}>
                    <Input
                      checked={reportType === EReport.SuperOrg && !includeChildren}
                      name="type"
                      onChange={() => {
                        setReportType(EReport.SuperOrg);
                        setIncludeChildren(false);
                      }}
                      type="radio"
                    />
                  </InputOption>
                  <InputOption
                    description={`${organisationData?.organisation?.name} and its child organisation data together`}
                    label={`Detailed report - ${organisationData?.organisation?.name} and all child organisations`}>
                    <Input
                      checked={reportType === EReport.SuperOrg && includeChildren}
                      name="type"
                      onChange={() => {
                        setReportType(EReport.SuperOrg);
                        setIncludeChildren(true);
                      }}
                      type="radio"
                    />
                  </InputOption>
                </Fieldset>
              </Cell>
            ) : null}
            <Cell>
              <Flexbox>
                <Field label="Start date">
                  <Input
                    max={formatISOShortDate(endDate)}
                    name="start"
                    onChange={handleStartDateChange}
                    type="date"
                    value={formatISOShortDate(startDate)}
                  />
                </Field>
                <Field label="End date">
                  <Input
                    max={formatISOShortDate(new Date())}
                    min={formatISOShortDate(startDate)}
                    name="end"
                    onChange={handleEndDateChange}
                    type="date"
                    value={formatISOShortDate(endDate)}
                  />
                </Field>
              </Flexbox>
            </Cell>
            <Cell>
              <Fieldset description="This data is specific to the dates you select above" label="Date-specific data">
                <InputOption label="Total number of sessions per day">
                  <Input checked={calls} name="sessions-total" onChange={() => setCalls(!calls)} type="checkbox" />
                </InputOption>
                <InputOption label="Total number of participants per day">
                  <Input
                    checked={connections}
                    name="participants"
                    onChange={() => setConnections(!connections)}
                    type="checkbox"
                  />
                </InputOption>
                <InputOption label="Concurrent lines per day">
                  <Input
                    checked={concurrencies}
                    name="lines"
                    onChange={() => setConcurencies(!concurrencies)}
                    type="checkbox"
                  />
                </InputOption>
                <InputOption label="List of scheduled sessions">
                  <Input
                    checked={meetings}
                    name="sessions-list"
                    onChange={() => setMeetings(!meetings)}
                    type="checkbox"
                  />
                </InputOption>
              </Fieldset>
            </Cell>
            <Cell>
              <Fieldset description="This data is independent of the date" label="All data">
                <InputOption label="All users">
                  <Input checked={users} name="users" onChange={() => setUsers(!users)} type="checkbox" />
                </InputOption>
                <InputOption label="All Hubs">
                  <Input checked={hubs} name="hubs" onChange={() => setHubs(!hubs)} type="checkbox" />
                </InputOption>
              </Fieldset>
            </Cell>
          </Grid>
        </Cell>
        <Cell center>
          <Input
            disabled={!calls && !connections && !concurrencies && !meetings && !users && !hubs}
            type="submit"
            value="Generate report"
          />
        </Cell>
      </Grid>
    </RequireAuthorisation>
  );
}
