import { isValidElement, ReactNode, useMemo } from "react";

import { Description, Flexbox, IAutocompleteDropdownOption, Icon, P, Strong } from "packages/catalog";
import type { EIcon } from "packages/utils";

import styles from "./DropdownListItem.module.scss";

export interface PDropdownListItem {
  focused: boolean;
  onSelect: (option: IAutocompleteDropdownOption) => void;
  option: IAutocompleteDropdownOption;
  searchInput: string;
}

function escapeRegExp(textString: string) {
  return textString.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, "\\$&");
}

function splitStringOnSearchMatch(fullString: string, search: string) {
  const strRegExp = new RegExp(escapeRegExp(search), "i");
  const indexOfMatch = fullString.search(strRegExp);
  if (indexOfMatch === -1) return { preMatchStr: fullString };
  const preMatchStr = fullString.substring(0, indexOfMatch);
  const postMatchStr = fullString.substring(indexOfMatch + search.length, fullString.length);
  const matchingStr = strRegExp.exec(fullString);
  return { preMatchStr, matchingStr: matchingStr[0], postMatchStr };
}

export function DropdownListItem({ focused, onSelect, option, searchInput }: PDropdownListItem) {
  const styledOption: ReactNode = useMemo(() => {
    const splitString = splitStringOnSearchMatch(option.name, searchInput);
    return (
      <span>
        {splitString.preMatchStr}
        <Strong>{splitString.matchingStr}</Strong>
        {splitString.postMatchStr}
      </span>
    );
  }, [option.name, searchInput]);

  const isAvatar = useMemo(() => {
    if (!option.image) return;
    return isValidElement(option.image);
  }, [option.image]);

  return (
    <Flexbox
      aria-label={option.name}
      aria-selected={focused}
      as="li"
      className={styles.dropdownOption}
      data-option-value={option.id}
      key={option.id}
      onClick={() => onSelect(option)}
      role="option"
      tabIndex={-1}>
      {option.image &&
        (!isAvatar ? <Icon className={styles.icon} icon={option.image as EIcon} focusable={false} /> : option.image)}
      <span>
        {styledOption}
        {option.description && (
          <P>
            <Description>{option.description}</Description>
          </P>
        )}
      </span>
    </Flexbox>
  );
}
