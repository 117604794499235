import { LOCAL_JWT_TOKEN } from "packages/utils";

import { instanceClientRootStore } from "packages/client/redux";

import { getRememberMeFromLocalStorage } from "packages/client/authentication/functions/getRememberMeFromLocalStorage";
import { refreshToken, verifyToken } from "packages/client/authentication/django/requests";
import { setAuthenticatedToken } from "packages/client/authentication/redux/slice";

export async function runRememberMe() {
  try {
    if (getRememberMeFromLocalStorage()) {
      const storedToken = localStorage.getItem(LOCAL_JWT_TOKEN);

      if (storedToken !== null) {
        const isTokenValid = await verifyToken(storedToken);

        if (isTokenValid) {
          // set into redux store
          instanceClientRootStore.dispatch(setAuthenticatedToken(storedToken));
        } else {
          try {
            // let's get a new token
            const { token } = await refreshToken(storedToken);

            // put it into local storage
            localStorage.setItem(LOCAL_JWT_TOKEN, token);

            // set into redux store
            instanceClientRootStore.dispatch(setAuthenticatedToken(token));
          } catch (e) {
            localStorage.removeItem(LOCAL_JWT_TOKEN);
          }
        }
      }
    } else {
      localStorage.removeItem(LOCAL_JWT_TOKEN);
    }
  } catch (e) {
    localStorage.removeItem(LOCAL_JWT_TOKEN);
  }
}
