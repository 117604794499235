import { ReactNode, useMemo } from "react";
import { Link, LinkProps } from "react-router-dom";

import { Illustration, TextButton } from "packages/catalog";
import { EAppearance, EIllustration, ILocationState, EStatus } from "packages/utils";

import styles from "./IllustratedCTA.module.scss";

export interface PIllustratedCTA extends Omit<LinkProps, "to"> {
  children: ReactNode;
  cta?: string;
  illustration: EIllustration;
  noPadding?: boolean;
  state?: ILocationState;
  to?: string | LinkProps["to"];
}

export function IllustratedCTA({ children, cta, illustration, noPadding, state, to }: PIllustratedCTA) {
  const illustratedCTAClasses = useMemo(
    () => [styles.illustratedCTA, !noPadding && styles.padding].filter(e => e).join(" "),
    [noPadding],
  );

  return (
    <section className={illustratedCTAClasses}>
      <Illustration className={styles.illustration} illustration={illustration} />
      <div className={styles.content}>{children}</div>
      {cta && (
        <TextButton as={Link} to={to} appearance={EAppearance.FILL} status={EStatus.ACCENT} state={state}>
          {cta}
        </TextButton>
      )}
    </section>
  );
}
