import { useCallback, useMemo, useState } from "react";

import {
  AutocompleteDropdown,
  Cell,
  Dialog,
  Field,
  Flexbox,
  Grid,
  IAutocompleteDropdownOption,
  IconButton,
  InternalTextLink,
  LabelledContent,
} from "packages/catalog";
import {
  EAppearance,
  EDimension,
  HEADER_JSON,
  EIcon,
  PATH_SUPERADMIN_USER,
  EStatus,
  toSentenceCase,
} from "packages/utils";

import { fetchDjangoAPIAuthenticated } from "packages/client/fetch";

import { useGetAllUsersQuery } from "packages/client/users/graphql/users.generated";

export interface PSuperAdminParticipants {
  collection: "users" | "moderators" | "lecturers" | "members";
  endpoint: string;
  onAdded: () => void;
  participants: {
    firstName: string;
    id: string;
    lastName: string;
  }[];
}

export function SuperAdminParticipants({ endpoint, collection, participants, onAdded }: PSuperAdminParticipants) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { data: allUsersData } = useGetAllUsersQuery();

  const options = useMemo(
    () =>
      allUsersData?.allUsers?.objects
        ?.filter(u => !participants.find(pu => pu.id === u.id))
        .map(u => ({
          id: u.id,
          name: `${u.id} - ${u.firstName} ${u.lastName}`,
          description: u.organisation ? u.organisation.name : "No organisation",
        })),
    [allUsersData, participants],
  );

  const updateParticipants = useCallback(
    async (userId: string, type: "add" | "remove") => {
      const res = await fetchDjangoAPIAuthenticated(endpoint, {
        method: "PATCH",
        headers: HEADER_JSON,
        body: JSON.stringify({
          [collection]:
            type === "add"
              ? [...participants.map(user => parseInt(user.id, 10)), parseInt(userId, 10)]
              : type === "remove" && [...participants.filter(user => user.id !== userId).map(u => parseInt(u.id, 10))],
        }),
      });
      if (res.status === 200) {
        onAdded();
        setSearchValue("");
      }
    },
    [collection, endpoint, onAdded, participants],
  );

  const handleAddUser = useCallback(
    (user: IAutocompleteDropdownOption) => updateParticipants(user.id, "add"),
    [updateParticipants],
  );

  const handleRemoveUser = useCallback((id: string) => updateParticipants(id, "remove"), [updateParticipants]);

  const handleToggleModal = useCallback(() => setIsModalOpen(!isModalOpen), [isModalOpen]);

  return (
    <>
      <Flexbox flexDirectionColumn>
        <LabelledContent
          content={
            participants.length ? (
              <ul>
                {participants.map(participant => (
                  <li key={participant.id}>
                    <Flexbox alignItemsCenter>
                      <InternalTextLink to={PATH_SUPERADMIN_USER(participant.id)}>
                        {participant.firstName} {participant.lastName}
                      </InternalTextLink>
                      <IconButton
                        appearance={EAppearance.GHOST}
                        as="button"
                        description="Remove user"
                        dimension={EDimension.SMALL}
                        icon={EIcon.X}
                        onClick={() => handleRemoveUser(participant.id)}
                        status={EStatus.DANGER}
                      />
                    </Flexbox>
                  </li>
                ))}
              </ul>
            ) : (
              "None"
            )
          }
          label={toSentenceCase(collection)}
        />
        <IconButton
          appearance={EAppearance.OUTLINE}
          as="button"
          description={`Add ${collection.toLowerCase()}`}
          dimension={EDimension.SMALL}
          icon={EIcon.PLUS}
          label={`Add ${collection.toLowerCase()}`}
          onClick={handleToggleModal}
          status={EStatus.NEUTRAL}
        />
      </Flexbox>
      <Dialog h1={<>Add {collection.toLowerCase()}</>} isOpen={isModalOpen} onAbort={handleToggleModal}>
        <Grid noColGap>
          <Cell>
            <Field label={toSentenceCase(collection)}>
              <AutocompleteDropdown
                name="user"
                onChange={e => setSearchValue(e.target.value)}
                onOptionSelect={handleAddUser}
                options={options}
                optionsCollectiveName="Users"
                showNoResultsMessage
                value={searchValue}
              />
            </Field>
          </Cell>
        </Grid>
      </Dialog>
    </>
  );
}
