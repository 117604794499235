import { useEffect, useState } from "react";
import { getFeatureFlag } from "../queries/featureFlags";

const featureFlags: Record<string, boolean> = {};

export function useFeatureFlag(flag: string, token = ""): boolean {
  const [isFlagEnabled, setIsFlagEnabled] = useState<boolean>(false);
  useEffect(() => {
    let cancelled = false;
    const fetchFlagOn = async () => {
      try {
        const result = await getFeatureFlag(flag);
        if (!cancelled) {
          const flagEnabled = result.enabled ?? false;
          setIsFlagEnabled(flagEnabled);
          featureFlags[flag] = flagEnabled;
        }
      } catch (error) {
        console.error(error);
      }
      return () => {
        cancelled = true;
      };
    };
    fetchFlagOn();
    return () => {
      cancelled = true;
    };
  }, [flag, token]);
  return featureFlags[flag] ?? isFlagEnabled;
}
