export enum EIllustration {
  CHRISTMAS = "christmas",
  CELEBRATION = "celebration",
  CONFERENCE_CALL = "conference-call",
  EXPLORE = "explore",
  GROUP_VIDEO = "group-video",
  ONLINE_LEARNING = "online-learning",
  PAGE_NOT_FOUND = "page-not-found",
  PEOPLE_COLLABORATING = "people-collaborating",
  PERSONAL_INFORMATION = "personal-information",
  SANTA = "santa",
  SCREEN_INCALL_WARNING = "screen-incall-warning",
  SUNNY_DAY = "sunny-day",
  WOMAN_CHECKLIST = "woman-checklist",
  WOMAN_HEARTS = "woman-hearts",
  WOMAN_SIGN = "woman-sign",
  VIDEO_FILES = "video-files",
  VIRTUAL_CLASSROOM = "virtual-classroom",
}
