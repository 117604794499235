import { ChangeEvent, useCallback, useEffect, useState } from "react";

import {
  Cell,
  Feedback,
  Grid,
  IllustratedCTA,
  Input,
  Label,
  Loader,
  Pagination,
  Table,
  TRTDTemp,
} from "packages/catalog";
import { EIllustration, EStatus } from "packages/utils";

import { useGetAllOrganisationsQuery } from "packages/client/organisations/graphql/organisations.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SuperAdminOrganisationRow } from "packages/client/organisations/components/SuperAdminOrganisationRow/SuperAdminOrganisationRow";

const COLUMNS = ["ID", "Organisation", "Parent organisation"];
const COLUMN_WIDTHS = "4rem 1fr 1fr 2rem";

export function SuperAdminOrganisations() {
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchString, setDebounceSearchString] = useState("");
  const [searchString, setSearchString] = useState("");

  const {
    data: allOrganisationsData,
    loading,
    error,
  } = useGetAllOrganisationsQuery({
    variables: { page: currentPage, search: debouncedSearchString },
  });

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value);
  }, []);

  useEffect(() => {
    let cancelled = false;

    window.setTimeout(() => {
      if (!cancelled) setDebounceSearchString(searchString);
    }, 500);

    return () => {
      cancelled = true;
    };
  }, [searchString]);

  return (
    <>
      <RoutingChangeHandler message="The organisations page has loaded." pageTitle="Organisations" />
      <Grid noColGap>
        <Cell>
          <Label htmlFor="search" isVisuallyHidden text="Search organisations" />
          <Input id="search" name="search" onChange={handleChangeSearch} type="search" value={searchString} />
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing all organisations on ClassView"
            hasMenuColumn>
            <>
              {loading && (
                <TRTDTemp>
                  <Loader notFullHeight />
                </TRTDTemp>
              )}
              {error && (
                <TRTDTemp>
                  <Feedback status={EStatus.DANGER}>Unable to get list of organisations.</Feedback>
                </TRTDTemp>
              )}
              <>
                {allOrganisationsData?.allApprovedOrganisations?.objects && (
                  <>
                    {allOrganisationsData.allApprovedOrganisations.objects.map(organisation => (
                      <SuperAdminOrganisationRow
                        columns={COLUMNS}
                        columnWidths={COLUMN_WIDTHS}
                        key={organisation.id}
                        organisationID={organisation.id}
                        organisationName={organisation.name}
                        parentOrganisationID={organisation.parentOrganisation?.id}
                        parentOrganisationName={organisation.parentOrganisation?.name}
                      />
                    ))}
                  </>
                )}
                {searchString.length && !allOrganisationsData?.allApprovedOrganisations?.objects.length && !loading ? (
                  <TRTDTemp>
                    <IllustratedCTA illustration={EIllustration.PEOPLE_COLLABORATING} noPadding>
                      No organisations found.
                    </IllustratedCTA>
                  </TRTDTemp>
                ) : null}
              </>
            </>
          </Table>
        </Cell>
        {allOrganisationsData?.allApprovedOrganisations?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={allOrganisationsData?.allApprovedOrganisations?.hasNext}
              hasPrevious={allOrganisationsData?.allApprovedOrganisations?.hasPrev}
              page={allOrganisationsData?.allApprovedOrganisations?.page}
              pages={allOrganisationsData?.allApprovedOrganisations?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
    </>
  );
}
