import { EDevice, useChooseDevices, TVidyoDevice } from "packages/call";
import { Cell, Field, Dropdown } from "packages/catalog";
import { EIcon } from "packages/utils";

export function DeviceConfiguration() {
  const { cameras, chooseCamera, chooseMicrophone, chooseSpeaker, microphones, speakers } = useChooseDevices();
  return (
    <>
      <Cell>
        <DeviceChooser
          available={cameras.available}
          disabled={!cameras.available.length}
          icon={EIcon.CAMERA}
          label="Camera"
          selected={cameras.selected}
          select={chooseCamera}
        />
      </Cell>
      <Cell>
        <DeviceChooser
          available={microphones.available}
          disabled={!microphones.available.length}
          icon={EIcon.MIC}
          label="Microphone"
          selected={microphones.selected}
          select={chooseMicrophone}
        />
      </Cell>
      <Cell>
        <DeviceChooser
          available={speakers.available}
          disabled={!speakers.available.length}
          icon={EIcon.VOLUME2}
          label="Speakers"
          selected={speakers.selected}
          select={chooseSpeaker}
        />
      </Cell>
    </>
  );
}

interface PDeviceChooser<T extends EDevice> {
  disabled?: boolean;
  label: string;
  available: TVidyoDevice<T>[];
  selected?: TVidyoDevice<T>;
  select: (d: TVidyoDevice<T>) => void;
  icon?: EIcon;
}
function DeviceChooser<T extends EDevice>({ available, disabled, icon, label, selected, select }: PDeviceChooser<T>) {
  function onSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    select(available.find(d => d.id === e.target.value));
  }
  const devices = available.reduce<Record<string, string>>((acc, dev) => {
    return {
      ...acc,
      [dev.id]: dev.name,
    };
  }, {});
  return (
    <Field label={label}>
      <Dropdown
        disabled={disabled}
        icon={icon}
        name="devices"
        onChange={onSelect}
        options={devices}
        required
        value={selected?.id}
      />
    </Field>
  );
}
