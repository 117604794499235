import { EDjangoPricingPlan, EDjangoSector, IDjangoFullOrganisation } from "packages/client/organisations/django/types";

import type { OrganisationType } from "packages/client/graphql_definitions.generated";

// we need to change the administrators field from an array of UserTypes to an array of strings, to allow for orgnanisation creation with just strings of administrator IDs
export interface IOrganisationTypeOverride extends Omit<OrganisationType, "administrators" | "parentOrganisation"> {
  administratorIDs: string[];
  parentOrganisationID: string;
}

export function toDjangoOrganisation(
  data: Partial<IOrganisationTypeOverride>,
  cleanUndefinedFields = false,
): Partial<IDjangoFullOrganisation> {
  const serialised = {
    about: data?.about,
    address: data?.address,
    administrators: data?.administratorIDs?.map(administratorID => parseInt(administratorID)),
    city: data?.city,
    country: data?.country,
    county: data?.county,
    domain: data?.domain,
    hide_collaboration: data?.hideCollaboration,
    name: data?.name,
    parent_organisation: data.parentOrganisationID ? parseInt(data.parentOrganisationID) : undefined,
    phone_number: data?.phoneNumber,
    postcode: data?.postcode,
    pricing_plan: EDjangoPricingPlan[data.pricingPlan],
    private_invites: data?.privateInvites,
    rebranding: data?.rebranding,
    recording_disabled: data?.recordingDisabled,
    region: data?.region,
    sector: EDjangoSector[data.sector],
  };

  if (!cleanUndefinedFields) return serialised;

  return Object.fromEntries(Object.entries(serialised).filter(([, v]) => v !== undefined));
}
