export enum EDjangoPricingPlan {
  BASIC = "basic",
  CAMPUS = "campus",
  SCHOOLS = "schools",
  SUPER_ORG = "super_org",
  SUPER_ORG_LITE = "super_org_lite",
  TEAM = "team",
}

export enum EDjangoSector {
  COMMERCIAL = "commercial",
  CONTENT_PROVIDERS = "content providers",
  FURTHER_EDUCATION_AND_ADULT = "further education and adult",
  HEALTH = "health",
  HIGHER_EDUCATION = "higher education",
  PUBLIC_SECTOR = "public sector",
  RESEARCH = "research",
  SCHOOLS = "schools",
}

export interface IDjangoConnections {
  connected_organisations: number[];
}
export interface IDjangoPendingConnections {
  incoming: number[];
  outgoing: number[];
}

export interface IDjangoEssentialOrganisation {
  id: number;
  logo: string;
  name: string;
  parent_organisation: number;
  rebranding: boolean;
}

export interface IDjangoFullOrganisation {
  about?: string;
  address: string;
  administrators: number[];
  analytics_dashboard: boolean;
  bulk_user_import: boolean;
  city: string;
  country: string;
  county: string;
  dial_out_enabled: boolean;
  domain: string;
  id?: number;
  latitude: number;
  logo: string;
  longitude: number;
  max_admin_accounts: number;
  max_guests: number;
  max_host_accounts: number;
  max_sip_connections: number;
  name: string;
  nrenum_enabled: boolean;
  operator_support: boolean;
  parent_organisation: number;
  phone_number: string;
  postcode: string;
  pricing_plan: EDjangoPricingPlan;
  rebranding: boolean;
  recording_enabled: boolean;
  region: string;
  sector: EDjangoSector;
  skype_for_business_enabled: boolean;
  webcast_enabled: boolean;
}
