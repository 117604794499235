import { Alerts, PAlert, Description, Dropdown, Input, Label, TextArea } from "packages/catalog";
import { flattenString, EStatus } from "packages/utils/";

import styles from "./Field.module.scss";

export interface PField {
  alerts?: PAlert[];
  children: ReactChild<typeof Input | typeof TextArea | typeof Dropdown>;
  description?: string;
  label: string;
}

function calculateBorderStyle(error: boolean, warning: boolean, success: boolean) {
  if (error) {
    return styles.error;
  }
  if (warning) {
    return styles.warning;
  }
  if (success) {
    return styles.success;
  } else return null;
}

export function Field({ alerts, children, description, label }: PField) {
  const descriptionString = flattenString(description);

  const hasError = (alerts ?? []).some(alert => alert.status === EStatus.DANGER);
  const hasWarning = (alerts ?? []).some(alert => alert.status === EStatus.WARNING);
  const hasSuccess = (alerts ?? []).some(alert => alert.status === EStatus.SUCCESS);

  return (
    <div className={`${styles.field} ${calculateBorderStyle(hasError, hasWarning, hasSuccess)}`}>
      <Label aria-describedby={descriptionString} error={hasError} hasMargin text={label}>
        {children}
      </Label>
      {description && <Description id={descriptionString}>{description}</Description>}
      {alerts && <Alerts alerts={alerts} />}
    </div>
  );
}
