import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { Cell, Field, Input, PAlert } from "packages/catalog";
import { EStatus } from "packages/utils";

const alerts = [
  {
    successCriteria: "isAtLeast8Characters",
    text: "Be at least 8 characters long",
  },
  {
    successCriteria: "isContainingNumber",
    text: "Contain at least one number",
  },
  {
    successCriteria: "isContainingUpperAndLower",
    text: "Include at least one uppercase and lowercase letter",
  },
];

const PASSWORD_CRITERIA: { [successCriteria: string]: boolean } = {
  isAtLeast8Characters: false,
  isContainingNumber: false,
  isContainingUpperAndLower: false,
};

export interface PPasswordValidation {
  isNotMatchingErrorDisplayed: boolean;
  password: string;
  passwordResetError?: string;
  reEnteredPassword: string;
  setIsValid: (isValid: boolean) => void;
  setPassword: (password: string) => void;
  setReEnteredPassword: (reEnteredPassword: string) => void;
}

export function PasswordValidation({
  isNotMatchingErrorDisplayed,
  password,
  passwordResetError,
  reEnteredPassword,
  setIsValid,
  setPassword,
  setReEnteredPassword,
}: PPasswordValidation) {
  const [passwordValidation, setPasswordValidation] = useState(PASSWORD_CRITERIA);

  const handleChangePassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPasswordValidation({
        isAtLeast8Characters: e.currentTarget.value.length >= 8,
        isContainingNumber: /[0-9]/.test(e.currentTarget.value),
        isContainingUpperAndLower: /[a-z]/.test(e.currentTarget.value) && /[A-Z]/.test(e.currentTarget.value),
      });
      setPassword(e.currentTarget.value);
    },
    [setPassword],
  );

  const handleChangeConfirmPassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setReEnteredPassword(e.currentTarget.value);
    },
    [setReEnteredPassword],
  );

  const calculateAlerts = useCallback(() => {
    const alerts: PAlert[] = [];

    if (password !== reEnteredPassword)
      alerts.push({
        message: "Please make sure your passwords match",
        status: EStatus.DANGER,
      });

    if (passwordResetError)
      alerts.push({
        message: passwordResetError,
        status: EStatus.DANGER,
      });

    return alerts;
  }, [password, passwordResetError, reEnteredPassword]);

  useEffect(() => {
    setIsValid(!Object.values(passwordValidation).includes(false));
  }, [password, passwordValidation, setIsValid]);

  return (
    <>
      <Cell>
        <Field
          alerts={alerts.map(alert => {
            return {
              message: alert.text,
              status:
                password.length > 0
                  ? passwordValidation[alert.successCriteria]
                    ? EStatus.SUCCESS
                    : EStatus.DANGER
                  : EStatus.NEUTRAL,
            };
          })}
          label="New password"
          description="Passwords must:">
          <Input
            autoComplete="new-password"
            maxLength={50}
            name="new-password"
            onChange={handleChangePassword}
            required
            type="password"
            value={password}
          />
        </Field>
      </Cell>
      <Cell>
        <Field
          alerts={
            (password.length &&
              reEnteredPassword.length &&
              isNotMatchingErrorDisplayed &&
              password !== reEnteredPassword) ||
            passwordResetError
              ? calculateAlerts()
              : null
          }
          label="Re-enter new password">
          <Input
            autoComplete="new-password"
            maxLength={50}
            name="new-password-confirm"
            onChange={handleChangeConfirmPassword}
            required
            type="password"
            value={reEnteredPassword}
          />
        </Field>
      </Cell>
    </>
  );
}
