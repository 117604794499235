import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { Flexbox, H2, IconButton, SmallPopoverMenu, StatusBadge, TextButton } from "packages/catalog";
import { EAppearance, EAttachment, EDimension, EIcon, PATH_CALLLINK, EStatus } from "packages/utils";

import { useGenerateEmailInvitationURI } from "packages/client/sessions/hooks";
import type { RoomObjectType } from "packages/client/graphql_definitions.generated";

import { copySessionURL } from "packages/client/sessions/functions";

import { HubInfo } from "packages/client/hubs/components";

import styles from "./HubListItem.module.scss";

export interface PHubListItem {
  hub: Partial<RoomObjectType>;
  mainHub: boolean;
  removeHub: (e: string) => void;
  setPin: (e: string) => void;
}

export function HubListItem({ hub, mainHub, removeHub, setPin }: PHubListItem) {
  const [showDetails, setShowDetails] = useState(false);

  const emailInvitationURI = useGenerateEmailInvitationURI(hub.key);

  const toggleShowDetails = useCallback(() => {
    setShowDetails(v => !v);
  }, []);
  const handleSetPIN = useCallback(() => {
    setPin(hub.key);
  }, [hub.key, setPin]);

  return !showDetails ? (
    <article className={styles.hub}>
      <Flexbox alignItemsCenter justifyContentSpaceBetween>
        <div>{mainHub && <StatusBadge status={EStatus.NEUTRAL}>Permanent</StatusBadge>}</div>
        <div className={styles.menu}>
          <SmallPopoverMenu
            attachment={EAttachment.SOUTH_SOUTH_EAST}
            key={hub.key}
            options={[
              !hub.isClassview && {
                onClick: toggleShowDetails,
                status: EStatus.NEUTRAL,
                text: "View details",
              },
              !hub.isClassview && {
                as: "a",
                href: emailInvitationURI,
                status: EStatus.NEUTRAL,
                text: "Invite participants",
              },
              !mainHub && {
                onClick: () => removeHub(hub.key),
                status: EStatus.DANGER,
                text: "Delete",
              },
            ]}
            trigger={
              <IconButton
                as="div"
                description="Options"
                status={EStatus.NEUTRAL}
                icon={EIcon.MOREVERTICAL}
                appearance={EAppearance.GHOST}
                dimension={EDimension.SMALL}
              />
            }
          />
        </div>
      </Flexbox>
      <div>
        <H2>{mainHub ? "Personal hub" : hub.displayName}</H2>
      </div>
      <div className={styles.manage}>
        <IconButton
          appearance={EAppearance.OUTLINE}
          as="button"
          description="Copy link"
          dimension={EDimension.LARGE}
          icon={EIcon.COPY}
          label="Copy link"
          onClick={() => copySessionURL(hub?.key)}
          status={EStatus.NEUTRAL}
        />
        {!hub.isClassview && (
          <IconButton
            appearance={EAppearance.OUTLINE}
            as="a"
            description="Send invitation email"
            dimension={EDimension.LARGE}
            href={emailInvitationURI}
            icon={EIcon.MAIL}
            label="Invite"
            status={EStatus.NEUTRAL}
          />
        )}
        <IconButton
          appearance={EAppearance.OUTLINE}
          as="button"
          description={hub.pin ? "Change PIN" : "Set PIN"}
          dimension={EDimension.LARGE}
          icon={hub.pin ? EIcon.LOCK : EIcon.UNLOCK}
          label={hub.pin ? "Change PIN" : "Set PIN"}
          onClick={handleSetPIN}
          status={hub.pin ? EStatus.DANGER : EStatus.NEUTRAL}
        />
      </div>
      <TextButton as={Link} to={PATH_CALLLINK(hub.key)} status={EStatus.ACCENT}>
        Join session
      </TextButton>
    </article>
  ) : (
    <HubInfo hub={hub} toggle={toggleShowDetails} />
  );
}
