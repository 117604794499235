import { ChangeEvent, useCallback } from "react";

import { Cell, Dropdown, Field, Flexbox, Input } from "packages/catalog";

import { ERecurringFrequency } from "packages/client/sessions/redux/types";

import { weeklyOccurrencies } from "packages/client/sessions/consts";

import { sanitiseRepetitions } from "packages/client/sessions/functions";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import {
  changeRecurringEveryXMonths,
  changeRecurringEveryXWeeks,
  changeRecurringOnXWeekOfMonth,
} from "packages/client/sessions/redux/slice";

import { EditRecurringDays } from "packages/client/sessions/components";

export interface PEditRecurringOccurrence {
  isEditMode: boolean;
}

export function EditRecurringOccurrence({ isEditMode }: PEditRecurringOccurrence) {
  const dispatch = useTypedDispatch();
  const { recurringEveryXMonths, recurringEveryXWeeks, recurringInterval, recurringOnXWeekOfMonth } = useTypedSelector(
    ({ sessionForm }) => ({
      recurringEveryXWeeks: sessionForm.recurringEveryXWeeks,
      recurringEveryXMonths: sessionForm.recurringEveryXMonths,
      recurringInterval: sessionForm.recurringInterval,
      recurringOnXWeekOfMonth: sessionForm.recurringOnXWeekOfMonth,
    }),
  );

  const handleOnEveryXMonthChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      dispatch(changeRecurringEveryXMonths(sanitiseRepetitions(e.currentTarget.value))),
    [dispatch],
  );

  const handleOnEveryXWeekChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      dispatch(changeRecurringEveryXWeeks(sanitiseRepetitions(e.currentTarget.value))),
    [dispatch],
  );

  return (
    <>
      <EditRecurringDays isEditMode={isEditMode} />
      {recurringInterval === ERecurringFrequency.Weekly && (
        <Cell>
          <Field description={`week${recurringEveryXWeeks > 1 ? "s" : ""}`} label="Repeat every">
            <Input
              max={56}
              min={1}
              name="recurring-x-weeks"
              onChange={handleOnEveryXWeekChange}
              showNumberControls
              type="number"
              value={recurringEveryXWeeks}
            />
          </Field>
        </Cell>
      )}
      {recurringInterval === ERecurringFrequency.Monthly && (
        <Cell>
          <Flexbox>
            <Field description={`month${recurringEveryXMonths > 1 ? "s" : ""}`} label="Repeat every">
              <Input
                max={12}
                min={1}
                name="recurring-x-months"
                onChange={handleOnEveryXMonthChange}
                showNumberControls
                type="number"
                value={recurringEveryXMonths}
              />
            </Field>
            <Field label="Weekly occurence">
              <Dropdown
                name="recurring-x-week-of-month"
                onChange={e => dispatch(changeRecurringOnXWeekOfMonth(parseInt(e.currentTarget.value, 10) || 0))}
                value={recurringOnXWeekOfMonth}
                options={weeklyOccurrencies}
              />
            </Field>
          </Flexbox>
        </Cell>
      )}
    </>
  );
}
