import { useCallback } from "react";

import { useVidyoSDKInstance } from "../../status/hooks/useVidyoSDKInstance";
import { dispatchVidyo, useSelectorVidyo } from "../../redux";
import { EVidyoLocalDevicesActions } from "../redux/types";
import type { TLocalCamera, TLocalMicrophone, TLocalSpeaker } from "../types";
import { EDevice } from "../enums/EDevice";

import { extractState } from "../functions/extractState";

export function useChooseDevices() {
  const vidyoSDK = useVidyoSDKInstance();
  const { cameras, microphones, speakers } = useSelectorVidyo(({ localDevices }) => ({
    cameras: extractState(localDevices.cameras),
    microphones: extractState(localDevices.microphones),
    speakers: extractState(localDevices.speakers),
  }));

  const chooseCamera = useCallback(
    (c: TLocalCamera) => {
      dispatchVidyo({
        type: EVidyoLocalDevicesActions.SET_PREFERRED_DEVICE,
        deviceId: c.id,
        deviceType: EDevice.CAMERA,
      });
      vidyoSDK.SelectLocalCamera({ localCamera: c });
    },
    [vidyoSDK],
  );

  const chooseMicrophone = useCallback(
    (m: TLocalMicrophone) => {
      dispatchVidyo({
        type: EVidyoLocalDevicesActions.SET_PREFERRED_DEVICE,
        deviceId: m.id,
        deviceType: EDevice.MICROPHONE,
      });
      vidyoSDK.SelectLocalMicrophone({ localMicrophone: m });
    },
    [vidyoSDK],
  );

  const chooseSpeaker = useCallback(
    (s: TLocalSpeaker) => {
      dispatchVidyo({
        type: EVidyoLocalDevicesActions.SET_PREFERRED_DEVICE,
        deviceId: s.id,
        deviceType: EDevice.SPEAKER,
      });
      vidyoSDK.SelectLocalSpeaker({ localSpeaker: s });
    },
    [vidyoSDK],
  );

  return {
    cameras,
    chooseCamera,
    chooseMicrophone,
    chooseSpeaker,
    microphones,
    speakers,
  };
}
