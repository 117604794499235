import { useCallback } from "react";

import { useDevicesMutedState } from "packages/call";
import { IconButton } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

import styles from "./DeviceStatus.module.scss";

export const commonProps = {
  appearance: EAppearance.OUTLINE,
  as: "button",
  dimension: EDimension.LARGE,
  status: EStatus.NEUTRAL,
  type: "button",
} as const;

export function DeviceStatus() {
  const { shouldCameraBeMuted, shouldMicrophoneBeMuted, setShouldCameraBeMuted, setShouldMicrophoneBeMuted } =
    useDevicesMutedState();

  const handleMutedVideoChange = useCallback(() => {
    setShouldCameraBeMuted(!shouldCameraBeMuted);
  }, [setShouldCameraBeMuted, shouldCameraBeMuted]);

  const handleMutedAudioChange = useCallback(() => {
    setShouldMicrophoneBeMuted(!shouldMicrophoneBeMuted);
  }, [setShouldMicrophoneBeMuted, shouldMicrophoneBeMuted]);

  return (
    <div className={styles.buttonsContainer}>
      <IconButton
        {...commonProps}
        description={!shouldCameraBeMuted ? "Turn off camera" : "Turn on camera"}
        icon={shouldCameraBeMuted ? EIcon.VIDEOOFF : EIcon.VIDEO}
        label={!shouldCameraBeMuted ? "Turn off" : "Turn on"}
        onClick={handleMutedVideoChange}
      />
      <IconButton
        {...commonProps}
        description={!shouldMicrophoneBeMuted ? "Mute microphone" : "Unmute microphone"}
        icon={shouldMicrophoneBeMuted ? EIcon.MICOFF : EIcon.MIC}
        label={!shouldMicrophoneBeMuted ? "Mute" : "Unmute"}
        onClick={handleMutedAudioChange}
      />
    </div>
  );
}
