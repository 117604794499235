import { useCallback, useEffect, useState } from "react";

import { Dialog, Emoji, ExternalTextLink, Flexbox, LI, P, TextButton, UL } from "packages/catalog";
import { EStatus } from "packages/utils";

import { useTypedSelector } from "packages/client/redux";
import { useGetPrivacyAcceptedOwnUserQuery } from "packages/client/users/graphql/users.generated";
import { acceptPrivacyPolicy } from "packages/client/users/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function PrivacyPolicyModal() {
  const [isOpen, setIsOpen] = useState(false);

  const { isSignedIn } = useTypedSelector(({ authentication }) => ({
    isSignedIn: authentication.isSignedIn,
  }));

  const { data, loading, refetch } = useGetPrivacyAcceptedOwnUserQuery();

  const handleAccept = useCallback(() => acceptPrivacyPolicy().then(() => refetch()), [refetch]);

  useEffect(() => {
    setIsOpen(!data?.user?.acceptedPrivacyPolicy);
  }, [data?.user?.acceptedPrivacyPolicy]);

  if (!isSignedIn || data?.user?.acceptedPrivacyPolicy || loading) return null;

  return (
    <Dialog
      h1={
        <>
          Policies updated&nbsp;
          <Emoji symbol="🔔" />
        </>
      }
      isOpen={isOpen}>
      <RoutingChangeHandler message="The privacy policy modal has loaded" />
      <P hasMargin>Please agree to our updated terms of service.</P>
      <P hasMargin>You can read these on our website:</P>
      <UL hasMargin>
        <LI>
          <ExternalTextLink href="https://classview.com/privacy-policy/">Privacy Policy</ExternalTextLink>
        </LI>
        <LI>
          <ExternalTextLink href="https://classview.com/end-user-licence-agreement/">
            End User Licence Agreement (EULA)
          </ExternalTextLink>
        </LI>
      </UL>
      <P hasMargin>You must accept these terms to continue.</P>
      <Flexbox justifyContentEnd>
        <TextButton onClick={handleAccept} status={EStatus.ACCENT}>
          Accept &amp; continue
        </TextButton>
      </Flexbox>
    </Dialog>
  );
}
