import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Cell,
  ConfirmActionModal,
  Feedback,
  Flexbox,
  Grid,
  H1,
  Input,
  IllustratedCTA,
  Label,
  Loader,
  P,
  Pagination,
  Table,
  TextButton,
  TRTDTemp,
} from "packages/catalog";
import { EIllustration, EStatus, scrollToContentTop, SEGMENT_ADD } from "packages/utils";

import { SuperAdminRoomSystemRow } from "packages/client/room-systems/components";

import { useGetAllRoomSystemsQuery } from "packages/client/room-systems/graphql/roomsystems.generated";
import { deleteRoomSystem } from "packages/client/room-systems/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";

const COLUMNS = ["ID", "Name", "Status", "Type", "Address/extension", "Organisation"];
const COLUMN_WIDTHS = "4rem 16rem 1fr 1fr 1.4fr 2fr 2rem";

export function SuperAdminRoomSystems() {
  const [actionID, setActionID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchString, setDebounceSearchString] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] = useState(false);
  const [showDeleteErrorMessage, setShowDeleteErrorMessage] = useState(false);

  const {
    data: allRoomSystemsData,
    loading,
    error,
    refetch,
  } = useGetAllRoomSystemsQuery({
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    variables: { page: currentPage, search: debouncedSearchString },
  });

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value);
  }, []);

  const handleDeleteRoomSystem = useCallback(() => {
    deleteRoomSystem(actionID)
      .then(() => {
        scrollToContentTop();
        refetch();
        setShowDeleteSuccessMessage(true);
      })
      .catch(() => {
        scrollToContentTop();
        setShowDeleteErrorMessage(true);
      });
    setActionID("");
    setIsDeleteModalOpen(false);
  }, [actionID, refetch]);

  const prepareDeleteRoomSystem = useCallback((roomSystemID: string) => {
    setShowDeleteSuccessMessage(false);
    setShowDeleteErrorMessage(false);
    setActionID(roomSystemID);
    setIsDeleteModalOpen(true);
  }, []);

  useEffect(() => {
    let cancelled = false;

    window.setTimeout(() => {
      if (!cancelled) setDebounceSearchString(searchString);
    }, 500);

    return () => {
      cancelled = true;
    };
  }, [searchString]);

  return (
    <>
      <RoutingChangeHandler message="The Room Systems page has loaded." pageTitle="Room Systems" />
      <Grid noColGap>
        {showDeleteSuccessMessage && (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Room System deleted.</Feedback>
          </Cell>
        )}
        {showDeleteErrorMessage && (
          <Cell>
            <Feedback status={EStatus.DANGER}>Unable to delete Room System. Please try again.</Feedback>
          </Cell>
        )}
        <Cell>
          <Flexbox alignItemsCenter justifyContentSpaceBetween>
            <H1>Room Systems</H1>
            <Flexbox>
              <Label htmlFor="search" isVisuallyHidden text="Search Room Systems" />
              <Input id="search" name="search" onChange={handleChangeSearch} type="search" value={searchString} />
              <TextButton as={Link} status={EStatus.ACCENT} to={SEGMENT_ADD}>
                Add new
              </TextButton>
            </Flexbox>
          </Flexbox>
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="All Room Systems on ClassView"
            hasMenuColumn>
            <>
              {loading ? (
                <TRTDTemp>
                  <Loader notFullHeight />
                </TRTDTemp>
              ) : (
                <>
                  {allRoomSystemsData?.allRoomSystems?.objects?.length
                    ? allRoomSystemsData?.allRoomSystems?.objects?.map(roomSystem => (
                        <SuperAdminRoomSystemRow
                          columns={COLUMNS}
                          columnWidths={COLUMN_WIDTHS}
                          ip={roomSystem.ip}
                          key={roomSystem.id}
                          name={roomSystem.name}
                          organisationID={roomSystem.organisation.id}
                          organisationName={roomSystem.organisation.name}
                          prepareDeleteRoomSystem={prepareDeleteRoomSystem}
                          privacy={roomSystem.privacy}
                          roomSystemID={roomSystem.id}
                          status={roomSystem.onlineStatus}
                          type={roomSystem.type}
                        />
                      ))
                    : !debouncedSearchString.length &&
                      !loading && (
                        <TRTDTemp>
                          <P center>No Room Systems</P>
                        </TRTDTemp>
                      )}
                  {debouncedSearchString.length && !allRoomSystemsData?.allRoomSystems?.objects.length && !loading ? (
                    <TRTDTemp>
                      <IllustratedCTA illustration={EIllustration.PEOPLE_COLLABORATING} noPadding>
                        No Room Systems found.
                      </IllustratedCTA>
                    </TRTDTemp>
                  ) : null}
                </>
              )}
              {error && (
                <TRTDTemp>
                  <Feedback status={EStatus.DANGER}>Unable to get list of room systems.</Feedback>
                </TRTDTemp>
              )}
              <></>
            </>
          </Table>
        </Cell>
        {allRoomSystemsData?.allRoomSystems?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={allRoomSystemsData?.allRoomSystems?.hasNext}
              hasPrevious={allRoomSystemsData?.allRoomSystems?.hasPrev}
              page={allRoomSystemsData?.allRoomSystems?.page}
              pages={allRoomSystemsData?.allRoomSystems?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="Room System"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteRoomSystem}
        status={EStatus.DANGER}
      />
    </>
  );
}
