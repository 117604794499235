import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { IChatState } from "packages/client/incall/redux/types";

const initialState: IChatState = {
  message: "",
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    updateMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
  },
});

export const { updateMessage } = chatSlice.actions;

export const chatReducer = chatSlice.reducer;
