import { useEffect } from "react";

import { useVidyoSDKInstance } from "../../status/hooks/useVidyoSDKInstance";
import { useSelectorVidyo } from "../../redux";

export function useKeepDevicesActive(skip = false) {
  const vidyoSDK = useVidyoSDKInstance();
  const {
    shouldCameraBeMuted,
    shouldMicrophoneBeMuted,
    shouldSpeakerBeMuted,
    cameraServerMuted,
    microphoneServerMuted,
  } = useSelectorVidyo(({ localDevices }) => ({
    shouldCameraBeMuted: localDevices.cameras.shouldBeMuted,
    shouldMicrophoneBeMuted: localDevices.microphones.shouldBeMuted,
    shouldSpeakerBeMuted: localDevices.speakers.shouldBeMuted,
    cameraServerMuted: localDevices.cameras.serverMuted,
    microphoneServerMuted: localDevices.microphones.serverMuted,
  }));

  useEffect(() => {
    if (!vidyoSDK || skip) return;
    vidyoSDK.SetCameraPrivacy({ privacy: shouldCameraBeMuted || cameraServerMuted });

    return () => {
      vidyoSDK.SetCameraPrivacy({ privacy: true });
    };
  }, [cameraServerMuted, shouldCameraBeMuted, skip, vidyoSDK]);

  useEffect(() => {
    if (!vidyoSDK || skip) return;
    vidyoSDK.SetMicrophonePrivacy({ privacy: shouldMicrophoneBeMuted || microphoneServerMuted });

    return () => {
      vidyoSDK.SetMicrophonePrivacy({ privacy: true });
    };
  }, [microphoneServerMuted, shouldMicrophoneBeMuted, skip, vidyoSDK]);

  useEffect(() => {
    if (!vidyoSDK || skip) return;
    vidyoSDK.SetSpeakerPrivacy({ privacy: shouldSpeakerBeMuted });

    return () => {
      vidyoSDK.SetSpeakerPrivacy({ privacy: true });
    };
  }, [shouldSpeakerBeMuted, skip, vidyoSDK]);
}
