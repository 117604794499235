import type { ChangeEventHandler } from "react";

import { AnswerOption, P } from "packages/catalog";
import { flattenString } from "packages/utils";

import styles from "./QuestionFieldset.module.scss";

export interface PQuestionFieldset {
  answerOptions: { answerOption: string; answerOptionID: string }[];
  allowsMultipleAnswers?: boolean;
  description?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  question: string;
  questionID: string;
  selectedAnswerOption: string;
}

export function QuestionFieldset({
  answerOptions,
  allowsMultipleAnswers,
  description,
  onChange,
  question,
  questionID,
  selectedAnswerOption,
}: PQuestionFieldset) {
  const descriptionString = flattenString(question);

  return (
    <fieldset aria-describedby={descriptionString} name={questionID}>
      <div className={styles.question}>
        <legend className={styles.legend}>{question}</legend>
        {description && (
          <P className={styles.description} id={descriptionString}>
            {description}
          </P>
        )}
      </div>
      <div role="group">
        {answerOptions?.map(answerOption => (
          <AnswerOption
            allowsMultipleAnswers={allowsMultipleAnswers}
            answerOption={answerOption.answerOption}
            answerOptionID={answerOption.answerOptionID}
            checked={selectedAnswerOption === answerOption.answerOptionID}
            key={answerOption.answerOptionID}
            onChange={onChange}
            questionID={questionID}
          />
        ))}
      </div>
    </fieldset>
  );
}
