import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { Cell, ConfirmActionModal, Feedback, Grid, H2, Loader, Table } from "packages/catalog";
import { EStatus, scrollToContentTop, TParams } from "packages/utils";

import { useGetUsersOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";
import { useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";
import { approveUser, deleteUser, demoteUser, promoteUser } from "packages/client/users/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SuperAdminUserRow } from "packages/client/users/components/SuperAdminUserRow/SuperAdminUserRow";

const COLUMNS = ["ID", "User", "Status"];
const COLUMN_WIDTHS = "4rem 1fr 1fr 2rem";

export function SuperAdminOrganisationUsers() {
  const { organisationID }: TParams = useParams();

  const [actionID, setActionID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    data: organisationData,
    loading,
    refetch: refetchAllUsers,
  } = useGetUsersOrganisationQuery({
    variables: {
      organisationID: parseInt(organisationID, 10),
    },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });
  const { data: ownUserData } = useGetEssentialOwnUserQuery();

  const handleApproveUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      approveUser(userID)
        .then(() => {
          refetchAllUsers();
          setSuccessMessage("User approved.");
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetchAllUsers],
  );

  const handleDeleteUser = useCallback(() => {
    setSuccessMessage("");
    setErrorMessage("");

    scrollToContentTop();
    setIsDeleteModalOpen(false);

    deleteUser(actionID)
      .then(() => {
        refetchAllUsers();
        setSuccessMessage("User deleted.");
      })
      .catch(reason => {
        setErrorMessage(reason.message);
      });

    setActionID("");
  }, [actionID, refetchAllUsers]);

  const handleDemoteUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      scrollToContentTop();

      demoteUser(userID)
        .then(() => {
          refetchAllUsers();
          setSuccessMessage("User removed from administrators.");
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetchAllUsers],
  );

  const handlePromoteUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      scrollToContentTop();

      promoteUser(userID)
        .then(() => {
          setSuccessMessage("User added to administrators");
          refetchAllUsers();
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetchAllUsers],
  );

  const prepareDeleteUser = useCallback((userID: string) => {
    setActionID(userID);
    setIsDeleteModalOpen(true);
  }, []);

  if (!organisationData) return null;
  if (loading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler message="The organistion users page has loaded." pageTitle="Organisation users" />
      <ConfirmActionModal
        action="delete"
        content="user"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteUser}
        status={EStatus.DANGER}
      />
      <Grid>
        {errorMessage.length ? (
          <Cell>
            <Feedback status={EStatus.DANGER}>{errorMessage}</Feedback>
          </Cell>
        ) : null}
        {successMessage.length ? (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>{successMessage}</Feedback>
          </Cell>
        ) : null}
        <Cell>
          <H2>Users</H2>
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing the organisation's users"
            hasMenuColumn>
            <>
              {organisationData?.organisation?.userSet?.map(user => (
                <SuperAdminUserRow
                  columns={COLUMNS}
                  columnWidths={COLUMN_WIDTHS}
                  email={user.email}
                  handleApproveUser={handleApproveUser}
                  handleDemoteUser={handleDemoteUser}
                  handlePromoteUser={handlePromoteUser}
                  hideOrganisation
                  isAdmin={user.isAdmin}
                  isApproved={user.isActive}
                  isEmailAuthenticated={user.emailAuthenticated}
                  isSuperAdmin={user.isSuperuser}
                  key={user.id}
                  organisationID={user.organisation?.id}
                  organisationName={user.organisation?.name}
                  prepareDeleteUser={prepareDeleteUser}
                  userFullname={`${user.firstName} ${user.lastName}`}
                  userID={user.id}
                  userOwnID={ownUserData?.user.id}
                />
              ))}
            </>
          </Table>
        </Cell>
      </Grid>
    </>
  );
}
