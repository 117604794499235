import { useMemo } from "react";

import { EStatus, useStatusStyle } from "packages/utils";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Alert.module.scss";
export interface PAlert {
  message: string;
  status: EStatus;
}

export function Alert({ message, status }: PAlert) {
  const statusStyle = useStatusStyle(styles);
  const classes = useMemo(() => [styles.alert, statusStyle[status]].join(" "), [status, statusStyle]);

  return (
    <li className={classes} role="alert">
      {message}
    </li>
  );
}
