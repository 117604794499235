import React, { useCallback, useState } from "react";

import { ConfirmActionModal } from "packages/catalog";
import { EIcon, EStatus } from "packages/utils";

import { useWhiteboardContext } from "packages/client/incall/contexts";

import { Tool } from "packages/client/incall/components";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "packages/client/incall/components/whiteboard/Tool/Tool.modules.scss";

export function ToolUndoRedo({ isCondensed, isModerator }: { isCondensed: boolean; isModerator: boolean }) {
  const { clear, undo, redo, canUndo, canRedo, isWhiteboardClean } = useWhiteboardContext();
  const [active, setActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) setActive(false);
  }, []);

  const handleActive = useCallback(() => {
    if (!isModalOpen) setActive(!active);
  }, [active, isModalOpen]);

  const stopPropagation = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const onDelete = useCallback(() => {
    setActive(false);
    clear();
    setIsModalOpen(false);
  }, [clear]);

  if (isCondensed)
    return (
      <div className={styles.popoverContainer} onClick={handleActive} onBlur={handleBlur}>
        <Tool icon={EIcon.MOREHORIZONTAL} inputType="button" label="Edit" active={active} />
        {active && (
          <div
            className={`${styles.popover} ${styles.popoverRightAligned} ${
              !isModerator ? `${styles.notModerator}` : ""
            }`}
            onClick={stopPropagation}
            onBlur={handleBlur}>
            <Tool icon={EIcon.CORNERUPLEFT} inputType="button" label="Undo" onClick={undo} disabled={!canUndo} />
            <Tool icon={EIcon.CORNERUPRIGHT} inputType="button" label="Redo" onClick={redo} disabled={!canRedo} />
            {isModerator && (
              <Tool
                icon={EIcon.TRASH2}
                inputType="button"
                label="Delete"
                onClick={() => setIsModalOpen(true)}
                disabled={isWhiteboardClean}
              />
            )}
          </div>
        )}
        <ConfirmActionModal
          action="delete"
          content="whiteboard contents"
          isOpen={isModalOpen}
          onAbort={() => setIsModalOpen(false)}
          onAction={onDelete}
          status={EStatus.DANGER}
        />
      </div>
    );

  return (
    <>
      <Tool icon={EIcon.CORNERUPLEFT} inputType="button" label="Undo" onClick={undo} disabled={!canUndo} />
      <Tool icon={EIcon.CORNERUPRIGHT} inputType="button" label="Redo" onClick={redo} disabled={!canRedo} />
      {isModerator && (
        <Tool
          disabled={isWhiteboardClean}
          icon={EIcon.TRASH2}
          inputType="button"
          label="Delete"
          onClick={() => setIsModalOpen(true)}
        />
      )}
      <ConfirmActionModal
        action="delete"
        content="whiteboard contents"
        isOpen={isModalOpen}
        onAbort={() => setIsModalOpen(false)}
        onAction={onDelete}
        status={EStatus.DANGER}
      />
    </>
  );
}
