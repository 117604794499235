import { HEADER_JSON } from "packages/utils";

import { fetchDjangoAPIAuthenticated } from "packages/client/fetch";
import type { RoomSystemObjectType } from "packages/client/graphql_definitions.generated";

import { toDjangoRoomSystem } from "packages/client/room-systems/django/serialisers";

export async function addRoomSystem(roomSystemData: Partial<RoomSystemObjectType>) {
  const response = await fetchDjangoAPIAuthenticated("/room-systems/", {
    body: JSON.stringify(toDjangoRoomSystem(roomSystemData)),
    headers: HEADER_JSON,
    method: "POST",
  });
  const responseData = await response.json();

  if (response.status === 400 && responseData === "You have reached your Room System limit") {
    throw new Error(
      "Your organisation has reached its limit of H.323/E.164/IP and SIP systems. Please delete a system or create a ClassView POD instead.",
    );
  }

  if (response.status === 400 && responseData.name.includes("Room System name already in use.")) {
    throw new Error("Room System name already in use. Please use another name.");
  }

  if (response.status !== 201) {
    throw new Error("Please try again.");
  }

  return response;
}

export async function deleteRoomSystem(roomSystemID: string) {
  const response = await fetchDjangoAPIAuthenticated(`/room-systems/${roomSystemID}/`, {
    headers: HEADER_JSON,
    method: "DELETE",
  });

  if (response.status !== 204) {
    throw new Error("Unable to delete Room System.");
  }

  return response;
}

export async function editRoomSystem(roomSystemData: Partial<RoomSystemObjectType>) {
  const response = await fetchDjangoAPIAuthenticated(`/room-systems/${roomSystemData.id}/`, {
    body: JSON.stringify(toDjangoRoomSystem(roomSystemData)),
    headers: HEADER_JSON,
    method: "PUT",
  });

  if (response.status !== 200) {
    throw new Error("Please try again.");
  }

  return response;
}
