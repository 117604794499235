import { useState } from "react";

import { LOCAL_FORGOT_PASSWORD_CODE, LOCAL_FORGOT_PASSWORD_EMAIL_ADDRESS } from "packages/utils";

import {
  requestResetPasswordCode,
  resetForgottenPassword,
  verifyResetPasswordCode,
} from "packages/client/authentication/django/requests";

export function useResetPassword() {
  const [isCodeConfirmed, setIsCodeConfirmed] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState(null);

  const genericErrorMessage = "Unable to reset your password. Please try again or contact us.";
  const invalidCodeErrorMessage = "Invalid code. Please request another.";
  const noEmailAddressFoundMessage = "Email address is not registered with ClassView.";

  const confirmCode = async (code: string) => {
    try {
      const res = await verifyResetPasswordCode(loadUserDetails(LOCAL_FORGOT_PASSWORD_EMAIL_ADDRESS), code);

      if (res.status === 200) {
        setIsCodeConfirmed(true);
        localStorage.setItem(LOCAL_FORGOT_PASSWORD_CODE, code);
      } else {
        if (res.status === 400) setPasswordResetError(invalidCodeErrorMessage);
        else if (res.status === 404) setPasswordResetError(noEmailAddressFoundMessage);
        else throw new Error();
      }
    } catch (err) {
      console.error(`Error resetting password: [${(err as Error).message}]`);
      setPasswordResetError(genericErrorMessage);
    }
  };

  const requestPasswordReset = async (email?: string) => {
    const emailToUse = email ?? loadUserDetails(LOCAL_FORGOT_PASSWORD_EMAIL_ADDRESS);

    try {
      const res = await requestResetPasswordCode(emailToUse);

      if (res.status === 200) {
        setIsEmailSubmitted(true);
        if (email) localStorage.setItem(LOCAL_FORGOT_PASSWORD_EMAIL_ADDRESS, email);
      } else {
        if (res.status === 404) setPasswordResetError(noEmailAddressFoundMessage);
        else throw new Error();
      }
    } catch (err) {
      console.error(`Error resetting password: [${(err as Error).message}]`);
      setPasswordResetError(genericErrorMessage);
    }
  };

  const loadUserDetails = (key: string) => {
    const storedData = localStorage.getItem(key);

    if (!storedData) throw new Error(`No ${key} found in storage.`);

    if (typeof storedData !== "string") throw new Error(`Saved ${key} does not have a correct format.`);

    return storedData;
  };

  const submitNewPassword = async (newPassword: string, confirmPassword: string) => {
    try {
      const res = await resetForgottenPassword(
        loadUserDetails(LOCAL_FORGOT_PASSWORD_EMAIL_ADDRESS),
        loadUserDetails(LOCAL_FORGOT_PASSWORD_CODE),
        newPassword,
        confirmPassword,
      );

      if (res.status === 200) {
        setIsPasswordReset(true);

        localStorage.removeItem(LOCAL_FORGOT_PASSWORD_EMAIL_ADDRESS);
        localStorage.removeItem(LOCAL_FORGOT_PASSWORD_CODE);
      } else {
        if (res.status === 400) setPasswordResetError(invalidCodeErrorMessage);
        else if (res.status === 404) setPasswordResetError(noEmailAddressFoundMessage);
        else throw new Error();
      }
    } catch (err) {
      console.error(`Error resetting password: [${(err as Error).message}]`);
      setPasswordResetError(genericErrorMessage);
    }
  };

  return {
    confirmCode,
    isCodeConfirmed,
    isEmailSubmitted,
    isPasswordReset,
    passwordResetError,
    requestPasswordReset,
    setPasswordResetError,
    submitNewPassword,
  };
}
