import { useEffect, useMemo, useRef, useState } from "react";

import { EBreakpoint, EIcon, isMobile } from "packages/utils";

import { useRoom } from "packages/client/incall/hooks/useRoom";

import { Tool, ToolCanvas, ToolColor, ToolDrawing, ToolUndoRedo } from "packages/client/incall/components";

import styles from "./WhiteboardToolbar.modules.scss";

export interface PWhiteboardToolbar {
  download: () => void;
}

export function WhiteboardToolbar({ download }: PWhiteboardToolbar) {
  const { isModerator } = useRoom();

  const containerRef = useRef<HTMLDivElement>(null);
  const [availableWidth, setAvailableWidth] = useState(0);

  // condensed needs to be based on container width, not viewport width, so the application of the styling needs to be done in js rather than media query in css

  const isCondensed = useMemo(() => availableWidth < EBreakpoint.BREAKPOINT_MD, [availableWidth]);

  const classes = useMemo(() => {
    const condensedStyle = isCondensed ? styles.condensed : "";
    const moderatorStyle = isModerator ? styles.moderator : "";
    return [styles.toolbar, condensedStyle, moderatorStyle].join(" ");
  }, [isCondensed, isModerator]);

  const inlineStyles = useMemo(
    () =>
      availableWidth < 850
        ? {
            width: availableWidth,
          }
        : undefined,
    [availableWidth],
  );

  useEffect(() => {
    let raf = 0;
    function measureContainer() {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        if (width !== availableWidth) setAvailableWidth(width);
        raf = requestAnimationFrame(measureContainer);
      }
    }
    raf = requestAnimationFrame(measureContainer);
    return () => cancelAnimationFrame(raf);
  }, [availableWidth]);

  return (
    <div className={styles.toolbarContainer} ref={containerRef}>
      <section className={classes} style={inlineStyles}>
        <ToolCanvas isCondensed={isCondensed || inlineStyles !== undefined} />
        <ToolDrawing isCondensed={isCondensed || isMobile} />
        <ToolColor />
        <Tool icon={EIcon.DOWNLOAD} inputType="button" label="Download" onClick={download} />
        <ToolUndoRedo isCondensed={isCondensed} isModerator={isModerator} />
      </section>
    </div>
  );
}
