import { EVidyoParticipantsActions, IParticipantsState, TParticipantsAction } from "./types";
import { ECallStatus, EVidyoStatusActions } from "../../status/redux/types";

import type { TStatusAction } from "../../status/redux/types";

const initialState: IParticipantsState = {
  dynamic: [],
  joined: {},
  loudest: undefined,
  participants: [],
};

export function participantsReducer(
  state = initialState,
  action: TParticipantsAction | TStatusAction,
): IParticipantsState {
  switch (action.type) {
    case EVidyoStatusActions.SET_CALL_STATUS:
      return action.status !== ECallStatus.IN_CALL ? initialState : state;
    case EVidyoParticipantsActions.ADD_PARTICIPANT: {
      const newJoined = { ...state.joined };
      newJoined[action.participant.userId] = action.joined;
      return {
        ...state,
        participants: [...state.participants, action.participant],
        joined: newJoined,
      };
    }
    case EVidyoParticipantsActions.REMOVE_PARTICIPANT: {
      const newJoined = { ...state.joined };
      delete newJoined[action.participant.userId];
      return {
        ...state,
        participants: state.participants.filter(p => p.id !== action.participant.id),
        joined: newJoined,
      };
    }
    case EVidyoParticipantsActions.DYNAMIC_PARTICIPANT:
      return {
        ...state,
        dynamic: action.dynamic,
        loudest: action.dynamic.length === 0 ? undefined : state.loudest,
      };
    case EVidyoParticipantsActions.LOUDEST_PARTICIPANT:
      return {
        ...state,
        loudest: action.loudest,
      };
    default:
      return state;
  }
}
