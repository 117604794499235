import { ChangeEvent, KeyboardEvent, ReactNode, useCallback, useEffect, useState } from "react";

import { Field, Input } from "packages/catalog";

import styles from "./NewSelectList.module.scss";

// a temporary copy of the old SelectList component to handle new graphql updates and adjust behaviour accordingly before all instances are updated to same graphql use

export interface INewSelectListData {
  id: number | string;
}

export interface PNewSelectList<T> {
  closeIfNoInput?: boolean;
  data: readonly T[];
  description?: string;
  disabled?: boolean;
  ignored?: T[];
  isLoading?: boolean;
  label: string;
  onSearch: (search: string) => void;
  onSelect?: (selected: T[]) => void;
  placeholder?: string;
  preSelected?: T[];
  renderDataAs: (data: T[], addItem: (item: T) => void) => ReactNode;
  renderSelectedAs?: (data: T[], removeItem: (item: INewSelectListData["id"]) => void) => ReactNode;
  searchString: string;
}

export function NewSelectList<T extends INewSelectListData>({
  closeIfNoInput,
  data,
  description,
  disabled,
  ignored,
  isLoading,
  label,
  onSearch,
  onSelect,
  placeholder,
  preSelected,
  renderDataAs,
  renderSelectedAs,
  searchString,
}: PNewSelectList<T>) {
  /**
   * Selected is cloning original objects so we can cache it as we're dynamically using full-text search
   */
  const [selected, setSelected] = useState(preSelected || []);
  const [isOpen, setIsOpen] = useState(false as boolean);
  const notSelected = data.filter(d => {
    if (!selected || (selected && !selected.find(s => s.id === d.id))) {
      if (!ignored || (ignored && !ignored.find(s => s.id === d.id))) {
        return d;
      }
    }
  });

  useEffect(() => {
    if (preSelected?.length) {
      setSelected(preSelected);
    } else {
      setSelected([]);
    }
  }, [preSelected, setSelected]);

  const handleChangeSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onSearch(e.currentTarget.value);
      if (closeIfNoInput && !e.currentTarget.value.length) return setIsOpen(false);
    },
    [closeIfNoInput, onSearch],
  );

  function _addItem(item: T): void {
    if (notSelected.length && !selected.find(s => s.id === item.id) && !isLoading) {
      const newSelected = [...selected, item];
      setSelected(newSelected);
      onSearch("");
      if (onSelect) onSelect(newSelected);
      setIsOpen(false);
    }
  }

  const handleUnselect = useCallback(
    (itemID: INewSelectListData["id"]): void => {
      const matchedItem = selected.find(s => s.id === `${itemID}`);
      if (matchedItem && !isLoading) {
        const newSelected = selected.filter(s => s.id !== matchedItem.id);
        setSelected(newSelected);
        if (onSelect) onSelect(newSelected);
      }
    },
    [isLoading, onSelect, selected],
  );

  const handleFocus = useCallback(() => {
    if (!isOpen && !disabled) {
      if (!closeIfNoInput || (closeIfNoInput && searchString.length)) setIsOpen(true);
    }
  }, [closeIfNoInput, disabled, isOpen, searchString.length]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") e.preventDefault();
      if (!isOpen) return handleFocus();
    },
    [handleFocus, isOpen],
  );

  return (
    <div className={styles.wrapper}>
      <Field description={description} label={label}>
        <Input
          disabled={disabled}
          name={label}
          onChange={handleChangeSearch}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          type="search"
          value={searchString}
        />
      </Field>
      <ul className={styles.results} style={!notSelected.length || !isOpen || isLoading ? { display: "none" } : {}}>
        {renderDataAs(notSelected, _addItem)}
      </ul>
      {renderSelectedAs && selected?.length > 0 && renderSelectedAs(selected, handleUnselect)}
    </div>
  );
}
