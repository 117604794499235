import { v4 as uuid } from "uuid";

import {
  ESnackbarFeedbackActions,
  ISnackbarFeedbackState,
  TSnackbarFeedbackAction,
} from "packages/client/snackbar-feedback/redux/types";

export const initialSnackbarFeedbackState: ISnackbarFeedbackState = {
  snackbars: [],
};

export function snackbarFeedbackReducer(
  prevState = initialSnackbarFeedbackState,
  action: TSnackbarFeedbackAction,
): ISnackbarFeedbackState {
  switch (action.type) {
    case ESnackbarFeedbackActions.AddSnackbar: {
      const id = uuid();
      return {
        ...prevState,
        snackbars: [
          ...prevState.snackbars,
          {
            ...action.data,
            id: id,
            actions: action.data.actions?.map((action, index) => {
              action.id = `${id}-${index}`;
              return action;
            }),
          },
        ],
      };
    }
    case ESnackbarFeedbackActions.RemoveSnackbar:
      return {
        ...prevState,
        snackbars: prevState.snackbars.filter(notification => action.id !== notification.id),
      };
    default:
      return prevState;
  }
}
