import type { HTMLProps } from "react";

import type { Icon } from "packages/catalog";
import { useMergedClassNames } from "packages/utils";

import styles from "./H1.module.scss";

export interface PH1 extends HTMLProps<HTMLHeadingElement> {
  center?: boolean;
  hasMargin?: boolean;
  icon?: ReactChild<typeof Icon>;
}

export function H1({ center, children, className, hasMargin, icon, ...rest }: PH1) {
  const h1Classes = useMergedClassNames({ className }, [
    styles.h1,
    hasMargin && styles.hasMargin,
    center && styles.center,
  ]);

  return (
    <h1 {...rest} className={h1Classes}>
      {icon}
      {children}
    </h1>
  );
}
