import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  AnswerResult,
  Cell,
  ConfirmActionModal,
  Feedback,
  Grid,
  H1,
  H2,
  IconButton,
  TextButton,
  Tooltip,
} from "packages/catalog";
import {
  copyString,
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  PATH_POLL_VOTE,
  TParams,
} from "packages/utils";

import { PollStatusV2 } from "packages/client/graphql_definitions.generated";
import {
  GetAllPollsDocument,
  GetEssentialPollDocument,
  useDeletePollMutation,
  useGetEssentialPollQuery,
  useGetPollResponsesQuery,
  useUpdatePollStatusMutation,
} from "packages/client/polls/graphql/polls.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { Badge, QuestionResult } from "packages/client/polls/components";

import { useSnackbarFeedbackContext } from "packages/client/snackbar-feedback/contexts";

import styles from "./Poll.module.scss";

export function Poll() {
  const { pollID }: TParams = useParams();
  const navigate = useNavigate();
  const { dispatchSnackbar, dispatchRetrySnackbar } = useSnackbarFeedbackContext();

  const { data: getPollData, error: getPollError } = useGetEssentialPollQuery({
    variables: {
      pollID,
    },
  });

  const { data: getPollResponsesData } = useGetPollResponsesQuery({
    variables: {
      pollID,
    },
  });

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmPublishModal, setShowConfirmPublishModal] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const pollTitle = useMemo(() => getPollData?.getPollV2?.questions[0]?.value, [getPollData?.getPollV2?.questions]);
  const pollStatus = useMemo(() => getPollData?.getPollV2?.status, [getPollData?.getPollV2?.status]);

  const questions = useMemo(() => {
    const votesByAnswerID = getPollResponsesData?.getResponsesByPollIdV2?.items?.reduce<Record<string, number>>(
      (allItems, item) =>
        item.answers.reduce(
          (allAnswers, answer) =>
            answer.answerOptionIds.reduce((allAnswerOptionIDs, answerOptionID) => {
              if (!allAnswerOptionIDs[answerOptionID]) allAnswerOptionIDs[answerOptionID] = 0;
              allAnswerOptionIDs[answerOptionID]++;
              return allAnswerOptionIDs;
            }, allAnswers),
          allItems,
        ),
      {},
    );

    const totalResponses = getPollResponsesData?.getResponsesByPollIdV2?.items.length ?? 0;

    return getPollData?.getPollV2?.questions?.map(question => {
      return (
        // loop through this question's answer options
        <QuestionResult key={question.id} question={question.value}>
          {question?.answerOptions?.map((answerOption, i) => {
            const votes = votesByAnswerID?.[answerOption.id] ?? 0;
            const percentage = totalResponses > 0 ? Math.round((votes / totalResponses) * 100) : 0;

            return <AnswerResult answer={answerOption.value} key={i} percentage={percentage} votes={votes} />;
          })}
        </QuestionResult>
      );
    });
  }, [getPollData?.getPollV2?.questions, getPollResponsesData?.getResponsesByPollIdV2?.items]);

  const [deletePoll] = useDeletePollMutation({
    onCompleted: () => {
      navigate("..", { state: { pollName: pollTitle } });
    },
    onError: () =>
      dispatchRetrySnackbar("Unable to delete poll.", () =>
        deletePoll({
          variables: { pollID },
          refetchQueries: [
            {
              query: GetAllPollsDocument,
            },
          ],
        }),
      ),
  });

  const handleDeletePoll = useCallback(() => {
    setShowConfirmDeleteModal(false);
    deletePoll({
      variables: { pollID },
      refetchQueries: [
        {
          query: GetAllPollsDocument,
        },
      ],
    });
  }, [deletePoll, pollID]);

  const [endPoll] = useUpdatePollStatusMutation({
    onCompleted: () => dispatchSnackbar({ content: "Poll ended." }),
    onError: () =>
      dispatchRetrySnackbar("Unable to end poll.", () =>
        endPoll({
          variables: { pollID: pollID, status: PollStatusV2.Ended },
          refetchQueries: [GetEssentialPollDocument],
        }),
      ),
  });

  const handleEndPoll = useCallback(
    () =>
      endPoll({
        variables: { pollID: pollID, status: PollStatusV2.Ended },
        refetchQueries: [GetEssentialPollDocument],
      }),
    [endPoll, pollID],
  );

  const [publishPoll, { loading: isPublishing }] = useUpdatePollStatusMutation({
    onCompleted: () => dispatchSnackbar({ content: "Poll published." }),
    onError: () =>
      dispatchRetrySnackbar("Unable to publish poll.", () =>
        publishPoll({
          variables: { pollID, status: PollStatusV2.Published },
          refetchQueries: [GetEssentialPollDocument],
        }),
      ),
  });

  const handlePublishPoll = useCallback(() => {
    setShowConfirmPublishModal(false);
    publishPoll({ variables: { pollID, status: PollStatusV2.Published }, refetchQueries: [GetEssentialPollDocument] });
  }, [pollID, publishPoll]);

  const handleCopyLink = useCallback(() => {
    const link = `https://${DOMAIN}${PATH_POLL_VOTE(pollID)} `;
    copyString(link);
    setIsLinkCopied(true);
  }, [pollID]);

  useEffect(() => {
    if (!isLinkCopied) return;
    const timeoutId = window.setTimeout(() => setIsLinkCopied(false), 3 * 1000);
    return () => window.clearTimeout(timeoutId);
  }, [isLinkCopied]);

  return (
    <>
      {getPollError &&
        (getPollError.message === "Poll could not be found." ? (
          <Feedback status={EStatus.DANGER}>Poll could not be found.</Feedback>
        ) : (
          <Feedback status={EStatus.DANGER}>Something went wrong.</Feedback>
        ))}
      {getPollData && getPollResponsesData && (
        <>
          <RoutingChangeHandler message="The poll page has loaded." pageTitle="Poll" />
          <Grid>
            <Cell className={styles.options}>
              <IconButton
                appearance={EAppearance.GHOST}
                as={Link}
                description={"Back to all polls"}
                dimension={EDimension.SMALL}
                label="Back to all polls"
                icon={EIcon.ARROWLEFT}
                status={EStatus.NEUTRAL}
                to=".."
              />
              {(getPollData?.getPollV2.status === PollStatusV2.Ended ||
                getPollData?.getPollV2.status === PollStatusV2.Draft) && (
                <TextButton
                  disabled={isPublishing}
                  onClick={() => setShowConfirmPublishModal(true)}
                  status={EStatus.ACCENT}>
                  {isPublishing ? "Publishing poll..." : "Publish poll"}
                </TextButton>
              )}
            </Cell>
            <Cell>
              <Badge status={pollStatus} />
            </Cell>
            <Cell>
              <Grid className={styles.results}>
                <Cell as="section" className={styles.answers} colSpanLG={8} colSpanXXL={6}>
                  <H1 hasMargin>{pollTitle}</H1>
                  {questions}
                </Cell>
                <Cell as="section" className={styles.overview} colSpanLG={4} colSpanXXL={3}>
                  <p className={styles.number}>{getPollResponsesData.getResponsesByPollIdV2.count}</p>
                  <p className={styles.text}>participants</p>
                </Cell>
                <Cell as="section" className={styles.manage} colSpanLG={4}>
                  <H2 hasMargin>Manage poll</H2>
                  <Grid as="ul" noColGap>
                    {getPollData?.getPollV2.status === PollStatusV2.Published && (
                      <Cell as="li">
                        <Tooltip
                          attachment={EAttachment.NORTH}
                          isActive={!isLinkCopied}
                          targetName={"Copy sharing link"}
                          tip="Link copied!"
                          trigger={
                            <IconButton
                              appearance={EAppearance.OUTLINE}
                              as="button"
                              description="Copy sharing link"
                              dimension={EDimension.SMALL}
                              icon={EIcon.LINK}
                              onClick={handleCopyLink}
                              status={EStatus.NEUTRAL}
                              label="Copy sharing link"
                            />
                          }
                        />
                      </Cell>
                    )}
                    {/* {getPollData.getPollV2.status === "PUBLISHED" && (
                    <Cell as="li">
                      <IconButton
                        appearance={EAppearance.OUTLINE}
                        as={Link}
                        description="Submit your vote"
                        dimension={EDimension.SMALL}
                        icon={EIcon.CHECKSQUARE}
                        label="Submit your vote"
                        status={EStatus.NEUTRAL}
                      />
                    </Cell>
                  )} */}
                    {/* {getPollData.getPollV2.status === ("PUBLISHED" || "ENDED") && (
                    <Cell as="li">
                      <IconButton
                        appearance={EAppearance.OUTLINE}
                        as="button"
                        description="Download results"
                        dimension={EDimension.SMALL}
                        icon={EIcon.DOWNLOAD}
                        label="Download results"
                        status={EStatus.NEUTRAL}
                      />
                    </Cell>
                  )} */}
                    {getPollData?.getPollV2.status === PollStatusV2.Published && (
                      <Cell as="li">
                        <IconButton
                          appearance={EAppearance.OUTLINE}
                          as="button"
                          description="End poll"
                          dimension={EDimension.SMALL}
                          icon={EIcon.SLASH}
                          label="End poll"
                          status={EStatus.NEUTRAL}
                          onClick={handleEndPoll}
                        />
                      </Cell>
                    )}
                    {/* {getPollData.getPollV2.status === "DRAFT" && (
                    <Cell as="li">
                      <IconButton
                        appearance={EAppearance.OUTLINE}
                        as={Link}
                        description="Edit poll"
                        dimension={EDimension.SMALL}
                        icon={EIcon.EDIT}
                        label="Edit poll"
                        status={EStatus.NEUTRAL}
                      />
                    </Cell>
                  )} */}
                    {/* <Cell as="li">
                    <IconButton
                      appearance={EAppearance.OUTLINE}
                      as="button"
                      description="Duplicate poll"
                      dimension={EDimension.SMALL}
                      icon={EIcon.COPY}
                      label="Duplicate poll"
                      status={EStatus.NEUTRAL}
                    />
                  </Cell> */}
                    <Cell as="li">
                      <IconButton
                        appearance={EAppearance.OUTLINE}
                        as="button"
                        description="Delete poll"
                        dimension={EDimension.SMALL}
                        icon={EIcon.TRASH2}
                        label="Delete poll"
                        status={EStatus.NEUTRAL}
                        onClick={() => setShowConfirmDeleteModal(true)}
                      />
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </>
      )}
      <ConfirmActionModal
        action="publish"
        content="poll"
        isOpen={showConfirmPublishModal}
        onAbort={() => setShowConfirmPublishModal(false)}
        onAction={handlePublishPoll}
      />
      <ConfirmActionModal
        action="delete"
        content="poll"
        isOpen={showConfirmDeleteModal}
        onAbort={() => setShowConfirmDeleteModal(false)}
        onAction={handleDeletePoll}
        status={EStatus.DANGER}
      />
    </>
  );
}
