// url segments, used for linking down a level
export const SEGMENT_ADD = "add";
export const SEGMENT_ABOUT = "about";
export const SEGMENT_ALL = "all";
export const SEGMENT_ANNOUNCEMENTS = "announcements";
export const SEGMENT_AUTH = "auth";
export const SEGMENT_BACKGROUNDEFFECTS = "background-effects";
export const SEGMENT_CALL = "call";
export const SEGMENT_CALLS = "calls";
export const SEGMENT_CALLLINK = "r";
export const SEGMENT_CHANGEPASSWORD = "change-password";
export const SEGMENT_CHAT = "chat";
export const SEGMENT_CHILDREN = "children";
export const SEGMENT_CONNECTIONS = "connections";
export const SEGMENT_CREATE = "create";
export const SEGMENT_DEVICES = "devices";
export const SEGMENT_DELETE = "delete";
export const SEGMENT_DIRECTORY = "directory";
export const SEGMENT_EDIT = "edit";
export const SEGMENT_EMAILCONFIRMATION = "email-confirmation";
export const SEGMENT_ENTERCODE = "enter-code";
export const SEGMENT_EXTENDCALL = "extend-call";
export const SEGMENT_FEATUREFLAGS = "feature-flags";
export const SEGMENT_FEEDBACK = "feedback";
export const SEGMENT_FORGOTPASSWORD = "forgot-password";
export const SEGMENT_HUBS = "hubs";
export const SEGMENT_JOINCALL = "join-call";
export const SEGMENT_JOINOPTIONS = "join-options";
export const SEGMENT_JOINMETHOD = "join-method";
export const SEGMENT_LAYOUT = "layout";
export const SEGMENT_MEMBERS = "members";
export const SEGMENT_ORGANISATIONS = "organisations";
export const SEGMENT_OVERVIEW = "overview";
export const SEGMENT_PARTICIPANTS = "participants";
export const SEGMENT_PENDING = "pending";
export const SEGMENT_POLLS = "polls";
export const SEGMENT_POSTCALL = "postcall";
export const SEGMENT_PRECALL = "precall";
export const SEGMENT_PROFILE = "profile";
export const SEGMENT_RECORDINGS = "recordings";
export const SEGMENT_REGISTER = "register";
export const SEGMENT_REPLAY = "replay";
export const SEGMENT_REPORT = "report";
export const SEGMENT_RESETPASSWORD = "reset-password";
export const SEGMENT_ROOMSYSTEMS = "room-systems";
export const SEGMENT_SCHEDULE = "schedule";
export const SEGMENT_SEARCH = "search";
export const SEGMENT_SETTINGS = "settings";
export const SEGMENT_SESSIONS = "sessions";
export const SEGMENT_SHARE = "share";
export const SEGMENT_SIGNIN = "sign-in";
export const SEGMENT_SIGNOUT = "sign-out";
export const SEGMENT_SUPERADMIN = "super-admin";
export const SEGMENT_USERS = "users";
export const SEGMENT_VOTE = "vote";
export const SEGMENT_WAITINGROOM = "waiting-room";
export const SEGMENT_WEBCAST = "webcast";
export const SEGMENT_WEBCASTPORTAL = "webcastportal";
export const SEGMENT_WORKSPACES = "workspaces";

// static direct paths, used for linking BETWEEN/ACROSS route sections (includes slashes)
export const PATH_CONNECTIONS = `/${SEGMENT_CONNECTIONS}`;
export const PATH_FEEDBACK = `/${SEGMENT_FEEDBACK}`;
export const PATH_ORGANISATIONS = `/${SEGMENT_ORGANISATIONS}`;
export const PATH_POLLS = `/${SEGMENT_POLLS}`;
export const PATH_PRECALL_WAITINGROOM = `/${SEGMENT_PRECALL}/${SEGMENT_WAITINGROOM}`;
export const PATH_PROFILE_CHANGEPASSWORD = `/${SEGMENT_PROFILE}/${SEGMENT_CHANGEPASSWORD}`;
export const PATH_SESSIONS = `/${SEGMENT_SESSIONS}`;
export const PATH_SESSIONS_SCHEDULE = `/${SEGMENT_SESSIONS}/${SEGMENT_SCHEDULE}`;
export const PATH_PROFILE_EDIT = `/${SEGMENT_PROFILE}/${SEGMENT_EDIT}`;
export const PATH_PROFILE_SETTINGS = `/${SEGMENT_PROFILE}/${SEGMENT_SETTINGS}`;
export const PATH_REGISTER = `/${SEGMENT_REGISTER}`;
export const PATH_SUPERADMIN = `/${SEGMENT_SUPERADMIN}`;
export const PATH_SUPERADMIN_ORGANISATIONS = `/${SEGMENT_SUPERADMIN}/${SEGMENT_ORGANISATIONS}`;
export const PATH_SUPERADMIN_ORGANISATIONS_DELETE = `/${SEGMENT_SUPERADMIN}/${SEGMENT_ORGANISATIONS}/${SEGMENT_DELETE}`;
export const PATH_SUPERADMIN_ORGANISATIONS_PENDING = `/${SEGMENT_SUPERADMIN}/${SEGMENT_ORGANISATIONS}/${SEGMENT_PENDING}`;
export const PATH_SUPERADMIN_SESSIONS = `/${SEGMENT_SUPERADMIN}/${SEGMENT_SESSIONS}`;
export const PATH_TLD = "/";
export const PATH_SUPERADMIN_USERS = `/${SEGMENT_SUPERADMIN}/${SEGMENT_USERS}`;
export const PATH_SUPERADMIN_USERS_PENDING = `/${SEGMENT_SUPERADMIN}/${SEGMENT_USERS}/${SEGMENT_PENDING}`;
export const PATH_USERS = `/${SEGMENT_USERS}`;

// dynamic paths to exact content pieces as needed, used for linking BETWEEN/ACROSS route sections (includes slashes)
export const PATH_CALLLINK = (callID: string) => `/${SEGMENT_CALLLINK}/${callID}`;
export const PATH_INCALL = (callID: string) => `/${SEGMENT_CALL}/${callID}`;
export const PATH_CALL_PARTICIPANTS = (callID: string) => `/${SEGMENT_CALL}/${callID}/${SEGMENT_PARTICIPANTS}`;
export const PATH_CALL_SETTINGS_EXTENDCALL = (callID: string) =>
  `/${SEGMENT_CALL}/${callID}/${SEGMENT_SETTINGS}/${SEGMENT_EXTENDCALL}`;
export const PATH_ORGANISATION = (organisationID: string) => `/${SEGMENT_ORGANISATIONS}/${organisationID}`;
export const PATH_ORGANISATION_EDIT = (organisationID: string) =>
  `/${SEGMENT_ORGANISATIONS}/${organisationID}/${SEGMENT_EDIT}`;
export const PATH_ORGANISATION_REPORT = (organisationID: string) =>
  `/${SEGMENT_ORGANISATIONS}/${organisationID}/${SEGMENT_REPORT}`;
export const PATH_POLL = (pollID: string) => `/${SEGMENT_POLLS}/${pollID}`;
export const PATH_POLL_VOTE = (pollID: string) => `/${SEGMENT_POLLS}/${pollID}/${SEGMENT_VOTE}`;
export const PATH_POSTCALL = (callID: string) => `/${SEGMENT_POSTCALL}/${callID}`;
export const PATH_RECORDING = (recordingID: string) => `/${SEGMENT_REPLAY}/${recordingID}`;
export const PATH_ROOMSYSTEM = (roomSystemID: string) => `/${SEGMENT_ROOMSYSTEMS}/${roomSystemID}`;
export const PATH_ROOMSYSTEM_EDIT = (roomSystemID: string) => `${PATH_ROOMSYSTEM(roomSystemID)}/${SEGMENT_EDIT}`;
export const PATH_SESSION = (sessionID: string) => `/${SEGMENT_SESSIONS}/${sessionID}`;
export const PATH_SESSION_EDIT = (sessionID: string) => `${PATH_SESSION(sessionID)}/${SEGMENT_EDIT}`;
export const PATH_SUPERADMIN_ORGANISATION_OVERVIEW = (organisationID: string) =>
  `/${SEGMENT_SUPERADMIN}/${SEGMENT_ORGANISATIONS}/${organisationID}/${SEGMENT_OVERVIEW}`;
export const PATH_SUPERADMIN_ORGANISATION = (organisationID: string) =>
  `/${SEGMENT_SUPERADMIN}/${SEGMENT_ORGANISATIONS}/${organisationID}`;
export const PATH_SUPERADMIN_ORGANISATION_REPORT = (organisationID: string) =>
  `/${SEGMENT_SUPERADMIN}/${SEGMENT_ORGANISATIONS}/${organisationID}/${SEGMENT_REPORT}`;
export const PATH_SUPERADMIN_ROOMSYSTEM = (roomSystemID: string) =>
  `/${SEGMENT_SUPERADMIN}/${SEGMENT_ROOMSYSTEMS}/${roomSystemID}`;
export const PATH_SUPERADMIN_ROOMSYSTEM_EDIT = (roomSystemID: string) =>
  `${PATH_SUPERADMIN_ROOMSYSTEM(roomSystemID)}/${SEGMENT_EDIT}`;
export const PATH_SUPERADMIN_SESSION = (sessionID: string) => `/${SEGMENT_SUPERADMIN}/${SEGMENT_SESSIONS}/${sessionID}`;
export const PATH_SUPERADMIN_SESSION_EDIT = (sessionID: string) =>
  `${PATH_SUPERADMIN_SESSION(sessionID)}/${SEGMENT_EDIT}`;
export const PATH_SUPERADMIN_USER = (userID: string) => `/${SEGMENT_SUPERADMIN}/${SEGMENT_USERS}/${userID}`;
export const PATH_USER = (userID: string) => `/${SEGMENT_USERS}/${userID}`;
export const PATH_WEBCAST = (webcastID: string) => `/${SEGMENT_WEBCAST}/${webcastID}`;
export const PATH_WEBCAST_PORTAL = (webcastID: string) => `/${SEGMENT_WEBCASTPORTAL}/${webcastID}`;
export const PATH_WORKSPACE = (workspaceID: string) => `/${SEGMENT_WORKSPACES}/${workspaceID}`;

// dynamic paths to routes that may need an optional param
export const PATH_FORGOTPASSWORD = (callID?: string) => `/${SEGMENT_FORGOTPASSWORD}${addOptionalParam(callID)}`;
export const PATH_JOINOPTIONS = (callID?: string) => `/${SEGMENT_JOINOPTIONS}${addOptionalParam(callID)}`;
export const PATH_PRECALL_DEVICES = (callID?: string) =>
  `/${SEGMENT_PRECALL}/${SEGMENT_DEVICES}${addOptionalParam(callID)}`;
export const PATH_PRECALL_JOINCALL = (callID?: string) =>
  `/${SEGMENT_PRECALL}/${SEGMENT_JOINCALL}${addOptionalParam(callID)}`;
export const PATH_RESETPASSWORD = (callID?: string) => `/${SEGMENT_RESETPASSWORD}${addOptionalParam(callID)}`;
export const PATH_SIGNIN = (callID?: string) => `/${SEGMENT_SIGNIN}${addOptionalParam(callID)}`;

// a util function to add an optional parameter to the path. used mostly for precall where we don't know if the route will have a callID param
const addOptionalParam = (id: string) => (id ? `/${id}` : "");
