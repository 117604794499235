import { simpleThrottle } from "packages/utils";

import { EVidyoParticipantsActions } from "../../participants/redux/types";

import { dispatchVidyo } from "../../redux";
import type { TVidyoConnector } from "../types";
import type { TVidyoParticipant } from "../../participants/types/TVidyoParticipant";

const changeLoudest = (loudest: TVidyoParticipant) =>
  dispatchVidyo({ type: EVidyoParticipantsActions.LOUDEST_PARTICIPANT, loudest });

const debouncedLoudest = simpleThrottle(changeLoudest, 3 * 1000, {
  runLastCall: true,
});

export async function createParticipantsLifecycle(vidyoConnector: TVidyoConnector) {
  await vidyoConnector.RegisterParticipantEventListener({
    onJoined: part =>
      dispatchVidyo({
        type: EVidyoParticipantsActions.ADD_PARTICIPANT,
        participant: part,
        joined: new Date().toISOString(),
      }),
    onLeft: part => dispatchVidyo({ type: EVidyoParticipantsActions.REMOVE_PARTICIPANT, participant: part }),
    onDynamicChanged: parts => dispatchVidyo({ type: EVidyoParticipantsActions.DYNAMIC_PARTICIPANT, dynamic: parts }),
    onLoudestChanged: debouncedLoudest,
  });
}
