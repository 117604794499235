import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";

import { Cell, Dropdown, Field, Flexbox, Grid, Input, InputOption } from "packages/catalog";
import {
  formatISOShortDate,
  formatLongTime,
  generateTimeSlots,
  isNowAfterDate,
  isNowAfterTime,
  isToday,
  ITimeSlot,
  MAXIMUM_DATETIME,
} from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import { changeEndTime, changeStartTime, toggleIsRecurring } from "packages/client/sessions/redux/slice";

export interface PEditDateTime {
  existingEndDateTime?: string;
  existingStartDateTime?: string;
  isEditMode?: boolean;
  shouldRefresh?: boolean;
}

function getMaximumPossibleStartDateTime(startDateTime: Date): Date {
  return new Date(new Date(startDateTime).setHours(23, 50, 0, 0));
}

function getMaximumPossibleEndDateTime(startDateTime: Date): Date {
  return new Date(new Date(startDateTime).setHours(23, 55, 0, 0));
}

function getSlots(startSlotsStart: Date): [ITimeSlot[], ITimeSlot[]] {
  const endSlotsStart = new Date(new Date(startSlotsStart).setMinutes(startSlotsStart.getMinutes() + 5));
  const startSlots = generateTimeSlots(startSlotsStart, getMaximumPossibleStartDateTime(startSlotsStart));
  const endSlots = generateTimeSlots(endSlotsStart, getMaximumPossibleEndDateTime(endSlotsStart));
  return [startSlots, endSlots];
}

function getHourMinutes(d: Date) {
  return formatLongTime(d).slice(0, 5);
}

export function EditDateTime({ existingEndDateTime, existingStartDateTime, isEditMode, shouldRefresh }: PEditDateTime) {
  const dispatch = useTypedDispatch();
  const { recurringInterval } = useTypedSelector(({ sessionForm }) => ({
    recurringInterval: sessionForm.recurringInterval,
  }));

  // array of time options in the time dropdowns
  const [startSlots, setStartSlots] = useState<ITimeSlot[]>();
  const [endSlots, setEndSlots] = useState<ITimeSlot[]>();

  // the current selected start and end timeslots in the time dropdowns
  const [selectedStartTimeslot, setSelectedStartTimeslot] = useState<ITimeSlot>();
  const [selectedEndTimeslot, setSelectedEndTimeslot] = useState<ITimeSlot>();

  // functions to handle updating the internal form state AND the redux store with current meeting data
  const updateSelectedStartTimeslot = useCallback(
    (slot: ITimeSlot): void => {
      setSelectedStartTimeslot(slot);
      dispatch(changeStartTime(slot.date));
    },
    [dispatch],
  );

  const updateSelectedEndTimeslot = useCallback(
    (slot: ITimeSlot): void => {
      setSelectedEndTimeslot(slot);
      dispatch(changeEndTime(slot.date));
    },
    [dispatch],
  );

  const handleChangeStartDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const selectedDate = e.currentTarget.valueAsDate;
      const isSelectedDateTimeToday = isToday(selectedDate);

      const currentStartDateTime = selectedStartTimeslot.date;
      const currentEndDateTime = selectedEndTimeslot.date;

      const meetingLengthInMs = currentEndDateTime.getTime() - currentStartDateTime.getTime();

      // make time on the new chosen date the same time as the current selected time
      const newStartDateTime = new Date(
        new Date(selectedDate).setHours(currentStartDateTime.getHours(), currentStartDateTime.getMinutes()),
      );
      const newEndDateTime = new Date(newStartDateTime.getTime() + meetingLengthInMs);

      // create new slots using selected new date
      const [startSlotsTemp] = getSlots(isSelectedDateTimeToday ? new Date() : selectedDate); // ternary to make sure if today the slots starts at current time and not midnight
      const endSlotsTemp = generateTimeSlots(newStartDateTime, getMaximumPossibleEndDateTime(newStartDateTime));

      // update the slots
      setStartSlots(startSlotsTemp);
      setEndSlots(endSlotsTemp);

      // selected the correct new slot for start and end with new selected date
      updateSelectedStartTimeslot(
        !isSelectedDateTimeToday || !isNowAfterTime(currentStartDateTime)
          ? startSlotsTemp.find(s => s.date.toString() === newStartDateTime.toString())
          : startSlotsTemp[0],
      );
      updateSelectedEndTimeslot(
        !isSelectedDateTimeToday || !isNowAfterTime(currentStartDateTime)
          ? endSlotsTemp.find(s => s.date.toString() === newEndDateTime.toString())
          : endSlotsTemp[0],
      );
    },
    [updateSelectedEndTimeslot, updateSelectedStartTimeslot, selectedEndTimeslot, selectedStartTimeslot],
  );

  const recalculateEndSlots = useCallback(
    (newStartDateTime: Date, currentEndDateTime: Date) => {
      if (!newStartDateTime) return;
      if (!currentEndDateTime) return;

      const maximumPossibleEndDateTime = getMaximumPossibleEndDateTime(newStartDateTime);
      const newEndSlots = generateTimeSlots(newStartDateTime, maximumPossibleEndDateTime);

      setEndSlots(newEndSlots);

      const startPlusOneHour = new Date(new Date(newStartDateTime).setMinutes(newStartDateTime.getMinutes() + 60));

      // if adding an hour to the new start time will change the date to the next day, set the max end datetime to same day 23:55, else, use startPlusOneHour
      const newEndDateTime: Date =
        startPlusOneHour.toLocaleDateString() !== newStartDateTime.toLocaleDateString()
          ? maximumPossibleEndDateTime
          : startPlusOneHour;

      // update the selected end timeslot to be the new, calcuated one
      updateSelectedEndTimeslot(newEndSlots.find(newEndSlot => newEndSlot.date.valueOf() === newEndDateTime.valueOf()));
    },
    [updateSelectedEndTimeslot],
  );

  // if no preexisting start time, use today, else if existing start time exists and has passed (meaning it's a repeating meeting previously created) use it, else use today (phew!)
  const minimumPossibleStartDate = useMemo(
    () =>
      !existingStartDateTime
        ? formatISOShortDate(new Date())
        : isNowAfterDate(new Date(existingStartDateTime))
          ? formatISOShortDate(new Date(existingStartDateTime))
          : formatISOShortDate(new Date()),
    [existingStartDateTime],
  );

  const optionsStart = useMemo(() => {
    if (!startSlots) return;

    return startSlots.reduce<Record<string, string>>((acc, s) => {
      return {
        ...acc,
        [s.id]: `${getHourMinutes(s.date)}`,
      };
    }, {});
  }, [startSlots]);

  const optionsEnd = useMemo(() => {
    if (!endSlots) return;

    return endSlots.reduce<Record<string, string>>((acc, s) => {
      return {
        ...acc,
        [s.id]: `${getHourMinutes(s.date)}`,
      };
    }, {});
  }, [endSlots]);

  const handleChangeStartTimeslot = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (!selectedEndTimeslot) return;

      const key = e.currentTarget.value;
      const timeStamp = parseInt(key);

      const newStartDateTime = new Date(timeStamp);
      updateSelectedStartTimeslot({ id: timeStamp, date: newStartDateTime });
      recalculateEndSlots(newStartDateTime, selectedEndTimeslot.date);
    },
    [recalculateEndSlots, selectedEndTimeslot, updateSelectedStartTimeslot],
  );

  const handleChangeEndTimeslot = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const key = e.currentTarget.value;
      const timeStamp = parseInt(key);
      updateSelectedEndTimeslot({ id: timeStamp, date: new Date(timeStamp) });
    },
    [updateSelectedEndTimeslot],
  );

  // initialise the form and calculate the first lot of timeslots to display based on existing data (edit) or today's date and time (schedule)
  useEffect(() => {
    const calculateInitialTimeSlots = () => {
      const hasExistingMeetingData = existingStartDateTime ? true : false;
      const now = new Date();

      // if we have no start date/time coming from existing meeting data, use today's date/current time, else, calculate slots from midnight of the existing date to allow all times to be possible in the dropdown for editing
      const [startSlotsTemp, endSlotsTemp] = getSlots(
        !hasExistingMeetingData ? now : new Date(new Date(existingStartDateTime).setHours(0, 0, 0)),
      );

      // set the start and end timeslots into the form state
      setStartSlots(startSlotsTemp);
      setEndSlots(endSlotsTemp);

      // choose the selected start slot based on preexisting starttime, or first timeslot option if scheduling
      updateSelectedStartTimeslot(
        hasExistingMeetingData
          ? startSlotsTemp.find(timeslot => timeslot.date.toString() === new Date(existingStartDateTime).toString())
          : startSlotsTemp[0],
      );

      // choose the selected end slot based on preexisting endtime, or, the first possible start timeslot option plus one hour
      updateSelectedEndTimeslot(
        hasExistingMeetingData
          ? endSlotsTemp.find(timeslot => timeslot.date.toString() === new Date(existingEndDateTime).toString())
          : endSlotsTemp.find(
              timeslot =>
                Date.parse(timeslot.date.toString()) === Date.parse(startSlotsTemp[0].date.toString()) + 1000 * 60 * 60,
            ),
      );
    };

    calculateInitialTimeSlots();
  }, [
    existingEndDateTime,
    existingStartDateTime,
    isEditMode,
    shouldRefresh,
    updateSelectedEndTimeslot,
    updateSelectedStartTimeslot,
  ]);

  return (
    <Cell>
      <Grid alignItemsCenter>
        {selectedStartTimeslot && (
          <Cell colSpan={4} colSpanSM={5} colSpanXL={4}>
            <Field label="Date">
              <Input
                min={minimumPossibleStartDate}
                max={formatISOShortDate(MAXIMUM_DATETIME)}
                name="date"
                onChange={handleChangeStartDate}
                required
                type="date"
                value={formatISOShortDate(selectedStartTimeslot?.date)}
              />
            </Field>
          </Cell>
        )}
        {selectedStartTimeslot && selectedEndTimeslot && (
          <Cell colSpan={8} colSpanSM={7} colSpanXL={5}>
            <Flexbox>
              <Field label="Start time">
                <Dropdown
                  name="start-time"
                  onChange={handleChangeStartTimeslot}
                  options={optionsStart}
                  required
                  value={selectedStartTimeslot.id}
                />
              </Field>
              <Field label="End time">
                <Dropdown
                  name="end-time"
                  onChange={handleChangeEndTimeslot}
                  options={optionsEnd}
                  required
                  value={selectedEndTimeslot.id}
                />
              </Field>
            </Flexbox>
          </Cell>
        )}
        <Cell colSpan={3}>
          <InputOption label="Repeat">
            <Input
              checked={recurringInterval !== null}
              name="repeat"
              onChange={() => dispatch(toggleIsRecurring())}
              type="checkbox"
            />
          </InputOption>
        </Cell>
      </Grid>
    </Cell>
  );
}
