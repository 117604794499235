import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  BigPopoverMenu,
  Block,
  Cell,
  ConfirmActionModal,
  Description,
  ExternalTextLink,
  Feedback,
  Flexbox,
  Grid,
  H1,
  H2,
  H3,
  Icon,
  IconButton,
  IconContent,
  InternalTextLink,
  LabelledContent,
  Loader,
  P,
  PlainAction,
  StatusBadge,
  Strong,
} from "packages/catalog";
import {
  // PATH_WEBCAST_PORTAL,
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  formatLongDate,
  formatLongTime,
  formatLongWordDate,
  formatWeekday,
  isNowAfterDate,
  isNowBetweenStartAndEnd,
  isToday,
  PATH_CALLLINK,
  PATH_SESSIONS,
  scrollToContentTop,
  SEGMENT_EDIT,
  TParams,
} from "packages/utils";

import { ERecurringFrequency } from "packages/client/sessions/redux/types";
import { days, weeklyOccurrencies } from "packages/client/sessions/consts";
import { copySessionURL } from "packages/client/sessions/functions";

import { useGetFullSessionQuery } from "packages/client/sessions/graphql/sessions.generated";
import { useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";
import { useDialIn } from "packages/client/incall/hooks/useDialIn";

import { deleteSession } from "packages/client/sessions/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { InProgress, JoinButtons } from "packages/client/sessions/components";
import { UserAvatarList, UserSummary } from "packages/client/users/components";

import styles from "./Session.module.scss";

function formatWeekdays(weekdays: number[]): string {
  if (weekdays.length === 1) return `${days[weekdays[0]].longName}s`;

  const words = [...weekdays].sort((a, b) => a - b).map(number => days[number].longName);
  const lastWord = words.pop();

  return words.join("s, ").concat("s and ", lastWord, "s");
}

export function Session() {
  const { meetingID }: TParams = useParams();

  const [hidePIN, setHidePIN] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showDeleteErrorMessage, setShowDeleteErrorMessage] = useState(false);

  const { data: sessionData, loading } = useGetFullSessionQuery({ variables: { sessionID: parseInt(meetingID, 10) } });
  const { data: ownUserData } = useGetEssentialOwnUserQuery();
  const dialIn = useDialIn(sessionData?.meeting?.room?.key);

  const navigate = useNavigate();

  const canModerate = useMemo(() => {
    if (!ownUserData?.user) return false;
    if (!sessionData?.meeting) return false;
    if (sessionData?.meeting.meetingOrganiser.id === ownUserData?.user?.id) return true;
    if (sessionData?.meeting.moderators?.map(m => m.id).includes(ownUserData?.user?.id)) return true;
    if (ownUserData?.user?.isAdmin) return true;
    return false;
  }, [ownUserData, sessionData]);
  const endTime = useMemo(
    () => (sessionData?.meeting?.endTime ? new Date(sessionData?.meeting?.endTime) : null),
    [sessionData?.meeting?.endTime],
  );
  const hasSettingsEnabled = useMemo(
    () =>
      sessionData?.meeting?.applicationPreferred ||
      sessionData?.meeting?.isPrivate ||
      sessionData?.meeting?.isRecorded ||
      sessionData?.meeting?.room?.pin,
    [
      sessionData?.meeting?.applicationPreferred,
      sessionData?.meeting?.isPrivate,
      sessionData?.meeting?.isRecorded,
      sessionData?.meeting?.room?.pin,
    ],
  );
  const startTime = useMemo(
    () => (sessionData?.meeting?.startTime ? new Date(sessionData?.meeting?.startTime) : null),
    [sessionData?.meeting?.startTime],
  );

  const handleDeleteSession = useCallback(() => {
    setShowDeleteErrorMessage(false);
    setIsDeleteModalOpen(false);
    deleteSession(meetingID)
      .then(() => {
        scrollToContentTop();
        navigate(PATH_SESSIONS);
      })
      .catch(() => {
        scrollToContentTop();
        setShowDeleteErrorMessage(true);
      });
  }, [meetingID, navigate]);

  const handleTogglePINDisplay = useCallback(() => setHidePIN(!hidePIN), [hidePIN]);
  // const webcastLink =
  // meeting && meeting.isWebcastMode && `/webcastportal/${btoa(`webcast_${meeting.id}_${meeting.startTime}`)}`;
  // const webcastLink = meeting?.isWebcastMode && PATH_WEBCAST_PORTAL(meeting.webcastAlias);

  if (!dialIn) return null;
  if (loading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler message="The session page has loaded." pageTitle="Session" />
      {sessionData?.meeting && startTime && endTime && (
        <>
          <Grid>
            {showDeleteErrorMessage && (
              <Cell>
                <Feedback status={EStatus.DANGER}>Unable to delete session. Please try again.</Feedback>
              </Cell>
            )}
            <Cell>
              <IconButton
                appearance={EAppearance.GHOST}
                as={Link}
                description="Sessions"
                dimension={EDimension.SMALL}
                icon={EIcon.ARROWLEFT}
                label={ownUserData?.user?.isAdmin ? "All sessions" : "Sessions"}
                status={EStatus.NEUTRAL}
                to=".."
              />
            </Cell>
            <Cell>
              <H1>Session</H1>
            </Cell>
            <Cell colSpanMD={8}>
              <Grid noColGap>
                <Cell as={Block}>
                  <Flexbox flexDirectionColumn>
                    <Flexbox justifyContentSpaceBetween>
                      <Flexbox>
                        <div className={styles.dateTime}>
                          <time className={styles.time} dateTime={startTime.toISOString()} title="Time">
                            {formatLongTime(startTime)} - {formatLongTime(endTime)}
                          </time>
                          <InProgress
                            endTime={sessionData?.meeting?.nextRecurringEnd ?? endTime}
                            startTime={sessionData?.meeting?.nextRecurringStart ?? startTime}
                          />
                          {isToday(new Date(sessionData?.meeting?.nextRecurringStart ?? startTime)) ? (
                            <P hasMargin>Today</P>
                          ) : (
                            <>
                              <P>{formatWeekday(new Date(sessionData?.meeting?.nextRecurringStart ?? startTime))}</P>
                              <P hasMargin>
                                {formatLongDate(new Date(sessionData?.meeting?.nextRecurringStart ?? startTime))}
                              </P>
                            </>
                          )}
                          {sessionData?.meeting?.recurringDates?.length && (
                            <IconContent description="Repeats" icon={EIcon.ROTATECW}>
                              <P>Repeats</P>
                            </IconContent>
                          )}
                        </div>
                        <Flexbox flexDirectionColumn>
                          <div>
                            <H2>{sessionData?.meeting?.subject}</H2>
                            {sessionData?.meeting?.description && (
                              <Description>{sessionData?.meeting?.description}</Description>
                            )}
                          </div>
                          <UserSummary
                            email={sessionData?.meeting?.meetingOrganiser.email}
                            fullName={`${sessionData?.meeting?.meetingOrganiser.firstName} ${sessionData?.meeting?.meetingOrganiser.lastName}`}
                            organisationName={sessionData?.meeting?.meetingOrganiser?.organisation?.name}
                            profilePictureURL={sessionData?.meeting?.meetingOrganiser.profilePicture}
                          />
                        </Flexbox>
                      </Flexbox>
                      {canModerate && (
                        <BigPopoverMenu
                          attachment={EAttachment.SOUTH_SOUTH_EAST}
                          trigger={
                            <IconButton
                              as="div"
                              description="Options"
                              status={EStatus.NEUTRAL}
                              icon={EIcon.MOREVERTICAL}
                              appearance={EAppearance.GHOST}
                              dimension={EDimension.SMALL}
                            />
                          }>
                          <Flexbox flexDirectionColumn>
                            {sessionData?.meeting?.nextRecurringStart && sessionData?.meeting?.nextRecurringEnd
                              ? !isNowBetweenStartAndEnd(
                                  new Date(sessionData?.meeting?.nextRecurringStart),
                                  new Date(sessionData?.meeting?.nextRecurringEnd),
                                ) && (
                                  <PlainAction as={Link} to={SEGMENT_EDIT}>
                                    Edit
                                  </PlainAction>
                                )
                              : !isNowBetweenStartAndEnd(
                                  new Date(sessionData?.meeting?.startTime),
                                  new Date(sessionData?.meeting?.endTime),
                                ) && (
                                  <PlainAction as={Link} to={SEGMENT_EDIT}>
                                    Edit
                                  </PlainAction>
                                )}
                            <PlainAction as="button" onClick={() => setIsDeleteModalOpen(true)} status={EStatus.DANGER}>
                              Delete
                            </PlainAction>
                          </Flexbox>
                        </BigPopoverMenu>
                      )}
                    </Flexbox>
                    <JoinButtons
                      canModerate={canModerate}
                      endTime={sessionData?.meeting?.endTime}
                      nextRecurringEnd={sessionData?.meeting?.nextRecurringEnd}
                      nextRecurringStart={sessionData?.meeting?.nextRecurringStart}
                      roomKey={sessionData?.meeting?.room?.key}
                      startTime={sessionData?.meeting?.startTime}
                    />
                    {/* {webcastLink && (
                          <TextButton as={Link} to={webcastLink}>
                            Watch livestream
                          </TextButton>
                        )} */}
                  </Flexbox>
                </Cell>
                {sessionData?.meeting?.recurringDates?.length && (
                  <Cell as={Block}>
                    <H2 hasMargin>Repeating settings</H2>
                    <H3>Overview</H3>
                    <P hasMargin>
                      This session repeats{" "}
                      <Strong>
                        {sessionData?.meeting?.recurringInterval === ERecurringFrequency.Daily
                          ? "daily"
                          : sessionData?.meeting?.recurringInterval === ERecurringFrequency.Weekly &&
                              sessionData?.meeting?.recurringEveryXWeeks === 1
                            ? "weekly"
                            : sessionData?.meeting?.recurringInterval === ERecurringFrequency.Monthly &&
                                sessionData?.meeting?.recurringEveryXMonths === 1
                              ? "monthly"
                              : null}
                      </Strong>
                      {sessionData?.meeting?.recurringOnXWeekOfMonth && (
                        <>
                          {" "}
                          during the{" "}
                          <Strong>
                            {weeklyOccurrencies[sessionData?.meeting?.recurringOnXWeekOfMonth].toLowerCase()}
                          </Strong>{" "}
                          of the month
                        </>
                      )}
                      {sessionData?.meeting?.recurringOnDays && (
                        <>
                          {" "}
                          on <Strong>{formatWeekdays(sessionData?.meeting?.recurringOnDays as number[])}</Strong>
                        </>
                      )}
                      {sessionData?.meeting?.recurringEveryXWeeks > 1 && (
                        <>
                          {" "}
                          every <Strong>{sessionData?.meeting?.recurringEveryXWeeks}</Strong> weeks
                        </>
                      )}
                      {sessionData?.meeting?.recurringEveryXMonths > 1 && (
                        <>
                          {" "}
                          every <Strong>{sessionData?.meeting?.recurringEveryXWeeks}</Strong> months
                        </>
                      )}
                      {sessionData?.meeting?.recurringWeekdaysOnly && (
                        <>
                          {" "}
                          on <Strong>weekdays</Strong>
                        </>
                      )}
                      , starting
                      {isToday(new Date(sessionData?.meeting?.recurringDates[0])) ? (
                        <>
                          {" "}
                          <Strong>today</Strong>
                        </>
                      ) : (
                        <>
                          {" "}
                          on <Strong>{formatWeekday(new Date(sessionData?.meeting?.recurringDates[0]))}</Strong>,{" "}
                          <Strong>{formatLongDate(new Date(sessionData?.meeting?.recurringDates[0]))}</Strong>
                        </>
                      )}{" "}
                      and ending with the last session
                      {isToday(
                        new Date(sessionData?.meeting?.recurringDates[sessionData?.meeting?.recurringDates.length - 1]),
                      ) ? (
                        <>
                          {" "}
                          <Strong>today</Strong>.
                        </>
                      ) : (
                        <>
                          {" "}
                          on{" "}
                          <Strong>
                            {formatWeekday(
                              new Date(
                                sessionData?.meeting?.recurringDates[sessionData?.meeting?.recurringDates.length - 1],
                              ),
                            )}
                          </Strong>
                          ,{" "}
                          <Strong>
                            {formatLongDate(
                              new Date(
                                sessionData?.meeting?.recurringDates[sessionData?.meeting?.recurringDates.length - 1],
                              ),
                            )}
                          </Strong>
                          {sessionData?.meeting?.recurringRepetitions && (
                            <>
                              {" "}
                              after <Strong>{sessionData?.meeting?.recurringRepetitions}</Strong> repetitions
                            </>
                          )}
                          .
                        </>
                      )}
                    </P>
                    <H3>Occurrences</H3>
                    <div style={{ maxHeight: "10rem", overflow: "auto" }}>
                      {sessionData?.meeting.recurringDates.map(session => (
                        <P key={session}>
                          {isNowAfterDate(new Date(session)) ? (
                            <del>
                              {formatLongTime(new Date(session))} {formatLongWordDate(new Date(session))}
                            </del>
                          ) : (
                            `${formatLongTime(new Date(session))} ${formatLongWordDate(new Date(session))}`
                          )}
                        </P>
                      ))}
                    </div>
                  </Cell>
                )}
                {hasSettingsEnabled && (
                  <Cell as={Block}>
                    <Grid noColGap>
                      <Cell>
                        <H2>Settings</H2>
                      </Cell>
                      <Cell>
                        <Flexbox as="ul" flexDirectionColumn>
                          {sessionData?.meeting?.room?.pin && (
                            <li>
                              <IconContent description="PIN settings" icon={EIcon.LOCK}>
                                <P>Requires a PIN to join</P>
                                <Flexbox alignItemsCenter className={styles.pinBox}>
                                  {hidePIN ? (
                                    <div className={styles.stars}>
                                      <Icon className={styles.icon} icon={EIcon.STAR} />
                                      <Icon className={styles.icon} icon={EIcon.STAR} />
                                      <Icon className={styles.icon} icon={EIcon.STAR} />
                                      <Icon className={styles.icon} icon={EIcon.STAR} />
                                    </div>
                                  ) : (
                                    <P className={styles.pin}>{sessionData?.meeting?.room.pin}</P>
                                  )}
                                  <IconButton
                                    as="button"
                                    appearance={EAppearance.OUTLINE}
                                    description={hidePIN ? "Show PIN" : "Hide PIN"}
                                    dimension={EDimension.SMALL}
                                    icon={hidePIN ? EIcon.EYE : EIcon.EYEOFF}
                                    onClick={handleTogglePINDisplay}
                                    status={EStatus.NEUTRAL}
                                  />
                                </Flexbox>
                              </IconContent>
                            </li>
                          )}
                          {sessionData?.meeting?.isRecorded && (
                            <li>
                              <IconContent description="Recording settings" icon={EIcon.VIDEO}>
                                <P>Will be recorded automatically</P>
                              </IconContent>
                            </li>
                          )}
                          {sessionData?.meeting?.applicationPreferred && (
                            <li>
                              <IconContent description="Additional recording settings" icon={EIcon.CAST}>
                                <P>Recording will favour shared content over participant tiles</P>
                              </IconContent>
                            </li>
                          )}
                          {sessionData?.meeting?.isPrivate && (
                            <li>
                              <IconContent description="Calendar event settings" icon={EIcon.CALENDAR}>
                                <P>Private calendar event</P>
                              </IconContent>
                            </li>
                          )}
                        </Flexbox>
                      </Cell>
                    </Grid>
                  </Cell>
                )}
              </Grid>
            </Cell>
            <Cell colSpanMD={4}>
              <Grid noColGap>
                <Cell as={Block}>
                  <Grid noColGap>
                    <Cell>
                      <H2>Participants</H2>
                    </Cell>
                    {sessionData?.meeting?.users.length > 0 && (
                      <Cell>
                        <LabelledContent
                          content={
                            <UserAvatarList
                              users={sessionData?.meeting?.users.map(user => ({
                                email: user.email,
                                firstName: user.firstName,
                                id: user.id,
                                lastName: user.lastName,
                                profilePicture: user.profilePicture,
                              }))}
                            />
                          }
                          label="ClassView users"
                        />
                      </Cell>
                    )}
                    {sessionData?.meeting?.moderators.length > 0 && (
                      <Cell>
                        <LabelledContent
                          content={
                            <UserAvatarList
                              users={sessionData?.meeting?.moderators.map(moderator => ({
                                email: moderator.email,
                                firstName: moderator.firstName,
                                id: moderator.id,
                                lastName: moderator.lastName,
                                profilePicture: moderator.profilePicture,
                              }))}
                            />
                          }
                          label="Moderators"
                        />
                      </Cell>
                    )}
                    {sessionData?.meeting?.lecturers.length > 0 && (
                      <Cell>
                        <LabelledContent
                          content={
                            <UserAvatarList
                              users={sessionData?.meeting?.lecturers.map(lecturer => ({
                                email: lecturer.email,
                                firstName: lecturer.firstName,
                                id: lecturer.id,
                                lastName: lecturer.lastName,
                                profilePicture: lecturer.profilePicture,
                              }))}
                            />
                          }
                          label="Lecturers"
                        />
                      </Cell>
                    )}
                    {sessionData?.meeting?.guests.length > 0 && (
                      <Cell>
                        <LabelledContent
                          content={
                            <ul>
                              {sessionData?.meeting?.guests.map(email => (
                                <P className={styles.email} key={email}>
                                  {email}
                                </P>
                              ))}
                            </ul>
                          }
                          label="Guests"
                        />
                      </Cell>
                    )}
                    {sessionData?.meeting?.roomSystems.length > 0 && (
                      <Cell>
                        <LabelledContent
                          content={
                            <ul>
                              {sessionData?.meeting?.roomSystems.map(rs => (
                                <li key={rs.id}>
                                  <Flexbox alignItemsCenter justifyContentSpaceBetween>
                                    <span>{rs.roomSystem.name}</span>
                                    {rs.dialOut && <StatusBadge status={EStatus.SUCCESS}>Dial out</StatusBadge>}
                                  </Flexbox>
                                </li>
                              ))}
                            </ul>
                          }
                          label="Room Systems"
                        />
                      </Cell>
                    )}
                  </Grid>
                </Cell>
                <Cell as={Block}>
                  <Grid noColGap>
                    <Cell>
                      <H2>How to join</H2>
                    </Cell>
                    <Cell>
                      <LabelledContent
                        content={
                          <>
                            <InternalTextLink
                              style={{
                                display: "block",
                                marginBottom: "0.5rem",
                                wordBreak: "break-all",
                              }}
                              to={PATH_CALLLINK(sessionData?.meeting?.room?.key)}>{`https://${DOMAIN}${PATH_CALLLINK(
                              sessionData?.meeting?.room?.key,
                            )}`}</InternalTextLink>
                            <IconButton
                              appearance={EAppearance.OUTLINE}
                              as="button"
                              description="Copy link"
                              dimension={EDimension.SMALL}
                              icon={EIcon.COPY}
                              onClick={() => copySessionURL(sessionData?.meeting?.room?.key)}
                              label="Copy link"
                              status={EStatus.NEUTRAL}
                            />
                          </>
                        }
                        label="Web or mobile browser"
                      />
                    </Cell>
                    <Cell>
                      <LabelledContent
                        content={
                          <>
                            Call <ExternalTextLink href="tel:+443002010142">+44 (0) 300 201 0142</ExternalTextLink> and
                            then enter extension {dialIn.extension}#
                          </>
                        }
                        label="Telephone"
                      />
                    </Cell>
                    <Cell>
                      <LabelledContent content={<>{dialIn.extension}</>} label="ClassView POD" />
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
          <ConfirmActionModal
            action="delete"
            content="session"
            isOpen={isDeleteModalOpen}
            onAbort={() => setIsDeleteModalOpen(false)}
            onAction={handleDeleteSession}
            status={EStatus.DANGER}
          />
        </>
      )}
    </>
  );
}
