import { useCallback, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Accordion,
  BigPopoverMenu,
  Block,
  Cell,
  ConfirmActionModal,
  EditableFieldSelect,
  EditableFieldText,
  Feedback,
  Field,
  Grid,
  H1,
  H2,
  IconButton,
  Input,
  InputOption,
  InternalTextLink,
  LabelledContent,
  Loader,
  P,
  PlainAction,
  Table,
  TD,
  TextButton,
  TR,
  TRTDTemp,
} from "packages/catalog";
import {
  convertStringEnumToDropdownObject,
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  PATH_SUPERADMIN_ORGANISATION,
  scrollToContentTop,
  TParams,
} from "packages/utils";

import { UserProfile, UserRecordingQuality, UserType } from "packages/client/graphql_definitions.generated";

import { useGetFullUserQuery } from "packages/client/users/graphql/users.generated";
import { deleteSession } from "packages/client/sessions/django/requests";
import { deleteUser, demoteUser, editUser, promoteUser } from "packages/client/users/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { UserAvatar } from "packages/client/users/components";
import { SuperAdminSessionRow } from "packages/client/sessions/components";

const COLUMNS_HUBS = ["Name", "Call ID", "Extension", "PIN"];
const COLUMN_WIDTHS_HUBS = "1fr 1fr 1fr 1fr 2rem";
const COLUMNS_SESSIONS = ["ID", "Next", "Name"];
const COLUMN_WIDTHS_SESSIONS = "4rem 20rem 1fr 2rem";
const PROFILE_TYPE_OPTIONS = convertStringEnumToDropdownObject(UserProfile);
const RECORDING_QUALITY_OPTIONS = convertStringEnumToDropdownObject(UserRecordingQuality);

export function SuperAdminUser() {
  const { userID }: TParams = useParams();

  const [actionID, setActionID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    data: userData,
    loading,
    refetch,
  } = useGetFullUserQuery({
    variables: {
      userID: parseInt(userID, 10),
    },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const navigate = useNavigate();

  const handleDeleteSession = useCallback(() => {
    setSuccessMessage("");
    setErrorMessage("");

    scrollToContentTop();
    setIsDeleteModalOpen(false);

    deleteSession(actionID)
      .then(() => {
        refetch();
        setSuccessMessage("Session deleted.");
      })
      .catch(reason => {
        setErrorMessage(reason.message);
      });

    setActionID("");
  }, [actionID, refetch]);

  const handleDeleteUser = useCallback(() => {
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    }

    deleteUser(userID).then(() => {
      navigate("..");
    });
  }, [navigate, userID]);

  const handleEditAdmin = useCallback(() => {
    if (userData?.user?.isAdmin) {
      demoteUser(userID).then(() => refetch());
    } else {
      promoteUser(userID).then(() => refetch());
    }
  }, [refetch, userData, userID]);

  const handleSaveField = useCallback(
    (name: keyof UserType, value: string | boolean) => {
      editUser(userID, { [name]: value }).then(() => refetch());
    },
    [userID, refetch],
  );

  const prepareDeleteSession = useCallback((userID: string) => {
    setActionID(userID);
    setIsDeleteModalOpen(true);
  }, []);

  if (!userData) return null;
  if (loading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler message="The user page has loaded." pageTitle="User" />
      <Grid>
        {errorMessage.length ? (
          <Cell>
            <Feedback status={EStatus.DANGER}>{errorMessage}</Feedback>
          </Cell>
        ) : null}
        {successMessage.length ? (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>{successMessage}</Feedback>
          </Cell>
        ) : null}
        <Cell>
          <IconButton
            appearance={EAppearance.GHOST}
            as={Link}
            description="Users"
            dimension={EDimension.SMALL}
            icon={EIcon.ARROWLEFT}
            label="Users"
            status={EStatus.NEUTRAL}
            to=".."
          />
        </Cell>
        <Cell>
          <H1>User</H1>
        </Cell>
        <Cell as={Block}>
          <H2 hasMargin>Overview</H2>
          <Grid>
            <Cell center colSpan={3}>
              <UserAvatar
                dimension={EDimension.EXTRA_LARGE}
                email={userData?.user?.email}
                fullName={`${userData?.user?.firstName} ${userData?.user?.lastName}`}
                profilePictureURL={userData?.user?.profilePicture}
              />
            </Cell>
            <Cell as={Grid} colSpan={9}>
              <Cell colSpan={6}>
                <EditableFieldText
                  data={userData?.user?.firstName}
                  label="First name"
                  name="firstName"
                  onSave={handleSaveField}
                />
              </Cell>
              <Cell colSpan={6}>
                <EditableFieldText
                  data={userData?.user?.lastName}
                  label="Last name"
                  name="lastName"
                  onSave={handleSaveField}
                />
              </Cell>
              <Cell colSpan={6}>
                <LabelledContent content={userData?.user?.id} label="ID" />
              </Cell>
              <Cell colSpan={6}>
                <LabelledContent
                  content={
                    <InternalTextLink to={PATH_SUPERADMIN_ORGANISATION(userData?.user?.organisation.id)}>
                      {userData?.user?.organisation.name}
                    </InternalTextLink>
                  }
                  label="Organisation"
                />
              </Cell>
              <Cell colSpan={6}>
                <LabelledContent content={userData?.user?.email} label="Email address" />
              </Cell>
            </Cell>
          </Grid>
        </Cell>
        <Cell as={Block}>
          <H2 hasMargin>Settings</H2>
          <Grid>
            <Cell colSpanMD={4}>
              <Field label="Permissions">
                <InputOption label="Is admin">
                  <Input checked={userData?.user?.isAdmin} name="isAdmin" onChange={handleEditAdmin} type="checkbox" />
                </InputOption>
              </Field>
            </Cell>
            <Cell colSpanMD={4}>
              <EditableFieldSelect
                label="Profile visibility"
                name="profile"
                onSave={handleSaveField}
                options={PROFILE_TYPE_OPTIONS}
                value={userData?.user?.profile}
              />
            </Cell>
            <Cell colSpanMD={4}>
              <EditableFieldSelect
                label="Recording quality"
                name="recordingQuality"
                onSave={handleSaveField}
                options={RECORDING_QUALITY_OPTIONS}
                value={userData?.user?.recordingQuality}
              />
            </Cell>
          </Grid>
        </Cell>
        <Cell as={Block}>
          <H2 hasMargin>Hubs</H2>
          <Table
            columns={COLUMNS_HUBS}
            columnWidths={COLUMN_WIDTHS_HUBS}
            description="A table listing all of this user's hubs"
            hasMenuColumn>
            {userData?.user?.hubs?.map(hub => (
              <TR columnWidths={COLUMN_WIDTHS_HUBS} key={hub.key}>
                <TD heading={COLUMNS_HUBS[0]}>{hub.name}</TD>
                <TD heading={COLUMNS_HUBS[1]}>{hub.key}</TD>
                <TD heading={COLUMNS_HUBS[2]}>{hub.extension}</TD>
                <TD heading={COLUMNS_HUBS[3]}>{hub.pin ? `${hub.pin}` : "-"}</TD>
                <TD>
                  <BigPopoverMenu
                    attachment={EAttachment.SOUTH_SOUTH_EAST}
                    trigger={
                      <IconButton
                        appearance={EAppearance.GHOST}
                        as="div"
                        description="Options"
                        dimension={EDimension.SMALL}
                        icon={EIcon.MOREVERTICAL}
                        status={EStatus.NEUTRAL}
                      />
                    }>
                    <PlainAction as={Link} to={`/r/${hub.key}`}>
                      Join
                    </PlainAction>
                  </BigPopoverMenu>
                </TD>
              </TR>
            ))}
          </Table>
        </Cell>
        <Cell as={Block} style={{ maxHeight: "30rem", overflow: "auto" }}>
          <H2 hasMargin>Sessions</H2>
          <Table
            columns={COLUMNS_SESSIONS}
            columnWidths={COLUMN_WIDTHS_SESSIONS}
            description="A table listing all sessions"
            hasMenuColumn>
            {userData?.user?.meetingSet?.length ? (
              <>
                {userData?.user?.meetingSet?.map(session => (
                  <SuperAdminSessionRow
                    columns={COLUMNS_SESSIONS}
                    columnWidths={COLUMN_WIDTHS_SESSIONS}
                    endTime={session.endTime}
                    key={session.id}
                    nextRecurringEndTime={session.nextRecurringEnd}
                    nextRecurringStartTime={session.nextRecurringStart}
                    prepareDeleteSession={prepareDeleteSession}
                    roomKey={session?.room?.key}
                    sessionID={session.id}
                    startTime={session.startTime}
                    subject={session.subject}
                  />
                ))}
              </>
            ) : (
              <TRTDTemp>
                <P center>This user has never scheduled a session.</P>
              </TRTDTemp>
            )}
          </Table>
        </Cell>
        <Cell>
          <Accordion heading={<H2>Profile</H2>}>
            <Grid>
              <Cell>
                <EditableFieldText
                  data={userData?.user?.jobTitle}
                  label="Job title"
                  name="jobTitle"
                  onSave={handleSaveField}
                />
              </Cell>
              <Cell colSpan={6}>
                <EditableFieldText data={userData?.user?.bio} label="Biography" name="bio" onSave={handleSaveField} />
              </Cell>
              <Cell colSpan={6}>
                <EditableFieldText
                  data={userData?.user?.skills}
                  label="Skills"
                  name="skills"
                  onSave={handleSaveField}
                />
              </Cell>
              <Cell colSpan={6}>
                <EditableFieldText
                  data={userData?.user?.phoneNumber}
                  label="Telephone number"
                  name="phoneNumber"
                  onSave={handleSaveField}
                />
              </Cell>
              <Cell colSpan={6}>
                <EditableFieldText
                  data={userData?.user?.website}
                  label="Website URL"
                  name="website"
                  onSave={handleSaveField}
                />
              </Cell>
            </Grid>
          </Accordion>
        </Cell>
        <Cell>
          <TextButton onClick={handleDeleteUser} status={EStatus.DANGER}>
            Delete user
          </TextButton>
        </Cell>
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="session"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteSession}
        status={EStatus.DANGER}
      />
    </>
  );
}
