export enum EParams {
  CODE = "code",
  ID_CALL = "callID",
  ID_MEETING = "meetingID",
  ID_ORGANISATION = "organisationID",
  ID_POLL = "pollID",
  ID_RECORDING = "recordingID",
  ID_ROOM = "roomID",
  ID_ROOMSYSTEM = "roomsystemID",
  ID_USER = "userID",
  ID_WEBCAST = "webcastID",
  ID_WORKSPACE = "workspaceID",
  METHOD = "method",
  TIMESTAMP = "timestamp",
  TOKEN = "token",
}
