import { API_DOMAIN, HEADER_JSON, S3_API_PATH } from "packages/utils";

import type { IDjangoFile } from "packages/client/files/s3";

import { getToken } from "packages/client/authentication/redux/functions";

export async function deleteFile(data: IDjangoFile, token: string): Promise<number | null> {
  try {
    const resp = await fetch(`https://${API_DOMAIN}${S3_API_PATH}/file?token=${token}`, {
      body: JSON.stringify(data),
      headers: HEADER_JSON,
      method: "DELETE",
    });
    return resp.status === 204 ? null : resp.status;
  } catch (err) {
    return err;
  }
}

export async function fetchRecordingCloudfrontURLFromAPIEndpoint(endpoint: string) {
  const resp = await fetch(endpoint);

  // these error messages will be shown for users who are logged in attempting to view their own recordings (using the replayID to fetch the video)
  if (resp.status !== 200) {
    if (resp.status === 400) throw new Error("This recording doesn’t exist.");
    if (resp.status === 403) throw new Error("You do not have permission to view this recording.");
    if (resp.status >= 500) throw new Error("We’re sorry, something has gone wrong. Please try again later.");
  }
  return await resp.text();
}

export async function fetchRecordingCloudfrontURLFromHash(hash: string) {
  const resp = await fetch(`https://${API_DOMAIN}${S3_API_PATH}/file/get-url/${hash}`);

  // these error messages will be shown for users who are viewing a public URL (using the hash to fetch the video)
  if (resp.status !== 200) {
    if (resp.status === 400)
      throw new Error("This recording link doesn’t exist or has expired. Please request another link from the sender.");
    if (resp.status === 403)
      throw new Error(
        "You may not have permission to view this recording. Please request another link from the sender.",
      );
    if (resp.status >= 500)
      throw new Error(
        "We’re sorry, something has gone wrong. Please try again later, or request another link from the sender.",
      );
  }
  return await resp.text();
}

export async function fetchRecordingHash(cloudfrontURL: string) {
  const token = getToken();
  if (!token) return "";
  const resp = await fetch(`https://${API_DOMAIN}${S3_API_PATH}/file/shorten-url?token=${token}`, {
    method: "POST",
    body: JSON.stringify({
      url: cloudfrontURL,
      expires: 3200,
    }),
  });
  if (resp.status !== 200) throw new Error(await resp.text());
  return await resp.text();
}

export async function sendFile(data: FormData, token: string): Promise<number | null> {
  try {
    const resp = await fetch(`https://${API_DOMAIN}${S3_API_PATH}/file?token=${token}`, {
      body: data,
      method: "POST",
    });
    return resp.status === 200 ? null : resp.status;
  } catch (err) {
    return err;
  }
}
