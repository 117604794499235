import { getLazyDependencyInstance, promiseWaiter } from "packages/utils";

import { EVidyoStatusActions } from "../redux/types";

import {
  createLocalDeviceLifecycle,
  createParticipantsLifecycle,
  createRecordingLifecycle,
  createSharingLifecycle,
  createStreamsLifecycle,
  debugLifecycle,
  getVidyoSDK,
} from "./";

import { dispatchVidyo } from "../../redux";
import type { TVidyoConnector } from "../types";

if (module.hot) module.hot.decline();

const timeoutErrorMessage = IS_ELECTRON
  ? "We were unable to connect you to the meeting. Please try again or restart the app."
  : "We were unable to connect you to the meeting. Please try again or refresh your browser";

export const connectToVidyo = getLazyDependencyInstance(async () => {
  try {
    const vidyoSDK = await Promise.race<VidyoClientLib.VidyoClient | null>([getVidyoSDK(), promiseWaiter(20 * 1000)]);

    if (!vidyoSDK) throw new Error(timeoutErrorMessage);

    const conn = await vidyoSDK.CreateVidyoConnector({
      remoteParticipants: 25,
      viewId: null, // <-- we need this to be null due to instantiating the connector on the precall screen for web and the specified viewID is then handled in the VidyoCallWeb's component's useEffect. Electron uses custom layout so needs it to be null anyway.
      viewStyle: "VIDYO_CONNECTORVIEWSTYLE_Default", // Not used in custom layout
      userData: IS_ELECTRON ? (undefined as number) : 0,
      logFileName: "",
      logFileFilter: "",
    });

    const v = await conn.GetVersion();

    // eslint-disable-next-line no-console
    console.info(`Launching VidyoSDK for ${IS_ELECTRON ? "Windows (Electron) " : "web "}version: ${v}`);
    await initConnector(conn);

    dispatchVidyo({ type: EVidyoStatusActions.SET_SDK_READY, sdkReady: true });

    return conn;
  } catch (e) {
    dispatchVidyo({ type: EVidyoStatusActions.SET_SDK_READY, sdkReady: false, sdkError: e.message });
  }
});

async function initConnector(vidyoConnector: TVidyoConnector) {
  const lifecycles = [
    createLocalDeviceLifecycle(vidyoConnector),
    createParticipantsLifecycle(vidyoConnector),
    createStreamsLifecycle(vidyoConnector),
    createRecordingLifecycle(vidyoConnector),
    createSharingLifecycle(vidyoConnector),
  ];

  if (NODE_ENV !== "production") lifecycles.push(debugLifecycle(vidyoConnector));
  await Promise.all(lifecycles);
}
