import { Link } from "react-router-dom";

import {
  Cell,
  ClosableHeading,
  Fieldset,
  Grid,
  IconButton,
  SidebarHeader,
  SidebarLink,
  SidebarMain,
  Toggle,
  ToggleOption,
  Tooltip,
} from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  SEGMENT_DEVICES,
  SEGMENT_EXTENDCALL,
  SEGMENT_FEEDBACK,
  SEGMENT_LAYOUT,
} from "packages/utils";

import { useGetEssentialSession } from "packages/client/sessions/hooks";
import { usePrioritiseSharedScreenRecording, useRoom, useScreenSharingPermission } from "packages/client/incall/hooks";

import { RoutingChangeHandler } from "packages/client/layout/components";

function RecordingSettingsToggle() {
  const { prioritiseSharedScreenRecording, updatePrioritiseSharedScreenRecording, isLoading } =
    usePrioritiseSharedScreenRecording();

  function togglePrioritiseSharedScreenRecording() {
    updatePrioritiseSharedScreenRecording(!prioritiseSharedScreenRecording);
  }

  return (
    <ToggleOption
      label={
        <span>
          Prioritise shared screen in recordings{" "}
          <Tooltip
            attachment={EAttachment.EAST_NORTH_EAST}
            targetName="Prioritise shared screen"
            tip="The recording will focus on the screen share over participants video output."
            trigger={
              <IconButton
                appearance={EAppearance.GHOST}
                as="button"
                description=""
                dimension={EDimension.SMALL}
                icon={EIcon.INFO}
                status={EStatus.ACCENT}
              />
            }
          />
        </span>
      }>
      <Toggle
        checked={prioritiseSharedScreenRecording}
        disabled={isLoading}
        name="prioritise-screen"
        onChange={togglePrioritiseSharedScreenRecording}
      />
    </ToggleOption>
  );
}

function ScreenSharingSettingsToggle() {
  const { screenSharingPermission, updateScreenSharingPermission, isLoading } = useScreenSharingPermission();

  function toggleScreenSharingPermission() {
    updateScreenSharingPermission(!screenSharingPermission);
  }

  return (
    <ToggleOption label="Allow non-moderators to share their screens">
      <Toggle checked={screenSharingPermission} disabled={isLoading} onChange={toggleScreenSharingPermission} />
    </ToggleOption>
  );
}

export function Settings() {
  const { isModerator, isMeeting, sessionID } = useRoom();
  const { session } = useGetEssentialSession(sessionID);

  return (
    <>
      <RoutingChangeHandler message="The settings menu has loaded." />
      <SidebarHeader>
        <ClosableHeading to="..">Settings</ClosableHeading>
      </SidebarHeader>
      <SidebarMain>
        <Grid noColGap>
          <Cell as="section">
            <nav>
              <ul>
                <SidebarLink linkAs={Link} text="Devices" to={SEGMENT_DEVICES} />
                <SidebarLink linkAs={Link} text="Layout" to={SEGMENT_LAYOUT} />
                {isModerator && isMeeting && !session?.nextRecurringStart && (
                  <SidebarLink linkAs={Link} text="Extend session" to={SEGMENT_EXTENDCALL} />
                )}
                <SidebarLink linkAs={Link} text="Provide session feedback" to={SEGMENT_FEEDBACK} />
              </ul>
            </nav>
          </Cell>
          {isModerator && (
            <Cell as="section">
              <Fieldset>
                <RecordingSettingsToggle />
                <ScreenSharingSettingsToggle />
              </Fieldset>
            </Cell>
          )}
        </Grid>
      </SidebarMain>
    </>
  );
}
