import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Cell,
  ExternalTextLink,
  Feedback,
  Field,
  Fieldset,
  Grid,
  Input,
  InputOption,
  Label,
  Rating,
  RatingOption,
  TextArea,
} from "packages/catalog";
import { EReaction, EStatus, SUPPORT_INFORMATION, TParams } from "packages/utils";

import { submitUserFeedback, submitUserFeedbackAuthenticated } from "packages/client/feedback-form/django/requests";
import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { updateFeedbackForm } from "packages/client/feedback-form/redux/slice";

export interface PFeedbackForm {
  setSuccess: (value: boolean) => void;
}

export function FeedbackForm({ setSuccess }: PFeedbackForm) {
  const { isSignedIn, feedback } = useTypedSelector(({ authentication, feedbackForm }) => ({
    feedback: feedbackForm.feedback,
    isSignedIn: authentication.isSignedIn,
  }));
  const { callID }: TParams = useParams();
  const dispatch = useTypedDispatch();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(feedback.rating);
  const [review, setReview] = useState<string>(feedback.feedbackMessage);
  const [contact, setContact] = useState<boolean>(feedback.happyToContact);
  const [email, setEmail] = useState<string>(feedback.email);
  const [validEmail, setValidEmail] = useState<boolean>(feedback.validEmail);
  const [error, setError] = useState<boolean>(false);

  const handleChangeRating = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value);
    setRating(value);
  }, []);

  const handleChangeReview = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReview(e.currentTarget.value);
  }, []);

  const handleContact = useCallback(() => {
    setEmail("");
    setContact(!contact);
  }, [contact]);

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.currentTarget;
      setEmail(input.value);
      if (input.checkValidity()) setValidEmail(true);
      else if (validEmail) setValidEmail(false);
    },
    [validEmail],
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      let resp: Response;
      if (isSignedIn) resp = await submitUserFeedbackAuthenticated(rating, review, contact, callID);
      else resp = await submitUserFeedback(rating, review, contact, email, callID);
      if (resp.status === 200) {
        setSuccess(true);
        dispatch(
          updateFeedbackForm({
            callID: "",
            email: "",
            feedbackMessage: "",
            happyToContact: false,
            rating: null,
            validEmail: false,
          }),
        );
      } else setError(true);
    },
    [isSignedIn, rating, review, contact, callID, email, setSuccess, dispatch],
  );

  useEffect(() => {
    dispatch(
      updateFeedbackForm({
        callID,
        email,
        feedbackMessage: review,
        happyToContact: contact,
        rating,
        validEmail,
      }),
    );
  }, [dispatch, contact, email, rating, review, validEmail, callID]);

  useEffect(() => {
    setDisabled(!rating || (contact && !isSignedIn && !email) || ((rating === 2 || rating === 3) && !review.length));
  }, [contact, email, isSignedIn, rating, review]);

  return (
    <Grid as="form" onSubmit={handleSubmit} noColGap>
      <Cell>
        <Fieldset
          flexDirectionRow
          justifyContentSpaceBetween
          label="How would you rate your experience using ClassView?">
          <RatingOption label="Bad">
            <Rating
              checked={rating === 2}
              icon={EReaction.SAD}
              name="rating"
              onChange={handleChangeRating}
              value="2"
              required
            />
          </RatingOption>
          <RatingOption label="OK">
            <Rating
              checked={rating === 3}
              icon={EReaction.NEUTRAL}
              name="rating"
              onChange={handleChangeRating}
              value="3"
              required
            />
          </RatingOption>
          <RatingOption label="Good">
            <Rating
              checked={rating === 4}
              icon={EReaction.SMILE}
              name="rating"
              onChange={handleChangeRating}
              value="4"
              required
            />
          </RatingOption>
          <RatingOption label="Great">
            <Rating
              checked={rating === 5}
              icon={EReaction.HEART_EYES}
              name="rating"
              onChange={handleChangeRating}
              value="5"
              required
            />
          </RatingOption>
        </Fieldset>
      </Cell>
      <Cell>
        <Field
          description={
            rating === 2 || rating === 3 ? "Please give any useful information that can help us improve ClassView." : ""
          }
          label="Why have you given this rating?">
          <TextArea
            maxLength={1500}
            name="information"
            onChange={handleChangeReview}
            required={rating === 2 || rating === 3}
            rows={5}
            value={review}
          />
        </Field>
      </Cell>
      <Cell>
        <InputOption label="I'm happy to be contacted and provide additional feedback">
          <Input checked={contact} name="contact" onChange={handleContact} type="checkbox" />
        </InputOption>
      </Cell>
      {!isSignedIn && (
        <Cell>
          <Label hasMargin text="Email address" disabled={!contact}>
            <Input
              autoComplete="email"
              disabled={!contact}
              name="email"
              onChange={handleChangeEmail}
              placeholder="e.g. dana.scully@fbi.gov"
              required
              type="email"
              value={email}
            />
          </Label>
        </Cell>
      )}
      <Cell center>
        <Input type="submit" value="Send feedback" disabled={disabled} />
      </Cell>
      {error && (
        <Cell>
          <Feedback status={EStatus.DANGER}>
            {"Sorry, we couldn't send your feedback. Please try again or contact our support team at "}
            <ExternalTextLink href={`mailto:${SUPPORT_INFORMATION.mail}`}>{SUPPORT_INFORMATION.mail}</ExternalTextLink>
          </Feedback>
        </Cell>
      )}
    </Grid>
  );
}
