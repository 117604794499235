import { HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { Icon } from "packages/catalog";
import { EIcon } from "packages/utils";

import styles from "./Accordion.module.scss";

export interface PAccordion extends HTMLAttributes<HTMLDetailsElement> {
  children: ReactNode;
  defaultOpen?: boolean;
  heading: string | ReactNode;
  onDidOpen?: () => void;
}

export function Accordion({ children, heading, onDidOpen, defaultOpen, ...rest }: PAccordion) {
  const detailsRef = useRef<HTMLDetailsElement>();
  const [clicked, setClicked] = useState(0);

  const handleClick = useCallback(() => {
    if (!clicked) setClicked(1);
  }, [clicked]);

  useEffect(() => {
    if (clicked && detailsRef.current.open && onDidOpen) onDidOpen();
  }, [clicked, detailsRef, onDidOpen]);

  return (
    <details ref={detailsRef} onClick={handleClick} open={defaultOpen} className={styles.details} {...rest}>
      <summary className={styles.summary}>
        <div className={styles.title}>
          {heading}
          <Icon className={styles.icon} icon={EIcon.CHEVRONDOWN} />
        </div>
      </summary>
      <div className={styles.inner}>{children}</div>
    </details>
  );
}
