import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { useTypedSelector } from "packages/client/redux";

import {
  FFullWhiteboardFragment,
  GetWhiteboardDocument,
  GetWhiteboardQuery,
  useCleanWhiteboardMutation,
  useDeleteWhiteboardMutation,
  useGetWhiteboardQuery,
  useUpsertWhiteboardMutation,
  WhiteboardUpdateDocument,
  WhiteboardUpdateSubscription,
} from "packages/client/incall/graphql/whiteboard.generated";
import { WhiteboardUpdateType, WhiteboardEntityInput } from "packages/client/graphql_definitions.generated";

export interface IUseWhiteboard {
  activeGrid: TGrid;
  canRedo: boolean;
  canUndo: boolean;
  clear: () => void;
  currentColour: string;
  draw: (entity: WhiteboardEntityInput) => void;
  isPencilMode: boolean;
  isWhiteboardClean: boolean;
  paths: Readonly<FFullWhiteboardFragment[]>;
  redo: () => void;
  setColour: (newColour: string) => void;
  setPencilMode: (isSelected: boolean) => void;
  undo: () => void;
  updateGrid: (canvasType: TGrid) => void;
}

export interface IWhiteboardState {
  currentColour: string;
  isPencilMode: boolean;
  redos: WhiteboardEntityInput[];
  undos: WhiteboardEntityInput[];
}

export interface IWhiteboardAction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  type: TWhiteboardAction;
}

export type TWhiteboardAction =
  | "updateUndos"
  | "updateRedos"
  | "beganNewDrawing"
  | "clearedWhiteboard"
  | "updateColour"
  | "updateIsPencilMode";

export type TGrid = "square" | "dot" | null;

const initialWhiteboardState: GetWhiteboardQuery = { __typename: "Query", whiteboard: [] };

const initialState: IWhiteboardState = {
  currentColour: "#000000",
  isPencilMode: false,
  redos: [],
  undos: [],
};

const MAX_UNDO_REDO_LIMIT = 10;

function whiteboardReducer(prevState: typeof initialState, action: IWhiteboardAction): typeof initialState {
  switch (action.type) {
    case "updateUndos":
    case "updateRedos":
      return {
        ...prevState,
        undos: action.payload.undos,
        redos: action.payload.redos,
      };
    case "beganNewDrawing":
      return {
        ...prevState,
        undos: [...prevState.undos, action.payload as WhiteboardEntityInput]
          .reverse()
          .slice(0, MAX_UNDO_REDO_LIMIT)
          .reverse(),
        redos: [],
      };
    case "clearedWhiteboard":
      return {
        ...prevState,
        undos: initialState.undos,
        redos: initialState.redos,
      };
    case "updateColour":
      return {
        ...prevState,
        currentColour: action.payload,
      };
    case "updateIsPencilMode":
      return {
        ...prevState,
        isPencilMode: action.payload,
      };
    default:
      return prevState;
  }
}

function upsertEntityData(data: GetWhiteboardQuery, entity: (typeof data.whiteboard)[0]): GetWhiteboardQuery {
  if (!data?.whiteboard) return { __typename: "Query", whiteboard: [entity] };
  return {
    ...data,
    whiteboard: [...data.whiteboard.filter(p => p.id !== entity.id), entity],
  };
}

function removeEntity(data: GetWhiteboardQuery, id: string): GetWhiteboardQuery {
  return { ...data, whiteboard: data.whiteboard.filter(p => p.id !== id) };
}

/**
 * @private This function is used within the context, to use the whiteboard values use `useWhiteboardContext()` hook
 */
export function useWhiteboard(room: string): IUseWhiteboard {
  const [whiteboardState, updateWhiteboardState] = useReducer(whiteboardReducer, initialState);

  const queryOptionsForMutate = useMemo(
    () => ({
      query: GetWhiteboardDocument,
      variables: {
        room,
      },
    }),
    [room],
  );
  const { guestName } = useTypedSelector(({ authentication }) => ({ guestName: authentication.guestName }));

  const { data, subscribeToMore } = useGetWhiteboardQuery({
    variables: { room },
    skip: !room || room === "",
    returnPartialData: true,
  });

  const [canvasType, setCanvasType] = useState<TGrid>(null);
  const [isWhiteboardClean, setIsWhiteboardClean] = useState<boolean>(false);

  useEffect(() => {
    setIsWhiteboardClean(!data?.whiteboard?.length);
  }, [data, isWhiteboardClean]);

  useEffect(() => {
    const unsub = subscribeToMore<WhiteboardUpdateSubscription>({
      document: WhiteboardUpdateDocument,
      variables: { room },
      updateQuery: (state, { subscriptionData }) => {
        const { data: d } = subscriptionData;
        if (!d) return state;
        const { whiteboardUpdate: update } = d;
        if (!update?.type) return state;
        switch (update.type) {
          case WhiteboardUpdateType.Clean:
            return initialWhiteboardState;
          case WhiteboardUpdateType.Delete:
            return removeEntity(state, update.id);
          case WhiteboardUpdateType.Upsert:
            return upsertEntityData(state, update.entity);
          default:
            return state;
        }
      },
    });
    return () => unsub();
  }, [subscribeToMore, room]);

  const [upsertEntity] = useUpsertWhiteboardMutation({
    update: (cache, { data: { whiteboardUpsertEntity: entity } }) => {
      const state = cache.readQuery<GetWhiteboardQuery>(queryOptionsForMutate);
      cache.writeQuery<GetWhiteboardQuery>({
        ...queryOptionsForMutate,
        data: upsertEntityData(state, entity),
      });
    },
  });

  const [handleClean] = useCleanWhiteboardMutation({
    variables: { room },
    update: cache =>
      cache.writeQuery<GetWhiteboardQuery>({
        ...queryOptionsForMutate,
        data: initialWhiteboardState,
      }),
  });

  const [deleteEntity] = useDeleteWhiteboardMutation({
    update: (cache, { data: { whiteboardDeleteEntity: entityID } }) => {
      const state = cache.readQuery<GetWhiteboardQuery>(queryOptionsForMutate);
      cache.writeQuery<GetWhiteboardQuery>({
        ...queryOptionsForMutate,
        data: removeEntity(state, entityID),
      });
    },
  });

  // const handleDelete = useCallback(
  //   (pathID: string) => {
  //     deleteEntity({ variables: { room, entityID: pathID } });
  //   },
  //   [room, deleteEntity],
  // );

  const handleClearWhiteboard = useCallback(async () => {
    try {
      await handleClean();
      updateWhiteboardState({ type: "clearedWhiteboard" });
    } catch {
      console.error("Could not clear whiteboard.");
    }
  }, [handleClean]);

  const handleDraw = useCallback(
    async (entity: Omit<WhiteboardEntityInput, "author">) => {
      const entityInput: WhiteboardEntityInput = { ...entity, author: guestName };
      try {
        const res = await upsertEntity({ variables: { room, entity: entityInput } });
        updateWhiteboardState({
          type: "beganNewDrawing",
          payload: { ...entityInput, id: res.data.whiteboardUpsertEntity.id },
        });
      } catch {
        console.error("Could not draw new path on the backend.");
      }
    },
    [guestName, room, upsertEntity],
  );
  // const undos = [...whiteboardState.undos, {...entityInput, id: res.data.whiteboardUpsertEntity.id } ];
  // updateWhiteboardState({
  //   type: "beganNewDrawing",
  //   payload: { undos },
  // });
  const handleUndo = useCallback(async () => {
    if (!whiteboardState.undos.length) return;
    const pickedItem = whiteboardState.undos[whiteboardState.undos.length - 1];
    const undos = whiteboardState.undos.slice(0, -1);
    const redos = [
      ...(whiteboardState.redos.length > MAX_UNDO_REDO_LIMIT - 1
        ? whiteboardState.redos.slice(1, MAX_UNDO_REDO_LIMIT)
        : whiteboardState.redos),
      pickedItem,
    ];
    try {
      await deleteEntity({ variables: { room, entityID: pickedItem.id } });
      updateWhiteboardState({
        type: "updateUndos",
        payload: { undos, redos, canUndo: !!undos.length, canRedo: !!redos.length },
      });
    } catch {
      console.error("Could not perform undo on the backend.");
    }
  }, [whiteboardState.undos, whiteboardState.redos, deleteEntity, room]);

  const handleRedo = useCallback(async () => {
    if (!whiteboardState.redos.length) return;
    const pickedItem = whiteboardState.redos[whiteboardState.redos.length - 1];
    const undos = [
      ...(whiteboardState.undos.length > MAX_UNDO_REDO_LIMIT - 1
        ? whiteboardState.undos.slice(1, MAX_UNDO_REDO_LIMIT)
        : whiteboardState.undos),
      pickedItem,
    ];
    const redos = whiteboardState.redos.slice(0, -1);
    try {
      await upsertEntity({ variables: { room, entity: pickedItem } });
      updateWhiteboardState({
        type: "updateRedos",
        payload: { undos, redos, canUndo: !!undos.length, canRedo: !!redos.length },
      });
    } catch {
      console.error("Could not perform redo on the backend.");
    }
  }, [whiteboardState.redos, whiteboardState.undos, upsertEntity, room]);

  const updateGrid = useCallback((canvasType: TGrid) => {
    setCanvasType(canvasType);
  }, []);

  const handleColourSelected = useCallback(
    (newColour: string) => {
      updateWhiteboardState({ type: "updateColour", payload: newColour });
    },
    [updateWhiteboardState],
  );

  const handlePencilModeSelected = useCallback(
    (isSelected: boolean) => {
      updateWhiteboardState({ type: "updateIsPencilMode", payload: isSelected });
    },
    [updateWhiteboardState],
  );

  return {
    paths: data?.whiteboard ?? [],
    canUndo: !!whiteboardState?.undos?.length,
    canRedo: !!whiteboardState?.redos?.length,
    currentColour: whiteboardState.currentColour,
    isPencilMode: whiteboardState.isPencilMode,
    draw: handleDraw,
    updateGrid,
    activeGrid: canvasType,
    isWhiteboardClean: isWhiteboardClean,
    // delete: handleDelete,
    clear: handleClearWhiteboard,
    undo: handleUndo,
    redo: handleRedo,
    setColour: handleColourSelected,
    setPencilMode: handlePencilModeSelected,
  };
}
