import { dispatchVidyo, instanceVidyoRootStore } from "../../redux";
import type { EVidyoLocalDeviceStatus } from "../../status/enums";
import { EVidyoLocalDevicesActions } from "../../local-devices/redux/types";
import { EDevice } from "../../local-devices/enums/EDevice";
import type { TLocalCamera, TLocalMicrophone, TLocalSpeaker } from "../../local-devices/types";
import type { TVidyoDevice } from "../../streams/types";
import type { TVidyoConnector } from "../types";

function generateHandlerLocalDevice(deviceType: EDevice, vidyoConnector: TVidyoConnector) {
  return {
    onAdded: (localDevice: TVidyoDevice<typeof deviceType>) => {
      const { localDevices } = instanceVidyoRootStore.getState();
      const s = localDevices[deviceType];
      const isPreferred = s.preferred === localDevice.id;

      if (isPreferred)
        window.setTimeout(() => {
          switch (deviceType) {
            case EDevice.CAMERA:
              return vidyoConnector.SelectLocalCamera({ localCamera: localDevice as TLocalCamera });
            case EDevice.MICROPHONE:
              return vidyoConnector.SelectLocalMicrophone({ localMicrophone: localDevice as TLocalMicrophone });
            case EDevice.SPEAKER:
              return vidyoConnector.SelectLocalSpeaker({ localSpeaker: localDevice as TLocalSpeaker });
            default:
              return undefined;
          }
        }, 50);
      dispatchVidyo({
        type: EVidyoLocalDevicesActions.ADD_DEVICE,
        device: localDevice,
        deviceType: deviceType,
      });
    },
    onRemoved: (localDevice: TVidyoDevice<typeof deviceType>) =>
      dispatchVidyo({
        type: EVidyoLocalDevicesActions.REMOVE_DEVICE,
        device: localDevice,
        deviceType: deviceType,
      }),
    onSelected: (localDevice: TVidyoDevice<typeof deviceType>) =>
      dispatchVidyo({
        type: EVidyoLocalDevicesActions.SELECT_DEVICE,
        device: localDevice,
        deviceType: deviceType,
      }),
    onStateUpdated: (localDevice: TVidyoDevice<typeof deviceType>, state: string) =>
      dispatchVidyo({
        type: EVidyoLocalDevicesActions.CHANGE_DEVICE_STATUS,
        device: localDevice,
        deviceType: deviceType,
        status: state as EVidyoLocalDeviceStatus,
      }),
  };
}

enum VidyoDeviceTypeMuting {
  VIDYO_DEVICETYPE_LocalCamera = "VIDYO_DEVICETYPE_LocalCamera",
  VIDYO_DEVICETYPE_LocalMicrophone = "VIDYO_DEVICETYPE_LocalMicrophone",
}

const mapVidyoDeviceType: Record<VidyoDeviceTypeMuting, EDevice> = {
  [VidyoDeviceTypeMuting.VIDYO_DEVICETYPE_LocalCamera]: EDevice.CAMERA,
  [VidyoDeviceTypeMuting.VIDYO_DEVICETYPE_LocalMicrophone]: EDevice.MICROPHONE,
};

function toDeviceType(d: VidyoDeviceTypeMuting): EDevice {
  return mapVidyoDeviceType[d];
}

enum VidyoRoomModerationType {
  VIDYO_ROOMMODERATIONTYPE_SoftMute = "VIDYO_ROOMMODERATIONTYPE_SoftMute",
  VIDYO_ROOMMODERATIONTYPE_HardMute = "VIDYO_ROOMMODERATIONTYPE_HardMute",
}

type MuteHandler = (vidyoDeviceType: string, moderationType: string, state: boolean) => void;

function handleMute(vidyoDeviceType: VidyoDeviceTypeMuting, moderationType: VidyoRoomModerationType, state: boolean) {
  const deviceType = toDeviceType(vidyoDeviceType);

  if (state) {
    switch (deviceType) {
      case EDevice.CAMERA:
        dispatchVidyo({
          type: EVidyoLocalDevicesActions.MUTE_DEVICE,
          deviceType,
          shouldBeMuted: true,
        });
        break;
      case EDevice.MICROPHONE:
        dispatchVidyo({
          type: EVidyoLocalDevicesActions.MUTE_DEVICE,
          deviceType,
          shouldBeMuted: true,
        });
        break;
      default:
    }
  }
  if (moderationType === VidyoRoomModerationType.VIDYO_ROOMMODERATIONTYPE_HardMute) {
    dispatchVidyo({
      type: EVidyoLocalDevicesActions.SERVER_MUTE_DEVICE,
      deviceType,
      serverMuted: state,
    });
  }
}

export async function createLocalDeviceLifecycle(vidyoConnector: TVidyoConnector) {
  await Promise.all([
    vidyoConnector.SetCameraPrivacy({ privacy: true }),
    vidyoConnector.SetMicrophonePrivacy({ privacy: true }),
    vidyoConnector.SetSpeakerPrivacy({ privacy: true }),
    vidyoConnector.RegisterLocalCameraEventListener(generateHandlerLocalDevice(EDevice.CAMERA, vidyoConnector)),
    vidyoConnector.RegisterLocalMicrophoneEventListener(generateHandlerLocalDevice(EDevice.MICROPHONE, vidyoConnector)),
    vidyoConnector.RegisterLocalSpeakerEventListener(generateHandlerLocalDevice(EDevice.SPEAKER, vidyoConnector)),
    vidyoConnector.RegisterModerationCommandEventListener(
      IS_ELECTRON
        ? {
            onModerationCommand: handleMute as unknown as MuteHandler,
          }
        : {
            // @ts-expect-error this is normal, type defs are wrong on native webrtc
            onModerationCommandReceived: handleMute as unknown as MuteHandler,
          },
    ),
  ]);
}
