import { useCallback, useState } from "react";
import type { ApolloQueryResult } from "@apollo/client";

import {
  BigPopoverMenu,
  Cell,
  Description,
  Feedback,
  Flexbox,
  Grid,
  IconButton,
  InternalTextLink,
  Loader,
  P,
  Pagination,
  PlainAction,
  Strong,
  Table,
  TD,
  TR,
  TRTDTemp,
} from "packages/catalog";
import { EAppearance, EAttachment, EDimension, EIcon, EStatus, PATH_SUPERADMIN_ORGANISATION } from "packages/utils";

import type { GetAllOrganisationsQuery } from "packages/client/organisations/graphql/organisations.generated";

import { approveOrganisation, deleteOrganisation } from "packages/client/organisations/django/requests";
import { useGetPendingOrganisationsQuery } from "packages/client/organisations/graphql/organisations.generated";
import { useGetOrganisationUsersLazyQuery } from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";

const COLUMNS = ["ID", "Organisation", "Creator", "Parent organisation"];
const COLUMN_WIDTHS = "4rem 1.5fr 1fr 1fr 2rem";

export interface PSuperAdminPendingOrganisations {
  refetchAllOrganisations?: () => Promise<ApolloQueryResult<GetAllOrganisationsQuery>>;
}

export function SuperAdminPendingOrganisations({ refetchAllOrganisations }: PSuperAdminPendingOrganisations) {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data,
    loading,
    error,
    refetch: refetchPendingOrganisations,
  } = useGetPendingOrganisationsQuery({
    fetchPolicy: "cache-and-network",
    variables: { page: currentPage },
  });

  const [getOrganisationUsers] = useGetOrganisationUsersLazyQuery();

  const handleApproveOrganisation = useCallback(
    (organisationID: string) => {
      approveOrganisation(organisationID).then(() => {
        refetchPendingOrganisations();
        refetchAllOrganisations();
        getOrganisationUsers();
      });
    },
    [getOrganisationUsers, refetchAllOrganisations, refetchPendingOrganisations],
  );

  const handleRejectOrganisation = useCallback(
    (organisationID: string) => {
      deleteOrganisation(organisationID).then(() => refetchPendingOrganisations());
    },
    [refetchPendingOrganisations],
  );

  return (
    <>
      <RoutingChangeHandler message="The pending organisations page has loaded." pageTitle="Pending organisations" />
      <Grid noColGap>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing all pending organisations on ClassView"
            hasMenuColumn>
            <>
              {loading && (
                <TRTDTemp>
                  <Loader notFullHeight />
                </TRTDTemp>
              )}
              {error && (
                <TRTDTemp>
                  <Feedback status={EStatus.DANGER}>Unable to get list of organisations.</Feedback>
                </TRTDTemp>
              )}
              <>
                {data?.nonApprovedOrganisations?.objects.length ? (
                  <>
                    {data.nonApprovedOrganisations.objects.map(pendingOrganisation => (
                      <TR columnWidths={COLUMN_WIDTHS} key={pendingOrganisation.id}>
                        <TD heading={COLUMNS[0]}>
                          <Strong>
                            <P>{pendingOrganisation.id}</P>
                          </Strong>
                        </TD>
                        <TD heading={COLUMNS[1]}>
                          <P>{pendingOrganisation.name}</P>
                          <Description>{pendingOrganisation?.parentOrganisation?.name}</Description>
                        </TD>
                        <TD heading={COLUMNS[2]}>
                          <P>
                            {pendingOrganisation.administrators[0].firstName}{" "}
                            {pendingOrganisation.administrators[0].lastName}
                          </P>
                          <Description>{pendingOrganisation.administrators[0].email}</Description>
                        </TD>
                        <TD heading={COLUMNS[3]}>
                          <P>
                            {pendingOrganisation.parentOrganisation?.name ? (
                              <InternalTextLink
                                to={PATH_SUPERADMIN_ORGANISATION(pendingOrganisation.parentOrganisation.id)}>
                                {pendingOrganisation.parentOrganisation.name}
                              </InternalTextLink>
                            ) : (
                              "-"
                            )}
                          </P>
                        </TD>
                        <TD>
                          <BigPopoverMenu
                            attachment={EAttachment.SOUTH_SOUTH_EAST}
                            trigger={
                              <IconButton
                                appearance={EAppearance.GHOST}
                                as="div"
                                description="Options"
                                dimension={EDimension.SMALL}
                                icon={EIcon.MOREVERTICAL}
                                status={EStatus.NEUTRAL}
                              />
                            }>
                            <Flexbox as="ul" flexDirectionColumn>
                              <li>
                                <PlainAction
                                  as="button"
                                  onClick={() => handleApproveOrganisation(pendingOrganisation.id)}>
                                  Approve
                                </PlainAction>
                              </li>
                              <li>
                                <PlainAction
                                  as="button"
                                  onClick={() => handleRejectOrganisation(pendingOrganisation.id)}
                                  status={EStatus.DANGER}>
                                  Reject
                                </PlainAction>
                              </li>
                            </Flexbox>
                          </BigPopoverMenu>
                        </TD>
                      </TR>
                    ))}
                  </>
                ) : (
                  <TRTDTemp>
                    <P center>No pending organisations.</P>
                  </TRTDTemp>
                )}
              </>
            </>
          </Table>
        </Cell>
        {data?.nonApprovedOrganisations?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={data?.nonApprovedOrganisations?.hasNext}
              hasPrevious={data?.nonApprovedOrganisations?.hasPrev}
              page={data?.nonApprovedOrganisations?.page}
              pages={data?.nonApprovedOrganisations?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
    </>
  );
}
