import type { ReactNode } from "react";
import { LinkProps, NavLink, NavLinkProps } from "react-router-dom";

import { Emoji } from "packages/catalog";

import styles from "./NavigationLink.module.scss";

export interface PNavigationLink extends NavLinkProps {
  children: ReactNode;
  emoji?: string;
  hasNotification?: boolean;
  isFullWidth?: boolean;
  isOnDarkBackground?: boolean;
  to: string | LinkProps["to"];
}

export function NavigationLink({
  children,
  emoji,
  hasNotification,
  isFullWidth,
  isOnDarkBackground,
  to,
  ...rest
}: PNavigationLink) {
  return (
    <li className={isFullWidth ? styles.full : ""}>
      <NavLink
        className={({ isActive }) =>
          `${styles.navlink}${isActive ? ` ${styles.active}` : ""}${
            isOnDarkBackground ? ` ${styles.isOnDarkBackground}` : ""
          }`
        }
        to={to}
        {...rest}>
        {emoji && (
          <span className={styles.emoji}>
            <Emoji symbol={emoji} />
          </span>
        )}
        <div className={styles.children}>
          {children}
          {hasNotification && <div className={styles.hasNotification} />}
        </div>
      </NavLink>
    </li>
  );
}
