import { useCallback, useState, ChangeEvent } from "react";

import { Field, Flexbox, IconButton, Input, LabelledContent, TextButton } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

export interface PEditableFieldText<T> {
  data: string;
  label: string;
  name: T;
  onSave: (field: T, newValue: string) => void;
}

export function EditableFieldText<T>({ data, name, label, onSave }: PEditableFieldText<T>) {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(data ?? "");

  const handleTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const handleSave = useCallback(() => {
    onSave(name, value);
    setIsEditing(false);
  }, [name, onSave, value]);

  return (
    <>
      {isEditing ? (
        <Flexbox alignItemsFlexEnd>
          <Field label={label}>
            <Input autoFocus onChange={handleTextChange} name={label} type="text" value={value} />
          </Field>
          <TextButton status={EStatus.ACCENT} as="button" onClick={handleSave}>
            Save
          </TextButton>
        </Flexbox>
      ) : (
        <LabelledContent
          label={label}
          content={
            <Flexbox alignItemsCenter>
              <span>{data?.length ? data : "-"}</span>
              <IconButton
                appearance={EAppearance.GHOST}
                as="button"
                description="Edit"
                dimension={EDimension.SMALL}
                icon={EIcon.EDIT}
                status={EStatus.NEUTRAL}
                onClick={() => setIsEditing(!isEditing)}
              />
            </Flexbox>
          }
        />
      )}
    </>
  );
}
