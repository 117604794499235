import { useCallback, useEffect, useState } from "react";

import { useTypedDispatch } from "packages/client/redux";

import { useValidateRoomKeyLazyQuery } from "packages/client/incall/graphql/room.generated";
import { validateRoomKey } from "packages/client/precall/django/requests";
import { updateRoomKey } from "packages/client/sessions/redux/slice";

export function useValidateRoomKey(roomKey: string) {
  const [roomKeyError, setRoomKeyError] = useState<string>(null);

  const dispatch = useTypedDispatch();

  const setRoomKey = useCallback((meetingID: string) => dispatch(updateRoomKey(meetingID)), [dispatch]);

  const handleError = useCallback(
    (errorMessage: string) => {
      setRoomKey("");
      setRoomKeyError(errorMessage);
    },
    [setRoomKey],
  );

  const [triggerRoomExists] = useValidateRoomKeyLazyQuery({
    variables: { roomKey },
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data?.getAllRoomInfo?.roomId && roomKey.length) {
        setRoomKey(data?.getAllRoomInfo?.roomId);
      }
    },
    onError: error => handleError(error.message),
  });

  useEffect(() => {
    if (roomKey === "" || !roomKey) return;

    setRoomKeyError(null);

    let cancelled = false;

    async function checkExtension() {
      try {
        const roomKeyRes = await validateRoomKey(roomKey);
        if (roomKeyRes?.key && !cancelled) triggerRoomExists({ variables: { roomKey: roomKeyRes.key } });
        else throw new Error();
      } catch (err) {
        handleError((err as Error)?.message || "RoomKey does not exist");
        console.error(`RoomKey not found: [${(err as Error).message}]`);
      }
    }

    function checkRoomKey() {
      if (!cancelled) triggerRoomExists({ variables: { roomKey } });
    }

    const isExtension = /^\d+$/.test(roomKey);

    const timer = window.setTimeout(isExtension ? checkExtension : checkRoomKey, 250);

    return () => {
      cancelled = true;
      window.clearTimeout(timer);
    };
  }, [handleError, roomKey, triggerRoomExists]);

  return {
    roomKeyError,
  };
}
