import { useEffect, useMemo, useRef } from "react";

import styles from "./Player.module.scss";

export interface PPlayer {
  noControls?: boolean;
  noProgress?: boolean;
  source: string;
}

export function Player({ source, noControls, noProgress }: PPlayer) {
  const videoNode = useRef<HTMLVideoElement>();
  const isStreaming = useMemo(() => source && source !== "" && source.endsWith(".m3u8"), [source]);

  const videoStyles = useMemo(
    () => [styles.large, noProgress ? styles.hideProgress : undefined].filter(e => e).join(" "),
    [noProgress],
  );

  useEffect(() => {
    if (!source || source === "") return;
    if (!isStreaming) {
      videoNode.current.src = source;
      return;
    }
    let returnF = () => {
      // nothing
    };
    async function launchStreaming() {
      const videoTag = videoNode.current;
      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        videoTag.src = source;
        return;
      }
      const { default: Hls } = await import("hls.js");
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(source);
        hls.attachMedia(videoTag);
        returnF = () => {
          hls.destroy();
        };
      }
    }
    launchStreaming();
    return returnF;
  }, [noControls, source, isStreaming]);

  return (
    <div className={styles.large}>
      <video className={videoStyles} ref={videoNode} autoPlay controls={!noControls} controlsList="nodownload" />
    </div>
  );
}
