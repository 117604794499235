import { useCallback, useMemo } from "react";

import { LOCAL_RECENT_SESSIONS } from "packages/utils";

export interface ISession {
  extension: string;
  roomKey: string;
  sessionName: string;
  timeStamp: number;
}

export function useRecentSessions() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const recentSessions: any = useMemo(() => {
    const storedSessions = JSON.parse(localStorage.getItem(LOCAL_RECENT_SESSIONS));
    return storedSessions ?? [];
  }, []);

  const saveSession = useCallback(
    (roomKey: string, sessionName: string, extension?: string) => {
      const timeStamp = Date.now();
      const foundIndex = recentSessions.findIndex((session: ISession) => session.roomKey === roomKey);

      if (foundIndex !== -1) {
        recentSessions[foundIndex] = {
          ...recentSessions[foundIndex],
          timeStamp,
          extension,
        };
        recentSessions.sort((a: ISession, b: ISession) => b.timeStamp - a.timeStamp);
      } else {
        recentSessions.unshift({ timeStamp, roomKey, sessionName, extension });
      }
      const mostRecentSessions = recentSessions.slice(0, 20);

      localStorage.setItem(LOCAL_RECENT_SESSIONS, JSON.stringify(mostRecentSessions));
    },
    [recentSessions],
  );

  return {
    recentSessions,
    saveSession,
  };
}
