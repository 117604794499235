import { useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Block,
  Cell,
  Field,
  Flexbox,
  Grid,
  H1,
  H2,
  IconButton,
  Input,
  InputOption,
  InternalTextLink,
  LabelledContent,
  Loader,
  P,
  StatusBadge,
  TextButton,
} from "packages/catalog";
import {
  // PATH_WEBCAST,
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  formatLongDate,
  formatLongTime,
  formatLongWordDate,
  getDays,
  getWeekOfMonth,
  isNowAfterDate,
  isNowBetweenStartAndEnd,
  PATH_CALLLINK,
  PATH_RECORDING,
  PATH_SUPERADMIN_USER,
  toSentenceCase,
  TParams,
} from "packages/utils";

import { useGetFullSessionQuery } from "packages/client/sessions/graphql/sessions.generated";
import { editSession } from "packages/client/sessions/django/requests";

import { canJoinSession } from "packages/client/sessions/functions";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SuperAdminParticipants } from "packages/client/sessions/components";

export function SuperAdminSession() {
  const { meetingID }: TParams = useParams();

  const {
    data: sessionData,
    loading,
    refetch,
  } = useGetFullSessionQuery({
    variables: { sessionID: parseInt(meetingID, 10) },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const handleEditRecording = useCallback(() => {
    editSession(meetingID, { isRecorded: !sessionData?.meeting.isRecorded }).then(() => refetch());
  }, [meetingID, refetch, sessionData]);

  if (!sessionData) return null;
  if (loading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler message="The session page has loaded." pageTitle="Session" />
      <Grid>
        <Cell>
          <IconButton
            appearance={EAppearance.GHOST}
            as={Link}
            description="All sesssions"
            dimension={EDimension.SMALL}
            icon={EIcon.ARROWLEFT}
            label="All sessions"
            status={EStatus.NEUTRAL}
            to=".."
          />
        </Cell>
        <Cell>
          <H1>Session</H1>
        </Cell>
        <Cell as={Block}>
          <H2 hasMargin>Overview</H2>
          <Grid>
            <Cell>
              <Flexbox justifyContentSpaceBetween alignItemsFlexEnd>
                <LabelledContent
                  label="Session name"
                  content={
                    <Flexbox>
                      <span>{sessionData?.meeting.subject}</span>
                      {(sessionData?.meeting.nextRecurringStart &&
                        sessionData?.meeting.nextRecurringEnd &&
                        isNowBetweenStartAndEnd(
                          new Date(sessionData?.meeting.nextRecurringStart),
                          new Date(sessionData?.meeting.nextRecurringEnd),
                        )) ||
                      (!sessionData?.meeting.nextRecurringStart &&
                        !sessionData?.meeting.nextRecurringEnd &&
                        isNowBetweenStartAndEnd(
                          new Date(sessionData?.meeting.startTime),
                          new Date(sessionData?.meeting.endTime),
                        )) ? (
                        <StatusBadge status={EStatus.DANGER}>In progress</StatusBadge>
                      ) : null}
                    </Flexbox>
                  }
                />
                {canJoinSession(
                  sessionData?.meeting?.startTime,
                  sessionData?.meeting?.endTime,
                  true,
                  sessionData?.meeting?.nextRecurringStart,
                  sessionData?.meeting?.nextRecurringEnd,
                ) ? (
                  <Flexbox>
                    <TextButton as={Link} status={EStatus.ACCENT} to={PATH_CALLLINK(sessionData?.meeting.room.key)}>
                      Join
                    </TextButton>
                    <TextButton
                      as={Link}
                      to={{ pathname: PATH_CALLLINK(sessionData?.meeting.room.key) }}
                      state={{ moderate: true }}
                      status={EStatus.NEUTRAL}>
                      Moderate
                    </TextButton>
                  </Flexbox>
                ) : null}
              </Flexbox>
            </Cell>
            <Cell colSpanSM={6} colSpanMD={4}>
              <Flexbox flexDirectionColumn hasDoubleGap>
                <LabelledContent label="ID" content={sessionData?.meeting.id} />
                <LabelledContent
                  label="Organiser"
                  content={
                    <InternalTextLink to={PATH_SUPERADMIN_USER(sessionData?.meeting.meetingOrganiser.id)}>
                      {sessionData?.meeting.meetingOrganiser.firstName} {sessionData?.meeting.meetingOrganiser.lastName}
                    </InternalTextLink>
                  }
                />
              </Flexbox>
            </Cell>
            <Cell colSpanSM={6} colSpanMD={4}>
              <Flexbox flexDirectionColumn hasDoubleGap>
                <LabelledContent
                  label="Original start"
                  content={`${formatLongTime(new Date(sessionData?.meeting.startTime))} ${formatLongWordDate(
                    new Date(sessionData?.meeting.startTime),
                  )}`}
                />
                <LabelledContent
                  label="Original end"
                  content={`${formatLongTime(new Date(sessionData?.meeting.endTime))} ${formatLongWordDate(
                    new Date(sessionData?.meeting.endTime),
                  )}`}
                />
              </Flexbox>
            </Cell>
            {sessionData?.meeting.nextRecurringStart && (
              <Cell colSpanSM={6} colSpanMD={4}>
                <LabelledContent
                  label="Next start"
                  content={`${formatLongTime(new Date(sessionData?.meeting.nextRecurringStart))} ${formatLongWordDate(
                    new Date(sessionData?.meeting.nextRecurringStart),
                  )}`}
                />
              </Cell>
            )}
          </Grid>
        </Cell>
        <Cell as={Block}>
          <H2 hasMargin>Participants</H2>
          <Grid>
            <Cell colSpanSM={6} colSpanMD={4} colSpanLG={3}>
              <SuperAdminParticipants
                collection="moderators"
                endpoint={`/meetings/${sessionData?.meeting.id}/`}
                participants={sessionData?.meeting?.moderators.map(moderator => {
                  return { id: moderator.id, firstName: moderator.firstName, lastName: moderator.lastName };
                })}
                onAdded={refetch}
              />
            </Cell>
            <Cell colSpanSM={6} colSpanMD={4} colSpanLG={3}>
              <SuperAdminParticipants
                collection="lecturers"
                endpoint={`/meetings/${sessionData?.meeting.id}/`}
                participants={sessionData?.meeting?.lecturers.map(lecturers => {
                  return { id: lecturers.id, firstName: lecturers.firstName, lastName: lecturers.lastName };
                })}
                onAdded={refetch}
              />
            </Cell>
            <Cell colSpanSM={6} colSpanMD={4} colSpanLG={3}>
              <SuperAdminParticipants
                collection="users"
                endpoint={`/meetings/${sessionData?.meeting.id}/`}
                participants={sessionData?.meeting?.users.map(users => {
                  return { id: users.id, firstName: users.firstName, lastName: users.lastName };
                })}
                onAdded={refetch}
              />
            </Cell>
            <Cell colSpanSM={6} colSpanMD={4} colSpanLG={3}>
              <LabelledContent
                label="Invited guests"
                content={
                  sessionData?.meeting?.guests?.length ? (
                    <>
                      <ul>{sessionData?.meeting.guests.map(g => <li key={`${g}`}>{g}</li>)}</ul>
                    </>
                  ) : (
                    "None"
                  )
                }
              />
            </Cell>
          </Grid>
        </Cell>
        <Cell as={Block}>
          <H2 hasMargin>Features</H2>
          <Grid>
            <Cell colSpanMD={4}>
              <LabelledContent
                label="Lecture mode enabled"
                content={sessionData?.meeting.isLectureMode ? "Yes" : "No"}
              />
            </Cell>
            <Cell colSpanMD={4}>
              <Flexbox flexDirectionColumn hasDoubleGap>
                <Field label="Recording">
                  <InputOption label="Enabled">
                    <Input
                      checked={sessionData?.meeting?.isRecorded}
                      name="recording"
                      onChange={handleEditRecording}
                      type="checkbox"
                    />
                  </InputOption>
                </Field>
                <LabelledContent
                  content={
                    sessionData?.meeting.recordingPlaybackUrl ? (
                      <P>
                        <Link
                          to={PATH_RECORDING(sessionData?.meeting.recordingPlaybackUrl)}
                          target="_blank"
                          rel="noreferrer noopener">
                          {sessionData?.meeting.recordingPlaybackUrl}
                        </Link>
                      </P>
                    ) : (
                      <P>N/A</P>
                    )
                  }
                  label="Recording URL"
                />
              </Flexbox>
            </Cell>
            <Cell colSpanMD={4}>
              <Flexbox flexDirectionColumn hasDoubleGap>
                <LabelledContent
                  content={sessionData?.meeting.isWebcastMode ? "Yes" : "No"}
                  label="Livestream enabled"
                />
                {/* <LabelledContent
                  content={
                    sessionData?.meeting.webcastUrl ? (
                      <P>
                        <Link
                          to={PATH_WEBCAST(btoa(sessionData?.meeting.webcastUrl))}
                          target="_blank"
                          rel="noreferrer noopener">
                          Link to livestream
                        </Link>
                      </P>
                    ) : (
                      <P>N/A</P>
                    )
                  }
                  label="Livestream URL"
                /> */}
              </Flexbox>
            </Cell>
          </Grid>
        </Cell>
        {sessionData?.meeting.nextRecurringStart && (
          <Cell as={Block}>
            <Grid>
              <Cell>
                <H2>Repeating settings</H2>
              </Cell>
              <Cell colSpanMD={4}>
                <LabelledContent
                  content={toSentenceCase(sessionData?.meeting.recurringInterval)}
                  label="Occurrence type"
                />
              </Cell>
              <Cell colSpanMD={4}>
                <LabelledContent
                  content={
                    sessionData?.meeting.recurringOnDays
                      ? getDays(sessionData?.meeting.recurringOnDays)
                      : sessionData?.meeting.recurringWeekdaysOnly
                        ? "Weekdays only"
                        : "N/A"
                  }
                  label="Repeats on"
                />
              </Cell>
              <Cell colSpanMD={4}>
                <LabelledContent
                  content={
                    sessionData?.meeting.recurringRepetitions
                      ? `After ${sessionData?.meeting.recurringRepetitions} repetitions`
                      : formatLongDate(new Date(sessionData?.meeting.recurringEndDate))
                  }
                  label={`Ends${sessionData?.meeting.recurringEndDate ? " on date" : ""}`}
                />
              </Cell>
              {!sessionData?.meeting.recurringWeekdaysOnly && (
                <Cell colSpanMD={4}>
                  <LabelledContent
                    content={`${
                      sessionData?.meeting.recurringEveryXWeeks
                        ? `${sessionData?.meeting.recurringEveryXWeeks} weeks`
                        : sessionData?.meeting.recurringEveryXMonths
                          ? `${sessionData?.meeting.recurringEveryXMonths} months`
                          : "N/A"
                    }${
                      sessionData?.meeting.recurringOnXWeekOfMonth
                        ? ` on the ${getWeekOfMonth(sessionData?.meeting.recurringOnXWeekOfMonth)} week of the month`
                        : ""
                    }`}
                    label="Repeats every"
                  />
                </Cell>
              )}
            </Grid>
          </Cell>
        )}
        {sessionData?.meeting.nextRecurringStart && (
          <Cell as={Block}>
            <Grid>
              <Cell>
                <H2>Occurrences</H2>
              </Cell>
              <Cell style={{ maxHeight: "30rem", overflow: "auto" }}>
                {sessionData?.meeting.recurringDates.map(session => (
                  <P key={session}>
                    {isNowAfterDate(new Date(session)) ? (
                      <del>
                        {formatLongTime(new Date(session))} {formatLongWordDate(new Date(session))}
                      </del>
                    ) : (
                      `${formatLongTime(new Date(session))} ${formatLongWordDate(new Date(session))}`
                    )}
                  </P>
                ))}
              </Cell>
            </Grid>
          </Cell>
        )}
      </Grid>
    </>
  );
}
