import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
/* eslint-disable-next-line no-restricted-imports */
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { TRootState, TDispatchFunction } from "packages/client/redux/types";

import { authenticationReducer } from "packages/client/authentication/redux/slice";
import { chatReducer } from "packages/client/incall/redux/slice";
import { feedbackFormReducer } from "packages/client/feedback-form/redux/slice";
import { layoutReducer } from "packages/client/layout/redux/slice";
import { pollsReducer } from "packages/client/polls/redux/slice";
import { sessionReducer, sessionFormReducer } from "packages/client/sessions/redux/slice";

const appReducer = combineReducers({
  authentication: authenticationReducer,
  chat: chatReducer,
  feedbackForm: feedbackFormReducer,
  layout: layoutReducer,
  polls: pollsReducer,
  sessionForm: sessionFormReducer,
  sessions: sessionReducer,
});

export const instanceClientRootStore = configureStore({
  reducer: appReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["sessionForm/changeEndTime", "sessionForm/changeStartTime"],
        ignoredPaths: ["sessionForm"],
      },
    });
  },
  devTools: NODE_ENV === "development",
});

export const useTypedDispatch: TDispatchFunction = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<TRootState> = useSelector;
