import { Link } from "react-router-dom";

import { EDimension, PATH_USER } from "packages/utils";

import type { UserType } from "packages/client/graphql_definitions.generated";

import { UserAvatar } from "packages/client/users/components";

import styles from "./UserAvatarList.module.scss";

export interface PUserAvatarList {
  users: Partial<UserType>[];
}

export function UserAvatarList({ users }: PUserAvatarList) {
  return (
    <ul className={styles.grid}>
      {users.map(user => (
        <li key={user.id}>
          <Link style={{ display: "block" }} to={PATH_USER(user.id)}>
            <UserAvatar
              dimension={EDimension.SMALL}
              email={user.email}
              fullName={`${user.firstName} ${user.lastName}`}
              profilePictureURL={user.profilePicture}
            />
          </Link>
        </li>
      ))}
    </ul>
  );
}
