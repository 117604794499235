import { CSSProperties, ReactNode, useEffect, useRef } from "react";

import { Grid } from "packages/catalog";

import type { Screens } from "packages/client/onboarding/enums";

const markerStyles: CSSProperties = {
  position: "absolute",
  top: "-12rem",
};

export interface PRegister {
  currentScreen: Screens;
  renderScreen: ReactNode;
}

export function Register({ currentScreen, renderScreen }: PRegister) {
  const marker = useRef<HTMLInputElement>(null);

  useEffect(() => {
    marker.current.scrollIntoView();
  }, [currentScreen]);

  return (
    <Grid noColGap>
      <div style={markerStyles} ref={marker} />
      <>{renderScreen}</>
    </Grid>
  );
}
