import { findRemoteSharing } from "../functions";

import { EDevice } from "../../local-devices/enums/EDevice";

import {
  EVidyoStreamActions,
  IAddRemoteDeviceAction,
  IRemoveRemoteDeviceAction,
  IStreamsState,
  IUpdateRemoteDeviceStateAction,
  TMapUserIDDeviceStatuses,
  TStreamsAction,
} from "./types";

const initialState: IStreamsState = {
  // mapCamerasPTZ: {},
  isThereARemoteSharing: false,
  mapParticipantCameras: {},
  mapParticipantMicrophones: {},
  pinnedStreamID: "",
  videoStreams: [],
};

function deviceReducer(
  map: TMapUserIDDeviceStatuses,
  action: IAddRemoteDeviceAction | IRemoveRemoteDeviceAction | IUpdateRemoteDeviceStateAction,
): TMapUserIDDeviceStatuses {
  switch (action.type) {
    case EVidyoStreamActions.ADD_REMOTE_DEVICE: {
      const copy = { ...map };
      const { participantUserID: pID, deviceStatus } = action;
      if (!copy[pID]) copy[pID] = [];
      copy[pID] = [...copy[pID], deviceStatus];
      return copy;
    }
    case EVidyoStreamActions.REMOVE_REMOTE_DEVICE: {
      const copy = { ...map };
      const { participantUserID: pID, deviceStatus } = action;
      copy[pID] = copy[pID].filter(d => d.deviceId !== deviceStatus.deviceId);
      if (copy[pID].length === 0) delete copy[pID];
      return copy;
    }
    case EVidyoStreamActions.UPDATE_REMOTE_DEVICE_STATE: {
      const copy = { ...map };
      const { participantUserID: pID, deviceStatus } = action;
      copy[pID] = [...copy[pID].filter(d => d.deviceId !== deviceStatus.deviceId), deviceStatus];
      return copy;
    }
    default:
      return map;
  }
}

export function streamsReducer(state = initialState, action: TStreamsAction): IStreamsState {
  switch (action.type) {
    case EVidyoStreamActions.PIN_STREAM:
      return {
        ...state,
        pinnedStreamID: action.pinnedStreamID,
      };
    case EVidyoStreamActions.ADD_STREAM: {
      const newStreams = [...state.videoStreams, action.stream];
      return {
        ...state,
        videoStreams: newStreams,
        isThereARemoteSharing: findRemoteSharing(newStreams),
        // mapCamerasPTZ: {
        //   ...state.mapCamerasPTZ,
        //   [action.stream.stream.id]: action.isPtz,
        // },
      };
    }
    case EVidyoStreamActions.REMOVE_STREAM: {
      const newStreams = state.videoStreams.filter(s => s.stream.id !== action.stream.stream.id);
      // const newPTZ = { ...state.mapCamerasPTZ };
      // if (newPTZ[action.stream.stream.id]) delete newPTZ[action.stream.stream.id];
      return {
        ...state,
        videoStreams: newStreams,
        isThereARemoteSharing: findRemoteSharing(newStreams),
        pinnedStreamID: state.pinnedStreamID === action.stream.stream.id ? "" : state.pinnedStreamID,
        // mapCamerasPTZ: newPTZ,
      };
    }
    case EVidyoStreamActions.ADD_REMOTE_DEVICE:
    case EVidyoStreamActions.REMOVE_REMOTE_DEVICE:
    case EVidyoStreamActions.UPDATE_REMOTE_DEVICE_STATE: {
      switch (action.deviceStatus.deviceType) {
        case EDevice.CAMERA:
          return {
            ...state,
            mapParticipantCameras: deviceReducer(state.mapParticipantCameras, action),
          };
        case EDevice.MICROPHONE:
          return {
            ...state,
            mapParticipantMicrophones: deviceReducer(state.mapParticipantMicrophones, action),
          };
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
