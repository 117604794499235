import { useGetEssentialSessonQuery } from "packages/client/sessions/graphql/sessions.generated";

export function useGetEssentialSession(sessionID: number) {
  const { data, loading, error, refetch, startPolling, stopPolling } = useGetEssentialSessonQuery({
    variables: { sessionID },
    fetchPolicy: "cache-and-network",
    skip: !sessionID,
  });
  return { session: data?.meeting, loading, error, refetch, startPolling, stopPolling };
}
