import type { TLocalMonitor } from "../../local-devices/types";
import type { TLocalWindowShare } from "../../streams/types";

export enum EVidyoSharingActions {
  ADD_MONITOR = "sharing/addmonitor",
  ADD_WINDOW_SHARE = "sharing/addwindowshare",
  REMOVE_MONITOR = "sharing/removemonitor",
  REMOVE_WINDOW_SHARE = "sharing/removewindowshare",
  SELECT_MONITOR = "sharing/selectmonitor",
  SELECT_WINDOW_SHARE = "sharing/selectwindowshare",
}

export interface ISharingState {
  isSharing: boolean;
  monitor: IScreenState<TLocalMonitor>;
  passiveSharingAvailable: boolean;
  windowShare: IScreenState<TLocalWindowShare>;
}

export interface IScreenState<T extends TLocalMonitor | TLocalWindowShare> {
  available: T[];
  selected: T;
}

export interface IAddMonitorAction {
  type: EVidyoSharingActions.ADD_MONITOR;
  monitor: TLocalMonitor;
}

export interface IAddWindowShareAction {
  type: EVidyoSharingActions.ADD_WINDOW_SHARE;
  windowShare: TLocalWindowShare;
}

export interface IRemoveMonitorAction {
  type: EVidyoSharingActions.REMOVE_MONITOR;
  monitor: TLocalMonitor;
}

export interface IRemoveWindowShareAction {
  type: EVidyoSharingActions.REMOVE_WINDOW_SHARE;
  windowShare: TLocalWindowShare;
}

export interface ISelectMonitorAction {
  type: EVidyoSharingActions.SELECT_MONITOR;
  monitor: TLocalMonitor;
}

export interface ISelectWindowShareAction {
  type: EVidyoSharingActions.SELECT_WINDOW_SHARE;
  windowShare: TLocalWindowShare;
}

export type TSharingAction =
  | IAddMonitorAction
  | IAddWindowShareAction
  | IRemoveMonitorAction
  | IRemoveWindowShareAction
  | ISelectMonitorAction
  | ISelectWindowShareAction;
