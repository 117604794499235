//import { useMemo } from "react";
import { createPortal } from "react-dom";

import type { ETheme } from "../types";

export interface PStyleHeadRenderer {
  content: string;
  theme: ETheme;
}

export default function StyleHeadRenderer({ content, theme }: PStyleHeadRenderer) {
  const head = document.getElementsByTagName("head")?.[0];
  return createPortal(<style id="themeValues">{content}</style>, head, theme);
}
