import { useCallback } from "react";

import { editRoom } from "packages/client/incall/django/requests";
import { useGetRoomSettingsQuery } from "packages/client/incall/graphql/room.generated";
import { useRoom } from "packages/client/incall/hooks";

export function useScreenSharingPermission() {
  const { isModerator, room } = useRoom();
  const {
    data,
    loading: isLoading,
    refetch,
  } = useGetRoomSettingsQuery({
    variables: {
      roomKey: room?.roomId,
    },
    returnPartialData: true,
    pollInterval: 5 * 1000,
    skip: !room?.roomId,
  });

  const updateScreenSharingPermission = useCallback(
    async (localScreenSharingPermission: boolean) => {
      if (!isModerator || !room?.roomId) return;
      try {
        await editRoom(room?.roomId, { screenSharingParticipants: localScreenSharingPermission });
        await refetch();
      } catch (error) {
        console.error("Unable to update screen sharing permission.");
      }
    },
    [isModerator, room, refetch],
  );

  const screenSharingPermission = data?.getAllRoomInfo?.roomInfo?.screenSharingParticipant ?? false;

  return {
    screenSharingPermission: screenSharingPermission,
    updateScreenSharingPermission,
    isLoading,
  };
}
