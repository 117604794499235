import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { Cell, Feedback, Field, Grid, H1, Input, InputOption, InternalTextLink, P } from "packages/catalog";
import {
  EStatus,
  LOCAL_REMEMBER_ME,
  PATH_FORGOTPASSWORD,
  PATH_PRECALL_JOINCALL,
  PATH_REGISTER,
  SEGMENT_JOINCALL,
  SEGMENT_JOINOPTIONS,
  TParams,
} from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { useAuth } from "packages/client/authentication/hooks";
import { setIs2FACodeSent } from "packages/client/authentication/redux/slice";

import { getRememberMeFromLocalStorage } from "packages/client/authentication/functions";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function SignIn() {
  const { is2FACodeSent, isSignedIn } = useTypedSelector(({ authentication }) => ({
    is2FACodeSent: authentication.is2FACodeSent,
    isSignedIn: authentication.isSignedIn,
  }));
  const dispatch = useTypedDispatch();

  const { callID }: TParams = useParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [rememberMe, setRememberMe] = useState(getRememberMeFromLocalStorage());

  const { signIn, isSigningIn, signingInError } = useAuth();
  const { state }: { state?: { from?: string } } = useLocation();

  const from = state?.from;

  const handleChangeCode = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (/^\d+$/.test(e.currentTarget.value)) setCode(e.currentTarget.value);
  }, []);

  const handleChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  }, []);

  const handleChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  }, []);

  const handleChangeRememberMe = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    localStorage.setItem(LOCAL_REMEMBER_ME, `${e.target.checked}`);
    setRememberMe(getRememberMeFromLocalStorage());
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      code.length ? signIn(email, password, code) : signIn(email, password);
    },
    [signIn, email, password, code],
  );

  useEffect(() => {
    return () => {
      dispatch(setIs2FACodeSent(false));
    };
  }, [dispatch]);

  if (isSignedIn) {
    return <Navigate replace to={from ?? PATH_PRECALL_JOINCALL(callID)} />;
  }

  return (
    <>
      <RoutingChangeHandler message="The sign in page has loaded" pageTitle="Sign in" />
      <H1 center hasMargin>
        Sign in
      </H1>
      <Grid as="form" onSubmit={handleSubmit} noColGap>
        {signingInError && (
          <Cell>
            <Feedback center status={EStatus.DANGER}>
              {signingInError}
            </Feedback>
          </Cell>
        )}
        {is2FACodeSent ? (
          <>
            <Cell>
              <Feedback center status={EStatus.NEUTRAL}>
                {"We've sent a verification code to your email address. Please submit the code below to sign in."}
              </Feedback>
            </Cell>
            <Cell>
              <Field label="Verification code">
                <Input
                  autoComplete="one-time-code"
                  inputMode="numeric"
                  maxLength={8}
                  name="code"
                  onChange={handleChangeCode}
                  required
                  type="text"
                  value={code}
                />
              </Field>
            </Cell>
          </>
        ) : (
          <>
            <Cell>
              <Field label="Email address">
                <Input
                  autoComplete="email"
                  disabled={isSigningIn}
                  maxLength={50}
                  name="email"
                  onChange={handleChangeEmail}
                  required
                  type="email"
                  value={email}
                />
              </Field>
            </Cell>
            <Cell>
              <Field
                label="Password"
                description="Your password is at least 8 characters long, contains at least one number and has a mixture of uppercase and lowercase letters">
                <Input
                  autoComplete="current-password"
                  disabled={isSigningIn}
                  maxLength={50}
                  name="current-password"
                  onChange={handleChangePassword}
                  required
                  type="password"
                  value={password}
                />
              </Field>
            </Cell>
            <Cell>
              <InputOption description="Remembers your email address and password" label="Stay signed in?">
                <Input checked={rememberMe} name="remember-me" onChange={handleChangeRememberMe} type="checkbox" />
              </InputOption>
            </Cell>
          </>
        )}
        <Cell center>
          <Input
            disabled={
              isSigningIn ||
              !email.length ||
              !password.length ||
              password.length < 8 ||
              (is2FACodeSent && code.length < 8)
            }
            type="submit"
            value="Sign in"
          />
        </Cell>
        <Cell center>
          <P>
            {from?.includes(SEGMENT_JOINCALL) || from?.includes(SEGMENT_JOINOPTIONS) ? (
              <>
                <InternalTextLink to={PATH_PRECALL_JOINCALL(callID)}>Prefer to join as guest?</InternalTextLink> |{" "}
              </>
            ) : (
              <>
                <InternalTextLink to={PATH_REGISTER}>Create an account</InternalTextLink> |{" "}
              </>
            )}
            <InternalTextLink to={PATH_FORGOTPASSWORD(callID)}>Forgotten your password?</InternalTextLink>
          </P>
        </Cell>
      </Grid>
    </>
  );
}
