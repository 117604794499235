import { ReactNode, useMemo } from "react";

import { Description, Flexbox, Toggle } from "packages/catalog";

import styles from "./ToggleOption.module.scss";

export interface PToggleOption {
  children: ReactChild<typeof Toggle>;
  description?: string;
  label: string | ReactNode;
}

export function ToggleOption({ children, description, label }: PToggleOption) {
  const toggleOptionClasses = useMemo(
    () => [styles.toggleOption, children.props.required && styles.required].filter(e => e).join(" "),
    [children.props.required],
  );

  const labelClasses = useMemo(
    () => [styles.label, children.props.disabled && styles.disabled].filter(e => e).join(" "),
    [children.props.disabled],
  );

  return (
    <label className={toggleOptionClasses}>
      <Flexbox alignItemsCenter justifyContentSpaceBetween>
        <span className={labelClasses}>{label}</span>
        {children}
      </Flexbox>
      {description && <Description className={styles.description}>{description}</Description>}
    </label>
  );
}
