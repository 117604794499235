import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useCallInformation } from "packages/call";
import { Accordion, Cell, Flexbox, Grid, H1, H2, Illustration, Loader, P, TextButton } from "packages/catalog";
import { EAppearance, EIllustration, EStatus, PATH_PRECALL_JOINCALL, PATH_TLD, TParams } from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { updatePIN, updateRoomKey } from "packages/client/sessions/redux/slice";

import { useRoom } from "packages/client/incall/hooks";

import { FeedbackForm } from "packages/client/feedback-form/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./LeftSession.module.scss";

export function LeftSession() {
  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { callID }: TParams = useParams();
  const navigate = useNavigate();

  const { isSignedIn } = useTypedSelector(({ authentication, sessions }) => ({
    guestName: authentication.guestName,
    isSignedIn: authentication.isSignedIn,
    roomKey: sessions.roomKey,
    roomPin: sessions.pin,
  }));

  const { isKicked, isTerminated, resetLeaveStatus, resetVidyoCallStatus } = useCallInformation();
  const { isModerator } = useRoom(callID);

  const dispatch = useTypedDispatch();

  const handleOnClickDashboard = useCallback(() => {
    resetVidyoCallStatus();
    resetLeaveStatus();
    dispatch(updateRoomKey(""));
    dispatch(updatePIN(""));
    navigate(PATH_TLD);
  }, [dispatch, navigate, resetLeaveStatus, resetVidyoCallStatus]);

  const handleOnClickRejoin = useCallback(() => {
    resetVidyoCallStatus();
    resetLeaveStatus();
    navigate(PATH_PRECALL_JOINCALL(callID));
  }, [callID, navigate, resetLeaveStatus, resetVidyoCallStatus]);

  // so leaving the call isn't jarring, let's give a little delay and animation
  useEffect(() => {
    let cancelled = false;
    setIsLeaving(true);

    window.setTimeout(() => {
      if (cancelled) return;
      setIsLeaving(false);
    }, 1500);

    return () => {
      cancelled = true;
    };
  }, []);

  return (
    <Grid>
      {isLeaving ? (
        <Cell>
          <Loader message="Leaving session..." notFullHeight />
        </Cell>
      ) : (
        <>
          <RoutingChangeHandler message="The post-session page has loaded." pageTitle="Left session" />
          <Cell>
            <H1 center>{"You've left the session"}</H1>
          </Cell>
          <Cell>
            <Illustration className={styles.illustration} illustration={EIllustration.EXPLORE} />
          </Cell>
          {isKicked && (
            <Cell>
              <P center>
                {isModerator
                  ? "You or another moderator has ended the session for everyone."
                  : "The organiser either ended the session or you have been removed."}
              </P>
            </Cell>
          )}
          {isTerminated && (
            <Cell>
              <P center>The session has ended.</P>
            </Cell>
          )}
          <Cell>
            <P center>See you again!</P>
          </Cell>
          <Cell>
            <Flexbox justifyContentCenter>
              {!isTerminated && !isKicked && (
                <TextButton appearance={EAppearance.GHOST} onClick={handleOnClickRejoin}>
                  Rejoin session
                </TextButton>
              )}
              {isSignedIn && (
                <TextButton appearance={EAppearance.FILL} onClick={handleOnClickDashboard} status={EStatus.ACCENT}>
                  Return to dashboard
                </TextButton>
              )}
            </Flexbox>
          </Cell>
          <Cell>
            <Accordion heading="Send feedback">
              <H2 hasMargin>{success ? "Thank you" : "Provide session feedback"}</H2>
              {success ? (
                <P hasMargin>Your feedback is really appreciated and helps us build a better product experience.</P>
              ) : (
                <FeedbackForm setSuccess={setSuccess} />
              )}
            </Accordion>
          </Cell>
        </>
      )}
    </Grid>
  );
}
