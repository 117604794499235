import { ChangeEvent, useCallback, useEffect } from "react";

import { Cell, Field, Input, InputOption } from "packages/catalog";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { changePIN, toggleIsPINProtected } from "packages/client/sessions/redux/slice";

export function EditPIN() {
  const dispatch = useTypedDispatch();
  const { pinProtected, room } = useTypedSelector(({ sessionForm }) => ({
    pinProtected: sessionForm.pinProtected,
    room: sessionForm.room,
  }));

  const handlePINChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const pin = e.currentTarget.value;
      if (pin === "") dispatch(changePIN(null));
      if (/^\d+$/.test(pin)) dispatch(changePIN(parseInt(`${pin}`)));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!pinProtected) {
      dispatch(changePIN(null));
    }
  }, [dispatch, pinProtected]);

  return (
    <>
      <Cell>
        <InputOption label="Require a PIN to join the session">
          <Input
            checked={pinProtected}
            name="require-pin"
            onChange={e => dispatch(toggleIsPINProtected(e.currentTarget.checked))}
            type="checkbox"
          />
        </InputOption>
      </Cell>
      {pinProtected && (
        <Cell>
          <Field description="Participants will receive the PIN in their invitation email" label="PIN">
            <Input
              inputMode="numeric"
              maxLength={4}
              minLength={4}
              name="pin"
              onChange={handlePINChange}
              pattern="^[0-9]*$"
              required
              type="text"
              value={room?.pin ?? ""}
            />
          </Field>
        </Cell>
      )}
    </>
  );
}
