import { HTMLProps, useMemo } from "react";

import type { TD } from "packages/catalog";

import styles from "./TR.module.scss";

export interface PTR extends HTMLProps<HTMLTableRowElement> {
  children: OneOrManyReactChildren<typeof TD> | HTMLElement;
  columnWidths: string;
}

export function TR({ children, columnWidths, ...rest }: PTR) {
  const trCellsStyle = useMemo(() => {
    return { gridTemplateColumns: `${columnWidths}` };
  }, [columnWidths]);

  return (
    <tr className={styles.tr} style={trCellsStyle} {...rest}>
      {children}
    </tr>
  );
}
