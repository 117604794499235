import { Icon } from "packages/catalog";
import { EIcon, isNowBetweenStartAndEnd } from "packages/utils";

import styles from "./InProgress.module.scss";

export interface PInProgress {
  endTime: string;
  startTime: string;
}

export function InProgress({ endTime, startTime }: PInProgress) {
  return (
    <>
      {isNowBetweenStartAndEnd(new Date(startTime), new Date(endTime)) ? (
        <Icon aria-label="In progress" className={styles.progress} icon={EIcon.CIRCLE} title="In progress" />
      ) : null}
    </>
  );
}
