import { HTMLProps, useMemo } from "react";

import { Icon } from "packages/catalog";
import { EIcon } from "packages/utils";

import styles from "./Dropdown.module.scss";

export interface PDropdown extends HTMLProps<HTMLSelectElement> {
  fixedOrderOptions?: { key: string; value: string }[];
  icon?: EIcon;
  options?: Record<string, string>;
}

export function Dropdown({ className, defaultValue, fixedOrderOptions, icon, options, required, ...rest }: PDropdown) {
  const wrapperClasses = useMemo(() => [styles.wrapper, className && className].filter(e => e).join(" "), [className]);

  const selectClasses = useMemo(() => [styles.dropdown, icon && styles.pad].filter(e => e).join(" "), [icon]);

  // some dodgy stuff regarding defaultValue due to legacy form_container validation behaviour in the registration flow. TODO revisit once registration process is re-done.

  return (
    <div className={wrapperClasses}>
      {icon && <Icon className={styles.icon} icon={icon} />}
      <select aria-required={required} className={selectClasses} {...rest}>
        {defaultValue && (
          <option hidden key={"initial"} value={defaultValue}>
            {defaultValue}
          </option>
        )}
        {!fixedOrderOptions
          ? Object.keys(options).map(key => {
              return (
                <option className={styles.option} key={key} value={key}>
                  {options[key]}
                </option>
              );
            })
          : fixedOrderOptions.map(option => {
              return (
                <option className={styles.option} key={option.key} value={option.key}>
                  {option.value}
                </option>
              );
            })}
      </select>
      <Icon className={styles.arrow} icon={EIcon.CHEVRONDOWN} />
    </div>
  );
}
