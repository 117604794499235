import { useEffect, useState, useCallback } from "react";

import { useTypedSelector } from "packages/client/redux";

import type { GetFeaturesByRoomIdRes } from "packages/client/graphql_definitions.generated";

import { useGetOrganisationUserLazyQuery } from "packages/client/users/graphql/users.generated";
import {
  useGetOrganisationRoomLazyQuery,
  useGetFeaturesRoomLazyQuery,
} from "packages/client/incall/graphql/room.generated";

import { generateOrganisationLogoURLAuthenticated } from "packages/client/organisations/s3";
import { getOrganisation } from "packages/client/organisations/django/requests";

const defaultValue: GetFeaturesByRoomIdRes = {
  __typename: "GetFeaturesByRoomIdRes",
  hideCollaboration: true,
  privateInvites: false,
  recordingDisabled: false,
  roomId: "",
};

export function useGetCallOrganisation() {
  const { userID, roomKey } = useTypedSelector(({ authentication, sessions }) => ({
    userID: authentication.userID,
    roomKey: sessions.roomKey,
  }));

  const [orgName, setOrgName] = useState<string>(undefined);
  const [logoToDisplay, setLogoToDisplay] = useState<string>(undefined);
  const [features, setFeatures] = useState<GetFeaturesByRoomIdRes>(undefined);

  const onOrgIDHandler = useCallback(async (orgIDs: readonly number[]) => {
    let u;
    const orgData = await getOrganisation(orgIDs[0]);
    setOrgName(orgData.name);
    if (!orgData.rebranding) return;
    if (orgData.logo && orgData.logo !== "") u = orgData.logo;
    if (!orgData.logo && orgIDs[1]) {
      const parentOrgData = await getOrganisation(orgIDs[1]);
      if (!parentOrgData.rebranding) return;
      if (parentOrgData.logo && parentOrgData.logo !== "") u = parentOrgData.logo;
    }
    if (u) setLogoToDisplay(generateOrganisationLogoURLAuthenticated(u));
  }, []);

  const [getUserOrg] = useGetOrganisationUserLazyQuery({
    variables: { userID: parseInt(userID) },
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const myOrgID = parseInt(data.user.organisation.id, 10);
      const parentID = data.user.organisation?.parentOrganisation?.id
        ? parseInt(data.user.organisation?.parentOrganisation?.id, 10)
        : undefined;
      const o = [myOrgID, parentID].filter(e => e);
      onOrgIDHandler(o);
    },
  });

  const [getRoomOrg] = useGetOrganisationRoomLazyQuery({
    variables: { roomKey },
    fetchPolicy: "cache-and-network",
    onCompleted: data => onOrgIDHandler(data.getAllRoomInfo.roomTypeLink.organisation),
  });

  const [getFeatures] = useGetFeaturesRoomLazyQuery({
    variables: { roomKey },
    fetchPolicy: "network-only",
    onCompleted: data => {
      const features = data?.getFeaturesByRoomId;
      setFeatures(features);
    },
  });

  useEffect(() => {
    if (roomKey) getRoomOrg();
    else if (userID) getUserOrg();
  }, [getRoomOrg, getUserOrg, userID, roomKey]);

  useEffect(() => {
    if (roomKey) getFeatures();
  }, [getFeatures, roomKey]);

  return {
    logo: logoToDisplay,
    orgName,
    features: features ? features : defaultValue,
  };
}
