import { useCallback, useState } from "react";

import { Cell, Grid, H2, Illustration, P, SidebarBack, SidebarHeader, SidebarMain, TextButton } from "packages/catalog";
import { EAppearance, EIllustration, EStatus } from "packages/utils";

import { FeedbackForm } from "packages/client/feedback-form/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./InCallFeedback.module.scss";

export function InCallFeedback() {
  const [success, setSuccess] = useState<boolean>(false);

  const handleNewFeedback = useCallback(() => {
    setSuccess(false);
  }, []);

  if (success) {
    return (
      <>
        <RoutingChangeHandler message="The feedback success page has loaded." />
        <SidebarHeader>
          <SidebarBack backTo=".." closeTo="../.." label="Settings" />
          <H2>Thank you</H2>
        </SidebarHeader>
        <SidebarMain>
          <Grid noColGap>
            <Cell>
              <P hasMargin>Your feedback is really appreciated and helps us build a better product experience.</P>
              <Illustration className={styles.illustration} illustration={EIllustration.WOMAN_HEARTS} />
            </Cell>
            <Cell center>
              <TextButton appearance={EAppearance.GHOST} onClick={handleNewFeedback} status={EStatus.ACCENT}>
                Leave more feedback
              </TextButton>
            </Cell>
          </Grid>
        </SidebarMain>
      </>
    );
  }

  return (
    <>
      <RoutingChangeHandler message="The feedback form has loaded." />
      <SidebarHeader>
        <SidebarBack backTo=".." closeTo="../.." label="Settings" />
        <H2>Provide session feedback</H2>
      </SidebarHeader>
      <SidebarMain>
        <FeedbackForm setSuccess={setSuccess} />
      </SidebarMain>
    </>
  );
}
