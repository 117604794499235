import { Link } from "react-router-dom";

import { BigPopoverMenu, Block, Cell, Flexbox, Grid, H1, IconButton, Loader, PlainAction } from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  PATH_ORGANISATION_EDIT,
  PATH_ORGANISATION_REPORT,
  PATH_ORGANISATION,
} from "packages/utils";

import { useGetFullOrganisationInfoOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { OrganisationSummary, PendingOrganisations } from "packages/client/organisations/components";

export function ChildOrganisations() {
  const { data, loading } = useGetFullOrganisationInfoOwnUserQuery();

  if (!data) return null;
  if (loading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler message="The child organisations page has loaded." pageTitle="Child organisations" />
      <Grid noColGap>
        <Cell>
          <IconButton
            appearance={EAppearance.GHOST}
            as={Link}
            description="Organisation"
            dimension={EDimension.SMALL}
            icon={EIcon.ARROWLEFT}
            label="Organisation"
            status={EStatus.NEUTRAL}
            to=".."
          />
        </Cell>
        <Cell>
          <H1>Child organisations</H1>
        </Cell>
        <PendingOrganisations />
        <Cell>
          <Grid>
            {data?.user?.organisation?.organisationSet?.map(organisation => (
              <Cell as={Block} colSpanMD={6} key={organisation.id}>
                <Flexbox alignItemsCenter justifyContentSpaceBetween>
                  <Link style={{ display: "block" }} to={PATH_ORGANISATION(organisation.id)}>
                    <OrganisationSummary
                      organisationID={organisation.id}
                      pendingOrganisationName={organisation.name}
                      pendingOrganisationParentOrganisationName={organisation.parentOrganisation?.name}
                    />
                  </Link>
                  <BigPopoverMenu
                    attachment={EAttachment.SOUTH_SOUTH_EAST}
                    trigger={
                      <IconButton
                        appearance={EAppearance.GHOST}
                        as="div"
                        description="Options"
                        dimension={EDimension.SMALL}
                        icon={EIcon.MOREVERTICAL}
                        status={EStatus.NEUTRAL}
                      />
                    }>
                    <Flexbox as="ul" flexDirectionColumn>
                      <li>
                        <PlainAction as={Link} to={PATH_ORGANISATION_REPORT(organisation.id)}>
                          Generate report
                        </PlainAction>
                      </li>
                      <li>
                        <PlainAction as={Link} to={PATH_ORGANISATION_EDIT(organisation.id)}>
                          Edit profile
                        </PlainAction>
                      </li>
                    </Flexbox>
                  </BigPopoverMenu>
                </Flexbox>
              </Cell>
            ))}
          </Grid>
        </Cell>
      </Grid>
    </>
  );
}
