import type { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

import { Flexbox, H1, IconButton } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

import styles from "./ClosableHeading.module.scss";

export interface PClosableHeading {
  autoFocus?: boolean;
  children: ReactNode;
  hasMargin?: boolean;
  onClose?: () => void;
  to?: string | LinkProps["to"];
}

export function ClosableHeading({ autoFocus, children, hasMargin, onClose, to }: PClosableHeading) {
  return (
    <Flexbox alignItemsCenter hasMargin={hasMargin} justifyContentSpaceBetween>
      <H1 className={styles.h1}>{children}</H1>
      <IconButton
        appearance={EAppearance.OUTLINE}
        as={to ? Link : "button"}
        autoFocus={autoFocus}
        description="Close"
        dimension={EDimension.SMALL}
        icon={EIcon.X}
        onClick={onClose}
        status={EStatus.NEUTRAL}
        to={to}
        type="button"
      />
    </Flexbox>
  );
}
