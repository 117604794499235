import { NODE_API_PATH, VSCENE_API_PATH } from "packages/utils";

import { useTypedDispatch } from "packages/client/redux";
import { getToken } from "packages/client/authentication/redux/functions";
import { verifyToken } from "packages/client/authentication/django/requests";
import { setIsSignedIn } from "packages/client/authentication/redux/slice";

export function fetchAuthenticated(path: string, params: RequestInit = {}) {
  const token = getToken();
  if (!token) return;

  return fetch(path, {
    ...params,
    headers: {
      ...params.headers,
      Authorization: `JWT ${token}`,
    },
  }).then(validateToken(token));
}

export function fetchCallAPIAuthenticated(path: string, params: RequestInit = {}, apiVersion: "v1" | "v2" = "v1") {
  return fetchAuthenticated(`https://${DOMAIN}/api/vidyo/${apiVersion}${path}`, params);
}

export function fetchDjangoAPI(path: string, params: RequestInit = {}) {
  return fetch(`https://${DOMAIN}${VSCENE_API_PATH}${path}`, params);
}

export function fetchDjangoAPIAuthenticated(path: string, params: RequestInit = {}) {
  return fetchAuthenticated(`https://${DOMAIN}${VSCENE_API_PATH}${path}`, params);
}

export function fetchNodeAPI(path: string, params: RequestInit = {}) {
  return fetch(`https://${DOMAIN}${NODE_API_PATH}${path}`, params);
}

const validateToken = (token: string) => (response: Response) => {
  if (response.status === 401) {
    verifyToken(token).then(isValid => {
      const dispatch = useTypedDispatch();
      if (!isValid) dispatch(setIsSignedIn(false));
      throw new Error(response.statusText);
    });
  }
  return response;
};
