import type { TVidyoParticipant } from "../types/TVidyoParticipant";

export enum EVidyoParticipantsActions {
  ADD_PARTICIPANT = "participants/add",
  REMOVE_PARTICIPANT = "participants/remove",
  DYNAMIC_PARTICIPANT = "participants/dynamic",
  LOUDEST_PARTICIPANT = "participants/loudest",
}

export interface IParticipantsState {
  dynamic: TVidyoParticipant[];
  loudest: TVidyoParticipant;
  participants: TVidyoParticipant[];
  joined: Record<string, string>;
}

export interface IAddParticipantAction {
  type: EVidyoParticipantsActions.ADD_PARTICIPANT;
  participant: TVidyoParticipant;
  joined: string;
}

export interface IRemoveParticipantAction {
  type: EVidyoParticipantsActions.REMOVE_PARTICIPANT;
  participant: TVidyoParticipant;
}

export interface IDynamicParticipantAction {
  type: EVidyoParticipantsActions.DYNAMIC_PARTICIPANT;
  dynamic: TVidyoParticipant[];
}

export interface ILoudestParticipantAction {
  type: EVidyoParticipantsActions.LOUDEST_PARTICIPANT;
  loudest: TVidyoParticipant;
}

export type TParticipantsAction =
  | IAddParticipantAction
  | IRemoveParticipantAction
  | IDynamicParticipantAction
  | ILoudestParticipantAction;
