import { useCallback, useEffect, useState } from "react";

import { fetchCallAPIAuthenticated } from "packages/client/fetch";

export function useRecording(roomKey: string | undefined, inCall: boolean) {
  const [isBeingRecorded, setIsBeingRecorded] = useState(false);

  const [isRequesting, setIsRequesting] = useState(false);

  const _hiddenRefreshRecording = useCallback(async () => {
    const res = await fetchCallAPIAuthenticated(`/admin/is-recorded/${roomKey}/`);
    const resJson: { Response: boolean } = await res.json();
    setIsBeingRecorded(resJson.Response);
  }, [roomKey]);

  useEffect(() => {
    if (!roomKey || !inCall) return;
    const i = window.setInterval(_hiddenRefreshRecording, 5 * 1000);
    return () => window.clearInterval(i);
  }, [_hiddenRefreshRecording, inCall, roomKey]);

  const _hiddenStartRecord = useCallback(async () => {
    try {
      setIsRequesting(true);
      if (!roomKey) throw new Error("roomKey not provided or invalid.");
      await fetchCallAPIAuthenticated(`/admin/start-recording/${roomKey}`, undefined, "v2");
      await _hiddenRefreshRecording();
    } catch (err) {
      const error = (err as Error).message;
      console.error(
        error
          ? `Error starting recording for room ${roomKey}: ${error}`
          : `Error starting recording for room ${roomKey}`,
      );
    } finally {
      setIsRequesting(false);
    }
  }, [_hiddenRefreshRecording, roomKey]);
  const _hiddenStopRecord = useCallback(
    async function requestStopRecording() {
      try {
        setIsRequesting(true);
        if (!roomKey) throw new Error("roomKey not provided or invalid.");
        await fetchCallAPIAuthenticated(`/admin/stop-recording/${roomKey}`);
        await _hiddenRefreshRecording();
      } catch (err) {
        const error = (err as Error).message;
        console.error(
          error
            ? `Error stopping recording for room ${roomKey}: ${error}`
            : `Error stopping recording for room ${roomKey}`,
        );
      } finally {
        setIsRequesting(false);
      }
    },
    [_hiddenRefreshRecording, roomKey],
  );

  const requestStartRecording = useCallback(() => {
    if (isRequesting) throw new Error("Another request in progress");
    _hiddenStartRecord();
  }, [_hiddenStartRecord, isRequesting]);

  const requestStopRecording = useCallback(() => {
    if (isRequesting) throw new Error("Another request in progress");
    _hiddenStopRecord();
  }, [_hiddenStopRecord, isRequesting]);

  return {
    isBeingRecorded,
    // isRecorderPaused,
    isRequesting,
    requestStartRecording,
    requestStopRecording,
  };
}
