/** always throw an error but try to send something useful */
export async function parseDjangoError(resp: Response) {
  // let's build an error message
  let errorMessage = "";

  try {
    // try to get the specific error message from the server
    const stringError = await resp.text();

    // if there is a specific error message from the server
    if (stringError.length) {
      try {
        // try to convert the server's string error message into json
        const jsonError = JSON.parse(stringError);

        // construct error string depending on fields existing
        if (jsonError.detail) errorMessage = jsonError.detail;
        if (jsonError?.non_field_errors?.length) errorMessage = jsonError.non_field_errors[0];

        // and if they don't, construct using the array values
        if (!errorMessage.length) {
          const otherFields = Object.keys(jsonError).reduce((allErrorMessages, currentErrorMessage, index) => {
            if (currentErrorMessage && jsonError[currentErrorMessage]) {
              const capitalisedErrors: string[] = jsonError[currentErrorMessage].map(
                (string: string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`,
              );
              allErrorMessages += `${
                Object.keys(jsonError).length > 1 ? ` ${index + 1}. ` : ""
              }${capitalisedErrors.join(" ")}`;
              return allErrorMessages;
            }
          }, "");
          if (otherFields) errorMessage = otherFields;
        }
      } catch (error) {
        // else return the default string server error if not json
        errorMessage = stringError;
      }
    } else {
      // else get any generic status text from server response (browser / http/2 dependent, so may or may not have length - only needed as backup message)
      const statusText = resp.statusText;

      // use the status text or return generic message
      errorMessage = statusText.length ? statusText : "We're sorry, something has gone wrong.";
    }
  } catch (error) {
    errorMessage = "We're sorry, something has gone wrong.";
  }

  if (resp.status > 500) errorMessage = "We're sorry, there has been a server error.";
  throw new Error(errorMessage);
}
