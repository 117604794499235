import { HEADER_JSON } from "packages/utils";

import { fetchCallAPIAuthenticated, fetchDjangoAPIAuthenticated } from "packages/client/fetch";

import type { RoomObjectType } from "packages/client/graphql_definitions.generated";

import type { IDjangoRoom } from "packages/client/incall/django/types";
import { toDjangoRoom } from "packages/client/incall/django/serialisers";

const createModerationEveryone = (path: string) => async (roomKey: string) => {
  const resp = await fetchCallAPIAuthenticated(`${path}${roomKey}/`, { headers: HEADER_JSON });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp.json();
};

const createModerationParticipant = (path: string) => async (roomKey: string, participantID: string) => {
  const resp = await fetchCallAPIAuthenticated(path, {
    body: JSON.stringify({
      room_key: roomKey,
      participant: participantID,
    }),
    headers: HEADER_JSON,
    method: "POST",
  });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp.json();
};

export const disconnectAll = createModerationEveryone("/user/disconnect-all/");
export const disconnectParticipant = createModerationParticipant("/admin/remove-participant/");
export const muteAudioAll = createModerationEveryone("/user/mute-audio-all/");
export const muteAudioParticipant = createModerationParticipant("/admin/mute-participant-audio/");
export const muteVideoAll = createModerationEveryone("/user/mute-video-all/");
export const muteVideoParticipant = createModerationParticipant("/admin/mute-participant-video/");
export const unmuteAudioAll = createModerationEveryone("/user/unmute-audio-all/");
export const unmuteAudioParticipant = createModerationParticipant("/admin/unmute-participant-audio/");
export const unmuteVideoAll = createModerationEveryone("/user/unmute-video-all/");
export const unmuteVideoParticipant = createModerationParticipant("/admin/unmute-participant-video/");

export async function addRoomSystemsToSession(roomKey: string, roomSystemIDs: string[]) {
  const resp = await fetchDjangoAPIAuthenticated(`/rooms/${roomKey}/v2/dial-out/`, {
    body: JSON.stringify({ room_systems: roomSystemIDs }),
    headers: HEADER_JSON,
    method: "POST",
  });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp;
}

export async function editRoom(roomKey: string, roomData: Partial<RoomObjectType>) {
  const resp = await fetchDjangoAPIAuthenticated(`/rooms/${roomKey}/`, {
    body: JSON.stringify(toDjangoRoom(roomData, true)),
    headers: HEADER_JSON,
    method: "PATCH",
  });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp.json() as Promise<IDjangoRoom>;
}

export async function fetchAudioVideoStatuses(roomKey: string) {
  const resp = await fetchCallAPIAuthenticated(`/admin/participants-mute-status/${roomKey}/`, { headers: HEADER_JSON });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp.json();
}
