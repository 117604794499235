import { ElementType, HTMLAttributes, useMemo } from "react";

import styles from "./Block.module.scss";

export interface PBlock extends HTMLAttributes<HTMLElement> {
  as?: string | ElementType;
  className?: string;
}

export function Block({ as: Component = "div", children, className, ...rest }: PBlock) {
  const classes = useMemo(() => [styles.block, className && className].filter(e => e).join(" "), [className]);

  return (
    <Component className={classes} {...rest}>
      {children}
    </Component>
  );
}
