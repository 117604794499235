import type { SVGProps } from "react";

import { flattenString, EReaction } from "packages/utils";

import reactions from "public/sprites/reactions-sprite.svg";

export interface PReaction extends SVGProps<SVGSVGElement> {
  className?: string;
  reaction: EReaction;
  title?: string;
}

export function Reaction({ className, reaction, title, ...rest }: PReaction) {
  const formattedTitle = flattenString(title);

  return (
    <svg aria-hidden="true" className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title id={formattedTitle}>{title}</title>}
      <use href={`${reactions}#${reaction}`} preserveAspectRatio="xMinYMin meet" role="img" />
    </svg>
  );
}
