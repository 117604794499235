/** If you know...you know */
/* if you don't know... now you know */

export function getLazyDependencyInstance<T>(initializer: () => Promise<T>) {
  let depPromise = Promise.resolve<T>(null);
  let depLoadLaunched = false;

  return function depGetter() {
    if (!depLoadLaunched) {
      depLoadLaunched = true;
      depPromise = depPromise.then(initializer).catch(e => {
        depPromise = Promise.resolve<T>(null);
        depLoadLaunched = false;
        return Promise.reject<T>(e);
      }) as Promise<Awaited<T>>;
    }
    return depPromise;
  };
}
