import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Cell, ExternalTextLink, Feedback, Grid, H1, Loader, P, TextButton } from "packages/catalog";
import { EStatus, PATH_SIGNIN, SUPPORT_INFORMATION, TParams } from "packages/utils";

import { fetchDjangoAPI } from "packages/client/fetch";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function EmailAddressVerificationResult() {
  const { code, timestamp }: TParams = useParams();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const validateLink = useCallback(async (): Promise<boolean> => {
    try {
      const res = await fetchDjangoAPI(`/users/auth/${code}/${timestamp}/`, {
        method: "GET",
      });

      if (res.status === 200) {
        setSuccess(true);
        setLoading(false);
        return true;
      } else {
        throw new Error();
      }
    } catch {
      setSuccess(false);
      setLoading(false);
      return false;
    }
  }, [code, timestamp]);

  useEffect(() => {
    validateLink();
  }, [validateLink]);

  if (loading) return <Loader />;

  return success ? (
    <>
      <RoutingChangeHandler message="The registration success page has loaded." pageTitle="Registration success" />
      <Grid noColGap>
        <Cell>
          <H1 center>Thank you</H1>
        </Cell>
        <Cell>
          <Feedback status={EStatus.SUCCESS}>{"You've verified your email address."}</Feedback>
        </Cell>
        <Cell>
          <P hasMargin>{"Your organisation administrator has been notified that your account needs approval."}</P>
          <P>{"We'll send you an email when your account has been approved and is ready to access."}</P>
        </Cell>
        <Cell center>
          <TextButton as={Link} status={EStatus.ACCENT} to={PATH_SIGNIN()}>
            Sign in
          </TextButton>
        </Cell>
      </Grid>
    </>
  ) : (
    <>
      <RoutingChangeHandler message="The registration error page has loaded." pageTitle="Registration error" />
      <Grid>
        <Cell>
          <H1 hasMargin>Error</H1>
          <Feedback status={EStatus.DANGER}>
            {"Unable to verify your email address. The confirmation link you used isn't valid."}
          </Feedback>
        </Cell>
        <Cell>
          <P>Please try again.</P>
          <P>
            Otherwise, please contact{" "}
            <ExternalTextLink href={`mailto:${SUPPORT_INFORMATION.mail}?subject=Question about my registration`}>
              {SUPPORT_INFORMATION.mail}
            </ExternalTextLink>
            .
          </P>
        </Cell>
      </Grid>
    </>
  );
}
