import { InputHTMLAttributes, useMemo } from "react";

import { Input } from "packages/catalog";

import styles from "./AnswerOption.module.scss";

export interface PAnswerOption extends InputHTMLAttributes<HTMLInputElement> {
  allowsMultipleAnswers?: boolean;
  answerOption: string;
  answerOptionID: string;
  questionID: string;
}

export function AnswerOption({
  answerOption,
  answerOptionID,
  allowsMultipleAnswers,
  checked,
  onChange,
  questionID,
}: PAnswerOption) {
  const classes = useMemo(() => [styles.option, checked && styles.checked].filter(e => e).join(" "), [checked]);

  return (
    <label className={classes}>
      <Input
        checked={checked}
        name={questionID}
        onChange={onChange}
        type={allowsMultipleAnswers ? "checkbox" : "radio"}
        value={answerOptionID}
      />
      <span className={styles.label}>{answerOption}</span>
    </label>
  );
}
