import { FocusEvent, useCallback, useState } from "react";

import { InputColor } from "packages/catalog";
import { EIcon } from "packages/utils";

import { useWhiteboardContext } from "packages/client/incall/contexts";

import { Tool } from "packages/client/incall/components";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "packages/client/incall/components/whiteboard/Tool/Tool.modules.scss";

export function ToolColor() {
  const [active, setActive] = useState(false);

  const { currentColour, setColour } = useWhiteboardContext();

  const handleColor = useCallback(
    (color: string) => {
      setColour(color);
    },
    [setColour],
  );

  const handleClick = useCallback(() => {
    setActive(!active);
  }, [active]);

  const handleBlur = useCallback((e: FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setActive(false);
    }
  }, []);

  return (
    <div onBlur={handleBlur} className={styles.colorToolWrapper}>
      <Tool
        active={active}
        fill={currentColour}
        icon={EIcon.CIRCLE}
        inputType="button"
        label="Colour"
        onClick={handleClick}
      />
      <InputColor color={currentColour} name="color" openColorPicker={active} onColorChange={handleColor} />
    </div>
  );
}
