import { Description, Icon } from "packages/catalog";
import { EIcon } from "packages/utils";

import { RoomSystemPrivacy } from "packages/client/graphql_definitions.generated";

import styles from "./PrivacyDescription.module.scss";

export interface PPrivacyDescription {
  privacy: RoomSystemPrivacy;
}

export function PrivacyDescription({ privacy }: PPrivacyDescription) {
  return (
    <Description
      className={styles.description}
      title={
        privacy === RoomSystemPrivacy.Organisation
          ? "Accessible to your organisations and connections only"
          : "Accessible to all organisations"
      }>
      {privacy === RoomSystemPrivacy.Organisation ? (
        <>
          <Icon className={`${styles.privacy} ${styles.private}`} icon={EIcon.LOCK} /> <>Private</>
        </>
      ) : (
        <>
          <Icon className={`${styles.privacy} ${styles.public}`} icon={EIcon.GLOBE} /> <>Public</>
        </>
      )}
    </Description>
  );
}
