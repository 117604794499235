import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  BigPopoverMenu,
  Description,
  Emoji,
  Flexbox,
  HR,
  IconButton,
  P,
  PlainAction,
  StatusBadge,
  Strong,
} from "packages/catalog";
import { ETheme } from "packages/theming";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  formatISOShortDate,
  formatLongTime,
  formatLongWordDate,
  LOCAL_THEME,
  PATH_PROFILE_CHANGEPASSWORD,
  PATH_PROFILE_EDIT,
  PATH_PROFILE_SETTINGS,
  PATH_USER,
} from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { updateTheme } from "packages/client/layout/redux/slice";

import { useAuth } from "packages/client/authentication/hooks";
import { useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { UserAvatar } from "packages/client/users/components";

import styles from "./DashboardHeader.module.scss";

interface IDates {
  date: string;
  isoDate: string;
  time: string;
}

function getDates() {
  const now = new Date();

  const d: IDates = {
    date: formatLongWordDate(now),
    isoDate: formatISOShortDate(now),
    time: formatLongTime(now),
  };

  return d;
}

export function DashboardHeader() {
  const [dates, setDates] = useState<IDates>(getDates());

  const dispatch = useTypedDispatch();
  const { theme } = useTypedSelector(({ layout }) => ({
    theme: layout.theme,
  }));
  const { signOut } = useAuth();
  const { data } = useGetEssentialOwnUserQuery();

  const otherTheme = useMemo(() => (theme === ETheme.LIGHT ? ETheme.DARK : ETheme.LIGHT), [theme]);

  const handleChangeTheme = useCallback(() => {
    dispatch(updateTheme(otherTheme));

    localStorage.setItem(LOCAL_THEME, otherTheme);
  }, [dispatch, otherTheme]);

  useEffect(() => {
    const timer = setInterval(() => setDates(getDates()), 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <header className={styles.header}>
      <Flexbox alignItemsCenter className={styles.flex} justifyContentSpaceBetween>
        <p className={styles.welcome}>
          Welcome back, <Strong>{data?.user?.firstName}</Strong> <Emoji symbol="👋" />
        </p>
        <div className={styles.options}>
          <Flexbox alignItemsCenter className={styles.dateTime} hasDoubleGap>
            <div>
              <P>
                <Description>
                  <time dateTime={dates.time}>{dates.time}</time> <time dateTime={dates.isoDate}>{dates.date}</time>
                </Description>
              </P>
            </div>
            <IconButton
              appearance={EAppearance.OUTLINE}
              as="button"
              description={theme === ETheme.LIGHT ? "Use dark theme" : "Use light theme"}
              dimension={EDimension.SMALL}
              icon={theme === ETheme.LIGHT ? EIcon.MOON : EIcon.SUN}
              onClick={handleChangeTheme}
              status={EStatus.NEUTRAL}
            />
          </Flexbox>
          <BigPopoverMenu
            attachment={EAttachment.SOUTH_SOUTH_EAST}
            trigger={
              <div className={styles.trigger}>
                <UserAvatar
                  dimension={EDimension.MEDIUM}
                  email={data?.user?.email}
                  fullName={`${data?.user?.firstName} ${data?.user?.lastName}`}
                  profilePictureURL={data?.user?.profilePicture}
                />
                <IconButton
                  as="div"
                  description="User menu and settings"
                  status={EStatus.NEUTRAL}
                  icon={EIcon.CHEVRONDOWN}
                  appearance={EAppearance.GHOST}
                  dimension={EDimension.SMALL}
                />
              </div>
            }>
            <Flexbox flexDirectionColumn>
              <div>
                <Flexbox alignItemsCenter>
                  <Link to={PATH_USER(data?.user?.id)}>
                    <P>
                      <Strong>
                        {data?.user?.firstName} {data?.user?.lastName}
                      </Strong>
                    </P>
                  </Link>
                  {data?.user?.isSuperuser ? (
                    <StatusBadge status={EStatus.NEUTRAL}>SuperAdmin</StatusBadge>
                  ) : data?.user?.isAdmin ? (
                    <StatusBadge status={EStatus.ACCENT}>Admin</StatusBadge>
                  ) : null}
                </Flexbox>
                <Description>{data?.user?.email}</Description>
              </div>
              <HR />
              <PlainAction as={Link} to={PATH_PROFILE_EDIT}>
                Edit profile
              </PlainAction>
              <PlainAction as={Link} to={PATH_PROFILE_CHANGEPASSWORD}>
                Change password
              </PlainAction>
              <PlainAction as={Link} to={PATH_PROFILE_SETTINGS}>
                Settings
              </PlainAction>
              <HR />
              <PlainAction as="a" href="https://knowledgebase.classview.com/" rel="noopener noreferrer" target="_blank">
                Help
              </PlainAction>
              <PlainAction as="button" onClick={signOut} type="button">
                Sign out
              </PlainAction>
            </Flexbox>
          </BigPopoverMenu>
        </div>
      </Flexbox>
    </header>
  );
}
