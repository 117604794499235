import { useMergedClassNames } from "packages/utils";
import type { HTMLProps } from "react";

import styles from "./P.module.scss";

export interface PProps extends HTMLProps<HTMLParagraphElement> {
  center?: boolean;
  hasMargin?: boolean;
}

export function P({ center, className, hasMargin, ...rest }: PProps) {
  const pClasses = useMergedClassNames({ className }, [
    styles.para,
    center && styles.center,
    hasMargin && styles.hasMargin,
  ]);

  return <p {...rest} className={pClasses} />;
}
