import { ChangeEvent, FormEvent, useEffect, useState, useCallback } from "react";

import {
  Block,
  Cell,
  Flexbox,
  Feedback,
  Field,
  Grid,
  H1,
  H2,
  Input,
  LabelledContent,
  Loader,
  TextArea,
  TextButton,
} from "packages/catalog";
import { EDimension, scrollToContentTop, EStatus } from "packages/utils";

import { editUser, uploadProfilePicture } from "packages/client/users/django/requests";
import { useGetProfileOwnUserQuery } from "packages/client/users/graphql/users.generated";

import type { UserType } from "packages/client/graphql_definitions.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { ImageCropModal } from "packages/client/modals/components";
import { UserAvatar } from "packages/client/users/components";

export function EditUser() {
  const [isImageCropModalOpen, setIsImageCropModalOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [form, setForm] = useState<Partial<UserType>>({
    bio: "",
    firstName: "",
    jobTitle: "",
    lastName: "",
    phoneNumber: "",
    skills: "",
    website: "",
  });

  const { data, loading, error, refetch } = useGetProfileOwnUserQuery({ fetchPolicy: "cache-and-network" });

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const handleChangeTextArea = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const handleUpload = useCallback(
    (formData: FormData, filename: string) => {
      setIsImageCropModalOpen(false);
      setShowErrorMessage(false);
      setShowSuccessMessage(false);
      uploadProfilePicture(formData, filename, form.profilePicture, parseInt(data?.user?.id))
        .then(() => {
          refetch();
          scrollToContentTop();
          setShowSuccessMessage(true);
        })
        .catch(() => {
          scrollToContentTop();
          setShowErrorMessage(true);
        });
    },
    [data?.user?.id, form.profilePicture, refetch],
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setShowErrorMessage(false);
      setShowSuccessMessage(false);
      editUser(data?.user?.id, { ...form })
        .then(() => {
          refetch();
          scrollToContentTop();
          setShowSuccessMessage(true);
        })
        .catch(() => {
          scrollToContentTop();
          setShowErrorMessage(true);
        });
    },
    [data, form, refetch],
  );

  useEffect(() => {
    if (data && !loading) {
      setForm({
        bio: data.user.bio ?? "",
        firstName: data.user.firstName ?? "",
        jobTitle: data.user.jobTitle ?? "",
        lastName: data.user.lastName ?? "",
        phoneNumber: data.user.phoneNumber ?? "",
        skills: data.user.skills ?? "",
        website: data.user.website ?? "",
      });
    }
  }, [data, loading]);

  return (
    <>
      {!data && loading && <Loader />}
      <>
        <RoutingChangeHandler message="The edit profile page has loaded." pageTitle="Edit profile" />
        <Grid noColGap>
          <Cell>
            <H1>Edit profile</H1>
          </Cell>
          {showErrorMessage && (
            <Cell>
              <Feedback status={EStatus.DANGER}>Unable to update profile. Please try again.</Feedback>
            </Cell>
          )}
          {showSuccessMessage && (
            <Cell>
              <Feedback status={EStatus.SUCCESS}>Profile updated.</Feedback>
            </Cell>
          )}
          {error && (
            <Cell>
              <Feedback status={EStatus.DANGER}>Unable to get your profile information. Please try again.</Feedback>
            </Cell>
          )}
          {data && !loading && (
            <Cell as="form" onSubmit={handleSubmit}>
              <Grid>
                <Cell as="section">
                  <Grid as={Block}>
                    <Cell>
                      <LabelledContent
                        content={
                          <Flexbox alignItemsCenter>
                            <UserAvatar
                              dimension={EDimension.EXTRA_LARGE}
                              email={data?.user?.email}
                              fullName={`${data?.user?.firstName} ${data?.user?.lastName}`}
                              profilePictureURL={data?.user?.profilePicture}
                            />
                            <TextButton onClick={() => setIsImageCropModalOpen(true)} type="button">
                              Choose profile photo
                            </TextButton>
                          </Flexbox>
                        }
                        label="Photo"
                      />
                    </Cell>
                    <Cell colSpanSM={6}>
                      <Field label="First name">
                        <Input
                          autoComplete="given-name"
                          name="firstName"
                          onChange={handleChangeInput}
                          required
                          type="text"
                          value={form.firstName}
                        />
                      </Field>
                    </Cell>
                    <Cell colSpanSM={6}>
                      <Field label="Last name">
                        <Input
                          autoComplete="family-name"
                          name="lastName"
                          onChange={handleChangeInput}
                          required
                          type="text"
                          value={form.lastName}
                        />
                      </Field>
                    </Cell>
                    <Cell colSpanSM={8}>
                      <Field label="Job title">
                        <Input
                          autoComplete="organization-title"
                          name="jobTitle"
                          onChange={handleChangeInput}
                          type="text"
                          value={form.jobTitle}
                        />
                      </Field>
                    </Cell>
                    <Cell colSpanSM={4}>
                      <Field label="Organisation">
                        <Input disabled name="organization" type="text" value={data.user.organisation.name} />
                      </Field>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell as="section">
                  <Grid as={Block}>
                    <Cell>
                      <H2>Contact details</H2>
                    </Cell>
                    <Cell colSpanSM={6}>
                      <Field label="Email address">
                        <Input autoComplete="email" disabled name="email" type="email" value={data.user.email} />
                      </Field>
                    </Cell>
                    <Cell colSpanSM={6}>
                      <Field label="Telephone number">
                        <Input
                          autoComplete="tel"
                          name="phoneNumber"
                          onChange={handleChangeInput}
                          type="tel"
                          value={form.phoneNumber}
                        />
                      </Field>
                    </Cell>
                    <Cell colSpanSM={6}>
                      <Field label="Website URL">
                        <Input
                          autoComplete="url"
                          name="website"
                          onChange={handleChangeInput}
                          type="url"
                          value={form.website}
                        />
                      </Field>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell>
                  <Grid as={Block}>
                    <Cell>
                      <H2>About you</H2>
                    </Cell>
                    <Cell>
                      <Field label="Biography">
                        <TextArea
                          name="bio"
                          maxLength={2000}
                          onChange={handleChangeTextArea}
                          rows={8}
                          value={form.bio}
                        />
                      </Field>
                    </Cell>
                    <Cell>
                      <Field label="Skills">
                        <TextArea name="skills" onChange={handleChangeTextArea} maxLength={2000} value={form.skills} />
                      </Field>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell center>
                  <Input type="submit" value="Save" />
                </Cell>
              </Grid>
            </Cell>
          )}
        </Grid>
        <ImageCropModal
          isOpen={isImageCropModalOpen}
          onAbort={() => setIsImageCropModalOpen(false)}
          handleUpload={handleUpload}
        />
      </>
    </>
  );
}
