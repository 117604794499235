import { Link, Navigate, useLocation, useParams } from "react-router-dom";

import { Cell, Grid, H1, HR, TextButton } from "packages/catalog";
import { EAppearance, TParams, PATH_PRECALL_JOINCALL, PATH_SIGNIN, EStatus } from "packages/utils";

import { useTypedSelector } from "packages/client/redux";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function JoinOptions() {
  const { callID }: TParams = useParams();
  const { pathname } = useLocation();
  const { isSignedIn } = useTypedSelector(({ authentication }) => ({ isSignedIn: authentication.isSignedIn }));

  if (isSignedIn) {
    return <Navigate to={PATH_PRECALL_JOINCALL(callID)} />;
  }

  return (
    <>
      <RoutingChangeHandler message="The join options page has loaded." pageTitle="Join options" />
      <Grid noColGap>
        <Cell>
          <H1 center hasMargin>
            Join session
          </H1>
        </Cell>
        <Cell fullWidthContent>
          <TextButton
            status={EStatus.ACCENT}
            appearance={EAppearance.FILL}
            as={Link}
            state={{ from: pathname }}
            to={PATH_SIGNIN(callID)}>
            Sign in
          </TextButton>
        </Cell>
        <Cell>
          <HR>or</HR>
        </Cell>
        <Cell fullWidthContent>
          <TextButton
            appearance={EAppearance.OUTLINE}
            as={Link}
            status={EStatus.ACCENT}
            to={PATH_PRECALL_JOINCALL(callID)}>
            Join as a guest
          </TextButton>
        </Cell>
      </Grid>
    </>
  );
}
