import { ChangeEvent, useEffect, useMemo, useState } from "react";

import {
  Cell,
  Feedback,
  Grid,
  H2,
  Input,
  Legend,
  PillOption,
  SidebarBack,
  SidebarHeader,
  SidebarMain,
  Strong,
} from "packages/catalog";
import { EStatus } from "packages/utils";

import { useGetEssentialSession } from "packages/client/sessions/hooks";
import { useRoom } from "packages/client/incall/hooks";

import { editSession } from "packages/client/sessions/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./ExtendCall.module.scss";

const OPTIONS: number[] = [10, 15, 30, 45, 60, 90];

export function ExtendCall() {
  const { sessionID } = useRoom();
  const { session, refetch } = useGetEssentialSession(sessionID);

  const [extendTime, setExtendTime] = useState(10);
  const [success, setSuccess] = useState(true);
  const [notification, setNotification] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [extendingStatus, setExtendingStatus] = useState(false);

  const meetingEndInMs = useMemo(() => {
    if (!session?.endTime) return null;
    return session.nextRecurringEnd ? Date.parse(session.nextRecurringEnd) : Date.parse(session.endTime);
  }, [session]);

  const endTimeformated = useMemo(() => {
    if (!meetingEndInMs) return "";
    const endTime = new Date(meetingEndInMs);
    return endTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }, [meetingEndInMs]);

  useEffect(() => {
    const notificationTimeout = setTimeout(() => setNotification(false), 6000);
    return () => clearTimeout(notificationTimeout);
  }, [notification]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const minutes = parseInt(e.target.value);
    setExtendTime(minutes);

    if (disabled) setDisabled(false);
  }

  async function handleSubmit(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setExtendingStatus(true);
    setDisabled(true);

    const newEndTime = new Date(meetingEndInMs + extendTime * 60000);

    const resp = await editSession(`${sessionID}`, { endTime: newEndTime });
    if (resp.status === 200) {
      setSuccess(true);
      refetch();
    } else setSuccess(false);

    setNotification(false);
    setNotification(true);
    setDisabled(true);
    setExtendingStatus(false);
  }

  // TODO when Fieldset component is revisited with grid, update below

  return (
    <>
      <RoutingChangeHandler message="The extend session settings have loaded." />
      <SidebarHeader>
        <SidebarBack backTo=".." closeTo="../.." label="Settings" />
        <H2>Extend session</H2>
      </SidebarHeader>
      <SidebarMain>
        <Grid as="form" onSubmit={handleSubmit} noColGap>
          {notification &&
            (success ? (
              <Cell>
                <Feedback status={EStatus.SUCCESS}>
                  Session extended by <Strong>{extendTime} minutes</Strong>
                </Feedback>
              </Cell>
            ) : (
              <Cell>
                <Feedback status={EStatus.DANGER}>
                  Session could not be extended. Please try again or contact your system administrator.
                </Feedback>
              </Cell>
            ))}
          <Cell>
            <Feedback status={EStatus.NEUTRAL}>
              Session end time: <Strong>{endTimeformated}</Strong>
            </Feedback>
          </Cell>
          <Cell as="fieldset">
            <Legend hasMargin>Extend session by</Legend>
            <div className={styles.fieldset} role="group">
              {OPTIONS.map(value => (
                <PillOption label={`${value} mins`} title={`${value} mins`} key={value}>
                  <Input
                    checked={!disabled && extendTime === value}
                    name="extendOption"
                    onChange={handleChange}
                    type="radio"
                    value={value}
                  />
                </PillOption>
              ))}
            </div>
          </Cell>
          <Cell center>
            <Input
              disabled={disabled}
              type="submit"
              value={extendingStatus ? "Extending session..." : "Extend session"}
            />
          </Cell>
        </Grid>
      </SidebarMain>
    </>
  );
}
