import { ReactNode, useEffect, useMemo, useRef } from "react";

import { Block, ClosableHeading, H1 } from "packages/catalog";

import styles from "./Dialog.module.scss";

export interface PDialog {
  children: ReactNode;
  h1?: string | ReactNode;
  isAtBottom?: boolean;
  isOpen: boolean;
  onAbort?: () => void;
}

export function Dialog({ children, h1, isAtBottom, isOpen, onAbort }: PDialog) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const classes = useMemo(
    () => [styles.dialog, isAtBottom && styles.isAtBottom].filter(e => e).join(" "),
    [isAtBottom],
  );

  useEffect(() => {
    isOpen ? dialogRef.current?.showModal() : dialogRef.current?.close();
  }, [isOpen]);

  return (
    <dialog className={classes} ref={dialogRef} onCancel={onAbort}>
      <Block>
        {h1 &&
          (onAbort ? (
            <ClosableHeading hasMargin onClose={onAbort}>
              {h1}
            </ClosableHeading>
          ) : (
            <H1 hasMargin>{h1}</H1>
          ))}
        {children}
      </Block>
    </dialog>
  );
}
