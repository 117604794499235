import { useCallback, useEffect, useRef } from "react";

import { EVidyoSettingsActions, IUpdateableSettingsState } from "../../settings/redux/types";
import { EVidyoStatusActions } from "../../status/redux/types";

import { dispatchVidyo, useSelectorVidyo } from "../../redux";

export function useLayoutControl() {
  const { isGridViewPreferred, showSelfview } = useSelectorVidyo(({ status }) => ({
    isGridViewPreferred: status.isGridViewPreferred,
    showSelfview: status.showSelfview,
  }));

  const setGridViewPreferred = useCallback(
    (shouldGridViewBePreferred: boolean) =>
      dispatchVidyo({
        type: EVidyoStatusActions.SET_IS_GRIDVIEW_PREFERRED,
        isGridViewPreferred: shouldGridViewBePreferred,
      }),
    [],
  );

  const setShowSelfview = useCallback(
    (shouldSelfviewBeShowed: boolean) =>
      dispatchVidyo({
        type: EVidyoStatusActions.SET_SHOW_SELFVIEW,
        showSelfview: shouldSelfviewBeShowed,
      }),
    [],
  );

  return {
    isGridViewPreferred,
    showSelfview,
    setGridViewPreferred,
    setShowSelfview,
  };
}

export function useLayoutSettings() {
  const layoutSettings = useSelectorVidyo(({ settings }) => settings);

  const updateSettings = useCallback(
    (newSettings: Partial<IUpdateableSettingsState>) =>
      dispatchVidyo({
        type: EVidyoSettingsActions.UPDATE_SETTINGS,
        update: newSettings,
      }),
    [],
  );

  const resetSettings = useCallback(
    () =>
      dispatchVidyo({
        type: EVidyoSettingsActions.RESET_SETTINGS,
      }),
    [],
  );

  return { ...layoutSettings, updateSettings, resetSettings };
}

/**
 * alters temporarily the current layout
 * usecase can be to force GRID layout when whiteboard is open for example
 */
export function useReversibleGridViewPreferred(shouldGridViewBePreferred: boolean) {
  const { isGridViewPreferred, setGridViewPreferred } = useLayoutControl();
  const refCurrentLayout = useRef(isGridViewPreferred);
  useEffect(() => {
    const l = refCurrentLayout.current;
    setGridViewPreferred(shouldGridViewBePreferred);
    return () => {
      setGridViewPreferred(l);
    };
  }, [setGridViewPreferred, shouldGridViewBePreferred]);
}
