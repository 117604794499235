import type { ReactNode } from "react";

import { Avatar, Flexbox, P } from "packages/catalog";
import { EDimension } from "packages/utils";

import styles from "./Participant.module.scss";

export interface PParticipant {
  buttons: ReactNode;
  fullName: string;
}

export function Participant({ buttons, fullName }: PParticipant) {
  return (
    <li className={styles.participant}>
      <Flexbox alignItemsCenter>
        <Avatar dimension={EDimension.SMALL} fullName={fullName} />
        <div className={styles.info}>
          <P className={styles.name}>{fullName}</P>
        </div>
      </Flexbox>
      <div className={styles.buttons}>{buttons}</div>
    </li>
  );
}
