export enum EVidyoStatusActions {
  MAX_REMOTE_SOURCES = "status/remoteSources",
  SET_CALL_STATUS = "status/callStatus",
  SET_IS_GRIDVIEW_PREFERRED = "status/setGridViewPreferred",
  RESET_LEAVE_STATUS = "status/resetLeaveStatus",
  SET_SDK_READY = "status/sdkReady",
  SET_SHOW_SELFVIEW = "status/setShowSelfview",
}

export interface IStatusState {
  callStatus: ECallStatus;
  isGridViewPreferred: boolean /** forces all cells to weight the same in layout */;
  isKicked: boolean;
  isTerminated: boolean;
  maxVidyoRemoteSources: number /** updated passively with network conditions */;
  reason?: string;
  sdkError: string;
  sdkReady: boolean;
  showSelfview: boolean /** show own video stream to user */;
}

export enum ECallStatus {
  FAILURE = "failure",
  IN_CALL = "inCall",
  KICKED = "kicked",
  OUTSIDE_CALL = "outsideCall",
  TERMINATED = "terminated",
}

export interface ISetSdkReadyAction {
  type: EVidyoStatusActions.SET_SDK_READY;
  sdkReady: boolean;
  sdkError?: string;
}

export interface ISetCallStatusAction {
  type: EVidyoStatusActions.SET_CALL_STATUS;
  status: ECallStatus;
  reason?: string;
}

export interface IResetLeaveStatus {
  type: EVidyoStatusActions.RESET_LEAVE_STATUS;
}

export interface IMaxRemoteSourcesAction {
  type: EVidyoStatusActions.MAX_REMOTE_SOURCES;
  maxVidyoRemoteSources: number;
}
export interface ISetIsGridViewPreferredAction {
  type: EVidyoStatusActions.SET_IS_GRIDVIEW_PREFERRED;
  isGridViewPreferred: boolean;
}

export interface ISetShowSelfviewAction {
  type: EVidyoStatusActions.SET_SHOW_SELFVIEW;
  showSelfview: boolean;
}

export type TStatusAction =
  | ISetCallStatusAction
  | ISetSdkReadyAction
  | IMaxRemoteSourcesAction
  | IResetLeaveStatus
  | ISetIsGridViewPreferredAction
  | ISetShowSelfviewAction;
