import type { SVGProps } from "react";

import { flattenString, EIllustration } from "packages/utils";

import illustrations from "public/sprites/illustrations-sprite.svg";

export interface PIllustration extends SVGProps<SVGSVGElement> {
  className?: string;
  illustration: EIllustration;
  title?: string;
}

export function Illustration({ className, illustration, title, ...rest }: PIllustration) {
  const formattedTitle = flattenString(title);

  return (
    <svg aria-hidden="true" className={className} xmlns="http://www.w3.org/2000/svg" {...rest}>
      {title && <title id={formattedTitle}>{title}</title>}
      <use href={`${illustrations}#${illustration}`} preserveAspectRatio="xMinYMin meet" role="img" />
    </svg>
  );
}
