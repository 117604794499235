import { useEffect, useRef } from "react";

import { getLazyDependencyInstance } from "packages/utils";

import styles from "./Map.module.scss";

export interface PMap {
  latitude: number;
  longitude: number;
  organisationName: string;
}

const GOOGLEMAPKEY = "AIzaSyBL6yWQMVmUD3lVzSbqlHvJ9tXBhzUT2o4";

const depsGoogle = getLazyDependencyInstance(async () => {
  const { Loader } = await import("@googlemaps/js-api-loader");
  const loader = new Loader({
    apiKey: GOOGLEMAPKEY,
  });
  return loader.load();
});

export function Map({ latitude, longitude, organisationName }: PMap) {
  const mapRef = useRef<HTMLDivElement>();

  useEffect(() => {
    let cancelled = false;
    let map: google.maps.Map = null;

    async function loadMap() {
      try {
        const google = await depsGoogle();
        if (cancelled) return;

        map = new google.maps.Map(mapRef.current, {
          center: { lat: latitude, lng: longitude },
          zoom: 10,
        });

        new google.maps.Marker({
          map,
          position: { lat: latitude, lng: longitude },
          title: organisationName,
          anchorPoint: new google.maps.Point(20, 0),
        });
      } catch (err) {
        console.error(`Google Maps error: [${(err as Error).message}]`);
      }
    }

    loadMap();

    return () => {
      if (map) map;
      cancelled = true;
    };
  }, [latitude, longitude, organisationName]);

  return <div className={styles.map} ref={mapRef}></div>;
}
