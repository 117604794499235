import { EColorSpace, getCSSVariableValue } from "packages/utils";

export type RGB = [red: number, green: number, blue: number];
export type HSL = [h: number, s: number, l: number];

export function HSLtoRGB([h, sat, light]: HSL): RGB {
  if (h > 360 || sat > 100 || light > 100) throw new Error("Invalid color value");
  const s = sat / 100;
  const l = light / 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
  } else if (120 <= h && h < 180) {
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    b = c;
  } else if (300 <= h && h <= 360) {
    r = c;
    b = x;
  }
  r = (r + m) * 255;
  g = (g + m) * 255;
  b = (b + m) * 255;
  return [r, g, b];
}

export function RGBtoHEX([r, g, b]: string[]): string {
  const red = Math.round(parseFloat(r));
  const green = Math.round(parseFloat(g));
  const blue = Math.round(parseFloat(b));
  if (red > 255 || green > 255 || blue > 255) throw new Error("Invalid color value");
  const rHex = red.toString(16).padStart(2, "0");
  const gHex = green.toString(16).padStart(2, "0");
  const bHex = blue.toString(16).padStart(2, "0");
  return "#" + rHex + gHex + bHex;
}

export function RGBtoHSL([red, green, blue]: RGB): HSL {
  if (red > 255 || green > 255 || blue > 255) throw new Error("Invalid color value");
  const r = red / 255;
  const g = green / 255;
  const b = blue / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  let h;

  if (delta === 0) {
    h = 0;
  } else if (max === r) {
    h = (g - b) / delta;
  } else if (max === g) {
    h = 2 + (b - r) / delta;
  } else {
    h = 4 + (r - g) / delta;
  }

  h = Math.min(h * 60, 360);

  if (h < 0) h += 360;

  const l = (max + min) / 2;

  let s;

  if (delta === 0) {
    s = 0;
  } else if (l <= 0.5) {
    s = delta / (max + min);
  } else {
    s = delta / (2 - max - min);
  }

  return [h, s * 100, l * 100];
}

export function HEXtoRGB(hex: string): RGB {
  const cleanHex = hex[0] === "#" ? hex.slice(1, hex.length) : hex;
  if (cleanHex.length > 6) throw new Error("Invalid color value");
  const r = parseInt(cleanHex.length === 3 ? cleanHex.slice(0, 1).repeat(2) : cleanHex.slice(0, 2), 16);
  const g = parseInt(cleanHex.length === 3 ? cleanHex.slice(1, 2).repeat(2) : cleanHex.slice(2, 4), 16);
  const b = parseInt(cleanHex.length === 3 ? cleanHex.slice(2, 3).repeat(2) : cleanHex.slice(4, 6), 16);
  if (isNaN(r) || isNaN(g) || isNaN(b)) throw new Error("Invalid color value");
  return [r, g, b];
}

export function HEXtoHSL(hex: string): HSL {
  const rgb = HEXtoRGB(hex);
  return RGBtoHSL(rgb);
}

export function HSLtoHEX(hsl: HSL): string {
  if (!hsl) return;
  const rgb = HSLtoRGB(hsl);
  return RGBtoHEX(rgb.map(String));
}

export function cssHSLVariabletoHex(variable: string) {
  const hsl = getCSSVariableValue(variable);
  return HSLtoHEX(parseHSL(hsl));
}

export function parseHSL(hsl: string): HSL {
  if (!hsl) return;
  const trimmedHSL = hsl.replace(/\s+/g, "");

  const h = parseInt(trimmedHSL.substring(trimmedHSL.indexOf("(") + 1, trimmedHSL.indexOf("d")));
  const s = parseInt(trimmedHSL.substring(trimmedHSL.indexOf("g") + 1, trimmedHSL.indexOf("%")));
  const l = parseInt(
    trimmedHSL.substring(trimmedHSL.indexOf("%") + 1, trimmedHSL.indexOf("%", trimmedHSL.indexOf("%") + 1)),
  );

  return [h, s, l];
}

export function convertColor(from: EColorSpace, to: EColorSpace, color: string | number[]) {
  if (from === EColorSpace.HEX) {
    return to === EColorSpace.HSL ? HEXtoHSL(color as string) : HEXtoRGB(color as string);
  } else if (from === EColorSpace.HSL) {
    return to === EColorSpace.HEX ? HSLtoHEX(color as HSL) : HSLtoRGB(color as HSL);
  } else {
    return to === EColorSpace.HEX ? RGBtoHEX((color as number[]).map(String)) : RGBtoHSL(color as RGB);
  }
}
