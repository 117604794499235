export function generateSignInErrorMessage(responseStatus: number) {
  let errorMessage = "";

  switch (responseStatus) {
    case 400:
      errorMessage = "Incorrect email address or password. Please try again";
      break;
    case 401:
      errorMessage = "Unauthorised.";
      break;
    case 403:
      errorMessage = "Forbidden.";
      break;
    case 405:
      errorMessage = "Your account is pending approval by your organisation administrator.";
      break;
    case 409:
      errorMessage = "Your user account is not active.";
      break;
    case null:
    case undefined:
      break;
    default:
      errorMessage = "Server error. Please try again later.";
      break;
  }

  return errorMessage;
}
