import type { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { PATH_SIGNIN } from "packages/utils";

import { useTypedSelector } from "packages/client/redux";

export interface PRequireAuthentication {
  children: ReactNode;
}

export function RequireAuthentication({ children }: PRequireAuthentication) {
  const { isSignedIn } = useTypedSelector(({ authentication }) => ({ isSignedIn: authentication.isSignedIn }));
  const { pathname } = useLocation();

  return isSignedIn ? <>{children}</> : <Navigate replace state={{ from: pathname }} to={PATH_SIGNIN()} />;
}
