import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Block,
  Cell,
  Description,
  ExternalTextLink,
  Feedback,
  Grid,
  H1,
  H2,
  IconButton,
  IconContent,
  LabelledContent,
  Loader,
  P,
  TextButton,
} from "packages/catalog";
import { EAppearance, EDimension, EIcon, SEGMENT_EDIT, EStatus, TParams, underscoredToTitleCase } from "packages/utils";

import { useGetProfileOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";
import { useGetEssentialOrganisationInfoOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { ManageConnection, OrganisationLogo } from "packages/client/organisations/components";

export function Organisation() {
  const { organisationID }: TParams = useParams();

  const {
    data: organisationData,
    loading: organisationDataLoading,
    error,
  } = useGetProfileOrganisationQuery({
    variables: {
      organisationID: parseInt(organisationID),
    },
  });
  const { data: userOwnData, loading: userOwnDataLoading } = useGetEssentialOrganisationInfoOwnUserQuery();

  const isAdminOfOrganisation = useMemo(
    () => userOwnData?.user?.organisation?.id === organisationData?.organisation?.id && userOwnData?.user?.isAdmin,
    [organisationData?.organisation?.id, userOwnData?.user?.isAdmin, userOwnData?.user?.organisation?.id],
  );

  if (organisationDataLoading || userOwnDataLoading) return <Loader />;

  if (error)
    return (
      <Feedback status={EStatus.DANGER}>{"This organisation does not exist, or has not yet been approved."}</Feedback>
    );

  return (
    <>
      <RoutingChangeHandler message="The organisation page has loaded." pageTitle="Organisation" />
      {organisationData && userOwnData && (
        <Grid>
          {userOwnData.user.isAdmin && (
            <Cell>
              <IconButton
                appearance={EAppearance.GHOST}
                as={Link}
                description="Organisation"
                dimension={EDimension.SMALL}
                icon={EIcon.ARROWLEFT}
                label="Organisation"
                status={EStatus.NEUTRAL}
                to=".."
              />
            </Cell>
          )}
          <Cell>
            <H1>Organisation profile</H1>
          </Cell>
          <Cell as={Block} colSpan={12} colSpanMD={5}>
            <Grid noColGap>
              <Cell center>
                <OrganisationLogo dimension={EDimension.EXTRA_LARGE} organisationID={organisationID} />
              </Cell>
              <Cell center>
                <H2>{organisationData.organisation.name}</H2>
                {organisationData.organisation.parentOrganisation && (
                  <Description>{organisationData.organisation.parentOrganisation.name}</Description>
                )}
              </Cell>
              {userOwnData.user.isAdmin && organisationID !== userOwnData.user.organisation.id && (
                <Cell center>
                  <ManageConnection organisationID={organisationID} />
                </Cell>
              )}
            </Grid>
          </Cell>
          <Cell as={Block} colSpan={12} colSpanMD={7}>
            <Grid noColGap>
              <Cell>
                <LabelledContent content={organisationData.organisation.about ?? "-"} label="About" />
              </Cell>
              <Cell>
                <LabelledContent
                  content={underscoredToTitleCase(organisationData.organisation.sector)}
                  label="Sector"
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell as={Block} colSpan={12} colSpanMD={5}>
            <Grid noColGap>
              <Cell>
                <IconContent description="Address" icon={EIcon.MAPPIN}>
                  <P>{organisationData.organisation.address}</P>
                  <P>{organisationData.organisation.city}</P>
                  {organisationData.organisation.county && <P>{organisationData.organisation.county}</P>}
                  <P>{organisationData.organisation.country}</P>
                  <P>{organisationData.organisation.postcode}</P>
                </IconContent>
              </Cell>
              {organisationData.organisation.phoneNumber && (
                <Cell>
                  <IconContent description="Telephone number" icon={EIcon.PHONE}>
                    <P>{organisationData.organisation.phoneNumber}</P>
                  </IconContent>
                </Cell>
              )}
              {organisationData.organisation.domain && (
                <Cell>
                  <IconContent description="Website URL" icon={EIcon.GLOBE}>
                    <P>
                      <ExternalTextLink href={organisationData.organisation.domain}>
                        {organisationData.organisation.domain}
                      </ExternalTextLink>
                    </P>
                  </IconContent>
                </Cell>
              )}
            </Grid>
          </Cell>
          <Cell center>
            {isAdminOfOrganisation && (
              <TextButton as={Link} to={SEGMENT_EDIT} status={EStatus.ACCENT}>
                Edit
              </TextButton>
            )}
          </Cell>
        </Grid>
      )}
    </>
  );
}
