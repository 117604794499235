import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import type { TParams } from "packages/utils";

import { useGetOrganisationOfSessionLazyQuery } from "packages/client/sessions/graphql/sessions.generated";
import { useGetEssentialOwnUserLazyQuery } from "packages/client/users/graphql/users.generated";

// a slightly simplified version of useCurrentOrg
export function useGetCurrentOrganisation() {
  const [organisationID, setOrganisationID] = useState<number>(undefined);
  const { callID }: TParams = useParams();

  const [getOrganisationSession] = useGetOrganisationOfSessionLazyQuery({
    variables: { sessionID: callID },
    fetchPolicy: "cache-and-network",
    onCompleted: data => setOrganisationID(data.getAllRoomInfo.roomTypeLink.organisation[0]),
  });

  const [getOrganisationOwnUser] = useGetEssentialOwnUserLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: data => setOrganisationID(parseInt(data.user.organisation.id)),
  });

  // if we have a callID, get the organisationID of the call's owner; else get your own organisationID
  useEffect(() => {
    if (callID) getOrganisationSession();
    else getOrganisationOwnUser();
  }, [callID, getOrganisationSession, getOrganisationOwnUser]);

  return { organisationID };
}
