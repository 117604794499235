import { useCallback, useEffect } from "react";

import { Cell, Field, Input, InputOption, Legend } from "packages/catalog";

import { EDays, EToggle, ERecurringFrequency } from "packages/client/sessions/redux/types";
import { days } from "packages/client/sessions/consts";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import { changeRecurringDay, toggleIsWeekdaysOnly } from "packages/client/sessions/redux/slice";

import styles from "./EditRecurringDays.module.scss";

export interface PEditRecurringDays {
  isEditMode: boolean;
}

export function EditRecurringDays({ isEditMode }: PEditRecurringDays) {
  const dispatch = useTypedDispatch();
  const { recurringInterval, recurringOnDays, recurringWeekdaysOnly, startTime } = useTypedSelector(
    ({ sessionForm }) => ({
      recurringInterval: sessionForm.recurringInterval,
      recurringOnDays: sessionForm.recurringOnDays,
      recurringWeekdaysOnly: sessionForm.recurringWeekdaysOnly,
      startTime: sessionForm.startTime,
    }),
  );

  const handleDaySelect = useCallback(
    (v: EDays) => {
      if (recurringInterval === ERecurringFrequency.Weekly) {
        dispatch(
          changeRecurringDay({ days: v, toggle: recurringOnDays?.includes(v) ? EToggle.Unselect : EToggle.Select }),
        );
      } else {
        if (!recurringOnDays || !recurringOnDays.includes(v))
          dispatch(changeRecurringDay({ days: v, toggle: EToggle.Select }));
      }
    },
    [dispatch, recurringInterval, recurringOnDays],
  );

  useEffect(() => {
    if (recurringInterval !== ERecurringFrequency.Daily && !isEditMode) {
      const valueOfStartDay = startTime.getDay() !== 0 ? startTime.getDay() - 1 : 6;

      dispatch(changeRecurringDay({ days: recurringOnDays[0], toggle: EToggle.Unselect }));

      if (!recurringOnDays?.includes(valueOfStartDay)) {
        dispatch(changeRecurringDay({ days: valueOfStartDay, toggle: EToggle.Select }));
      }
    }
  }, [startTime]);

  const dayMapper = (
    <fieldset>
      <Legend hasMargin>Repeat on</Legend>
      <div className={styles.week}>
        {days.map(day => (
          <label className={styles.day} key={day.value}>
            <input
              checked={recurringOnDays?.includes(day.value) ?? false}
              name="day"
              onChange={() => handleDaySelect(day.value)}
              type="checkbox"
            />
            <span className={styles.name}>{day.shortName}</span>
          </label>
        ))}
      </div>
    </fieldset>
  );

  return (
    <Cell>
      {recurringInterval === ERecurringFrequency.Daily && (
        <Field label="Repeat on">
          <InputOption label="Only weekdays">
            <Input
              checked={recurringWeekdaysOnly}
              name="weekdays"
              onChange={() => dispatch(toggleIsWeekdaysOnly(!recurringWeekdaysOnly))}
              type="checkbox"
            />
          </InputOption>
        </Field>
      )}
      {(recurringInterval === ERecurringFrequency.Weekly || recurringInterval === ERecurringFrequency.Monthly) &&
        dayMapper}
    </Cell>
  );
}
