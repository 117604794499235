import { createContext, ReactNode, useContext } from "react";

import type { ISnackbar, TSnackbarFeedbackAction } from "packages/client/snackbar-feedback/redux/types";

import { useSnackbarFeedback } from "packages/client/snackbar-feedback/hooks";

export interface PSnackbarFeedbackContext {
  children: ReactNode;
}

function noop() {
  //nothing
}

export interface ISnackbarFeedbackContext {
  dismissSnackbar: (id: string) => void;
  dispatchLinkSnackbar: (content: string, linkContent: string, to: string) => void;
  dispatchRetrySnackbar: (content: string, ontrigger: () => void) => void;
  dispatchSnackbar: (snackbar: ISnackbar) => void;
  snackbarFeedbackDispatch: (value: TSnackbarFeedbackAction) => void;
  snackbars: ISnackbar[];
}

const SnackbarFeedbackStore = createContext<ISnackbarFeedbackContext>({
  dismissSnackbar: noop,
  dispatchLinkSnackbar: noop,
  dispatchRetrySnackbar: noop,
  dispatchSnackbar: noop,
  snackbarFeedbackDispatch: noop,
  snackbars: [],
});

export function SnackbarFeedbackContext({ children }: PSnackbarFeedbackContext) {
  const data = useSnackbarFeedback();
  return <SnackbarFeedbackStore.Provider value={data}>{children}</SnackbarFeedbackStore.Provider>;
}

export function useSnackbarFeedbackContext() {
  return useContext(SnackbarFeedbackStore);
}
