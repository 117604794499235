import type { CSSProperties } from "react";

import { EAttachment } from "packages/utils";

export function handlePlacement(a: EAttachment, w: number, h: number): CSSProperties {
  const height = `${h}px`;
  const spacing = "0.4rem";
  const width = `${w}px`;

  const placement = {
    [EAttachment.NORTH]: `translateX(calc(${width} - 50% - (${width} / 2))) translateY(calc(-100% - ${height} - ${spacing}))`,
    [EAttachment.NORTH_NORTH_EAST]: `translateX(calc(${width} - 100%)) translateY(calc(-100% - ${height} - ${spacing}))`,
    [EAttachment.NORTH_EAST]: `translateX(${width}) translateY(calc(-100% - ${height}))`,
    [EAttachment.EAST_NORTH_EAST]: `translateX(calc(${width} + ${spacing})) translateY(calc(-100% + (100% - ${height})))`,
    [EAttachment.EAST]: `translateX(calc(${width} + ${spacing})) translateY(calc(-50% - (${height} / 2)))`,
    [EAttachment.EAST_SOUTH_EAST]: `translateX(calc(${width} + ${spacing})) translateY(-100%)`,
    [EAttachment.SOUTH_EAST]: `translateX(${width})`,
    [EAttachment.SOUTH_SOUTH_EAST]: `translateX(calc(${width} - 100%)) translateY(${spacing})`,
    [EAttachment.SOUTH]: `translateX(calc(${width} - 50% - (${width} / 2))) translateY(${spacing})`,
    [EAttachment.SOUTH_SOUTH_WEST]: `translateY(${spacing})`,
    [EAttachment.SOUTH_WEST]: "translateX(-100%)",
    [EAttachment.WEST_SOUTH_WEST]: `translateX(calc(-100% - ${spacing})) translateY(-100%)`,
    [EAttachment.WEST]: `translateX(calc(-100% - ${spacing})) translateY(calc(-50% - (${height} / 2)))`,
    [EAttachment.WEST_NORTH_WEST]: `translateX(calc(-100% - ${spacing})) translateY(calc(-100% + (100% - ${height})))`,
    [EAttachment.NORTH_WEST]: `translateX(-100%) translateY(calc(-100% - ${height}))`,
    [EAttachment.NORTH_NORTH_WEST]: `translateY(calc(-100% - ${height} - ${spacing}))`,
  };

  return { transform: placement[a] };
}
