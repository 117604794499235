// screenshare sizing setting commented out as vidyo default layout in use

import { ChangeEvent, useCallback, useMemo } from "react";

// import {
//   useLayoutSettings,
//   //VidyoNetworkPolicy
// } from "packages/call";
import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import {
  Cell,
  Dropdown,
  Field,
  // Fieldset,
  Grid,
  H2,
  //IconButton,
  // Input,
  // TextButton,
  SidebarBack,
  SidebarHeader,
  SidebarMain,
  // Toggle,
  //ToggleOption,
  //Switch,
  //Tooltip,
} from "packages/catalog";
import { ETheme } from "packages/theming";
import {
  LOCAL_THEME,
  toSentenceCase, //EAttachment,
  //EAppearance,
  //EDimension,
  //EIcon,
  //EStatus
} from "packages/utils";

import { updateTheme } from "packages/client/layout/redux/slice";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function LayoutWeb() {
  const { theme } = useTypedSelector(({ layout }) => ({
    theme: layout.theme,
  }));
  const dispatch = useTypedDispatch();
  // const {
  //   isSelfShareMaximized,
  //   // isSpeakerMaximizedOnFallback,
  //   // networkPolicy,
  //   updateSettings,
  //   // userProvidedMaxSources,
  //   // userProvidedMinSources,
  //   // resetSettings,
  //   // isInitialState,
  // } = useLayoutSettings();

  const themeOptions = useMemo(() => {
    return Object.values(ETheme).reduce<Record<string, string>>((acc, v) => {
      acc[v] = `${toSentenceCase(v)}`;
      return acc;
    }, {});
  }, []);

  const handleChangeTheme = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const selectedTheme = e.target.value as ETheme;

      dispatch(updateTheme(selectedTheme));

      localStorage.setItem(LOCAL_THEME, selectedTheme);
    },
    [dispatch],
  );

  return (
    <>
      <RoutingChangeHandler message="The layout settings have loaded." />
      <SidebarHeader>
        <SidebarBack backTo=".." closeTo="../.." label="Settings" />
        <H2>Layout</H2>
      </SidebarHeader>
      <SidebarMain>
        <Grid noColGap>
          <Cell as="section">
            <Field label="Theme">
              <Dropdown name="theme" onChange={handleChangeTheme} options={themeOptions} value={theme} />
            </Field>
          </Cell>
          {/* <Cell as="section">
            <Fieldset>
              <ToggleOption
                label={
                  <span>
                    Keep all tiles the same size while screensharing
                    <Tooltip
                      attachment={EAttachment.SOUTH}
                      targetName="Toggle tile size while screensharing"
                      tip="If on speaker view, your own screenshare tile will not be maximised. Applies only to your own incall layout."
                      trigger={
                        <IconButton
                          appearance={EAppearance.GHOST}
                          as="button"
                          description=""
                          dimension={EDimension.SMALL}
                          icon={EIcon.INFO}
                          status={EStatus.ACCENT}
                        />
                      }
                    />
                  </span>
                }>
                <Toggle
                  id="shareOwnScreenSizeToggle"
                  checked={!isSelfShareMaximized}
                  onChange={e => updateSettings({ isSelfShareMaximized: !e.target.checked })}
                />
              </ToggleOption>
            </Fieldset>
          </Cell> */}
          {/* <Cell as="section">
          <TextButton appearance={EAppearance.GHOST} disabled={isInitialState} onClick={resetSettings}>
            Reset settings
          </TextButton>
        </Cell> */}
          {/* <Cell as="section">
          <Field description="B" label="b">
            <ToggleOption
              label={
                <span>
                  Maximize the main speaker when no one is screensharing
                  <Tooltip
                    attachment={EAttachment.EAST_NORTH_EAST}
                    targetName="Toggle main speaker size"
                    tip="Make main speaker large when no one is screensharing. Applies to only your own incall layout."
                    trigger={
                      <IconButton
                        appearance={EAppearance.GHOST}
                        as="button"
                        description=""
                        dimension={EDimension.SMALL}
                        icon={EIcon.INFO}
                        status={EStatus.ACCENT}
                      />
                    }
                  />
                </span>
              }>
              <Toggle
                id="speakerMaximizedOnFallback"
                checked={isSpeakerMaximizedOnFallback}
                onChange={e => updateSettings({ isSpeakerMaximizedOnFallback: e.target.checked })}
              />
            </ToggleOption>
          </Field>
        </Cell> */}
          {/* <Cell as="section">
          <Field description="C" label="c">
            <Switch
              selected={networkPolicy}
              onChange={v => updateSettings({ networkPolicy: v })}
              options={[
                {
                  id: VidyoNetworkPolicy.FPS,
                  label: "FPS",
                  title: "Prefer more images per seconds",
                },
                {
                  id: VidyoNetworkPolicy.PEOPLE,
                  label: "People",
                  title: "Prefer more people displayed",
                },
              ]}
            />
          </Field>
        </Cell> */}
          {/* <Cell>
          <Field
            description="The number of tiles displayed is based on your bandwidth. You can force more tiles to display, but this may compromise quality and speed."
            label={`Minimum tiles displayed (${userProvidedMinSources + 1})`}>
            <Input
              type="range"
              value={userProvidedMinSources}
              min={2}
              max={30}
              onChange={e => {
                const n = parseInt(e.target.value, 10);
                if (!isNaN(n)) updateSettings({ userProvidedMinSources: n });
              }}
            />
          </Field>
        </Cell>
        <Cell>
          <Field
            description="The number of tiles displayed is based on your bandwidth. You can force more tiles to display, but this may compromise quality and speed."
            label={`Maximum tiles displayed (${userProvidedMaxSources + 1})`}>
            <Input
              type="range"
              value={userProvidedMaxSources}
              min={2}
              max={30}
              onChange={e => {
                const n = parseInt(e.target.value, 10);
                if (!isNaN(n)) updateSettings({ userProvidedMaxSources: n });
              }}
            />
          </Field>
        </Cell> */}
        </Grid>
      </SidebarMain>
    </>
  );
}
