import { Link } from "react-router-dom";

import { IconButton } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

import styles from "./SidebarBack.module.scss";

export interface PSidebarBack {
  backTo: string;
  closeTo: string;
  label: string;
}

export function SidebarBack({ backTo, closeTo, label }: PSidebarBack) {
  return (
    <div className={styles.back}>
      <IconButton
        appearance={EAppearance.GHOST}
        as={Link}
        description={`Back to ${label}`}
        dimension={EDimension.SMALL}
        label={`Back to ${label}`}
        icon={EIcon.ARROWLEFT}
        status={EStatus.NEUTRAL}
        style={{ marginLeft: "-0.5rem" }}
        to={backTo}
      />
      <IconButton
        appearance={EAppearance.OUTLINE}
        as={Link}
        description="Close"
        dimension={EDimension.SMALL}
        icon={EIcon.X}
        status={EStatus.NEUTRAL}
        to={closeTo}
      />
    </div>
  );
}
