import { FormEvent, KeyboardEvent, TextareaHTMLAttributes, useEffect, useRef, useState } from "react";

import { IconButton, TextArea } from "packages/catalog";
import { EAppearance, EBreakpoint, EDimension, EIcon, EStatus } from "packages/utils";

import styles from "./ChatTextArea.module.scss";

export interface PChatTextArea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  sendDisabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onSend: () => void;
}

export function ChatTextArea({ sendDisabled, value, onBlur, onChange, onFocus, onSend, ...rest }: PChatTextArea) {
  const chatInput = useRef<HTMLTextAreaElement>();
  const [isActive, setIsActive] = useState(false);
  const [shouldResize, setShouldResize] = useState(false);

  const handleSend = (e: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    onSend();
    chatInput.current.style.height = "initial";
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (onFocus) onFocus();
      handleSend(e);
    }
  };

  const handleFocus = () => {
    setIsActive(true);
    if (onFocus) onFocus();
  };

  const handleBlur = () => {
    setIsActive(false);
    if (onBlur) onBlur();
  };

  useEffect(() => {
    chatInput.current.style.height = "2rem";
    chatInput.current.style.height = `${chatInput.current.scrollHeight / 10}rem`;
    setShouldResize(false);
  }, [shouldResize, value]);

  useEffect(() => {
    const handleChangedWindowSize = () => {
      setShouldResize(true);
    };
    const mql = window.matchMedia(`(min-width: ${EBreakpoint.BREAKPOINT_SM}px)`);
    mql.onchange = handleChangedWindowSize;
    return () => {
      mql.onchange = null;
    };
  }, []);

  return (
    <form
      className={`${styles.chatTextArea} ${isActive ? `${styles.focused}` : ""}`}
      onClick={() => chatInput.current.focus()}
      onSubmit={handleSend}>
      <TextArea
        name="message"
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyPress}
        ref={chatInput}
        rows={1}
        value={value}
        {...rest}
      />
      <IconButton
        appearance={EAppearance.GHOST}
        as="button"
        description="Send"
        dimension={EDimension.SMALL}
        disabled={sendDisabled ?? false}
        icon={EIcon.SEND}
        rotateby={45}
        status={EStatus.NEUTRAL}
        title="Send"
        type="submit"
      />
    </form>
  );
}
