/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-invalid-this */

export type AnyFunction = (...args: unknown[]) => unknown;

/** Debound lodash-style */
export function debounce<T extends AnyFunction>(callback: T, wait: number, immediate = false): T {
  let timeout: number = null;

  const wrapped: T = function (this: any) {
    const callNow = immediate && !timeout;
    const next = () => callback.apply(this, arguments);

    window.clearTimeout(timeout);
    timeout = window.setTimeout(next, wait);

    if (callNow) {
      next();
    }
  } as any as T;

  return wrapped;
}
