// all custom layout code is commented out to use the vidyo default layout. to bring back the custom layout, remove the web vs election VidyoCall, then make make VidyoCallElection the sole truth and in this file remove the useEffect, usePositionVidyoSDKStrem hook and the new return statement and bring back all commented code. remove the elementRef prop and usage from usePositionVidyoSDKStream.ts. finally, uncomment the layout switches in the sidebar layout and letterbox top components.

// import { useRef, useState, useMemo } from "react";
import { useEffect, useRef } from "react";
// import useObserveSizing from "../hooks/useObserveSizing";
// import usePositionVidyoSDKStream from "../hooks/usePositionVidyoSDKStream";
import { useVidyoSDKInstance } from "../../../status/hooks";
// import type {
//   //BlockPositioning,
//   VidyoParticipant,
//   ParticipantStream,
// } from "../types";
// import GenericStreamRenderer from "./GenericStreamRenderer";
// import useVidyoLayoutPosition from "../hooks/useVidyoLayoutPosition";
// import { useSelectorVidyo } from "../redux/index";

import styles from "./VidyoCall.module.scss";

const viewID = "callPlacement";

// export function mapStreamIDParticipants(videoStreams: ParticipantStream[], participants: VidyoParticipant[]) {
//   const mapPart = new Map<string, VidyoParticipant>();
//   participants.forEach(p => mapPart.set(p.userId, p));
//   const map = new Map<string, VidyoParticipant>();
//   videoStreams.forEach(s => {
//     map.set(s.stream.id, mapPart.get(s.participantUserID));
//   });
//   return Object.fromEntries(map.entries());
// }

//const defaultPos: BlockPositioning = [0, 0, 0, 0];

export function VidyoCallWeb() {
  //const [availableSize, setAvailableSize] = useState(defaultPos);
  const vidyoSDK = useVidyoSDKInstance();
  // const refDivSizing = useRef<HTMLDivElement>();
  // const { parts, videoStreams } = useSelectorVidyo(({ streams, participants }) => ({
  //   // mapCamerasPTZ: streams.mapCamerasPTZ,
  //   videoStreams: streams.videoStreams,
  //   parts: participants.participants,
  // }));

  //useObserveSizing(refDivSizing, setAvailableSize);

  // const streamsToRender = useVidyoLayoutPosition(availableSize);
  // const mapStreamsToPart = useMemo(() => mapStreamIDParticipants(videoStreams, parts), [parts, videoStreams]);

  // const listStreams = useMemo(
  //   () =>
  //     streamsToRender.map(e => ({
  //       ...e,
  //       // isPTZ: mapCamerasPTZ?.[e.stream.id] ?? false,
  //       participant: mapStreamsToPart?.[e.stream.id],
  //     })),
  //   [mapStreamsToPart, streamsToRender],
  // );

  // return (
  //   <div ref={refDivSizing} className={tilesStyling.allTilesContainer}>
  //     {listStreams.map(str => (
  //       <GenericStreamRenderer key={str.keyID} vidyoSDK={vidyoSDK} {...str} />
  //     ))}
  //   </div>
  // );

  const viewArea = document.getElementById(viewID);
  const viewAreaRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (vidyoSDK && viewAreaRef) {
      vidyoSDK
        .AssignViewToCompositeRenderer({
          viewId: viewID,
          viewStyle: "VIDYO_CONNECTORVIEWSTYLE_Default",
          remoteParticipants: 25,
        })
        .catch(() => console.error("Unable to assign view to composite renderer."));
    }

    return () => {
      if (vidyoSDK) vidyoSDK.HideView({ viewId: viewID });
    };
  }, [viewArea, vidyoSDK, viewAreaRef]);

  // IF DEFAULT VIDYO LAYOUT ENABLED ON ELECTRON we need this hook - this is our custom hook which determines the scale for electron positioning and places the video feed content in the place of our call area (default layout only, saved here for reference)
  // usePositionVidyoSDKStream(vidyoSDK, viewID, viewAreaRef);

  return <div className={styles.allTilesContainer} id={viewID} ref={viewAreaRef}></div>;
}
