import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { Field, Input } from "packages/catalog";
import { EStatus } from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { updatePIN } from "packages/client/sessions/redux/slice";

import { validatePIN } from "packages/client/precall/django/requests";

export interface PPINField {
  callID: string;
  isPinValid: boolean;
  setIsFetching: (isFetching: boolean) => void;
  setIsPinValid: (isPinValid: boolean) => void;
}

export function PINField({ isPinValid, callID, setIsFetching, setIsPinValid }: PPINField) {
  const { guestName } = useTypedSelector(({ authentication }) => ({
    guestName: authentication.guestName,
  }));

  const [pinError, setPinError] = useState<string>(null);
  const [pin, setPin] = useState("");

  const dispatch = useTypedDispatch();

  const handlePinChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    if (/^\d+$/.test(val) || val === "") setPin(val);
    else setPinError("PIN must be numeric");
  }, []);

  useEffect(() => {
    let cancelled = false;

    async function checkValid() {
      try {
        setPinError(null);
        setIsFetching(true);
        const isValid = await validatePIN(callID, pin);
        if (!cancelled) {
          setIsPinValid(isValid);
        }
        if (!isValid) setPinError("Incorrect PIN");
      } catch (err) {
        setPinError((err as Error)?.message ?? "Unable to validate your PIN. Please contact ClassView support.");
        console.error(`Error validating PIN: [${(err as Error).message}]`);
      } finally {
        setIsFetching(false);
      }
    }

    const timer = pin && callID ? window.setTimeout(checkValid, 500) : undefined;

    dispatch(updatePIN(pin));

    return () => {
      window.clearTimeout(timer);
      cancelled = true;
    };
  }, [dispatch, callID, pin, setIsFetching, setIsPinValid]);

  return (
    <Field label="PIN" alerts={pinError?.length && [{ message: pinError, status: EStatus.DANGER }]}>
      <Input
        autoComplete="off"
        autoFocus={guestName.length && (!pin?.length || !isPinValid)}
        maxLength={4}
        minLength={4}
        onChange={handlePinChange}
        required
        type="password"
        value={pin}
      />
    </Field>
  );
}
