import { ApolloClient, HttpLink, NormalizedCacheObject, Operation, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient as createClientWs } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

import { NODE_API_PATH } from "packages/utils";

import { cache } from "packages/client/graphql/apolloCache";

const SERVER_URI = `${DOMAIN}${NODE_API_PATH}/graphql`;

export let client: ApolloClient<NormalizedCacheObject> = null;

function querySplitter({ query }: Operation) {
  const definition = getMainDefinition(query);
  return definition.kind === "OperationDefinition" && definition.operation === "subscription";
}

export function createClient(token?: string) {
  if (client) {
    client.stop();
    client = null;
  }
  cache.reset();
  const h = token
    ? {
        authorization: token,
      }
    : {};
  const httpLink = new HttpLink({
    uri: `https://${SERVER_URI}`,
    headers: h,
  });
  const wsLink = new GraphQLWsLink(
    createClientWs({
      url: `wss://${SERVER_URI}`,
      lazy: true,
      connectionParams: h,
    }),
  );
  const splitLink = split(querySplitter, wsLink, httpLink);

  client = new ApolloClient({
    cache,
    link: splitLink,
    queryDeduplication: true,
    connectToDevTools: process.env.NODE_ENV !== "production",
  });

  return client;
}
