import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Alert, Input, Dropdown } from "packages/catalog";
import { EColorSpace, convertColor, HSL, RGB, EStatus } from "packages/utils";

import styles from "./InputFields.module.scss";

const formatOptions = Object.values(EColorSpace).reduce<Record<string, string>>((acc, v) => {
  acc[v] = v.toLocaleUpperCase();
  return acc;
}, {});

const validMax = {
  h: 360,
  sl: 100,
  rgb: 255,
};

export interface PInputFields {
  colorHSL: HSL;
  setColorHSL: (hsl: HSL) => void;
}

export function InputFields({ colorHSL, setColorHSL }: PInputFields) {
  const [colorFormat, setColorFormat] = useState(EColorSpace.HSL);
  const [hexValue, setHexValue] = useState<string>(null);
  const [invalidColor, setInvalidColor] = useState(false);

  const lastValidHex = useRef<string>(null);

  const multipleColorValues: HSL | RGB = useMemo(
    () =>
      colorFormat === EColorSpace.HSL ? colorHSL : (convertColor(EColorSpace.HSL, EColorSpace.RGB, colorHSL) as RGB),
    [colorFormat, colorHSL],
  );

  const handleColorFormatChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const newFormat = e.target.value as EColorSpace;
      setColorFormat(newFormat);
      if (newFormat === EColorSpace.HEX) {
        const hex = convertColor(EColorSpace.HSL, EColorSpace.HEX, colorHSL) as string;
        setHexValue(hex);
        lastValidHex.current = hex;
      }
      if (newFormat !== EColorSpace.HEX) {
        setHexValue(null);
        lastValidHex.current = null;
        setInvalidColor(false);
      }
    },
    [colorHSL, setColorFormat, setHexValue],
  );

  const handleHexChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setHexValue(value);
      try {
        const hsl = convertColor(EColorSpace.HEX, EColorSpace.HSL, value) as HSL;
        setColorHSL(hsl);
        lastValidHex.current = value;
      } catch {
        setInvalidColor(true);
      }
    },
    [setColorHSL],
  );

  const handleXValueChange = useCallback(
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      if (!parseInt(e.target.value)) return;
      const value = Math.min(
        parseInt(e.target.value),
        colorFormat === EColorSpace.HSL ? (index === 0 ? validMax.h : validMax.sl) : validMax.rgb,
      );
      const newColor = [...multipleColorValues];
      newColor[index] = value;
      try {
        const hsl =
          colorFormat === EColorSpace.HSL ? newColor : convertColor(EColorSpace.RGB, EColorSpace.HSL, newColor);
        setColorHSL(hsl as HSL);
      } catch {
        setInvalidColor(true);
      }
    },
    [colorFormat, multipleColorValues, setColorHSL],
  );

  const handleFirstValueChange = useMemo(() => handleXValueChange(0), [handleXValueChange]);
  const handleSecondValueChange = useMemo(() => handleXValueChange(1), [handleXValueChange]);
  const handleThirdValueChange = useMemo(() => handleXValueChange(2), [handleXValueChange]);

  useEffect(() => {
    setInvalidColor(false);
  }, [colorHSL, setInvalidColor]);

  useEffect(() => {
    if (!lastValidHex.current || colorFormat !== EColorSpace.HEX) return;
    const hsl = convertColor(colorFormat, EColorSpace.HSL, lastValidHex.current) as HSL;
    if (hsl.some((value, index) => value !== colorHSL[index])) {
      setHexValue(convertColor(EColorSpace.HSL, EColorSpace.HEX, colorHSL) as string);
    }
  }, [colorHSL, colorFormat, setHexValue]);

  return (
    <div className={styles.inputFields}>
      <Dropdown
        name="colorFormat"
        type="dropdown"
        options={formatOptions}
        value={colorFormat}
        onChange={handleColorFormatChange}
      />
      <div
        className={`${styles.colorInputs} ${
          colorFormat === EColorSpace.HEX ? styles.singleInput : styles.multipleInputs
        }`}>
        {colorFormat === EColorSpace.HEX ? (
          <>
            <Input name="hex" type="text" value={hexValue} onChange={handleHexChange} maxLength={7} />
            {invalidColor && <Alert status={EStatus.DANGER} message="Please enter a valid color" />}
          </>
        ) : (
          <>
            <fieldset className={styles.inputs}>
              <Input
                max={colorFormat === EColorSpace.HSL ? validMax.h : validMax.rgb}
                min={0}
                name="h"
                onChange={handleFirstValueChange}
                type="number"
                value={Math.round(multipleColorValues[0])}
              />
              <Input
                max={colorFormat === EColorSpace.HSL ? validMax.sl : validMax.rgb}
                min={0}
                name="s"
                onChange={handleSecondValueChange}
                type="number"
                value={Math.round(multipleColorValues[1])}
              />
              <Input
                max={colorFormat === EColorSpace.HSL ? validMax.sl : validMax.rgb}
                min={0}
                name="l"
                onChange={handleThirdValueChange}
                type="number"
                value={Math.round(multipleColorValues[2])}
              />
            </fieldset>
            {invalidColor && <Alert status={EStatus.DANGER} message="Please enter a valid color" />}
          </>
        )}
      </div>
    </div>
  );
}
