import { EVidyoRecordingActions, IRecordingState, TRecordingAction } from "./types";
import { ECallStatus, EVidyoStatusActions } from "../../status/redux/types";

import type { TStatusAction } from "../../status/redux/types";

const initialState: IRecordingState = {
  isRecording: false,
  isPaused: false,
  passiveUpdateActive: !IS_ELECTRON,
};

export function recordingReducer(state = initialState, action: TRecordingAction | TStatusAction): IRecordingState {
  switch (action.type) {
    case EVidyoStatusActions.SET_CALL_STATUS:
      return action.status !== ECallStatus.IN_CALL ? initialState : state;
    case EVidyoRecordingActions.RECORDING_CHANGE:
      return { ...state, isPaused: action.isPaused, isRecording: action.isRecording };
    default:
      return state;
  }
}
