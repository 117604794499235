import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useVidyoControl } from "packages/call";
import { PATH_POSTCALL, TParams } from "packages/utils";

export function useLeaveCall() {
  const { callID }: TParams = useParams();
  const navigate = useNavigate();
  const { forceDisconnect } = useVidyoControl();

  const leaveCall = useCallback(async () => {
    await forceDisconnect()
      .then(() => {
        navigate(PATH_POSTCALL(callID));
      })
      .catch(() => {
        console.log("Unable to disconnect");
      });
  }, [callID, forceDisconnect, navigate]);

  return leaveCall;
}
