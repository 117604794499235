import { useCallback, useEffect, useMemo, useState } from "react";

import { LOCAL_ANNOUNCEMENTS_DISMISSED } from "packages/utils";

import { useGetActiveAnnouncementsQuery } from "packages/client/announcements/graphql/announcements.generated";

function storeDismissed(ids: string[]) {
  localStorage.setItem(LOCAL_ANNOUNCEMENTS_DISMISSED, JSON.stringify(ids));
}
function getDismissed() {
  const l: string[] = JSON.parse(localStorage.getItem(LOCAL_ANNOUNCEMENTS_DISMISSED)) ?? [];
  return l;
}

export function useAnnouncements() {
  const { data, startPolling, stopPolling, called, loading } = useGetActiveAnnouncementsQuery();
  const [dismissed, setDismissed] = useState<string[]>(getDismissed);

  useEffect(() => {
    startPolling(30 * 1000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  const announcements = useMemo(() => {
    return data?.activePublicAnnouncements?.filter(p => !dismissed?.includes(p.id)) ?? [];
  }, [data?.activePublicAnnouncements, dismissed]);

  const onDismiss = useCallback(
    (ids: string[]) => {
      setDismissed([...dismissed, ...ids]);
    },
    [dismissed],
  );

  useEffect(() => {
    storeDismissed(dismissed);
  }, [dismissed]);

  useEffect(() => {
    if (called && !loading) {
      const activeIds = data?.activePublicAnnouncements?.map(a => a.id);
      const toKeepDismissed = dismissed?.filter(d => activeIds?.includes(d));
      if (toKeepDismissed?.length !== dismissed?.length) {
        setDismissed(toKeepDismissed);
      }
    }
  }, [called, data?.activePublicAnnouncements, dismissed, loading]);

  return { announcements, onDismiss };
}
