import { FocusEvent, KeyboardEvent, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";

import { Label, IAutocompleteDropdownOption, P } from "packages/catalog";

import { DropdownListItem } from "./DropdownListItem";

import styles from "./DropdownList.module.scss";

export interface PDropdownList {
  autoCompleteSearchRef: MutableRefObject<HTMLInputElement>;
  dropdownListRef: MutableRefObject<HTMLDivElement>;
  onSelect: (option: IAutocompleteDropdownOption) => void;
  options: IAutocompleteDropdownOption[];
  optionsCollectiveName: string;
  searchInput: string;
  setDropdownOpen: (open: boolean) => void;
  showNoResultsMessage?: boolean;
}

export function DropdownList({
  autoCompleteSearchRef,
  dropdownListRef,
  onSelect,
  options,
  optionsCollectiveName,
  searchInput,
  setDropdownOpen,
  showNoResultsMessage,
}: PDropdownList) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleFocusOnKeyDown = useCallback(
    (step: 1 | -1) => {
      const newIndex = activeIndex + step;
      newIndex > -1 ? setActiveIndex(newIndex % options.length) : autoCompleteSearchRef.current.focus();
    },
    [activeIndex, autoCompleteSearchRef, options.length],
  );

  const handleSelect = useCallback(
    (option?: IAutocompleteDropdownOption) => {
      option ? onSelect(option) : onSelect(options[activeIndex]);
      autoCompleteSearchRef.current.focus();
      setDropdownOpen(false);
    },
    [onSelect, options, activeIndex, autoCompleteSearchRef, setDropdownOpen],
  );

  const handleKeyboardEvents = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      switch (event.key) {
        case "ArrowUp":
        case "ArrowDown":
          event.preventDefault();
          handleFocusOnKeyDown(event.key === "ArrowDown" ? 1 : -1);
          break;
        case "Enter":
          handleSelect();
          break;
        case "Escape":
          autoCompleteSearchRef.current.focus();
          setDropdownOpen(false);
          break;
        default:
          autoCompleteSearchRef.current.focus();
      }
    },
    [autoCompleteSearchRef, handleFocusOnKeyDown, handleSelect, setDropdownOpen],
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      if (event.target.id === "autocompleteDropdown") {
        options.length > 0 ? setActiveIndex(0) : autoCompleteSearchRef.current.focus();
      }
    },
    [autoCompleteSearchRef, options.length],
  );

  const shouldDisplayNoResults = useMemo(
    () => (showNoResultsMessage && options.length === 0 && !!searchInput.trim().length) ?? false,
    [showNoResultsMessage, options.length, searchInput],
  );

  useEffect(() => {
    if (activeIndex > -1) document.querySelector("[aria-selected='true']")?.scrollIntoView({ block: "nearest" });
  }, [activeIndex, options, searchInput]);

  return (
    <div
      className={`${styles.dropdown}`}
      tabIndex={-1}
      onFocus={handleFocus}
      onBlur={() => setActiveIndex(-1)}
      onKeyDown={handleKeyboardEvents}
      ref={dropdownListRef}>
      {shouldDisplayNoResults ? (
        <P
          className={
            styles.noResults
          }>{`Sorry, we were unable to find any matching ${optionsCollectiveName.toLowerCase()}`}</P>
      ) : (
        <>
          <Label className={styles.label} text={optionsCollectiveName} />
          <ul id="autocompleteOptions" role="listbox" aria-label={optionsCollectiveName}>
            {options.map((option, index) => (
              <DropdownListItem
                focused={activeIndex === index}
                key={option.id}
                option={option}
                searchInput={searchInput}
                onSelect={handleSelect}
              />
            ))}
          </ul>
          <div aria-live="polite" role="status" className={styles.visuallyHidden}>
            {`${options.length} results available.`}
          </div>
        </>
      )}
    </div>
  );
}
