import { useMemo } from "react";

import { useGetOrganisationPermissionsInfoOwnUserQuery } from "packages/client/users/graphql/users.generated";
import { OrganisationPricingPlan } from "packages/client/graphql_definitions.generated";

export function useIsRecordingDisabled() {
  const { data } = useGetOrganisationPermissionsInfoOwnUserQuery();
  const isRecordingDisabled = useMemo(
    () =>
      data?.user?.organisation?.pricingPlan === OrganisationPricingPlan.Schools ||
      data?.user?.organisation?.recordingDisabled,
    [data?.user?.organisation?.pricingPlan, data?.user?.organisation?.recordingDisabled],
  );
  return { isRecordingDisabled };
}
