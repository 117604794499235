import { HEADER_JSON } from "packages/utils";

import { fetchDjangoAPIAuthenticated } from "packages/client/fetch";

export async function deleteRecording(recordingID: string) {
  const response = await fetchDjangoAPIAuthenticated(`/recordings/${recordingID}/`, {
    headers: HEADER_JSON,
    method: "DELETE",
  });

  if (response.status !== 204) {
    throw new Error("Unable to delete recording.");
  }

  return response;
}
