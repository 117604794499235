import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import {
  // Link,
  useParams,
} from "react-router-dom";

import {
  // IconButton,
  Cell,
  Feedback,
  Grid,
  Input,
  QuestionFieldset,
} from "packages/catalog";
import {
  // EAppearance,
  // EDimension,
  TParams,
  EStatus,
} from "packages/utils";

import { useCreatePollResponseMutation, useGetEssentialPollQuery } from "packages/client/polls/graphql/polls.generated";
import { PollStatusV2 } from "packages/client/graphql_definitions.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function Vote() {
  const { pollID }: TParams = useParams();
  const [selectedAnswer, setSelectedAnswer] = useState("");

  const { data: pollData, loading: pollLoading } = useGetEssentialPollQuery({
    variables: {
      pollID,
    },
  });

  const answersOptions = useMemo(() => {
    const question = pollData?.getPollV2.questions[0];
    return question?.answerOptions.map(q => {
      return { answerOption: q.value, answerOptionID: q.id };
    });
  }, [pollData?.getPollV2.questions]);

  const [voteOnPoll, { data: voteSuccess, error: voteError }] = useCreatePollResponseMutation();

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedAnswer(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      voteOnPoll({
        variables: {
          newPollResponse: {
            pollId: pollID,
            answers: [
              {
                questionId: pollData?.getPollV2?.questions[0].id,
                answerOptionIds: [selectedAnswer],
              },
            ],
          },
        },
      });
    },
    [pollData?.getPollV2?.questions, pollID, selectedAnswer, voteOnPoll],
  );

  return (
    <>
      <RoutingChangeHandler message="The poll vote page has loaded." pageTitle="Vote" />
      <Grid as="form" noColGap onSubmit={handleSubmit}>
        {pollData?.getPollV2.status === PollStatusV2.Published ? (
          <>
            {voteSuccess ? (
              <Cell>
                <Feedback status={EStatus.SUCCESS}>Thank you! Your response was submitted.</Feedback>
              </Cell>
            ) : (
              <>
                {voteError && (
                  <Cell>
                    <Feedback status={EStatus.DANGER}>Unable to submit response.</Feedback>
                  </Cell>
                )}
                <Cell>
                  <QuestionFieldset
                    onChange={handleChange}
                    answerOptions={answersOptions}
                    question={pollData?.getPollV2.questions[0].value}
                    questionID={pollData?.getPollV2.questions[0].id}
                    selectedAnswerOption={selectedAnswer}
                  />
                </Cell>
                <Cell center>
                  <Input disabled={!selectedAnswer} type="submit" value="Submit your vote" />
                </Cell>
              </>
            )}
          </>
        ) : (
          !pollLoading && (
            <Cell>
              <Feedback status={EStatus.WARNING}>Sorry, this poll is not available.</Feedback>
            </Cell>
          )
        )}
      </Grid>
    </>
  );
}
