import { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Block,
  Cell,
  Dialog,
  Feedback,
  Grid,
  H1,
  H2,
  IconButton,
  Input,
  Loader,
  P,
  TextButton,
} from "packages/catalog";
import {
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  parseDjangoError,
  PATH_SESSION,
  scrollToContentTop,
  scrollToTop,
} from "packages/utils";

import type { IUseGetFullSessionResult } from "packages/client/sessions/hooks";
import type { IMutableSession } from "packages/client/sessions/graphql/types";

import { client } from "packages/client/graphql/apolloClient";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import { loadForm, resetForm } from "packages/client/sessions/redux/slice";
import { editSession, scheduleSession } from "packages/client/sessions/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import {
  EditDateTime,
  EditFeatures,
  EditOverview,
  EditParticipants,
  EditRecurring,
} from "packages/client/sessions/components";

export interface PSessionForm {
  isEditMode?: boolean;
  sessionData?: IUseGetFullSessionResult;
}

export function SessionForm({ isEditMode, sessionData }: PSessionForm) {
  const dispatch = useTypedDispatch();
  const sessionForm = useTypedSelector(state => state.sessionForm);

  const [asyncAlert, setAsyncAlert] = useState(null as { status: EStatus; message: string } | null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  // const [webcastExpired, setWebcastExpired] = useState(false);

  const navigate = useNavigate();

  const meetingIsLoading = useMemo(() => sessionData?.loading, [sessionData]);
  const meetingData = useMemo(
    () => (isEditMode && sessionData ? sessionData.meeting : null),
    [isEditMode, sessionData],
  );
  const isAnExistingRepeatingMeetingStartedInThePast = useMemo(
    () => new Date(meetingData?.startTime).getTime() < Date.now(),
    [meetingData?.startTime],
  );
  const spansTwoDays = useMemo(
    () => new Date(meetingData?.endTime).toDateString() !== new Date(meetingData?.startTime).toDateString(),
    [meetingData],
  );

  const hasSlotExpired = useCallback(() => {
    const hasExpired = Date.now() > new Date(sessionForm.startTime).getTime();
    setShouldRefresh(hasExpired);
    setIsModalOpen(hasExpired);
    return hasExpired;
  }, [sessionForm.startTime]);

  // const hasWebcastExpired = useCallback(() => {
  //   const hasExpired = new Date(state.startTime).getTime() < Date.now() + 901000; // time before webcast can start
  //   setWebcastExpired(hasExpired);
  //   if (hasExpired) dispatch(updateWebcastMode(false));
  //   return hasExpired;
  // }, [dispatch, state.startTime]);

  // to allow submission of the form if a repeating session started in the past - YVAN TODO server side allowing?

  const displayMessage = useCallback((status: EStatus, message: string) => {
    scrollToContentTop();
    scrollToTop();
    setAsyncAlert({ status, message });
  }, []);

  const handleSchedule = useCallback(async () => {
    displayMessage(EStatus.LOADING, "Scheduling session...");

    try {
      const res = await scheduleSession(sessionForm);

      if (res.status === 201) {
        displayMessage(EStatus.SUCCESS, "Session scheduled.");
        client.resetStore();
        dispatch(resetForm());
        setShouldRefresh(true);
      } else {
        throw await parseDjangoError(res);
      }
    } catch (err) {
      if (err) {
        displayMessage(EStatus.DANGER, `${(err as Error).message}`);
      } else {
        setAsyncAlert(null);
      }
    }
  }, [dispatch, displayMessage, sessionForm]);

  const handleEdit = useCallback(async () => {
    displayMessage(EStatus.LOADING, "Updating session...");

    try {
      const res = await editSession(sessionForm.id, sessionForm);

      if (res.status === 200) {
        displayMessage(EStatus.SUCCESS, "Session updated.");
        client.resetStore();
        setTimeout(() => navigate(PATH_SESSION(sessionForm.id)), 500);
      } else {
        throw await parseDjangoError(res);
      }
    } catch (err) {
      if (err) {
        displayMessage(EStatus.DANGER, `${(err as Error).message}`);
      } else {
        setAsyncAlert(null);
      }
    }
  }, [displayMessage, navigate, sessionForm]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      setAsyncAlert(null);
      //if (state.isWebcastMode && hasWebcastExpired()) return;
      if (isAnExistingRepeatingMeetingStartedInThePast || !hasSlotExpired()) {
        if (isEditMode) {
          handleEdit();
        } else {
          handleSchedule();
        }
      }
    },
    [
      hasSlotExpired,
      //hasWebcastExpired,
      handleEdit,
      handleSchedule,
      isAnExistingRepeatingMeetingStartedInThePast,
      isEditMode,
      // state.isWebcastMode,
    ],
  );

  useEffect(() => {
    if (!sessionForm.subject || (!sessionForm.recurringRepetitions && isNaN(sessionForm.recurringRepetitions))) {
      setSubmitButtonDisabled(true);
    } else {
      setSubmitButtonDisabled(false);
    }
  }, [sessionForm.subject, sessionForm.recurringRepetitions]);

  useEffect(() => {
    // loads the session data into the reducer
    if (isEditMode && meetingData && !sessionForm.id) {
      dispatch(loadForm(meetingData as IMutableSession));
    }
  }, [dispatch, isEditMode, meetingData, sessionForm]);

  useEffect(() => {
    if (shouldRefresh) setShouldRefresh(false);
  }, [shouldRefresh]);

  useEffect(() => {
    if (!isEditMode) {
      // we need to run both of these to reset the form to its null state, then refresh it to force the start date to re-calcuate
      dispatch(resetForm());
      setShouldRefresh(true);
    }
  }, [dispatch, isEditMode]);

  if (isEditMode && meetingIsLoading) return <Loader />;
  if (isEditMode && !meetingData) return null;

  if (spansTwoDays)
    return (
      <Feedback status={EStatus.DANGER}>
        {
          "We're sorry, unfortunately this session's end time is invalid and the session can't be edited. Please delete this session and schedule it again."
        }
      </Feedback>
    );

  if (!meetingIsLoading) {
    return (
      <>
        <RoutingChangeHandler
          message={`The ${isEditMode ? "edit" : "schedule"} session page has loaded`}
          pageTitle={`${isEditMode ? "Edit" : "Schedule"} session`}
        />
        <Grid noColGap>
          {asyncAlert && (
            <Cell>
              <Feedback status={asyncAlert.status}>{asyncAlert.message}</Feedback>
            </Cell>
          )}
          <Cell>
            <IconButton
              appearance={EAppearance.GHOST}
              as={Link}
              description={isEditMode ? "Cancel" : "Sessions"}
              dimension={EDimension.SMALL}
              icon={EIcon.ARROWLEFT}
              label={isEditMode ? "Cancel" : "Sessions"}
              status={EStatus.NEUTRAL}
              to="./.."
            />
          </Cell>
          <Cell>
            <H1>{isEditMode ? "Edit session" : "Schedule session"}</H1>
          </Cell>
          <Cell>
            <Grid as="form" onSubmit={handleSubmit}>
              <Cell colSpanLG={8}>
                <Grid as={Block} noColGap>
                  <Cell>
                    <H2>Session details</H2>
                  </Cell>
                  <EditOverview />
                  {(!isEditMode || sessionForm.startTime) && ( // do not render if data has not been loaded into reducer
                    <EditDateTime
                      existingEndDateTime={meetingData?.endTime}
                      existingStartDateTime={meetingData?.startTime}
                      isEditMode={isEditMode}
                      shouldRefresh={shouldRefresh}
                    />
                  )}
                  <EditRecurring isEditMode={isEditMode} />
                  <EditFeatures
                  //webcastExpired={webcastExpired}
                  />
                </Grid>
              </Cell>
              <Cell colSpanLG={4}>
                <EditParticipants />
              </Cell>
              <Cell center>
                {isEditMode ? (
                  <>
                    <TextButton appearance={EAppearance.GHOST} as={Link} to={PATH_SESSION(meetingData.id)}>
                      Cancel
                    </TextButton>
                    <Input disabled={!sessionForm.startTime || !sessionForm.endTime} type="submit" value="Save" />
                  </>
                ) : (
                  <Input disabled={submitButtonDisabled} type="submit" value="Schedule session" />
                )}
              </Cell>
            </Grid>
          </Cell>
        </Grid>
        <Dialog h1="Unable to schedule session" isOpen={isModalOpen} onAbort={() => setIsModalOpen(!isModalOpen)}>
          <P hasMargin>The selected session start time has expired.</P>
          <P>The time slots will be reset. Please check and adjust your time slots.</P>
        </Dialog>
      </>
    );
  }
}
