import { useCallback, useEffect, useMemo, useState } from "react";

import {
  useGetInvitedRoomSystemsQuery,
  useSearchRoomSystemsIncallQuery,
} from "packages/client/room-systems/graphql/roomsystems.generated";
import { addRoomSystemsToSession } from "packages/client/incall/django/requests";

import { useRoom, useVidyoContext } from "packages/client/incall/hooks";

const successMessage = "Room system(s) added to the call.";
const errorMessage = "Unable to add room system(s).";

export interface IRoomSystem {
  dialOut: boolean;
  id: string;
  ip: string;
  name: string;
}

export function useAddRoomSystem(search: string) {
  const [dialFailedId, setDialFailedId] = useState<string | null>(null);
  const [feedbackMessage, setFeedbackMessage] = useState<string>(null);

  const { room, sessionID } = useRoom();
  const {
    moderation: { participantStatuses },
  } = useVidyoContext();
  const roomKey = room?.roomId;

  const { data } = useSearchRoomSystemsIncallQuery({ variables: { search }, returnPartialData: true });
  const { data: dataInvited } = useGetInvitedRoomSystemsQuery({
    variables: {
      meetingID: sessionID,
    },
    returnPartialData: true,
    skip: !sessionID,
  });

  const invitedRooms = useMemo(() => {
    if (!dataInvited?.meeting?.roomSystems) return [];
    return dataInvited.meeting.roomSystems
      .filter(room => !room.dialOut)
      .map<IRoomSystem>(r => ({
        dialOut: r.dialOut,
        id: r.roomSystem.id,
        ip: r.roomSystem.ip,
        name: r.roomSystem.name,
      }));
  }, [dataInvited]);

  const roomSystemsInCallFromInvited = useMemo(
    () => invitedRooms.filter(r => participantStatuses.some(p => p.id === r.ip || p.id === r.name)),
    [invitedRooms, participantStatuses],
  );

  const dialableRooms = useMemo(
    () => invitedRooms.filter(room => !roomSystemsInCallFromInvited.includes(room)),
    [invitedRooms, roomSystemsInCallFromInvited],
  );

  const dialRoom = useCallback(
    async (roomId: string, showSuccessMessage?: boolean) => {
      setFeedbackMessage(null);
      setDialFailedId(null);
      try {
        await addRoomSystemsToSession(roomKey, [roomId]);
        if (showSuccessMessage) setFeedbackMessage(successMessage);
      } catch (error) {
        console.error(`Error dialling room system: [${(error as Error).message}]`);
        setFeedbackMessage(errorMessage);
        setDialFailedId(roomId);
      }
    },
    [roomKey, setFeedbackMessage, setDialFailedId],
  );

  useEffect(() => {
    if (feedbackMessage) {
      const messageTimeOut = setTimeout(() => {
        setFeedbackMessage(null);
        setDialFailedId(null);
      }, 3000);
      return () => clearTimeout(messageTimeOut);
    }
  }, [feedbackMessage]);

  const roomSystemsOnSearch = useMemo(() => {
    const d = data?.allRoomSystems?.objects ?? [];
    return d.map(room => {
      return { name: room.name, id: room.id };
    });
  }, [data?.allRoomSystems?.objects]);

  return {
    dialableRooms,
    dialFailedId,
    dialRoom,
    feedbackMessage,
    roomSystemsOnSearch,
  };
}
