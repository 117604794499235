import { HEADER_JSON } from "packages/utils";

import type { IDjangoPIN } from "./types";

import { fetchDjangoAPIAuthenticated } from "packages/client/fetch";

export async function addHub(name: string) {
  const resp = await fetchDjangoAPIAuthenticated("/users/hubs/", {
    body: JSON.stringify({ name, is_classview: false }),
    headers: HEADER_JSON,
    method: "POST",
  });

  const nameValid: string = await resp.json();
  return nameValid;
}

export async function createHub(name: string) {
  const resp = await fetchDjangoAPIAuthenticated("/users/hubs/", {
    body: JSON.stringify({ name, is_classview: false }),
    headers: HEADER_JSON,
    method: "POST",
  });

  if (resp.status !== 200) {
    throw new Error("Unable to create hub.");
  }

  return resp;
}

export async function deleteHub(hubID: string) {
  const resp = await fetchDjangoAPIAuthenticated(`/rooms/${hubID}/`, {
    method: "DELETE",
    headers: HEADER_JSON,
  });

  if (resp.status !== 204) {
    throw new Error("Unable to delete hub.");
  }

  return resp;
}

export async function setPin(roomID: string, pin?: number) {
  const body: { pin: number | null } = { pin: null };
  if (pin) body.pin = pin;

  const resp = await fetchDjangoAPIAuthenticated(`/rooms/${roomID}/`, {
    body: JSON.stringify(body),
    headers: HEADER_JSON,
    method: "PATCH",
  });

  if (resp.status !== 200) throw new Error(resp.statusText);

  const data: { key: string; pin: number | null } = await resp.json();
  const p: IDjangoPIN = {
    pin: data.pin,
    key: data.key,
  };
  return p;
}
