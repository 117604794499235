import { createElement, useState, useEffect } from "react";

import { EM, ExternalTextLink, H1, H2, H3, HR, LI, OL, P, Q, Strong, UL } from "packages/catalog";
import { getLazyDependencyInstance } from "packages/utils";

export interface PMarkdownRenderer {
  value: string;
}

export const renderMarkdown = getLazyDependencyInstance(async () => {
  const [
    { unified },
    { default: parse },
    { default: gfm },
    { default: remarkRehype },
    { default: rehypeExternalLinks },
    { default: rehype2react },
  ] = await Promise.all([
    import("unified"),
    import("remark-parse"),
    import("remark-gfm"),
    import("remark-rehype"),
    import("rehype-external-links"),
    import("rehype-react"),
  ]);

  const renderer = unified()
    .use(parse)
    .use(gfm)
    .use(remarkRehype)
    .use(rehypeExternalLinks)
    .use(rehype2react, {
      createElement,
      components: {
        a: ExternalTextLink,
        blockquote: Q,
        em: EM,
        h1: H1,
        h2: H2,
        h3: H3,
        hr: HR,
        li: LI,
        ol: OL,
        p: P,
        strong: Strong,
        ul: UL,
      },
    });
  return renderer;
});

export function Markdown({ value }: PMarkdownRenderer) {
  const [rendered, setRendered] = useState(null);

  useEffect(() => {
    let cancelled = false;

    async function render() {
      const renderer = await renderMarkdown();
      const { result } = await renderer.process(value);

      if (!cancelled) setRendered(result);
    }

    render();

    return () => {
      cancelled = true;
    };
  }, [value]);

  return <>{rendered}</>;
}
