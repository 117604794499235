import type { IParticipantStream } from "../interfaces";

import type { EVidyoDeviceStatus } from "../../status/enums";
import type { TStatusableDevice } from "../../status/types";

export enum EVidyoStreamActions {
  ADD_REMOTE_DEVICE = "streams/addRemoteDevice",
  ADD_STREAM = "streams/add",
  PIN_STREAM = "streams/pin",
  REMOVE_REMOTE_DEVICE = "streams/removeRemoteDevice",
  REMOVE_STREAM = "streams/remove",
  UPDATE_REMOTE_DEVICE_STATE = "streams/updateRemoteDeviceState",
}

export interface IStreamsState {
  // mapCamerasPTZ: Record<string, boolean>;
  isThereARemoteSharing: boolean;
  mapParticipantCameras: TMapUserIDDeviceStatuses;
  mapParticipantMicrophones: TMapUserIDDeviceStatuses;
  pinnedStreamID: string;
  videoStreams: IParticipantStream[];
}

export interface IDeviceStatus {
  deviceId: string;
  deviceName: string;
  deviceType: TStatusableDevice;
  state?: EVidyoDeviceStatus;
}

export type TMapUserIDDeviceStatuses = Record<string, IDeviceStatus[]>;

export interface IAddStreamAction {
  type: EVidyoStreamActions.ADD_STREAM;
  stream: IParticipantStream;
  // isPtz: boolean;
}
export interface IRemoveStreamAction {
  type: EVidyoStreamActions.REMOVE_STREAM;
  stream: IParticipantStream;
}
export interface IAddRemoteDeviceAction {
  type: EVidyoStreamActions.ADD_REMOTE_DEVICE;
  participantUserID: string;
  deviceStatus: IDeviceStatus;
}
export interface IRemoveRemoteDeviceAction {
  type: EVidyoStreamActions.REMOVE_REMOTE_DEVICE;
  participantUserID: string;
  deviceStatus: IDeviceStatus;
}
export interface IUpdateRemoteDeviceStateAction {
  type: EVidyoStreamActions.UPDATE_REMOTE_DEVICE_STATE;
  participantUserID: string;
  deviceStatus: IDeviceStatus;
}
export interface IPinStreamAction {
  type: EVidyoStreamActions.PIN_STREAM;
  pinnedStreamID: string;
}
export type TStreamsAction =
  | IAddRemoteDeviceAction
  | IAddStreamAction
  | IPinStreamAction
  | IRemoveRemoteDeviceAction
  | IRemoveStreamAction
  | IUpdateRemoteDeviceStateAction;
