import { useMemo } from "react";
import { Link } from "react-router-dom";

import {
  BigPopoverMenu,
  Block,
  Cell,
  Description,
  Flexbox,
  IconButton,
  P,
  PlainAction,
  Strong,
} from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  formatLongTime,
  formatShortWordDate,
  isToday,
  PATH_SESSION_EDIT,
  PATH_SESSION,
  PATH_USER,
  useREMSize,
  useWindowSize,
} from "packages/utils";

import type { UserType } from "packages/client/graphql_definitions.generated";

import { InProgress, IsRepeating, JoinButtons } from "packages/client/sessions/components";
import { UserSummary } from "packages/client/users/components";

import styles from "./SessionListItem.module.scss";

export interface PSessionListItem {
  canCancel?: boolean;
  canEdit?: boolean;
  canModerate?: boolean;
  endTimeUTC: string;
  isRepeating: boolean;
  meetingID: string;
  nextRecurringEnd?: string;
  nextRecurringStart?: string;
  roomKey: string;
  setToBeDeleted?: (meetingID: string) => void;
  startTimeUTC: string;
  title: string;
  user: {
    email?: string;
    firstName: UserType["firstName"];
    id: UserType["id"];
    lastName: UserType["lastName"];
    organisationName: string;
    profilePictureURL?: string;
  };
}

export function SessionListItem({
  canCancel,
  canEdit,
  canModerate,
  endTimeUTC,
  isRepeating,
  meetingID,
  nextRecurringEnd,
  nextRecurringStart,
  roomKey,
  setToBeDeleted,
  startTimeUTC,
  title,
  user,
}: PSessionListItem) {
  const { width } = useWindowSize();
  const remSize = useREMSize();
  const condensedWidth = 96 * remSize;
  const isCondensed = width < condensedWidth;

  const nextStart = useMemo(
    () => (nextRecurringStart ? nextRecurringStart : startTimeUTC),
    [nextRecurringStart, startTimeUTC],
  );

  return (
    <Cell as="li">
      <Block className={styles.meeting}>
        <div>
          <P>
            <Strong>
              <time className={styles.start} dateTime={nextStart} title="Start time">
                {formatLongTime(new Date(nextStart))}
              </time>
            </Strong>
            <InProgress endTime={nextRecurringEnd ?? endTimeUTC} startTime={nextRecurringStart ?? startTimeUTC} />
          </P>
          <P className={styles.date}>
            <time dateTime={nextStart} title="Date">
              {isToday(new Date(nextStart)) ? "Today" : formatShortWordDate(new Date(nextStart))}
            </time>
            {isRepeating && <IsRepeating />}
          </P>
        </div>
        <Flexbox flexDirectionColumn>
          <Flexbox alignItemsCenter justifyContentSpaceBetween>
            <div className={styles.overview}>
              <Flexbox alignItemsCenter>
                <Link className={styles.title} to={PATH_SESSION(meetingID)}>
                  {title}
                </Link>
              </Flexbox>
              <Description>ID {meetingID}</Description>
              <Link style={{ display: "block" }} to={PATH_USER(`${user.id}`)}>
                <UserSummary
                  email={user.email}
                  fullName={`${user.firstName} ${user.lastName}`}
                  organisationName={user.organisationName}
                  profilePictureURL={user.profilePictureURL}
                />
              </Link>
            </div>
            <Flexbox alignItemsCenter>
              {!isCondensed && (
                <JoinButtons
                  canModerate={canModerate}
                  endTime={endTimeUTC}
                  nextRecurringEnd={nextRecurringEnd}
                  nextRecurringStart={nextRecurringStart}
                  roomKey={roomKey}
                  startTime={startTimeUTC}
                />
              )}
              <BigPopoverMenu
                attachment={EAttachment.SOUTH_SOUTH_EAST}
                trigger={
                  <IconButton
                    as="div"
                    description="Options"
                    status={EStatus.NEUTRAL}
                    icon={EIcon.MOREVERTICAL}
                    appearance={EAppearance.GHOST}
                    dimension={EDimension.SMALL}
                  />
                }>
                <Flexbox flexDirectionColumn>
                  <PlainAction as={Link} to={PATH_SESSION(meetingID)}>
                    View details
                  </PlainAction>
                  {canEdit && (
                    <PlainAction as={Link} to={PATH_SESSION_EDIT(meetingID)}>
                      Edit
                    </PlainAction>
                  )}
                  {canCancel && (
                    <PlainAction as="button" onClick={() => setToBeDeleted(meetingID)} status={EStatus.DANGER}>
                      Delete
                    </PlainAction>
                  )}
                </Flexbox>
              </BigPopoverMenu>
            </Flexbox>
          </Flexbox>
          {isCondensed && (
            <JoinButtons
              canModerate={canModerate}
              endTime={endTimeUTC}
              nextRecurringEnd={nextRecurringEnd}
              nextRecurringStart={nextRecurringStart}
              roomKey={roomKey}
              startTime={startTimeUTC}
            />
          )}
        </Flexbox>
      </Block>
    </Cell>
  );
}
