export enum EAttachment {
  NORTH = "north",
  NORTH_NORTH_EAST = "north-north-east",
  NORTH_EAST = "north-east",
  EAST_NORTH_EAST = "east-north-east",
  EAST = "east",
  EAST_SOUTH_EAST = "east-south-east",
  SOUTH_EAST = "south-east",
  SOUTH_SOUTH_EAST = "south-south-east",
  SOUTH = "south",
  SOUTH_SOUTH_WEST = "south-south-west",
  SOUTH_WEST = "south-west",
  WEST_SOUTH_WEST = "west-south-west",
  WEST = "west",
  WEST_NORTH_WEST = "west-north-west",
  NORTH_WEST = "north-west",
  NORTH_NORTH_WEST = "north-north-west",
}
