import { EDimension } from "packages/utils";

export interface PuseDimensionStyle {
  extraLarge?: string;
  extraSmall?: string;
  large?: string;
  medium?: string;
  small?: string;
}

export function useDimensionStyle({ extraLarge, extraSmall, large, medium, small }: PuseDimensionStyle) {
  return {
    [EDimension.EXTRA_LARGE]: extraLarge ?? "",
    [EDimension.EXTRA_SMALL]: extraSmall ?? "",
    [EDimension.LARGE]: large ?? "",
    [EDimension.MEDIUM]: medium ?? "",
    [EDimension.SMALL]: small ?? "",
  };
}
