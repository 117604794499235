import { Block, Cell, Grid, H2 } from "packages/catalog";

import { EParticipants } from "packages/client/sessions/redux/types";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { changeParticipants } from "packages/client/sessions/redux/slice";

import { EditGuests, EditRoomSystems, EditUsers } from "packages/client/sessions/components";

import styles from "./EditParticipants.module.scss";

export function EditParticipants() {
  const dispatch = useTypedDispatch();
  const { users, moderators, lecturers, guests, roomSystems, isLectureMode } = useTypedSelector(({ sessionForm }) => ({
    guests: sessionForm.guests,
    isLectureMode: sessionForm.isLectureMode,
    lecturers: sessionForm.lecturers,
    moderators: sessionForm.moderators,
    roomSystems: sessionForm.roomSystems,
    users: sessionForm.users,
  }));

  return (
    <Grid as={Block} className={styles.sidebar} noColGap>
      <Cell>
        <H2>Participants</H2>
      </Cell>
      <Cell>
        <EditUsers
          ignored={[...moderators, ...lecturers]}
          label="ClassView members"
          onSelect={selected => dispatch(changeParticipants({ items: selected, collection: EParticipants.Users }))}
          preSelected={users}
        />
      </Cell>
      <Cell>
        <EditRoomSystems
          label="Room systems"
          onSelect={selected =>
            dispatch(changeParticipants({ items: selected, collection: EParticipants.RoomSystems }))
          }
          preSelected={roomSystems}
        />
      </Cell>
      <Cell>
        <EditUsers
          description="Moderators can edit the session and mute/remove users in-session without participating"
          ignored={[...users, ...lecturers]}
          label="Session moderators"
          onSelect={selected => dispatch(changeParticipants({ items: selected, collection: EParticipants.Moderators }))}
          preSelected={moderators}
        />
      </Cell>
      <Cell className={styles.cell}>
        <EditGuests
          description="Guests receive an invitation via email"
          label="Guests outside your organisation"
          onSelect={selected =>
            dispatch(
              changeParticipants({
                items: selected.map(s => s.value),
                collection: EParticipants.Guests,
              }),
            )
          }
          placeholder="e.g. janedoe@email.com"
          preSelected={guests}
        />
      </Cell>
      {isLectureMode && (
        <Cell>
          <EditUsers
            description="Lecturers can mute/unmute participants insession"
            ignored={[...users, ...moderators]}
            label="Lecturers"
            onSelect={selected =>
              dispatch(changeParticipants({ items: selected, collection: EParticipants.Lecturers }))
            }
            preSelected={lecturers}
          />
        </Cell>
      )}
    </Grid>
  );
}
