import { ReactNode, useMemo } from "react";

import { Flexbox, P } from "packages/catalog";

import styles from "./Loader.scss";

export interface PLoader {
  hideAnimation?: boolean;
  message?: string | ReactNode;
  notFullHeight?: boolean;
}

export function Loader({ hideAnimation, message, notFullHeight }: PLoader = { hideAnimation: false }) {
  const classes = useMemo(() => [!notFullHeight && styles.loader].filter(e => e).join(" "), [notFullHeight]);

  return (
    <Flexbox className={classes} flexDirectionColumn justifyContentCenter>
      {!hideAnimation && (
        <Flexbox className={styles.bouncy} hasDoubleGap hasMargin justifyContentCenter>
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
        </Flexbox>
      )}
      <P center className={styles.heading}>
        {message || "Loading..."}
      </P>
    </Flexbox>
  );
}
