import { ChangeEvent, useCallback, useEffect, useState } from "react";

import {
  Cell,
  ConfirmActionModal,
  Feedback,
  Grid,
  Input,
  Label,
  Loader,
  Pagination,
  Table,
  TRTDTemp,
} from "packages/catalog";
import { EStatus, scrollToContentTop } from "packages/utils";

import { useGetAllUsersQuery, useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";
import { approveUser, deleteUser, demoteUser, promoteUser } from "packages/client/users/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SuperAdminUserRow } from "packages/client/users/components";

const COLUMNS = ["ID", "User", "Status", "Organisation"];
const COLUMN_WIDTHS = "4rem 1fr 0.75fr 1fr 3rem";

export function SuperAdminUsers() {
  const [actionID, setActionID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchString, setDebounceSearchString] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    data: allUsersData,
    loading,
    refetch: refetchAllUsers,
  } = useGetAllUsersQuery({
    variables: { page: currentPage, search: debouncedSearchString },
  });
  const { data: ownUserData } = useGetEssentialOwnUserQuery();

  const handleApproveUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      approveUser(userID)
        .then(() => {
          refetchAllUsers();
          setSuccessMessage("User approved.");
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetchAllUsers],
  );

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value);
  }, []);

  const handleDeleteUser = useCallback(() => {
    setSuccessMessage("");
    setErrorMessage("");

    scrollToContentTop();
    setIsDeleteModalOpen(false);

    deleteUser(actionID)
      .then(() => {
        refetchAllUsers();
        setSuccessMessage("User deleted.");
      })
      .catch(reason => {
        setErrorMessage(reason.message);
      });

    setActionID("");
  }, [actionID, refetchAllUsers]);

  const handleDemoteUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      scrollToContentTop();

      demoteUser(userID)
        .then(() => {
          refetchAllUsers();
          setSuccessMessage("User removed from administrators.");
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetchAllUsers],
  );

  const handlePromoteUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      scrollToContentTop();

      promoteUser(userID)
        .then(() => {
          setSuccessMessage("User added to administrators");
          refetchAllUsers();
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetchAllUsers],
  );

  const prepareDeleteUser = useCallback((userID: string) => {
    setActionID(userID);
    setIsDeleteModalOpen(true);
  }, []);

  useEffect(() => {
    let cancelled = false;

    window.setTimeout(() => {
      if (!cancelled) setDebounceSearchString(searchString);
    }, 500);

    return () => {
      cancelled = true;
    };
  }, [searchString]);

  return (
    <>
      <RoutingChangeHandler message="The users page has loaded." pageTitle="Users" />
      <Grid noColGap>
        {errorMessage.length ? (
          <Cell>
            <Feedback status={EStatus.DANGER}>{errorMessage}</Feedback>
          </Cell>
        ) : null}
        {successMessage.length ? (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>{successMessage}</Feedback>
          </Cell>
        ) : null}
        <Cell>
          <Label htmlFor="search" isVisuallyHidden text="Search users" />
          <Input id="search" name="search" onChange={handleChangeSearch} type="search" value={searchString} />
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing all users on ClassView"
            hasMenuColumn>
            {loading && (
              <TRTDTemp>
                <Loader notFullHeight />
              </TRTDTemp>
            )}
            <>
              {allUsersData?.allUsers?.objects?.map(user => (
                <SuperAdminUserRow
                  columns={COLUMNS}
                  columnWidths={COLUMN_WIDTHS}
                  email={user.email}
                  handleApproveUser={handleApproveUser}
                  handleDemoteUser={handleDemoteUser}
                  handlePromoteUser={handlePromoteUser}
                  isAdmin={user.isAdmin}
                  isApproved={user.isActive}
                  isEmailAuthenticated={user.emailAuthenticated}
                  isSuperAdmin={user.isSuperuser}
                  key={user.id}
                  organisationID={user.organisation?.id}
                  organisationName={user.organisation?.name}
                  prepareDeleteUser={prepareDeleteUser}
                  userFullname={`${user.firstName} ${user.lastName}`}
                  userID={user.id}
                  userOwnID={ownUserData?.user.id}
                />
              ))}
            </>
          </Table>
        </Cell>
        {allUsersData?.allUsers?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={allUsersData?.allUsers?.hasNext}
              hasPrevious={allUsersData?.allUsers?.hasPrev}
              page={allUsersData?.allUsers?.page}
              pages={allUsersData?.allUsers?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="user"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteUser}
        status={EStatus.DANGER}
      />
    </>
  );
}
