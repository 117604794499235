import { ChangeEvent, useEffect, useState, useCallback } from "react";

import {
  Cell,
  ConfirmActionModal,
  Feedback,
  Flexbox,
  Grid,
  H1,
  Input,
  IllustratedCTA,
  Label,
  Loader,
  Pagination,
} from "packages/catalog";
import { EIllustration, EStatus, scrollToContentTop } from "packages/utils";

import { useGetAllRecordingsQuery } from "packages/client/recordings/graphql/recordings.generated";
import { useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";
import { deleteRecording } from "packages/client/recordings/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { RecordingListItem } from "packages/client/recordings/components";

export function Recordings() {
  const [actionID, setActionID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchString, setDebounceSearchString] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    data: allRecordingsData,
    loading,
    error,
    refetch,
  } = useGetAllRecordingsQuery({
    variables: { page: currentPage, search: debouncedSearchString },
  });
  const { data: userOwnData } = useGetEssentialOwnUserQuery();

  const handleDeleteRecording = useCallback(() => {
    setSuccessMessage("");
    setErrorMessage("");

    scrollToContentTop();
    setIsDeleteModalOpen(false);

    deleteRecording(actionID)
      .then(() => {
        refetch();
        setSuccessMessage("Recording deleted.");
      })
      .catch(reason => {
        setErrorMessage(reason.message);
      });
  }, [actionID, refetch]);

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value);
  }, []);

  const prepareDeleteRecording = useCallback((recordingID: string) => {
    setActionID(recordingID);
    setIsDeleteModalOpen(true);
  }, []);

  useEffect(() => {
    let cancelled = false;

    window.setTimeout(() => {
      if (!cancelled) setDebounceSearchString(searchString);
    }, 500);

    return () => {
      cancelled = true;
    };
  }, [searchString]);

  return (
    <>
      <RoutingChangeHandler message="The recordings page has loaded." pageTitle="Recordings" />
      <Grid>
        {errorMessage.length ? (
          <Cell>
            <Feedback status={EStatus.DANGER}>{errorMessage}</Feedback>
          </Cell>
        ) : null}
        {successMessage.length ? (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>{successMessage}</Feedback>
          </Cell>
        ) : null}
        <Cell>
          <Flexbox alignItemsCenter justifyContentSpaceBetween>
            <H1>Recordings</H1>
            <Label htmlFor="search" isVisuallyHidden text="Search recordings" />
            <Input id="search" name="search" onChange={handleChangeSearch} type="search" value={searchString} />
          </Flexbox>
        </Cell>
        <Cell>
          <Grid as="ol">
            {loading && (
              <Cell center>
                <Loader />
              </Cell>
            )}
            {error && (
              <Cell>
                <Feedback status={EStatus.DANGER}>Unable to get recordings.</Feedback>
              </Cell>
            )}
            <>
              {allRecordingsData?.allRecordings?.objects?.length
                ? allRecordingsData?.allRecordings?.objects?.map(recording => (
                    <RecordingListItem
                      duration={`${recording.duration}`}
                      id={recording?.id}
                      isOwner={userOwnData?.user?.id === recording?.owner?.id}
                      key={recording.id}
                      name={recording?.name}
                      ownerEmail={recording?.owner?.email}
                      ownerFullName={`${recording?.owner?.firstName} ${recording?.owner?.lastName}`}
                      ownerOrganisationName={recording?.owner?.organisation.name}
                      prepareDeleteRecording={prepareDeleteRecording}
                      roomType={recording?.room?.type}
                      recordingID={recording?.vidyoreplayId}
                      startTime={recording?.startTime}
                    />
                  ))
                : !debouncedSearchString.length &&
                  !loading && (
                    <Cell center>
                      <IllustratedCTA illustration={EIllustration.VIDEO_FILES}>
                        {"You don't have any recordings yet. When you do, they'll appear here."}
                      </IllustratedCTA>
                    </Cell>
                  )}
            </>
            {debouncedSearchString.length && !allRecordingsData?.allRecordings?.objects.length && !loading ? (
              <Cell center fullHeight>
                <IllustratedCTA illustration={EIllustration.VIDEO_FILES}>No recordings found.</IllustratedCTA>
              </Cell>
            ) : null}
          </Grid>
        </Cell>
        {allRecordingsData?.allRecordings?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={allRecordingsData?.allRecordings?.hasNext}
              hasPrevious={allRecordingsData?.allRecordings?.hasPrev}
              page={allRecordingsData?.allRecordings?.page}
              pages={allRecordingsData?.allRecordings?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="recording"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteRecording}
        status={EStatus.DANGER}
      />
    </>
  );
}
