import React from "react";
import { v4 as uuid } from "uuid";

import { Alerts, Dropdown, Field, Input, InputOption } from "packages/catalog";
import { debounce, EStatus } from "packages/utils";

import type {
  ICheckboxProps,
  IFormCheckboxProps,
  IFormElementState,
  IFormInputProps,
  IFormProps,
  IFormSelectProps,
  IFormState,
  IInputProps,
  InputType,
  ISelectProps,
} from "packages/client/onboarding/interfaces";

const FormContext = React.createContext({
  cache: null,
  handleChange: null,
  handleSubmit: null,
  initializeCheckbox: null,
  initializeInput: null,
  initializeSelect: null,
});

export class FormContainer extends React.Component<IFormProps, IFormState> {
  public static Input(props: IInputProps) {
    return (
      <FormContext.Consumer>
        {({ initializeInput, handleChange, handleSubmit, cache }) => (
          <FormInput
            {...props}
            refCallback={props.refCallback}
            cache={cache}
            initializeElement={(id: string) =>
              initializeInput(id, props.rules, props.type || "text", props.value || "", props.isRequired)
            }
            disabled={props.disabled}
            value={props.value || ""}
            handleSubmit={handleSubmit}
            handleChange={(id: string, currentValue: string) => {
              handleChange(id, currentValue);
              if (props.onChange) props.onChange(currentValue);
            }}
          />
        )}
      </FormContext.Consumer>
    );
  }
  public static Select(props: ISelectProps) {
    return (
      <FormContext.Consumer>
        {({ initializeSelect, handleChange, handleSubmit, cache }) => (
          <FormSelect
            {...props}
            cache={cache}
            initializeElement={(id: string) =>
              initializeSelect(
                id,
                props.value || props.defaultValue || props.options[0],
                props.defaultValue,
                props.errorMessage,
              )
            }
            value={props.value}
            defaultValue={props.defaultValue}
            handleSubmit={handleSubmit}
            handleChange={(id: string, currentValue: string) => {
              handleChange(id, currentValue);
              if (props.onChange) props.onChange(currentValue);
            }}
          />
        )}
      </FormContext.Consumer>
    );
  }
  public static Checkbox(props: ICheckboxProps) {
    return (
      <FormContext.Consumer>
        {({ initializeCheckbox, handleChange, cache }) => (
          <FormCheckbox
            {...props}
            cache={cache}
            required={props.required}
            initializeElement={(id: string) =>
              initializeCheckbox(id, props.required, props.checked ? "checked" : "unchecked", props.errorMessage)
            }
            handleChange={(id: string, currentValue: string) => {
              handleChange(id, currentValue);
              if (props.onChange) props.onChange(currentValue === "checked" ? true : false);
            }}
          />
        )}
      </FormContext.Consumer>
    );
  }
  constructor(props: IFormProps) {
    super(props);
    this.state = {};
    this._handleInputChange = this._handleInputChange.bind(this);
    this._initializeCheckbox = this._initializeCheckbox.bind(this);
    this._initializeInput = this._initializeInput.bind(this);
    this._initializeSelect = this._initializeSelect.bind(this);
    this._submitForm = debounce(
      this._submitForm,
      props.debounceValidation ? (props.debounceValidation === true ? 500 : props.debounceValidation) : 0,
    ).bind(this);
    this._validateInput = this._validateInput.bind(this);
  }
  public componentDidMount() {
    this._submitForm(null, false, false);
  }
  public componentDidUpdate() {
    if (this.props.checkingEmpty) {
      this._submitForm();
      this.props.emptyChecked;
    }
  }
  public render() {
    return (
      <FormContext.Provider
        value={{
          cache: this.state,
          handleChange: this._handleInputChange,
          handleSubmit: this._submitForm,
          initializeCheckbox: this._initializeCheckbox,
          initializeInput: this._initializeInput,
          initializeSelect: this._initializeSelect,
        }}>
        <form
          className={this.props.className}
          onSubmit={e => {
            e.preventDefault();
            this._submitForm(e);
          }}>
          {this.props.children}
        </form>
      </FormContext.Provider>
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _initializeInput(id: string, rules?: any, type?: InputType, value?: string, isRequired?: boolean) {
    this.setState({
      [id]: {
        errors: [],
        isRequired,
        isValid: null,
        rules: rules || null,
        type: type || null,
        value,
      },
    });
  }
  private _initializeSelect(id: string, value?: string, defaultValue?: string, errorMessage?: string) {
    this.setState({
      [id]: {
        defaultValue,
        errorMessage,
        errors: [],
        isValid: null,
        type: "select",
        value,
      },
    });
  }
  private _initializeCheckbox(id: string, isRequired = false, value: "checked" | "unchecked", errorMessage?: string) {
    this.setState({
      [id]: {
        errorMessage,
        errors: [],
        isRequired,
        isValid: null,
        type: "checkbox",
        value,
      },
    });
  }
  private _handleInputChange(id: string, value: string) {
    const { isRequired } = this.state[id];
    const errors = this._validateInput(id, value);
    this.setState({
      [id]: {
        ...this.state[id],
        errors,
        isValid: !isRequired && !errors.length,
        value,
      },
    });
    this._submitForm(null, false, false);
  }
  private _validateInput(id: string, currentValue?: string) {
    const { defaultValue, errorMessage, isRequired, rules, type } = this.state[id];
    const errors: string[] = [];
    const value = typeof currentValue !== "undefined" ? currentValue : this.state[id].value;
    // If empty input value and Form.Input tag has explicitly set isRequired to false,
    // return with an empty errors array
    if (!value.length && !isRequired) return errors;
    switch (type) {
      case "select":
        if (defaultValue && defaultValue === value) {
          if (errorMessage) {
            errors.push(errorMessage);
          } else {
            errors.push("Select at least one option.");
          }
        }
        break;
      case "checkbox":
        if (isRequired && value !== "checked") {
          if (errorMessage) {
            errors.push(errorMessage);
          } else {
            errors.push("This field is required.");
          }
        }
        break;
      default:
        if (rules) {
          Object.keys(rules).forEach(rule => {
            switch (rule) {
              case "min":
                if (type && type === "number") {
                  if (value === "" || parseInt(value, 10) < +rules[rule].value) {
                    rules[rule].message;
                    if (rules[rule].message) {
                      errors.push(rules[rule].message);
                    } else {
                      errors.push("Min length error.");
                    }
                  }
                } else {
                  if (value.length < +rules[rule].value) {
                    rules[rule].message;
                    if (rules[rule].message) {
                      errors.push(rules[rule].message);
                    } else {
                      errors.push("Min length error.");
                    }
                  }
                }
                break;
              case "max":
                if (type && type === "number") {
                  if (parseInt(value, 10) > +rules[rule].value) {
                    rules[rule].message ? errors.push(rules[rule].message) : errors.push("Max length error.");
                  }
                } else {
                  if (value.length > +rules[rule].value) {
                    rules[rule].message ? errors.push(rules[rule].message) : errors.push("Max length error.");
                  }
                }
                break;
              case "regex":
                if (!new RegExp(rules[rule].value).exec(value)) {
                  rules[rule].message ? errors.push(rules[rule].message) : errors.push("Invalid format.");
                }
                break;
              default:
                break;
            }
          });
        }
        break;
    }
    return errors;
  }
  private _submitForm(e?: React.FormEvent<HTMLFormElement>, forceValidate = true, storeErrors = true) {
    // storeErrors param is used by componentDidMount to silently validate
    // the form on load but hide errors on the initial load
    // if (e) e.preventDefault();
    let errorCount = 0;
    Object.keys(this.state).forEach(field => {
      const { defaultValue, isRequired, isValid, type, value } = this.state[field];
      const errors = this._validateInput(field);
      if (errors.length) {
        switch (type) {
          case "select":
            if (forceValidate) {
              this.setState({
                [field]: {
                  ...this.state[field],
                  errors: storeErrors ? errors : this.state[field].errors,
                  isValid: defaultValue && defaultValue === value ? false : isValid,
                },
              });
            }
            break;
          case "checkbox":
            if (forceValidate) {
              this.setState({
                [field]: {
                  ...this.state[field],
                  errors: storeErrors ? errors : this.state[field].errors,
                  isValid: isRequired && value !== "checked" ? false : isValid,
                },
              });
            }
            break;
          default:
            this.setState({
              [field]: {
                ...this.state[field],
                errors: storeErrors ? errors : this.state[field].errors,
                isValid: forceValidate ? false : value === "" ? isValid : false,
              },
            });
            break;
        }
        errorCount++;
      }
    });
    if (this.props.onValidation) this.props.onValidation(errorCount === 0);
    if (errorCount === 0 && forceValidate && this.props.onSubmit) this.props.onSubmit(e);
  }
}

class FormInput extends React.Component<IFormInputProps, IFormElementState> {
  constructor(props: IFormInputProps) {
    super(props);
    this.state = {
      id: `${this.props.type || "text"}_${uuid()}`,
    };
  }
  public componentDidMount() {
    this.props.initializeElement(this.state.id, this.props.value || "");
  }
  public componentDidUpdate(prevProps: IFormInputProps) {
    if (this.props.value !== prevProps.value) {
      this.props.handleChange(this.state.id, this.props.value);
    }
  }
  public render() {
    const {
      advisory,
      autoComplete,
      required,
      cache,
      handleChange,
      handleSubmit,
      label,
      max,
      min,
      name,
      refCallback,
      type,
      value,
    } = this.props;
    const { id } = this.state;
    return (
      <>
        <Field description={advisory} label={label}>
          <Input
            autoComplete={autoComplete}
            id={label}
            max={max}
            min={min}
            name={name}
            onChange={e => handleChange(id, e.currentTarget.value)}
            onKeyDown={e => e.keyCode === 13 && handleSubmit(e)}
            ref={refCallback || undefined}
            required={required}
            type={type || "text"}
            value={value || (cache[id] ? cache[id].value : "")}
          />
        </Field>
        <FormError cache={cache} id={id} />
      </>
    );
  }
}

export class FormSelect extends React.Component<IFormSelectProps, IFormElementState> {
  constructor(props: IFormSelectProps) {
    super(props);
    this.state = {
      id: `select_${uuid()}`,
    };
  }
  public componentDidMount() {
    this.props.initializeElement(this.state.id);
  }
  public render() {
    const { advisory, cache, defaultValue, handleChange, handleSubmit, label, name, options, required, value } =
      this.props;
    const { id } = this.state;
    return (
      <>
        <Field description={advisory} label={label}>
          <Dropdown
            defaultValue={defaultValue}
            name={name}
            onChange={e => handleChange(id, e.currentTarget.value)}
            onKeyDown={e => e.keyCode === 13 && handleSubmit(e)}
            options={options}
            required={required}
            value={value ?? cache[id]?.value ?? defaultValue}
          />
        </Field>
        <FormError cache={cache} id={id} />
      </>
    );
  }
}

export class FormCheckbox extends React.Component<IFormCheckboxProps, IFormElementState> {
  constructor(props: IFormCheckboxProps) {
    super(props);
    this.state = {
      id: `checkbox_${uuid()}`,
    };
  }
  public componentDidMount() {
    this.props.initializeElement(this.state.id);
  }
  public render() {
    const { handleChange, cache, checked, label } = this.props;
    const { id } = this.state;
    return (
      <>
        <InputOption label={label}>
          <Input
            checked={checked || (cache[id] && cache[id].value === "checked") || false}
            onChange={e => handleChange(id, e.currentTarget.checked ? "checked" : "unchecked")}
            type="checkbox"
          />
        </InputOption>
        <FormError cache={cache} id={id} />
      </>
    );
  }
}

function FormError({ cache, id }: { cache: IFormState; id: string }) {
  return cache[id] && !cache[id].isValid ? (
    <Alerts alerts={cache[id].errors.map(err => ({ message: err, status: EStatus.DANGER }))} />
  ) : null;
}
