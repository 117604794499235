import { useState, useEffect } from "react";

import { Dialog, Emoji, Flexbox, P, TextButton } from "packages/catalog";
import { EAppearance, EStatus } from "packages/utils";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function NewVersionModal() {
  const [shouldShow, setShouldShow] = useState(false);
  const [forceCancelled, setForceCancelled] = useState(false);

  useEffect(() => {
    async function consumeUpdater() {
      for await (const isUpdateThere of window.isNewBrowserVersionAvailable()) {
        setShouldShow(isUpdateThere);
      }
    }
    if (window.isNewBrowserVersionAvailable) consumeUpdater();
  }, []);

  function onUpdate() {
    window.location.reload();
  }

  function onCancel() {
    setForceCancelled(true);
  }

  if (!shouldShow || forceCancelled) return null;

  return (
    <Dialog
      h1={
        <>
          New version available <Emoji symbol="🎉" />
        </>
      }
      isOpen={shouldShow}>
      <RoutingChangeHandler message="The new version modal has loaded." />
      <P center hasMargin>
        ClassView has been updated.
      </P>
      <Flexbox justifyContentCenter>
        <TextButton appearance={EAppearance.GHOST} onClick={onCancel}>
          Reload later
        </TextButton>
        <TextButton onClick={onUpdate} status={EStatus.ACCENT}>
          Update now
        </TextButton>
      </Flexbox>
    </Dialog>
  );
}
