import {
  FocusEvent,
  InputHTMLAttributes,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Input } from "packages/catalog";

import { ColorPicker } from "./ColorPicker";

import styles from "./InputColor.module.scss";

export interface PInputColor extends InputHTMLAttributes<HTMLInputElement> {
  color: string;
  onColorChange: (color: string) => void;
  openColorPicker: boolean;
}

export function InputColor({ color, onColorChange, openColorPicker, ...rest }: PInputColor) {
  const [pickerOpen, setPickerOpen] = useState(false);

  const details = useRef<HTMLDetailsElement>(null);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.currentTarget.parentElement.click();
      if (rest.onClick) rest.onClick(e);
    },
    [rest],
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (rest.onFocus) rest.onFocus(e);
    },
    [rest],
  );

  const handleToggle = useCallback((e: SyntheticEvent<HTMLDetailsElement>) => {
    setPickerOpen(e.currentTarget.open);
  }, []);

  useEffect(() => {
    details.current.open = openColorPicker;
    setPickerOpen(openColorPicker);
  }, [openColorPicker]);

  return (
    <details className={styles.details} ref={details} onToggle={handleToggle}>
      <summary className={styles.summary}>
        <Input
          onClick={handleClick}
          onFocus={handleFocus}
          readOnly
          tabIndex={-1}
          type="color"
          value={color}
          {...rest}
        />
      </summary>
      <ColorPicker color={color} pickerOpen={pickerOpen} setColor={onColorChange} />
    </details>
  );
}
