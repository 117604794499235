import { isLinuxElectron } from "packages/utils";

const useNativeVersion = IS_ELECTRON && !isLinuxElectron;

export async function getVidyoSDK() {
  const p = useNativeVersion ? import("./electronSDKLoader") : import("./nativeWebRTCLoader");

  const { default: sdkPromise } = await p;

  return sdkPromise();
}
