import type { IDjangoSession } from "packages/client/sessions/django/types";
import type { IMutableSession } from "packages/client/sessions/graphql/types";

export function toDjangoSession(data: Partial<IMutableSession>, cleanUndefinedFields = false): IDjangoSession {
  const serialised = {
    ...(data?.id ? { id: parseInt(data?.id, 10) } : {}),
    application_preferred: data?.applicationPreferred ?? false,
    block_external_guests: data?.blockExternalGuests,
    description: data?.description,
    end_time: data?.endTime?.toISOString(),
    guests: data?.guests,
    is_lecture_mode: data?.isLectureMode,
    is_private: data?.isPrivate,
    is_recorded: data?.isRecorded,
    is_webcast_mode: data?.isWebcastMode,
    lecturers: data?.lecturers?.map(lecturer => parseInt(lecturer.id, 10)),
    local_timezone: data.localTimezone,
    moderators: data?.moderators?.map(moderator => parseInt(moderator.id, 10)),
    pin: data?.room?.pin,
    pin_protected: !!data?.room?.pin,
    recurring_meeting:
      data?.recurringInterval !== null && typeof data?.recurringInterval !== "undefined"
        ? {
            end_date: data?.recurringEndDate ? new Date(data?.recurringEndDate).toISOString() : null,
            every_x_months: data?.recurringEveryXMonths,
            every_x_weeks: data?.recurringEveryXWeeks,
            interval: data?.recurringInterval,
            on_days: data?.recurringOnDays,
            repetitions: data?.recurringRepetitions,
            weekdays_only: data?.recurringWeekdaysOnly,
            weekly_occurrence: data?.recurringOnXWeekOfMonth,
          }
        : null,
    room_systems: data?.roomSystems?.map(roomSystem => ({
      room_system: parseInt(roomSystem?.roomSystem?.id, 10),
      dial_out: roomSystem?.dialOut,
    })),
    start_time: data?.startTime?.toISOString(),
    subject: data?.subject,
    users: data?.users?.map(user => parseInt(user.id, 10)),
    waiting_room_enabled: data?.waitingRoomEnabled,
  };

  if (!cleanUndefinedFields) return serialised;

  return Object.fromEntries(Object.entries(serialised).filter(([, v]) => v !== undefined));
}
