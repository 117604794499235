import type { ComponentType } from "react";
import { Link } from "react-router-dom";

import { Icon, Strong } from "packages/catalog";
import { EIcon } from "packages/utils";

import styles from "./SidebarLink.module.scss";

export interface PSidebarLink {
  linkAs?: string | ComponentType;
  text: string;
  to: string;
}

export function SidebarLink({ text, to, linkAs }: PSidebarLink) {
  const LinkAs = linkAs ?? Link;

  return (
    <li className={styles.item}>
      <LinkAs className={styles.link} to={to}>
        <Strong>{text}</Strong>
        <Icon className={styles.icon} icon={EIcon.CHEVRONRIGHT} />
      </LinkAs>
    </li>
  );
}
