import type { IBlockCell } from "../interfaces";

const OPTIMAL_RATIO = 16 / 9;

function linear(a: number, b = 0, o = OPTIMAL_RATIO) {
  return function (x: number) {
    return a * (x - o) + b;
  };
}

function polyTwo(a: number, b = 0, o = OPTIMAL_RATIO) {
  return function (x: number) {
    return a * (x - o) ** 2 + b;
  };
}

/* R is the ratio from width/height of a rectangle
  it is 0 if width/height == 16/9
  But
   when width > height the value can become really far from 16/9 (~= 1.78)
    for example 3:1 ratio is 3 which means a 1.22 difference to 16/9
    but 1/10 is 0.1 which is much closer to 16/9 only being different by 1.68
    but a rectangle with a ratio of 3:1 is not great but usable
    on the other hand a screen with a ratio of 1/10 is terrible
    and should not be used

    As "left" of 16/9 can never be more than 1.78 difference to 16/9
    we use a polynomial function to increase each "incremental" difference an exponential increase of R (from 0.2 to 0.1 there's a massive difference in use)

    and we use a linear function to increase R when over 16/9 (between 2 and 2.1) there's a minimal difference to use

  */
const leftR = polyTwo(5);
const rightR = linear(1.5);

/** R is lower when closer to 16/9 and favorise width / height > 16/9 */
export function getR(pixelWidth: number, pixelHeight: number) {
  if (pixelWidth <= 0 || pixelHeight <= 0) return Infinity;
  const x = pixelWidth / pixelHeight;
  return x < OPTIMAL_RATIO ? leftR(x) : rightR(x);
}

/**
 * @example
 * sliceEqualSizeExact(201, 2) => [101, 100]
 */
export function sliceEqualSizeExact(total: number, sliceInto: number): number[] {
  if (total < 0 || sliceInto < 1 || isNaN(total) || isNaN(sliceInto)) return [];
  if (sliceInto < 2) return [total];
  const integralPart = Math.floor(total / sliceInto);
  const restOnTotal = total - integralPart * sliceInto;
  const allBasic: number[] = new Array(sliceInto).fill(integralPart);
  allBasic[0] += restOnTotal;
  return [...allBasic];
}

export function sumBefore(acc: number[], index: number): number {
  return acc.reduce((acc, e, i) => (i < index ? (acc += e) : acc), 0);
}

export function sumFromTo(sizes: number[], begin: number, end: number) {
  return sizes.slice(begin, end).reduce((acc, e) => (acc += e), 0);
}

export function averageROnArray(arr: { width: number; height: number }[]) {
  const allRs = arr.map(b => getR(b.width, b.height));
  return allRs.reduce((acc, e) => (acc += e ** 2), 0) / allRs.length;
}

export function uniqBlocks<T extends IBlockCell>(arr1: T[] = [], arr2: T[] = []) {
  const m = new Map<string, T>();
  arr1.forEach(b => m.set(b.id, b));
  arr2.forEach(b => m.set(b.id, b));
  return Array.from(m.values());
}
