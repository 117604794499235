import { Description, Flexbox, P, StatusBadge, Strong } from "packages/catalog";
import { EDimension, EStatus } from "packages/utils";

import { EConnectionStatus } from "packages/client/organisations/enums";

import { useGetEssentialOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";

import { OrganisationLogo } from "packages/client/organisations/components";

export interface POrganisationSummary {
  connectionStatus?: EConnectionStatus;
  organisationID: string;
  pendingOrganisationName?: string;
  pendingOrganisationParentOrganisationName?: string;
}

export function OrganisationSummary({
  connectionStatus,
  organisationID,
  pendingOrganisationName,
  pendingOrganisationParentOrganisationName,
}: POrganisationSummary) {
  const { data } = useGetEssentialOrganisationQuery({
    variables: {
      organisationID: parseInt(organisationID),
    },
  });

  return (
    <Flexbox alignItemsCenter>
      <OrganisationLogo
        dimension={EDimension.SMALL}
        organisationID={organisationID}
        pendingOrganisationName={pendingOrganisationName}
      />
      <div>
        <P>
          <Strong>{pendingOrganisationName ?? data?.organisation?.name}</Strong>&nbsp;
          {connectionStatus === EConnectionStatus.CONNECTED && (
            <StatusBadge status={EStatus.SUCCESS}>Connected</StatusBadge>
          )}
          {connectionStatus === EConnectionStatus.INCOMING && (
            <StatusBadge status={EStatus.NEUTRAL}>Connection pending</StatusBadge>
          )}
          {connectionStatus === EConnectionStatus.OUTGOING && (
            <StatusBadge status={EStatus.NEUTRAL}>Connection pending</StatusBadge>
          )}
        </P>
        {(pendingOrganisationParentOrganisationName || data?.organisation?.parentOrganisation) && (
          <>
            <Description>
              {pendingOrganisationParentOrganisationName ?? data?.organisation?.parentOrganisation?.name}
            </Description>
          </>
        )}
      </div>
    </Flexbox>
  );
}
