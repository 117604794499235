import { ReactNode, useMemo } from "react";

import { IllustratedCTA } from "packages/catalog";
import { EIllustration, PATH_SESSIONS } from "packages/utils";

export interface PRequireAuthorisation {
  children: ReactNode;
  conditions?: boolean[];
}

export function RequireAuthorisation({ children, conditions = [] }: PRequireAuthorisation) {
  const passesConditions = useMemo(() => conditions.every(condition => !!condition), [conditions]);

  return passesConditions ? (
    <>{children}</>
  ) : (
    <IllustratedCTA cta="Return home" illustration={EIllustration.SCREEN_INCALL_WARNING} to={PATH_SESSIONS}>
      {"Sorry, you're not allowed to view this page."}
    </IllustratedCTA>
  );
}
