import { ReactNode, useMemo } from "react";

import { Description, Input } from "packages/catalog";

import styles from "./InputOption.module.scss";

export interface PInputOption {
  children: ReactChild<typeof Input>;
  description?: string;
  label: string | ReactNode;
}

export function InputOption({ children, description, label }: PInputOption) {
  const optionClasses = useMemo(
    () => [styles.option, children.props.required && styles.required].filter(e => e).join(" "),
    [children.props.required],
  );

  return (
    <label className={optionClasses}>
      {children}
      <div className={styles.label}>
        <p className={styles.text}>{label}</p>
        {description && <Description className={styles.description}>{description}</Description>}
      </div>
    </label>
  );
}
