import { FocusEvent, HTMLProps, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Avatar, IconButton, PlainAction } from "packages/catalog";
import { EAttachment, EBreakpoint, handlePlacement, EStatus, useWindowSize } from "packages/utils";

import styles from "./BigPopoverMenu.module.scss";

export interface PBigPopoverMenu extends HTMLProps<HTMLDetailsElement> {
  attachment: EAttachment;
  children: ReactNode;
  trigger: ReactChild<typeof IconButton | typeof Avatar>;
}

export function BigPopoverMenu({ attachment, children, trigger }: PBigPopoverMenu) {
  const [currentHeight, setCurrentHeight] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(0);

  const detailsRef = useRef<HTMLDetailsElement>(null);

  const { width } = useWindowSize();

  const timeoutIDRef = useRef<number>(null);
  const location = useLocation();

  useEffect(() => {
    let f: number;
    let w: number;
    let h: number;
    function resize() {
      if (detailsRef.current) {
        const { width, height } = detailsRef.current.getBoundingClientRect();
        if (w !== width || h !== height) {
          w = width;
          h = height;
          setCurrentWidth(width);
          setCurrentHeight(height);
        }
      }
      f = requestAnimationFrame(resize);
    }
    f = requestAnimationFrame(resize);
    return () => {
      cancelAnimationFrame(f);
    };
  }, [detailsRef]);

  const contentStyle = useMemo(() => {
    if (currentWidth === 0 || currentHeight === 0) {
      return { display: "none" };
    }
    return handlePlacement(attachment, currentWidth, currentHeight);
  }, [attachment, currentHeight, currentWidth]);

  const isSmallScreen = useMemo(() => width < EBreakpoint.BREAKPOINT_MD, [width]);

  const closeDetails = useCallback(() => {
    timeoutIDRef.current = window.setTimeout(() => {
      detailsRef.current.open = false;
    });
  }, []);

  useEffect(() => {
    closeDetails();
  }, [closeDetails, location]);

  const onBlurHandler = useCallback(
    (e: FocusEvent<HTMLElement>) => {
      const shouldBlur = !detailsRef.current.contains(e.relatedTarget as Element);
      if (shouldBlur) {
        closeDetails();
      }
    },
    [closeDetails],
  );

  const content = useMemo(
    () => (
      <div className={styles.content} onBlur={onBlurHandler} style={!isSmallScreen ? contentStyle : null}>
        {children}
        {isSmallScreen && (
          <div className={styles.cancel}>
            <PlainAction onClick={closeDetails} status={EStatus.NEUTRAL}>
              Cancel
            </PlainAction>
          </div>
        )}
      </div>
    ),
    [children, closeDetails, contentStyle, isSmallScreen, onBlurHandler],
  );

  return (
    <details className={styles.details} ref={detailsRef}>
      <summary className={styles.summary} onBlur={onBlurHandler}>
        {trigger}
      </summary>
      {isSmallScreen ? <div className={styles.overlay}>{content}</div> : content}
    </details>
  );
}
