import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useDevicesMutedState, useSelectorVidyo } from "packages/call";
import { Cell, Feedback, Field, Grid, H1, Input, InternalTextLink, P, Strong } from "packages/catalog";
import { EStatus, PATH_PRECALL_DEVICES, PATH_PRECALL_JOINCALL, PATH_SIGNIN } from "packages/utils";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";
import { setGuestName } from "packages/client/authentication/redux/slice";

import { useRecentSessions } from "packages/client/precall/hooks";
import { useRoom } from "packages/client/incall/hooks";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { PINField, SessionIDField } from "packages/client/precall/components";

export function JoinCall() {
  const { isSignedIn, guestName, meetingId, pin } = useTypedSelector(({ authentication, sessions }) => ({
    guestName: authentication.guestName,
    isSignedIn: authentication.isSignedIn,
    meetingId: sessions.roomKey,
    pin: sessions.pin,
    token: authentication.token,
  }));
  const dispatch = useTypedDispatch();
  const { room, loading: roomLoading } = useRoom();
  const { sdkError } = useSelectorVidyo(({ status }) => ({
    sdkError: status.sdkError,
  }));
  const { saveSession } = useRecentSessions();
  const [isFetching, setIsFetching] = useState(false);
  const [isPinValid, setIsPinValid] = useState(false);
  const { pathname } = useLocation();
  const { state }: { state?: { moderate?: boolean } } = useLocation();

  const navigate = useNavigate();
  const { setShouldCameraBeMuted, setShouldMicrophoneBeMuted } = useDevicesMutedState();

  const isMeetingIdInvalid = !room?.roomInfo?.key && !roomLoading;

  const isContinueDisabled = useMemo(() => {
    return (
      roomLoading ||
      isFetching ||
      (room?.needPin && !isPinValid) ||
      isMeetingIdInvalid ||
      !meetingId.length ||
      !guestName.length ||
      (room?.needPin && !pin.length)
    );
  }, [
    roomLoading,
    isFetching,
    room?.needPin,
    isPinValid,
    isMeetingIdInvalid,
    meetingId.length,
    guestName.length,
    pin.length,
  ]);

  const handleFullNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value;
      dispatch(setGuestName(val));
    },
    [dispatch],
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isMeetingIdInvalid) return;

      saveSession(meetingId, room?.roomInfo?.displayName);

      navigate(PATH_PRECALL_DEVICES(meetingId), { state: { from: PATH_PRECALL_JOINCALL(meetingId) } });
    },
    [isMeetingIdInvalid, saveSession, meetingId, room?.roomInfo?.displayName, navigate],
  );

  useEffect(() => {
    if (state?.moderate) {
      setShouldCameraBeMuted(true);
      setShouldMicrophoneBeMuted(true);
    }
  }, [setShouldCameraBeMuted, setShouldMicrophoneBeMuted, state?.moderate]);

  return (
    <>
      <RoutingChangeHandler message="The join session page has loaded." pageTitle="Join session" />
      <Grid as="form" onSubmit={handleSubmit} noColGap>
        <Cell>
          <H1 center>Join ClassView session</H1>
          {room?.roomInfo?.displayName && <P center>{room?.roomInfo?.displayName}</P>}
        </Cell>
        <Cell>
          <Field label="Your name">
            <Input autoFocus type="text" required value={guestName} onChange={handleFullNameChange} maxLength={50} />
          </Field>
        </Cell>
        <Cell>
          <SessionIDField />
        </Cell>
        {room?.needPin && (
          <Cell>
            <PINField
              isPinValid={isPinValid}
              callID={meetingId}
              setIsFetching={setIsFetching}
              setIsPinValid={setIsPinValid}
            />
          </Cell>
        )}
        <Cell center>
          <Input type="submit" disabled={isContinueDisabled} value="Continue" />
        </Cell>
        {!isSignedIn && (
          <Cell center>
            <P>
              Have an account?{" "}
              <InternalTextLink state={{ from: pathname }} to={PATH_SIGNIN(!isMeetingIdInvalid && meetingId)}>
                Sign in to ClassView
              </InternalTextLink>
              .
            </P>
          </Cell>
        )}
        {sdkError && (
          <Cell>
            <Feedback center status={EStatus.DANGER}>
              {sdkError}
            </Feedback>
          </Cell>
        )}
        <Cell>
          <P hasMargin>
            <Strong>Recording notice:</Strong> Please be aware that this session may be recorded. If you do not wish to
            be recorded please do not join this session, or notify the session organiser that you do not wish to be
            recorded.
          </P>
          <P>
            Only the session organiser and registered members of an organisation have the ability to record sessions.
            You will see a recording notification in the top-left corner when recording is taking place.
          </P>
        </Cell>
      </Grid>
    </>
  );
}
