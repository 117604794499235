import type { InputHTMLAttributes, ReactElement } from "react";

import { Icon } from "packages/catalog";
import type { EIcon } from "packages/utils";

import type { TGrid } from "packages/client/incall/hooks";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Tool.modules.scss";

export interface PTool extends InputHTMLAttributes<HTMLElement> {
  active?: boolean | TGrid;
  children?: ReactElement;
  fill?: string;
  icon?: EIcon;
  inputType: "radio" | "button" | "color";
  label: string;
}

export function Tool({ active, children, disabled, icon, label, inputType, title, ...rest }: PTool) {
  return (
    <label
      className={`${styles.tool}${active ? ` ${styles.active}` : ""}${disabled ? ` ${styles.disabled}` : ""}`}
      title={title}
      role={inputType}>
      {children ? children : <Icon className={styles.icon} icon={icon} />}
      <input className={styles.input} type={inputType} id={label} {...rest} />
      <span className={styles.label}>{label}</span>
    </label>
  );
}
