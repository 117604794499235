import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LOCAL_PREFERRED_DEVICES } from "packages/utils";

import { saveReducerToLocalStorage } from "../../redux/functions";
import { EDevice } from "../enums/EDevice";
import { EVidyoLocalDeviceStatus } from "../../status/enums";
import type { TVidyoDevice } from "../../streams/types";
import {
  EVidyoLocalDevicesActions,
  ILocalDeviceState,
  ILocalDevicesState,
  IAddLocalDeviceAction,
  TLocalDevicesAction,
} from "./types";

const initialState: ILocalDevicesState = {
  [EDevice.CAMERA]: {
    available: [],
    selected: undefined,
    shouldBeMuted: false,
    serverMuted: false,
    status: EVidyoLocalDeviceStatus.STOPPED,
    preferred: "",
  },
  [EDevice.MICROPHONE]: {
    available: [],
    selected: undefined,
    shouldBeMuted: false,
    serverMuted: false,
    status: EVidyoLocalDeviceStatus.STOPPED,
    preferred: "",
  },
  [EDevice.SPEAKER]: {
    available: [],
    selected: undefined,
    shouldBeMuted: false,
    serverMuted: false,
    status: EVidyoLocalDeviceStatus.STOPPED,
    preferred: "",
  },
};

// * <<<<<<-----------
// the following two consts are an attempt to upgrade to modern Redux using slices. this is not possible currently as the store is containing classes from vidyo's types, and doing state[deviceType].available?.push(device) in deviceAdded method throws an unsuppressable typescript error. the vidyo redux therefore can't use slices, at least in this reducer.
const localDevicesSlice = createSlice({
  name: "localDevices",
  initialState,
  reducers: {
    deviceAdded(state, action: PayloadAction<IAddLocalDeviceAction>) {
      const {
        deviceType,
        // device
      } = action.payload;
      state[deviceType].available?.push();
    },
  },
});

export const { deviceAdded } = localDevicesSlice.actions;

//* -------->

export function localDevicesReducer<T extends EDevice>(
  state: ILocalDeviceState<T>,
  action: TLocalDevicesAction,
): ILocalDeviceState<T> {
  switch (action.type) {
    case EVidyoLocalDevicesActions.ADD_DEVICE:
      return { ...state, available: [...state.available, action.device as TVidyoDevice<T>] };
    case EVidyoLocalDevicesActions.REMOVE_DEVICE:
      return { ...state, available: state.available.filter(d => d.id !== action.device.id) };
    case EVidyoLocalDevicesActions.SELECT_DEVICE:
      return { ...state, selected: action.device as TVidyoDevice<T> };
    case EVidyoLocalDevicesActions.MUTE_DEVICE:
      return { ...state, shouldBeMuted: action.shouldBeMuted };
    case EVidyoLocalDevicesActions.CHANGE_DEVICE_STATUS:
      return { ...state, status: action.status };
    case EVidyoLocalDevicesActions.SET_PREFERRED_DEVICE:
      return { ...state, preferred: action.deviceId };
    case EVidyoLocalDevicesActions.SERVER_MUTE_DEVICE:
      return { ...state, serverMuted: action.serverMuted };
    case EVidyoLocalDevicesActions.RESET_MUTE:
      return { ...state, serverMuted: false, shouldBeMuted: false };
    default:
      return state;
  }
}

function unwrappedReducer(state = initialState, action: TLocalDevicesAction): ILocalDevicesState {
  if (action.type === EVidyoLocalDevicesActions.RESET_MUTE) {
    return {
      ...state,
      cameras: localDevicesReducer(state.cameras, action),
      microphones: localDevicesReducer(state.microphones, action),
      speakers: localDevicesReducer(state.speakers, action),
    };
  }
  switch (action.deviceType) {
    case EDevice.CAMERA:
    case EDevice.MICROPHONE:
    case EDevice.SPEAKER:
      return { ...state, [action.deviceType]: localDevicesReducer(state[action.deviceType], action) };
    default:
      return state;
  }
}

export const persistedLocalDevicesReducer = saveReducerToLocalStorage(LOCAL_PREFERRED_DEVICES, unwrappedReducer, s => ({
  [EDevice.CAMERA]: {
    preferred: s[EDevice.CAMERA].preferred,
  },
  [EDevice.MICROPHONE]: {
    preferred: s[EDevice.MICROPHONE].preferred,
  },
  [EDevice.SPEAKER]: {
    preferred: s[EDevice.SPEAKER].preferred,
  },
}));
