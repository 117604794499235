import { BUCKET_ORGANISATION_LOGOS, HEADER_JSON } from "packages/utils";

import { fetchDjangoAPI, fetchDjangoAPIAuthenticated } from "packages/client/fetch";

import { getToken } from "packages/client/authentication/redux/functions";
import { deleteFile, sendFile } from "packages/client/files/s3";
import { IOrganisationTypeOverride, toDjangoOrganisation } from "packages/client/organisations/django/serialisers";

import type {
  IDjangoConnections,
  IDjangoEssentialOrganisation,
  IDjangoFullOrganisation,
  IDjangoPendingConnections,
} from "packages/client/organisations/django/types";

export async function acceptConnection(organisationID: string) {
  const response = await fetchDjangoAPIAuthenticated(`/organisations/connection/${organisationID}/`, {
    headers: HEADER_JSON,
    method: "GET",
  });

  if (response.status !== 200) {
    throw new Error("Unable to accept connection request.");
  }

  return response;
}

export async function approveOrganisation(organisationID: string) {
  const response = await fetchDjangoAPIAuthenticated(`/organisations/approve/${organisationID}/`, {
    headers: HEADER_JSON,
    method: "GET",
  });

  if (response.status !== 200) {
    throw new Error("Unable to approve organisation.");
  }

  return response;
}

export async function createOrganisation(data: Partial<IOrganisationTypeOverride>) {
  const response = await fetchDjangoAPI("/organisations/", {
    body: JSON.stringify(toDjangoOrganisation(data, true)),
    headers: HEADER_JSON,
    method: "POST",
  });

  if (response.status !== 201) {
    throw new Error("Unable to create organisation.");
  }

  return response.json() as Promise<IDjangoFullOrganisation>;
}

export async function deleteConnection(organisationID: string) {
  const response = await fetchDjangoAPIAuthenticated(`/organisations/connection/${organisationID}/`, {
    headers: HEADER_JSON,
    method: "DELETE",
  });

  if (response.status !== 204) {
    throw new Error("Unable to remove connection.");
  }
}

export async function deleteOrganisation(organisationID: string) {
  const response = await fetchDjangoAPIAuthenticated(`/organisations/${organisationID}/`, {
    headers: HEADER_JSON,
    method: "DELETE",
  });

  if (response.status !== 204) {
    throw new Error("Unable to delete organisation.");
  }

  return response;
}

export async function editOrganisation(organisationID: string, data: Partial<IOrganisationTypeOverride>) {
  const response = await fetchDjangoAPIAuthenticated(`/organisations/${organisationID}/`, {
    body: JSON.stringify(toDjangoOrganisation(data, true)),
    headers: HEADER_JSON,
    method: "PATCH",
  });

  if (response.status !== 200) {
    throw new Error("Unable to update organisation.");
  }

  return response;
}

export async function getAllOrganisations(): Promise<IDjangoEssentialOrganisation[]> {
  const response = await fetchDjangoAPI("/organisations/", {
    headers: HEADER_JSON,
    method: "GET",
  });

  if (response.status !== 200) {
    throw new Error("Unable to get all organisations.");
  }

  const data = await response.json();

  return data;
}

export async function getConnections(): Promise<IDjangoConnections> {
  const response = await fetchDjangoAPIAuthenticated("/organisations/connection/", {
    headers: HEADER_JSON,
    method: "GET",
  });

  const data = await response.json();

  return data;
}

export async function getOrganisation(organisationID: number) {
  const resp = await fetchDjangoAPI(`/organisations/${organisationID}/`, { headers: HEADER_JSON });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp.json() as Promise<IDjangoFullOrganisation>;
}

export async function getPendingConnections(): Promise<IDjangoPendingConnections> {
  const response = await fetchDjangoAPIAuthenticated("/organisations/connection/pending", {
    headers: HEADER_JSON,
    method: "GET",
  });

  if (response.status !== 200) {
    throw new Error("Unable to get pending connections.");
  }

  const data = await response.json();

  return data;
}

export async function requestConnection(organisationID: string) {
  const response = await fetchDjangoAPIAuthenticated("/organisations/connection/", {
    body: JSON.stringify({
      requestee: organisationID,
    }),
    headers: HEADER_JSON,
    method: "POST",
  });

  if (response.status !== 201) {
    throw new Error("Unable to make connection request.");
  }

  return response;
}

export async function updateOrganisationLogoAddress(filename: string, organisationID: string) {
  const response = await fetchDjangoAPIAuthenticated(`/organisations/${organisationID}/`, {
    body: JSON.stringify({
      id: organisationID,
      logo: filename,
    }),
    headers: HEADER_JSON,
    method: "PATCH",
  });

  return response.status === 200;
}

export async function uploadOrganisationLogo(
  formData: FormData,
  filename: string,
  currentOrganisationLogo: string,
  organisationID: string,
) {
  formData.append("bucket", BUCKET_ORGANISATION_LOGOS);
  formData.append("subfolder", "");

  const token = getToken();

  const response = await sendFile(formData, token);

  if (!response) {
    const organisationIsUpdated = await updateOrganisationLogoAddress(filename, organisationID);

    if (organisationIsUpdated) {
      if (currentOrganisationLogo) {
        if (
          await deleteFile(
            {
              bucket: BUCKET_ORGANISATION_LOGOS,
              filename: currentOrganisationLogo,
              subfolder: "",
            },
            token,
          )
        ) {
          console.error("Unable to delete current organisation logo.");
        }
      }
    } else {
      throw new Error("Unable to update organisation logo.");
    }
  } else {
    throw new Error(`Error: ${response} when sending files.`);
  }
}
