import { ECallStatus, EVidyoStatusActions, IStatusState, TStatusAction } from "./types";

import { saveReducerToLocalStorage } from "../../redux/functions";

const DEFAULT_REMOTE_SOURCES = 16;

const initialState: IStatusState = {
  callStatus: ECallStatus.OUTSIDE_CALL,
  isGridViewPreferred: false,
  isKicked: false,
  isTerminated: false,
  maxVidyoRemoteSources: DEFAULT_REMOTE_SOURCES,
  reason: null,
  sdkError: null,
  sdkReady: false,
  showSelfview: true,
};

function statusReducer(state = initialState, action: TStatusAction): IStatusState {
  switch (action.type) {
    case EVidyoStatusActions.SET_SDK_READY:
      return { ...state, sdkReady: action.sdkReady, sdkError: action.sdkError ?? null };
    case EVidyoStatusActions.SET_IS_GRIDVIEW_PREFERRED:
      return { ...state, isGridViewPreferred: action.isGridViewPreferred };
    case EVidyoStatusActions.RESET_LEAVE_STATUS:
      return { ...state, isKicked: false, isTerminated: false };
    case EVidyoStatusActions.SET_SHOW_SELFVIEW:
      return { ...state, showSelfview: action.showSelfview };
    case EVidyoStatusActions.SET_CALL_STATUS: {
      const vidyoLimit =
        action.status === ECallStatus.OUTSIDE_CALL ? DEFAULT_REMOTE_SOURCES : state.maxVidyoRemoteSources;

      // we need to persist the kicked or terminated status seperately as usePresenceInCall reruns and sets the status to OUTSIDE_CALL before the left session screen can handle appropriate messaging. isKicked and isTerminated are reset on each of the buttons for navigating away from the left session screen.
      const keepIsKicked = !state.isKicked ? action.status === ECallStatus.KICKED : state.isKicked;
      const keepIsTerminated = !state.isTerminated ? action.status === ECallStatus.TERMINATED : state.isTerminated;

      return {
        ...state,
        callStatus: action.status,
        isKicked: keepIsKicked,
        isTerminated: keepIsTerminated,
        maxVidyoRemoteSources: vidyoLimit,
        reason: action.reason ?? null,
      };
    }
    case EVidyoStatusActions.MAX_REMOTE_SOURCES:
      return { ...state, maxVidyoRemoteSources: action.maxVidyoRemoteSources };
    default:
      return state;
  }
}
export const persistedStatusReducer = saveReducerToLocalStorage("status", statusReducer, s => ({
  isGridViewPreferred: s.isGridViewPreferred,
}));
