import { Outlet } from "react-router-dom";

import { ErrorBoundary } from "packages/client/errors/components";
import { BasicHeader } from "packages/client/layout/components";

import styles from "./PlayerLayout.module.scss";

export function PlayerLayout() {
  return (
    <div className={styles.grid}>
      <BasicHeader />
      <main className={styles.main}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </main>
    </div>
  );
}
