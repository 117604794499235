import type { ReactNode } from "react";
import { Link } from "react-router-dom";

import { H1, IconButton } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

import styles from "./FullScreenContainer.module.scss";

export interface PFullScreenContainer {
  children: ReactNode;
  closeTo: string | number;
  heading: ReactChild<typeof H1>;
}

export function FullScreenContainer({ closeTo, children, heading }: PFullScreenContainer) {
  return (
    <div className={styles.createWrapper}>
      <header className={styles.header}>
        {heading}
        <div className={styles.closeButton}>
          <IconButton
            appearance={EAppearance.OUTLINE}
            as={Link}
            description="Close"
            dimension={EDimension.MEDIUM}
            icon={EIcon.X}
            status={EStatus.NEUTRAL}
            to={closeTo}
          />
        </div>
      </header>
      <main className={styles.centeredContent}>{children}</main>
    </div>
  );
}
