import type { HTMLProps } from "react";

import { useMergedClassNames } from "packages/utils";

import styles from "./HR.module.scss";

export interface PHR extends HTMLProps<HTMLHRElement> {
  hasMargin?: boolean;
}

export function HR({ children, className, hasMargin, ...rest }: PHR) {
  const HRClasses = useMergedClassNames({ className }, [styles.rule, hasMargin && styles.hasMargin]);

  if (children)
    return (
      <div className={styles.container}>
        <hr {...rest} className={HRClasses} />
        <p className={styles.text}>{children}</p>
        <hr {...rest} className={HRClasses} />
      </div>
    );
  else return <hr {...rest} className={HRClasses} />;
}
