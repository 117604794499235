import { useCallback, useState } from "react";
import type { ApolloQueryResult } from "@apollo/client";

import { Block, Cell, EM, Flexbox, Grid, H2, P, Pagination, TextButton } from "packages/catalog";
import { EAppearance, EStatus, scrollToContentTop } from "packages/utils";

import { approveUser, deleteUser } from "packages/client/users/django/requests";
import { GetOrganisationUsersQuery, useGetPendingUsersQuery } from "packages/client/users/graphql/users.generated";

import { OrganisationSummary } from "packages/client/organisations/components";
import { UserSummary } from "packages/client/users/components";

import styles from "./PendingUsers.module.scss";

export interface PPendingUsers {
  refetchAllUsers: () => Promise<ApolloQueryResult<GetOrganisationUsersQuery>>;
  setSuccessMessage: (message: string) => void;
  setErrorMessage: (message: string) => void;
}

export function PendingUsers({ refetchAllUsers, setErrorMessage, setSuccessMessage }: PPendingUsers) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch } = useGetPendingUsersQuery({
    fetchPolicy: "cache-and-network",
    variables: { page: currentPage },
  });

  const handleApproveUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      scrollToContentTop();

      approveUser(userID)
        .then(() => {
          setSuccessMessage("User approved.");

          refetch();
          refetchAllUsers();
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetch, refetchAllUsers, setErrorMessage, setSuccessMessage],
  );

  const handleRejectUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      scrollToContentTop();
      deleteUser(userID)
        .then(() => {
          setSuccessMessage("User rejected.");

          refetch();
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetch, setErrorMessage, setSuccessMessage],
  );

  return (
    <>
      {data?.pendingUsers?.objects.length ? (
        <Cell>
          <Grid as={Block}>
            <Cell>
              <H2>Pending users</H2>
            </Cell>
            <>
              {data.pendingUsers.objects.map(pendingUser => (
                <Cell as={Block} key={pendingUser.id}>
                  <Flexbox alignItemsCenter justifyContentSpaceBetween>
                    <UserSummary
                      email={pendingUser.email}
                      emailAuthenticated={pendingUser.emailAuthenticated}
                      isActive={pendingUser.isActive}
                      fullName={`${pendingUser.firstName} ${pendingUser.lastName}`}
                    />
                    {pendingUser.organisation ? (
                      <OrganisationSummary organisationID={pendingUser?.organisation?.id} />
                    ) : (
                      <P>
                        <EM>Organisation unapproved</EM>
                      </P>
                    )}
                    <Flexbox>
                      <TextButton appearance={EAppearance.GHOST} onClick={() => handleRejectUser(pendingUser.id)}>
                        Reject
                      </TextButton>
                      <TextButton
                        aria-describedby={!pendingUser.organisation && "button-description"}
                        disabled={!pendingUser.organisation}
                        onClick={() => handleApproveUser(pendingUser.id)}
                        status={EStatus.ACCENT}
                        title={
                          !pendingUser.organisation
                            ? "Unable to approve user until organisation is approved"
                            : "Approve user"
                        }>
                        Approve
                      </TextButton>
                    </Flexbox>
                    {!pendingUser.organisation && (
                      <p className={styles.buttonDescription} id="button-description">
                        Unable to approve user until organisation is approved
                      </p>
                    )}
                  </Flexbox>
                </Cell>
              ))}
            </>
            {data?.pendingUsers?.pages > 1 && (
              <Cell>
                <Pagination
                  hasNext={data?.pendingUsers?.hasNext}
                  hasPrevious={data?.pendingUsers?.hasPrev}
                  page={data?.pendingUsers?.page}
                  pages={data?.pendingUsers?.pages}
                  setPage={setCurrentPage}
                />
              </Cell>
            )}
          </Grid>
        </Cell>
      ) : null}
    </>
  );
}
