import { v4 as uuid } from "uuid";

import { ELocale } from "packages/utils";

// convert a graphql enum into an options object for dropdown
export function convertStringEnumToDropdownObject<T>(enumSrc: T) {
  return Object.values(enumSrc).reduce<Record<string, string>>((acc, v) => {
    acc[v] = underscoredToTitleCase(v);
    return acc;
  }, {});
}

// copy a string to the clipboard
export function copyString(str: string): void {
  if (!str) return;
  // Add new input elmement to the document
  const dummy = document.createElement("input");
  document.body.appendChild(dummy);
  // Output the array into it
  dummy.value = str;
  // Select it
  dummy.select();
  // Copy its contents
  document.execCommand("copy");
  // Remove it as its not needed anymore
  document.body.removeChild(dummy);
}

// flatten and hyphenate a string e.g. slugify
export function flattenString(str: string): string {
  if (!str) return "";
  return str?.replace(/\s+/g, "-").toLowerCase() ?? "";
}

export function getDays(days: readonly number[]): string {
  return days
    .map(day => ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays", "Sundays"][day])
    .join(", ");
}

export function getWeekOfMonth(week: number): string {
  return ["first", "second", "third", "fourth"][week];
}

// TODO: remove this later
/* convert numbers from 0 to 9 into words */
export function numToWords(n: number): string {
  const units = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];

  return n < 10 ? units[n - 1] : `${n}`;
}

// create a plural piece of text
export function pluralise(count: number, locale: ELocale, single: string, plural: string): string {
  const rules = new Intl.PluralRules(locale, {
    type: "cardinal",
  });

  const grammaticalNumber = rules.select(count);

  const word = {
    [ELocale.EN_GB]: {
      zero: plural,
      one: single,
      two: plural,
      few: plural,
      many: plural,
      other: plural,
    },
  };

  return `${count} ${word[locale][grammaticalNumber]}`;
}

export function randomString(): string {
  return uuid();
}

// squash that email
export function shortenEmail(email: string): string {
  if (!email) return "";
  const parts = email.split("@");
  let domain = parts[1];
  if (domain.length > 10) {
    const oldDomainParts = domain.split(".");
    domain = `${oldDomainParts[0].slice(0, 4)}... .${oldDomainParts[1]}`;
  }
  const firstPart = parts[0].length > 6 ? `${parts[0].slice(0, 4)}... ` : parts[0];
  return `${firstPart}@${domain}`;
}

// transform first character of string to uppercase
export function toSentenceCase(str: string): string {
  if (!str) return "";
  const copy = str ?? "";
  if (copy.length < 1) return copy;
  return `${copy.slice(0, 1).toUpperCase()}${copy.slice(1).toLowerCase()}`;
}

export function toTitleCase(str: string): string {
  if (!str) return "";
  return str
    .split(" ")
    .map(word => toSentenceCase(word))
    .join(" ");
}

export function transformUnderscoresToSpaces(str: string): string {
  if (!str) return "";
  return str.replace(/_+/g, " ");
}

export function underscoredToTitleCase(str: string): string {
  if (!str) return "";
  return toTitleCase(transformUnderscoresToSpaces(str));
}
