import { forwardRef, ReactNode } from "react";

import styles from "./SidebarMain.module.scss";

export interface PSidebarMain {
  children: ReactNode;
}

export const SidebarMain = forwardRef<HTMLElement, PSidebarMain>(({ children, ...rest }, ref) => {
  return (
    <main className={styles.sidebarMain} ref={ref} {...rest}>
      {children}
    </main>
  );
});
