import { useCallback, useEffect, useState } from "react";
import type { MutableRefObject } from "react";

export function useContainerSize(targetRef: MutableRefObject<HTMLElement>) {
  const getDimensions = useCallback(() => {
    return {
      width: targetRef.current ? targetRef.current.offsetWidth : 0,
      height: targetRef.current ? targetRef.current.offsetHeight : 0,
    };
  }, [targetRef]);

  const [dimensions, setDimensions] = useState(getDimensions);

  const handleResize = useCallback(() => {
    if (targetRef.current) {
      setDimensions(getDimensions());
    }
  }, [getDimensions, targetRef]);

  useEffect(() => {
    if (!targetRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry) {
          handleResize();
        }
      }
    });

    resizeObserver.observe(targetRef.current);

    return () => resizeObserver.disconnect();
  }, [handleResize, targetRef]);

  return dimensions;
}
