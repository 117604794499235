import { useCallback } from "react";

import { Flexbox, TextButton } from "packages/catalog";
import { EAppearance, EStatus } from "packages/utils";

import { useTypedDispatch } from "packages/client/redux";
import { useVidyoContext } from "packages/client/incall/hooks";
import { useLeaveCall } from "packages/client/postcall/hooks/useLeaveCall";

import { toggleIsLeaveOptionsOpen } from "packages/client/layout/redux/slice";

// user will only see the extra options if they are a call moderator
export function LeaveOptions() {
  const {
    moderation: { kickAll, numberOfParticipants },
    recording: { isRequesting, isBeingRecorded, requestStopRecording },
  } = useVidyoContext();
  const leaveCall = useLeaveCall();
  const dispatch = useTypedDispatch();

  const handleKickEveryone = useCallback(async () => {
    await kickAll().then(() => {
      dispatch(toggleIsLeaveOptionsOpen(false));
      leaveCall();
    });
  }, [dispatch, kickAll, leaveCall]);

  const handleLeave = useCallback(() => {
    dispatch(toggleIsLeaveOptionsOpen(false));
    leaveCall();
  }, [dispatch, leaveCall]);

  const handleStopRecording = useCallback(() => {
    requestStopRecording();
  }, [requestStopRecording]);

  return (
    <Flexbox justifyContentCenter>
      {(isBeingRecorded || isRequesting) && (
        <TextButton
          appearance={EAppearance.FILL}
          disabled={isRequesting}
          onClick={handleStopRecording}
          status={EStatus.DANGER}>
          {isRequesting ? "Stopping recording..." : "Stop recording"}
        </TextButton>
      )}
      {numberOfParticipants > 1 && (
        <TextButton
          appearance={EAppearance.OUTLINE}
          disabled={isBeingRecorded || isRequesting}
          onClick={handleKickEveryone}
          status={EStatus.DANGER}>
          End session for everyone
        </TextButton>
      )}
      <TextButton
        appearance={EAppearance.FILL}
        disabled={numberOfParticipants === 1 && isBeingRecorded}
        onClick={handleLeave}
        status={EStatus.ACCENT}>
        Leave session
      </TextButton>
    </Flexbox>
  );
}
