import { useCallback } from "react";

import { BigPopoverMenu, Flexbox, Icon, PlainAction, TextButton } from "packages/catalog";
import { EAttachment, EIcon, EStatus } from "packages/utils";

import { acceptConnection, deleteConnection, requestConnection } from "packages/client/organisations/django/requests";
import { useGetConnections } from "packages/client/organisations/hooks";

import styles from "./ManageConnection.module.scss";

export interface PManageConnection {
  organisationID: string;
}

export function ManageConnection({ organisationID }: PManageConnection) {
  const {
    connections: { connected, incoming, outgoing },
    getData,
  } = useGetConnections();

  const handleOnClickAccept = useCallback(() => {
    acceptConnection(organisationID).then(() => {
      getData();
    });
  }, [getData, organisationID]);

  const handleOnClickDelete = useCallback(() => {
    deleteConnection(organisationID).then(() => {
      getData();
    });
  }, [getData, organisationID]);

  const handleOnClickRequest = useCallback(() => {
    requestConnection(organisationID).then(() => {
      getData();
    });
  }, [getData, organisationID]);

  if (connected.includes(parseInt(organisationID)))
    return (
      <BigPopoverMenu
        attachment={EAttachment.SOUTH_SOUTH_EAST}
        trigger={
          <TextButton as="div" status={EStatus.SUCCESS}>
            <Flexbox alignItemsCenter>
              <div className={styles.content}>
                <Icon className={styles.icon} icon={EIcon.CHECK} />
                <span>Connected</span>
              </div>
              <Icon className={styles.icon} icon={EIcon.CHEVRONDOWN} />
            </Flexbox>
          </TextButton>
        }>
        <PlainAction as="button" onClick={handleOnClickDelete} status={EStatus.DANGER}>
          Remove connection
        </PlainAction>
      </BigPopoverMenu>
    );

  if (incoming.includes(parseInt(organisationID)))
    return (
      <BigPopoverMenu
        attachment={EAttachment.SOUTH_SOUTH_EAST}
        trigger={
          <TextButton as="div" status={EStatus.NEUTRAL}>
            <Flexbox alignItemsCenter>
              <div className={styles.content}>
                <Icon className={styles.icon} icon={EIcon.LINK} />
                <span>Connection request received</span>
              </div>
              <Icon className={styles.icon} icon={EIcon.CHEVRONDOWN} />
            </Flexbox>
          </TextButton>
        }>
        <Flexbox as="ul" flexDirectionColumn>
          <li>
            <PlainAction as="button" onClick={handleOnClickAccept} status={EStatus.NEUTRAL}>
              Accept
            </PlainAction>
          </li>
          <li>
            <PlainAction as="button" onClick={handleOnClickDelete} status={EStatus.DANGER}>
              Decline
            </PlainAction>
          </li>
        </Flexbox>
      </BigPopoverMenu>
    );

  if (outgoing.includes(parseInt(organisationID)))
    return (
      <BigPopoverMenu
        attachment={EAttachment.SOUTH_SOUTH_EAST}
        trigger={
          <TextButton as="div" status={EStatus.NEUTRAL}>
            <Flexbox alignItemsCenter>
              <div className={styles.content}>
                <Icon className={styles.icon} icon={EIcon.MOREHORIZONTAL} />
                <span>Connection request sent</span>
              </div>
              <Icon className={styles.icon} icon={EIcon.CHEVRONDOWN} />
            </Flexbox>
          </TextButton>
        }>
        <PlainAction as="button" onClick={handleOnClickDelete} status={EStatus.DANGER}>
          Cancel request
        </PlainAction>
      </BigPopoverMenu>
    );

  return (
    <TextButton onClick={handleOnClickRequest} status={EStatus.ACCENT}>
      <div className={styles.content}>
        <Icon className={styles.icon} icon={EIcon.PLUS} />
        <span>Add connection</span>
      </div>
    </TextButton>
  );
}
