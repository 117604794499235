import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  BigPopoverMenu,
  Cell,
  ConfirmActionModal,
  Description,
  EM,
  Feedback,
  Flexbox,
  Grid,
  H1,
  IconButton,
  Input,
  IllustratedCTA,
  Label,
  Loader,
  P,
  Pagination,
  PlainAction,
  Strong,
  Table,
  TD,
  TR,
  TRTDTemp,
} from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EIllustration,
  EStatus,
  PATH_ORGANISATION,
  PATH_USER,
  scrollToContentTop,
} from "packages/utils";

import { deleteUser, demoteUser, promoteUser } from "packages/client/users/django/requests";
import {
  useGetChildOrganisationsIDsOwnUserQuery,
  useGetOrganisationUsersQuery,
  useGetEssentialOwnUserQuery,
} from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { OrganisationSummary } from "packages/client/organisations/components";
import { PendingUsers, UserSummary } from "packages/client/users/components";

const COLUMNS = ["ID", "User", "Organisation"];
const COLUMN_WIDTHS = "4rem 1.5fr 1fr 3rem";

export function Users() {
  const [actionID, setActionID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchString, setDebounceSearchString] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    data: allUsersData,
    loading,
    error,
    refetch: refetchAllUsers,
  } = useGetOrganisationUsersQuery({
    variables: { page: currentPage, search: debouncedSearchString },
    fetchPolicy: "cache-and-network",
  });
  const { data: ownUserData } = useGetEssentialOwnUserQuery();
  const { data: childOrganisationIDsData } = useGetChildOrganisationsIDsOwnUserQuery();

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value);
  }, []);

  const handleDeleteUser = useCallback(() => {
    setSuccessMessage("");
    setErrorMessage("");

    scrollToContentTop();
    setIsDeleteModalOpen(false);

    deleteUser(actionID)
      .then(() => {
        refetchAllUsers();
        setSuccessMessage("User deleted.");
      })
      .catch(reason => {
        setErrorMessage(reason.message);
      });

    setActionID("");
  }, [actionID, refetchAllUsers]);

  const handleDemoteUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      scrollToContentTop();

      demoteUser(userID)
        .then(() => {
          refetchAllUsers();
          setSuccessMessage("User removed from administrators.");
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetchAllUsers],
  );

  const handlePromoteUser = useCallback(
    (userID: string) => {
      setSuccessMessage("");
      setErrorMessage("");

      scrollToContentTop();

      promoteUser(userID)
        .then(() => {
          setSuccessMessage("User added to administrators");
          refetchAllUsers();
        })
        .catch(reason => {
          setErrorMessage(reason.message);
        });
    },
    [refetchAllUsers],
  );

  const prepareDeleteUser = useCallback((userID: string) => {
    setActionID(userID);
    setIsDeleteModalOpen(true);
  }, []);

  useEffect(() => {
    let cancelled = false;

    window.setTimeout(() => {
      if (!cancelled) setDebounceSearchString(searchString);
    }, 500);

    return () => {
      cancelled = true;
    };
  }, [searchString]);

  return (
    <>
      <RoutingChangeHandler message="The users page has loaded." pageTitle="Users" />
      <Grid noColGap>
        {errorMessage.length ? (
          <Cell>
            <Feedback status={EStatus.DANGER}>{errorMessage}</Feedback>
          </Cell>
        ) : null}
        {successMessage.length ? (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>{successMessage}</Feedback>
          </Cell>
        ) : null}
        <Cell>
          <Flexbox alignItemsCenter justifyContentSpaceBetween>
            <div>
              <H1>Users</H1>
              <Description>
                Displaying all users of your organsation
                {childOrganisationIDsData?.user?.organisation?.organisationSet.length
                  ? " and its child organisations"
                  : null}
                .
              </Description>
            </div>
            <Label htmlFor="search" isVisuallyHidden text="Search users" />
            <Input id="search" name="search" onChange={handleChangeSearch} type="search" value={searchString} />
          </Flexbox>
        </Cell>
        <PendingUsers
          refetchAllUsers={refetchAllUsers}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
        />
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing your organisation's users"
            hasMenuColumn>
            <>
              {loading && (
                <TRTDTemp>
                  <Loader notFullHeight />
                </TRTDTemp>
              )}
              {error && (
                <TRTDTemp>
                  <Feedback status={EStatus.DANGER}>Unable to get list of users.</Feedback>
                </TRTDTemp>
              )}
              <>
                {allUsersData?.myOrganisationUsers?.objects && (
                  <>
                    {allUsersData.myOrganisationUsers.objects.map(user => (
                      <TR columnWidths={COLUMN_WIDTHS} key={user.id}>
                        <TD heading={COLUMNS[0]}>
                          <Strong>
                            <P>{user.id}</P>
                          </Strong>
                        </TD>
                        <TD heading={COLUMNS[1]}>
                          <Link style={{ display: "block" }} to={PATH_USER(user.id)}>
                            <UserSummary
                              email={user.email}
                              emailAuthenticated={user.emailAuthenticated}
                              fullName={`${user.firstName} ${user.lastName}`}
                              isActive={user.isActive}
                              isAdmin={user.isAdmin}
                              isSuperadmin={user.isSuperuser}
                              profilePictureURL={user.profilePicture}
                            />
                          </Link>
                        </TD>
                        <TD heading={COLUMNS[2]}>
                          {user.organisation ? (
                            <Link style={{ display: "block" }} to={PATH_ORGANISATION(user.organisation.id)}>
                              <OrganisationSummary organisationID={user.organisation.id} />
                            </Link>
                          ) : (
                            <EM>Pending</EM>
                          )}
                        </TD>
                        <TD>
                          {user.id !== ownUserData?.user.id && user.isActive && (
                            <BigPopoverMenu
                              attachment={EAttachment.SOUTH_SOUTH_EAST}
                              trigger={
                                <IconButton
                                  appearance={EAppearance.GHOST}
                                  as="div"
                                  description="Options"
                                  dimension={EDimension.SMALL}
                                  icon={EIcon.MOREVERTICAL}
                                  status={EStatus.NEUTRAL}
                                />
                              }>
                              <Flexbox as="ul" flexDirectionColumn>
                                <li>
                                  <PlainAction
                                    as="button"
                                    onClick={
                                      user.isAdmin ? () => handleDemoteUser(user.id) : () => handlePromoteUser(user.id)
                                    }>
                                    {user.isAdmin ? "Demote" : "Make admin"}
                                  </PlainAction>
                                </li>
                                <li>
                                  <PlainAction
                                    as="button"
                                    onClick={() => prepareDeleteUser(user.id)}
                                    status={EStatus.DANGER}>
                                    Delete
                                  </PlainAction>
                                </li>
                              </Flexbox>
                            </BigPopoverMenu>
                          )}
                        </TD>
                      </TR>
                    ))}
                  </>
                )}
                {searchString.length && !allUsersData?.myOrganisationUsers?.objects.length && !loading ? (
                  <TRTDTemp>
                    <IllustratedCTA illustration={EIllustration.PEOPLE_COLLABORATING} noPadding>
                      No users found.
                    </IllustratedCTA>
                  </TRTDTemp>
                ) : null}
              </>
            </>
          </Table>
        </Cell>
        {allUsersData?.myOrganisationUsers?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={allUsersData?.myOrganisationUsers?.hasNext}
              hasPrevious={allUsersData?.myOrganisationUsers?.hasPrev}
              page={allUsersData?.myOrganisationUsers?.page}
              pages={allUsersData?.myOrganisationUsers?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="user"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteUser}
        status={EStatus.DANGER}
      />
    </>
  );
}
