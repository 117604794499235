import { useCallback } from "react";

import { EVidyoSharingActions } from "../redux/types";

import { useVidyoSDKInstance } from "../../status/hooks/useVidyoSDKInstance";
import { dispatchVidyo, useSelectorVidyo } from "../../redux";
import type { TLocalWindowShare } from "../../streams/types";
import type { TLocalMonitor } from "../../local-devices/types";

export function useControlSharing() {
  const vidyoSDK = useVidyoSDKInstance();
  const { sharing } = useSelectorVidyo(({ sharing }) => ({ sharing }));

  /** Electron only, on browser use chooseWindowSharing and popup will provide monitor choice */
  const chooseMonitor = useCallback(
    (m: TLocalMonitor) => {
      if (!vidyoSDK || !IS_ELECTRON) return;

      vidyoSDK.SelectLocalMonitor({ localMonitor: m });
    },
    [vidyoSDK],
  );

  /**
   * - Electron use an actual LocalWindowShare
   * - Browser pass "startSharing" to launch the browser native popup
   *  */
  const chooseWindowSharing = useCallback(
    async (w: TLocalWindowShare | "startSharing") => {
      if (!vidyoSDK) return;

      // for electron
      if (typeof w !== "string") {
        await vidyoSDK.SelectLocalWindowShare({ localWindowShare: w });
        return;
      }

      // for browser
      if (w) {
        try {
          await vidyoSDK.RegisterLocalWindowShareEventListener({
            onAdded: async localWindowShare => {
              try {
                await vidyoSDK.SelectLocalWindowShare({ localWindowShare });
              } catch (e) {
                console.error("Error on SelectLocalWindowShare:", e);
              }
            },
            onRemoved: localWindowShare => console.info("removed sharing", localWindowShare),
            onSelected: localWindowShare =>
              dispatchVidyo({
                type: EVidyoSharingActions.SELECT_WINDOW_SHARE,
                windowShare: localWindowShare,
              }),
            onStateUpdated: (localWindowShare, state) => console.info("state update sharing", localWindowShare, state),
          });
        } catch (e) {
          console.error("Error on RegisterLocalWindowShareEventListener:", e);
          alert("Sorry, screen sharing failed.");
        }
      }
    },
    [vidyoSDK],
  );

  return { ...sharing, chooseMonitor, chooseWindowSharing };
}
