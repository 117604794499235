import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ETheme } from "packages/theming";

import type { ILayoutState } from "packages/client/layout/redux/types";

const initialState: ILayoutState = {
  isFeedbackFormOpen: false,
  isLeaveOptionsOpen: false,
  isWhiteboardOpen: false,
  routingChangeMessage: "",
  theme: ETheme.LIGHT,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleIsFeedbackFormOpen: (state, action: PayloadAction<boolean>) => {
      state.isFeedbackFormOpen = action.payload;
    },
    toggleIsLeaveOptionsOpen: (state, action: PayloadAction<boolean>) => {
      state.isLeaveOptionsOpen = action.payload;
    },
    toggleIsWhiteboardOpen: (state, action: PayloadAction<boolean>) => {
      state.isWhiteboardOpen = action.payload;
    },
    updateRoutingChangeMessage: (state, action: PayloadAction<string>) => {
      state.routingChangeMessage = action.payload;
    },
    updateTheme: (state, action: PayloadAction<ETheme>) => {
      state.theme = action.payload;
    },
  },
});

export const {
  toggleIsFeedbackFormOpen,
  toggleIsLeaveOptionsOpen,
  toggleIsWhiteboardOpen,
  updateRoutingChangeMessage,
  updateTheme,
} = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
