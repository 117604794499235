import { useMemo } from "react";

import { SUPPORT_INFORMATION } from "packages/utils";

import type { DialInDetails } from "packages/client/graphql_definitions.generated";

import { useGetEssentialUserQuery } from "packages/client/users/graphql/users.generated";
import { useGetDialInQuery, useGetFullRoomQuery } from "packages/client/incall/graphql/room.generated";

function generateEmailContent(
  dialIn: DialInDetails,
  organisationName: string,
  ownerName: string,
  sessionName: string,
  meetingID?: string,
  pin?: string,
) {
  return `
  ${ownerName} (${organisationName}) has invited you to a ClassView session.

  Join: ${window.location.origin}/call/${dialIn?.room}

  Name: ${sessionName}
  Session ID: ${dialIn?.room}
  Extension: ${dialIn?.extension}
  ${pin ? `PIN: ${pin}` : ""}

  --------

  Other ways to join

  Tel: call +443002010142 then enter extension ${dialIn?.extension}#

  Or from a ClassView POD: https://knowledgebase.classview.com/joining-options

  Please be aware that this session may be recorded. If you do not wish to be recorded, please mute your camera and microphone before joining, or notify the session organiser that you do not wish to be recorded.

  Only the session organiser or moderator has the ability to record sessions. You will notice a red icon at the top of the screen when recording is taking place.

  --------

  Need help?
  Contact our support team${meetingID && `, quoting reference ID ${meetingID}`}

  Tel: ${SUPPORT_INFORMATION.tel}
  Email: ${SUPPORT_INFORMATION.mail}
  (available 8am-5pm Monday to Friday)

  Or visit our support site ${SUPPORT_INFORMATION.page}`;
}

export function useGenerateEmailInvitationURI(roomKey: string) {
  // get all the details we need for the email content
  const { data: sessionInformation } = useGetFullRoomQuery({ variables: { roomKey } });
  const { data: sessionDialInDetails } = useGetDialInQuery({ variables: { roomKey } });
  const { data: sessionOwner } = useGetEssentialUserQuery({
    variables: {
      userID: parseInt(sessionInformation?.getAllRoomInfo?.roomInfo?.owner),
    },
  });

  // put it all together into the body
  const emailInvitationURI = useMemo(
    () =>
      encodeURI(
        `mailto:?subject=Join my ClassView session: ${sessionDialInDetails?.getAllRoomInfo?.dialInDetails
          ?.room}&body=${generateEmailContent(
          sessionDialInDetails?.getAllRoomInfo?.dialInDetails,
          sessionOwner?.user?.organisation?.name,
          `${sessionOwner?.user?.firstName} ${sessionOwner?.user?.lastName}`,
          sessionInformation?.getAllRoomInfo?.roomInfo?.name,
          sessionInformation?.getAllRoomInfo?.roomTypeLink?.id,
          sessionInformation?.getAllRoomInfo?.roomInfo?.pin,
        )}`,
      ),
    [sessionDialInDetails, sessionInformation, sessionOwner],
  );

  return emailInvitationURI;
}
