import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  EDays,
  EParticipants,
  ERecurringEnd,
  ERecurringFrequency,
  EToggle,
  EWeeklyOccurrence,
  ISessionState,
} from "packages/client/sessions/redux/types";
import type { IMutableSession, TMutableUser } from "packages/client/sessions/graphql/types";
import type { TRoomSystemDialout } from "packages/client/sessions/components/EditRoomSystems/EditRoomSystems";

import { getRecurringStartDay, setToMidnight } from "packages/client/sessions/functions";

// session state - for precall joining a session

const initialSessionState: ISessionState = {
  pin: "",
  roomKey: "",
};

export const sessionSlice = createSlice({
  name: "session",
  initialState: initialSessionState,
  reducers: {
    updatePIN: (state, action: PayloadAction<string>) => {
      state.pin = action.payload;
    },
    updateRoomKey: (state, action: PayloadAction<string>) => {
      state.roomKey = action.payload;
    },
  },
});

export const { updatePIN, updateRoomKey } = sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;

// session form state - for scheduling a session

export const initialSessionFormState: Partial<IMutableSession> = {
  applicationPreferred: false,
  blockExternalGuests: false,
  description: "",
  endTime: null,
  guests: [],
  id: null,
  isLectureMode: false,
  isPrivate: false,
  isRecorded: false,
  isWebcastMode: false,
  lecturers: [],
  localTimezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
  meetingOrganiser: null,
  moderators: [],
  nextRecurringEnd: null,
  nextRecurringStart: null,
  pinProtected: false,
  recurringDates: [],
  recurringEndDate: null,
  recurringEveryXMonths: null,
  recurringEveryXWeeks: null,
  recurringInterval: null,
  recurringOnDays: [],
  recurringOnXWeekOfMonth: null,
  recurringRepetitions: null,
  recurringWeekdaysOnly: false,
  room: null,
  roomSystems: [],
  startTime: null,
  subject: "",
  users: [],
  waitingRoomEnabled: false,
  webcastUrl: null,
};

export const sessionFormSlice = createSlice({
  name: "sessionForm",
  initialState: initialSessionFormState,
  reducers: {
    changeDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    changeDialoutIDs: (state, action: PayloadAction<number[]>) => {
      return {
        ...state,
        roomSystems: state.roomSystems.reduce((acc, rsdt) => {
          acc.push({
            ...rsdt,
            dialOut: action.payload.includes(parseInt(rsdt.roomSystem.id, 10)) ? true : false,
          });
          return acc;
        }, []),
      };
    },
    changeEndTime: (state, action: PayloadAction<Date>) => {
      state.endTime = action.payload;
    },
    changeParticipants: (
      state,
      action: PayloadAction<{ items: TMutableUser[] | TRoomSystemDialout[] | string[]; collection: EParticipants }>,
    ) => {
      if (state[action.payload.collection]) {
        return {
          ...state,
          [action.payload.collection]: action.payload.items,
        };
      } else return state;
    },
    changePIN: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        room: {
          ...state.room,
          pin: action.payload,
        },
      };
    },
    changeRecurringDay: (state, action: PayloadAction<{ days: EDays; toggle: EToggle }>) => {
      switch (state.recurringInterval) {
        case ERecurringFrequency.Monthly:
          switch (action.payload.toggle) {
            case EToggle.Select:
              return {
                ...state,
                recurringOnDays: [action.payload.days],
              };
            default:
              return state;
          }
        default:
          switch (action.payload.toggle) {
            case EToggle.Select:
              return {
                ...state,
                recurringOnDays: [...state.recurringOnDays, action.payload.days],
              };
            case EToggle.Unselect:
              return {
                ...state,
                recurringOnDays: state.recurringOnDays.filter(d => d !== action.payload.days),
              };
            default:
              return state;
          }
      }
    },
    changeRecurringEndDate: (state, action: PayloadAction<string>) => {
      state.recurringEndDate = action.payload;
    },
    changeRecurringEndType: (state, action: PayloadAction<ERecurringEnd>) => {
      state.recurringRepetitions =
        action.payload === ERecurringEnd.After
          ? state.recurringRepetitions === null
            ? 1
            : state.recurringRepetitions
          : null;
      state.recurringEndDate =
        action.payload === ERecurringEnd.After
          ? null
          : state.recurringEndDate
            ? setToMidnight(new Date(state.recurringEndDate))
            : setToMidnight(new Date(state.startTime));
    },
    changeRecurringEveryXMonths: (state, action: PayloadAction<number>) => {
      state.recurringEveryXMonths = action.payload;
    },
    changeRecurringEveryXWeeks: (state, action: PayloadAction<number>) => {
      state.recurringEveryXWeeks = action.payload;
    },
    changeRecurringFrequency: (state, action: PayloadAction<ERecurringFrequency>) => {
      switch (action.payload) {
        case ERecurringFrequency.Daily:
          return {
            ...state,
            recurringInterval: ERecurringFrequency.Daily,
            recurringWeekdaysOnly: false,
            recurringEveryXWeeks: null,
            recurringEveryXMonths: null,
            recurringOnXWeekOfMonth: null,
            recurringOnDays: [EDays.MO],
          };
        case ERecurringFrequency.Weekly:
          return {
            ...state,
            recurringInterval: ERecurringFrequency.Weekly,
            recurringWeekdaysOnly: null,
            recurringEveryXWeeks: 1,
            recurringEveryXMonths: null,
            recurringOnXWeekOfMonth: null,
            recurringOnDays: [getRecurringStartDay(state.startTime)],
          };
        case ERecurringFrequency.Monthly:
          return {
            ...state,
            recurringInterval: ERecurringFrequency.Monthly,
            recurringWeekdaysOnly: null,
            recurringEveryXWeeks: null,
            recurringEveryXMonths: 1,
            recurringOnXWeekOfMonth: EWeeklyOccurrence.FIRST,
            recurringOnDays: [getRecurringStartDay(state.startTime)],
          };
        default:
          return state;
      }
    },
    changeRecurringOnXWeekOfMonth: (state, action: PayloadAction<EWeeklyOccurrence>) => {
      state.recurringOnXWeekOfMonth = action.payload;
    },
    changeRecurringRepetitions: (state, action: PayloadAction<number>) => {
      state.recurringRepetitions = action.payload;
    },
    changeStartTime: (state, action: PayloadAction<Date>) => {
      state.startTime = action.payload;
    },
    changeSubject: (state, action: PayloadAction<string>) => {
      state.subject = action.payload;
    },
    loadForm: (_state, action: PayloadAction<Partial<IMutableSession>>) => {
      return {
        ...action.payload,
        startTime: new Date(action.payload.startTime),
        endTime: new Date(action.payload.endTime),
      };
    },
    resetForm: () => {
      return initialSessionFormState;
    },
    toggleIsApplicationPreferred: (state, action: PayloadAction<boolean>) => {
      state.applicationPreferred = action.payload;
    },
    toggleIsPINProtected: (state, action: PayloadAction<boolean>) => {
      state.pinProtected = action.payload;
    },
    toggleIsPrivate: (state, action: PayloadAction<boolean>) => {
      state.isPrivate = action.payload;
    },
    toggleIsRecorded: (state, action: PayloadAction<boolean>) => {
      state.applicationPreferred = !action.payload ? false : state.applicationPreferred;
      state.isRecorded = action.payload;
    },
    toggleIsRecurring: state => {
      // update the recurringInterval first based on the previous (current) recurringEndDate
      state.recurringInterval = state.recurringEndDate === null ? ERecurringFrequency.Daily : null;
      state.nextRecurringEnd = null;
      state.nextRecurringStart = null;
      state.recurringDates = [];
      state.recurringEndDate = null;
      state.recurringEveryXMonths = null;
      state.recurringEveryXWeeks = null;
      state.recurringOnDays = [];
      state.recurringOnXWeekOfMonth = null;
      state.recurringRepetitions = null;
      state.recurringWeekdaysOnly = false;
    },
    toggleIsWeekdaysOnly: (state, action: PayloadAction<boolean>) => {
      state.recurringWeekdaysOnly = action.payload;
    },
  },
});

export const {
  changeDescription,
  changeDialoutIDs,
  changeEndTime,
  changeParticipants,
  changePIN,
  changeRecurringDay,
  changeRecurringEndDate,
  changeRecurringEndType,
  changeRecurringEveryXMonths,
  changeRecurringEveryXWeeks,
  changeRecurringFrequency,
  changeRecurringOnXWeekOfMonth,
  changeRecurringRepetitions,
  changeStartTime,
  changeSubject,
  loadForm,
  resetForm,
  toggleIsApplicationPreferred,
  toggleIsPINProtected,
  toggleIsPrivate,
  toggleIsRecorded,
  toggleIsRecurring,
  toggleIsWeekdaysOnly,
} = sessionFormSlice.actions;

export const sessionFormReducer = sessionFormSlice.reducer;
