import { isNowAfterDate, isNowBetweenStartAndEnd, isNowOneHourFromStart } from "packages/utils";

export function canJoinSession(
  startTime: string,
  endTime: string,
  canModerate?: boolean,
  nextRecurringStart?: string,
  nextRecurringEnd?: string,
) {
  const currentEndTime = new Date(nextRecurringEnd || endTime);
  const currentStartTime = new Date(nextRecurringStart || startTime);

  return (
    (!isNowAfterDate(currentStartTime) &&
      canModerate &&
      (isNowOneHourFromStart(currentStartTime) || isNowBetweenStartAndEnd(currentStartTime, currentEndTime))) ||
    isNowBetweenStartAndEnd(currentStartTime, currentEndTime)
  );
}
