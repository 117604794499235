import { HEADER_JSON } from "packages/utils";

import { fetchDjangoAPI, fetchNodeAPI } from "packages/client/fetch";

export async function authenticateGuest(roomKey: string, guestName: string) {
  const resp = await fetchDjangoAPI("/users/guest-login/", {
    body: JSON.stringify({
      guest_name: guestName,
      room_key: roomKey,
    }),
    headers: HEADER_JSON,
    method: "POST",
  });
  if (resp.status !== 201) throw new Error(resp.statusText);
  const data: { token: string } = await resp.json();
  return data.token;
}

export async function authenticateUser(email: string, password: string, code?: string) {
  const resp = await fetchDjangoAPI("/users/login/", {
    body: JSON.stringify({ email, password, code }),
    headers: HEADER_JSON,
    method: "POST",
  });

  // we're going to throw the whole response, so we can check if it is indeed a server response that comes back (for handling network errors)
  if (!resp.ok) throw resp;
  else return resp.json() as Promise<{ token: string }>;
}

export async function refreshToken(token: string) {
  const resp = await fetchNodeAPI("/sso/token/refresh/", {
    body: JSON.stringify({ token }),
    headers: HEADER_JSON,
    method: "POST",
  });
  if (resp.status !== 200) throw new Error(resp.statusText);
  return resp.json() as Promise<{ token: string }>;
}

export function requestResetPasswordCode(email: string) {
  return fetchDjangoAPI(`/users/reset-password/v2/${email}/`, { headers: HEADER_JSON });
}

export function resetForgottenPassword(email: string, code: string, newPassword: string, confirmPassword: string) {
  return fetchDjangoAPI(`/users/reset-password/v2/confirm/${email}/${code}/`, {
    body: JSON.stringify({ new_password: newPassword, confirm_password: confirmPassword }),
    headers: HEADER_JSON,
    method: "PATCH",
  });
}

export function verifyResetPasswordCode(email: string, code: string) {
  return fetchDjangoAPI(`/users/reset-password/v2/confirm/${email}/${code}/`, { headers: HEADER_JSON });
}

export async function verifyToken(token: string) {
  const response = await fetchDjangoAPI("/users/token-verify/", {
    body: JSON.stringify({
      token,
    }),
    headers: HEADER_JSON,
    method: "POST",
  });
  return response.status === 200;
}
