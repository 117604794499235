import { EStatus } from "packages/utils";
import { useCallback, useReducer } from "react";

import type { ISnackbarFeedbackContext } from "packages/client/snackbar-feedback/contexts";

import { ESnackbarFeedbackActions, ISnackbar } from "packages/client/snackbar-feedback/redux/types";
import {
  initialSnackbarFeedbackState,
  snackbarFeedbackReducer,
} from "packages/client/snackbar-feedback/redux/reducers";

export function useSnackbarFeedback(): ISnackbarFeedbackContext {
  const [state, dispatch] = useReducer(snackbarFeedbackReducer, initialSnackbarFeedbackState);

  const handleDismiss = useCallback(
    (id: string) => {
      dispatch({
        id: id,
        type: ESnackbarFeedbackActions.RemoveSnackbar,
      });
    },
    [dispatch],
  );

  const dispatchSnackbar = useCallback(
    (snackbar: ISnackbar) =>
      dispatch({
        data: snackbar,
        type: ESnackbarFeedbackActions.AddSnackbar,
      }),
    [],
  );

  const dispatchLinkSnackbar = useCallback((content: string, linkContent: string, to: string) => {
    dispatch({
      type: ESnackbarFeedbackActions.AddSnackbar,
      data: {
        content: content,
        actions: [
          {
            content: linkContent,
            props: {
              as: "Link",
              status: EStatus.ACCENT,
              to: to,
            },
          },
        ],
      },
    });
  }, []);

  const dispatchRetrySnackbar = useCallback(
    (content: string, ontrigger: () => void) =>
      dispatch({
        type: ESnackbarFeedbackActions.AddSnackbar,
        data: {
          content: content,
          actions: [
            {
              onTrigger: ontrigger,
              content: "Retry",
              props: {
                as: "button",
                status: EStatus.ACCENT,
              },
            },
          ],
        },
      }),
    [],
  );

  return {
    dismissSnackbar: handleDismiss,
    dispatchLinkSnackbar,
    dispatchRetrySnackbar,
    dispatchSnackbar,
    snackbarFeedbackDispatch: dispatch,
    snackbars: state?.snackbars,
  };
}
