import { useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Accordion,
  Block,
  Cell,
  EditableFieldText,
  EditableFieldSelect,
  Fieldset,
  Flexbox,
  Grid,
  H2,
  IconButton,
  InternalTextLink,
  LabelledContent,
  Loader,
  StatusBadge,
  Toggle,
  ToggleOption,
} from "packages/catalog";
import {
  convertStringEnumToDropdownObject,
  COUNTRIES_OPTIONS,
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  PATH_SUPERADMIN_ORGANISATION,
  PATH_SUPERADMIN_ORGANISATION_REPORT,
  PATH_SUPERADMIN_ORGANISATIONS_DELETE,
  TParams,
} from "packages/utils";

import {
  OrganisationPricingPlan,
  OrganisationSector,
  OrganisationType,
} from "packages/client/graphql_definitions.generated";

import { useGetFullOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";
import { editOrganisation } from "packages/client/organisations/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { OrganisationLogo } from "packages/client/organisations/components";

const PRICING_PLAN_OPTIONS = convertStringEnumToDropdownObject(OrganisationPricingPlan);
const SECTOR_OPTIONS = convertStringEnumToDropdownObject(OrganisationSector);

export function SuperAdminOrganisationOverview() {
  const { organisationID }: TParams = useParams();

  const {
    data: organisationData,
    loading,
    refetch,
  } = useGetFullOrganisationQuery({
    variables: {
      organisationID: parseInt(organisationID, 10),
    },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const handleSaveField = useCallback(
    (name: keyof OrganisationType, value: string | boolean) => {
      editOrganisation(organisationID, { [name]: value }).then(() => refetch());
    },
    [organisationID, refetch],
  );

  if (!organisationData) return null;
  if (loading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler message="The organisation overview page has loaded." pageTitle="Organisation overview" />
      <Grid>
        <Cell colSpanMD={9}>
          <Grid>
            <Cell>
              <H2 hasMargin>Overview</H2>
              <Grid>
                <Cell center colSpan={3}>
                  <OrganisationLogo
                    dimension={EDimension.EXTRA_LARGE}
                    organisationID={organisationData?.organisation.id}
                  />
                </Cell>
                <Cell as={Grid} colSpan={9}>
                  <Cell>
                    <EditableFieldText
                      data={organisationData?.organisation.name}
                      label="Name"
                      name="name"
                      onSave={handleSaveField}
                    />
                  </Cell>
                  <Cell colSpan={6}>
                    <LabelledContent
                      label="Registration status"
                      content={
                        <StatusBadge
                          status={organisationData?.organisation.isApproved ? EStatus.SUCCESS : EStatus.DANGER}>
                          {organisationData?.organisation.isApproved ? "Approved" : "Not approved"}
                        </StatusBadge>
                      }
                    />
                  </Cell>
                  <Cell colSpan={6}>
                    <LabelledContent label="ID" content={organisationData?.organisation.id} />
                  </Cell>
                  <Cell colSpan={6}>
                    <LabelledContent
                      label="Parent organisation"
                      content={
                        organisationData?.organisation.parentOrganisation ? (
                          <InternalTextLink
                            to={PATH_SUPERADMIN_ORGANISATION(organisationData?.organisation.parentOrganisation.id)}>
                            {organisationData?.organisation.parentOrganisation.name}
                          </InternalTextLink>
                        ) : (
                          "N/A"
                        )
                      }
                    />
                  </Cell>
                  <Cell colSpan={6}>
                    <EditableFieldSelect
                      label="Sector"
                      name="sector"
                      onSave={handleSaveField}
                      options={SECTOR_OPTIONS}
                      value={organisationData?.organisation.sector}
                    />
                  </Cell>
                  <Cell colSpan={6}>
                    <EditableFieldSelect
                      label="Pricing plan"
                      name="pricingPlan"
                      options={PRICING_PLAN_OPTIONS}
                      onSave={handleSaveField}
                      value={organisationData?.organisation.pricingPlan}
                    />
                  </Cell>
                </Cell>
              </Grid>
            </Cell>
            <Cell as={Block} colSpanMD={6} colSpanXL={4}>
              <H2 hasMargin>Settings</H2>
              <Grid noColGap>
                <Cell>
                  <Fieldset>
                    <ToggleOption label="Custom logo enabled">
                      <Toggle
                        checked={organisationData?.organisation?.rebranding ?? false}
                        name="logo"
                        onChange={e => handleSaveField("rebranding", e.target.checked)}
                      />
                    </ToggleOption>
                    <ToggleOption label="Private invitations">
                      <Toggle
                        checked={organisationData?.organisation?.privateInvites ?? false}
                        name="private-invitations"
                        onChange={e => handleSaveField("privateInvites", e.target.checked)}
                      />
                    </ToggleOption>
                    <ToggleOption label="Recording disabled">
                      <Toggle
                        checked={organisationData?.organisation?.recordingDisabled ?? false}
                        name="recording-disabled"
                        onChange={e => handleSaveField("recordingDisabled", e.target.checked)}
                      />
                    </ToggleOption>
                    <ToggleOption label="Hide collaboration">
                      <Toggle
                        checked={organisationData?.organisation?.hideCollaboration ?? false}
                        name="hide-collaboration"
                        onChange={e => handleSaveField("hideCollaboration", e.target.checked)}
                      />
                    </ToggleOption>
                  </Fieldset>
                </Cell>
              </Grid>
            </Cell>
            <Cell as={Block} colSpanMD={6} colSpanXL={4}>
              <H2 hasMargin>Features</H2>
              <Grid>
                <Cell>
                  <LabelledContent
                    label="Operator support"
                    content={organisationData?.organisation.operatorSupport ? "Yes" : "No"}
                  />
                </Cell>
                <Cell>
                  <LabelledContent
                    label="Bulk user import"
                    content={organisationData?.organisation.bulkUserImport ? "Yes" : "No"}
                  />
                </Cell>
              </Grid>
            </Cell>
            <Cell as={Block} colSpanMD={6} colSpanXL={4}>
              <H2 hasMargin>Limits</H2>
              <Grid>
                <Cell>
                  <LabelledContent
                    label="Maximum number of regular accounts"
                    content={
                      organisationData?.organisation.maxHostAccounts
                        ? organisationData?.organisation.maxHostAccounts
                        : "N/A"
                    }
                  />
                </Cell>
                <Cell>
                  <LabelledContent
                    label="Maximum number of admin accounts"
                    content={
                      organisationData?.organisation.maxAdminAccounts
                        ? organisationData?.organisation.maxAdminAccounts
                        : "N/A"
                    }
                  />
                </Cell>
                <Cell>
                  <LabelledContent
                    label="Maximum number of incall guests"
                    content={
                      organisationData?.organisation.maxGuests ? organisationData?.organisation.maxGuests : "N/A"
                    }
                  />
                </Cell>
                <Cell>
                  <LabelledContent
                    label="Maximum number of SIP connections"
                    content={
                      organisationData?.organisation.maxSipConnections
                        ? organisationData?.organisation.maxSipConnections
                        : "N/A"
                    }
                  />
                </Cell>
              </Grid>
            </Cell>
            <Cell>
              <Accordion heading={<H2>Profile</H2>}>
                <Grid>
                  <Cell colSpan={6}>
                    <Grid>
                      <Cell>
                        <EditableFieldText
                          data={organisationData?.organisation.about}
                          label="About"
                          name="about"
                          onSave={handleSaveField}
                        />
                      </Cell>
                      <Cell>
                        <EditableFieldText
                          data={organisationData?.organisation.domain}
                          label="Website"
                          name="domain"
                          onSave={handleSaveField}
                        />
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell colSpan={6}>
                    <Grid>
                      <Cell>
                        <EditableFieldText
                          data={organisationData?.organisation.address}
                          label="Street"
                          name="address"
                          onSave={handleSaveField}
                        />
                      </Cell>
                      <Cell>
                        <EditableFieldText
                          data={organisationData?.organisation.city}
                          label="City"
                          name="city"
                          onSave={handleSaveField}
                        />
                      </Cell>
                      <Cell>
                        <EditableFieldText
                          data={organisationData?.organisation.county}
                          label="County"
                          name="county"
                          onSave={handleSaveField}
                        />
                      </Cell>
                      <Cell>
                        <EditableFieldText
                          data={organisationData?.organisation.region}
                          label="Region"
                          name="region"
                          onSave={handleSaveField}
                        />
                      </Cell>
                      <Cell>
                        <EditableFieldSelect
                          label="Country"
                          name="country"
                          options={COUNTRIES_OPTIONS}
                          onSave={handleSaveField}
                          value={organisationData?.organisation.country}
                        />
                      </Cell>
                      <Cell>
                        <EditableFieldText
                          data={organisationData?.organisation.postcode}
                          label="Postcode"
                          name="postcode"
                          onSave={handleSaveField}
                        />
                      </Cell>
                    </Grid>
                  </Cell>
                </Grid>
              </Accordion>
            </Cell>
          </Grid>
        </Cell>
        <Cell colSpanMD={3}>
          <H2 hasMargin>Tools</H2>
          <Flexbox as="ul" flexDirectionColumn hasDoubleGap>
            <li>
              <IconButton
                appearance={EAppearance.OUTLINE}
                as={Link}
                description="Generate report"
                dimension={EDimension.SMALL}
                icon={EIcon.FILETEXT}
                label="Generate report"
                status={EStatus.NEUTRAL}
                to={PATH_SUPERADMIN_ORGANISATION_REPORT(organisationID)}
              />
            </li>
            <li>
              <IconButton
                appearance={EAppearance.OUTLINE}
                as={Link}
                description="Delete organisation"
                dimension={EDimension.SMALL}
                icon={EIcon.TRASH2}
                label="Delete organisation"
                state={{ organisationID }}
                status={EStatus.DANGER}
                to={PATH_SUPERADMIN_ORGANISATIONS_DELETE}
              />
            </li>
          </Flexbox>
        </Cell>
      </Grid>
    </>
  );
}
