import type { HTMLProps } from "react";

import { Flexbox, NavigationLink } from "packages/catalog";

export interface PNavigationList extends HTMLProps<HTMLUListElement> {
  children: OneOrManyReactChildren<typeof NavigationLink>;
  flexDirectionColumn?: boolean;
  flexDirectionColumnLG?: boolean;
  fullHeight?: boolean;
  hasDoubleGap?: boolean;
  justifyContentCenter?: boolean;
}

export function NavigationList({
  children,
  flexDirectionColumn,
  fullHeight,
  hasDoubleGap,
  flexDirectionColumnLG,
  justifyContentCenter,
}: PNavigationList) {
  return (
    <Flexbox
      alignItemsCenter
      alignItemsFlexStart={flexDirectionColumn || flexDirectionColumnLG}
      as="ul"
      flexDirectionColumn={flexDirectionColumn}
      flexDirectionColumnLG={flexDirectionColumnLG}
      flexWrapWrap={!flexDirectionColumn || !flexDirectionColumnLG}
      fullHeight={fullHeight}
      hasDoubleGap={hasDoubleGap}
      justifyContentCenter={justifyContentCenter}>
      {children}
    </Flexbox>
  );
}
