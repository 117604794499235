import { Accordion, Cell, Fieldset, Input, InputOption } from "packages/catalog";

import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import { toggleIsApplicationPreferred, toggleIsPrivate, toggleIsRecorded } from "packages/client/sessions/redux/slice";

import { useIsRecordingDisabled } from "packages/client/organisations/hooks";

import { EditPIN } from "packages/client/sessions/components";

export function EditFeatures() {
  const dispatch = useTypedDispatch();
  const { applicationPreferred, isPrivate, isRecorded } = useTypedSelector(({ sessionForm }) => ({
    applicationPreferred: sessionForm.applicationPreferred,
    isPrivate: sessionForm.isPrivate,
    isRecorded: sessionForm.isRecorded,
  }));

  const { isRecordingDisabled } = useIsRecordingDisabled();

  return (
    <>
      <EditPIN />
      <Cell>
        <Accordion heading="Optional settings">
          <Fieldset>
            {!isRecordingDisabled && (
              <>
                <InputOption
                  description="Will be saved automatically to your Recordings. Recording will stop if nobody joins the call."
                  label="Record call">
                  <Input
                    checked={isRecorded}
                    name="record"
                    onChange={() => dispatch(toggleIsRecorded(!isRecorded))}
                    type="checkbox"
                  />
                </InputOption>
                {isRecorded && (
                  <InputOption
                    description={
                      "Otherwise, participants' video feeds will be included in the recording during screenshare"
                    }
                    label="Record screenshared content in full-screen">
                    <Input
                      checked={applicationPreferred}
                      name="record-fullscreen"
                      onChange={() => dispatch(toggleIsApplicationPreferred(!applicationPreferred))}
                      type="checkbox"
                    />
                  </InputOption>
                )}
              </>
            )}
            {/* <InputOption
                description="Participants joining via browser will held in a virtual waiting room until an admin/moderator joins the call"
                label="Waiting room (browser ONLY)">
                <Input
                  checked={waitingRoomEnabled}
                  onChange={() => dispatch(updateWaitingRoomEnabled(!waitingRoomEnabled))}
                  type="checkbox"
                />
              </InputOption> */}
            {/* {!isSchoolsPlan && (
              <InputOption
                description={
                  webcastExpired
                    ? "Available if the meeting starts in more than 15 minutes"
                    : "Participants will receive a link to the livestream via email"
                }
                label="Livestream the meeting">
                <Input
                  checked={isWebcastMode}
                  disabled={webcastExpired}
                  onChange={() => dispatch(updateWebcastMode(!isWebcastMode))}
                  type="checkbox"
                />
              </InputOption>
            )} */}
            <InputOption
              description="Event is only visible to participants in their own calendars"
              label="Keep calendar event private">
              <Input
                checked={isPrivate}
                name="private-calendar"
                onChange={() => dispatch(toggleIsPrivate(!isPrivate))}
                type="checkbox"
              />
            </InputOption>
          </Fieldset>
        </Accordion>
      </Cell>
    </>
  );
}
