import { useMemo, Suspense, lazy } from "react";

const DarkTheme = lazy(() => import("./DarkTheme"));
import LightTheme from "./LightTheme";
import { ETheme } from "../types";

import "@fontsource/quicksand/500.css";
import "@fontsource/quicksand/600.css";
import "@fontsource/quicksand/700.css";

import "../styles/styles.scss";

export interface PThemeProvider {
  theme: ETheme;
}

export function ThemeProvider({ theme }: PThemeProvider) {
  const content = useMemo(() => {
    switch (theme) {
      case ETheme.DARK:
        return <DarkTheme />;
      default:
        return <LightTheme />;
    }
  }, [theme]);
  return <Suspense fallback={<LightTheme />}>{content}</Suspense>;
}
