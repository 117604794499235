import type { PropsWithChildren } from "react";
import { Provider } from "react-redux";

import { VidyoContext, instanceVidyoRootStore } from "../../";

// eslint-disable-next-line @typescript-eslint/ban-types
export function VidyoReduxProvider({ children }: PropsWithChildren<{}>) {
  return (
    <Provider context={VidyoContext} store={instanceVidyoRootStore}>
      {children}
    </Provider>
  );
}
