import { HTMLAttributes, useEffect, useRef } from "react";

import styles from "./SidebarHeader.module.scss";

export function SidebarHeader({ children }: HTMLAttributes<HTMLElement>) {
  const headerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!headerRef.current) return;

    headerRef.current.focus();
  }, []);

  return (
    <header className={styles.sidebarHeader} ref={headerRef} tabIndex={-1}>
      {children}
    </header>
  );
}
