// schedule meeting change event handler to prevent NaNs or zeros from being submitted

export function sanitiseRepetitions(repetitions: string): number {
  try {
    const input = parseInt(repetitions);
    return input === 0 || isNaN(input) ? 1 : input;
  } catch {
    1;
  }
}
