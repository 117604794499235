import { ButtonHTMLAttributes, ComponentType, MouseEvent, ReactNode, useCallback, useMemo } from "react";
import type { LinkProps } from "react-router-dom";

import { EAppearance, ILocationState, EStatus, useAppearanceStyle, useStatusStyle } from "packages/utils";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "../Buttons.module.scss";

export interface PTextButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: EAppearance;
  as?: string | ComponentType<{ title?: string }>;
  children: ReactNode;
  href?: string;
  rel?: string;
  state?: ILocationState;
  status?: EStatus;
  target?: string;
  to?: string | LinkProps["to"];
}

export function TextButton({
  appearance = EAppearance.FILL,
  as: Component = "button",
  children,
  status = EStatus.NEUTRAL,
  ...rest
}: PTextButton) {
  const appearanceStyle = useAppearanceStyle(styles);
  const statusStyle = useStatusStyle(styles);

  const classNames = useMemo(
    () => [styles.textButton, appearanceStyle[appearance], statusStyle[status]].join(" "),
    [appearance, appearanceStyle, status, statusStyle],
  );

  // prevent new window to appear on wheelclick electron
  const handleAuxClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (event.button === 1) {
      event.preventDefault();
    }
  }, []);

  return (
    <Component className={classNames} onAuxClick={handleAuxClick} {...rest}>
      {children}
    </Component>
  );
}
