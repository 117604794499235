import { useState } from "react";

import { Flexbox, IconButton, P, NewSelectList, Strong } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

import type { TMutableUser } from "packages/client/sessions/graphql/types";

import { useGetAllUsersQuery, useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { UserAvatar, UserSummary } from "packages/client/users/components";

import styles from "./EditUsers.module.scss";

export interface PEditUsers {
  description?: string;
  ignored: TMutableUser[];
  label: string;
  onSelect: (sel: TMutableUser[]) => void;
  preSelected: TMutableUser[];
}

export function EditUsers({ description, ignored, label, onSelect, preSelected }: PEditUsers) {
  const [searchString, setSearchString] = useState("");

  const { data: ownUserData, loading: ownUserLoading } = useGetEssentialOwnUserQuery();
  const { data: allUsersData, loading: allUsersLoading } = useGetAllUsersQuery({
    variables: { page: 1, search: searchString },
  });

  return (
    <NewSelectList
      closeIfNoInput
      data={allUsersData?.allUsers?.objects ?? []}
      description={description}
      ignored={ownUserData?.user ? [...ignored, ownUserData?.user] : ignored}
      isLoading={allUsersLoading || ownUserLoading}
      label={label}
      onSearch={setSearchString}
      onSelect={onSelect}
      preSelected={preSelected}
      renderDataAs={(data, addItem) => (
        <>
          {data?.map(u => (
            <button className={styles.item} key={u.id} onClick={() => addItem(u)} type="button">
              <Flexbox alignItemsCenter>
                <UserAvatar
                  dimension={EDimension.SMALL}
                  email={u.email}
                  fullName={`${u.firstName} ${u.lastName}`}
                  profilePictureURL={u.profilePicture}
                />
                <div>
                  <P>
                    <Strong>
                      {u.firstName} {u.lastName}
                    </Strong>
                  </P>
                  <P>{u.email}</P>
                </div>
              </Flexbox>
            </button>
          ))}
        </>
      )}
      renderSelectedAs={(selected, removeItem) => {
        return ownUserLoading ? null : (
          <Flexbox as="ul" className={styles.results} flexDirectionColumn>
            {(ownUserData ? selected.filter(s => s.id !== ownUserData?.user?.id) : selected).map(
              selectedParticipant => (
                <Flexbox as="li" key={selectedParticipant.id} justifyContentSpaceBetween>
                  <UserSummary
                    email={selectedParticipant.email}
                    fullName={`${selectedParticipant.firstName} ${selectedParticipant.lastName}`}
                    profilePictureURL={selectedParticipant.profilePicture}
                  />
                  <IconButton
                    appearance={EAppearance.GHOST}
                    as="button"
                    description={`Remove ${selectedParticipant.firstName} ${selectedParticipant.lastName}`}
                    dimension={EDimension.SMALL}
                    icon={EIcon.X}
                    onClick={() => removeItem(selectedParticipant.id)}
                    status={EStatus.DANGER}
                  />
                </Flexbox>
              ),
            )}
          </Flexbox>
        );
      }}
      searchString={searchString}
    />
  );
}
