export enum Screens {
  ChooseOrg,
  JoinOrCreate,
  CreateOrgStep1,
  CreateOrgStep2,
  CreateOrgStep3,
  Review,
  Success,
  User,
}
