import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";

import { Cell, Field, FormActionModal, Grid, Input, InputOption, P } from "packages/catalog";

export interface PHubModalPIN {
  currentPIN: number | null;
  handleSetPIN: (newPin: number | null) => void;
  isOpen: boolean;
  onAbort: () => void;
}

export function HubModalPIN({ currentPIN, handleSetPIN, isOpen, onAbort }: PHubModalPIN) {
  const [requirePIN, setRequirePIN] = useState<boolean>(false);
  const [pin, setPIN] = useState<string>("");

  useEffect(() => {
    if (currentPIN) {
      setPIN(`${currentPIN}`);
      setRequirePIN(true);
    }
  }, [currentPIN]);

  const handleRequirePINChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRequirePIN(e.currentTarget.checked);
  }, []);

  const handlePINChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const pin = e.currentTarget.value;
    if (/^\d+$/.test(pin) || pin === "") setPIN(pin);
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (requirePIN) {
        handleSetPIN(parseInt(pin));
      } else {
        handleSetPIN(null);
      }
    },
    [handleSetPIN, pin, requirePIN],
  );

  return (
    <FormActionModal
      ctaDisabled={requirePIN && pin.length < 4}
      ctaText="Save"
      heading="PIN settings"
      isOpen={isOpen}
      onAbort={onAbort}
      onAction={handleSubmit}>
      <P hasMargin>
        You can now set a 4-digit PIN to protect your hub. Once set, all participants will be asked to provide the PIN
        before entering your hub.
      </P>
      <Grid noColGap>
        <Cell>
          <InputOption label="Require PIN">
            <Input checked={requirePIN} name="require-pin" onChange={handleRequirePINChange} type="checkbox" />
          </InputOption>
        </Cell>
        {requirePIN && (
          <Cell>
            <Field label="PIN">
              <Input
                disabled={!requirePIN}
                inputMode="numeric"
                maxLength={4}
                minLength={4}
                name="pin"
                onChange={handlePINChange}
                pattern="^[0-9]*$"
                required={requirePIN}
                type="text"
                value={pin}
              />
            </Field>
          </Cell>
        )}
      </Grid>
    </FormActionModal>
  );
}
