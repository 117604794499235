const getLocalLanguage = () => navigator?.languages?.[0] ?? navigator?.language ?? "en-GB";

const CompleteDateFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  month: "numeric",
  year: "numeric",
});

const ShortDateFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const LongDateFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  day: "2-digit",
  month: "long",
  year: "numeric",
});

const LongWordDateFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  weekday: "long",
  day: "2-digit",
  month: "long",
  year: "numeric",
});

const ShortWordDateFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  weekday: "short",
  day: "2-digit",
  month: "short",
});

const LongTimeFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
});

const ExtraLongTimeFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false,
});

// UTC because we are receiving UTC from the backend
const DurationFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  timeZone: "UTC",
});

const MinuteSecondFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  minute: "2-digit",
  second: "2-digit",
  timeZone: "UTC",
});

const WeekDayFormatter = new Intl.DateTimeFormat(getLocalLanguage(), {
  weekday: "long",
});

// TODO: think of better descriptive names for these; merge / bring in legacy versions
export const formatCompleteDate = (d: Date) => CompleteDateFormatter.format(d); // 19/06/1978, 09:00
export const formatShortDate = (d: Date) => ShortDateFormatter.format(d); // 18/06/1978
export const formatISOShortDate = (d: Date) => d.toISOString().slice(0, 10); // 1978-06-19
export const formatLongDate = (d: Date) => LongDateFormatter.format(d); // 19 June 1978
export const formatLongWordDate = (d: Date) => LongWordDateFormatter.format(d); // Monday, 19 June 1978
export const formatShortWordDate = (d: Date) => ShortWordDateFormatter.format(d); // Mon, 19 Jun 1978
export const formatLongTime = (d: Date) => LongTimeFormatter.format(d); // 09:00
export const formatExtraLongTime = (d: Date) => ExtraLongTimeFormatter.format(d); // 09:00:03
export const formatWeekday = (d: Date) => WeekDayFormatter.format(d); // Monday

export const getDateFromUnixNano = (nano: string) => {
  return new Date(parseInt(nano.slice(0, -6), 10));
};

export const getDuration = (d: Date) => {
  const dDays = Math.floor(d.getTime() / 1000 / 60 / 60 / 24);
  const hours = Math.floor(d.getTime() / 1000 / 60 / 60);
  if (dDays > 0) return `${dDays} day${dDays > 1 ? "s" : ""}`;
  if (hours > 0) return DurationFormatter.format(d);
  else return MinuteSecondFormatter.format(d);
};

export const getWeekOfDate = (d: Date) => {
  // Just in case: https://weeknumber.net/how-to/javascript
  const date = new Date(d.getTime());
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  const year = date.getFullYear();
  const week1 = new Date(year, 0, 4);
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
};
