export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export function scrollToContentTop() {
  document.getElementById("content").scrollTo(0, 0);
}
