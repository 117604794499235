import { createContext, ReactNode, useContext } from "react";

import { useRaisehand, IUseRaisehand } from "packages/client/incall/hooks";

export interface PRaisehandContext {
  children: ReactNode;
}

function noop() {
  //nothing
}

const RaisehandStore = createContext<IUseRaisehand>({
  raisedHands: [],
  setRaisedHand: noop,
  unRaiseUserHand: noop,
});

export function RaisehandContext({ children }: PRaisehandContext) {
  const data = useRaisehand();
  return <RaisehandStore.Provider value={data}>{children}</RaisehandStore.Provider>;
}

export function useRaisehandContext() {
  return useContext(RaisehandStore);
}
