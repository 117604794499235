import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Cell,
  Feedback,
  Field,
  Grid,
  H1,
  Illustration,
  Input,
  P,
  PasswordValidation,
  Strong,
  TextButton,
} from "packages/catalog";
import { EAppearance, EIllustration, TParams, PATH_SIGNIN, EStatus } from "packages/utils";

import { useResetPassword } from "packages/client/authentication/hooks";

import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./ResetPassword.module.scss";

export function ResetPassword() {
  const { callID }: TParams = useParams();

  const [code, setCode] = useState("");
  const [isNotMatchingErrorDisplayed, setIsNotMatchingErrorDisplayed] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [password, setPassword] = useState("");
  const [reEnteredPassword, setReEnteredPassword] = useState("");

  const {
    confirmCode,
    isCodeConfirmed,
    isPasswordReset,
    passwordResetError,
    requestPasswordReset,
    setPasswordResetError,
    submitNewPassword,
  } = useResetPassword();

  const handleChangeCode = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (/^\d+$/.test(e.currentTarget.value) || e.currentTarget.value === "") {
      setCode(e.currentTarget.value);
    }
  }, []);

  const handleCodeForResetPassword = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      confirmCode(code);
    },
    [confirmCode, code],
  );

  const handleResendEmail = useCallback(() => {
    requestPasswordReset();
    setPasswordResetError(null);
    setCode("");
  }, [requestPasswordReset, setPasswordResetError]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsNotMatchingErrorDisplayed(password !== reEnteredPassword);
      if (password === reEnteredPassword) {
        submitNewPassword(password, reEnteredPassword);
      }
    },
    [password, reEnteredPassword, submitNewPassword],
  );

  if (isPasswordReset) {
    return (
      <>
        <RoutingChangeHandler message="The reset password success page has loaded." pageTitle="Password reset" />
        <Grid>
          <Cell>
            <H1 center>Success</H1>
          </Cell>
          <Cell>
            <Illustration className={styles.illustration} illustration={EIllustration.CELEBRATION} />
          </Cell>
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Password reset!</Feedback>
          </Cell>
          <Cell center>
            <TextButton status={EStatus.ACCENT} appearance={EAppearance.FILL} as={Link} to={PATH_SIGNIN(callID)}>
              Sign in
            </TextButton>
          </Cell>
        </Grid>
      </>
    );
  }

  if (isCodeConfirmed) {
    return (
      <>
        <RoutingChangeHandler message="The create new password page has loaded." pageTitle="Create new password" />
        <H1 center hasMargin>
          Create new password
        </H1>
        <Grid as="form" onSubmit={handleSubmit} noColGap>
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Identify verified!</Feedback>
          </Cell>
          <Cell>
            <P>Please create a new password.</P>
          </Cell>
          <PasswordValidation
            isNotMatchingErrorDisplayed={isNotMatchingErrorDisplayed}
            password={password}
            passwordResetError={passwordResetError}
            reEnteredPassword={reEnteredPassword}
            setIsValid={setIsPasswordValid}
            setPassword={setPassword}
            setReEnteredPassword={setReEnteredPassword}
          />
          <Cell center>
            <Input disabled={!isPasswordValid || !reEnteredPassword} type="submit" value="Reset password" />
          </Cell>
        </Grid>
      </>
    );
  }

  return (
    <>
      <RoutingChangeHandler message="The reset password page has loaded." pageTitle="Reset password" />
      <H1 center hasMargin>
        Enter code
      </H1>
      <P hasMargin>
        To validate your identify and reset your password, please enter the <Strong>8 digit code</Strong> that you
        received by email.
      </P>
      <Grid as="form" onSubmit={handleCodeForResetPassword} noColGap>
        <Cell>
          <Field
            alerts={passwordResetError?.length && [{ message: passwordResetError, status: EStatus.DANGER }]}
            label="Code">
            <Input
              autoComplete="one-time-code"
              maxLength={8}
              name="code"
              onChange={handleChangeCode}
              required
              type="number"
              value={code}
            />
          </Field>
        </Cell>
        <Cell center>
          <Input disabled={code.length !== 8} type="submit" value="Continue" />
        </Cell>
        <Cell center>
          <TextButton
            appearance={EAppearance.GHOST}
            as="button"
            onClick={handleResendEmail}
            status={EStatus.NEUTRAL}
            type="button">
            Re-send code
          </TextButton>
        </Cell>
      </Grid>
    </>
  );
}
