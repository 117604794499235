import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Cell,
  Description,
  Feedback,
  Grid,
  H1,
  IconButton,
  Input,
  IllustratedCTA,
  Label,
  Loader,
  P,
  Pagination,
  Strong,
  Table,
  TD,
  TR,
  TRTDTemp,
} from "packages/catalog";
import {
  EAppearance,
  EDimension,
  EIcon,
  EIllustration,
  EStatus,
  PATH_ORGANISATION,
  underscoredToTitleCase,
} from "packages/utils";

import { EConnectionStatus } from "packages/client/organisations/enums";

import { useGetAllOrganisationsQuery } from "packages/client/organisations/graphql/organisations.generated";

import { useGetConnections } from "packages/client/organisations/hooks";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { IncomingConnectionRequests, OrganisationSummary } from "packages/client/organisations/components";

const COLUMNS = ["ID", "Organisation", "Sector", "Plan"];
const COLUMN_WIDTHS = "4rem 35rem repeat(auto-fit, minmax(6rem, 1fr))";

export function AllOrganisations() {
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchString, setDebounceSearchString] = useState("");
  const [searchString, setSearchString] = useState("");

  const {
    data: allOrganisationsData,
    loading,
    error,
  } = useGetAllOrganisationsQuery({
    variables: { page: currentPage, search: debouncedSearchString },
  });

  const {
    connections: { connected, incoming, outgoing },
    getData,
  } = useGetConnections();

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value);
  }, []);

  const getConnectionStatus = useCallback(
    (organisationID: string) => {
      if (connected.includes(parseInt(organisationID))) return EConnectionStatus.CONNECTED;
      if (incoming.includes(parseInt(organisationID))) return EConnectionStatus.INCOMING;
      if (outgoing.includes(parseInt(organisationID))) return EConnectionStatus.OUTGOING;
      else return null;
    },
    [connected, incoming, outgoing],
  );

  useEffect(() => {
    let cancelled = false;

    window.setTimeout(() => {
      if (!cancelled) setDebounceSearchString(searchString);
    }, 500);

    return () => {
      cancelled = true;
    };
  }, [searchString]);

  return (
    <>
      <RoutingChangeHandler message="The organisations page has loaded." pageTitle="Organisations" />
      <Grid noColGap>
        <Cell>
          <IconButton
            appearance={EAppearance.GHOST}
            as={Link}
            description="Organisation"
            dimension={EDimension.SMALL}
            icon={EIcon.ARROWLEFT}
            label="Organisation"
            status={EStatus.NEUTRAL}
            to=".."
          />
        </Cell>
        <Cell>
          <H1>All organisations</H1>
          <Description>Displaying all organisations on ClassView.</Description>
        </Cell>
        {incoming.length ? <IncomingConnectionRequests refetchConnections={getData} /> : null}
        <Cell>
          <Label htmlFor="searchOrganisations" isVisuallyHidden text="Search organisations" />
          <Input
            id="searchOrganisations"
            name="searchOrganisations"
            onChange={handleChangeSearch}
            type="search"
            value={searchString}
          />
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing all organisations on ClassView">
            <>
              {loading && (
                <TRTDTemp>
                  <Loader notFullHeight />
                </TRTDTemp>
              )}
              {error && (
                <TRTDTemp>
                  <Feedback status={EStatus.DANGER}>Unable to get list of organisations.</Feedback>
                </TRTDTemp>
              )}
              <>
                {allOrganisationsData?.allApprovedOrganisations?.objects && (
                  <>
                    {allOrganisationsData.allApprovedOrganisations.objects.map(organisation => (
                      <TR columnWidths={COLUMN_WIDTHS} key={organisation.id}>
                        <TD heading={COLUMNS[0]}>
                          <Strong>
                            <P>{organisation.id}</P>
                          </Strong>
                        </TD>
                        <TD heading={COLUMNS[1]}>
                          <Link style={{ display: "block" }} to={PATH_ORGANISATION(organisation.id)}>
                            <OrganisationSummary
                              connectionStatus={getConnectionStatus(organisation.id)}
                              organisationID={organisation.id}
                            />
                          </Link>
                        </TD>
                        <TD heading={COLUMNS[2]}>
                          <P>{underscoredToTitleCase(organisation.sector)}</P>
                        </TD>
                        <TD heading={COLUMNS[3]}>
                          <P>{underscoredToTitleCase(organisation.pricingPlan)}</P>
                        </TD>
                      </TR>
                    ))}
                  </>
                )}
                {searchString.length && !allOrganisationsData?.allApprovedOrganisations?.objects.length && !loading ? (
                  <TRTDTemp>
                    <IllustratedCTA illustration={EIllustration.PEOPLE_COLLABORATING} noPadding>
                      No organisations found.
                    </IllustratedCTA>
                  </TRTDTemp>
                ) : null}
              </>
            </>
          </Table>
        </Cell>
        {allOrganisationsData?.allApprovedOrganisations?.pages > 1 && (
          <Cell>
            <Pagination
              hasNext={allOrganisationsData?.allApprovedOrganisations?.hasNext}
              hasPrevious={allOrganisationsData?.allApprovedOrganisations?.hasPrev}
              page={allOrganisationsData?.allApprovedOrganisations?.page}
              pages={allOrganisationsData?.allApprovedOrganisations?.pages}
              setPage={setCurrentPage}
            />
          </Cell>
        )}
      </Grid>
    </>
  );
}
