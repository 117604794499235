import { createContext, ReactNode, useContext } from "react";

import { useRoom, useWhiteboard, IUseWhiteboard } from "packages/client/incall/hooks";

export interface IWhiteboardContext {
  children: ReactNode;
}

function noop() {
  //nothing
}

const WhiteboardStore = createContext<IUseWhiteboard>({
  paths: [],
  currentColour: null,
  isPencilMode: true,
  isWhiteboardClean: false,
  updateGrid: noop,
  activeGrid: null,
  canUndo: false,
  canRedo: false,
  draw: noop,
  clear: noop,
  // delete: noop,
  undo: noop,
  redo: noop,
  setColour: noop,
  setPencilMode: noop,
});

export function WhiteboardContext({ children }: IWhiteboardContext) {
  const { convID: collabKey } = useRoom();
  const data = useWhiteboard(collabKey);
  return <WhiteboardStore.Provider value={data}>{children}</WhiteboardStore.Provider>;
}

export function useWhiteboardContext() {
  return useContext(WhiteboardStore);
}
