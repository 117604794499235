import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { ChatTextArea } from "packages/catalog";

import { useIncallChatContext } from "packages/client/incall/contexts";
import { useTypedDispatch, useTypedSelector } from "packages/client/redux";

import { updateMessage } from "packages/client/incall/redux/slice";

export interface PChatMessageTextArea {
  scrollToBottom?: () => void;
}

export function ChatMessageTextArea({ scrollToBottom }: PChatMessageTextArea) {
  const { chatMessage } = useTypedSelector(({ chat }) => ({
    chatMessage: chat.message,
  }));
  const dispatch = useTypedDispatch();
  const { sendMessage } = useIncallChatContext();

  const [message, setMessage] = useState(chatMessage);

  useEffect(() => {
    return () => {
      dispatch(updateMessage(message));
    };
  }, [dispatch, message]);

  const handleSubmit = useCallback(() => {
    if (message.trim().length) {
      sendMessage({ text: message });
      setMessage("");
      scrollToBottom();
    }
  }, [message, scrollToBottom, sendMessage]);

  return (
    <ChatTextArea
      autoFocus
      onSend={handleSubmit}
      maxLength={500}
      onFocus={scrollToBottom}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
      sendDisabled={!message.trim().length}
      placeholder="Write a message..."
      value={message}
    />
  );
}
