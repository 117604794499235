import type { HTMLProps } from "react";

import { useMergedClassNames } from "packages/utils";

import styles from "./H2.module.scss";

export interface PH2 extends HTMLProps<HTMLHeadingElement> {
  hasMargin?: boolean;
}

export function H2({ className, hasMargin, ...rest }: PH2) {
  const h2Classes = useMergedClassNames({ className }, [styles.h2, hasMargin && styles.hasMargin]);

  return <h2 {...rest} className={h2Classes} />;
}
