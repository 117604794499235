import { HEADER_JSON } from "packages/utils";

import { fetchDjangoAPI, fetchDjangoAPIAuthenticated } from "packages/client/fetch";

export async function submitUserFeedback(
  rating: number,
  review: string,
  happyToContact: boolean,
  email: string,
  callID: string,
) {
  return await fetchDjangoAPI("/users/feedback/", {
    body: JSON.stringify({
      rating: rating,
      review: review,
      happyToContact: happyToContact,
      email: email,
      callID: callID,
    }),
    headers: HEADER_JSON,
    method: "POST",
  });
}

export async function submitUserFeedbackAuthenticated(
  rating: number,
  review: string,
  happyToContact: boolean,
  callID: string,
) {
  return await fetchDjangoAPIAuthenticated("/users/feedback/", {
    body: JSON.stringify({
      rating: rating,
      review: review,
      happyToContact: happyToContact,
      callID: callID,
    }),
    headers: HEADER_JSON,
    method: "POST",
  });
}
