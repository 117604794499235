import { UserType, UserProfile, UserRecordingQuality } from "packages/client/graphql_definitions.generated";
import {
  EDjangoProfilePrivacy,
  EDjangoRecordingQuality,
  IDjangoPasswords,
  IDjangoUser,
  IDjangoUserOwnProfile,
} from "packages/client/users/django/types";
import type { IUserTypeOverride } from "packages/client/users/graphql/types";

const mapProfilePrivacy: Record<UserProfile, EDjangoProfilePrivacy> = {
  [UserProfile.Protected]: EDjangoProfilePrivacy.PROTECTED,
  [UserProfile.Public]: EDjangoProfilePrivacy.PUBLIC,
};

const mapRecordingQuality: Record<UserRecordingQuality, EDjangoRecordingQuality> = {
  [UserRecordingQuality.High]: EDjangoRecordingQuality.High,
  [UserRecordingQuality.Low]: EDjangoRecordingQuality.Low,
  [UserRecordingQuality.Medium]: EDjangoRecordingQuality.Medium,
};

export function toDjangoPasswords(data: {
  confirmPassword: string;
  currentPassword: string;
  newPassword: string;
}): IDjangoPasswords {
  return {
    confirm_password: data.confirmPassword,
    new_password: data.newPassword,
    old_password: data.currentPassword,
  };
}

export function toDjangoUser(data: IUserTypeOverride): IDjangoUser {
  return {
    confirm_password: data.confirmPassword,
    email: data.email,
    first_name: data.firstName,
    last_name: data.lastName,
    opt_in: data.optIn,
    organisation: parseInt(data.organisationID),
    password: data.password,
    phone_number: data.phoneNumber || null,
  };
}

export function toDjangoUserOwnProfile(
  data: Partial<UserType>,
  cleanUndefinedFields = false,
): Partial<IDjangoUserOwnProfile> {
  const serialised = {
    accepted_privacy_policy: data.acceptedPrivacyPolicy,
    bio: data.bio,
    email: data.email,
    first_name: data.firstName,
    id: data.id ? parseInt(data.id) : undefined,
    job_title: data.jobTitle,
    last_name: data.lastName,
    opt_in: data.optIn,
    organisation: data?.organisation?.id ? parseInt(data.organisation.id) : undefined,
    phone_number: data.phoneNumber,
    profile: mapProfilePrivacy[data.profile],
    profile_picture: data.profilePicture,
    recording_quality: mapRecordingQuality[data.recordingQuality],
    skills: data.skills,
    website: data.website,
  };

  if (!cleanUndefinedFields) return serialised;

  return Object.fromEntries(Object.entries(serialised).filter(([, v]) => v !== undefined));
}
