export enum EVidyoRecordingActions {
  RECORDING_CHANGE = "recording/change",
}

export interface IRecordingState {
  isPaused: boolean;
  isRecording: boolean;
  passiveUpdateActive: boolean;
}

export interface IRecordingChangeAction {
  type: EVidyoRecordingActions.RECORDING_CHANGE;
  isRecording: boolean;
  isPaused: boolean;
}

export type TRecordingAction = IRecordingChangeAction;
