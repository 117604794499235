import type { ReactNode } from "react";

import { Flexbox, Icon } from "packages/catalog";
import { flattenString, EIcon } from "packages/utils";

import styles from "./IconContent.module.scss";

export interface PIconContent {
  children: string | ReactNode;
  description: string;
  icon: EIcon;
}

export function IconContent({ children, description, icon }: PIconContent) {
  const descriptionString = flattenString(description);

  return (
    <Flexbox as="figure" hasDoubleGap={false}>
      <figcaption className={styles.figcaption}>
        <Icon aria-describedby={descriptionString} className={styles.icon} icon={icon} />
      </figcaption>
      <div id={descriptionString}>{children}</div>
    </Flexbox>
  );
}
