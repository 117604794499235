import { ChangeEvent, useCallback, useEffect, useReducer, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Cell,
  Feedback,
  Field,
  Grid,
  H1,
  H2,
  H3,
  IconButton,
  Input,
  LabelledContent,
  P,
  StatusBadge,
  Table,
  TD,
  TextButton,
  TR,
} from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus, ILocationState } from "packages/utils";

import { deleteOrganisation } from "packages/client/organisations/django/requests";
import { deleteUser, fetchOrganisationUsers } from "packages/client/users/django/requests";
import { useGetFullOrganisationLazyQuery } from "packages/client/organisations/graphql/organisations.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";

export interface User {
  id: number;
  isDeleted: boolean;
}

const COLUMNS = ["ID", "Status"];
const COLUMN_WIDTHS = "4rem 1fr";

export function SuperAdminOrganisationManager() {
  const [organisationID, setOrganisationID] = useState("");
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersDeleting, setUsersDeleting] = useState(false);

  const { state }: { state?: ILocationState } = useLocation();

  const [userz, updateUserz] = useReducer(
    (state: User[], action: { id?: number; type: "create" | "markDeleted" | "wipe" }) => {
      switch (action.type) {
        case "create":
          return action.id
            ? [...state.filter(item => item.id !== action.id), { id: action.id, isDeleted: false }]
            : state;
        case "markDeleted":
          return action.id ? state.map(item => (item.id === action.id ? { ...item, isDeleted: true } : item)) : state;
        case "wipe":
          return [];
        default:
          return state;
      }
    },
    [],
  );

  const [loadOrg, { data, error, loading: organisationLoading }] = useGetFullOrganisationLazyQuery({
    fetchPolicy: "network-only",
  });

  const fetchUsers = useCallback(async (organisationID: string) => {
    setUsersLoading(true);
    const response = await fetchOrganisationUsers(organisationID);
    const users: { id: number }[] = await response.json();
    users.forEach(u => updateUserz({ type: "create", id: u.id }));
    setUsersLoading(false);
  }, []);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOrganisationID(e.currentTarget.value);
  }, []);

  const handleDeleteOrganisation = useCallback(() => {
    const userConfirmation = window.prompt(
      `Are you sure you want to delete '${data?.organisation.name}'? Type in 'Delete [name of the organisation]' to confirm this action.`,
    );

    if (userConfirmation === `Delete ${data?.organisation.name}`) {
      deleteOrganisation(data?.organisation.id).then(() => location.reload());
    }
  }, [data]);

  const handleDeleteUsers = useCallback(async () => {
    const userConfirmation = window.prompt(
      `Are you sure you want to delete all users in '${data?.organisation.name}' ? Type in 'Delete all users in [name of the organisation]' to confirm this action.`,
    );

    if (userConfirmation === `Delete all users in ${data?.organisation.name}`) {
      setUsersDeleting(true);

      const requests = [];

      for (let i = 0; i < userz.length; i++) {
        const user = userz[i];

        requests.push(
          new Promise(resolve => {
            function run() {
              deleteUser(`${user.id}`).then(() => updateUserz({ type: "markDeleted", id: user.id }));
            }

            resolve(run());
          }),
        );
      }

      await Promise.all(requests);
      setUsersDeleting(false);
    }
  }, [data, userz]);

  const loadOrganisation = useCallback(() => {
    updateUserz({ type: "wipe" });
    loadOrg({ variables: { organisationID: parseInt(organisationID, 10) } });
  }, [loadOrg, organisationID]);

  useEffect(() => {
    if (data?.organisation?.id) {
      updateUserz({ type: "wipe" });
      fetchUsers(data.organisation.id);
    }
  }, [data, fetchUsers]);

  useEffect(() => {
    if (state?.organisationID) {
      setOrganisationID(state.organisationID);
    }
  }, [state?.organisationID]);

  return (
    <>
      <RoutingChangeHandler message="The organisation deletion page has loaded." pageTitle="Delete organisation" />
      <Grid>
        <Cell>
          <IconButton
            appearance={EAppearance.GHOST}
            as={Link}
            description="Organisations"
            dimension={EDimension.SMALL}
            icon={EIcon.ARROWLEFT}
            label="Organisations"
            status={EStatus.NEUTRAL}
            to=".."
          />
        </Cell>
        <Cell>
          <H1>Delete organisation</H1>
        </Cell>
        <Cell>
          <Feedback status={EStatus.WARNING}>
            {"Don't forget to delete all child organisations of an organisation first, prior to deleting the parent."}
          </Feedback>
        </Cell>
        <Cell>
          <Field label="Organisation ID">
            <Input name="organisationID" type="text" value={organisationID} onChange={handleChange} />
          </Field>
        </Cell>
        <Cell>
          <TextButton
            disabled={!organisationID?.length || organisationLoading}
            status={EStatus.NEUTRAL}
            onClick={loadOrganisation}>
            {data?.organisation?.id && data.organisation.id === organisationID
              ? "Reload organisation"
              : "Load organisation"}
          </TextButton>
        </Cell>
        {organisationLoading ? (
          <Cell>
            <P>Loading organisation...</P>
          </Cell>
        ) : (
          <>
            {error && (
              <Cell>
                <Feedback status={EStatus.DANGER}>Organisation could not be found.</Feedback>
              </Cell>
            )}
            {data?.organisation && (
              <>
                <Cell>
                  <H2>{data.organisation.name}</H2>
                </Cell>
                <Cell>
                  <H3>Overview</H3>
                </Cell>
                <Cell>
                  <LabelledContent content={data.organisation.id} label="ID" />
                </Cell>
                <Cell>
                  <LabelledContent
                    content={data.organisation?.parentOrganisation?.name ?? "N/A"}
                    label="Parent organisation"
                  />
                </Cell>
                <Cell>
                  <LabelledContent content={data.organisation.pricingPlan} label="Pricing plan" />
                </Cell>
                <Cell>
                  <StatusBadge status={data.organisation.isApproved ? EStatus.SUCCESS : EStatus.DANGER}>
                    {data.organisation.isApproved ? "Approved" : "Not approved"}
                  </StatusBadge>
                </Cell>
                <Cell>
                  <TextButton
                    disabled={
                      userz?.length
                        ? userz.filter(u => u.isDeleted).length !== userz.length
                        : !data?.organisation?.id ||
                          organisationLoading ||
                          usersLoading ||
                          (data?.organisation?.id && !!userz?.length)
                    }
                    status={EStatus.DANGER}
                    onClick={handleDeleteOrganisation}>
                    Delete organisation
                  </TextButton>
                </Cell>
              </>
            )}
          </>
        )}
        {data?.organisation?.id && (
          <>
            <Cell>
              <H3>Users ({userz.length})</H3>
            </Cell>
            {usersLoading ? (
              <Cell>
                <P>Loading users...</P>
              </Cell>
            ) : (
              <>
                {usersDeleting && (
                  <Cell>
                    <P>Deleting users...</P>
                  </Cell>
                )}
                {(usersDeleting || userz?.find(u => u.isDeleted)) && (
                  <Cell>
                    <progress
                      value={
                        userz.length ? ((userz.filter(u => u.isDeleted).length / userz.length) * 100).toString() : "0"
                      }
                      max="100">
                      {userz.length ? ((userz.filter(u => u.isDeleted).length / userz.length) * 100).toString() : "0"}%
                    </progress>
                  </Cell>
                )}
                <Cell>
                  <Table columns={COLUMNS} columnWidths={COLUMN_WIDTHS} description="All users">
                    {userz?.map(u => (
                      <TR columnWidths={COLUMN_WIDTHS} key={`u-${u.id}`}>
                        <TD heading={COLUMNS[0]}>{`${u.id}`}</TD>
                        <TD heading={COLUMNS[1]}>
                          <StatusBadge status={u.isDeleted ? EStatus.DANGER : EStatus.SUCCESS}>
                            {u.isDeleted ? "Deleted" : "Active"}
                          </StatusBadge>
                        </TD>
                      </TR>
                    ))}
                  </Table>
                </Cell>
                <Cell>
                  <TextButton
                    disabled={
                      !data?.organisation?.id ||
                      organisationLoading ||
                      usersLoading ||
                      !userz?.length ||
                      !!userz.filter(u => u.isDeleted).length
                    }
                    status={EStatus.DANGER}
                    onClick={handleDeleteUsers}>
                    Delete all users
                  </TextButton>
                </Cell>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
