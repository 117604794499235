import { RoomSystemType } from "packages/client/graphql_definitions.generated";

// these are user-facing terms for the data response from graphql
export const roomSystemConnectionType = {
  [RoomSystemType.H323]: "H.323/E.164/IP",
  [RoomSystemType.Sip]: "SIP",
  [RoomSystemType.Vsceneroom]: "ClassView POD",
};

export const roomSystemConnectionTypeLabel = {
  [RoomSystemType.H323]: "IP address",
  [RoomSystemType.Sip]: "SIP address",
  [RoomSystemType.Vsceneroom]: "Extension number",
};
