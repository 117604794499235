import { useCallback, useState } from "react";
import type { ApolloQueryResult } from "@apollo/client";

import { Block, Cell, Flexbox, Grid, H2, Pagination, TextButton } from "packages/catalog";
import { EAppearance, EStatus } from "packages/utils";

import type { GetAllOrganisationsQuery } from "packages/client/organisations/graphql/organisations.generated";

import { approveOrganisation, deleteOrganisation } from "packages/client/organisations/django/requests";
import { useGetPendingOrganisationsQuery } from "packages/client/organisations/graphql/organisations.generated";
import { useGetOrganisationUsersLazyQuery } from "packages/client/users/graphql/users.generated";

import { OrganisationSummary } from "packages/client/organisations/components";

export interface PPendingOrganisations {
  refetchAllOrganisations?: () => Promise<ApolloQueryResult<GetAllOrganisationsQuery>>;
}

export function PendingOrganisations({ refetchAllOrganisations }: PPendingOrganisations) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch: refetchPendingOrganisations } = useGetPendingOrganisationsQuery({
    fetchPolicy: "cache-and-network",
    variables: { page: currentPage },
  });

  const [getOrganisationUsers] = useGetOrganisationUsersLazyQuery();

  const handleApproveOrganisation = useCallback(
    (organisationID: string) => {
      approveOrganisation(organisationID).then(() => {
        refetchPendingOrganisations();
        refetchAllOrganisations();
        getOrganisationUsers();
      });
    },
    [getOrganisationUsers, refetchAllOrganisations, refetchPendingOrganisations],
  );

  const handleRejectOrganisation = useCallback(
    (organisationID: string) => {
      deleteOrganisation(organisationID).then(() => refetchPendingOrganisations());
    },
    [refetchPendingOrganisations],
  );

  return (
    <>
      {data?.nonApprovedOrganisations?.objects.length ? (
        <Cell>
          <Grid as={Block}>
            <Cell>
              <H2>Pending organisations</H2>
            </Cell>
            <>
              {data.nonApprovedOrganisations.objects.map(pendingOrganisation => (
                <Cell as={Block} colSpan={12} colSpanXL={6} key={pendingOrganisation.id}>
                  <Flexbox alignItemsCenter justifyContentSpaceBetween>
                    <OrganisationSummary
                      organisationID={pendingOrganisation.id}
                      pendingOrganisationName={pendingOrganisation.name}
                      pendingOrganisationParentOrganisationName={pendingOrganisation?.parentOrganisation?.name}
                    />
                    <Flexbox>
                      <TextButton
                        appearance={EAppearance.GHOST}
                        onClick={() => handleRejectOrganisation(pendingOrganisation.id)}>
                        Reject
                      </TextButton>
                      <TextButton
                        onClick={() => handleApproveOrganisation(pendingOrganisation.id)}
                        status={EStatus.ACCENT}>
                        Approve
                      </TextButton>
                    </Flexbox>
                  </Flexbox>
                </Cell>
              ))}
            </>
            {data?.nonApprovedOrganisations?.pages > 1 && (
              <Cell>
                <Pagination
                  hasNext={data?.nonApprovedOrganisations?.hasNext}
                  hasPrevious={data?.nonApprovedOrganisations?.hasPrev}
                  page={data?.nonApprovedOrganisations?.page}
                  pages={data?.nonApprovedOrganisations?.pages}
                  setPage={setCurrentPage}
                />
              </Cell>
            )}
          </Grid>
        </Cell>
      ) : null}
    </>
  );
}
