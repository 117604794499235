import { useCallback, useState } from "react";

import { Icon } from "packages/catalog";
import { EIcon } from "packages/utils";

import type { TGrid } from "packages/client/incall/hooks";

import { useWhiteboardContext } from "packages/client/incall/contexts";

import { Tool } from "packages/client/incall/components";

import dotImport from "public/svg/dot-grid.svg";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "packages/client/incall/components/whiteboard/Tool/Tool.modules.scss";

const POPOVER_POSITION = { left: "0" };

enum EGrid {
  SQUARE = "square",
  DOT = "dot",
  BLANK = "blank",
}

export function ToolCanvas({ isCondensed }: { isCondensed: boolean }) {
  const [active, setActive] = useState(false);
  const { activeGrid, updateGrid } = useWhiteboardContext();

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) setActive(false);
  }, []);

  function handleToggleClick() {
    setActive(!active);
  }

  const handleSectionClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    if (e.detail === 1) setActive(false);
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (e.currentTarget.value === "blank") updateGrid(null);
      else updateGrid(e.currentTarget.value as TGrid);
    },
    [updateGrid],
  );

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") setActive(false);
  }, []);

  const handleGridIcon = useCallback(() => {
    switch (activeGrid) {
      case EGrid.SQUARE:
        return <Icon className={styles.icon} icon={EIcon.GRID} />;
      case EGrid.DOT:
        return (
          <svg className={styles.icon}>
            <use href={`${dotImport}#dot`} role="img"></use>
          </svg>
        );
      default:
        return <Icon className={styles.icon} icon={EIcon.SQUARE} strokeLinecap="square" />;
    }
  }, [activeGrid]);

  return (
    <div className={styles.popoverContainer} onBlur={handleBlur}>
      <Tool onClick={handleToggleClick} inputType="button" label="Canvas" active={active}>
        {handleGridIcon()}
      </Tool>
      {active && (
        <section
          className={styles.popover}
          onKeyDown={handleKeyDown}
          onClick={handleSectionClick}
          style={isCondensed ? { ...POPOVER_POSITION } : null}>
          <Tool
            active={activeGrid === EGrid.SQUARE}
            checked={activeGrid === EGrid.SQUARE}
            icon={EIcon.GRID}
            inputType="radio"
            label="Squared"
            name="canvas"
            onChange={handleChange}
            value={EGrid.SQUARE}
          />
          <Tool
            inputType="radio"
            name="canvas"
            label="Dot"
            active={activeGrid === EGrid.DOT}
            onChange={handleChange}
            checked={activeGrid === EGrid.DOT}
            value={EGrid.DOT}>
            <svg className={styles.icon}>
              <use href={`${dotImport}#dot`} role="img"></use>
            </svg>
          </Tool>
          <Tool
            icon={EIcon.SQUARE}
            inputType="radio"
            name="canvas"
            label="Blank"
            active={!activeGrid}
            onChange={handleChange}
            checked={!activeGrid}
            value="blank"
          />
        </section>
      )}
    </div>
  );
}
