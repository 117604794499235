import { useCallback } from "react";

import { dispatchVidyo, useSelectorVidyo } from "../../redux";

import { ECallStatus, EVidyoStatusActions } from "../redux/types";

/**
 * Gets basic current call information from call redux, without connection check like usePresenceInCall does
 * @returns current callstatus and redux dispatcher function to reset it
 */
export function useCallInformation() {
  const { callStatus, isKicked, isTerminated } = useSelectorVidyo(({ status }) => ({
    callStatus: status.callStatus,
    isKicked: status.isKicked,
    isTerminated: status.isTerminated,
  }));

  const resetVidyoCallStatus = useCallback(() => {
    dispatchVidyo({
      type: EVidyoStatusActions.SET_CALL_STATUS,
      status: ECallStatus.OUTSIDE_CALL,
    });
  }, []);

  const resetLeaveStatus = useCallback(() => {
    dispatchVidyo({
      type: EVidyoStatusActions.RESET_LEAVE_STATUS,
    });
  }, []);

  return { callStatus, isKicked, isTerminated, resetLeaveStatus, resetVidyoCallStatus };
}
