import { Navigate, useParams } from "react-router-dom";

import { isNowBetweenStartAndEnd, TParams, PATH_SESSION } from "packages/utils";

import { useGetFullSession } from "packages/client/sessions/hooks";

import { SessionForm } from "packages/client/sessions/components";

/**
 * Hooks cant be called conditionally, useGetSingle call needs an id,
 * we are using separate views beacuse of that
 */

// Root call resolver for switching between CREATE and EDIT modes.

export function SessionFormResolver() {
  const { meetingID }: TParams = useParams();
  const id = meetingID ? parseInt(meetingID, 10) : null;
  return id ? <EditSessionForm id={id} /> : <SessionForm />;
}

function EditSessionForm({ id }: { id: number }) {
  const sessionData = useGetFullSession(id);
  /**
   * TEMPORARILY DISABLED EDIT FOR LIVE SESSIONS
   */
  if (sessionData.meeting) {
    if (sessionData.meeting.nextRecurringStart && sessionData.meeting.nextRecurringEnd) {
      if (
        isNowBetweenStartAndEnd(
          new Date(sessionData.meeting.nextRecurringStart),
          new Date(sessionData.meeting.nextRecurringEnd),
        )
      ) {
        return <Navigate to={PATH_SESSION(sessionData.meeting.id)} />;
      }
    } else {
      if (isNowBetweenStartAndEnd(new Date(sessionData.meeting.startTime), new Date(sessionData.meeting.endTime))) {
        return <Navigate to={PATH_SESSION(sessionData.meeting.id)} />;
      }
    }
  }
  /**
   * TEMPORARILY DISABLED EDIT FOR LIVE SESSIONS
   */
  return <SessionForm isEditMode sessionData={sessionData} />;
}
