import {
  DetailedHTMLProps,
  ElementType,
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  useMemo,
} from "react";

import styles from "./Flexbox.module.scss";

export interface PFlexbox extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  alignItemsCenter?: boolean;
  alignItemsFlexEnd?: boolean;
  alignItemsFlexStart?: boolean;
  as?: string | ElementType;
  children: ReactNode;
  className?: string;
  flexDirectionColumn?: boolean;
  flexDirectionColumnLG?: boolean;
  flexWrapWrap?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  hasDoubleGap?: boolean;
  hasHalfGap?: boolean;
  hasMargin?: boolean;
  justifyContentCenter?: boolean;
  justifyContentEnd?: boolean;
  justifyContentFlexStart?: boolean;
  justifyContentSpaceBetween?: boolean;
}

export const Flexbox = forwardRef(
  (
    {
      alignItemsCenter,
      alignItemsFlexEnd,
      alignItemsFlexStart,
      as: Component = "div",
      children,
      className,
      flexDirectionColumn,
      flexDirectionColumnLG,
      flexWrapWrap,
      fullHeight,
      fullWidth,
      hasDoubleGap,
      hasHalfGap,
      hasMargin,
      justifyContentCenter,
      justifyContentEnd,
      justifyContentFlexStart,
      justifyContentSpaceBetween,
      ...rest
    }: PFlexbox,
    ref: MutableRefObject<HTMLElement>,
  ) => {
    const classes = useMemo(
      () =>
        [
          styles.flexbox,
          className && className,
          alignItemsCenter && styles.alignItemsCenter,
          alignItemsFlexEnd && styles.alignItemsFlexEnd,
          alignItemsFlexStart && styles.alignItemsFlexStart,
          flexDirectionColumn && styles.flexDirectionColumn,
          flexDirectionColumnLG && styles.flexDirectionColumnLG,
          flexWrapWrap && styles.flexWrapWrap,
          fullHeight && styles.fullHeight,
          fullWidth && styles.fullWidth,
          hasDoubleGap && styles.hasDoubleGap,
          hasHalfGap && styles.hasHalfGap,
          hasMargin && styles.hasMargin,
          justifyContentCenter && styles.justifyContentCenter,
          justifyContentEnd && styles.justifyContentEnd,
          justifyContentFlexStart && styles.justifyContentFlexStart,
          justifyContentSpaceBetween && styles.justifyContentSpaceBetween,
        ]
          .filter(e => e)
          .join(" "),
      [
        alignItemsCenter,
        alignItemsFlexEnd,
        alignItemsFlexStart,
        className,
        flexDirectionColumn,
        flexDirectionColumnLG,
        flexWrapWrap,
        fullHeight,
        fullWidth,
        hasDoubleGap,
        hasHalfGap,
        hasMargin,
        justifyContentCenter,
        justifyContentEnd,
        justifyContentFlexStart,
        justifyContentSpaceBetween,
      ],
    );

    return (
      <Component className={classes} ref={ref} {...rest}>
        {children}
      </Component>
    );
  },
);
