import type { IBlockCell, IBlockMeasuredCell } from "../interfaces";

import type { TStreamPosition } from "../types";

import { sliceEqualSizeExact, sumBefore } from "./";

export enum ESpreadDirection {
  Horizontal,
  Vertical,
}

export function spreadCells(
  blockAvailable: TStreamPosition,
  blocksToSpread: IBlockCell[] = [],
  spreadDirection = ESpreadDirection.Horizontal,
): IBlockMeasuredCell[] {
  const [left, top, width, height] = blockAvailable;
  const isCol = spreadDirection === ESpreadDirection.Horizontal;
  const spreadSizes = sliceEqualSizeExact(isCol ? width : height, blocksToSpread.length);

  return blocksToSpread.map((b, i) => {
    const l: number = isCol ? sumBefore(spreadSizes, i) : 0;
    const t: number = !isCol ? sumBefore(spreadSizes, i) : 0;

    return {
      ...b,
      left: l + left,
      top: t + top,
      width: isCol ? spreadSizes[i] : width,
      height: !isCol ? spreadSizes[i] : height,
    };
  });
}
