import { useMemo } from "react";

import { EDimension, useDimensionStyle } from "packages/utils";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Avatar.module.scss";

export interface PAvatar {
  dimension: EDimension;
  email?: string;
  fullName: string;
  imageURL?: string;
  loading?: boolean;
}

export function getColorStyle(initials: string): string {
  // if no initials return default styles
  if (!initials?.trim().length) return styles.mono;

  // reduce decimal char code down to a single digit
  const digit = getSingleDigit(initials.charCodeAt(0));

  // return styles based on digit
  if (digit > 0 && digit <= 2) return styles.akebi;
  else if (digit > 2 && digit <= 4) return styles.strawberry;
  else if (digit > 4 && digit <= 6) return styles.satsuma;
  else if (digit > 6 && digit <= 8) return styles.lime;
  else if (digit === 9) return styles.blueberry;
  else return styles.mono;
}

export function getInitials(fullname: string) {
  const nameToManipulate = fullname?.trim();

  // if no name return empty string
  if (!nameToManipulate?.length) return "";

  // get all parts (words) of the name
  const names = nameToManipulate?.split(" ");

  // if all one word, e.g. korean name or only first name, return first character, else return the first character of the first and last words in the name
  return names?.length === 1 ? names[0][0] : `${names[0][0]}${names[names?.length - 1][0]}`;
}

function getSingleDigit(decimal: number): number {
  return ((decimal - 1) % 9) + 1;
}

export function Avatar({ dimension, email, fullName, imageURL, loading }: PAvatar) {
  const dimensionStyle = useDimensionStyle(styles);

  const initials = useMemo(() => getInitials(fullName), [fullName]);

  const classes = useMemo(
    () =>
      [styles.avatar, getColorStyle(initials), dimensionStyle[dimension], imageURL && styles.img]
        .filter(e => e)
        .join(" "),
    [dimension, dimensionStyle, imageURL, initials],
  );
  const title = useMemo(() => `${fullName}${email ? ` - ${email}` : ""}`, [email, fullName]);

  if (loading) return <div className={classes} />;

  return (
    <div className={classes} title={title}>
      {imageURL ? (
        <img alt={`Picture of ${fullName}`} className={styles.inner} src={imageURL} />
      ) : (
        <span className={styles.initials}>{initials}</span>
      )}
    </div>
  );
}
