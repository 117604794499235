import { Alert, PAlert } from "packages/catalog";

import styles from "./Alerts.module.scss";

export interface PAlerts {
  alerts: PAlert[];
}

export function Alerts({ alerts }: PAlerts) {
  return (
    <ul className={styles.alerts}>
      {alerts.map((alert, index) => (
        <Alert key={index} status={alert.status} message={alert.message} />
      ))}
    </ul>
  );
}
