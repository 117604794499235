import { MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { Avatar, IconButton, Icon, SmallPopoverMenu } from "packages/catalog";
import { copyString, EIcon, EAttachment, EAppearance, EDimension, EMessageOrder, EStatus } from "packages/utils";

import styles from "./Bubble.module.scss";

export interface PBubble {
  author: {
    id?: string | number;
    fullName: string;
  };
  children?: ReactNode;
  containerRef?: MutableRefObject<HTMLDivElement>;
  datetime: string;
  isAuthorMe: boolean;
  isAdmin?: boolean;
  isPinned?: boolean;
  message?: string;
  messageOrder: EMessageOrder;
  onDeleteClick?: () => void;
}

const dayConfig: Intl.DateTimeFormatOptions = {
  localeMatcher: "best fit",
  minute: "2-digit",
  hour: "2-digit",
  day: "numeric",
  month: "short",
  hour12: false,
};

const timeConfig: Intl.DateTimeFormatOptions = {
  minute: dayConfig.minute,
  hour: dayConfig.hour,
  hour12: dayConfig.hour12,
};

const tf = new Intl.DateTimeFormat(undefined, timeConfig);
const dtf = new Intl.DateTimeFormat(undefined, dayConfig);
const dtfYear = new Intl.DateTimeFormat(undefined, { ...dayConfig, year: "numeric" });
function formatDateTime(d: Date) {
  const today = new Date();
  if (d.toDateString() === today.toDateString()) return tf.format(d);
  if (d.getFullYear() !== today.getFullYear()) return dtfYear.format(d);
  return dtf.format(d);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rtf = (Intl as any).RelativeTimeFormat ? new (Intl as any).RelativeTimeFormat() : undefined;

function formatRelativeLessThanAWeek(diffSeconds: number) {
  if (!rtf) return undefined;
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  if (diffDays === 1) return "Yesterday";
  if (diffDays > 1 && diffDays < 7) return rtf.format(-diffDays, "day");
  return undefined;
}

export function Bubble({
  author,
  children,
  containerRef,
  datetime,
  isAdmin,
  isAuthorMe,
  isPinned,
  message,
  messageOrder,
  onDeleteClick,
}: PBubble) {
  const menuRef = useRef<HTMLDivElement>();
  const [menuUpwards, setMenuUpwards] = useState(false);

  // detect if the chat bubble's popover menu has enough room to display a downwards menu
  useEffect(() => {
    if (!menuRef.current) return;
    const observer = new IntersectionObserver(
      entries => {
        setMenuUpwards(entries[0].isIntersecting);
      },
      {
        root: containerRef?.current,
        // this is a hard-coded estimated margin box based on the SmallPopoverMenu's height. if the volume of items in the menu changes, the third value (bottom margin) will need updated
        rootMargin: "50% 0px -85px 0px",
        threshold: 1.0,
      },
    );

    observer.observe(menuRef.current);
    return () => {
      observer.disconnect();
    };
  });

  // set the menu attachment depending on if it has room to go down (else upwards) and where it should attach depending on the user
  const attachmentType = useMemo(
    () =>
      isAuthorMe
        ? menuUpwards
          ? EAttachment.SOUTH_SOUTH_WEST
          : EAttachment.NORTH_NORTH_WEST
        : menuUpwards
          ? EAttachment.SOUTH_SOUTH_EAST
          : EAttachment.NORTH_NORTH_EAST,
    [isAuthorMe, menuUpwards],
  );

  const authorTypeStyle = useMemo(() => {
    return isAuthorMe ? styles.me : styles.them;
  }, [isAuthorMe]);

  const nowByMinutes = Math.floor(Date.now() / (1000 * 60));
  const formattedDate = useMemo(() => {
    const d = new Date(datetime);
    const dInSeconds = Math.floor(d.getTime() / 1000);
    const diffSeconds = Math.floor(nowByMinutes * 60 - dInSeconds);
    const relativeFormat = formatRelativeLessThanAWeek(diffSeconds);
    return relativeFormat ?? formatDateTime(d);
  }, [datetime, nowByMinutes]);

  const menuOptions = useMemo(() => {
    return [
      { onClick: () => copyString(message), status: EStatus.NEUTRAL, text: "Copy" },
      ...(isAdmin ? [{ onClick: onDeleteClick, status: EStatus.DANGER, text: "Delete" }] : []),
    ];
  }, [isAdmin, message, onDeleteClick]);

  const avatar = useMemo(() => {
    return !isAuthorMe && (messageOrder === EMessageOrder.FIRST || messageOrder === EMessageOrder.FIRSTANDLAST) ? (
      <>
        <div className={styles.avatar}>
          <Avatar dimension={EDimension.SMALL} fullName={author.fullName} />
        </div>
        <div className={styles.name}>{author.fullName}</div>
      </>
    ) : null;
  }, [author.fullName, isAuthorMe, messageOrder]);

  const wrapperClasses = useMemo(
    () => [styles.wrapper, authorTypeStyle, avatar && styles.withAvatar].filter(e => e).join(" "),
    [authorTypeStyle, avatar],
  );

  return (
    <div className={wrapperClasses}>
      <div className={styles.message}>
        {avatar}
        <div className={styles.bubbleContainer}>
          <div className={styles.bubble}>
            <div className={styles.text}>{children}</div>
            <time className={styles.date} dateTime={datetime}>
              {formattedDate}
            </time>
            {isPinned && (
              <div className={styles.pin}>
                <Icon className={styles.pinIcon} icon={EIcon.BOOKMARK} title="Pinned message" />
              </div>
            )}
          </div>
          <div ref={menuRef}>
            <SmallPopoverMenu
              attachment={attachmentType}
              options={menuOptions}
              shouldScrollIntoView
              trigger={
                <IconButton
                  as="div"
                  description="Options"
                  status={EStatus.NEUTRAL}
                  icon={EIcon.MOREVERTICAL}
                  appearance={EAppearance.GHOST}
                  dimension={EDimension.SMALL}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
