import { createContext, ReactNode } from "react";

import { useVidyoStore } from "packages/client/incall/hooks";

export interface PVidyoContext {
  children: ReactNode;
}

export const VidyoStore = createContext<ReturnType<typeof useVidyoStore>>(null);

export function VidyoContext({ children }: PVidyoContext) {
  const value = useVidyoStore();
  return <VidyoStore.Provider value={value}>{children}</VidyoStore.Provider>;
}
