import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";

import { InternalTextLink, P, Snackbar, Strong } from "packages/catalog";
import { EIcon, PATH_CALL_SETTINGS_EXTENDCALL, EStatus, TParams } from "packages/utils";

import { useGetEssentialSession } from "packages/client/sessions/hooks";

const SECONDS_BEFORE_END_TO_DISPLAY = 120;

export interface PMeetingEndingNotification {
  isMedium: boolean;
  isModerator: boolean;
  isRecurring?: boolean;
  isSmall: boolean;
  sessionID: number;
}

const END_TIME_FORMAT = { hour: "2-digit", minute: "2-digit" } as const;

export function MeetingEndingNotification({
  isMedium,
  isModerator,
  isRecurring,
  isSmall,
  sessionID,
}: PMeetingEndingNotification) {
  const { callID }: TParams = useParams();

  const [dismissed, setDismissed] = useState(false);

  const [isEndingSoon, setIsEndingSoon] = useState(false);
  const [isExtendedBy, setIsExtendedBy] = useState(0);

  const oldEndTimeInMs = useRef(0);

  const { session, startPolling, stopPolling } = useGetEssentialSession(sessionID);

  useEffect(() => {
    startPolling(5 * 1000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const endTimeInMs = useMemo(() => {
    if (!session) return null;
    return session.nextRecurringEnd ? Date.parse(session.nextRecurringEnd) : Date.parse(session.endTime);
  }, [session]);

  const endTimeformated = useMemo(() => {
    if (!endTimeInMs) return "";
    const endTime = new Date(endTimeInMs);
    return endTime.toLocaleTimeString(undefined, END_TIME_FORMAT);
  }, [endTimeInMs]);

  useEffect(() => {
    function timeLeftInMeeting() {
      if (!endTimeInMs) return;
      const timeInMs = Date.now();
      const timeLeftInS = Math.floor((endTimeInMs - timeInMs) / 1000);
      setIsEndingSoon(timeLeftInS < SECONDS_BEFORE_END_TO_DISPLAY);
    }
    const countdownTick = setInterval(timeLeftInMeeting, 1000);
    return () => clearInterval(countdownTick);
  }, [endTimeInMs, isEndingSoon]);

  useEffect(() => {
    if (!endTimeInMs) return;
    if (oldEndTimeInMs.current === 0) {
      oldEndTimeInMs.current = endTimeInMs;
      return;
    }
    setIsExtendedBy((endTimeInMs - oldEndTimeInMs.current) / (60 * 1000));
    setDismissed(false);
  }, [endTimeInMs]);

  const handleDismiss = useCallback(() => {
    oldEndTimeInMs.current = endTimeInMs;
    setIsExtendedBy(0);
    isEndingSoon ? setDismissed(true) : setDismissed(false);
  }, [endTimeInMs, isEndingSoon]);

  if (dismissed) return null;

  if (isEndingSoon) {
    return (
      <Snackbar handleDismiss={handleDismiss} icon={EIcon.ALERTTRIANGLE} status={EStatus.WARNING}>
        <span>{isSmall ? "Ending. " : isMedium ? "Ending soon." : "This session will end soon."}</span>
        {isModerator && !isRecurring && (
          <InternalTextLink to={PATH_CALL_SETTINGS_EXTENDCALL(callID)} onClick={handleDismiss}>
            Extend
          </InternalTextLink>
        )}
      </Snackbar>
    );
  }

  return isExtendedBy > 0 ? (
    <Snackbar handleDismiss={handleDismiss} icon={EIcon.CHECKCIRCLE} status={EStatus.SUCCESS}>
      {isSmall ? (
        "Extended"
      ) : isMedium ? (
        <P>
          New end time <Strong>{endTimeformated}</Strong>.
        </P>
      ) : (
        <P>
          Session extended by {isExtendedBy} minutes. New end time <Strong>{endTimeformated}</Strong>.
        </P>
      )}
    </Snackbar>
  ) : null;
}
