import { Cell, Flexbox, Grid, H1, H2, P, TextButton } from "packages/catalog";
import { convertStringEnumToDropdownObject, EAppearance, EStatus, REGEX_URL } from "packages/utils";

import { OrganisationSector } from "packages/client/graphql_definitions.generated";

import type { Screens } from "packages/client/onboarding/enums/screens";

import { defaultRules } from "packages/client/onboarding/consts";

import { FormContainer as Form, OrgLookupContainer, SScreen } from "packages/client/onboarding/containers";

import { RoutingChangeHandler } from "packages/client/layout/components";

export interface PCreateOrgStep1 {
  cache: SScreen;
  checkEmpty: (screen: string) => void;
  checkingEmpty: boolean;
  emptyChecked: (screen: string) => void;
  goTo: (screen: Screens.ChooseOrg) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (formData: any) => void;
  onFormValidation: (isValid: boolean) => void;
  onNext: () => void;
  onPrevious: () => void;
}

const SECTOR_OPTIONS = convertStringEnumToDropdownObject(OrganisationSector);

export function CreateOrgStep1({
  cache,
  checkEmpty,
  checkingEmpty,
  emptyChecked,
  onChange,
  onFormValidation,
  onNext,
  onPrevious,
}: PCreateOrgStep1) {
  return (
    <>
      <RoutingChangeHandler
        message="The create organisation step one page has loaded."
        pageTitle="Create an organistion"
      />
      <Cell>
        <H1 center hasMargin>
          Create an organisation
        </H1>
        <P>
          Please complete your organisation information. You will automatically become the administrator for your
          organisation.
        </P>
      </Cell>
      <Cell>
        <P>Step 1 of 3</P>
      </Cell>
      <Cell>
        <H2>About your organisation</H2>
      </Cell>
      <Cell>
        <Form
          checkingEmpty={checkingEmpty}
          emptyChecked={() => emptyChecked("orgA")}
          onValidation={onFormValidation}
          debounceValidation={150}>
          <Grid noColGap>
            <Cell>
              <Form.Input
                autoComplete="organization"
                label="Organisation name"
                name="organisation"
                onChange={orgName => onChange({ orgName })}
                required
                rules={defaultRules}
                value={cache.orgName ? `${cache.orgName}` : null}
              />
            </Cell>
            <Cell>
              <OrgLookupContainer
                limit={5}
                onChange={selected => onChange({ selected })}
                optional
                onlySuperorgsAndLites
                selected={
                  cache.selectedParent && cache.selectedParentName
                    ? { id: cache.selectedParent, name: `${cache.selectedParentName}` }
                    : null
                }
                title="Parent organisation"
              />
            </Cell>
            <Cell>
              <Form.Select
                defaultValue="-- Choose sector --"
                label="Sector"
                onChange={sector => onChange({ sector })}
                options={SECTOR_OPTIONS}
                required
                value={cache.sector ? `${cache.sector}` : null}
              />
            </Cell>
            <Cell>
              <Form.Input
                advisory="This should be the full URL for your organisation, e.g. https://classview.io"
                autoComplete="url"
                label="Website URL"
                name="website"
                onChange={url => onChange({ url })}
                optional
                rules={{
                  ...defaultRules,
                  regex: {
                    message: "Invalid website URL",
                    value: REGEX_URL,
                  },
                }}
                value={cache.url ? `${cache.url}` : ""}
              />
            </Cell>
          </Grid>
        </Form>
      </Cell>
      <Cell>
        <Flexbox justifyContentSpaceBetween>
          <TextButton appearance={EAppearance.GHOST} as="button" onClick={onPrevious}>
            Back
          </TextButton>
          <TextButton
            as="button"
            disabled={!cache.isValid}
            onClick={cache.isValid ? onNext : () => checkEmpty("orgA")}
            status={EStatus.ACCENT}>
            Next
          </TextButton>
        </Flexbox>
      </Cell>
    </>
  );
}
