import { useMemo } from "react";
import { Link } from "react-router-dom";

import {
  BigPopoverMenu,
  Flexbox,
  IconButton,
  InternalTextLink,
  P,
  PlainAction,
  Strong,
  TD,
  TR,
} from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  formatLongTime,
  formatLongWordDate,
  PATH_SUPERADMIN_ORGANISATION,
  PATH_SUPERADMIN_SESSION,
  PATH_SUPERADMIN_SESSION_EDIT,
  PATH_SUPERADMIN_USER,
} from "packages/utils";

import { canJoinSession } from "packages/client/sessions/functions";

import { InProgress } from "packages/client/sessions/components/InProgress/InProgress";
import { IsRepeating } from "packages/client/sessions/components/IsRepeating/IsRepeating";

export interface PSuperAdminSessionRow {
  columns: string[];
  columnWidths: string;
  endTime: string;
  sessionID: string;
  nextRecurringEndTime?: string;
  nextRecurringStartTime?: string;
  organisationID?: string;
  organisationName?: string;
  ownerID?: string;
  ownerFullName?: string;
  prepareDeleteSession: (sessionID: string) => void;
  roomKey?: string;
  startTime: string;
  subject: string;
}

export function SuperAdminSessionRow({
  columns,
  columnWidths,
  endTime,
  nextRecurringEndTime,
  nextRecurringStartTime,
  organisationID,
  organisationName,
  ownerID,
  ownerFullName,
  prepareDeleteSession,
  roomKey,
  sessionID,
  startTime,
  subject,
}: PSuperAdminSessionRow) {
  const currentStartTime = useMemo(() => nextRecurringStartTime || startTime, [nextRecurringStartTime, startTime]);

  return (
    <TR columnWidths={columnWidths} key={sessionID}>
      <TD heading={columns[0]}>
        <P>
          <Strong>{sessionID}</Strong>
        </P>
      </TD>
      <TD heading={columns[1]}>
        <P>
          {formatLongTime(new Date(currentStartTime))}
          <InProgress endTime={endTime} startTime={currentStartTime} />
        </P>
        <P>
          {formatLongWordDate(new Date(currentStartTime))}
          {!!nextRecurringStartTime && <IsRepeating />}
        </P>
      </TD>
      <TD heading={columns[2]}>
        <P>
          <InternalTextLink to={PATH_SUPERADMIN_SESSION(sessionID)}>{subject}</InternalTextLink>
        </P>
      </TD>
      {ownerID && (
        <TD heading={columns[3]}>
          <P>
            <InternalTextLink to={PATH_SUPERADMIN_USER(ownerID)}>{ownerFullName}</InternalTextLink>
          </P>
        </TD>
      )}
      {organisationName && (
        <TD heading={columns[4]}>
          <P>
            <InternalTextLink to={PATH_SUPERADMIN_ORGANISATION(organisationID)}>{organisationName}</InternalTextLink>
          </P>
        </TD>
      )}
      <TD>
        <BigPopoverMenu
          attachment={EAttachment.SOUTH_SOUTH_EAST}
          trigger={
            <IconButton
              appearance={EAppearance.GHOST}
              as="div"
              description="Options"
              dimension={EDimension.SMALL}
              icon={EIcon.MOREVERTICAL}
              status={EStatus.NEUTRAL}
            />
          }>
          <Flexbox as="ul" flexDirectionColumn>
            {roomKey && canJoinSession(startTime, endTime, true, nextRecurringStartTime, nextRecurringEndTime) ? (
              <>
                <li>
                  <PlainAction as={Link} to={`/r/${roomKey}`}>
                    Join
                  </PlainAction>
                </li>
                <li>
                  <PlainAction as={Link} to={{ pathname: `/r/${roomKey}` }} state={{ moderate: true }}>
                    Moderate
                  </PlainAction>
                </li>
              </>
            ) : null}
            <li>
              <PlainAction as={Link} to={PATH_SUPERADMIN_SESSION_EDIT(sessionID)}>
                Edit
              </PlainAction>
            </li>
            <li>
              <PlainAction as={Link} to={PATH_SUPERADMIN_SESSION(sessionID)}>
                View
              </PlainAction>
            </li>
            <li>
              <PlainAction as="button" onClick={() => prepareDeleteSession(sessionID)} status={EStatus.DANGER}>
                Delete
              </PlainAction>
            </li>
          </Flexbox>
        </BigPopoverMenu>
      </TD>
    </TR>
  );
}
