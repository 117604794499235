import { useEffect, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import { Loader } from "packages/catalog";
import { ThemeProvider } from "packages/theming";
import {
  EParams,
  PATH_SESSIONS,
  PATH_SUPERADMIN_SESSIONS,
  SEGMENT_ADD,
  SEGMENT_ALL,
  SEGMENT_ANNOUNCEMENTS,
  SEGMENT_CALL,
  SEGMENT_CALLLINK,
  SEGMENT_CHANGEPASSWORD,
  SEGMENT_CHAT,
  SEGMENT_CHILDREN,
  SEGMENT_CREATE,
  SEGMENT_DELETE,
  SEGMENT_DEVICES,
  SEGMENT_EDIT,
  SEGMENT_EMAILCONFIRMATION,
  SEGMENT_EXTENDCALL,
  SEGMENT_FEATUREFLAGS,
  SEGMENT_FEEDBACK,
  SEGMENT_FORGOTPASSWORD,
  SEGMENT_HUBS,
  SEGMENT_JOINCALL,
  SEGMENT_JOINOPTIONS,
  SEGMENT_LAYOUT,
  SEGMENT_ORGANISATIONS,
  SEGMENT_PARTICIPANTS,
  SEGMENT_PENDING,
  SEGMENT_POLLS,
  SEGMENT_POSTCALL,
  SEGMENT_PRECALL,
  SEGMENT_PROFILE,
  SEGMENT_RECORDINGS,
  SEGMENT_REGISTER,
  SEGMENT_REPLAY,
  SEGMENT_REPORT,
  SEGMENT_RESETPASSWORD,
  SEGMENT_ROOMSYSTEMS,
  SEGMENT_SCHEDULE,
  SEGMENT_SESSIONS,
  SEGMENT_SETTINGS,
  SEGMENT_SHARE,
  SEGMENT_SIGNIN,
  SEGMENT_SUPERADMIN,
  SEGMENT_USERS,
  SEGMENT_VOTE,
} from "packages/utils";

// hooks
import { useAuth } from "packages/client/authentication/hooks";
import { useGetEssentialOrganisationInfoOwnUserQuery } from "packages/client/users/graphql/users.generated";
import { useIsRecordingDisabled } from "packages/client/organisations/hooks/useIsRecordingDisabled";
import { useTheme } from "packages/client/layout/hooks/useTheme";
import { useTypedSelector } from "packages/client/redux";
import useEvents from "packages/client/useEvents";

// contexts, clients & containers
import { createClient } from "packages/client/graphql/apolloClient";
import { SnackbarFeedbackContext } from "packages/client/snackbar-feedback/contexts/SnackbarFeedbackContext";
import { RegisterContainer } from "packages/client/onboarding/containers";

// layouts
import {
  BasicLayout,
  DashboardLayout,
  IncallSidebarLayout,
  PlayerLayout,
  SuperAdminLayout,
  SuperAdminOrganisationLayout,
  SuperAdminOrganisationsLayout,
  SuperAdminUsersLayout,
  UserSettingsLayout,
} from "packages/client/layout/components";

// components
import { Announcement, SuperAdminAnnouncements } from "packages/client/announcements/components";
import {
  ForgotPassword,
  ResetPassword,
  RequireAuthentication,
  RequireAuthorisation,
  SignIn,
  Welcome,
} from "packages/client/authentication/components";
import { ErrorBoundary, NoMatch } from "packages/client/errors/components";
import { SuperAdminFeatureFlags } from "packages/client/feature-flags/components";
import { Hubs } from "packages/client/hubs/components";
import {
  Call,
  DeviceSettings,
  ElectronShareScreen,
  ExtendCall,
  IncallChat,
  InCallFeedback,
  Layout,
  Participants,
  Settings,
} from "packages/client/incall/components";
import { RoutingChangeMessage } from "packages/client/layout/components";
import { NewVersionModal } from "packages/client/modals/components";
import { EmailAddressVerificationResult } from "packages/client/onboarding/components";
import {
  AllOrganisations,
  ChildOrganisations,
  EditOrganisation,
  GenerateReport,
  Organisation,
  Overview,
  SuperAdminPendingOrganisations,
  SuperAdminOrganisationChildren,
  SuperAdminOrganisationManager,
  SuperAdminOrganisationOverview,
  SuperAdminOrganisationRoomSystems,
  SuperAdminOrganisations,
  SuperAdminOrganisationSessions,
  SuperAdminOrganisationUsers,
} from "packages/client/organisations/components";
import { CreatePoll, Poll, Polls, Vote } from "packages/client/polls/components";
import { LeftSession } from "packages/client/postcall/components";
import { ChooseDevices, JoinCall, JoinMethod, JoinOptions } from "packages/client/precall/components";
import { Recording, Recordings } from "packages/client/recordings/components";
import {
  RoomSystem,
  RoomSystemForm,
  RoomSystems,
  SuperAdminRoomSystems,
} from "packages/client/room-systems/components";
import {
  Session,
  Sessions,
  SessionFormResolver,
  SuperAdminSession,
  SuperAdminSessions,
} from "packages/client/sessions/components";
import { SnackbarFeedback } from "packages/client/snackbar-feedback/components";
import {
  ChangePassword,
  EditUser,
  PrivacyPolicyModal,
  SuperAdminPendingUsers,
  SuperAdminUser,
  SuperAdminUsers,
  User,
  Users,
  UserSettings,
} from "packages/client/users/components";

export default function ApolloWrapper() {
  const { token } = useTypedSelector(({ authentication }) => ({
    token: authentication.token,
  }));

  const apolloClient = useMemo(() => createClient(token), [token]);

  return (
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  );
}

function App() {
  const { isSignedIn, theme } = useTypedSelector(({ authentication, layout }) => ({
    isSignedIn: authentication.isSignedIn,
    theme: layout.theme,
  }));
  const { isSigningIn } = useAuth();
  const { autoLoadTheme } = useTheme();

  const { isRecordingDisabled } = useIsRecordingDisabled();
  const { data: userOwnData } = useGetEssentialOrganisationInfoOwnUserQuery();

  useEvents();

  useEffect(() => {
    autoLoadTheme();
  }, [autoLoadTheme]);

  if (isSigningIn || isSignedIn === null) return <Loader message="Signing in..." />;

  return (
    <ErrorBoundary>
      <SnackbarFeedbackContext>
        <RoutingChangeMessage />
        <ThemeProvider theme={theme} />
        <Announcement />
        <PrivacyPolicyModal />
        {!IS_ELECTRON && <NewVersionModal />}
        <Routes>
          <Route element={<BasicLayout isWide />}>
            <Route element={<Welcome />} index />
            <Route element={<JoinMethod />} path={SEGMENT_CALLLINK} />
            <Route element={<JoinMethod />} path={`${SEGMENT_CALLLINK}/:${EParams.ID_CALL}`} />
            <Route element={<LeftSession />} path={`${SEGMENT_POSTCALL}/:${EParams.ID_CALL}`} />
          </Route>
          <Route element={<BasicLayout />}>
            <Route element={<Navigate to={SEGMENT_SIGNIN} />} path={"/auth/*"} />
            <Route element={<SignIn />} path={SEGMENT_SIGNIN} />
            <Route element={<SignIn />} path={`${SEGMENT_SIGNIN}/:${EParams.ID_CALL}`} />
            <Route element={<ForgotPassword />} path={SEGMENT_FORGOTPASSWORD} />
            <Route element={<ForgotPassword />} path={`${SEGMENT_FORGOTPASSWORD}/:${EParams.ID_CALL}`} />
            <Route element={<ResetPassword />} path={SEGMENT_RESETPASSWORD} />
            <Route element={<ResetPassword />} path={`${SEGMENT_RESETPASSWORD}/:${EParams.ID_CALL}`} />
            <Route element={<Vote />} path={`${SEGMENT_POLLS}/:${EParams.ID_POLL}/${SEGMENT_VOTE}`} />
            <Route element={<RegisterContainer />} path={SEGMENT_REGISTER} />
            <Route
              element={<EmailAddressVerificationResult />}
              path={`${SEGMENT_EMAILCONFIRMATION}/:${EParams.CODE}/:${EParams.TIMESTAMP}`}
            />
          </Route>
          <Route element={<PlayerLayout />}>
            <Route element={<Recording />} path={`${SEGMENT_REPLAY}/:${EParams.ID_RECORDING}`} />
          </Route>
          <Route path={SEGMENT_PRECALL}>
            <Route element={<BasicLayout />}>
              <Route element={<Navigate to={SEGMENT_JOINOPTIONS} />} index />
              <Route element={<JoinOptions />} path={SEGMENT_JOINOPTIONS} />
              <Route element={<JoinOptions />} path={`${SEGMENT_JOINOPTIONS}/:${EParams.ID_CALL}`} />
              <Route element={<JoinCall />} path={SEGMENT_JOINCALL} />
              <Route element={<JoinCall />} path={`${SEGMENT_JOINCALL}/:${EParams.ID_CALL}`} />
            </Route>
            <Route element={<BasicLayout isWide />}>
              <Route element={<ChooseDevices />} path={`${SEGMENT_DEVICES}`} />
              <Route element={<ChooseDevices />} path={`${SEGMENT_DEVICES}/:${EParams.ID_CALL}`} />
            </Route>
          </Route>
          <Route element={<Call />} path={`${SEGMENT_CALL}/:${EParams.ID_CALL}`}>
            <Route element={<IncallSidebarLayout />}>
              <Route element={<IncallChat />} path={SEGMENT_CHAT} />
              <Route element={<Participants />} path={SEGMENT_PARTICIPANTS} />
              <Route path={SEGMENT_SETTINGS}>
                <Route element={<Settings />} index />
                <Route element={<DeviceSettings />} path={SEGMENT_DEVICES} />
                <Route element={<Layout />} path={SEGMENT_LAYOUT} />
                <Route element={<ExtendCall />} path={SEGMENT_EXTENDCALL} />
                <Route element={<InCallFeedback />} path={SEGMENT_FEEDBACK} />
              </Route>
              {IS_ELECTRON && <Route element={<ElectronShareScreen />} path={SEGMENT_SHARE} />}
            </Route>
          </Route>
          <Route
            element={
              <RequireAuthentication>
                <DashboardLayout />
              </RequireAuthentication>
            }>
            <Route element={<Navigate to={PATH_SESSIONS} />} index />
            <Route element={<Navigate to={PATH_SESSIONS} />} path={"/meetings/*"} />
            <Route path={SEGMENT_HUBS}>
              <Route element={<Hubs />} index />
            </Route>
            <Route path={SEGMENT_POLLS}>
              <Route element={<Polls />} index />
              <Route element={<Poll />} path={`:${EParams.ID_POLL}`} />
              <Route element={<CreatePoll />} path={SEGMENT_CREATE} />
            </Route>
            <Route path={SEGMENT_SESSIONS}>
              <Route element={<Sessions />} index />
              <Route element={<Session />} path={`:${EParams.ID_MEETING}`} />
              <Route element={<SessionFormResolver />} path={`:${EParams.ID_MEETING}/${SEGMENT_EDIT}`} />
              <Route element={<SessionFormResolver />} path={SEGMENT_SCHEDULE} />
            </Route>
            <Route element={<UserSettingsLayout />} path={SEGMENT_PROFILE}>
              <Route element={<Navigate to={SEGMENT_EDIT} />} index />
              <Route element={<EditUser />} path={SEGMENT_EDIT} />
              <Route element={<ChangePassword />} path={SEGMENT_CHANGEPASSWORD} />
              <Route element={<UserSettings />} path={SEGMENT_SETTINGS} />
            </Route>
            <Route path={SEGMENT_USERS}>
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <Users />
                  </RequireAuthorisation>
                }
                index
              />
              <Route element={<User />} path={`:${EParams.ID_USER}`} />
            </Route>
            <Route path={SEGMENT_ORGANISATIONS}>
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <Overview />
                  </RequireAuthorisation>
                }
                index
              />
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <ChildOrganisations />
                  </RequireAuthorisation>
                }
                path={SEGMENT_CHILDREN}
              />
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <AllOrganisations />
                  </RequireAuthorisation>
                }
                path={SEGMENT_ALL}
              />
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <EditOrganisation />
                  </RequireAuthorisation>
                }
                path={`:${EParams.ID_ORGANISATION}/${SEGMENT_EDIT}`}
              />
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <GenerateReport />
                  </RequireAuthorisation>
                }
                path={`:${EParams.ID_ORGANISATION}/${SEGMENT_REPORT}`}
              />
              <Route element={<Organisation />} path={`:${EParams.ID_ORGANISATION}`} />
            </Route>
            <Route path={SEGMENT_ROOMSYSTEMS}>
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <RoomSystems />
                  </RequireAuthorisation>
                }
                index
              />
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <RoomSystemForm />
                  </RequireAuthorisation>
                }
                path={SEGMENT_ADD}
              />
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <RoomSystem />
                  </RequireAuthorisation>
                }
                path={`:${EParams.ID_ROOMSYSTEM}`}
              />
              <Route
                element={
                  <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin]}>
                    <RoomSystemForm />
                  </RequireAuthorisation>
                }
                path={`:${EParams.ID_ROOMSYSTEM}/${SEGMENT_EDIT}`}
              />
            </Route>
            <Route path={SEGMENT_RECORDINGS}>
              <Route
                element={
                  <RequireAuthorisation conditions={[!isRecordingDisabled, userOwnData?.user?.isAdmin]}>
                    <Recordings />
                  </RequireAuthorisation>
                }
                index
              />
            </Route>
            <Route
              element={
                <RequireAuthorisation conditions={[userOwnData?.user?.isSuperuser]}>
                  <SuperAdminLayout />
                </RequireAuthorisation>
              }
              path={SEGMENT_SUPERADMIN}>
              <Route element={<Navigate to={PATH_SUPERADMIN_SESSIONS} />} index />
              <Route path={SEGMENT_SESSIONS}>
                <Route element={<SuperAdminSessions />} index />
                <Route element={<SuperAdminSession />} path={`:${EParams.ID_MEETING}`} />
                <Route element={<SessionFormResolver />} path={`:${EParams.ID_MEETING}/${SEGMENT_EDIT}`} />
              </Route>
              <Route path={SEGMENT_ORGANISATIONS}>
                <Route element={<SuperAdminOrganisationsLayout />}>
                  <Route element={<SuperAdminOrganisations />} index />
                  <Route element={<SuperAdminPendingOrganisations />} path={SEGMENT_PENDING} />
                </Route>
                <Route element={<SuperAdminOrganisationLayout />} path={`:${EParams.ID_ORGANISATION}`}>
                  <Route element={<SuperAdminOrganisationOverview />} index />
                  <Route element={<SuperAdminOrganisationSessions />} path={SEGMENT_SESSIONS} />
                  <Route element={<SuperAdminOrganisationChildren />} path={SEGMENT_CHILDREN} />
                  <Route element={<SuperAdminOrganisationUsers />} path={SEGMENT_USERS} />
                  <Route element={<SuperAdminOrganisationRoomSystems />} path={SEGMENT_ROOMSYSTEMS} />
                </Route>
                <Route element={<SuperAdminOrganisationManager />} path={SEGMENT_DELETE} />
                <Route element={<GenerateReport />} path={`:${EParams.ID_ORGANISATION}/${SEGMENT_REPORT}`} />
              </Route>
              <Route path={SEGMENT_USERS}>
                <Route element={<SuperAdminUsersLayout />}>
                  <Route element={<SuperAdminUsers />} index />
                  <Route element={<SuperAdminPendingUsers />} path={SEGMENT_PENDING} />
                </Route>
                <Route element={<SuperAdminUser />} path={`:${EParams.ID_USER}`} />
              </Route>
              <Route path={SEGMENT_ROOMSYSTEMS}>
                <Route element={<SuperAdminRoomSystems />} index />
                <Route element={<RoomSystemForm />} path={SEGMENT_ADD} />
                <Route element={<RoomSystemForm />} path={`:${EParams.ID_ROOMSYSTEM}/${SEGMENT_EDIT}`} />
              </Route>
              <Route element={<SuperAdminAnnouncements />} path={SEGMENT_ANNOUNCEMENTS} />
              <Route element={<SuperAdminFeatureFlags />} path={SEGMENT_FEATUREFLAGS} />
              {/* <Route element={<SuperAdminPods />} path={SEGMENT_PODS} /> */}
            </Route>
          </Route>
          <Route element={<NoMatch />} path="*" />
        </Routes>
        <SnackbarFeedback />
      </SnackbarFeedbackContext>
    </ErrorBoundary>
  );
}
