import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { Block, Cell, Feedback, Flexbox, Grid, H2, TextButton } from "packages/catalog";
import { EAppearance, PATH_ORGANISATION, EStatus } from "packages/utils";

import { acceptConnection, deleteConnection } from "packages/client/organisations/django/requests";
import { useGetConnections } from "packages/client/organisations/hooks";

import { OrganisationSummary } from "packages/client/organisations/components";

export interface PIncomingConnectionRequests {
  refetchConnections: () => void;
}

export function IncomingConnectionRequests({ refetchConnections }: PIncomingConnectionRequests) {
  const [showRespondErrorMessage, setShowRespondErrorMessage] = useState(false);
  const {
    connections: { incoming },
  } = useGetConnections();

  const handleOnClickAccept = useCallback(
    (organisationID: string) => {
      setShowRespondErrorMessage(false);

      acceptConnection(organisationID)
        .then(() => {
          refetchConnections();
        })
        .catch(() => setShowRespondErrorMessage(true));
    },
    [refetchConnections],
  );

  const handleOnClickDelete = useCallback(
    (organisationID: string) => {
      setShowRespondErrorMessage(false);

      deleteConnection(organisationID)
        .then(() => {
          refetchConnections();
        })
        .catch(() => setShowRespondErrorMessage(true));
    },
    [refetchConnections],
  );

  return (
    <>
      {showRespondErrorMessage && (
        <Cell>
          <Feedback status={EStatus.DANGER}>Unable to respond to this request. Please try again.</Feedback>
        </Cell>
      )}
      <Cell as={Block}>
        <H2 hasMargin>Connection requests</H2>
        <Grid as="ul">
          {incoming.map(organisationID => (
            <Cell as="li" colSpanLG={6} key={organisationID}>
              <Flexbox alignItemsCenter as={Block} justifyContentSpaceBetween>
                <Flexbox alignItemsCenter>
                  <Link style={{ display: "block" }} to={PATH_ORGANISATION(`${organisationID}`)}>
                    <OrganisationSummary organisationID={`${organisationID}`} />
                  </Link>
                </Flexbox>
                <Flexbox>
                  <TextButton appearance={EAppearance.GHOST} onClick={() => handleOnClickDelete(`${organisationID}`)}>
                    Decline
                  </TextButton>
                  <TextButton onClick={() => handleOnClickAccept(`${organisationID}`)} status={EStatus.ACCENT}>
                    Accept
                  </TextButton>
                </Flexbox>
              </Flexbox>
            </Cell>
          ))}
        </Grid>
      </Cell>
    </>
  );
}
