import { useMemo } from "react";

import { P, TextButton } from "packages/catalog";
import { EAppearance, EStatus } from "packages/utils";

import styles from "./ShowMore.module.scss";

export interface PShowMore {
  currentVolume: number;
  hasMore?: boolean;
  item: string;
  maxVolume?: number;
  onShowMore: () => void;
}

export function ShowMore({ currentVolume, hasMore, item, maxVolume, onShowMore }: PShowMore) {
  const volumeText = useMemo(() => {
    return `${currentVolume} ${maxVolume ? `of ${maxVolume} ` : ""}${item.toLowerCase()}s`;
  }, [currentVolume, item, maxVolume]);

  const percentage = useMemo(() => `${currentVolume}%`, [currentVolume]);

  return (
    <div className={styles.showMore}>
      <P className={styles.text}>Showing {volumeText}</P>
      {maxVolume && (
        <meter className={styles.meter} max={maxVolume} min={0} value={currentVolume}>
          <div className={styles.meterFallback}>
            <span className={styles.meterBarFallback} style={{ width: percentage }} />
          </div>
        </meter>
      )}
      {(hasMore || currentVolume < maxVolume) && (
        <TextButton appearance={EAppearance.FILL} onClick={onShowMore} status={EStatus.NEUTRAL}>
          Show more {item.toLowerCase()}s
        </TextButton>
      )}
    </div>
  );
}
