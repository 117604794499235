import { Outlet } from "react-router-dom";

import styles from "./IncallSidebarLayout.module.scss";

export function IncallSidebarLayout() {
  return (
    <aside className={styles.sidebar}>
      <Outlet />
    </aside>
  );
}
