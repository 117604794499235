import type { AnchorHTMLAttributes } from "react";

import styles from "./ExternalTextLink.module.scss";

export function ExternalTextLink({ children, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>) {
  return (
    <a className={styles.link} rel="noopener noreferrer" target="_blank" {...rest}>
      {children}
    </a>
  );
}
