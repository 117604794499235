import { useMemo } from "react";

import { Cell, ExternalTextLink, Flexbox, H1, H2, P, TextButton } from "packages/catalog";
import { EAppearance, EStatus } from "packages/utils";

import { OrganisationPricingPlan } from "packages/client/graphql_definitions.generated";

import type { SScreen } from "packages/client/onboarding/containers";
import type { Screens } from "packages/client/onboarding/enums";

import { PackageOption } from "packages/client/onboarding/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

export interface PCreateOrgStep3 {
  cache: SScreen;
  goTo: (screen: Screens.ChooseOrg | Screens.CreateOrgStep1 | Screens.CreateOrgStep2) => void;
  onChange: (e: { plan: OrganisationPricingPlan }) => void;
  onNext: () => void;
  onPrevious: () => void;
}

export function CreateOrgStep3({ cache, onChange, onPrevious, onNext }: PCreateOrgStep3) {
  const plans = useMemo(() => {
    function handleClick(plan: OrganisationPricingPlan) {
      onChange({ plan: plan === cache.plan ? null : plan });
    }

    return {
      [OrganisationPricingPlan.Schools]: {
        byline: "Free use for primary and secondary schools (limited features).",
        cta: "Choose plan",
        heading: OrganisationPricingPlan.Schools,
        price: 0,
        onClick: () => handleClick(OrganisationPricingPlan.Schools),
      },
      [OrganisationPricingPlan.Team]: {
        byline: "Solution suitable for small teams and organisations.",
        cta: "Choose plan",
        heading: OrganisationPricingPlan.Team,
        price: 245,
        onClick: () => handleClick(OrganisationPricingPlan.Team),
      },
      [OrganisationPricingPlan.Campus]: {
        byline: "Solution suitable for medium sized organisations.",
        cta: "Choose plan",
        heading: OrganisationPricingPlan.Campus,
        price: 550,
        onClick: () => handleClick(OrganisationPricingPlan.Campus),
      },
      [OrganisationPricingPlan.SuperOrg]: {
        byline: "Offers a scalable option for roll out across large organisations.",
        cta: "Choose plan",
        heading: OrganisationPricingPlan.SuperOrg,
        price: "POA",
        onClick: () => handleClick(OrganisationPricingPlan.SuperOrg),
      },
    };
  }, [cache, onChange]);

  return (
    <>
      <RoutingChangeHandler
        message="The create organisation step three page has loaded."
        pageTitle="Create an organistion"
      />
      <Cell>
        <H1 center hasMargin>
          Choose your plan
        </H1>
        <P hasMargin>Choose a plan best suited to you from the list.</P>
        <P>
          Not sure which plan to choose?{" "}
          <ExternalTextLink href="https://classview.com/pricing">View full comparison of features</ExternalTextLink>{" "}
          (opens in a new tab).
        </P>
      </Cell>
      <Cell>
        <P>Step 3 of 3</P>
      </Cell>
      <Cell>
        <H2>Pricing plan</H2>
      </Cell>
      {Object.entries(plans).map(([k, plan]) => (
        <Cell key={k}>
          <PackageOption
            byline={plan.byline}
            cta={plan.cta}
            heading={plan.heading}
            onClick={plan.onClick}
            price={plan.price}
            selected={cache.plan === plan.heading}
          />
        </Cell>
      ))}
      <Cell>
        <Flexbox justifyContentSpaceBetween>
          <TextButton appearance={EAppearance.GHOST} as="button" onClick={onPrevious}>
            Back
          </TextButton>
          <TextButton
            as="button"
            disabled={!cache.isValid}
            onClick={cache.isValid ? onNext : undefined}
            status={EStatus.ACCENT}>
            Next
          </TextButton>
        </Flexbox>
      </Cell>
    </>
  );
}
