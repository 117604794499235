import type { ISortableMessage } from "packages/client/incall/interfaces";

export function msgCompare(a: ISortableMessage, b: ISortableMessage) {
  return a.datetime.localeCompare(b.datetime, "en");
}

export function uniqSortedMessages<T extends ISortableMessage>(messArray: T[]): T[] {
  if (!messArray || messArray.length < 2) return messArray;
  return messArray.sort(msgCompare).filter((item, pos, array) => {
    return !pos || msgCompare(item, array[pos - 1]);
  });
}
