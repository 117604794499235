import { Outlet } from "react-router-dom";

import { Cell, Grid, NavigationList, NavigationLink } from "packages/catalog";
import {
  SEGMENT_ANNOUNCEMENTS,
  SEGMENT_FEATUREFLAGS,
  SEGMENT_SESSIONS,
  SEGMENT_ORGANISATIONS,
  SEGMENT_ROOMSYSTEMS,
  SEGMENT_USERS,
} from "packages/utils";

export function SuperAdminLayout() {
  return (
    <Grid noColGap>
      <Cell>
        <NavigationList>
          <NavigationLink to={SEGMENT_SESSIONS}>Sessions</NavigationLink>
          <NavigationLink to={SEGMENT_ORGANISATIONS}>Organisations</NavigationLink>
          <NavigationLink to={SEGMENT_USERS}>Users</NavigationLink>
          <NavigationLink to={SEGMENT_ROOMSYSTEMS}>Room Systems</NavigationLink>
          <NavigationLink to={SEGMENT_ANNOUNCEMENTS}>Announcements</NavigationLink>
          <NavigationLink to={SEGMENT_FEATUREFLAGS}>Feature Flags</NavigationLink>
        </NavigationList>
      </Cell>
      <Cell>
        <Outlet />
      </Cell>
    </Grid>
  );
}
