import { HTMLProps, useMemo } from "react";

import { useMergedClassNames } from "packages/utils";

import styles from "./Label.module.scss";

export interface PLabel extends HTMLProps<HTMLLabelElement> {
  children?: JSX.Element;
  disabled?: boolean;
  error?: boolean;
  hasMargin?: boolean;
  isVisuallyHidden?: boolean;
  text: string;
}

export function Label({ children, className, text, error, disabled, hasMargin, isVisuallyHidden, ...rest }: PLabel) {
  const labelClasses = useMergedClassNames({ className }, [
    styles.label,
    error && styles.error,
    disabled && styles.disabled,
    hasMargin && styles.hasMargin,
    isVisuallyHidden && styles.visuallyHidden,
  ]);
  const requiredClasses = useMemo(
    () => [styles.required, disabled && styles.disabled].filter(e => e).join(" "),
    [disabled],
  );

  return (
    <label {...rest}>
      <span className={labelClasses}>
        {text}
        {children?.props?.required && (
          <span aria-hidden className={requiredClasses}>
            *
          </span>
        )}
      </span>
      {children}
    </label>
  );
}
