import { dispatchVidyo } from "../../redux";
import { EVidyoRecordingActions } from "../../recording/redux/types";
import type { TVidyoConnector } from "../types";

export async function createRecordingLifecycle(vidyoConnector: TVidyoConnector) {
  await vidyoConnector.RegisterRecorderInCallEventListener({
    onRecorderInCallChanged: (hasRecorder, isPaused) =>
      dispatchVidyo({
        type: EVidyoRecordingActions.RECORDING_CHANGE,
        isPaused: isPaused,
        isRecording: hasRecorder,
      }),
  });
}
