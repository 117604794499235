import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import {
  Block,
  Cell,
  Dropdown,
  Feedback,
  Field,
  Fieldset,
  Grid,
  H1,
  IconButton,
  Input,
  InputOption,
  LabelledContent,
  Loader,
} from "packages/catalog";
import {
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  roomSystemConnectionType,
  roomSystemConnectionTypeLabel,
  scrollToContentTop,
  SEGMENT_SUPERADMIN,
  TParams,
} from "packages/utils";

import { RoomSystemPrivacy, RoomSystemType } from "packages/client/graphql_definitions.generated";
import type { IMutableRoomSystem } from "packages/client/room-systems/graphql/types";

import { useGetFullRoomSystemQuery } from "packages/client/room-systems/graphql/roomsystems.generated";
import { useGetEssentialOrganisationInfoOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { getAllOrganisations } from "packages/client/organisations/django/requests";
import { addRoomSystem, editRoomSystem } from "packages/client/room-systems/django/requests";

import { useIsSchoolsPlan } from "packages/client/organisations/hooks";
import { useRoomSystemsUsage } from "packages/client/room-systems/hooks";

import { RequireAuthorisation } from "packages/client/authentication/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

const defaultFormState = {
  id: "",
  ip: "",
  name: "",
  notificationEmail: "",
  organisationID: "",
  privacy: RoomSystemPrivacy.Organisation,
  type: RoomSystemType.Vsceneroom,
};

export function RoomSystemForm() {
  const { pathname } = useLocation();
  const { roomsystemID }: TParams = useParams();

  const [form, setForm] = useState<Partial<IMutableRoomSystem>>(defaultFormState);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [organisations, setOrganisations] = useState(null);

  const {
    data: roomSystemData,
    loading: roomSystemDataLoading,
    refetch: roomSystemDataRefetch,
  } = useGetFullRoomSystemQuery({
    variables: { roomsystemID: parseInt(roomsystemID) },
  });
  const { data: userOwnData, loading: userOwnDataLoading } = useGetEssentialOrganisationInfoOwnUserQuery();

  const { isRoomsLimitReached } = useRoomSystemsUsage();
  const { isSchoolsPlan } = useIsSchoolsPlan();

  const getOrganisations = useCallback(() => {
    if (!userOwnData?.user?.isSuperuser) return;

    setErrorMessage(null);

    getAllOrganisations()
      .then(response => {
        const allOrganisations = response.map(organisation => ({ key: organisation.id, value: organisation.name }));

        allOrganisations.unshift({ key: null, value: "Your organisation (default)" });

        setOrganisations(allOrganisations);
      })
      .catch(reason => setErrorMessage(reason.message));
  }, [userOwnData?.user?.isSuperuser]);

  // user must be an admin of the room system's organisation or parent organisation, or a superadmin
  const hasAdminRightsToOrganisation = useMemo(
    () =>
      userOwnData?.user?.isSuperuser ||
      (userOwnData?.user?.isAdmin &&
        (roomSystemData?.roomSystem?.organisation?.id === userOwnData?.user?.organisation?.id ||
          roomSystemData?.roomSystem?.organisation?.parentOrganisation?.id === userOwnData?.user?.organisation?.id)),
    [roomSystemData, userOwnData],
  );

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const handleChangeDropdown = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setErrorMessage(null);
      setShowSuccessMessage(false);

      if (roomsystemID) {
        editRoomSystem(form)
          .then(() => {
            roomSystemDataRefetch();
            scrollToContentTop();
            setShowSuccessMessage(true);
          })
          .catch(reason => {
            scrollToContentTop();
            setErrorMessage(reason.message);
          });
      } else {
        addRoomSystem(form)
          .then(() => {
            setForm(defaultFormState);
            scrollToContentTop();
            setShowSuccessMessage(true);
          })
          .catch(reason => {
            scrollToContentTop();
            setErrorMessage(reason.message);
          });
      }
    },
    [form, roomSystemDataRefetch, roomsystemID],
  );

  useEffect(() => {
    getOrganisations();
  }, [getOrganisations]);

  useEffect(() => {
    if (roomSystemData && !roomSystemDataLoading) {
      setForm({
        id: roomSystemData.roomSystem.id ?? defaultFormState.id,
        ip: roomSystemData.roomSystem.ip ?? defaultFormState.ip,
        organisationID: roomSystemData.roomSystem.organisation.id ?? defaultFormState.organisationID,
        name: roomSystemData.roomSystem.name ?? defaultFormState.name,
        notificationEmail: roomSystemData.roomSystem.notificationEmail ?? defaultFormState.notificationEmail,
        privacy: roomSystemData.roomSystem.privacy ?? defaultFormState.privacy,
        type: roomSystemData.roomSystem.type ?? defaultFormState.type,
      });
    }
  }, [roomSystemData, roomSystemDataLoading]);

  if (roomSystemDataLoading || userOwnDataLoading) return <Loader />;

  // back-end restrictions mean the room system type cannot be changed if the room system limit is reached (even though the org is AT the limit, not over it). this means the type field needs to be disabled enmasse at limit, not adjusted as per conditions e.g. allow change *to* pod from SIP or H.323.
  return (
    <RequireAuthorisation conditions={[roomsystemID ? hasAdminRightsToOrganisation : true]}>
      <RoutingChangeHandler
        message={`The ${roomsystemID ? "edit" : "add"} Room System page has loaded`}
        pageTitle={`${roomsystemID ? "Edit" : "Add"} Room System`}
      />
      <Grid>
        <Cell>
          <IconButton
            appearance={EAppearance.GHOST}
            as={Link}
            description={roomsystemID ? "Cancel" : "Room Systems"}
            dimension={EDimension.SMALL}
            icon={EIcon.ARROWLEFT}
            label={roomsystemID ? "Cancel" : "Room Systems"}
            status={EStatus.NEUTRAL}
            to=".."
          />
        </Cell>
        <Cell>
          <H1>{roomsystemID ? "Edit" : "Add a"} Room System</H1>
        </Cell>
        {errorMessage && (
          <Cell>
            <Feedback status={EStatus.DANGER}>
              Unable to {roomsystemID ? "edit" : "add"} Room System. {errorMessage}
            </Feedback>
          </Cell>
        )}
        {showSuccessMessage && (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Room System {roomsystemID ? "edited" : "added"}.</Feedback>
          </Cell>
        )}
        <Cell as="form" onSubmit={handleSubmit}>
          <Grid noColGap>
            <Cell colSpanXL={6}>
              <Grid as={Block}>
                {roomsystemID ? (
                  <Cell>
                    <LabelledContent content={roomSystemData?.roomSystem?.organisation?.name} label="Organisation" />
                  </Cell>
                ) : (
                  <>
                    {userOwnData?.user?.isSuperuser && pathname?.includes(SEGMENT_SUPERADMIN) && organisations ? (
                      <Cell>
                        <Field label="Organisation">
                          <Dropdown
                            fixedOrderOptions={organisations}
                            name="organisationID"
                            onChange={handleChangeDropdown}
                            required
                            value={form.organisationID}
                          />
                        </Field>
                      </Cell>
                    ) : null}
                  </>
                )}
                <Cell>
                  <Field
                    description="Name your room system after the room it's operating in, e.g. Room 101, Room of Requirement, The War Room etc"
                    label="Name">
                    <Input name="name" onChange={handleChangeInput} required type="text" value={form.name} />
                  </Field>
                </Cell>
                <Cell>
                  <Fieldset
                    description={
                      form.privacy === RoomSystemPrivacy.Organisation
                        ? "Accessible to your organisations and connections only"
                        : "Accessible to all organisations"
                    }
                    label="Privacy level">
                    <InputOption label="Public">
                      <Input
                        checked={form.privacy === RoomSystemPrivacy.Public}
                        name="privacy"
                        onChange={handleChangeInput}
                        required
                        type="radio"
                        value={RoomSystemPrivacy.Public}
                      />
                    </InputOption>
                    <InputOption label="Private">
                      <Input
                        checked={form.privacy === RoomSystemPrivacy.Organisation}
                        name="privacy"
                        onChange={handleChangeInput}
                        required
                        type="radio"
                        value={RoomSystemPrivacy.Organisation}
                      />
                    </InputOption>
                  </Fieldset>
                </Cell>
                <Cell>
                  <Fieldset
                    description={
                      isSchoolsPlan
                        ? "As a Schools plan user, you can only create and access ClassView POD systems"
                        : isRoomsLimitReached
                        ? "Your organisation has reached the limit of H.323/E.164/IP and SIP systems"
                        : null
                    }
                    label="Type">
                    <InputOption label={roomSystemConnectionType.VSCENEROOM}>
                      <Input
                        checked={form.type === RoomSystemType.Vsceneroom}
                        disabled={isRoomsLimitReached}
                        name="type"
                        onChange={handleChangeInput}
                        required
                        type="radio"
                        value={RoomSystemType.Vsceneroom}
                      />
                    </InputOption>
                    <InputOption label={roomSystemConnectionType.H323}>
                      <Input
                        checked={form.type === RoomSystemType.H323}
                        disabled={isSchoolsPlan || isRoomsLimitReached}
                        name="type"
                        onChange={handleChangeInput}
                        required
                        type="radio"
                        value={RoomSystemType.H323}
                      />
                    </InputOption>
                    <InputOption label={roomSystemConnectionType.SIP}>
                      <Input
                        checked={form.type === RoomSystemType.Sip}
                        disabled={isSchoolsPlan || isRoomsLimitReached}
                        name="type"
                        onChange={handleChangeInput}
                        required
                        type="radio"
                        value={RoomSystemType.Sip}
                      />
                    </InputOption>
                  </Fieldset>
                </Cell>
                <Cell>
                  <Field label={roomSystemConnectionTypeLabel[form.type]}>
                    <Input name="ip" onChange={handleChangeInput} required type="text" value={form.ip} />
                  </Field>
                </Cell>
                <Cell>
                  <Field
                    description="Notifications for this Room System will be sent to this email address"
                    label="Email address">
                    <Input
                      name="notificationEmail"
                      onChange={handleChangeInput}
                      required
                      type="email"
                      value={form.notificationEmail}
                    />
                  </Field>
                </Cell>
              </Grid>
            </Cell>
            <Cell center>
              <Input type="submit" value={roomsystemID ? "Save" : "Add"} />
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </RequireAuthorisation>
  );
}
