import { ChangeEvent, useCallback, useState } from "react";

import { Dropdown, Field, Flexbox, IconButton, LabelledContent, PDropdown, TextButton } from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus } from "packages/utils";

export interface PEditableFieldSelect<T> {
  label: string;
  name: T;
  onSave: (field: T, newVal: string) => void;
  options: PDropdown["options"];
  value: string;
}

export function EditableFieldSelect<T>({ name, label, onSave, options, value }: PEditableFieldSelect<T>) {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value ?? "");

  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
  }, []);

  const handleSave = useCallback(() => {
    onSave(name, selectedValue);
    setIsEditing(false);
  }, [name, onSave, selectedValue]);

  return (
    <>
      {isEditing ? (
        <Flexbox alignItemsFlexEnd>
          <Field label={label}>
            <Dropdown autoFocus name="label" onChange={handleChange} options={options} value={selectedValue} />
          </Field>
          <TextButton status={EStatus.ACCENT} as="button" onClick={handleSave}>
            Save
          </TextButton>
        </Flexbox>
      ) : (
        <LabelledContent
          label={label}
          content={
            <Flexbox alignItemsCenter>
              <span>{options?.[selectedValue] ?? "-"}</span>
              <IconButton
                appearance={EAppearance.GHOST}
                as="button"
                description="Edit"
                dimension={EDimension.SMALL}
                icon={EIcon.EDIT}
                status={EStatus.NEUTRAL}
                onClick={() => setIsEditing(!isEditing)}
              />
            </Flexbox>
          }
        />
      )}
    </>
  );
}
