import { Cell, ExternalTextLink, Flexbox, Grid, H1, H2, P, TextButton } from "packages/catalog";
import { EAppearance, COUNTRIES_OPTIONS, REGEX_COUNTY, REGEX_POSTCODE, REGEX_TELEPHONE, EStatus } from "packages/utils";

import type { Screens } from "packages/client/onboarding/enums";

import { defaultRules } from "packages/client/onboarding/consts";

import { FormContainer as Form, SScreen } from "packages/client/onboarding/containers";

import { RoutingChangeHandler } from "packages/client/layout/components";

export interface PCreateOrgStep2 {
  cache: SScreen;
  checkEmpty: (screen: string) => void;
  checkingEmpty: boolean;
  emptyChecked: (screen: string) => void;
  goTo: (screen: Screens.ChooseOrg | Screens.CreateOrgStep1) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (formData: any) => void;
  onNext: () => void;
  onPrevious: () => void;
  onValidation: (isValid: boolean) => void;
}

export function CreateOrgStep2({
  cache,
  checkEmpty,
  checkingEmpty,
  emptyChecked,
  onChange,
  onNext,
  onPrevious,
  onValidation,
}: PCreateOrgStep2) {
  return (
    <>
      <RoutingChangeHandler
        message="The create organisation step two page has loaded."
        pageTitle="Create an organistion"
      />
      <Cell>
        <H1 center>Create an organisation</H1>
      </Cell>
      <Cell>
        <P>Step 2 of 3</P>
      </Cell>
      <Cell>
        <H2>Organisation address</H2>
      </Cell>
      <Cell>
        <Form
          checkingEmpty={checkingEmpty}
          emptyChecked={() => emptyChecked("orgB")}
          onValidation={onValidation}
          debounceValidation={150}>
          <Grid noColGap>
            <Cell>
              <Form.Input
                autoComplete="street-address"
                label="First line of address"
                name="street"
                onChange={street => onChange({ street })}
                required
                rules={defaultRules}
                value={cache.street ? `${cache.street}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Input
                autoComplete="address-level2"
                label="City"
                name="city"
                onChange={city => onChange({ city })}
                required
                rules={{ ...defaultRules, regex: { value: REGEX_COUNTY, message: "Invalid city." } }}
                value={cache.city ? `${cache.city}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Input
                autoComplete="postal-code"
                label="Postcode"
                name="postcode"
                onChange={postcode => onChange({ postcode })}
                required
                rules={{ ...defaultRules, regex: { value: REGEX_POSTCODE, message: "Invalid postcode." } }}
                value={cache.postcode ? `${cache.postcode}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Input
                autoComplete="address-level1"
                label="County"
                name="county"
                optional
                isRequired={false}
                rules={{ ...defaultRules, regex: { value: REGEX_COUNTY, message: "Invalid county." } }}
                onChange={county => onChange({ county })}
                value={cache.county ? `${cache.county}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Select
                autoComplete="country-name"
                defaultValue="-- Choose country --"
                label="Country"
                name="country"
                onChange={country => onChange({ country })}
                options={COUNTRIES_OPTIONS}
                required
                value={cache.country ? `${cache.country}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Input
                autoComplete="tel"
                label="Telephone number"
                name="tel"
                onChange={orgPhone => onChange({ orgPhone })}
                optional
                rules={{ ...defaultRules, regex: { value: REGEX_TELEPHONE, message: "Invalid telephone number." } }}
                value={cache.orgPhone ? `${cache.orgPhone}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Checkbox
                checked={cache.tnc}
                defaultChecked
                errorMessage="Please accept our terms and conditions."
                label={
                  <>
                    {" "}
                    I agree to the{" "}
                    <ExternalTextLink
                      target="blank"
                      rel="noopener noreferrer"
                      href="https://classview.com/terms-and-conditions-of-business/">
                      terms and conditions
                    </ExternalTextLink>{" "}
                    on behalf of my organisation.
                  </>
                }
                onChange={isChecked => onChange({ tnc: isChecked })}
                required
              />
            </Cell>
            <Cell>
              <P>
                By clicking ‘Next’, you agree to the service terms and conditions as set out in our{" "}
                <ExternalTextLink target="blank" rel="noopener noreferrer" href="https://classview.com/privacy-policy">
                  privacy policy
                </ExternalTextLink>
                .
              </P>
            </Cell>
          </Grid>
        </Form>
      </Cell>
      <Cell>
        <Flexbox justifyContentSpaceBetween>
          <TextButton appearance={EAppearance.GHOST} as="button" onClick={onPrevious}>
            Back
          </TextButton>
          <TextButton
            as="button"
            disabled={!cache.isValid}
            onClick={cache.isValid ? onNext : () => checkEmpty("orgB")}
            status={EStatus.ACCENT}>
            Next
          </TextButton>
        </Flexbox>
      </Cell>
    </>
  );
}
