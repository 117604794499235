import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";

import { Cell, Grid, H1, Field, Input, P, Strong, TextButton } from "packages/catalog";
import { EAppearance, TParams, PATH_RESETPASSWORD, PATH_SIGNIN, EStatus } from "packages/utils";

import { useResetPassword } from "packages/client/authentication/hooks";

import { RoutingChangeHandler } from "packages/client/layout/components";

export function ForgotPassword() {
  const { callID }: TParams = useParams();
  const [email, setEmail] = useState("");

  const { requestPasswordReset, passwordResetError, isEmailSubmitted } = useResetPassword();

  const handleEmailForResetPassword = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      requestPasswordReset(email);
    },
    [requestPasswordReset, email],
  );
  const handleChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  }, []);

  if (isEmailSubmitted) {
    return <Navigate to={PATH_RESETPASSWORD(callID)} />;
  }

  return (
    <>
      <RoutingChangeHandler message="The forgot password page has loaded." pageTitle="Forgot password" />
      <H1 center hasMargin>
        Forgotten your password?
      </H1>
      <P hasMargin>
        Please enter your email address below. We will send you an <Strong>8 digit code</Strong> which you can use to
        change your password. You may need to check your <Strong>spam folder</Strong>.
      </P>
      <Grid as="form" onSubmit={handleEmailForResetPassword} noColGap>
        <Cell>
          <Field
            alerts={passwordResetError?.length && [{ message: passwordResetError, status: EStatus.DANGER }]}
            label="Email address">
            <Input
              autoComplete="email"
              maxLength={50}
              name="email"
              onChange={handleChangeEmail}
              required
              type="email"
              value={email}
            />
          </Field>
        </Cell>
        <Cell center>
          <Input disabled={!email.length} type="submit" value="Request code" />
        </Cell>
        <Cell center>
          <TextButton appearance={EAppearance.GHOST} as={Link} to={PATH_SIGNIN(callID)}>
            Cancel
          </TextButton>
        </Cell>
      </Grid>
    </>
  );
}
