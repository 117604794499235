import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Cell, ConfirmActionModal, Feedback, Grid, H2, Loader, P, Table, TRTDTemp } from "packages/catalog";
import { EStatus, TParams, scrollToContentTop } from "packages/utils";

import { useGetSessionsOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";
import { deleteSession } from "packages/client/sessions/django/requests";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { SuperAdminSessionRow } from "packages/client/sessions/components/SuperAdminSessionRow/SuperAdminSessionRow";

const COLUMNS = ["ID", "When", "Name", "Owner"];
const COLUMN_WIDTHS = "4rem 24rem 1.5fr 1fr 2rem";

export function SuperAdminOrganisationSessions() {
  const [actionID, setActionID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const { organisationID }: TParams = useParams();

  const {
    data: organisationData,
    loading,
    refetch,
  } = useGetSessionsOrganisationQuery({
    variables: {
      organisationID: parseInt(organisationID, 10),
    },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const sessions = useMemo(
    () =>
      organisationData?.organisation?.userSet
        ?.flatMap(user => user?.meetingSet?.map(session => session))
        ?.sort((a, b) => new Date(b?.startTime).getTime() - new Date(a?.startTime).getTime()),
    [organisationData?.organisation?.userSet],
  );

  const handleDeleteSession = useCallback(() => {
    setSuccessMessage("");
    setErrorMessage("");

    scrollToContentTop();
    setIsDeleteModalOpen(false);

    deleteSession(actionID)
      .then(() => {
        refetch();
        setSuccessMessage("Session deleted.");
      })
      .catch(reason => {
        setErrorMessage(reason.message);
      });

    setActionID("");
  }, [actionID, refetch]);

  const prepareDeleteSession = useCallback((userID: string) => {
    setActionID(userID);
    setIsDeleteModalOpen(true);
  }, []);

  if (!organisationData) return null;
  if (loading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler message="The organisation sessions page has loaded." pageTitle="Organisation sessions" />
      <Grid>
        {errorMessage.length ? (
          <Cell>
            <Feedback status={EStatus.DANGER}>{errorMessage}</Feedback>
          </Cell>
        ) : null}
        {successMessage.length ? (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>{successMessage}</Feedback>
          </Cell>
        ) : null}
        <Cell>
          <H2>Sessions</H2>
        </Cell>
        <Cell>
          <Table
            columns={COLUMNS}
            columnWidths={COLUMN_WIDTHS}
            description="A table listing all sessions for this organisation">
            {sessions?.length ? (
              <>
                {sessions?.map(session => (
                  <SuperAdminSessionRow
                    columns={COLUMNS}
                    columnWidths={COLUMN_WIDTHS}
                    endTime={session.endTime}
                    key={session.id}
                    nextRecurringEndTime={session.nextRecurringEnd}
                    nextRecurringStartTime={session.nextRecurringStart}
                    ownerID={session.meetingOrganiser.id}
                    ownerFullName={`${session.meetingOrganiser.firstName} ${session.meetingOrganiser.lastName}`}
                    prepareDeleteSession={prepareDeleteSession}
                    roomKey={session.room?.key}
                    sessionID={session.id}
                    startTime={session.startTime}
                    subject={session.subject}
                  />
                ))}
              </>
            ) : (
              <TRTDTemp>
                <P center>This organisation has no sessions.</P>
              </TRTDTemp>
            )}
          </Table>
        </Cell>
      </Grid>
      <ConfirmActionModal
        action="delete"
        content="session"
        isOpen={isDeleteModalOpen}
        onAbort={() => setIsDeleteModalOpen(false)}
        onAction={handleDeleteSession}
        status={EStatus.DANGER}
      />
    </>
  );
}
