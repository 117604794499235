import { useMergedClassNames } from "packages/utils";
import type { HTMLProps } from "react";

import styles from "./Strong.module.scss";

export function Strong({ className, ...rest }: HTMLProps<HTMLSpanElement>) {
  const strongClasses = useMergedClassNames({ className }, [styles.strong]);

  return <strong {...rest} className={strongClasses} />;
}
