import { useMemo } from "react";

import { SESSION_CATEGORY, ESession, convIDFromRoomTypeLink } from "packages/utils";

import { useTypedSelector } from "packages/client/redux";
import { useGetFullRoomQuery } from "packages/client/incall/graphql/room.generated";

export function useRoom(callID?: string) {
  const { roomKey } = useTypedSelector(({ sessions }) => ({ roomKey: sessions.roomKey }));
  const roomKeyToFetch = callID ?? roomKey;
  const { data, loading, error } = useGetFullRoomQuery({
    variables: {
      roomKey: roomKeyToFetch,
    },
    skip: !roomKeyToFetch || roomKeyToFetch === "",
    returnPartialData: true,
  });

  const room = useMemo(() => data?.getAllRoomInfo, [data?.getAllRoomInfo]);
  const isModerator = useMemo(
    () => data?.getAllRoomInfo?.canUserModerate ?? false,
    [data?.getAllRoomInfo?.canUserModerate],
  );
  const isMeeting = useMemo(
    () => SESSION_CATEGORY.includes(room?.roomTypeLink?.type as ESession),
    [room?.roomTypeLink?.type],
  );
  const sessionID = useMemo(() => {
    if (!isMeeting) return undefined;
    const i = parseInt(room?.roomTypeLink?.id, 10);
    return isNaN(i) ? undefined : i;
  }, [isMeeting, room?.roomTypeLink?.id]);

  const convID = useMemo(
    () => convIDFromRoomTypeLink(room?.roomId, room?.roomTypeLink?.type as ESession),
    [room?.roomId, room?.roomTypeLink?.type],
  );

  return { room, loading, error, isModerator, isMeeting, sessionID, convID };
}
