import { HEADER_JSON, parseDjangoError } from "packages/utils";

import type { IMutableSession } from "packages/client/sessions/graphql/types";

import { toDjangoSession } from "packages/client/sessions/django/serialisers";

import { fetchDjangoAPIAuthenticated } from "packages/client/fetch";

export async function deleteSession(sessionID: string) {
  const response = await fetchDjangoAPIAuthenticated(`/meetings/${sessionID}/`, {
    method: "DELETE",
  });

  if (response.status !== 204) {
    await parseDjangoError(response);
  }
}

export async function editSession(sessionID: string, data: Partial<IMutableSession>) {
  const response = await fetchDjangoAPIAuthenticated(`/meetings/${sessionID}/`, {
    body: JSON.stringify(toDjangoSession(data, true)),
    headers: HEADER_JSON,
    method: "PATCH",
  });

  if (response.status !== 200) {
    throw new Error("Unable to update session.");
  }

  return response;
}

export async function scheduleSession(data: Partial<IMutableSession>) {
  const response = await fetchDjangoAPIAuthenticated("/meetings/", {
    method: "POST",
    body: JSON.stringify(toDjangoSession(data, true)),
    headers: HEADER_JSON,
  });

  return response;
}
