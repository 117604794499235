let isBlink = false;
let isChrome = IS_ELECTRON;
let isIE = false;
let isIOS = false;
let isMobile = false;
let isTablet = false;
let isOldEdge = false;
let isWindows = false;
const isLinuxElectron = IS_ELECTRON && NATIVE_PLATFORM === "linux";

if (!IS_ELECTRON && !IS_TEST) {
  const { blink, chrome, ie, iOS, mobile, oldEdge, windows, tablet } = parseWebPlatform();
  isBlink = blink;
  isChrome = chrome;
  isIE = ie;
  isIOS = iOS;
  isMobile = mobile;
  isOldEdge = oldEdge;
  isWindows = windows;
  isTablet = tablet;
}

function parseWebPlatform() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { getParser } = require("bowser");
  const browser = getParser(window.navigator.userAgent);
  return {
    blink: browser.getEngine().name === "Blink",
    chrome: browser.is("chrome"),
    ie: browser.is("ie"),
    iOS: browser.is("ios"),
    mobile: browser.is("mobile"),
    tablet: browser.is("tablet") || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1),
    oldEdge: browser.getEngine().name === "EdgeHTML",
    windows: browser.getOSName() === "Windows",
  };
}

export { isOldEdge, isMobile, isIE, isBlink, isWindows, isTablet, isLinuxElectron, isChrome, isIOS };
