import { useCallback, useEffect } from "react";

import {
  GetRaisedHandsUpdateDocument,
  GetRaisedHandsUpdateSubscription,
  GetRaisedHandsUpdateSubscriptionVariables,
  useGetRaisedHandsQuery,
  useLowerHandMutation,
  useRaiseHandMutation,
} from "packages/client/incall/graphql/raisehand.generated";
import { useTypedSelector } from "packages/client/redux";

import { useRoom } from "packages/client/incall/hooks";

export type IUseRaisehand = ReturnType<typeof useRaisehand>;

export function useRaisehand() {
  const { userName } = useTypedSelector(({ authentication }) => ({ userName: authentication.guestName }));

  const { convID } = useRoom();

  const { data, subscribeToMore } = useGetRaisedHandsQuery({
    variables: { convID },
    fetchPolicy: "cache-and-network",
    skip: !convID,
  });

  const raisedHands = data?.getRaisedHands ?? [];

  useEffect(() => {
    if (!convID) return;
    const unsub = subscribeToMore<GetRaisedHandsUpdateSubscription, GetRaisedHandsUpdateSubscriptionVariables>({
      document: GetRaisedHandsUpdateDocument,
      variables: {
        convID,
        userID: userName,
      },
      updateQuery: (state, { subscriptionData }) => {
        const { data: d } = subscriptionData;
        if (!d) return state;
        const { getRaisedHandsUpdate } = d;
        if (!getRaisedHandsUpdate) return state;
        return {
          ...state,
          getRaisedHands: getRaisedHandsUpdate,
        };
      },
    });
    return () => unsub();
  }, [subscribeToMore, convID, userName]);

  const [raisehandMut] = useRaiseHandMutation({
    variables: {
      convID: convID,
      userID: userName,
    },
  });

  const [unRaisehandMut] = useLowerHandMutation({
    variables: {
      convID: convID,
      userID: userName,
    },
  });

  const setRaisedHand = useCallback(
    (raised: boolean) => {
      if (raised) raisehandMut();
      else unRaisehandMut();
    },
    [raisehandMut, unRaisehandMut],
  );

  const unRaiseUserHand = useCallback(
    (userName: string) => {
      unRaisehandMut({
        variables: {
          convID: convID,
          userID: userName,
        },
      });
    },
    [convID, unRaisehandMut],
  );

  return {
    raisedHands,
    setRaisedHand,
    unRaiseUserHand,
  };
}
