import { useLocation } from "react-router-dom";

import { IllustratedCTA } from "packages/catalog";
import { EIllustration, PATH_TLD } from "packages/utils";

import { RoutingChangeHandler } from "packages/client/layout/components";

import styles from "./NoMatch.module.scss";

export function NoMatch() {
  const { pathname } = useLocation();

  return (
    <>
      <RoutingChangeHandler message="The 404 page page has loaded." pageTitle="Page not found" />
      <div className={styles.container}>
        <IllustratedCTA cta="Return home" illustration={EIllustration.PAGE_NOT_FOUND} to={PATH_TLD}>
          Sorry, <code>{pathname}</code> not found.
        </IllustratedCTA>
      </div>
    </>
  );
}
