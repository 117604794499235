import { isLinuxElectron } from "packages/utils";

import { dispatchVidyo } from "../../redux";
import { EVidyoSharingActions } from "../../sharing/redux/types";
import type { TVidyoConnector } from "../types";

export async function createSharingLifecycle(vidyoConnector: TVidyoConnector) {
  if (!IS_ELECTRON) return;
  if (isLinuxElectron) return;

  await vidyoConnector.RegisterLocalMonitorEventListener({
    onAdded: localMonitor => dispatchVidyo({ type: EVidyoSharingActions.ADD_MONITOR, monitor: localMonitor }),
    onRemoved: localMonitor => dispatchVidyo({ type: EVidyoSharingActions.REMOVE_MONITOR, monitor: localMonitor }),
    onSelected: localMonitor => dispatchVidyo({ type: EVidyoSharingActions.SELECT_MONITOR, monitor: localMonitor }),
    onStateUpdated: (localMonitor, state) => console.info("Monitor State Changed", localMonitor, state),
  });

  await vidyoConnector.RegisterLocalWindowShareEventListener({
    onAdded: async localWindowShare => {
      const pid = await localWindowShare.GetProcessId();
      if (pid === process.ppid) return;
      dispatchVidyo({ type: EVidyoSharingActions.ADD_WINDOW_SHARE, windowShare: localWindowShare });
    },
    onRemoved: localWindowShare =>
      dispatchVidyo({ type: EVidyoSharingActions.REMOVE_WINDOW_SHARE, windowShare: localWindowShare }),
    onSelected: localWindowShare =>
      dispatchVidyo({ type: EVidyoSharingActions.SELECT_WINDOW_SHARE, windowShare: localWindowShare }),
    onStateUpdated: (localWindowShare, state) => console.info("WindowShare State Changed", localWindowShare, state),
  });
}
