import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { AutocompleteDropdown, Field, IAutocompleteDropdownOption } from "packages/catalog";
import { EIcon, TParams, EStatus } from "packages/utils";

import { useTypedSelector } from "packages/client/redux";

import { ISession, useRecentSessions } from "packages/client/precall/hooks";
import { useValidateRoomKey } from "packages/client/precall/hooks";

export function SessionIDField() {
  const { callID: callIDFromURL }: TParams = useParams();
  const { guestName } = useTypedSelector(({ authentication }) => ({
    guestName: authentication.guestName,
  }));

  const [callID, setCallID] = useState(callIDFromURL ?? "");

  const { roomKeyError } = useValidateRoomKey(callID);

  const { recentSessions } = useRecentSessions();

  const recentSessionOptions: IAutocompleteDropdownOption[] = useMemo(() => {
    return recentSessions
      .filter((session: ISession) => session?.roomKey?.toLowerCase().includes(callID.toLowerCase()))
      .map((session: ISession) => {
        return {
          description: session.sessionName,
          id: session.roomKey,
          image: EIcon.ROTATECCW,
          name: session.extension ?? session.roomKey,
        };
      });
  }, [callID, recentSessions]);

  const handleCallIDChange = useCallback((callIDInput: string) => {
    const id = callIDInput.trim();
    setCallID(id);
  }, []);

  const handleMeetingIdInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleCallIDChange(event.target.value);
    },
    [handleCallIDChange],
  );

  const handleMeetingIdSelect = useCallback(
    (option: IAutocompleteDropdownOption) => {
      handleCallIDChange(option.id);
    },
    [handleCallIDChange],
  );

  useEffect(() => {
    if (callIDFromURL?.length) {
      setCallID(callIDFromURL);
    }
  }, [callIDFromURL]);

  return (
    <Field label="Session ID" alerts={roomKeyError?.length && [{ message: roomKeyError, status: EStatus.DANGER }]}>
      <AutocompleteDropdown
        autoFocus={guestName.length && !callID.length}
        optionsCollectiveName="Recent sessions"
        maxLength={50}
        name="roomKey"
        onOptionSelect={handleMeetingIdSelect}
        onChange={handleMeetingIdInput}
        options={recentSessionOptions}
        required
        value={callID}
      />
    </Field>
  );
}
