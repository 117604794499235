import { Suspense, useMemo } from "react";
import { Outlet } from "react-router-dom";

import { Loader } from "packages/catalog";

import { ErrorBoundary } from "packages/client/errors/components";
import { BasicFooter, BasicHeader } from "packages/client/layout/components";

import styles from "./BasicLayout.module.scss";

export interface PBasicLayout {
  isWide?: boolean;
}

export function BasicLayout({ isWide }: PBasicLayout) {
  const mainClasses = useMemo(() => [styles.main, isWide && styles.isWide].filter(e => e).join(" "), [isWide]);

  return (
    <>
      <div className={styles.grid}>
        <BasicHeader />
        <main className={mainClasses}>
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </main>
        <BasicFooter />
      </div>
    </>
  );
}
