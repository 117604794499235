import { Cell, H1, HR, TextButton } from "packages/catalog";
import { EAppearance } from "packages/utils";

import { Screens } from "packages/client/onboarding/enums";

import { PackageOption } from "packages/client/onboarding/components";
import { RoutingChangeHandler } from "packages/client/layout/components";

export interface PJoinOrCreate {
  goTo: (screen: Screens.ChooseOrg | Screens.CreateOrgStep1) => void;
  onPrevious: () => void;
}

export function JoinOrCreate({ goTo, onPrevious }: PJoinOrCreate) {
  return (
    <>
      <RoutingChangeHandler message="The choose organisation page has loaded." pageTitle="Choose Organisation" />
      <Cell>
        <H1 center hasMargin>
          Choose your organisation
        </H1>
      </Cell>
      <Cell>
        <PackageOption
          byline="Select this option if your organisation is already registered with ClassView."
          cta="Join an existing organisation"
          heading="Join an existing organisation"
          onClick={() => goTo(Screens.ChooseOrg)}
        />
      </Cell>
      <Cell>
        <HR>or</HR>
      </Cell>
      <Cell>
        <PackageOption
          byline="If your organisation is not on ClassView. you can register it now."
          cta="Create a new organisation"
          heading="Create a new organisation"
          onClick={() => goTo(Screens.CreateOrgStep1)}
        />
      </Cell>
      <Cell>
        <TextButton appearance={EAppearance.GHOST} as="button" onClick={onPrevious}>
          Back
        </TextButton>
      </Cell>
    </>
  );
}
