import { EConversation } from "../enums/EConversation";
import { ESession } from "../enums/ESession";

const PRIVATE_CONV_PREFIX = "private_";
const SCHEDULED_PREFIX = "scheduled_";
const HUB_PREFIX = "hub_";

export function meanKeyPrivateConv(convID: string) {
  return `${PRIVATE_CONV_PREFIX}${convID}`;
}

export function meanKeyHub(roomKey: string) {
  return `${HUB_PREFIX}${roomKey}`;
}

export function meanKeyScheduled(roomKey: string) {
  return `${SCHEDULED_PREFIX}${roomKey}`;
}

export function parseConvTypeFromMeanKey(meanKey: string) {
  if (meanKey.startsWith(PRIVATE_CONV_PREFIX)) return EConversation.ConvPrivate;
  if (meanKey.startsWith(HUB_PREFIX)) return EConversation.ConvHub;
  if (meanKey.startsWith(SCHEDULED_PREFIX)) return EConversation.ConvScheduled;
  return undefined;
}

export function convIDFromRoomTypeLink(roomKey: string, roomType?: ESession) {
  if (!roomKey || roomKey === "" || !roomType) return undefined;
  switch (roomType) {
    case ESession.Hub:
      return meanKeyHub(roomKey);
    case ESession.Meeting:
    case ESession.LectureMode:
    case ESession.Webcast:
      return meanKeyScheduled(roomKey);
    default:
      return undefined;
  }
}
