import { Link, Outlet, useParams } from "react-router-dom";

import { Cell, Grid, IconButton, H1, NavigationLink, NavigationList } from "packages/catalog";
import {
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  PATH_SUPERADMIN_ORGANISATION,
  SEGMENT_CHILDREN,
  SEGMENT_ROOMSYSTEMS,
  SEGMENT_SESSIONS,
  SEGMENT_USERS,
  TParams,
} from "packages/utils";

import { useGetProfileOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";

export function SuperAdminOrganisationLayout() {
  const { organisationID }: TParams = useParams();

  const { data: organisationData } = useGetProfileOrganisationQuery({
    variables: {
      organisationID: parseInt(organisationID, 10),
    },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  if (!organisationData) return null;

  return (
    <Grid noColGap>
      <Cell>
        <IconButton
          appearance={EAppearance.GHOST}
          as={Link}
          description="Organisations"
          dimension={EDimension.SMALL}
          icon={EIcon.ARROWLEFT}
          label="Organisations"
          status={EStatus.NEUTRAL}
          to=".."
        />
      </Cell>
      <Cell>
        <H1>{organisationData?.organisation?.name}</H1>
      </Cell>
      <Cell>
        <NavigationList>
          <NavigationLink end to={PATH_SUPERADMIN_ORGANISATION(organisationID)}>
            Overview
          </NavigationLink>
          <NavigationLink to={SEGMENT_SESSIONS}>Sessions</NavigationLink>
          <NavigationLink to={SEGMENT_USERS}>Users</NavigationLink>
          {organisationData?.organisation?.organisationSet?.length ? (
            <NavigationLink to={SEGMENT_CHILDREN}>Child organisations</NavigationLink>
          ) : null}
          <NavigationLink to={SEGMENT_ROOMSYSTEMS}>Room Systems</NavigationLink>
        </NavigationList>
      </Cell>
      <Cell>
        <Outlet />
      </Cell>
    </Grid>
  );
}
