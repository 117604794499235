import { EDevice } from "../enums";
import type { EVidyoLocalDeviceStatus } from "../../status/enums";
import type { TVidyoDevice } from "../../streams/types";

export enum EVidyoLocalDevicesActions {
  ADD_DEVICE = "localDevices/add",
  CHANGE_DEVICE_STATUS = "localDevices/statuschange",
  MUTE_DEVICE = "localDevices/mute",
  REMOVE_DEVICE = "localDevices/remove",
  RESET_MUTE = "localDevices/resetmute",
  SELECT_DEVICE = "localDevices/select",
  SERVER_MUTE_DEVICE = "localDevices/servermute",
  SET_PREFERRED_DEVICE = "localDevices/preferred",
}

export interface ILocalDevicesState {
  [EDevice.CAMERA]: ILocalDeviceState<EDevice.CAMERA>;
  [EDevice.MICROPHONE]: ILocalDeviceState<EDevice.MICROPHONE>;
  [EDevice.SPEAKER]: ILocalDeviceState<EDevice.SPEAKER>;
}

export interface ILocalDeviceState<T extends EDevice> {
  available?: TVidyoDevice<T>[];
  preferred?: string;
  selected?: TVidyoDevice<T> | null;
  serverMuted?: boolean;
  shouldBeMuted?: boolean;
  status?: EVidyoLocalDeviceStatus;
}

export interface IAddLocalDeviceAction {
  type: EVidyoLocalDevicesActions.ADD_DEVICE;
  deviceType: EDevice;
  device: TVidyoDevice<EDevice>;
}
export interface IRemoveLocalDeviceAction {
  type: EVidyoLocalDevicesActions.REMOVE_DEVICE;
  deviceType: EDevice;
  device: TVidyoDevice<EDevice>;
}
export interface ISelectLocalDeviceAction {
  type: EVidyoLocalDevicesActions.SELECT_DEVICE;
  deviceType: EDevice;
  device: TVidyoDevice<EDevice>;
}
export interface IChangeLocalDeviceStatus {
  type: EVidyoLocalDevicesActions.CHANGE_DEVICE_STATUS;
  deviceType: EDevice;
  device: TVidyoDevice<EDevice>;
  status: EVidyoLocalDeviceStatus;
}
export interface IMuteLocalDeviceAction {
  type: EVidyoLocalDevicesActions.MUTE_DEVICE;
  deviceType: EDevice;
  shouldBeMuted: boolean;
}
export interface ISetPreferredDeviceAction {
  type: EVidyoLocalDevicesActions.SET_PREFERRED_DEVICE;
  deviceType: EDevice;
  deviceId: string;
}
export interface IServerMuteDeviceAction {
  type: EVidyoLocalDevicesActions.SERVER_MUTE_DEVICE;
  deviceType: EDevice;
  serverMuted: boolean;
}
export interface IResetServerMuteDeviceAction {
  type: EVidyoLocalDevicesActions.RESET_MUTE;
}
export type TLocalDevicesAction =
  | IAddLocalDeviceAction
  | IRemoveLocalDeviceAction
  | ISelectLocalDeviceAction
  | IChangeLocalDeviceStatus
  | IMuteLocalDeviceAction
  | ISetPreferredDeviceAction
  | IServerMuteDeviceAction
  | IResetServerMuteDeviceAction;
