import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Block,
  Cell,
  Description,
  ExternalTextLink,
  Grid,
  IconButton,
  IconContent,
  IllustratedCTA,
  H1,
  H2,
  LabelledContent,
  Loader,
  P,
  TextButton,
} from "packages/catalog";
import { EAppearance, EDimension, EIcon, TParams, PATH_PROFILE_EDIT, EStatus, EIllustration } from "packages/utils";

import { useGetEssentialOwnUserQuery, useGetProfileUserQuery } from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { UserAvatar } from "packages/client/users/components";

export function User() {
  const { userID }: TParams = useParams();

  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
  } = useGetProfileUserQuery({
    variables: {
      userID: parseInt(userID),
    },
  });

  const { data: userOwnData, loading: userOwnDataLoading } = useGetEssentialOwnUserQuery();

  const isOwnUser = useMemo(() => `${userOwnData?.user?.id}` === userID, [userOwnData?.user?.id, userID]);

  if (!userOwnData) return null;
  if (userDataLoading || userOwnDataLoading) return <Loader />;

  return (
    <>
      <RoutingChangeHandler message="The user's page has loaded." pageTitle="User" />
      <Grid>
        {userOwnData?.user?.isAdmin && (
          <Cell>
            <IconButton
              appearance={EAppearance.GHOST}
              as={Link}
              description="Users"
              dimension={EDimension.SMALL}
              icon={EIcon.ARROWLEFT}
              label="Users"
              status={EStatus.NEUTRAL}
              to=".."
            />
          </Cell>
        )}
        {userDataError && userDataError?.message === "Permission Denied." && (
          <Cell center fullHeight>
            <IllustratedCTA illustration={EIllustration.PERSONAL_INFORMATION}>
              {"This user's profile is private."}
            </IllustratedCTA>
          </Cell>
        )}
        {userData && (
          <>
            <Cell>
              <H1>User</H1>
            </Cell>
            <Cell as={Block} colSpan={12} colSpanMD={5}>
              <Grid noColGap>
                <Cell center>
                  <UserAvatar
                    dimension={EDimension.EXTRA_LARGE}
                    email={userData?.user?.email}
                    fullName={`${userData?.user?.firstName} ${userData?.user?.lastName}`}
                    profilePictureURL={userData?.user?.profilePicture}
                  />
                </Cell>
                <Cell center>
                  <H2>
                    {userData.user.firstName} {userData.user.lastName}
                  </H2>
                  <Description>
                    {userData.user.jobTitle} at {userData.user.organisation?.name}
                  </Description>
                </Cell>
              </Grid>
            </Cell>
            <Cell as={Block} colSpan={12} colSpanMD={7}>
              <Grid noColGap>
                {userData.user.bio && (
                  <Cell>
                    <LabelledContent content={userData.user.bio} label="About" />
                  </Cell>
                )}
                {userData.user.skills && (
                  <Cell>
                    <LabelledContent content={userData.user.skills} label="Skills" />
                  </Cell>
                )}
              </Grid>
            </Cell>
            <Cell as={Block} colSpan={12} colSpanMD={5}>
              <Grid noColGap>
                <Cell>
                  <IconContent description="Email address" icon={EIcon.MAIL}>
                    <P>
                      <ExternalTextLink href={`mailto: ${userData.user.email}`}>{userData.user.email}</ExternalTextLink>
                    </P>
                  </IconContent>
                </Cell>
                {userData.user.phoneNumber && (
                  <Cell>
                    <IconContent description="Telephone number" icon={EIcon.PHONE}>
                      <P>
                        <ExternalTextLink href={`tel: ${userData.user.phoneNumber}`}>
                          {userData.user.phoneNumber}
                        </ExternalTextLink>
                      </P>
                    </IconContent>
                  </Cell>
                )}
                {userData.user.website && (
                  <Cell>
                    <IconContent description="Website URL" icon={EIcon.GLOBE}>
                      <P>
                        <ExternalTextLink href={userData.user.website}>{userData.user.website}</ExternalTextLink>
                      </P>
                    </IconContent>
                  </Cell>
                )}
              </Grid>
            </Cell>
            {isOwnUser && (
              <Cell center>
                <TextButton as={Link} status={EStatus.ACCENT} to={PATH_PROFILE_EDIT}>
                  Edit
                </TextButton>
              </Cell>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
