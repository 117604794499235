import simplifyjs from "simplify-js";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toInt = (n: any) => Math.round(Number(n));

export interface CanvasPoint {
  x: number;
  y: number;
}

export interface CanvasPathData {
  points: CanvasPoint[];
  color: string;
  drawer: string | null;
}

export class CanvasPath implements CanvasPathData {
  points: CanvasPoint[];
  color: string;
  drawer: string | null;
  constructor(o?: { points?: CanvasPoint[]; color?: string; drawer?: string }) {
    this.points = o?.points ?? [];
    this.drawer = o?.drawer;
    this.color = o?.color ?? "#000";
  }
  getPath2D(): Path2D {
    return this.points.reduce((path, point, index) => {
      if (index === 0) path.moveTo(point.x, point.y);
      path.lineTo(point.x, point.y);
      return path;
    }, new Path2D());
  }
  add(point: CanvasPoint): void {
    this.points.push(point);
  }
  getOptimized(): CanvasPath {
    const tempPoints = this.points.map(e => {
      return { x: toInt(e.x), y: toInt(e.y) };
    });
    const pointsArray = simplifyjs(tempPoints, 1.5, true);
    return new CanvasPath({ color: this.color, points: pointsArray });
  }
  toJson(): string {
    return JSON.stringify(this);
  }
}
