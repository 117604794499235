import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Block,
  Cell,
  Dropdown,
  Feedback,
  Flexbox,
  Field,
  Grid,
  H1,
  H2,
  IconButton,
  Input,
  LabelledContent,
  P,
  TextButton,
  TextArea,
  Toggle,
  ToggleOption,
} from "packages/catalog";
import {
  convertStringEnumToDropdownObject,
  COUNTRIES_OPTIONS,
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  scrollToContentTop,
  TParams,
} from "packages/utils";

import { editOrganisation, uploadOrganisationLogo } from "packages/client/organisations/django/requests";

import { RequireAuthorisation } from "packages/client/authentication/components";

import { OrganisationType, OrganisationSector } from "packages/client/graphql_definitions.generated";
import { useGetProfileOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";
import { useGetEssentialOwnUserQuery } from "packages/client/users/graphql/users.generated";

import { RoutingChangeHandler } from "packages/client/layout/components";
import { ImageCropModal } from "packages/client/modals/components";
import { OrganisationLogo } from "packages/client/organisations/components";

const SECTOR_OPTIONS = convertStringEnumToDropdownObject(OrganisationSector);

export function EditOrganisation() {
  const { organisationID }: TParams = useParams();

  const [form, setForm] = useState<Partial<OrganisationType>>({
    about: "",
    address: "",
    city: "",
    country: "United Kingdom",
    county: "",
    domain: "",
    phoneNumber: "",
    postcode: "",
    rebranding: false,
    region: "",
    sector: OrganisationSector.Commercial,
  });
  const [isImageCropModalOpen, setIsImageCropModalOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const {
    data: organisationData,
    loading,
    refetch,
  } = useGetProfileOrganisationQuery({ variables: { organisationID: parseInt(organisationID) } });

  const { data: userOwnData } = useGetEssentialOwnUserQuery();

  const isChildOrganisation = useMemo(() => !!organisationData?.organisation?.parentOrganisation, [organisationData]);

  const isOwnChildOrganisation = useMemo(
    () =>
      userOwnData?.user?.organisation?.organisationSet?.some(
        childOrganisation => childOrganisation.id === organisationID,
      ),
    [organisationID, userOwnData],
  );

  const isOwnOrganisation = useMemo(
    () => organisationID === userOwnData?.user?.organisation?.id,
    [organisationID, userOwnData?.user?.organisation?.id],
  );

  const handleChangeCountry = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, country: e.target.value });
    },
    [form],
  );

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const handleChangeRebranding = useCallback(() => {
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
    editOrganisation(organisationID, { rebranding: !form.rebranding })
      .then(() => {
        refetch();
        setShowSuccessMessage(true);
      })
      .catch(() => setShowErrorMessage(true));
    setForm({ ...form, rebranding: !form.rebranding });
  }, [form, organisationID, refetch]);

  const handleChangeSector = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, sector: e.target.value as OrganisationSector });
    },
    [form],
  );

  const handleChangeTextArea = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setShowErrorMessage(false);
      setShowSuccessMessage(false);
      editOrganisation(organisationID, { ...form })
        .then(() => {
          refetch();
          scrollToContentTop();
          setShowSuccessMessage(true);
        })
        .catch(() => {
          scrollToContentTop();
          setShowErrorMessage(true);
        });
    },
    [form, organisationID, refetch],
  );

  const handleUpload = useCallback(
    (formData: FormData, filename: string) => {
      setIsImageCropModalOpen(false);
      setShowErrorMessage(false);
      setShowSuccessMessage(false);
      uploadOrganisationLogo(formData, filename, form.logo, organisationID)
        .then(() => {
          refetch();
          scrollToContentTop();
          setShowSuccessMessage(true);
        })
        .catch(() => {
          scrollToContentTop();
          setShowErrorMessage(true);
        });
    },
    [form.logo, organisationID, refetch],
  );

  useEffect(() => {
    if (organisationData && !loading) {
      setForm({
        about: organisationData.organisation.about ?? "",
        address: organisationData.organisation.address ?? "",
        city: organisationData.organisation.city ?? "",
        country: organisationData.organisation.country ?? "United Kingdom",
        county: organisationData.organisation.county ?? "",
        domain: organisationData.organisation.domain ?? "",
        phoneNumber: organisationData.organisation.phoneNumber ?? "",
        postcode: organisationData.organisation.postcode ?? "",
        rebranding: organisationData.organisation.rebranding ?? false,
        region: organisationData.organisation.region ?? "",
        sector: organisationData.organisation.sector ?? OrganisationSector.Commercial,
      });
    }
  }, [loading, organisationData]);

  if (!organisationData) return null;
  if (!userOwnData) return null;

  // requireauthorisation wrapper is used to wrap the component here because App.tsx is unable to access the nested param organisationID as it is not within a Routes itself
  return (
    <RequireAuthorisation conditions={[userOwnData?.user?.isAdmin, isOwnOrganisation || isOwnChildOrganisation]}>
      <RoutingChangeHandler message="The edit organisation page has loaded." pageTitle="Edit organisation" />
      <Grid noColGap>
        <Cell>
          <IconButton
            appearance={EAppearance.GHOST}
            as={Link}
            description="Organisation"
            dimension={EDimension.SMALL}
            icon={EIcon.ARROWLEFT}
            label="Organisation"
            status={EStatus.NEUTRAL}
            to=".."
          />
        </Cell>
        <Cell>
          <H1>Edit organisation</H1>
        </Cell>
        {showErrorMessage && (
          <Cell>
            <Feedback status={EStatus.DANGER}>Unable to update organisation. Please try again.</Feedback>
          </Cell>
        )}
        {showSuccessMessage && (
          <Cell>
            <Feedback status={EStatus.SUCCESS}>Organisation updated.</Feedback>
          </Cell>
        )}
        <Cell as="form" onSubmit={handleSubmit}>
          <Grid>
            <Cell colSpan={12} colSpanMD={4}>
              <Grid noColGap>
                <Cell as={Block}>
                  <Grid noColGap>
                    <Cell>
                      <LabelledContent
                        content={
                          <Flexbox justifyContentCenter>
                            <OrganisationLogo dimension={EDimension.EXTRA_LARGE} organisationID={organisationID} />
                          </Flexbox>
                        }
                        label="Logo"
                      />
                    </Cell>
                    <Cell center>
                      <TextButton type="button" onClick={() => setIsImageCropModalOpen(true)}>
                        Change logo
                      </TextButton>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell as={Block}>
                  <Grid noColGap>
                    <Cell>
                      <H2>Application branding</H2>
                    </Cell>
                    <Cell>
                      <P>
                        Application branding allows you to replace the ClassView logo with your chosen organisation logo
                        for users of this organisation and session guests.
                      </P>
                    </Cell>
                    <Cell>
                      <ToggleOption
                        description="Displays instead of the ClassView logo"
                        label={`Use own logo${
                          isChildOrganisation ? " or parent organisation logo" : ""
                        } as application logo`}>
                        <Toggle
                          checked={form.rebranding}
                          name="rebranding"
                          onChange={handleChangeRebranding}
                          value="rebranding"
                        />
                      </ToggleOption>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
            <Cell colSpanMD={8}>
              <Grid noColGap>
                <Cell as={Block}>
                  <Grid noColGap>
                    <Cell>
                      <H2>Overview</H2>
                    </Cell>
                    <Cell>
                      <Field
                        description="To change the name of your organisation, please email support@classview.com"
                        label="Name">
                        <Input
                          autoComplete="organization"
                          disabled
                          name="organisation"
                          type="text"
                          required
                          value={organisationData?.organisation?.name ?? ""}
                        />
                      </Field>
                    </Cell>
                    <Cell>
                      <Field label="Sector">
                        <Dropdown
                          name="sector"
                          onChange={handleChangeSector}
                          options={SECTOR_OPTIONS}
                          required
                          value={form.sector}
                        />
                      </Field>
                    </Cell>
                    <Cell>
                      <Field label="About">
                        <TextArea name="about" onChange={handleChangeTextArea} required rows={4} value={form.about} />
                      </Field>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell as={Block}>
                  <Grid noColGap>
                    <Cell>
                      <H2>Address</H2>
                    </Cell>
                    <Cell>
                      <Field label="Street">
                        <Input
                          autoComplete="street-address"
                          name="address"
                          onChange={handleChangeInput}
                          required
                          type="text"
                          value={form.address}
                        />
                      </Field>
                    </Cell>
                    <Cell>
                      <Field label="Town or city">
                        <Input
                          autoComplete="address-level2"
                          name="city"
                          onChange={handleChangeInput}
                          required
                          type="text"
                          value={form.city}
                        />
                      </Field>
                    </Cell>
                    <Cell>
                      <Field label="County">
                        <Input
                          autoComplete="address-level1"
                          name="county"
                          onChange={handleChangeInput}
                          required
                          type="text"
                          value={form.county}
                        />
                      </Field>
                    </Cell>
                    <Cell>
                      <Field label="Region">
                        <Input name="region" onChange={handleChangeInput} required type="text" value={form.region} />
                      </Field>
                    </Cell>
                    <Cell>
                      <Field label="Country">
                        <Dropdown
                          name="country"
                          onChange={handleChangeCountry}
                          options={COUNTRIES_OPTIONS}
                          required
                          value={form.country}
                        />
                      </Field>
                    </Cell>
                    <Cell>
                      <Field label="Postcode">
                        <Input
                          autoCapitalize="postal-code"
                          name="postcode"
                          onChange={handleChangeInput}
                          required
                          type="text"
                          value={form.postcode}
                        />
                      </Field>
                    </Cell>
                  </Grid>
                </Cell>
                <Cell as={Block}>
                  <Grid noColGap>
                    <Cell>
                      <H2>Contact details</H2>
                    </Cell>
                    <Cell>
                      <Field label="Website URL">
                        <Input
                          autoComplete="url"
                          name="domain"
                          onChange={handleChangeInput}
                          type="url"
                          value={form.domain}
                        />
                      </Field>
                    </Cell>
                    <Cell>
                      <Field label="Telephone number">
                        <Input
                          autoComplete="tel"
                          name="phoneNumber"
                          onChange={handleChangeInput}
                          type="tel"
                          value={form.phoneNumber}
                        />
                      </Field>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
            <Cell center>
              <Flexbox>
                <Input type="submit" value="Save" />
              </Flexbox>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
      <ImageCropModal
        isOpen={isImageCropModalOpen}
        onAbort={() => setIsImageCropModalOpen(false)}
        handleUpload={handleUpload}
      />
    </RequireAuthorisation>
  );
}
