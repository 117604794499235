import { Link } from "react-router-dom";

import { Description, Flexbox } from "packages/catalog";
import { EDimension, PATH_ORGANISATION, PATH_SESSIONS } from "packages/utils";

import { AppLogo } from "packages/client/layout/components";

import styles from "./DashboardLogo.module.scss";

export interface PDashboardLogo {
  isCondensed?: boolean;
  organisationID: string;
  organisationName: string;
  userEmail: string;
}

export function DashboardLogo({ isCondensed, organisationID, organisationName, userEmail }: PDashboardLogo) {
  return (
    <Flexbox alignItemsCenter>
      <Link to={PATH_SESSIONS}>
        <AppLogo dimension={EDimension.EXTRA_SMALL} />
      </Link>
      {!isCondensed && (
        <div className={styles.orgs}>
          <Link style={{ display: "block" }} to={PATH_ORGANISATION(organisationID)}>
            <p className={styles.org}>{organisationName}</p>
          </Link>
          <Description className={styles.email}>{userEmail}</Description>
        </div>
      )}
    </Flexbox>
  );
}
