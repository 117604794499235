import {
  ComponentPropsWithRef,
  ComponentType,
  forwardRef,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import type { LinkProps } from "react-router-dom";

import { Icon } from "packages/catalog";
import {
  EAppearance,
  EDimension,
  EIcon,
  EStatus,
  ILocationState,
  useAppearanceStyle,
  useDimensionStyle,
  useStatusStyle,
} from "packages/utils";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "../Buttons.module.scss";

export interface PIconButton extends ComponentPropsWithRef<"button"> {
  active?: boolean;
  appearance: EAppearance;
  as: string | ComponentType;
  description: string;
  dimension: EDimension.LARGE | EDimension.MEDIUM | EDimension.SMALL | EDimension.EXTRA_SMALL;
  fillIcon?: boolean;
  hasNotification?: boolean;
  href?: string;
  icon: EIcon | null;
  label?: string | ReactNode;
  rel?: string;
  rotateby?: number;
  state?: ILocationState;
  status: EStatus;
  target?: string;
  to?: string | LinkProps["to"] | number;
}

export const IconButton = forwardRef(
  (
    {
      active,
      appearance,
      as: Component,
      children,
      description,
      dimension,
      fillIcon,
      hasNotification,
      icon,
      label,
      rotateby,
      status,
      ...rest
    }: PIconButton,
    ref: MutableRefObject<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const appearanceStyle = useAppearanceStyle(styles);
    const dimensionStyle = useDimensionStyle(styles);
    const statusStyle = useStatusStyle(styles);

    const componentClasses = useMemo(
      () => [styles.iconButton, dimensionStyle[dimension]].join(" "),
      [dimension, dimensionStyle],
    );

    const contentClasses = useMemo(
      () =>
        [styles.content, statusStyle[status], appearanceStyle[appearance], active && styles.active]
          .filter(e => e)
          .join(" "),
      [active, appearance, appearanceStyle, status, statusStyle],
    );

    const iconClasses = useMemo(() => [styles.icon, fillIcon && styles.fillIcon].filter(e => e).join(" "), [fillIcon]);

    const rotation = useMemo(() => (rotateby ? { transform: `rotate(${rotateby}deg)` } : {}), [rotateby]);

    // prevent new window to appear on wheelclick electron
    const handleAuxClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
      if (event.button === 1) {
        event.preventDefault();
      }
    }, []);

    return (
      <Component className={componentClasses} onAuxClick={handleAuxClick} ref={ref} {...rest}>
        <div className={contentClasses} style={rotation}>
          {icon && <Icon className={iconClasses} icon={icon} />}
          {children}
          {!rest.disabled && dimension === EDimension.LARGE && hasNotification && (
            <div className={styles.hasNotification} />
          )}
        </div>
        {label ? (
          <span className={styles.label}>{label}</span>
        ) : (
          <span className={styles.description}>{description}</span>
        )}
      </Component>
    );
  },
);
