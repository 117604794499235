import { useEffect, useState } from "react";

import { connectToVidyo } from "../functions";
import type { TVidyoConnector } from "../types";

export function useVidyoSDKInstance() {
  const [vidyoSDK, setVidyoSDK] = useState<TVidyoConnector>(undefined);

  useEffect(() => {
    let cancelled = false;

    connectToVidyo().then(vidyoConnector => {
      if (!cancelled) setVidyoSDK(vidyoConnector);
    });
    return () => {
      cancelled = true;
    };
  }, []);

  return vidyoSDK;
}
