import { createContext } from "react";
import { createSelectorHook } from "react-redux";
import { combineReducers, Dispatch, Middleware, Reducer } from "redux";
import {
  applyMiddleware,
  compose,
  //configureStore,
  createStore,
} from "@reduxjs/toolkit";

// import { EVidyoLocalDevicesActions } from "../local-devices/redux/types";
// import { EVidyoParticipantsActions } from "../participants/redux/types";
// import { EVidyoSharingActions } from "../sharing/redux/types";
import type { IVidyoRootStore, TVidyoActions } from "./types";

import { participantsReducer } from "../participants/redux/reducer";
import { persistedLocalDevicesReducer } from "../local-devices/redux/reducer";
import { persistedSettingsReducer } from "../settings/redux/reducer";
import { persistedStatusReducer } from "../status/redux/reducer";
import { recordingReducer } from "../recording/redux/reducer";
import { sharingReducer } from "../sharing/redux/reducer";
import { streamsReducer } from "../streams/redux/reducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (params?: Record<string, unknown>) => typeof compose;
  }
}

export const VidyoContext = createContext(null);

const vidyoReducer: Reducer<IVidyoRootStore> = combineReducers({
  localDevices: persistedLocalDevicesReducer,
  participants: participantsReducer,
  recording: recordingReducer,
  settings: persistedSettingsReducer,
  sharing: sharingReducer,
  status: persistedStatusReducer,
  streams: streamsReducer,
});

const middlewares = [];

const logger: Middleware<undefined, IVidyoRootStore> = _store => next => action => {
  console.info("dispatching", action);
  return next(action);
};

const hasReduxDevtools = !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
if (!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && NODE_ENV !== "production") middlewares.push(logger);

const composer = hasReduxDevtools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: "VidyoStore" }) : compose;

export const instanceVidyoRootStore = createStore(vidyoReducer, composer(applyMiddleware(...middlewares)));

export const useSelectorVidyo = createSelectorHook(VidyoContext);
export const dispatchVidyo = instanceVidyoRootStore.dispatch as Dispatch<TVidyoActions>;
