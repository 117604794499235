import {
  Cell,
  ExternalTextLink,
  Flexbox,
  Grid,
  H3,
  IconButton,
  InternalTextLink,
  LabelledContent,
} from "packages/catalog";
import { EAppearance, EDimension, EIcon, EStatus, PATH_CALLLINK } from "packages/utils";

import type { RoomObjectType } from "packages/client/graphql_definitions.generated";

import { copySessionURL } from "packages/client/sessions/functions";

export interface PHubInfo {
  hub: Partial<RoomObjectType>;
  toggle: () => void;
}

export function HubInfo({ hub, toggle }: PHubInfo) {
  return (
    <>
      <Flexbox alignItemsFlexStart>
        <Grid noColGap>
          <Cell>
            <H3>How to join</H3>
          </Cell>
          <Cell>
            <LabelledContent
              content={
                <>
                  <InternalTextLink
                    style={{
                      display: "block",
                      marginBottom: "0.5rem",
                      wordBreak: "break-all",
                    }}
                    to={PATH_CALLLINK(hub.key)}>{`https://${DOMAIN}${PATH_CALLLINK(hub.key)}`}</InternalTextLink>
                  <IconButton
                    appearance={EAppearance.OUTLINE}
                    as="button"
                    description="Copy link"
                    dimension={EDimension.SMALL}
                    icon={EIcon.COPY}
                    label="Copy link"
                    onClick={() => copySessionURL(hub?.key)}
                    status={EStatus.NEUTRAL}
                  />
                </>
              }
              label="Web or mobile browser"
            />
          </Cell>
          <Cell>
            <LabelledContent
              content={
                <>
                  Call <ExternalTextLink href="tel:+443002010142">+44 (0) 300 201 0142</ExternalTextLink> and then enter
                  extension {hub.extension}#
                </>
              }
              label="Telephone"
            />
          </Cell>
          <Cell>
            <LabelledContent content={hub.extension} label="ClassView POD" />
          </Cell>
        </Grid>
        <IconButton
          appearance={EAppearance.GHOST}
          as="button"
          description="Back"
          dimension={EDimension.SMALL}
          icon={EIcon.ARROWRIGHT}
          onClick={toggle}
          status={EStatus.NEUTRAL}
        />
      </Flexbox>
    </>
  );
}
