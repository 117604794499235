import { useEffect } from "react";

import { useAuth } from "packages/client/authentication/hooks";

declare interface IBrowserLogEntry {
  timestamp: number;
  value: unknown[];
}

// global declarations for checking for web updates and handling log downloads
declare global {
  interface Window {
    allBrowserLogs: IBrowserLogEntry[];
    getAllBrowserLogs(): Promise<IBrowserLogEntry[]>;
    isNewBrowserVersionAvailable: () => AsyncGenerator<boolean>;
  }
}

if (!IS_ELECTRON) {
  let newVersion = false;

  // hoist timeout checker function to Window
  window.isNewBrowserVersionAvailable = async function* checkNewVersion() {
    while (true) {
      await new Promise(r => setTimeout(r, 10 * 1000));
      yield newVersion;
    }
  };

  // we only want the updater to run on production
  if (process.env.NODE_ENV === "production") {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then(reg => window.setInterval(() => reg.update(), 1000 * 60 * 5));

        let refreshing: boolean;

        window.navigator.serviceWorker.addEventListener("controllerchange", function () {
          if (refreshing) return;
          refreshing = true;
          newVersion = true;
        });
      });
    }
  }

  // console logging monkey patching
  /* eslint-disable no-console */
  /* eslint-disable no-inner-declarations */
  window.allBrowserLogs = [];

  let storeLogs: IDBDatabase | undefined = undefined;
  const LOGS_OBJ_STORE_NAME = "logs";
  const LOGS_TTL = 60 * 60 * 1000; // 1 hour in ms
  // const LOGS_TTL = 30 * 1000; // 30 seconds

  if (window.indexedDB) {
    const open = indexedDB.open(LOGS_OBJ_STORE_NAME);

    open.onupgradeneeded = () => {
      const db = open.result;
      const store = db.createObjectStore(LOGS_OBJ_STORE_NAME, { autoIncrement: true });
      store.createIndex("timestamp", "timestamp");
      storeLogs = db;
    };

    open.onsuccess = () => {
      const db = open.result;
      const range = IDBKeyRange.upperBound(Date.now() - LOGS_TTL);

      db
        .transaction(LOGS_OBJ_STORE_NAME, "readwrite")
        .objectStore(LOGS_OBJ_STORE_NAME)
        .index("timestamp")
        .openCursor(range).onsuccess = event => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cursor = (event.target as any).result;
        if (!cursor) return;
        cursor.delete();
        cursor.continue();
      };
      // db.transaction(LOGS_OBJ_STORE_NAME, "readonly").objectStore(LOGS_OBJ_STORE_NAME).count().onsuccess = event =>
      //   console.debug(`Logs in DB: ${(event.target as any).result}`);
      storeLogs = db;
    };
  }

  function pushToLogsSaved(tab: unknown[]) {
    // microstask handling
    Promise.resolve().then(() => {
      const now = Date.now();
      const value = tab.map(t => JSON.stringify(t, null, 2));
      const line: IBrowserLogEntry = { timestamp: now, value };
      if (storeLogs) {
        storeLogs.transaction(LOGS_OBJ_STORE_NAME, "readwrite").objectStore(LOGS_OBJ_STORE_NAME).put(line);
      } else window.allBrowserLogs.push(line);
    });
  }

  function getAllLogs() {
    if (storeLogs) {
      return new Promise<IBrowserLogEntry[]>((resolve, reject) => {
        const s = storeLogs.transaction(LOGS_OBJ_STORE_NAME, "readonly").objectStore(LOGS_OBJ_STORE_NAME);
        const q = s.getAll();
        q.onsuccess = e => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const res: IBrowserLogEntry[] = (e.target as any).result;
          resolve(res);
        };
        q.onerror = reject;
      });
    } else return Promise.resolve(window.allBrowserLogs);
  }
  window.getAllBrowserLogs = getAllLogs;

  const toIntercept = ["log", "error", "warn", "info"];

  type TLog = typeof console.log | typeof console.error | typeof console.warn | typeof console.info;

  const proxyConsole: ProxyHandler<TLog> = {
    apply: function (oTarget, _, args) {
      pushToLogsSaved(args);
      return oTarget.apply(window.console, args);
    },
  };

  toIntercept.forEach(v => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window.console as any)[v] = new Proxy((window.console as any)[v], proxyConsole);
  });
}

/* eslint-enable no-console */
/* eslint-enable no-inner-declarations */

let firstLoad = true;

export default function useEventsBrowser() {
  const { autoSignIn } = useAuth();

  useEffect(() => {
    if (firstLoad) {
      firstLoad = false;
      autoSignIn();
    }
  }, [autoSignIn]);
}
