import { lazy, useCallback } from "react";

import { Cell, Dialog, Emoji, Grid } from "packages/catalog";

import { useAnnouncements } from "packages/client/announcements/hooks";
import { RoutingChangeHandler } from "packages/client/layout/components";

const LazyMarkdown = lazy(() =>
  import("packages/catalog/atoms/typography/Markdown/Markdown").then(p => ({ default: p.Markdown })),
);

export function Announcement() {
  const { announcements, onDismiss } = useAnnouncements();

  const handleDismiss = useCallback(
    () => onDismiss(announcements.map(announcement => announcement.id)),
    [announcements, onDismiss],
  );

  if (!announcements.length) return null;

  return (
    <Dialog
      h1={
        <>
          Announcement <Emoji symbol="📣" />
        </>
      }
      isOpen={!!announcements.length}
      onAbort={handleDismiss}>
      <RoutingChangeHandler message="The announcement modal has loaded" />
      <Grid>
        <>
          {announcements.map(announcement => (
            <Cell key={announcement.id}>
              <LazyMarkdown value={announcement.content} />
            </Cell>
          ))}
        </>
      </Grid>
    </Dialog>
  );
}
