import { ECallStatus, useSelectorVidyo } from "packages/call";

import { useTypedSelector } from "packages/client/redux";

import { useModeration, useRecording } from "packages/client/incall/hooks";

export function useVidyoStore() {
  const { isInCall } = useSelectorVidyo(({ status }) => ({ isInCall: status.callStatus === ECallStatus.IN_CALL }));
  const { roomKey } = useTypedSelector(({ sessions }) => ({
    roomKey: sessions.roomKey,
  }));
  const recording = useRecording(roomKey, isInCall);
  const moderation = useModeration(roomKey, isInCall);
  return {
    recording,
    moderation,
  };
}
