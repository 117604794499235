import { useCallback, useEffect, useMemo, useState } from "react";
import {
  disconnectAll,
  disconnectParticipant,
  fetchAudioVideoStatuses,
  muteAudioAll,
  muteAudioParticipant,
  muteVideoAll,
  muteVideoParticipant,
  unmuteAudioAll,
  unmuteAudioParticipant,
  unmuteVideoAll,
  unmuteVideoParticipant,
} from "packages/client/incall/django/requests";
import type { IContextParticipant, IDjangoParticipant } from "packages/client/incall/django/types";
import { toContextParticipant } from "packages/client/incall/django/serialisers";

export function useModeration(roomKey: string | undefined, inCall: boolean) {
  const [loading, setLoading] = useState<boolean>(false);
  const [participantStatuses, setPartStatuses] = useState<IContextParticipant[]>([]);

  const numberOfParticipants = useMemo(() => participantStatuses.length, [participantStatuses]);

  const updateParticipantsState = useCallback(async () => {
    const data: IDjangoParticipant[] = await fetchAudioVideoStatuses(roomKey);
    setPartStatuses(toContextParticipant(data ?? []));
  }, [roomKey]);

  useEffect(() => {
    if (!roomKey || !inCall) return;
    let cancelled = false;
    async function update() {
      const data: IDjangoParticipant[] = await fetchAudioVideoStatuses(roomKey);
      if (cancelled) return;
      if (!data) return;
      const newData = toContextParticipant(data);
      setPartStatuses(newData);
    }
    const t = window.setInterval(update, 3000);
    return () => {
      window.clearInterval(t);
      cancelled = true;
    };
  }, [inCall, roomKey]);

  const moderateParticipants = useCallback(
    async (
      participantModerationRequest: (roomKey: string, p?: string) => Promise<{ Response: string }>,
      p?: string,
    ) => {
      setLoading(true);
      try {
        const res = await participantModerationRequest(roomKey, p);
        if (res.Response === "OK") {
          window.setTimeout(async () => {
            await updateParticipantsState();
            setLoading(false);
          }, 500);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [roomKey, updateParticipantsState],
  );

  const kickAll = () => disconnectAll(roomKey);
  const kickParticipant = (p: string) => disconnectParticipant(roomKey, p);
  const muteCameraAll = () => moderateParticipants(muteVideoAll);
  const muteCameraParticipant = (p: string) => moderateParticipants(muteVideoParticipant, p);
  const muteMicrophoneAll = () => moderateParticipants(muteAudioAll);
  const muteMicrophoneParticipant = (p: string) => moderateParticipants(muteAudioParticipant, p);
  const unmuteCameraAll = () => moderateParticipants(unmuteVideoAll);
  const unmuteCameraParticipant = (p: string) => moderateParticipants(unmuteVideoParticipant, p);
  const unmuteMicrophoneAll = () => moderateParticipants(unmuteAudioAll);
  const unmuteMicrophoneParticipant = (p: string) => moderateParticipants(unmuteAudioParticipant, p);

  return {
    kickAll,
    kickParticipant,
    loading,
    muteCameraAll,
    muteCameraParticipant,
    muteMicrophoneAll,
    muteMicrophoneParticipant,
    numberOfParticipants,
    participantStatuses,
    unmuteCameraAll,
    unmuteCameraParticipant,
    unmuteMicrophoneAll,
    unmuteMicrophoneParticipant,
  };
}
