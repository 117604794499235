import type { SVGProps } from "react";

export function ClassViewLogo({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      style={{
        display: "block",
      }}
      viewBox="0 0 161 104"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <title>ClassView</title>
      <g>
        <rect fill="var(--theme-color-accent-base)" x="70" width="91" height="57" rx="3" ry="3"></rect>
        <rect fill="var(--theme-color-accent-base)" x="20" y="67" width="40" height="25" rx="3" ry="3"></rect>
        <rect fill="var(--theme-color-accent-base)" x="70" y="67" width="60" height="37" rx="3" ry="3"></rect>
        <rect fill="var(--theme-color-accent-base)" y="20" width="60" height="37" rx="3" ry="3"></rect>
      </g>
    </svg>
  );
}
