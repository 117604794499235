import { useCallback, useState } from "react";

import { Dialog, Feedback } from "packages/catalog";
import { EStatus } from "packages/utils";

import { FeedbackForm } from "packages/client/feedback-form/components/FeedbackForm/FeedbackForm";

export interface PUserFeedbackModal {
  isOpen?: boolean;
  onAbort: () => void;
}

export function UserFeedbackModal({ isOpen, onAbort }: PUserFeedbackModal) {
  const [success, setSuccess] = useState<boolean>(false);

  const onClickAbort = useCallback(() => {
    setSuccess(false);
    onAbort();
  }, [onAbort]);

  return (
    <Dialog h1="Send feedback" isOpen={isOpen} onAbort={onClickAbort}>
      {success ? (
        <Feedback status={EStatus.SUCCESS}>
          {
            "Thank you. Your feedback is really appreciated and helps us build a better product experience. We'll be in touch if you opted in to being contacted."
          }
        </Feedback>
      ) : (
        <FeedbackForm setSuccess={setSuccess} />
      )}
    </Dialog>
  );
}
