import type { HTMLProps } from "react";

import { useMergedClassNames } from "packages/utils";

import styles from "./Description.module.scss";

export function Description({ children, className, ...rest }: HTMLProps<HTMLSpanElement>) {
  const descriptionClasses = useMergedClassNames({ className }, [styles.description]);

  return (
    <span>
      <small className={descriptionClasses} {...rest}>
        {children}
      </small>
    </span>
  );
}
