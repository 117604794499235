import { Outlet } from "react-router-dom";

import { Cell, Feedback, Grid, H1, NavigationLink, NavigationList } from "packages/catalog";
import { EStatus, PATH_SUPERADMIN_USERS, PATH_SUPERADMIN_USERS_PENDING } from "packages/utils";

export function SuperAdminUsersLayout() {
  return (
    <Grid noColGap>
      <Cell>
        <H1>Users</H1>
      </Cell>
      {/* The following feedback warning only applies to live, to explain why every user prior to 4208 is marked as having their email unverified */}
      <Cell>
        <Feedback status={EStatus.WARNING}>Email verification was not available for users prior to user 4208.</Feedback>
      </Cell>
      <Cell>
        <NavigationList>
          <NavigationLink end to={PATH_SUPERADMIN_USERS}>
            All
          </NavigationLink>
          <NavigationLink to={PATH_SUPERADMIN_USERS_PENDING}>Pending</NavigationLink>
        </NavigationList>
      </Cell>
      <Cell>
        <Outlet />
      </Cell>
    </Grid>
  );
}
