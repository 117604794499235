import type { EBackgroundColor } from "packages/utils";

import type { EVidyoNetworkPolicy } from "../enums";

export enum EVidyoSettingsActions {
  RESET_SETTINGS = "settings/reset",
  UPDATE_SETTINGS = "settings/update",
}

export interface IUpdateableSettingsState {
  backgroundColor: EBackgroundColor /** background colour */;
  isBackgroundBlurred: boolean /** blur own background */;
  isBackgroundColored: boolean /** colour own background */;
  isSelfShareMaximized: boolean /** own window sharing is also zoomed */;
  isSpeakerMaximizedOnFallback: boolean /** main speaker / loudest, is maximized and weight like a window share if no sharing */;
  networkPolicy: EVidyoNetworkPolicy /** prefer smoother videos or lots of people */;
  userProvidedMaxSources: number /** user provided to apply on network policy people */;
  userProvidedMinSources: number /** user provided to apply on network policy people */;
}

export interface ISettingsState extends IUpdateableSettingsState {
  isInitialState: boolean;
}

export interface IUpdateSettingsAction {
  type: EVidyoSettingsActions.UPDATE_SETTINGS;
  update: Partial<IUpdateableSettingsState>;
}
export interface IResetSettingsAction {
  type: EVidyoSettingsActions.RESET_SETTINGS;
}
export type TSettingsAction = IResetSettingsAction | IUpdateSettingsAction;
