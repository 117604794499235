import { createContext, ReactNode, useContext } from "react";

import { useIncallChat, TUseIncallChat, useRoom } from "packages/client/incall/hooks";

export interface PIncallChatContext {
  children: ReactNode;
}

function noop() {
  //nothing
}

const IncallChatStore = createContext<TUseIncallChat>({
  canLoadMore: false,
  deleteAllMessages: noop,
  deleteMessage: noop,
  fetchMoreMessages: noop,
  messages: [],
  sendMessage: noop,
});

export function IncallChatContext({ children }: PIncallChatContext) {
  const { convID } = useRoom();
  const data = useIncallChat(convID);
  return <IncallChatStore.Provider value={data}>{children}</IncallChatStore.Provider>;
}

export function useIncallChatContext() {
  return useContext(IncallChatStore);
}
