import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  BigPopoverMenu,
  Description,
  Flexbox,
  HR,
  IconButton,
  NavigationLink,
  NavigationList,
  P,
  PlainAction,
  StatusBadge,
  Strong,
} from "packages/catalog";
import {
  EAppearance,
  EAttachment,
  EDimension,
  EIcon,
  EStatus,
  PATH_PROFILE_CHANGEPASSWORD,
  PATH_PROFILE_EDIT,
  PATH_PROFILE_SETTINGS,
  PATH_SESSIONS,
  PATH_SUPERADMIN,
  PATH_USER,
  SEGMENT_HUBS,
  SEGMENT_ORGANISATIONS,
  SEGMENT_POLLS,
  SEGMENT_RECORDINGS,
  SEGMENT_ROOMSYSTEMS,
  SEGMENT_USERS,
  useREMSize,
  useWindowSize,
} from "packages/utils";

import { useAuth } from "packages/client/authentication/hooks";
import { useIsRecordingDisabled } from "packages/client/organisations/hooks";
import {
  useGetEssentialOrganisationInfoOwnUserQuery,
  useGetPendingUsersQuery,
} from "packages/client/users/graphql/users.generated";

import { ErrorBoundary } from "packages/client/errors/components";
import { DashboardLogo, Version } from "packages/client/layout/components";
import { UserAvatar } from "packages/client/users/components";

import styles from "./DashboardNavigation.module.scss";

export function DashboardNavigation() {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const { signOut } = useAuth();
  const { data } = useGetEssentialOrganisationInfoOwnUserQuery();
  const { data: pendingUsers } = useGetPendingUsersQuery({
    fetchPolicy: "cache-and-network",
    pollInterval: 3000,
  });
  const { isRecordingDisabled } = useIsRecordingDisabled();
  const { width } = useWindowSize();
  const remSize = useREMSize();

  const condensedWidth = 76.8 * remSize;
  const isCondensed = width < condensedWidth;

  useEffect(() => setIsOpen(false), [location]);

  useEffect(() => {
    setIsOpen(!isCondensed);
  }, [isCondensed, location]);

  return (
    <nav className={styles.nav}>
      <ErrorBoundary>
        <div className={styles.flex}>
          <div>
            <Flexbox alignItemsCenter justifyContentSpaceBetween>
              <DashboardLogo
                isCondensed={isCondensed}
                organisationID={data?.user?.organisation?.id}
                organisationName={data?.user?.organisation?.name}
                userEmail={data?.user?.email}
              />
              {isCondensed && (
                <Flexbox alignItemsCenter>
                  <BigPopoverMenu
                    attachment={EAttachment.SOUTH_SOUTH_EAST}
                    trigger={
                      <div className={styles.trigger}>
                        <UserAvatar
                          dimension={EDimension.MEDIUM}
                          email={data?.user?.email}
                          fullName={`${data?.user?.firstName} ${data?.user?.lastName}`}
                        />
                        <IconButton
                          as="div"
                          description="Options"
                          status={EStatus.NEUTRAL}
                          icon={EIcon.CHEVRONDOWN}
                          appearance={EAppearance.GHOST}
                          dimension={EDimension.SMALL}
                        />
                      </div>
                    }>
                    <Flexbox flexDirectionColumn>
                      <div>
                        <Flexbox alignItemsCenter>
                          <Link to={PATH_USER(data?.user?.id)}>
                            <P>
                              <Strong>
                                {data?.user?.firstName} {data?.user?.lastName}
                              </Strong>
                            </P>
                          </Link>
                          {data?.user?.isSuperuser ? (
                            <StatusBadge status={EStatus.NEUTRAL}>SuperAdmin</StatusBadge>
                          ) : data?.user?.isAdmin ? (
                            <StatusBadge status={EStatus.ACCENT}>Admin</StatusBadge>
                          ) : null}
                        </Flexbox>
                        <Description>{data?.user?.email}</Description>
                      </div>
                      <HR />
                      <PlainAction as={Link} to={PATH_PROFILE_EDIT}>
                        Edit profile
                      </PlainAction>
                      <PlainAction as={Link} to={PATH_PROFILE_CHANGEPASSWORD}>
                        Change password
                      </PlainAction>
                      <PlainAction as={Link} to={PATH_PROFILE_SETTINGS}>
                        Settings
                      </PlainAction>
                      <HR />
                      <PlainAction as="button" onClick={signOut} type="button">
                        Sign out
                      </PlainAction>
                    </Flexbox>
                  </BigPopoverMenu>
                  <IconButton
                    appearance={EAppearance.GHOST}
                    as="button"
                    description={isOpen ? "Close menu" : "Open menu"}
                    dimension={EDimension.LARGE}
                    icon={isOpen ? EIcon.X : EIcon.MENU}
                    onClick={() => setIsOpen(!isOpen)}
                    status={EStatus.NEUTRAL}
                    type="button"
                  />
                </Flexbox>
              )}
            </Flexbox>
            {!isCondensed && <HR className={styles.hr} />}
          </div>
          {isOpen && (
            <Flexbox className={styles.links} flexDirectionColumn justifyContentSpaceBetween>
              <NavigationList flexDirectionColumn>
                <NavigationLink emoji="📅" isFullWidth isOnDarkBackground to={PATH_SESSIONS}>
                  {data?.user?.isAdmin ? "All sessions" : "Sessions"}
                </NavigationLink>
                <NavigationLink emoji="💻" isFullWidth isOnDarkBackground to={SEGMENT_HUBS}>
                  Hubs
                </NavigationLink>
                {!isRecordingDisabled && (
                  <NavigationLink emoji="🎥" isFullWidth isOnDarkBackground to={SEGMENT_RECORDINGS}>
                    Recordings
                  </NavigationLink>
                )}
                <NavigationLink emoji="📊" isFullWidth isOnDarkBackground to={SEGMENT_POLLS}>
                  Polls
                </NavigationLink>
                {data?.user?.isAdmin && (
                  <>
                    <NavigationLink
                      emoji="👩‍👧‍👦"
                      hasNotification={!!pendingUsers?.pendingUsers?.objects?.length}
                      isFullWidth
                      isOnDarkBackground
                      to={SEGMENT_USERS}>
                      Users
                    </NavigationLink>
                    <NavigationLink emoji="📺" isFullWidth isOnDarkBackground to={SEGMENT_ROOMSYSTEMS}>
                      Room Systems
                    </NavigationLink>
                    <NavigationLink emoji="🌐" isFullWidth isOnDarkBackground to={SEGMENT_ORGANISATIONS}>
                      Organisation
                    </NavigationLink>
                  </>
                )}
                {data?.user?.isSuperuser && (
                  <>
                    <HR />
                    <NavigationLink emoji="🚀" isFullWidth isOnDarkBackground to={PATH_SUPERADMIN}>
                      SuperAdmin
                    </NavigationLink>
                  </>
                )}
              </NavigationList>
              <P center>
                <Description>
                  <Version />
                </Description>
              </P>
            </Flexbox>
          )}
        </div>
      </ErrorBoundary>
    </nav>
  );
}
