import { generateFileAPIEndpointURLAuthenticated } from "packages/client/files/s3";

export function generateRecordingThumbnailURL(recordingID: string): string {
  if (!recordingID) return;
  return generateFileAPIEndpointURLAuthenticated(BUCKET_RECORDING, "", `${recordingID}.jpg`);
}

export function generateDownloadLink(url: string) {
  const u = generateFileAPIEndpointURLAuthenticated(BUCKET_RECORDING, "", `${url}.mp4`);
  const download = new URL(u);
  download.host = window.location.host;
  return download.href;
}
