import { useMemo, useState } from "react";

import { EDimension, useDimensionStyle } from "packages/utils";

import { useGetEssentialOrganisationQuery } from "packages/client/organisations/graphql/organisations.generated";

import { useGetCurrentOrganisation } from "packages/client/organisations/hooks";

import { generateOrganisationLogoURLAuthenticated } from "packages/client/organisations/s3";

import { ClassViewLogo } from "packages/client/layout/components";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./AppLogo.scss";

export interface PAppLogo {
  dimension: EDimension.EXTRA_SMALL | EDimension.SMALL | EDimension.MEDIUM | EDimension.LARGE;
}

export function AppLogo({ dimension }: PAppLogo) {
  const [imageError, setImageError] = useState(false);

  const { organisationID } = useGetCurrentOrganisation();
  const { data } = useGetEssentialOrganisationQuery({ variables: { organisationID }, skip: !organisationID });

  const dimensionStyle = useDimensionStyle(styles);

  const classNames = useMemo(() => dimensionStyle[dimension], [dimension, dimensionStyle]);

  const organisationLogoSRC = useMemo(() => {
    if (data?.organisation?.rebranding) return data?.organisation?.logo ?? data?.organisation?.parentOrganisation?.logo;
    return null;
  }, [data?.organisation]);
  const organisationLogoURL = useMemo(
    () => generateOrganisationLogoURLAuthenticated(organisationLogoSRC),
    [organisationLogoSRC],
  );

  return (
    <>
      {organisationLogoSRC && !imageError ? (
        <img
          alt={`Logo of ${data?.organisation?.name}`}
          className={classNames}
          onError={() => setImageError(true)}
          src={organisationLogoURL}
        />
      ) : (
        <ClassViewLogo className={classNames} />
      )}
    </>
  );
}
