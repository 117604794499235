import {
  Cell,
  ExternalTextLink,
  Feedback,
  Flexbox,
  Grid,
  H1,
  H2,
  InternalTextLink,
  P,
  TextButton,
} from "packages/catalog";
import { PATH_SIGNIN, REGEX_EMAIL, REGEX_TELEPHONE, EStatus } from "packages/utils";

import { defaultRules } from "packages/client/onboarding/consts";

import { FormContainer as Form, SScreen } from "packages/client/onboarding/containers";

import { RoutingChangeHandler } from "packages/client/layout/components";

export interface PUser {
  cache: SScreen;
  checkEmpty: (screen: string) => void;
  checkingEmpty: boolean;
  emptyChecked: (screen: string) => void;
  isUserValidating: boolean;
  newUserError?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (formData: any) => void;
  onValidation: (isValid: boolean) => void;
  validateUser: () => void;
}

export function User({
  cache,
  checkEmpty,
  checkingEmpty,
  emptyChecked,
  isUserValidating,
  newUserError,
  onChange,
  onValidation,
  validateUser,
}: PUser) {
  return (
    <>
      <RoutingChangeHandler message="The create account page has loaded." pageTitle="Create account" />
      <Cell>
        <H1 center hasMargin>
          Create your account
        </H1>
        <P>Get started with ClassView and access the video collaboration platform for education.</P>
      </Cell>
      <Cell>
        <H2>About you</H2>
      </Cell>
      <Cell>
        <Form
          checkingEmpty={checkingEmpty}
          emptyChecked={() => emptyChecked("user")}
          onValidation={onValidation}
          debounceValidation={150}>
          <Grid noColGap>
            <Cell>
              <Form.Input
                autoComplete="given-name"
                isRequired
                label="First name"
                name="first-name"
                onChange={firstName => onChange({ firstName })}
                required
                rules={defaultRules}
                value={cache.firstName ? `${cache.firstName}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Input
                autoComplete="family-name"
                isRequired
                label="Last name"
                name="last-name"
                onChange={lastName => onChange({ lastName })}
                required
                rules={defaultRules}
                value={cache.lastName ? `${cache.lastName}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Input
                advisory="Please use your institutional email address as we are unable to approve generic email addresses."
                autoComplete="email"
                isRequired
                label="Institutional email address"
                name="email"
                onChange={email => onChange({ email })}
                required
                rules={{ ...defaultRules, regex: { value: REGEX_EMAIL, message: "Invalid email address." } }}
                type="email"
                value={cache.email ? `${cache.email}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Input
                autoComplete="new-password"
                isRequired
                label="Password"
                name="new-password"
                onChange={password => onChange({ password })}
                required
                rules={defaultRules}
                type="password"
                value={cache.password ? `${cache.password}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Input
                autoComplete="new-password"
                isRequired
                label="Confirm password"
                name="confirm-new-password"
                onChange={repeat => onChange({ repeat })}
                required
                rules={defaultRules}
                type="password"
                value={cache.repeat ? `${cache.repeat}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Input
                autoComplete="tel"
                isRequired={false}
                label="Telephone number"
                name="tel"
                onChange={userPhone => onChange({ userPhone })}
                rules={{ ...defaultRules, regex: { value: REGEX_TELEPHONE, message: "Invalid telephone number." } }}
                type="text"
                value={cache.userPhone ? `${cache.userPhone}` : ""}
              />
            </Cell>
            <Cell>
              <Form.Checkbox
                checked={cache.optIn}
                defaultChecked
                label="I would like to OPT IN to receive marketing communication on the ClassView service. I understand if I do not opt in, I will still receive service communications such as feature updates and service improvements."
                onChange={isChecked => onChange({ optIn: isChecked })}
              />
            </Cell>
            <Cell>
              <Form.Checkbox
                checked={cache.eula}
                errorMessage="Please accept the End User Agreement."
                label={
                  <>
                    I agree to{" "}
                    <ExternalTextLink
                      target="blank"
                      rel="noopener noreferrer"
                      href="https://classview.com/end-user-licence-agreement/">
                      End User Agreement (EULA)
                    </ExternalTextLink>{" "}
                    as set out in our{" "}
                    <ExternalTextLink
                      target="blank"
                      rel="noopener noreferrer"
                      href="https://classview.com/privacy-policy/">
                      Privacy Policy
                    </ExternalTextLink>
                    .
                  </>
                }
                onChange={isChecked => onChange({ eula: isChecked })}
                required
              />
            </Cell>
          </Grid>
        </Form>
      </Cell>
      {newUserError && !isUserValidating && (
        <Cell>
          <Feedback status={EStatus.DANGER}>{`${newUserError} Please update your input.`}</Feedback>
        </Cell>
      )}
      <Cell>
        <Flexbox justifyContentEnd>
          <TextButton
            as="button"
            disabled={!cache.isValid || isUserValidating}
            onClick={cache.isValid ? validateUser : () => checkEmpty("user")}
            status={EStatus.ACCENT}>
            Next
          </TextButton>
        </Flexbox>
      </Cell>
      <Cell center>
        <P>
          Already have an account? <InternalTextLink to={PATH_SIGNIN()}>Sign in</InternalTextLink>.
        </P>
      </Cell>
    </>
  );
}
