import { EAppearance } from "packages/utils";

export interface PuseAppearanceStyle {
  fill?: string;
  ghost?: string;
  outline?: string;
}

export function useAppearanceStyle({ fill, ghost, outline }: PuseAppearanceStyle) {
  return {
    [EAppearance.FILL]: fill ?? "",
    [EAppearance.GHOST]: ghost ?? "",
    [EAppearance.OUTLINE]: outline ?? "",
  };
}
