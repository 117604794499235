import type { AnswerResult } from "packages/catalog";

export interface PQuestionResult {
  children: OneOrManyReactChildren<typeof AnswerResult>;
  question: string;
}

export function QuestionResult({ children }: PQuestionResult) {
  return <>{children}</>;
}
