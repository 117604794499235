import { useMemo } from "react";
import { Link } from "react-router-dom";

import { BigPopoverMenu, Flexbox, Icon, IconButton, InternalTextLink, PlainAction, TD, TR } from "packages/catalog";
import { EAppearance, EAttachment, EDimension, EIcon, ELocale, pluralise, PATH_POLL, EStatus } from "packages/utils";

import type { FEssentialPollFragment } from "packages/client/polls/graphql/polls.generated";

import { Badge } from "packages/client/polls/components";

import styles from "./PollListItem.module.scss";

export interface PPollListItem {
  columns: string[];
  columnWidths: string;
  handleEnd: () => void;
  poll: FEssentialPollFragment;
  prepareDelete: () => void;
  preparePublish: () => void;
}

export function PollListItem({ columns, columnWidths, poll, prepareDelete, handleEnd, preparePublish }: PPollListItem) {
  const votesText = useMemo(
    () => pluralise(poll.voteCount, ELocale.EN_GB, "participant", "participants"),
    [poll.voteCount],
  );

  return (
    <TR columnWidths={columnWidths}>
      <TD heading={columns[0]}>
        <Flexbox alignItemsCenter>
          <Icon className={styles.icon} icon={EIcon.BARCHART2} />
          <InternalTextLink to={PATH_POLL(poll.id)}>{poll.name}</InternalTextLink>
        </Flexbox>
      </TD>
      <TD heading={columns[1]}>{votesText}</TD>
      <TD heading={columns[2]}>
        <Badge status={poll.status} />
      </TD>
      <TD>
        <BigPopoverMenu
          attachment={EAttachment.SOUTH_SOUTH_EAST}
          trigger={
            <IconButton
              appearance={EAppearance.GHOST}
              as="div"
              description="Options"
              dimension={EDimension.SMALL}
              icon={EIcon.MOREVERTICAL}
              status={EStatus.NEUTRAL}
            />
          }>
          <ul>
            <li className={styles.action}>
              <PlainAction as={Link} to={PATH_POLL(poll.id)}>
                Manage poll
              </PlainAction>
            </li>
            {poll.status === "PUBLISHED" ? (
              <li className={styles.action}>
                <PlainAction as="button" onClick={handleEnd}>
                  End
                </PlainAction>
              </li>
            ) : (
              <li className={styles.action}>
                <PlainAction as="button" onClick={preparePublish}>
                  Publish
                </PlainAction>
              </li>
            )}
            <li className={styles.action}>
              <PlainAction as="button" status={EStatus.DANGER} onClick={prepareDelete}>
                Delete
              </PlainAction>
            </li>
          </ul>
        </BigPopoverMenu>
      </TD>
    </TR>
  );
}
